import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
const styles = (theme: Theme): StyleRules<string> => ({
  root: {},
  container: {
    margin: '0 5px 0 5px',
    padding: '5px 20px 5px 20px',
    borderRadius: '5px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.55)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hiddenConainer: {
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.40)',
  },
  hiddenElement: {
    opacity: 0.4,
  },
  nameContainer: {
    lineHeight: '50px',
    height: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: 'flex',
  },
});
export default styles;
