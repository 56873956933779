import {
  MemberWithKey,
  Repetition,
  ShiftDialogState,
  ShiftTemplateWithKey,
} from '@shared/schema';
import { Reducer } from 'redux';
import { ShiftAction, ShiftActionType } from './shiftAction';

export interface ShiftParamsToChange {
  selectedMember?: MemberWithKey;
  shiftTemplates?: ShiftTemplateWithKey[];
  selectedShiftTemplate?: ShiftTemplateWithKey;
  selectedDate?: Date;
  repetition?: Repetition;
}

export interface ShiftState {
  shiftDialogState: ShiftDialogState;
  totalProgress: number;
  progressMessage: string;
  currentProgress: number;
}

export const defaultShiftState: ShiftState = {
  shiftDialogState: ShiftDialogState.CLOSED,
  totalProgress: 0,
  currentProgress: 0,
  progressMessage: '',
};

export const shiftReducer: Reducer<ShiftState> = (
  state = defaultShiftState,
  action: ShiftAction,
): ShiftState => {
  switch (action.type) {
    case ShiftActionType.SHIFT_NEW:
      return {
        ...state,
        shiftDialogState: ShiftDialogState.OPEN,
      };
    case ShiftActionType.SHIFT_CANCEL:
      return {
        ...state,
        shiftDialogState: ShiftDialogState.CLOSED,
      };
    case ShiftActionType.SET_CURRENT_PROGRESS:
      return {
        ...state,
        currentProgress: action.payload,
      };
    case ShiftActionType.SET_TOTAL_PROGRESS:
      return {
        ...state,
        totalProgress: action.payload,
      };
    case ShiftActionType.SET_PROGRESS_MESSAGE:
      return {
        ...state,
        progressMessage: action.payload,
      };
    case ShiftActionType.INCREMENT_PROGRESS:
      return {
        ...state,
        currentProgress: state.currentProgress + 1,
      };
    default:
      return state;
  }
};
