import {
  IconButton,
  StyledComponentProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { i18n } from '@shared/locale';
import {
  ChangeLog,
  HoursByHourType,
  HourTypeWithKey,
  MemberWithKey,
  WorkingHoursWithKey,
  WorkTimeNotesByHourType,
} from '@shared/schema/index';
import { WorkTimeReports } from '@shared/schema/reports';
import * as React from 'react';
import CustomTableCell from 'theme/styles/tableCell';
import styles from './styles';
import WorkTimeTableMemberRow from './WorkTimeTableMemberRow';

export interface WorkTimeTableMemberProps extends StyledComponentProps {
  hourTypes: HourTypeWithKey[] | undefined;
  startDate?: Date;
  endDate?: Date;
  companyId: string;
  selectedMember: MemberWithKey;
  reports?: WorkTimeReports;
  showOrCloseMemberWorktimeData(member: MemberWithKey | undefined): void;
}

/**
 * Helper interface for building object that holds all
 * need data for one day of work and counts hours separately
 * for each hourtype
 */
export interface WorkTimeByDate {
  workDate: string;
  month: string;
  workTimeByHours: HoursByHourType;
  workNotesByHours: WorkTimeNotesByHourType;
  allHoursForOneDay: number;
  changeLog: ChangeLog[];
}

export class WorkTimeTableMember extends React.Component<
  WorkTimeTableMemberProps
> {
  constructor(props: WorkTimeTableMemberProps) {
    super(props);
  }

  public render() {
    const {
      classes = {},
      selectedMember,
      hourTypes,
      showOrCloseMemberWorktimeData,
      reports,
    } = this.props;

    return (
      <div className={classes.tableContainer}>
        <div className={classes.memberInfo}>
          <h2 className={classes.memberHeader}>
            {selectedMember && selectedMember.name}
            <span>
              <IconButton
                className={classes.iconButton}
                onClick={() =>
                  showOrCloseMemberWorktimeData(
                    selectedMember ? selectedMember : undefined,
                  )
                }
              >
                <Clear nativeColor="white" />
              </IconButton>
            </span>
          </h2>
          <small>{selectedMember && selectedMember.email}</small>
          <br />
          <small>
            {selectedMember && selectedMember.phone
              ? selectedMember.phone
              : '-'}
          </small>
        </div>

        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <CustomTableCell className={classes.tableCellHeader} />
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.day}
                </CustomTableCell>

                {hourTypes &&
                  hourTypes.map(hourType => (
                    <CustomTableCell
                      key={hourType.name}
                      className={classes.tableCellHeader}
                    >
                      {hourType.name}:
                    </CustomTableCell>
                  ))}
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.total}:
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader} />
              </TableRow>
            </TableHead>
            <TableBody>
              {reports &&
                selectedMember.key &&
                reports[selectedMember.key].ALL.map(
                  workDate =>
                    workDate.key &&
                    this.getWorkTimeTableRow(
                      workDate,
                      selectedMember,
                      workDate.key,
                      workDate.month,
                      workDate.changeLog,
                    ),
                )}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  private getWorkTimeTableRow = (
    workDate: WorkingHoursWithKey,
    member: MemberWithKey,
    workDateKey: string,
    month: string,
    changeLog: ChangeLog[],
  ) => {
    const { hourTypes, companyId } = this.props;

    if (hourTypes) {
      const hoursByHourType: HoursByHourType = buildHoursByHourTypeObjectBase(
        hourTypes,
      );

      const notesByHourType: WorkTimeNotesByHourType = buildWorkNotesByHourTypeObjectBase(
        hourTypes,
      );

      workDate.data.forEach(d => {
        hourTypes.forEach((hType: HourTypeWithKey) => {
          if (hType.key && hType.key === d.hourtype) {
            hoursByHourType[hType.key] += d.time;
            notesByHourType[hType.key] = d.note;
          }
        });
      });

      if (workDate) {
        if (member.key) {
          return (
            <WorkTimeTableMemberRow
              hourTypes={hourTypes}
              workDate={workDateKey}
              month={month}
              companyId={companyId}
              memberId={member && member.key}
              hoursByHourType={hoursByHourType}
              notesByHourType={notesByHourType}
              changeLog={changeLog}
            />
          );
        }
      }
    }

    return undefined;
  };
}

export const buildWorkNotesByHourTypeObjectBase = (
  hourTypes: HourTypeWithKey[],
): WorkTimeNotesByHourType => {
  let notesByHourType: WorkTimeNotesByHourType = {};

  hourTypes.forEach((hTypes: HourTypeWithKey) => {
    if (hTypes.key) {
      notesByHourType = {
        ...notesByHourType,
        [hTypes.key]: '',
      };
    }
  });

  return notesByHourType;
};

export const buildHoursByHourTypeObjectBase = (
  hourTypes: HourTypeWithKey[],
): HoursByHourType => {
  let hoursByHourType: HoursByHourType = {};

  hourTypes.forEach((hTypes: HourTypeWithKey) => {
    if (hTypes.key) {
      hoursByHourType = {
        ...hoursByHourType,
        [hTypes.key]: 0,
      };
    }
  });

  return hoursByHourType;
};

export default withStyles(styles, { withTheme: true })(WorkTimeTableMember);
