import Paper from '@material-ui/core/Paper';
import { Schema, ShortUserInfo, User } from '@shared/schema';
import LoadingSpinner from 'components/LoadingSpinner';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import defaultUserImageUrl from 'static/User.png';
import AccountForm from '../components/AccountForm/AccountForm';
export interface AccountContainerProps extends Partial<DispatchProp<any>> {
  auth: any;
}

interface State {
  isLoading: boolean;
  account: User;
}

export class AccountContainer extends React.Component<
  AccountContainerProps,
  State
> {
  constructor(props: AccountContainerProps) {
    super(props);

    this.state = {
      isLoading: true,
      account: {
        id: '',
        displayName: '',
        email: '',
        photoURL: '',
        home: '',
      },
    };
  }

  public componentDidMount() {
    if (
      this.props.auth.appUser !== undefined &&
      this.props.auth.appUser.id !== undefined
    ) {
      firebaseApp
        .firestore()
        .collection(Schema.USERS)
        .doc(this.props.auth.appUser.id)
        .get()
        .then(snapshot => {
          const account: any = snapshot.data();
          if (account) {
            this.setState({
              isLoading: false,
              account,
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner data-test="loadingSpinner" />;
    }

    return (
      <div style={styles.container}>
        <Paper style={styles.pane}>
          <div style={styles.settings}>
            <div style={styles.avatar}>
              <img
                data-test="avatarImage"
                style={styles.avatar}
                src={
                  this.state.account && this.state.account.photoURL
                    ? this.state.account.photoURL
                    : defaultUserImageUrl
                }
              />
            </div>
            <div style={styles.meta}>
              <AccountForm
                onSubmit={this.handleSubmit}
                onUpdateAccount={this.handleAccountChange}
                initialValues={this.state.account}
                data-test="accountForm"
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }

  private handleAccountChange = (account: ShortUserInfo) => {
    this.setState({ account });
  };

  private handleSubmit = () => {
    firebaseApp
      .firestore()
      .collection(Schema.USERS)
      .doc(this.state.account.id)
      .update(this.state.account);
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<AccountContainerProps>,
) => {
  return {
    ...ownProps,
    auth: state.auth,
  };
};

const styles = {
  container: {
    width: '100%',
    display: '-webkit-flex',
    alignItems: 'center',
    paddingTop: '4rem',
    justifyContent: 'center',
  },
  pane: {
    display: 'flex',
    flexBasis: '80%',
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  avatar: {
    width: '100%',
    maxWidth: '13rem',
    height: 'auto',
    cursor: 'pointer',
  },
  meta: {
    flexBasis: '60%',
    marginBottom: '3rem',
    marginTop: '2rem',
  },
};

export default withRouter<any>(connect<any>(mapStateToProps)(AccountContainer));
