import { createStore } from "redux";

import { rootReducer } from "reducers";
import { isDevMode } from "utils/devUtils";

function configureStore() {
  let enhancer;
  if (isDevMode()) {
    const tools = require("redux-devtools-extension");
    enhancer = tools.composeWithDevTools();
  }
  return createStore(rootReducer, enhancer);
}

// pass an optional param to rehydrate state on app start
const store = configureStore();

// Hot Module Replacement API
declare let module: { hot: any };

/**
 * Dev time hot module replace support
 */
if (module.hot) {
  module.hot.accept("reducers", () =>
    store.replaceReducer(require("reducers").rootReducer)
  );
}

export default store;
