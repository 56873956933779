import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import { List } from '@material-ui/core';
import { Schema } from '@shared/schema';
import firebaseApp from 'firebaseApp';
import styles from './styles';
import UploadListItem from './UploadListItem';

export interface MultiUploadFileList {
  [key: string]: MultiUploadFile;
}

export interface MultiUploadFile {
  key: string;
  file?: Blob;
  fileName: string;
  mimeType: string;
  status: FileUploadStatus;
  url?: string;
}

export enum FileUploadStatus {
  DONE,
  INPROGRESS,
  FAILED,
  WAITING,
}

interface MultiUploadProps extends StyledComponentProps {
  onChange: (multiUploadFiles: MultiUploadFileList) => void;
  fileRefs: MultiUploadFileList;
  firestoreFilePath: string;
}

interface State {
  fileRefs: MultiUploadFileList;
}

class MultiUpload extends React.Component<MultiUploadProps, State> {
  constructor(props: MultiUploadProps) {
    super(props);
    this.state = {
      fileRefs: {},
    };
  }

  public componentWillReceiveProps(nextProps: MultiUploadProps) {
    if (nextProps.fileRefs !== this.state.fileRefs) {
      this.setState({
        fileRefs: nextProps.fileRefs,
      });
    }
  }

  public onFileStatusChange = (multiUploadFile: MultiUploadFile) => {
    const newFileRefs = { ...this.state.fileRefs };
    newFileRefs[multiUploadFile.fileName] = multiUploadFile;

    this.setState({
      fileRefs: newFileRefs,
    });

    this.props.onChange(newFileRefs);
  };

  public onFileDelete = (fileKey: string) => {
    const { firestoreFilePath } = this.props;
    const newFileRefs = { ...this.state.fileRefs };
    delete newFileRefs[fileKey];

    const storageRef = firebaseApp.storage().ref();
    storageRef
      .child(`${Schema.COMPANIES}/${firestoreFilePath}/${fileKey}`)
      .delete()
      .then(() => {
        this.props.onChange(newFileRefs);
      })
      .catch(error => {
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            this.props.onChange(newFileRefs);
            break;
        }
        console.error('File deletion failed @ MultiUpload', error.code);
      });

    const companyId = firestoreFilePath.substring(
      0,
      firestoreFilePath.indexOf('/'),
    );

    const key = firestoreFilePath.split('/').pop();
    const newFiles: object[] = [];
    for (const newFile in newFileRefs) {
      if (newFile) {
        const dataToBeSaved: object = {
          fileName: newFileRefs[newFile].fileName,
          key: newFileRefs[newFile].key,
          mimeType: newFileRefs[newFile].mimeType,
          url: newFileRefs[newFile].url,
        };
        newFiles.push(dataToBeSaved);
      }
    }
    const dataToBeChanged = {
      files: {
        newFiles,
      },
    };

    firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.JOBTYPES)
      .doc(key)
      .set({ files: dataToBeChanged }, { merge: true });
  };

  public render() {
    const { firestoreFilePath } = this.props;
    const { fileRefs } = this.state;

    return (
      <List>
        {Object.keys(fileRefs).map(key => {
          return (
            <UploadListItem
              key={fileRefs[key].fileName}
              firestoreFilePath={firestoreFilePath}
              fileRef={fileRefs[key]}
              onChange={this.onFileStatusChange}
              onDelete={this.onFileDelete}
            />
          );
        })}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MultiUpload);
