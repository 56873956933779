import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  StyledComponentProps,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import { MemberWithKey, TaskWithKey, TimerChangeLog } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import { roundToNext } from '@shared/utils/timeUtils';
import ACSelect from 'components/ACSelect';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { ApplicationState } from 'reducers';
import { getTasksTableRef, getUserTimerLogsDuration } from 'utils/tasksUtil';
import { TimerTotalFix } from '../../../../../../../../../../../../@shared/schema/index';
import { ACSelectValue } from '../../../../../../../../../../components/ACSelect/index';
import styles from './styles';

interface Props extends DispatchProp, StyledComponentProps {
  open: boolean;
  task: TaskWithKey;
  members: MemberWithKey[];
  onClose: () => void;
  onSave?: (updatedTask: TaskWithKey) => void;
  firebaseUser?: firebase.User;
  companyId?: string;
}

interface State {
  selectedUser?: MemberWithKey;
  newWorkingTime: number;
}

class ChangeWorkingTimeDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedUser: undefined,
      newWorkingTime: 0,
    };
  }

  public render() {
    const { open, task, members, classes = {} } = this.props;
    const { selectedUser, newWorkingTime } = this.state;

    let listOfTaskUsersIds: Array<string | undefined> = [];
    let filteredListOfTaskUserIds: string[] = [];

    // Check that if task is multi-user or just one member has many log records
    if (task.timerLog) {
      listOfTaskUsersIds = _.uniq(task.timerLog.map(log => log.memberId));
      filteredListOfTaskUserIds = listOfTaskUsersIds.filter(
        (i): i is string => i !== null && i !== undefined,
      );
    }

    const userList =
      filteredListOfTaskUserIds &&
      filteredListOfTaskUserIds.map(id => members.find(mem => mem.key === id));

    const filteredUserList: MemberWithKey[] = userList.filter(
      (i): i is MemberWithKey => i !== null,
    );

    if (filteredUserList.length > 0 && selectedUser === undefined) {
      this.setState({ selectedUser: filteredUserList[0] });
    }

    return (
      <Dialog open={open} onClose={this.onClose}>
        <DialogTitle>{i18n().ui.change_working_time_for_user}</DialogTitle>
        <DialogContent>
          {filteredUserList.length > 0 && (
            <div className={classes.contentContainer}>
              <FormControl>
                <ACSelect
                  options={filteredUserList.map(
                    user => user && { value: user.key, label: user.name },
                  )}
                  onChange={(selected: ACSelectValue) => {
                    const user = members.find(
                      mem => mem.key === selected.value,
                    );
                    this.setState({ selectedUser: user });
                  }}
                  value={{
                    label: (selectedUser && selectedUser.name) || '',
                    value: (selectedUser && selectedUser.key) || '',
                  }}
                />
              </FormControl>

              <div className={classes.workingTimeValuesContainer}>
                <div className={classes.previousWorkingTimeContainer}>
                  <Typography>{i18n().ui.previous_working_time}</Typography>
                  <Typography className={classes.previousWorkingTimeValue}>
                    {momentDurationFormat(
                      roundToNext(
                        getUserTimerLogsDuration(
                          task,
                          false,
                          this.state.selectedUser &&
                            this.state.selectedUser.key,
                        ),
                        'minute',
                      ),
                      DurationFormatPreset.HHMM,
                    )}
                  </Typography>
                </div>

                <div className={classes.newWorkingTimeContainer}>
                  <Typography>{i18n().ui.new_working_time}</Typography>
                  <Typography
                    component={'span'}
                    className={classes.newWorkingTimeValue}
                  >
                    {
                      <TextField
                        className={classes.textField}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                          onChange: this.handleChangeNewWorkingTime,
                        }}
                        value={momentDurationFormat(
                          roundToNext(
                            (newWorkingTime && Number(newWorkingTime)) || 0,
                            'minute',
                          ),
                          DurationFormatPreset.HHMM,
                        )}
                      />
                    }
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>{i18n().ui.cancel}</Button>
          <Button onClick={this.onSave}>{i18n().ui.save}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onSave = () => {
    const { selectedUser, newWorkingTime } = this.state;
    const { task, firebaseUser, companyId } = this.props;

    if (selectedUser && selectedUser.key) {
      const oldTimerTotalFixed = getUserTimerLogsDuration(
        task,
        false,
        selectedUser.key,
      );

      const timerTotalFixedValue =
        moment.duration(newWorkingTime).asMilliseconds() -
        getUserTimerLogsDuration(task, true, selectedUser.key);

      //// Updating task.timerTotalFix

      const newTimerTotalFixedEntry: TimerTotalFix = {
        memberId: selectedUser.key,
        value: timerTotalFixedValue,
      };

      if (task.timerTotalFixed) {
        const foundTimerTotalFixed = task.timerTotalFixed.find(
          timer => timer.memberId === selectedUser.key,
        );

        // Updating existing entry for selected user
        if (foundTimerTotalFixed) {
          const indexOfFoundTimerTotalFixed =
            task.timerTotalFixed.indexOf(foundTimerTotalFixed);

          task.timerTotalFixed[indexOfFoundTimerTotalFixed] =
            newTimerTotalFixedEntry;
        } else {
          // Pushing new entry of timerTotalFix

          task.timerTotalFixed = [
            ...task.timerTotalFixed,
            newTimerTotalFixedEntry,
          ];
        }
      } else {
        // Creating new timerTotalFix
        task.timerTotalFixed = [newTimerTotalFixedEntry];
      }

      //// Updating task.timerChangeLog

      const timerChangeLogEntry: TimerChangeLog = {
        memberName: (firebaseUser && firebaseUser.displayName) || undefined,
        memberId: (firebaseUser && firebaseUser.uid) || '',
        editedMemberId: selectedUser.key || '',
        timestamp: moment().valueOf(),
        oldValue: roundToNext(oldTimerTotalFixed, 'minute'),
        newValue: roundToNext(newWorkingTime, 'minute'),
      };

      if (task.timerChangeLog) {
        task.timerChangeLog.push(timerChangeLogEntry);
      } else {
        task.timerChangeLog = [timerChangeLogEntry];
      }

      //// Saving changes
      try {
        getTasksTableRef(companyId).doc(task.key).update(task);
      } catch (error) {
        console.error('Failed to update task time change', error);
      }
    }

    this.onClose();
  };

  private handleChangeNewWorkingTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({
      newWorkingTime: moment.duration(event.target.value).asMilliseconds(),
    });
  };

  private onClose = () => {
    this.props.onClose();
    this.setState({ selectedUser: undefined });
  };
}

/**
 * Creates and returns MaskedInput component
 * @param props
 */
const TextMaskCustom = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      keepCharPositions
      showMask
      guide
    />
  );
};

/**
 * Handles masking
 * If there is more than 4 digits in textfields use hundreds hours masking
 * @param userInput
 */
const mask = (userInput: string) => {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join('').length;
  }

  if (numberLength > 4) {
    return [/\d/, /\d/, /\d/, ':', /\d/, /\d/];
  } else {
    return [/\d/, /\d/, ':', /\d/, /\d/];
  }
};

const mapStateToProps = (state: ApplicationState, ownProps: Partial<Props>) => {
  return {
    ...ownProps,
    companyId: state.company.companyId,
    firebaseUser: state.auth.firebaseUser,
  } as Props;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ChangeWorkingTimeDialog),
);
