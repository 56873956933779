import { Checkbox, Grid, IconButton } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { VisibilityOff } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { i18n } from '@shared/locale/index';
import classNames from 'classnames';
import * as React from 'react';
import styles from './styles';

interface ListItemProps extends StyledComponentProps {
  id: string;
  name: string;
  hidden?: boolean;
  onModify?: (id: string) => void;
  onHide?: (id: string) => void;
  onDelete?: (id: string) => void;
  onSelect?: (id: string, selected: boolean) => void;
  isAdd?: boolean;
  selectActive?: boolean;
  selected?: boolean;
}

class SettingListItem extends React.Component<ListItemProps, any> {
  public render() {
    const { classes = {}, id, name, selectActive, selected } = this.props;
    return (
      <Grid item xs={4} md={3} className={classes.root}>
        <div
          className={classNames(
            classes.container,
            this.props.hidden && classes.hiddenConainer,
          )}
        >
          {selectActive && (
            <Checkbox
              checked={selected}
              value={id}
              onClick={this.handleCheckClick()}
            />
          )}
          <div
            className={classNames(
              classes.nameContainer,
              this.props.hidden && classes.hiddenElement,
            )}
            title={name}
          >
            {name}
          </div>
          <div
            className={classNames(
              classes.buttonContainer,
              this.props.hidden && classes.hiddenElement,
            )}
          >
            <IconButton
              className={classes.button}
              aria-label={i18n().ui.modify}
              onClick={this.handleModifyClick()}
            >
              <EditIcon />
            </IconButton>
            {this.props.hidden !== undefined && (
              <IconButton
                className={classes.button}
                aria-label={'hide'}
                onClick={this.handleHideClick()}
              >
                <VisibilityOff />
              </IconButton>
            )}
            <IconButton
              className={classes.button}
              aria-label={i18n().ui.delete}
              onClick={this.handleDeleteClick()}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </Grid>
    );
  }

  private handleCheckClick = () => (event: any) => {
    this.props.onSelect &&
      this.props.onSelect(this.props.id, event.target.checked);
  };

  private handleModifyClick = () => (event: any) => {
    event.preventDefault();
    this.props.onModify && this.props.onModify(this.props.id);
  };
  private handleHideClick = () => (event: any) => {
    event.preventDefault();
    this.props.onHide && this.props.onHide(this.props.id);
  };
  private handleDeleteClick = () => (event: any) => {
    event.preventDefault();
    this.props.onDelete && this.props.onDelete(this.props.id);
  };
}

export default withStyles(styles, { withTheme: true })(SettingListItem);
