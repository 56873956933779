import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  tabs: {
    background: '#E5E5E5',
  },
  bar: {
    height: 'fit-content',
    padding: '10px 16px',
    background: '#F5F5F5',
    position: 'relative',
  },
  button: {
    marginRight: '20px',
    background: '#FFFFFF',
    textTransform: 'none',
    padding: '2px 10px 2px 10px',
  },
  leftIcon: {
    marginRight: '6px',
  },
  privacyLinkContainer: {
    textAlign: 'right',
    display: 'inline',
    position: 'absolute',
    right: '10px',
  },
});

export default styles;
