import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchIcon from '@material-ui/icons/Search';
import { i18n } from '@shared/locale/index';
import {
  CompanyId,
  DialogStatus,
  FileReference,
  JobType,
  JobTypeQualifications,
  JobTypeWithKey,
  Qualification,
  QualificationWithKey,
  Schema,
} from '@shared/schema';
import classNames from 'classnames';
import CheckboxGrid from 'components/CheckboxGrid';
import { CheckboxGridItem } from 'components/CheckboxGrid/CheckboxGrid';
import SettingsListItem from 'containers/Private/Settings/components/SettingsListItem';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import MultiUpload, {
  FileUploadStatus,
  MultiUploadFile,
  MultiUploadFileList,
} from '../../../../../Timeline/components/MultiUpload';
import styles from './styles';

interface JobTypeSettingProps extends StyledComponentProps {
  companyId: CompanyId | undefined;
}

interface State {
  jobTypes: JobTypeWithKey[];
  deleteJobType: JobTypeWithKey | undefined;
  hideJobType?: JobTypeWithKey;
  modifyJobType: JobTypeWithKey | undefined;
  newJobType: JobTypeWithKey | undefined;
  filter: string;
  selectMultiple: boolean;
  selectedItems: SelectedJobTypes;
  fileUploads: MultiUploadFileList;
  sessionFileList: MultiUploadFileList;
  deleteMultiple: boolean;
  qualifications: QualificationWithKey[];
  jobtypeError: string;
}

interface SelectedJobTypes {
  [key: string]: boolean;
}

/**
 * JobType update data with deletable fields
 */
interface JobTypeUpdate {
  name: string;
  serviceNumber?: string | firebase.firestore.FieldValue;
  note?: string | firebase.firestore.FieldValue;
  files?: FileReference[] | firebase.firestore.FieldValue;
  requiredQualifications?:
    | JobTypeQualifications
    | firebase.firestore.FieldValue;
  key: string;
}

enum MultipleHideUnhideButtonId {
  hideSelectedButton = 'hideSelectedButton',
  unhideSelectedButton = 'unhideSelectedButton',
}

class JobTypeSettings extends React.Component<JobTypeSettingProps, State> {
  private unsubscribeJobtypes: () => void;
  private unsubscribeQualifications: () => void;

  private newJobtypeRef: firebase.firestore.DocumentReference;

  constructor(props: JobTypeSettingProps) {
    super(props);
    this.state = {
      jobTypes: [],
      newJobType: undefined,
      deleteJobType: undefined,
      modifyJobType: undefined,
      filter: '',
      selectMultiple: false,
      selectedItems: {},
      deleteMultiple: false,
      qualifications: [],
      fileUploads: {},
      sessionFileList: {},
      jobtypeError: '',
    };
  }

  public componentDidMount() {
    const { companyId } = this.props;
    if (companyId) {
      this.unsubscribeJobtypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.JOBTYPES)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const jobTypes: JobType[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const jobType = data.data() as JobTypeWithKey;
            jobType.key = data.id;

            jobTypes.push({
              ...jobType,
            });
          });

          this.sort(jobTypes);
          this.setState({
            jobTypes,
          });
        });

      this.unsubscribeQualifications = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.QUALIFICATIONS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const qualifications: Qualification[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const qualification = data.data() as QualificationWithKey;
            qualification.key = data.id;
            qualifications.push({
              ...qualification,
            });
          });

          this.sort(qualifications);

          this.setState({
            qualifications,
          });
        });
    } else {
      console.error('Company id is not set @JobTypeSettings');
    }
  }
  public sort(dataToSort: Qualification[] | JobTypeWithKey[]) {
    dataToSort.sort((a, b) => {
      if (!a.name.toLowerCase() || !b.name.toLowerCase()) {
        return 0;
      }
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }
  public componentWillUnmount() {
    this.unsubscribeJobtypes && this.unsubscribeJobtypes();
    this.unsubscribeQualifications && this.unsubscribeQualifications();
  }

  public onUploadChange = () => (event: any) => {
    const fileToUpload = event.target.files[0];
    const originalName = fileToUpload.name;
    const mimeType = fileToUpload.type;

    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)], {
        type: mimeType,
      });
      const fileUploads = {
        ...this.state.fileUploads,
      };
      fileUploads[originalName] = {
        key: originalName,
        fileName: originalName,
        mimeType,
        file: blob,
        status: FileUploadStatus.WAITING,
      } as MultiUploadFile;
      this.setState({
        fileUploads,
      });
    };
    reader.readAsArrayBuffer(fileToUpload);
  };

  public onModifyUploadChange = () => (event: any) => {
    const fileToUpload = event.target.files[0];
    const originalName = fileToUpload.name;
    const mimeType = fileToUpload.type;

    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)], {
        type: mimeType,
      });
      const fileUploads = {
        ...this.state.fileUploads,
      };
      fileUploads[originalName] = {
        key: originalName,
        fileName: originalName,
        mimeType,
        file: blob,
        status: FileUploadStatus.WAITING,
      } as MultiUploadFile;

      const sessionUploads = {
        ...this.state.sessionFileList,
      };

      sessionUploads[originalName] = {
        key: originalName,
        fileName: originalName,
        mimeType,
        file: blob,
        status: FileUploadStatus.WAITING,
      } as MultiUploadFile;

      this.setState({
        fileUploads,
        sessionFileList: sessionUploads,
      });
    };
    reader.readAsArrayBuffer(fileToUpload);
  };

  public isNewFormValid(): boolean {
    const { fileUploads, newJobType } = this.state;
    // Check if uploads still going on
    if (fileUploads && Object.keys(fileUploads).length > 0) {
      for (const key of Object.keys(fileUploads)) {
        if (fileUploads[key].status !== FileUploadStatus.DONE) {
          return false;
        }
      }
    }

    const newValid = newJobType !== undefined && newJobType.name !== '';
    return newValid;
  }

  public isModifiedFormValid(): boolean {
    const { fileUploads } = this.state;
    // Check if uploads still going on
    if (fileUploads && Object.keys(fileUploads).length > 0) {
      for (const key of Object.keys(fileUploads)) {
        if (fileUploads[key].status !== FileUploadStatus.DONE) {
          return false;
        }
      }
    }
    return true;
  }

  public onMultiUploadChange = (fileUploads: MultiUploadFileList) => {
    this.setState({
      fileUploads,
    });
  };

  public render() {
    const { classes = {} } = this.props;
    const {
      jobTypes,
      newJobType,
      modifyJobType,
      deleteJobType,
      filter,
      selectMultiple,
      selectedItems,
      deleteMultiple,
      qualifications,
      fileUploads,
    } = this.state;

    return (
      <div className={classNames(classes.container)}>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.bar}>
            <FormControl className={classes.topFormControl}>
              <TextField
                id="search"
                placeholder={i18n().ui.search_jobtypes}
                className={classes.searchInput}
                margin="normal"
                onChange={this.handleFilterChange()}
              />
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleAddNewJobType}
              >
                <AddIcon className={classes.icon} />
                {i18n().ui.add_new}
              </Button>
              <Button
                variant="contained"
                className={classNames(
                  classes.button,
                  selectMultiple ? classes.selected : '',
                )}
                onClick={this.handleSelectMultiple}
              >
                <DoneAllIcon className={classes.icon} />
                {i18n().ui.select_multiple}
              </Button>
              {selectMultiple && (
                <>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={this.handleDeleteMultiple}
                  >
                    <DeleteIcon className={classes.icon} />
                    {i18n().ui.delete_selected}
                  </Button>

                  <Button
                    id={MultipleHideUnhideButtonId.hideSelectedButton}
                    variant="contained"
                    className={classes.button}
                    onClick={this.handleMultipleJobTypeHide}
                  >
                    <VisibilityOff className={classes.icon} />
                    {i18n().ui.hide_selected}
                  </Button>

                  <Button
                    id={MultipleHideUnhideButtonId.unhideSelectedButton}
                    variant="contained"
                    className={classes.button}
                    onClick={this.handleMultipleJobTypeHide}
                  >
                    <Visibility className={classes.icon} />
                    {i18n().ui.unhide_selected}
                  </Button>
                </>
              )}
              {/* selectMultiple && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  <EyeIcon className={classes.icon} />
                  Piilota valitut
                </Button>
              ) */}
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.itemContainer}>
            <Grid container spacing={24}>
              {jobTypes.map((item: JobTypeWithKey) => {
                if (
                  filter.length === 0 ||
                  item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
                ) {
                  return (
                    <SettingsListItem
                      key={item.key}
                      id={item.key!}
                      name={item.name}
                      hidden={item.hidden !== undefined ? item.hidden : false}
                      onModify={this.handleJobtypeModify}
                      onHide={this.handleJobtypeHide}
                      onDelete={this.handleJobtypeDelete}
                      selectActive={selectMultiple}
                      onSelect={this.handleItemSelect}
                      selected={selectedItems[item.key!]}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={newJobType !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.add_jobtype}
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={i18n().ui.name}
                  type="text"
                  value={newJobType ? newJobType.name : ''}
                  onChange={this.handleNewDialogNameChange()}
                  fullWidth
                  error={this.state.jobtypeError !== ''}
                  helperText={this.state.jobtypeError}
                />
              </ListItem>
              <ListItem>
                <TextField
                  margin="dense"
                  id="note"
                  label={i18n().ui.instructions}
                  type="text"
                  value={newJobType ? newJobType.note : ''}
                  onChange={e => {
                    const newNote: any = {
                      ...newJobType,
                      note: e.target.value,
                    };
                    this.setState({
                      newJobType: newNote,
                    });
                  }}
                  fullWidth
                  multiline
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={i18n().ui.task_files} />
                <input
                  accept="image/*,.pdf"
                  className={classNames(classes.input, classes.displayNone)}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={this.onUploadChange()}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span" className={classes.button}>
                    {i18n().ui.search}
                    {<SearchIcon className={classes.rightIcon} />}
                  </Button>
                </label>
              </ListItem>
              <ListItem>
                <FormControl className={classes.uploadFormControl}>
                  <MultiUpload
                    fileRefs={fileUploads}
                    onChange={this.onMultiUploadChange}
                    firestoreFilePath={`${this.props.companyId}/${
                      Schema.JOBTYPES
                    }/${newJobType ? newJobType.id : 'not_set'}`}
                  />
                </FormControl>
              </ListItem>
              {qualifications && qualifications.length > 0 && (
                <>
                  <ListItem>
                    <Typography variant="subtitle1" className={classes.title}>
                      {i18n().ui.required_qualifications}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <CheckboxGrid
                      items={qualifications.map(
                        (qualification): CheckboxGridItem => ({
                          key: qualification.key!,
                          label: qualification.name,
                          checked: this.isCheckedQualifications(
                            newJobType,
                            qualification,
                          ),
                        }),
                      )}
                      onChange={this.handleDialogQualificationNew}
                    />
                  </ListItem>
                </>
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.NEW)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleNewDialogConfirm}
              disabled={!this.isNewFormValid()}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={modifyJobType !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.modify_jobtype}
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={i18n().ui.name}
                  type="text"
                  value={modifyJobType ? modifyJobType.name : ''}
                  onChange={this.handleModifyDialogNameChange()}
                  fullWidth
                  error={this.state.jobtypeError !== ''}
                  helperText={this.state.jobtypeError}
                />
              </ListItem>
              <ListItem>
                <TextField
                  autoFocus
                  margin="dense"
                  id="note"
                  label={i18n().ui.instructions}
                  type="text"
                  value={modifyJobType ? modifyJobType.note : ''}
                  onChange={e => {
                    const newNote: any = {
                      ...modifyJobType,
                      note: e.target.value,
                    };
                    this.setState({
                      modifyJobType: newNote,
                    });
                  }}
                  fullWidth
                  multiline
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={i18n().ui.task_files} />
                <input
                  accept="image/*,.pdf"
                  className={classNames(classes.input, classes.displayNone)}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={this.onModifyUploadChange()}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span" className={classes.button}>
                    {i18n().ui.search}
                    {<SearchIcon className={classes.rightIcon} />}
                  </Button>
                </label>
              </ListItem>
              <ListItem>
                <FormControl className={classes.uploadFormControl}>
                  <MultiUpload
                    fileRefs={fileUploads}
                    onChange={this.onMultiUploadChange}
                    firestoreFilePath={`${this.props.companyId}/${
                      Schema.JOBTYPES
                    }/${modifyJobType ? modifyJobType.key : 'not_set'}`}
                  />
                </FormControl>
              </ListItem>
              {modifyJobType && qualifications && qualifications.length > 0 && (
                <>
                  <ListItem>
                    <Typography variant="subtitle1" className={classes.title}>
                      {i18n().ui.required_qualifications}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <CheckboxGrid
                      items={qualifications.map(
                        (qualification): CheckboxGridItem => ({
                          key: qualification.key!,
                          label: qualification.name,
                          checked: this.isCheckedQualifications(
                            modifyJobType,
                            qualification,
                          ),
                        }),
                      )}
                      onChange={this.handleDialogQualifChange}
                    />
                  </ListItem>
                </>
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.MODIFY)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleModifyDialogConfirm}
              disabled={!this.isModifiedFormValid()}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteJobType !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_jobtype_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.DELETE)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteMultiple}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_selected_jobtypes_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose(DialogStatus.DELETEMULTIPLE)}
            >
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteMultipleDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  private handleNewDialogNameChange = () => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newJobType: JobTypeWithKey = this.state.newJobType
      ? { ...this.state.newJobType }
      : { name: '' };
    newJobType.name = e.target.value;
    this.setState({
      jobtypeError: '',
      newJobType,
    });
  };
  private handleModifyDialogNameChange = () => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const modifyJobType: JobTypeWithKey = this.state.modifyJobType
      ? { ...this.state.modifyJobType }
      : { name: '' };
    modifyJobType.name = e.target.value;
    this.setState({
      jobtypeError: '',
      modifyJobType,
    });
  };

  private isCheckedQualifications = (
    jobType: JobTypeWithKey | undefined,
    qualification: QualificationWithKey,
  ): boolean => {
    return jobType &&
      jobType.requiredQualifications &&
      jobType.requiredQualifications[qualification.key!]
      ? true
      : false;
  };

  private handleDialogQualifChange = (items: CheckboxGridItem[]) => {
    if (!this.state.modifyJobType) {
      return;
    }

    const requiredQualifications = {};
    items.forEach(item => {
      if (item.checked) {
        requiredQualifications[item.key] = true;
      }
    });

    const modifyJobType: JobTypeWithKey = {
      ...this.state.modifyJobType,
      requiredQualifications,
    };

    this.setState({ modifyJobType });
  };

  private handleDialogQualificationNew = (items: CheckboxGridItem[]) => {
    if (!this.state.newJobType) {
      return;
    }

    const requiredQualifications = {};
    items.forEach(item => {
      if (item.checked) {
        requiredQualifications[item.key] = true;
      }
    });

    const newJobType: JobTypeWithKey = {
      ...this.state.newJobType,
      requiredQualifications,
    };

    this.setState({ newJobType });
  };

  private handleSelectMultiple = () => {
    this.setState({
      selectMultiple: !this.state.selectMultiple,
      selectedItems: {},
    });
  };

  private handleItemSelect = (jobtypeKey: string, selected: boolean) => {
    const newSelectedItems = this.state.selectedItems;
    if (selected) {
      newSelectedItems[jobtypeKey] = true;
    } else {
      delete newSelectedItems[jobtypeKey];
    }

    this.setState({
      selectedItems: newSelectedItems,
      sessionFileList: {},
    });
  };

  private handleFilterChange = () => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({
      filter: e.target.value,
    });
  };

  /**
   * Creating new object to newJobType to make the dialog visible to make new jobtype
   */
  private handleAddNewJobType = () => {
    const ref = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.JOBTYPES)
      .doc();
    this.newJobtypeRef = ref;
    this.setState({
      newJobType: {
        name: '',
        note: '',
        key: ref.id,
      },
    });
  };

  /**
   * Assigning selected elemento to state to be modified and the dialog to be visible
   */
  private handleJobtypeModify = (jobTypeKey: string) => {
    const jobtypeToModify = this.getJobTypeByKey(jobTypeKey);
    const uploadFilesList: MultiUploadFileList = {};
    if (jobtypeToModify && jobtypeToModify.files) {
      for (const file of jobtypeToModify.files) {
        const multiUploadFile: MultiUploadFile = {
          fileName: file.fileName,
          file: undefined,
          key: file.key,
          mimeType: file.mimeType,
          status: FileUploadStatus.DONE,
          url: file.url,
        };
        uploadFilesList[file.key] = multiUploadFile;
      }
    }

    this.setState({
      modifyJobType: jobtypeToModify,
      fileUploads: uploadFilesList,
      sessionFileList: {},
    });
  };

  /**
   * Assigning selected elemento to state to be deleted and the dialog to be visible
   */
  private handleJobtypeDelete = (jobTypeKey: string) => {
    this.setState({
      deleteJobType: this.getJobTypeByKey(jobTypeKey),
    });
  };

  private handleDeleteMultiple = () => {
    this.setState({
      deleteMultiple: true,
    });
  };

  private handleJobtypeHide = async (jobTypeKey: string) => {
    const jobtypeToHide = this.getJobTypeByKey(jobTypeKey);
    if (jobtypeToHide) {
      const jobtypeToUpdate = jobtypeToHide;
      delete jobtypeToUpdate.key;
      try {
        const hidden =
          jobtypeToHide.hidden !== undefined ? !jobtypeToHide.hidden : true;
        await firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.JOBTYPES)
          .doc(jobTypeKey)
          .update({ ...jobtypeToUpdate, hidden });
      } catch (err) {
        console.error(err);
      }
    }
  };

  private handleMultipleJobTypeHide = (e: React.MouseEvent) => {
    const jobTypes = [...this.state.jobTypes];
    const batch = firebaseApp.firestore().batch();
    let hidden = Boolean();
    switch (e.currentTarget.id) {
      case MultipleHideUnhideButtonId.hideSelectedButton:
        hidden = true;
        break;
      case MultipleHideUnhideButtonId.unhideSelectedButton:
        hidden = false;
        break;
      default:
        return;
    }
    this.setState({
      selectMultiple: false,
    });
    jobTypes
      .filter(jobType => {
        return jobType.key
          ? this.state.selectedItems[jobType.key] !== undefined
          : false;
      })
      .forEach(jobType => {
        const jobTypeKey = jobType.key;
        delete jobType.key;
        const jobTypeRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.JOBTYPES)
          .doc(jobTypeKey);

        batch.update(jobTypeRef, { hidden });
      });
    batch.commit().catch(err => {
      console.error(err);
    });
  };

  private handleNewDialogConfirm = () => {
    if (!this.newJobtypeRef || !this.state.newJobType) {
      return;
    }

    // Needs to be 'any' for firebase.firestore.FieldValue
    const newJobType: any = { ...this.state.newJobType };

    const { fileUploads } = this.state;

    const attachments: FileReference[] = [];

    for (const key in fileUploads) {
      if (fileUploads[key].status === FileUploadStatus.DONE) {
        attachments.push({
          key: fileUploads[key].key,
          fileName: fileUploads[key].fileName,
          mimeType: fileUploads[key].mimeType,
          url: fileUploads[key].url!,
        });
      }
    }

    if (attachments.length > 0) {
      newJobType.files = attachments;
    }

    if (!newJobType.note || newJobType.note.length === 0) {
      delete newJobType.note;
    }

    if (
      !newJobType.requiredQualifications ||
      Object.keys(newJobType.requiredQualifications).length === 0
    ) {
      delete newJobType.requiredQualifications;
    }
    const jobType = this.state.jobTypes.find(
      job => job.name === newJobType.name,
    );
    if (jobType) {
      this.setState({ jobtypeError: i18n().ui.jobtype_already_exists });
    } else {
      this.newJobtypeRef
        .set(newJobType)
        .then(() =>
          this.setState({
            newJobType: undefined,
            fileUploads: {},
            jobtypeError: '',
          }),
        )
        .catch(error =>
          console.error(
            'Creating new jobtype failed for some magical reason',
            error,
          ),
        );
    }
  };

  private handleModifyDialogConfirm = () => {
    const { modifyJobType, fileUploads } = this.state;

    if (!modifyJobType || !modifyJobType.key) {
      return;
    }

    const files: FileReference[] = [];
    for (const key in fileUploads) {
      if (fileUploads[key].status === FileUploadStatus.DONE) {
        files.push({
          key: fileUploads[key].key,
          fileName: fileUploads[key].fileName,
          mimeType: fileUploads[key].mimeType,
          url: fileUploads[key].url!,
        });
      }
    }

    const jobTypeUpdate: JobTypeUpdate = {
      ...modifyJobType,
      key: modifyJobType.key,
      files: files.length > 0 ? files : firebase.firestore.FieldValue.delete(),

      note:
        modifyJobType.note && modifyJobType.note.length > 0
          ? modifyJobType.note
          : firebase.firestore.FieldValue.delete(),

      requiredQualifications:
        modifyJobType.requiredQualifications &&
        Object.keys(modifyJobType.requiredQualifications).length > 0
          ? modifyJobType.requiredQualifications
          : firebase.firestore.FieldValue.delete(),
    };

    const jobTypeKey = modifyJobType.key;

    firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.JOBTYPES)
      .doc(jobTypeKey)
      .update(jobTypeUpdate)
      .then(() =>
        this.setState({
          modifyJobType: undefined,
          fileUploads: {},
          jobtypeError: '',
        }),
      )
      .catch(error => console.error('Jobtype modification failed.', error));
  };

  private handleDeleteDialogConfirm = async () => {
    const { deleteJobType } = this.state;

    if (deleteJobType && deleteJobType.key) {
      const doc = await firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.JOBTYPES)
        .doc(deleteJobType.key)
        .get();

      const data = doc.data();
      // Deleting all files from storage.
      if (data && data.files) {
        const files = data.files;
        const storageRef = firebaseApp.storage().ref();
        for (const file of files) {
          storageRef
            .child(
              `${Schema.COMPANIES}/${this.props.companyId}/${Schema.JOBTYPES}/${deleteJobType.key}/${file.fileName}`,
            )
            .delete()
            .catch(error => {
              console.error('Error: ', error);
            });
        }
      }
      // Deleting all data from firestore.
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.JOBTYPES)
        .doc(deleteJobType.key)
        .delete()
        .then(() => {
          this.setState({
            deleteJobType: undefined,
          });
        })
        .catch(error => {
          console.error('Delete failed for some magical reason', error);
        });
    }
  };

  private handleDeleteMultipleDialogConfirm = async () => {
    const { selectedItems } = this.state;
    const batch = firebaseApp.firestore().batch();

    // Deleting all files from selected jobtypes.
    for (const key in selectedItems) {
      if (key) {
        const doc = await firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.JOBTYPES)
          .doc(key)
          .get();

        const data = doc.data();

        if (data && data.files) {
          const files = data.files;
          const storageRef = firebaseApp.storage().ref();
          for (const file of files) {
            storageRef
              .child(
                `${Schema.COMPANIES}/${this.props.companyId}/${Schema.JOBTYPES}/${key}/${file.fileName}`,
              )
              .delete()
              .catch(error => {
                console.error('Error: ', error);
              });
          }
        }
      }
    }

    for (const key in selectedItems) {
      if (selectedItems[key]) {
        const itemRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.JOBTYPES)
          .doc(key);

        batch.delete(itemRef);
      }
    }

    batch
      .commit()
      .then(() => {
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      })
      .catch(error => {
        console.error(
          'Something went wrong while deleting multiple @ JobTypesSettings',
          error,
        );
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      });
  };

  /**
   * Handling dialog closes
   */
  private handleDialogClose = (dialogStatus: DialogStatus) => () => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        // Deleting folder from storage if user doesn't save the form
        if (this.newJobtypeRef && this.state.fileUploads) {
          const storageRef = firebaseApp.storage().ref();
          const files = this.state.fileUploads;
          const keyNames = Object.keys(files);
          keyNames.forEach(key => {
            storageRef
              .child(
                `${Schema.COMPANIES}/${this.props.companyId}/${Schema.JOBTYPES}/${this.newJobtypeRef.id}/${key}`,
              )
              .delete()
              .catch(error => {
                console.error('Error: ', error);
              });
          });
          // keyNames = [];
        }
        this.setState({
          newJobType: undefined,
          fileUploads: {},
          sessionFileList: {},
          jobtypeError: '',
        });
        break;
      case DialogStatus.MODIFY:
        if (this.state.sessionFileList && this.state.modifyJobType) {
          const storageRef = firebaseApp.storage().ref();
          const files = this.state.sessionFileList;
          const keyNames = Object.keys(files);

          keyNames.forEach(key => {
            storageRef
              .child(
                `${Schema.COMPANIES}/${this.props.companyId}/${
                  Schema.JOBTYPES
                }/${this.state.modifyJobType!.key}/${key}`,
              )
              .delete()
              .catch(error => {
                console.error('Error: ', error);
              });
          });
        }

        this.setState({
          modifyJobType: undefined,
          sessionFileList: {},
          jobtypeError: '',
        });

        break;
      case DialogStatus.DELETE:
        this.setState({
          deleteJobType: undefined,
        });
        break;
      case DialogStatus.DELETEMULTIPLE:
        this.setState({
          deleteMultiple: false,
        });
        break;
    }
  };
  private getJobTypeByKey = (key: string) => {
    for (const jobType of this.state.jobTypes) {
      if (jobType.key === key) {
        return {
          ...jobType,
          requiredQualifications: { ...jobType.requiredQualifications },
        } as JobTypeWithKey;
      }
    }
    return undefined;
  };
}
export default withStyles(styles, { withTheme: true })(JobTypeSettings);
