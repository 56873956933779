import {
  Button,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  StyledComponentProps,
  StyleRules,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';

import { i18n } from '@shared/locale';
import { CompanyId, Customer, CustomerWithKey, Schema } from '@shared/schema';
import classNames from 'classnames';
import HeaderBar from 'components/HeaderBar';
import LoadingSpinner from 'components/LoadingSpinner';
import SortingHeadTableCell from 'components/SortingHeadTableCell/SortingHeadTableCell';
import firebaseApp from 'firebaseApp';
import 'react-table/react-table.css';
import { ApplicationState } from 'reducers';
import { newCustomer, updateCustomer } from 'reducers/customer/customerActions';
import tableLayout from 'theme/styles/table';
import CustomTableCell from 'theme/styles/tableCell';
import Editor from '../components/Editor';

export interface CustomerProps
  extends Partial<DispatchProp<any>>,
    StyledComponentProps {
  editing?: boolean;
  companyId: CompanyId;
  editedCustomer?: Customer;
}

interface State {
  isLoading: boolean;
  customers: Customer[];
  page: number;
  rowsPerPage: number;
  filter: string;
  order: OrderOptions;
  orderBy: OrderByValues;
}

export enum OrderByValues {
  customerName = 'customerName',
  customerAddress = 'customerAddress',
  contanctPerson = 'contactPerson',
  customerEmail = 'customerEmail',
}

export enum OrderOptions {
  asc = 'asc',
  desc = 'desc',
}

export class Customers extends React.Component<CustomerProps, State> {
  public state: State = {
    isLoading: true,
    customers: [],
    page: 0,
    rowsPerPage: 50,
    filter: '',
    order: OrderOptions.asc,
    orderBy: OrderByValues.customerName,
  };
  private unsubscribeCustomers: () => void;

  public componentDidMount() {
    this.unsubscribeCustomers = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.CUSTOMERS)
      .onSnapshot(snapshot => {
        const customers: Customer[] = [];
        snapshot.forEach(doc => {
          const data = doc.data() as CustomerWithKey;
          data.key = doc.id;
          customers.push(data);
        });
        this.setState(
          {
            isLoading: false,
            customers,
          },
          () => {
            this.sortCustomerList();
          },
        );
      });
  }

  public componentWillUnmount() {
    this.unsubscribeCustomers && this.unsubscribeCustomers();
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    const { classes = {} } = this.props;
    const { customers, page, rowsPerPage } = this.state;

    const filter = this.state.filter.trim().toLowerCase();

    return (
      <>
        <HeaderBar
          searchOnChangeHandler={this.searchOnChangeHandler}
          searchComponentPlaceholder={i18n().ui.search_customers}
        >
          <Button
            className={classes.addNewButton}
            onClick={this.handleFabButtonClick}
          >
            {i18n().ui.add_new}
          </Button>
        </HeaderBar>
        <div className={classes.container}>
          <div className={classes.tableBody}>
            <Table>
              <TableHead>
                <TableRow
                  classes={{ root: classes.tableHead }}
                  data-test="customer-table-head-row"
                >
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellCustomerName"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.customerName}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.customer}
                  />
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellCustomerAddress"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.customerAddress}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.address}
                  />
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellContactPerson"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.contanctPerson}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.contact_person}
                  />
                  <CustomTableCell className={classes.tableCellHeader}>
                    {i18n().ui.phone}
                  </CustomTableCell>
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellCustomerEmail"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.customerEmail}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.email}
                  />
                </TableRow>
              </TableHead>

              <TableBody>
                {(filter === ''
                  ? customers
                  : customers.filter(
                      customer =>
                        customer.name.toLowerCase().indexOf(filter) !== -1,
                    )
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(customer => {
                    const defaultContact =
                      customer.contacts &&
                      customer.contacts.find(contact => !!contact.default);

                    return (
                      <TableRow
                        className={classes.row}
                        key={(customer as CustomerWithKey).key}
                        hover={true}
                        onClick={this.rowOnClickHandler(customer)}
                      >
                        <CustomTableCell
                          className={classNames(
                            classes.tableCell,
                            classes.sortableCell,
                          )}
                        >
                          {customer.name}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {customer.address}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {(defaultContact && defaultContact.name) || ''}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {(defaultContact && defaultContact.phone) || ''}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {(defaultContact && defaultContact.email) || ''}
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>

          <div className={classes.tableFooter}>
            <TablePagination
              classes={{
                toolbar: classes.tablePagination,
                select: classes.paginationSelect,
              }}
              component="div"
              count={customers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': i18n().ui.previous_page,
              }}
              nextIconButtonProps={{
                'aria-label': i18n().ui.next_page,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage={i18n().ui.rows_per_page}
            />
          </div>
          <Editor
            data-test="editor"
            editing={this.props.editing}
            editedCustomer={this.props.editedCustomer}
            companyId={this.props.companyId}
          />
        </div>
      </>
    );
  }

  private handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    this.setState({ page });
  };

  private handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({ rowsPerPage: Number(e.target.value) });
  };

  private rowOnClickHandler = (customer: Customer) => () => {
    const { companyId, dispatch } = this.props;
    dispatch && dispatch(updateCustomer(companyId, customer));
  };

  private handleFabButtonClick = () => {
    const { companyId, dispatch } = this.props;
    dispatch && dispatch(newCustomer(companyId));
  };

  private searchOnChangeHandler = (filter: string) => {
    this.setState({ filter });
  };

  private sortCustomerList = () => {
    let customersSortedList = [...this.state.customers];
    const { order, orderBy } = this.state;
    switch (orderBy) {
      case OrderByValues.customerName: {
        customersSortedList = this.customerNameSort(customersSortedList);
        break;
      }
      case OrderByValues.customerAddress: {
        customersSortedList = this.customerAddressSort(customersSortedList);
        break;
      }
      case OrderByValues.contanctPerson: {
        customersSortedList = this.customerContactPersonSort(
          customersSortedList,
        );
        break;
      }
      case OrderByValues.customerEmail: {
        customersSortedList = this.customerEmailSort(customersSortedList);
        break;
      }
      default:
        return;
    }
    if (order === OrderOptions.desc) {
      customersSortedList.reverse();
    }
    this.setState({
      customers: customersSortedList,
    });
  };

  /** Handle table order change
   * Invoked everytime when user click to orderable table title cell.
   * If the same cell cliked, then just change the "order", if another cell clicked, then set the "order" to default 'asc' and set new "orderBy" value
   * At the end, calls sortCustomerList()
   *
   * @param {OrderByValues} orderBy
   */
  private handleTableSortChange = (orderBy: OrderByValues) => {
    if (this.state.orderBy === orderBy) {
      this.setState(
        {
          order: this.getOppositeOrderOption(),
        },
        () => {
          this.sortCustomerList();
        },
      );
    } else {
      this.setState(
        {
          order: OrderOptions.asc,
          orderBy,
        },
        () => {
          this.sortCustomerList();
        },
      );
    }
  };

  /**
   * Returns the opposite value of "order" variable from state
   */
  private getOppositeOrderOption = () => {
    const order = this.state.order;
    if (order === OrderOptions.asc) {
      return OrderOptions.desc;
    } else {
      return OrderOptions.asc;
    }
  };

  /**
   * Sort provided customer array by customer name
   *
   * @param {Customer[]} customers
   */
  private customerNameSort = (customers: Customer[]) => {
    customers.sort((customer1, customer2) =>
      this.customerDefaultSort(customer1, customer2),
    );
    return customers;
  };

  /**
   * Sort provided customer array by customer address
   *
   * @param {Customer[]} customers
   */
  private customerAddressSort = (customers: Customer[]) => {
    customers.sort((customer1, customer2) => {
      const customer1Address = customer1.address || '';
      const customer2Address = customer2.address || '';
      const result = this.possibleEmptylValuesSort(
        customer1Address.trim(),
        customer2Address.trim(),
      );
      return result === 0
        ? this.customerDefaultSort(customer1, customer2)
        : result;
    });
    return customers;
  };

  /**
   * Sort provided customer array by customer contact person
   *
   * @param {Customer[]} customers
   */
  private customerContactPersonSort = (customers: Customer[]) => {
    customers.sort((customer1, customer2) => {
      const customer1ContactPerson =
        (customer1.contacts &&
          customer1.contacts.find(contact => !!contact.default) &&
          customer1.contacts.find(contact => !!contact.default)!.name.trim()) ||
        '';
      const customer2ContactPerson =
        (customer2.contacts &&
          customer2.contacts.find(contact => !!contact.default) &&
          customer2.contacts.find(contact => !!contact.default)!.name.trim()) ||
        '';
      const result = this.possibleEmptylValuesSort(
        customer1ContactPerson,
        customer2ContactPerson,
      );
      return result === 0
        ? this.customerDefaultSort(customer1, customer2)
        : result;
    });
    return customers;
  };

  /**
   * Sort provided customer array by customer email
   *
   * @param {Customer[]} customers
   */
  private customerEmailSort = (customers: Customer[]) => {
    customers.sort((customer1, customer2) => {
      let customer1Email = '';
      let customer2Email = '';
      if (
        customer1.contacts &&
        customer1.contacts.find(contact => !!contact.default)
      ) {
        customer1Email = customer1.contacts
          .find(contact => !!contact.default)!
          .email.trim();
      }
      if (
        customer2.contacts &&
        customer2.contacts.find(contact => !!contact.default)
      ) {
        customer2Email = customer2.contacts
          .find(contact => !!contact.default)!
          .email.trim();
      }
      const result = this.possibleEmptylValuesSort(
        customer1Email,
        customer2Email,
      );
      return result === 0
        ? this.customerDefaultSort(customer1, customer2)
        : result;
    });
    return customers;
  };

  /**
   * Function used by sorting methods to place data with empty records to the bottom in 'asc' order
   *
   * @param {string} value1
   * @param {string} value2
   */
  private possibleEmptylValuesSort = (value1: string, value2: string) => {
    let result = value1.localeCompare(value2);
    if (!value1 && value2) {
      result = 1;
    } else if (value1 && !value2) {
      result = -1;
    }
    return result;
  };

  /**
   * Default sorting method that sorts two customers according to their names
   *
   * @param {Customer} customer1
   * @param {Customer} customer2
   */
  private customerDefaultSort = (customer1: Customer, customer2: Customer) => {
    return customer1.name.trim().localeCompare(customer2.name.trim());
  };
}

const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    margin: '20px',
    marginTop: '35px',
    height: '100%',
  },
  addNewButton: {
    marginLeft: '10px',
    height: '65%',
    margin: 'auto 0px',
    backgroundColor: '#99ca3c',
    minWidth: '120px',
    border: '1px solid',
    borderColor: 'rgba(0,0,255,0.25)',
    textTransform: 'none',
  },
  sortLabel: {
    color: 'white',
    '&:hover': { color: 'white' },
    '&:focus': { color: 'white' },
    '& svg': {
      color: 'white',
      opacity: 0.4,
    },
  },
  sortableCell: {
    cursor: 'pointer',
  },
  ...tableLayout(theme),
});

const mapStateToProps = (state: ApplicationState, ownProps: CustomerProps) => {
  return {
    ...ownProps,
    editing: state.customer.editing,
    editedCustomer: state.customer.editedCustomer,
  };
};

export default connect<CustomerProps>(mapStateToProps)(
  withRouter<any>(withStyles(styles, { withTheme: true })(Customers)),
);
