import { i18n, SupportedLocale } from '../locale/index';

export interface MemberSet {
  [memberId: string]: boolean;
}

export interface JobtypeSet {
  [jobtypeId: string]: boolean;
}
/**
 * Firestore typings for the application
 *
 * @author Ville Venäläinen
 */

/**
 * Schema definition of Firebase firestore
 */

export enum Schema {
  /**
   * Companies info documents. Single document is described in Company-interface
   */
  COMPANIES = 'companies',

  CUSTOMERS = 'customers',

  WORKSITE = 'worksite',

  MEMBERS = 'members',

  TASKS = 'tasks',

  JOBTYPES = 'jobtypes',

  QUALIFICATIONS = 'qualifications',

  VATS = 'vats',

  HOURTYPES = 'hourtypes',

  GEO = 'geo',
  /**
   * User's defined to system, but are not yet signed in themselves. Single document is described by PendingUser
   */
  PENDING_USERS = 'pending-users',

  /**
   * User info documents.
   *
   * At root levet this, whill list a documents with User -interface.
   * Under organization, this will list users with UserCompanyInfo -interface
   */
  USERS = 'users',

  TIMEREPORTS = 'timereports',

  CHAT = 'chat',

  SETTINGS = 'settings',

  WORKINGHOURS = 'workinghours',

  REPEATINGTASKS = 'repeatingTasks',

  SHIFTS = 'shifts',

  SHIFTTEMPLATES = 'shiftTemplates',
}

/**
 * Open tab of task dialog
 */
export enum TaskDialogState {
  CLOSED = 'closed',
  TASK = 'task',
  CHAT = 'chat',
  MAP = 'map',
}

export const enum ShiftDialogState {
  CLOSED = 'closed',
  OPEN = 'open',
}

export const enum ImportTasksDialogState {
  CLOSED = 'closed',
  OPEN = 'open',
}

/**
 * Chat icon read messages state
 */
export enum ChatIconState {
  UNDEFINED = 'undefined',
  READ = 'read',
  UNREAD = 'unread',
}

/**
 * Used to define in which state the opened task editor is
 */
export const enum EditorState {
  CLOSED = 'closed',
  EDIT = 'edit',
  NEW = 'new',
}

/**
 * Used to define which view is in use.
 */
export const enum ViewState {
  TIMELINE = 'timeline',
  TASKBANK = 'taskbank',
}

export const enum SignInMethod {
  GOOGLE = 'google',
  EMAILANDPASSWORD = 'emailAndPassword',
}

export enum TaskParams {
  MEMBER = 'member',
  WORKSITE = 'worksite',
  CUSTOMER = 'customer',
  VAT = 'vat',
  JOBTYPE = 'jobtype',
  ALARM = 'alarm',
  STATUS = 'status',
  PRIORITY = 'priority',
  TITLE = 'title',
  DESC = 'desc',
  DATE = 'date',
  TIME = 'time',
  DURATION = 'duration',
}

export enum ShiftTemplateTimeParams {
  START = 'start',
  END = 'end',
}

export type UserId = string;

export type CompanyId = string;

export type CustomerId = string;

/**
 * Client side extended firebase entit interface with key attached to help
 * handling the local data handling.
 *
 * NOTE: You must always remove the key from object before saving it to firestore and
 * you must explicitly add the key when reading from firestore.
 */
export interface WithKey {
  key?: string;
}

export interface HourTypeData {
  hourtype: string | undefined;
  time: number;
  note: string;
}

export interface WorkingHours {
  data: HourTypeData[];
  month: string;
  changeLog: ChangeLog[];
}

export interface WorkingHoursWithKey extends WorkingHours, WithKey {}

/**
 * Short user info that is replicated to different sides of application when needed.
 */
export interface ShortUserInfo {
  id: UserId;
  displayName: string;
  email: string;
  photoURL: string;
}

/**
 * User that has been attached to at least one company by email but
 * has not yet authenticated itself.
 */
export interface PendingUser {
  displayName: string;
  photoURL: string;
  home: CompanyId;
  companies: {
    [companyId: string]: UserRole;
  };
  isContact?: boolean;
  endCustomerCompanyName?: string;
}

export const enum UserRole {
  /**
   * Resourse has been created but not yet activated by the company. This usually hapens after user has signed in the mobile app. After that account must be activated from the dashboard
   */
  PENDING = 'p',
  /**
   * Active basic account
   */
  USER = 'u',
  /**
   * Admin account with access to dashboard
   */
  ADMIN = 'a',
  /**
   * Account has been closed from dashboard
   */
  CLOSED = 'c',

  /**
   * Super user able to jump between different organizations and the whole system
   */
  SYSTEM_ADMIN = 's',

  /**
   * End customer able to view simplified task report for their company
   */
  END_CUSTOMER = 'ec',
}

export const ResourceRoles: UserRole[] = [
  UserRole.PENDING,
  UserRole.USER,
  UserRole.ADMIN,
  UserRole.CLOSED,
];
/**
 * Different dialog types for closing function parameter
 */
export const enum DialogStatus {
  MODIFY = 'modify',
  NEW = 'new',
  DELETE = 'delete',
  DELETEMULTIPLE = 'deleteMultiple',
}
export interface ResourceRoleDesc {
  role: UserRole;
  name: string;
}

export interface Priority {
  label: string;
  value: number;
}
export interface PriorityWithKey extends Priority, WithKey {}
export function getPriorityMap(): Priority[] {
  return [
    { label: i18n().ui.high, value: 1 },
    { label: i18n().ui.normal, value: 0 },
    { label: i18n().ui.low, value: -1 },
  ];
}

export function getAlarmMap(): Alarm[] {
  return [
    { message: i18n().ui.no_alarm, minutes: -1 },
    { message: i18n().ui.fifteen_min_before, minutes: 15 },
    { message: i18n().ui.thirty_min_before, minutes: 30 },
    { message: i18n().ui.hour_before, minutes: 60 },
  ];
}

export interface SelectablePriority {
  name: string;
  key: string;
}

export interface HourTypeLabel {
  label: string;
  value: string;
}

export function getHourTypeMap(): HourTypeLabel[] {
  return [
    {
      label: i18n().ui.total_hours,
      value: 'totalhours',
    },
    {
      label: i18n().ui.daily_hours,
      value: 'dailyhours',
    },
  ];
}

export const RESOURCE_ROLE_MAP: ResourceRoleDesc[] = [
  {
    role: UserRole.PENDING,
    name: i18n().ui.role_awaiting,
  },
  {
    role: UserRole.USER,
    name: i18n().ui.role_user,
  },
  {
    role: UserRole.ADMIN,
    name: i18n().ui.role_admin,
  },
  {
    role: UserRole.CLOSED,
    name: i18n().ui.role_closed,
  },
];

export type Weekday = 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su';

export const WeekDays: Weekday[] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

export const enum UpdateType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}
/**
 * Message used to trigger resource event status changes from backend to clients. Based on this information
 * clients can update their own local notifications for alarming before event starts.
 *
 * @export
 * @interface TaskUpdateCloudMessage
 */
export interface TaskUpdateCloudMessage {
  /**
   * Resource event id
   */
  id: string;
  updateType: UpdateType;
  title?: TaskTitle;
  /**
   * Number of minutes to trigger alarm before the event starts
   *
   * @type {string}
   * @memberof TaskUpdateCloudMessage
   */
  alarmMinutes?: string;
  /**
   * Possible message included into the alarm
   *
   * @type {string}
   * @memberof TaskUpdateCloudMessage
   */
  alarmMessage?: string;

  customerName?: string;

  worksiteName?: string;

  jobtypeName?: string;

  address?: string;

  start?: number;

  status?: TaskStatus;

  priority?: Priority;

  code?: string;

  city?: string;
}

export interface WorkTime {
  key?: string;
  /**
   * Day of the week
   */
  day: Weekday;
  /**
   * Start time at a day in minutes
   */
  start: number;
  /**
   * End time at a day in minutes
   */
  end: number;
  /**
   * Every nth. week. (default is one = every week)
   */
  step?: number;
}

export interface Filter {
  priorities?: number[];
  jobtypes?: JobtypeSet;
  members?: MemberSet;
}
export interface Customer {
  name: string;
  customerNumber?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  contacts?: Contact[];
  worksiteRefs: WorksiteRef;
  tasks?: TaskRef;
}

export interface CustomerWithKey extends Customer, WithKey {
  key: CustomerId;
}

export interface Contact {
  /**
   * True if this contact is the customer's default contact.
   * Should not be included in object when false to conserve storage space.
   */
  default?: true;
  name: string;
  email: string;
  phone: string;
  role?: string;
  isContact?: boolean;
}

export interface ContactWithKey extends Contact, WithKey {}

export interface WorksiteRef {
  [key: string]: {
    name: string;
    nick?: string;
  };
}
/*
 * @export
 * @interface TaskUpdateCloudMessage
 */
export interface TaskUpdateCloudMessage {
  /**
   * Resource event id
   */
  id: string;
  updateType: UpdateType;
  /**
   * Number of minutes to trigger alarm before the event starts
   *
   * @type {string}
   * @memberof TaskUpdateCloudMessage
   */
  alarmMinutes?: string;
  /**
   * Possible message included into the alarm
   *
   * @type {string}
   * @memberof TaskUpdateCloudMessage
   */
  alarmMessage?: string;

  venueName?: string;

  taskName?: string;

  address?: string;

  code?: string;

  city?: string;
}

export interface Worksite {
  /**
   * @deprecated Use WorksiteWithKey
   */
  id?: string;
  name: string;
  nick: string;
  note: string;
  location: Location;
  phone?: string;
  /**
   * Customer ID
   */
  customer?: string;
  geoLocation?: GeoLocation;
}

export interface WorksiteWithKey extends Worksite, WithKey {
  key: string;
}

export interface Shift {
  shiftTemplateId: string;
  taskIds: string[];
  userId?: string;
  repeatingShiftId?: string;
}

export interface ShiftWithKey extends Shift, WithKey {}

export interface ShiftTemplate {
  name: string;
  tasks: ShiftTemplateTask[];
}

export interface ShiftTemplateWithKey extends ShiftTemplate, WithKey {}

export interface ShiftTemplateTask {
  id: string;
  start: number;
  end: number;
  jobtypeId: string;
  worksiteId?: string;
  customerId?: string;
  vatId?: string;
  priority?: Priority;
  title?: TaskTitle;
  desc?: string;
  alarm?: Alarm;
}

/**
 * Geolocation describig lattitude and longitude at given time
 */
export interface GeoLocation {
  /**
   * Latitude
   */
  la: number;
  /**
   * Longitude
   */
  lo: number;
  /**
   * Timestamp in milliseconds
   *
   * TODO: change any to Firebase FieldValue -type?
   */
  t: number | any;
}

/**
 * Event physical location
 */
export interface Location {
  id?: string;
  address: string;
  postalCode: string;
  city: string;
}

export interface DetailedLocation extends Location {
  geo: GeoLocation;
}

export const enum RepetitionType {
  /**
   * No repetition
   */

  NONE = 'n',
  /**
   * Repeat daily
   */

  DAILY = 'd',
  /**
   * Repeat every work day
   */
  WORKDAY = 'wd',
  /**
   * Repeat weekly
   */

  WEEKLY = 'w',
  /**
   * Repeat monthly
   */
  MONTHLY = 'm',
  /**
   * Repeat monthly
   */
  YEARLY = 'y',
}

export enum TaskStatus {
  UNDONE = 'UNDONE',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
  LATE = 'LATE',
}

export interface Repetition {
  /**
   * Cycling type of repation
   */
  type: RepetitionType;
  /**
   * Step of repeat based on type
   */
  step: number;
  /**
   * Number of repeats
   */
  count: number;
  /**
   * If type is w (week), we can select the days when to repeat the event
   */
  weekDays?: Weekday[];
}

export interface Alarm {
  minutes: number;
  message?: string;
}

export interface TaskTimer {
  started: number;
  ended?: number;
  memberId: string;
}

export interface TimerChangeLog {
  memberName: string | undefined;
  memberId: string | undefined;
  editedMemberId: string | undefined;
  timestamp: number;
  oldValue: number;
  newValue: number;
}

/**
 * Repeating tasks type
 */
export interface RepeatingTasks {
  tasks: string[];
}

/**
 * Company's task type
 */
export interface JobType {
  /**
   * @deprecated Use JobTypeWithKey
   */
  id?: string;
  hidden?: boolean;
  name: string;
  serviceNumber?: string;
  note?: string;
  files?: FileReference[];
  requiredQualifications?: JobTypeQualifications;
}

export interface JobTypeWithKey extends JobType, WithKey {}

export interface JobTypeQualifications {
  [qualificationKey: string]: boolean;
}

export interface Qualification {
  /**
   * @deprecated Use QualificationWithKey
   */
  id?: string;
  name: string;
}

export interface QualificationWithKey extends Qualification, WithKey {}

export interface Vat {
  default?: boolean;
  name: string;
  percent: number;
}

export interface VatWithKey extends Vat, WithKey {}

export interface HourType {
  default?: boolean;
  name: string;
  multiplier: number;
}

/**
 * Helper interface for building object that holds
 * all hours by hourtype and is extensible when companys
 * hourtypes changes
 */
export interface HoursByHourType {
  [key: string]: number;
}

export interface WorkTimeNotesByHourType {
  [key: string]: string;
}

export interface HourTypeWithKey extends HourType, WithKey {}

export type TaskId = string;

export interface TaskTimeRecord {
  worksite: TaskWorksite;
  customerId: CustomerId;
  customerName: string;
  jobtypeId: string;
  jobtypeName: string;
  total: number;
}
export interface TaskTimes {
  [key: string]: TaskTimeRecord;
}

/**
 * Headers type for csv
 */
export interface CsvHeader {
  label: string;
  key: string;
}

export interface CSVRow {
  task: string;
  member: string;
  customerTaskId?: string;
  jobtype?: string;
  customer?: string;
  worksite?: string;
  started: string;
  completed: string;
  workingTime: string;
  vat?: string;
}

/**
 * Types for WorkingHours
 */
export interface WorkingHoursByHourTypeForMember {
  memberId: string;
  workingHoursByHourType: HoursByHourType;
}

/**
 * Types for Changelog values
 */
export interface ChangeLog {
  memberName: string | undefined;
  memberId: string | undefined;
  timestamp: number;
  changes: Change[];
}

export interface Change {
  hourtypeName: string | undefined;
  hourtypeId: string | undefined;
  newValue: string | number;
  oldValue: string | number;
  changeType: ChangeType;
}

/**
 * Single day time report for a single resource
 */
export interface MemberTimerReport {
  /**
   * Date of time report
   */
  date: number;
  /**
   * Separate events done during the day
   */
  events?: TaskTimes;
  /**
   * Total time worked in milliseconds
   */
  total: number;
}

/**
 * Single chat message
 */
export interface ChatMessage {
  id?: string;
  message: string;
  uid: string;
  date: number;
  userName: string;
  avatar?: string;
}

/**
 * Single task
 */
interface TaskBase {
  id?: TaskId;
  title?: TaskTitle;
  start: number;
  end: number;
  status: TaskStatus;
  priority?: Priority;
  userId?: string;
  userName?: string;
  customerId?: CustomerId;
  customerName?: string;
  worksite?: any;
  jobtypeId: string;
  jobtypeName: string;
  phone?: string;
  desc?: string;
  created?: number;
  archived?: boolean;
  date?: string;
  weekNumber?: string;
  flexible?: boolean;

  /**
   * Reserved time for a task
   */
  duration: number;
  location?: any;
  repetition?: Repetition;
  alarm?: Alarm;

  /**
   * Active timer, if exists
   */
  activeTimer?: any;

  /**
   * Log of timer events
   */
  timerLog?: TaskTimer[];

  /**
   * Total time spend on venue
   */
  timerTotal?: number;

  /**
   * Value will be **ADDED** to timerTotal
   */
  timerTotalFixed?: TimerTotalFix[];

  /**
   * Log of timerTotal edits by admin
   */
  timerChangeLog?: TimerChangeLog[];
  /**
   * Id of the origin resource event (used with repetition)
   */
  originId?: string;

  /**
   * Files attached to the task
   */
  files?: FileReference[];

  /**
   * Value-added tax for the task
   */
  vat?: Vat;

  /**
   * Boolea value if the task has date only
   */
  dateOnly?: boolean;

  /**
   * Boolean value that checks if task is updated from dashboard
   */
  updatedByAdmin?: boolean;

  /**
   * Repetitive task ID
   */
  repeatingTaskId?: string;

  /**
   * Check if shiftTask has been modified by user
   */
  shiftTaskModified?: boolean;
}

export interface DateRange {
  start: number | undefined;
  end: number | undefined;
}

export interface TaskTitle {
  value: string;
  /**
   * User has modified the title if set to be true, this is there so we can know if we auto generate the title from task data
   */
  userModified: boolean;
}

export interface EditorTask extends Task {
  startDate?: string;
  startTime?: string;
  durationText?: string;
}

export interface Task extends TaskBase {
  worksite?: TaskWorksite;
  location?: Location;
  activeTimer?: TaskTimer;
  vat?: VatWithKey;
  priority?: Priority;
  startDate?: string;
  startTime?: string;
  durationText?: string;
  completionDate?: number;
  hasReadChat?: ChatRead;
  shiftId?: string;
  shiftTemplateTaskId?: string;
  shiftTemplateId?: string;
  repeatingTaskId?: string;
  repeatingShiftId?: string;
  customerTaskId?: string;
}

export interface ChatRead {
  [userId: string]: string;
}

/**
 * This interface is used if you write Tasks to database, will allow field deletion values on fields
 */
export interface TaskWrite extends TaskBase {}

export interface FileReference {
  key: string;
  fileName: string;
  mimeType: string;
  url: string;
}

export interface ImportTemplate {
  customerTaskId: string;
  weekNumber: string;
  date: string;
  startTime: string;
  endTime: string;
  jobType: string;
  address: string;
  postalIndex: string;
  city: string;
  businessId: string;
  memberEmail: string;
  extraInfo: string;
}

/**
 * Task with key value
 */
export interface TaskWithKey extends Task, WithKey {}

export interface TaskWorksite {
  id: string;
  name: string;
  nick: string;
  note: string;
}

export interface TaskRef {
  [key: string]: boolean;
}

export interface TimerTotalFix {
  memberId: string;
  value: number;
}

export interface Member {
  active?: boolean;
  name: string;
  email: string;
  photoURL: string;
  phone: string;
  jobtypes: MemberJobTypes;
  qualifications: MemberQualifications;
  role: UserRole;
  workTimes: WorkTime[];
  /**
   * Latest stored geo location
   */
  geo?: GeoLocation;
  /**
   * Active event's basic info. Available only when task is active
   */
  activeTask?: TaskWithKey; // | firebase.firestore.FieldValue;
  /**
   * Geo location where the task has started
   */
  activeGeo?: GeoLocation;

  /**
   * Client tokens used with cloud messaging, Tokens are desribed as simple key list, where
   * the key is the token.
   */
  tokens?: Map<string, boolean>;
  /**
   * Defines if the person is a company contact person or not
   */
  isContact?: boolean;
  /**
   * Only for Customer contact members that have been converted into Members with END_CUSTOMER role.
   * Required to restrict the reporting functionality that the end customer can access.
   */
  endCustomerCompanyName?: string;
  /**
   * Preferred locale for member. This is mainly used for backend emails. Will fall back to `Company.defaultLocale`.
   */
  locale?: SupportedLocale;
}

export interface Groups {
  id: string;
  content: string;
  photoURL: string;
}

export interface MemberJobTypes {
  [jobtypeKey: string]: boolean;
}

export interface MemberQualifications {
  [qualificationKey: string]: boolean;
}

export interface MemberIcons {
  id: string | undefined;
  photoURL: string;
}

export interface VatWithKey extends Vat, WithKey {}
/**
 * @see #applyCompanyDefaults to generate
 * valid company objects out of raw snapshot data.
 */
export interface Company {
  name: string;
  email: string;
  address: string;
  /**
   * @deprecated not in use anymore
   */
  contactPerson: string;
  postalCode: string;
  city: string;
  phone: string;
  logoURL: string;
  settings: Settings;
  geoLocation?: GeoLocation;
  /**
   * WARNING: This is not company document ID but a user supplied value (Finnish: Y-tunnus)
   */
  companyID?: string;
  /**
   * Default locale for new members and backend emails.
   */
  locale?: SupportedLocale;
}

export interface CompanyWithKey extends Company, WithKey {}

export enum CompanyStatus {
  ACTIVE,
  WAITING,
  CLOSED,
}

export enum ChangeType {
  HOURTYPE = 'hourtype',
  TIME = 'time',
}

export interface Settings {
  general: GeneralSettings;
  advanced: AdvancedSettings;
}

export interface GeneralSettings {
  activeHours?: ActiveHours;
  timelineStyle: number;
  enableCustomer: boolean;
  enableJobType: boolean;
  enableWorksite: boolean;
  enableWorksiteNick: boolean;
  enableVat: boolean;
  enableStatus?: boolean;
  enableCustomerTaskId: boolean;
}

export interface AdvancedSettings {
  /**
   * @deprecated
   */
  enableTaskBank: boolean;
  status?: CompanyStatus;
}

export interface ActiveHours {
  start: number;
  end: number;
}

export interface MemberWithKey extends Member, WithKey {}

/**
 * Resolve resource state
 * @param {Member} resource
 */
export function getResourceRole(resource: Member): UserRole {
  return resource.role || UserRole.PENDING;
}

export function getRoleDisplayName(role: UserRole): string {
  const resourceRole: ResourceRoleDesc | undefined = RESOURCE_ROLE_MAP.find(
    r => r.role === role,
  );
  // TODO Localise this... or maybe not have these functions at all in schema?
  return resourceRole ? resourceRole.name : 'tunnistamaton';
}

/**
 * User record for a single user in system. User records are listed on root level
 * at SCHEMA.USERS -collection.
 */
export interface User extends ShortUserInfo {
  /**
   * Home company's id. This is the company where the user will end after signin in
   */
  home?: CompanyId;
  /**
   * List of organizations where user is involved
   */
  companies?: {
    [companyId: string]: UserRole;
  };
  /**
   * True if user is system admin
   */
  systemAdmin?: boolean;
  /**
   * User's preferred localization
   */
  locale?: SupportedLocale;
  isContact?: boolean;
  endCustomerCompanyName?: string;
}

// export interface UserCompanyInfo {
//   /**
//    * Name of the organization
//    */
//   name: string;
//   role: UserRole;
//   modified?: number;
//   created?: number;
// }

export interface Organization {
  id: CompanyId;
  name: string;
  users?: Map<UserId, ShortUserInfo>;
  /**
   * Set of admin user's emails for an organization
   */
  admins?: {
    [email: string]: true;
  };
}
export type SessionToken = string;

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export enum MobileWorksitesOrderBy {
  name = 'name',
  customer = 'customer',
}

export interface MobileWorksitesSortOption {
  order: Order;
  orderBy: MobileWorksitesOrderBy;
  label: string;
}

export function getMobileWorksitesSortOptionsMap(): MobileWorksitesSortOption[] {
  return [
    {
      order: Order.asc,
      orderBy: MobileWorksitesOrderBy.name,
      label: i18n().ui.name + ' A-Z',
    },
    {
      order: Order.desc,
      orderBy: MobileWorksitesOrderBy.name,
      label: i18n().ui.name + ' Z-A',
    },
    {
      order: Order.asc,
      orderBy: MobileWorksitesOrderBy.customer,
      label: i18n().ui.customer + ' A-Z',
    },
    {
      order: Order.desc,
      orderBy: MobileWorksitesOrderBy.customer,
      label: i18n().ui.customer + ' Z-A',
    },
  ];
}
