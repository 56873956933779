import { Reducer } from 'redux';

import { CompanyId, Member, MemberWithKey } from '@shared/schema';
import { ResourcesAction, ResourcesActionType } from './resourcesActions';

/**
 * Evenet time line state
 */
export interface ResourcesState {
  editing: boolean;
  companyId?: CompanyId;
  /**
   * If editing, this will have an resoure that is been edited. In new resource mode this is always null
   */
  editedMember?: Member | undefined;
  allMembers: MemberWithKey[];
}

const initialState: ResourcesState = {
  editing: false,
  editedMember: undefined,
  allMembers: [],
};

export const resourcesReducer: Reducer<ResourcesState> = (
  state = initialState,
  action: ResourcesAction,
) => {
  switch (action.type) {
    case ResourcesActionType.RESOURCES_NEW: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedMember: undefined,
        allMembers: action.payload.allMembers,
      };
    }

    case ResourcesActionType.RESOURCES_EDIT: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedMember: {
          ...action.payload.resource,
        },
        allMembers: action.payload.allMembers,
      };
    }
    case ResourcesActionType.RESOURCES_CANCEL: {
      return {
        ...state,
        editing: false,
        editedMember: undefined,
      };
    }

    default:
      return state;
  }
};
