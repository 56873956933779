import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import muiTheme from 'theme';

export interface AuthMessageProps {
  /**
   * Message title
   */
  title: string;
  /**
   * Message body
   */
  message: string;
  /**
   * Button text (optional)
   *
   * If not defined, button is not shown.
   */
  buttonLabel?: string;
  /**
   * Button click handler (optional)
   */
  onButtonClick?: () => void;
}

const cardStyle: React.CSSProperties = {
  margin: 'auto',
  width: '50%',
  padding: '10px',
  marginTop: '10px',
};

const buttonStyle: React.CSSProperties = {
  margin: 'auto',
  width: '100%',
  padding: '10px',
  marginTop: '10px',
  color: 'white',
  backgroundColor: muiTheme.palette.primary.main,
};

const AuthMessage = (props: AuthMessageProps) => (
  <Paper style={cardStyle}>
    <Typography variant="h5" component="h3" data-test="AuthMessage_Title">
      {props.title}
    </Typography>
    <Typography component="p" data-test="AuthMessage_Message">
      {props.message}
    </Typography>
    {props.buttonLabel && (
      <Button
        style={buttonStyle}
        onClick={props.onButtonClick}
        data-test="AuthMessage_Button"
      >
        {props.buttonLabel}
      </Button>
    )}
  </Paper>
);

export default AuthMessage;
