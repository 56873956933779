import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  loader: {
    width: '0%',
    height: '2px',
    background: theme.palette.primary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
  displayNone: {
    display: 'none',
  },
  cursiveGrey: {
    fontStyle: 'italic',
    color: '#5c5c5c',
  },
});

export default styles;
