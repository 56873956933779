import { Task, TaskTitle, EditorTask, TaskWithKey } from '../schema/index';

export const resolveTaskTitle = (
  editorTask: Task | EditorTask,
  displayWorksite: boolean,
  displayWorksiteNick: boolean,
  displayJobtype: boolean,
) => {
  if (editorTask.title && editorTask.title.userModified) {
    // If user has changed value, just leave it be
    return editorTask.title;
  }

  // Leave value empty if nothing is set after it
  let value: string = '';
  if (displayJobtype) {
    // This is used if displayWorksite is set to false and displayJobtype is on, or when displayWorksite is on and worksite is not set
    value = editorTask.jobtypeName;
  } else if (displayWorksite && editorTask.worksite && !displayJobtype) {
    // If worksite is enabled and is there
    if (displayWorksiteNick) {
      // If nick is to be used
      value = editorTask.worksite.nick;
    } else {
      value = editorTask.worksite.name;
    }
  } else if (editorTask.customerName && editorTask.customerName.length > 0) {
    // Happens if all else is set to false and there is a customer name
    value = editorTask.customerName;
  }

  const title: TaskTitle = {
    value,
    userModified: false,
  };
  return title;
};

export const sortTasks = (tasks: TaskWithKey[]) => {
  const filteredTasks = tasks.filter(task => task.start > 0);
  filteredTasks.sort((a, b) => a.start - b.start);
  const filteredTasks1 = tasks.filter(task => task.start === 0);
  const allFilteredTasks = filteredTasks.concat(filteredTasks1);
  allFilteredTasks.sort((a, b) => b.priority!.value - a.priority!.value);

  return allFilteredTasks;
};

export const getUuid = () => {
  let uuid = '';
  let random;

  for (let i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
};
