import {
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  TextField,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CustomersIcon from '@material-ui/icons/Contacts';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneIcon from '@material-ui/icons/Done';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import PersonIcon from '@material-ui/icons/Person';
import WorksiteIcon from '@material-ui/icons/Place';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import RepeatIcon from '@material-ui/icons/Repeat';
import TimerIcon from '@material-ui/icons/Timer';
import TrafficIcon from '@material-ui/icons/Traffic';
import { i18n } from '@shared/locale/index';

import {
  CompanyId,
  CustomerWithKey,
  EditorState,
  getAlarmMap,
  getPriorityMap,
  Groups,
  JobTypeWithKey,
  MemberWithKey,
  QualificationWithKey,
  Repetition,
  Settings,
  Task,
  TaskParams,
  TaskStatus,
  TaskWithKey,
  UserId,
  VatWithKey,
  ViewState,
  WorksiteWithKey,
} from '@shared/schema';
import classNames from 'classnames';
import ACSelect, { ACSelectValue } from 'components/ACSelect';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import moment from 'moment';
import { Moment } from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ActionMeta } from 'react-select/lib/types';
import { ApplicationState } from 'reducers';
import { paramChange } from 'reducers/events/eventActions';
import { TimelineState } from 'reducers/timeline/timelineReducer';
import muiTheme from 'theme';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import {
  getRepeatCount,
  getThisAndFollowingRepeatingTasks,
} from 'utils/repeatingTasksUtils';
import {
  getShiftsTableRef,
  getShiftTemplatesTableRef,
} from 'utils/shiftsUtils';
import { MultiUploadFileList } from '../../../MultiUpload';
import RepetitionEditor from '../../../RepetitionEditor';
import styles from '../styles';

const emptyDate = new Date().setHours(0, 0, 0, 0);

/**
 * Variables for matching snapshot everytime...
 */
let defaultTime = new Date().setFullYear(2000, 0, 1);
defaultTime = new Date(defaultTime).setHours(8, 0, 0, 0);
let defaultDuration = new Date().setFullYear(2000, 0, 1);
defaultDuration = new Date(defaultDuration).setHours(1, 0, 0, 0);

export interface TaskFormContainerProps
  extends DispatchProp,
    StyledComponentProps {
  timelineState: TimelineState;
  jobTypes?: JobTypeWithKey[];
  worksites?: WorksiteWithKey[];
  vats?: VatWithKey[];
  customers: CustomerWithKey[];
  members: MemberWithKey[];
  settings: Settings;
  editorState?: EditorState;
  editedTask: TaskWithKey;
  companyId?: CompanyId;
  userId?: UserId;
  startDate?: moment.Moment;
  groups: Groups[];
  qualifications?: QualificationWithKey[];
  newWorksiteId?: string;
  viewState: ViewState;
}

interface State {
  selectedDate: Date | null;
  selectedTime: Date | null;
  selectedDuration: Date;
  selectableWorksites: WorksiteWithKey[];
  selectableCustomers: CustomerWithKey[];
  fileUploads: MultiUploadFileList;
  oldStatus?: TaskStatus;
  showQualificationWarning: boolean;
  showMissingTimeWarning: boolean;
  showMissingMemberWarning: boolean;
  selectedWorksite?: WorksiteWithKey;
  selectedCustomer?: CustomerWithKey;
  isMemberEmpty: boolean;
  shiftName?: string;
  repetitionEndDate?: Moment;
}

export class TaskFormContainer extends React.Component<
  TaskFormContainerProps,
  State
> {
  constructor(props: TaskFormContainerProps) {
    super(props);
    this.state = {
      selectableWorksites: [],
      selectableCustomers: [],
      fileUploads: {},
      selectedDate: null,
      selectedTime: null,
      selectedDuration: moment(emptyDate).toDate(),
      oldStatus: undefined,
      showQualificationWarning: false,
      showMissingTimeWarning: false,
      showMissingMemberWarning: false,
      selectedWorksite: undefined,
      selectedCustomer: undefined,
      isMemberEmpty: false,
      shiftName: undefined,
      repetitionEndDate: undefined,
    };
  }
  public componentDidMount() {
    const {
      editorState,
      editedTask,
      userId,
      newWorksiteId,
      worksites,
      customers,
      startDate,
    } = this.props;

    let selectedWorksite: WorksiteWithKey | undefined;
    let selectedCustomer: CustomerWithKey | undefined;

    if (editedTask.shiftId) {
      this.getShiftTemplateName();
    }

    if (editedTask.repetition) {
      this.getRepetitionEndDate().then(repetitionEndDate => {
        this.setState({ repetitionEndDate });
      });
    }

    if (newWorksiteId && worksites) {
      selectedWorksite = worksites.find(
        worksite => worksite.key === newWorksiteId,
      );
      if (selectedWorksite && selectedWorksite.customer) {
        selectedCustomer = customers.find(
          customer => customer.key === selectedWorksite!.customer,
        );
      }
      this.setState({ selectedWorksite, selectedCustomer });
    }

    if (!userId) {
      this.setState({ showMissingMemberWarning: true });
    }
    if (!startDate) {
      this.setState({ showMissingTimeWarning: true });
    }

    if (editorState === EditorState.NEW && editedTask && startDate) {
      const formattedStartTime: number = parseFloat(
        moment(startDate).format('x'),
      );
      const changedTaskParameters: any = {
        start: formattedStartTime,
        dateOnly: false,
        end: formattedStartTime + 3600000,
        duration: 3600000,
        userId: userId ? this.props.userId : '',
        userName: userId ? this.returnMember(userId).label : '',
        worksite: {},
        location: {},
        customerName: '',
        customerId: '',
      };

      if (selectedWorksite) {
        const worksite = {
          id: selectedWorksite.key,
          name: selectedWorksite.name,
          nick: selectedWorksite.nick,
          note: selectedWorksite.note,
        };
        const location = {
          address: selectedWorksite.location.address,
          city: selectedWorksite.location.city,
          postalCode: selectedWorksite.location.postalCode,
        };
        changedTaskParameters.worksite = worksite;
        changedTaskParameters.location = location;
      } else {
        delete changedTaskParameters.worksite;
        delete changedTaskParameters.location;
      }
      if (selectedCustomer) {
        const customerId = selectedCustomer.key;
        const customerName = selectedCustomer.name;

        changedTaskParameters.customerId = customerId;
        changedTaskParameters.customerName = customerName;
      } else {
        delete changedTaskParameters.customerId;
        delete changedTaskParameters.customerName;
      }
      this.props.dispatch(
        paramChange(changedTaskParameters, this.props.companyId),
      );
    }
    this.setState({ oldStatus: this.props.editedTask.status }, () =>
      this.showQualificationWarning(
        this.props.editedTask.jobtypeId,
        this.props.editedTask.userId,
      ),
    );

    if (
      editorState === EditorState.NEW &&
      editedTask &&
      selectedWorksite &&
      worksites
    ) {
      const changedTaskParameters: any = {
        worksite: {},
        location: {},
        customerName: '',
        customerId: '',
      };

      const worksite = {
        id: selectedWorksite.key,
        name: selectedWorksite.name,
        nick: selectedWorksite.nick,
        note: selectedWorksite.note,
      };
      const location = {
        address: selectedWorksite.location.address,
        city: selectedWorksite.location.city,
        postalCode: selectedWorksite.location.postalCode,
      };
      changedTaskParameters.worksite = worksite;
      changedTaskParameters.location = location;

      if (selectedCustomer) {
        const customerId = selectedCustomer.key;
        const customerName = selectedCustomer.name;

        changedTaskParameters.customerId = customerId;
        changedTaskParameters.customerName = customerName;
      } else {
        delete changedTaskParameters.customerId;
        delete changedTaskParameters.customerName;
      }
      if (changedTaskParameters) {
        this.props.dispatch(
          paramChange(changedTaskParameters, this.props.companyId),
        );
      }
    }

    this.setState({ oldStatus: this.props.editedTask.status });
  }
  public render() {
    const {
      classes = {},
      members,
      jobTypes,
      vats,
      settings,
      editorState,
      editedTask,
      userId,
      groups,
      viewState,
      companyId,
    } = this.props;
    const {
      oldStatus,
      selectedWorksite,
      selectedCustomer,
      showMissingTimeWarning: showTimelineDisplayWarning,
      showMissingMemberWarning,
      shiftName,
    } = this.state;
    const resourceList = members || [];
    const jobTypeList = jobTypes || [];
    const vatsList = vats || [];
    const selectedUserId = userId !== undefined ? userId : '';
    const isMemberSelectDisabled: boolean =
      oldStatus === TaskStatus.ACTIVE ||
      (editedTask.shiftId !== undefined && editedTask.shiftId !== '');
    /**
     * Check if there is need for missing member warning
     */
    if (editedTask.userId === '' && showMissingMemberWarning === false) {
      this.setState({ showMissingMemberWarning: true });
    }
    if (editedTask.userId !== '' && showMissingMemberWarning === true) {
      this.setState({ showMissingMemberWarning: false });
    }

    /**
     * Check if there is need for missing date/time/duration warning
     */
    if (
      (editedTask.start === 0 ||
        editedTask.dateOnly === true ||
        editedTask.duration === 0) &&
      showTimelineDisplayWarning === false
    ) {
      this.setState({ showMissingTimeWarning: true });
    }
    if (
      editedTask.start !== 0 &&
      !editedTask.dateOnly &&
      editedTask.duration !== 0 &&
      showTimelineDisplayWarning === true
    ) {
      this.setState({ showMissingTimeWarning: false });
    }

    return (
      <List>
        <ListItem disableGutters={true}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>

          <FormControl className={classes.formControl}>
            {groups &&
              groups.length !== resourceList.length &&
              editorState === EditorState.NEW && (
                <div>
                  <ACSelect
                    isDisabled={isMemberSelectDisabled}
                    options={groups.map((value: any) => ({
                      value: value.id,
                      label: value.content,
                      color: this.hasUserAllRequirments(
                        this.props.editedTask.jobtypeId,
                        value.id,
                      )
                        ? undefined
                        : muiTheme.priorities.high.color,
                    }))}
                    value={this.returnMember(selectedUserId)}
                    onChange={this.handleTaskParamChange(TaskParams.MEMBER)}
                    placeholder={i18n().ui.member_name}
                    isClearable={editedTask.userId !== ''}
                    data-test="memberACSelect"
                  />
                  {this.state.showQualificationWarning ? (
                    <div
                      data-test="qualificationWarning"
                      className={classNames(
                        classes.memberListHelperText,
                        classes.memberListQualificationWarning,
                      )}
                    >
                      {i18n().ui.lack_qualification_warning}
                    </div>
                  ) : (
                    <div className={classes.memberListHelperText}>
                      {i18n().ui.some_results_are_filtered}
                    </div>
                  )}
                </div>
              )}
            {(editorState === EditorState.EDIT ||
              (groups &&
                groups.length === resourceList.length &&
                editorState === EditorState.NEW) ||
              (!groups && editorState === EditorState.NEW)) && (
              <>
                <ACSelect
                  isDisabled={isMemberSelectDisabled}
                  options={resourceList.map((value: MemberWithKey) => ({
                    value: value.key,
                    label: value.name,
                    color: this.hasUserAllRequirments(
                      this.props.editedTask.jobtypeId,
                      value.key,
                    )
                      ? undefined
                      : muiTheme.priorities.high.color,
                  }))}
                  value={this.returnMember(selectedUserId)}
                  onChange={this.handleTaskParamChange(TaskParams.MEMBER)}
                  placeholder={i18n().ui.member_name}
                  isClearable={editedTask.userId !== ''}
                  data-test="memberACSelect"
                />
                {this.state.showQualificationWarning && (
                  <div
                    data-test="qualificationWarning"
                    className={classNames(
                      classes.memberListHelperText,
                      classes.memberListQualificationWarning,
                    )}
                  >
                    {i18n().ui.lack_qualification_warning}
                  </div>
                )}
                {this.state.showMissingMemberWarning && (
                  <div
                    data-test="qualificationWarning"
                    className={classNames(
                      classes.memberListHelperText,
                      classes.memberListQualificationWarning,
                    )}
                  >
                    {i18n().ui.missing_member_warning_message}
                  </div>
                )}
              </>
            )}
          </FormControl>
        </ListItem>
        {editorState === EditorState.EDIT && editedTask.shiftId && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <RepeatIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              <div>
                {
                  <div>
                    <TextField
                      label={i18n().ui.shift_info}
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={shiftName || ''}
                    />
                  </div>
                }
              </div>
            </FormControl>
          </ListItem>
        )}
        {settings.general.enableCustomer && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <CustomersIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              <ACSelect
                options={this.returnCustomers()}
                isDisabled={!!selectedWorksite}
                value={{
                  value:
                    (selectedCustomer && selectedCustomer.key) ||
                    editedTask.customerId ||
                    '',
                  label:
                    (selectedCustomer && selectedCustomer.name) ||
                    editedTask.customerName ||
                    '',
                }}
                onChange={this.handleTaskParamChange(TaskParams.CUSTOMER)}
                placeholder={i18n().ui.no_customer}
                isClearable={editedTask.customerId !== ''}
                data-test="customerACSelect"
              />
            </FormControl>
          </ListItem>
        )}
        {settings.general.enableWorksite && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <WorksiteIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              <ACSelect
                options={this.returnWorksites()}
                isDisabled={!!selectedWorksite}
                value={{
                  value:
                    (selectedWorksite && selectedWorksite.key) ||
                    (editedTask.worksite && editedTask.worksite.id) ||
                    '',
                  label:
                    (selectedWorksite && selectedWorksite.name) ||
                    (editedTask.worksite &&
                      ((settings.general.enableWorksiteNick &&
                        editedTask.worksite.nick) ||
                        editedTask.worksite.name)) ||
                    '',
                }}
                onChange={this.handleTaskParamChange(TaskParams.WORKSITE)}
                placeholder={i18n().ui.no_worksite}
                isClearable={
                  editedTask.worksite && editedTask.worksite.id !== ''
                }
                data-test="worksiteACSelect"
              />
            </FormControl>
          </ListItem>
        )}

        <ListItem disableGutters={true}>
          <ListItemIcon>
            <DoneIcon />
          </ListItemIcon>
          <FormControl className={classes.formControl}>
            <ACSelect
              options={jobTypeList
                .filter(jobtype =>
                  jobtype.hidden !== undefined ? !jobtype.hidden : true,
                )
                .map(value => ({
                  value: value.key,
                  label: value.name,
                }))}
              value={{
                value: editedTask.jobtypeId || '',
                label: editedTask.jobtypeName || '',
              }}
              onChange={this.handleTaskParamChange(TaskParams.JOBTYPE)}
              placeholder={i18n().ui.no_jobtype + ' *'}
              isClearable={false}
              data-test="jobtypeACSelect"
            />
          </FormControl>
          {/* <ArrowDropDown /> */}
        </ListItem>
        {settings.general.enableVat && (
          <ListItem id="vatListItem" disableGutters={true}>
            <ListItemIcon>
              <EuroSymbolIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              <ACSelect
                options={vatsList.map((value: VatWithKey) => ({
                  value: value.key,
                  label: `${value.name} ${
                    value.percent >= 0 ? value.percent + ' %' : ''
                  }`,
                }))}
                value={this.returnVat()}
                onChange={this.handleTaskParamChange(TaskParams.VAT)}
                placeholder={i18n().ui.select_vat}
                isClearable={false}
                data-test="vatACSelect"
              />
            </FormControl>
          </ListItem>
        )}
        <ListItem disableGutters={true} style={{ paddingBottom: '0px' }}>
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>

          <div className={classes.formControlDate}>
            <DatePicker
              id="date"
              label={i18n().ui.date}
              value={this.returnCorrectTimeOrDate(editedTask, TaskParams.DATE)}
              onChange={this.handleTaskTimeParamChange(TaskParams.DATE)}
              onClear={this.handleDateClear}
              animateYearScrolling={false}
              invalidDateMessage={i18n().ui.give_valid_date}
              format={'DD.MM.YYYY'}
              mask={(value: string | RegExp) =>
                value
                  ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              cancelLabel={i18n().ui.cancel}
              clearLabel={i18n().ui.clear}
              keyboard
              clearable
              disableOpenOnEnter
              minDateMessage={i18n().ui.min_date_message}
              maxDateMessage={i18n().ui.max_date_message}
              disabled={this.isTaskRepeating()}
              data-test="datepicker"
            />
          </div>
          <div className={classes.formControlSmall}>
            <TimePicker
              ampm={false}
              label={i18n().ui.time}
              value={this.returnCorrectTimeOrDate(editedTask, TaskParams.TIME)}
              onChange={this.handleTaskTimeParamChange(TaskParams.TIME)}
              mask={[/\d/, /\d/, ':', /\d/, /\d/]}
              cancelLabel={i18n().ui.cancel}
              clearLabel={i18n().ui.clear}
              invalidDateMessage={i18n().ui.give_valid_time}
              initialFocusedDate={defaultTime}
              keyboardIcon={<AccessTimeIcon />}
              keyboard
              clearable
              disableOpenOnEnter
              data-test="time"
            />
          </div>
          <div className={classes.formControlSmall}>
            <TimePicker
              ampm={false}
              label={i18n().ui.duration}
              value={this.returnCorrectTimeOrDate(
                editedTask,
                TaskParams.DURATION,
              )}
              onChange={this.handleTaskTimeParamChange(TaskParams.DURATION)}
              mask={[/\d/, /\d/, ':', /\d/, /\d/]}
              format={'HH:mm'}
              cancelLabel={i18n().ui.cancel}
              clearLabel={i18n().ui.clear}
              invalidDateMessage={i18n().ui.give_valid_duration}
              initialFocusedDate={defaultDuration}
              keyboardIcon={<TimerIcon />}
              keyboard
              clearable
              disableOpenOnEnter
              data-test="duration"
            />
          </div>
        </ListItem>

        {showTimelineDisplayWarning ? (
          <div
            data-test="qualificationWarning"
            className={classNames(
              classes.memberListHelperText,
              classes.dateDisplayWarning,
            )}
          >
            {i18n().ui.date_time_warning_message}
          </div>
        ) : (
          ''
        )}

        {editedTask.start > 0 && !editedTask.dateOnly && (
          <ListItem id="alarmListItem" disableGutters={true}>
            <ListItemIcon>
              <AccessAlarmIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              <ACSelect
                options={getAlarmMap().map(value => ({
                  value: value.minutes,
                  label: value.message,
                }))}
                value={this.getDialogAlarm()}
                onChange={this.handleTaskParamChange(TaskParams.ALARM)}
                placeholder={i18n().ui.no_alarm}
                isClearable={false}
                data-test="alarmACSelect"
              />
            </FormControl>
          </ListItem>
        )}

        {(viewState === ViewState.TASKBANK ||
          editorState === EditorState.EDIT) && (
          <ListItem id="statusListItem" disableGutters={true}>
            <ListItemIcon>
              <TrafficIcon />
            </ListItemIcon>
            <FormControl className={classes.formControl}>
              {
                <ACSelect
                  options={this.returnStatusArray()}
                  value={this.getDialogStatus()}
                  onChange={this.handleTaskParamChange(TaskParams.STATUS)}
                  isClearable={false}
                  data-test="statusACSelect"
                />
              }
            </FormControl>
          </ListItem>
        )}
        {!editedTask.shiftId && (
          <RepetitionEditor
            repetitionEndDate={this.state.repetitionEndDate}
            startDate={
              editedTask.start
                ? moment(editedTask.start)
                : this.props.startDate!
            }
            isEnabled={editedTask.start !== 0}
            repetition={editedTask.repetition}
            onChange={(
              repetition: Repetition | undefined,
              repetitionEndDate: Moment,
            ) => {
              const newState = { ...editedTask, repetition };
              this.setState({ repetitionEndDate });
              this.props.dispatch(paramChange(newState, companyId));
            }}
            data-test="repetitionSwitch"
          />
        )}
        {editedTask.start === 0 && editorState === EditorState.NEW && (
          <div className={classes.repetitionWarning}>
            {i18n().ui.set_date_to_create_repetitive}
          </div>
        )}

        {editedTask.repetition && editedTask.repetition.count > 365 && (
          <div className={classes.repetitionCountErrorMessage}>
            {i18n().ui.too_many_tasks_error}
          </div>
        )}

        {editedTask.repetition && editedTask.repetition.count < 1 && (
          <div className={classes.repetitionDurationError}>
            {i18n().ui.startdate_later_enddate}
          </div>
        )}

        {viewState === ViewState.TASKBANK && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <PriorityHighIcon />
            </ListItemIcon>

            <FormControl className={classes.formControl}>
              <ACSelect
                options={getPriorityMap().map(value => ({
                  value: value.value,
                  label: value.label,
                }))}
                value={{
                  value: editedTask.priority
                    ? editedTask.priority.value.toString()
                    : '0',
                  label: editedTask.priority
                    ? this.getCorrectPriority(editedTask.priority.value)
                    : getPriorityMap()[1].label,
                }}
                onChange={this.handleTaskParamChange(TaskParams.PRIORITY)}
                placeholder={i18n().ui.priority}
                isClearable={false}
                data-test="priorityACSelect"
              />
            </FormControl>
          </ListItem>
        )}
        <ListItem disableGutters={true}>
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <TextField
            label={i18n().ui.task_name}
            placeholder={i18n().ui.optional_name_for_task}
            value={editedTask.title ? editedTask.title.value : undefined}
            className={classes.textField}
            onChange={this.handleTaskTextFieldsChange(TaskParams.TITLE)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            data-test="taskNameInput"
          />
        </ListItem>
        <ListItem disableGutters={true}>
          <TextField
            label={i18n().ui.notes_and_notices}
            placeholder={i18n().ui.write_notes_here}
            multiline={true}
            value={editedTask.desc}
            onChange={this.handleTaskTextFieldsChange(TaskParams.DESC)}
            className={classes.textField}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            data-test="taskNotesInput"
          />
        </ListItem>
      </List>
    );
    {
      /* Commented out because everything is not ready for this yet. Uncomment when upload / files are needed on task */
    }
    {
      /* <ListItem>
                <ListItemText primary={i18n().ui.task_files} />
                <input
                  accept="image/*,.pdf"
                  className={classnames(classes.input, classes.displayNone)}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={this.onUploadChange()}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span" className={classes.button}>
                    {i18n().ui.search}
                    <SearchIcon className={classes.rightIcon} />
                  </Button>
                </label>
              </ListItem>
              <ListItem>
                <FormControl className={classes.uploadFormControl}>
                  <MultiUpload
                    fileRefs={fileUploads}
                    onChange={this.onMultiUploadChange}
                    companyName={appUser && appUser.home ? appUser.home : ''}
                    taskId={
                      editedResource && editedResource.id
                        ? editedResource.id
                        : ''
                    }
                  />
                </FormControl>
              </ListItem> */
    }
  }

  /**
   * This function returns editedTasks VAT
   */
  private returnVat = () => {
    const { editedTask } = this.props;

    const returnedVat = {
      value: editedTask.vat ? editedTask.vat.percent.toString() : '',
      label: editedTask.vat
        ? `${editedTask.vat.name} ${
            editedTask.vat.percent >= 0 ? editedTask.vat.percent + ' %' : ''
          }`
        : '',
    };
    return returnedVat;
  };

  /**
   * This function is used to return member to the ACSelect value
   * @param {string} selectedUserId this variable is given only when timeline view and double clicked on timeline
   */
  private returnMember = (selectedUserId: string) => {
    const { editorState, editedTask, viewState } = this.props;
    const { isMemberEmpty } = this.state;

    if (isMemberEmpty) {
      return {
        value: '',
        label: i18n().ui.select_member,
      };
    }

    const returnedMember =
      viewState === ViewState.TIMELINE &&
      editorState === EditorState.NEW &&
      !selectedUserId &&
      !editedTask.userId
        ? {
            value: '',
            label: i18n().ui.select_member,
          }
        : {
            value: editedTask.userId || selectedUserId,
            label: this.getSelectedUserName(
              editedTask.userId ? editedTask.userId : selectedUserId,
            ),
          };

    return returnedMember;
  };

  /**
   * This function returns worksites according to customerID which the task has or not
   */
  private returnWorksites = () => {
    const { editedTask, worksites } = this.props;

    let worksiteList: WorksiteWithKey[] = [];

    if (editedTask.customerId && worksites) {
      worksiteList = worksites.filter(
        worksite => worksite.customer === editedTask.customerId,
      );
    } else {
      worksiteList = worksites ? worksites : [];
    }

    const worksiteReturn = worksiteList.map((value: WorksiteWithKey) => ({
      value: value.key,
      label: value.name,
    }));
    return worksiteReturn;
  };

  /**
   *  This function returns correct customers according editedTask worksite
   */
  private returnCustomers = () => {
    const { editedTask, customers } = this.props;

    let customersList: CustomerWithKey[] = [];
    if (editedTask.worksite && editedTask.worksite.id && customers) {
      customersList = customers.filter(customer =>
        Object.keys(customer.worksiteRefs).includes(editedTask.worksite!.id),
      );
    } else {
      customersList = customers;
    }
    if (customersList) {
      const customerReturn = customersList.map((value: CustomerWithKey) => ({
        value: value.key,
        label: value.name,
      }));
      return customerReturn;
    } else {
      return [];
    }
  };

  /**
   * This function will return correct time format for Material ui TimePicker or DatePicker component
   * @param {TaskWithKey} editedTask
   * @param {string} formatOfTime this variable has 3 modes date, time, duration.
   */
  private returnCorrectTimeOrDate = (
    editedTask: TaskWithKey,
    formatOfTime: TaskParams,
  ) => {
    switch (formatOfTime) {
      case TaskParams.DATE:
        if (editedTask.start && editedTask.start > 0) {
          return editedTask.start;
        }
        return null;

      case TaskParams.TIME:
        if (editedTask.start && editedTask.start > 0) {
          return editedTask.dateOnly === true ? null : moment(editedTask.start);
        }
        return null;

      case TaskParams.DURATION:
        const date = moment().set({ h: 0, m: 0, s: 0, ms: 0 });
        const duration = editedTask.duration;
        if (duration > 0) {
          return date.valueOf() + editedTask.duration;
        }
        return null;
    }
    return null;
  };

  /**
   * Function returns an array of ACSelectValue objects which contains every status for task depending is the editorState EDIT or NEW.
   */
  private returnStatusArray = () => {
    const { editorState } = this.props;

    let statusArray: string[] = [];
    if (editorState === EditorState.NEW) {
      statusArray = Object.keys(TaskStatus).filter(
        (status: TaskStatus) =>
          status !== TaskStatus.LATE && status !== TaskStatus.PAUSED,
      );
    } else {
      statusArray = Object.keys(TaskStatus).filter(
        (status: TaskStatus) => status !== TaskStatus.LATE,
      );
    }

    const ACObjectArray = statusArray.map(status => ({
      label: i18n().ui[status.toLowerCase()],
      value: TaskStatus[status.toUpperCase()],
    }));

    return ACObjectArray;
  };

  /**
   * Function returns correct alarmMap if editedTask has one
   */
  private getDialogAlarm = () => {
    const { editedTask } = this.props;
    let ACAlarmObject: ACSelectValue = {
      label: getAlarmMap()[0].message as string,
      value: getAlarmMap()[0].minutes.toString(),
    };

    if (editedTask.alarm) {
      const correctAlarm = getAlarmMap().find(
        alarm => editedTask.alarm!.minutes === alarm.minutes,
      );

      ACAlarmObject = {
        label: correctAlarm!.message as string,
        value: correctAlarm!.minutes.toString(),
      };
    }

    return ACAlarmObject;
  };

  /**
   * Gets status for task
   * @returns Status object
   */
  private getDialogStatus = () => {
    const { editedTask } = this.props;

    const ACStatusObject = {
      label: i18n().ui[editedTask.status.toLowerCase()] as string,
      value: TaskStatus[editedTask.status.toUpperCase()] as string,
    };

    return ACStatusObject;
  };

  /**
   * Function changes tasks parameters according inputname and event
   * @param {TaskParams} inputName
   * @event React.ChangeEvent<HTMLInputElement>
   */
  private handleTaskTextFieldsChange = (inputName: TaskParams) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { editedTask, companyId } = this.props;

    let changedTaskTextFieldParams = {};

    switch (inputName) {
      case TaskParams.TITLE:
        if (editedTask.title && editedTask.title.value !== target.value) {
          changedTaskTextFieldParams = {
            title: {
              userModified: editedTask.jobtypeName !== target.value,
              value: target.value,
            },
          };
        }
        break;

      case TaskParams.DESC:
        if (
          (editedTask.desc && editedTask.desc !== target.value) ||
          !editedTask.desc
        ) {
          changedTaskTextFieldParams = { desc: target.value };
        }
        break;
    }

    this.props.dispatch(paramChange(changedTaskTextFieldParams, companyId));
  };

  /**
   * Function changes date and time according inputName and event
   * @param {TaskParams} inputName
   * @event moment.Moment
   */
  private handleTaskTimeParamChange = (inputName: TaskParams) => (
    newTimeParam: moment.Moment,
  ) => {
    const { companyId, editedTask, startDate, userId, members } = this.props;
    let changedTimeFieldParams: any = {};

    switch (inputName) {
      case TaskParams.DATE:
        if (newTimeParam) {
          const oldTaskMoment = moment(editedTask.start);
          const dateMoment =
            editedTask.dateOnly === false || editedTask.dateOnly === undefined
              ? newTimeParam.set({
                  hours: oldTaskMoment.get('hours'),
                  minutes: oldTaskMoment.get('minutes'),
                })
              : newTimeParam;
          const newDate = dateMoment.valueOf();
          changedTimeFieldParams = { start: newDate };
        } else {
          changedTimeFieldParams = { start: 0, dateOnly: true };
        }

        break;

      case TaskParams.TIME:
        const timeMoment = newTimeParam;

        if (timeMoment) {
          const newTimeValue = editedTask.start
            ? moment(editedTask.start)
                .set({
                  hours: timeMoment.get('hours'),
                  minutes: timeMoment.get('minutes'),
                })
                .valueOf()
            : moment()
                .set({
                  hours: timeMoment.get('hours'),
                  minutes: timeMoment.get('minutes'),
                })
                .valueOf();

          changedTimeFieldParams = { start: newTimeValue, dateOnly: false };
        } else {
          changedTimeFieldParams = { dateOnly: true, end: 0 };
        }

        break;

      case TaskParams.DURATION:
        if (newTimeParam) {
          const durationMoment = newTimeParam;
          const endDateTime = moment(editedTask.start)
            .add(durationMoment.get('hours'), 'hours')
            .add(durationMoment.get('minutes'), 'minutes')
            .valueOf();
          const durationStamp = endDateTime - editedTask.start;

          changedTimeFieldParams = {
            duration: durationStamp,
          };
        } else {
          changedTimeFieldParams = {
            end: 0,
            duration: 0,
          };
        }

        break;
    }

    if (startDate && editedTask.dateOnly && userId) {
      const newMember = members.find(member => member.key === userId);
      changedTimeFieldParams = {
        ...changedTimeFieldParams,
        start: startDate.valueOf(),
        dateOnly: false,
        userId: newMember ? newMember.key : userId,
        userName: newMember ? newMember.name : '',
      };
    }
    if (
      editedTask.repetition &&
      inputName === TaskParams.DATE &&
      this.state.repetitionEndDate
    ) {
      const newRepetition: Repetition = editedTask.repetition;
      const repetitionCount = getRepeatCount(
        newRepetition,
        newTimeParam,
        this.state.repetitionEndDate!,
      );
      newRepetition.count = repetitionCount;
      changedTimeFieldParams = {
        ...changedTimeFieldParams,
        repetition: newRepetition,
      };
    }
    if (
      (editedTask.dateOnly === false ||
        changedTimeFieldParams.dateOnly === false) &&
      (editedTask.duration || changedTimeFieldParams.duration) &&
      (editedTask.start || changedTimeFieldParams.start) &&
      newTimeParam
    ) {
      const duration = changedTimeFieldParams.duration
        ? changedTimeFieldParams.duration
        : editedTask.duration;
      let endDateTime = editedTask.start
        ? editedTask.start + duration
        : changedTimeFieldParams.start + duration;

      if (editedTask.start && changedTimeFieldParams.start) {
        endDateTime = changedTimeFieldParams.start + duration;
      }

      changedTimeFieldParams = {
        ...changedTimeFieldParams,
        end: endDateTime,
      };
    }

    this.props.dispatch(paramChange(changedTimeFieldParams, companyId));
  };

  /**
   * Removes repetition from a task if date is cleared
   */
  private handleDateClear = () => {
    const { companyId } = this.props;
    const changedRepetitionParams = {
      repetition: null,
      start: 0,
    };
    this.props.dispatch(paramChange(changedRepetitionParams, companyId));
  };

  /**
   * Function changes tasks ACSelect values only according
   * inputName and selected and action
   * @param {TaskParams} inputName
   * @param {ACSelectValue} selected
   * @param {ActionMeta} action
   *
   */
  private handleTaskParamChange = (inputName: TaskParams) => (
    selected: ACSelectValue,
    action: ActionMeta,
  ) => {
    const { editedTask, companyId, worksites, vats } = this.props;
    let changedTaskParameters: Partial<Task> = {};

    const value: string =
      action.action === 'select-option' ? selected.value : '';
    const label: string = selected ? selected.label : '';

    /**
     * Display/remove warning if user has no member selected and is in timeline view
     */
    if (inputName === TaskParams.MEMBER && value === '') {
      this.setState({ showMissingMemberWarning: true });
    } else if (inputName === TaskParams.MEMBER && value !== '') {
      this.setState({ showMissingMemberWarning: false });
    }

    switch (inputName) {
      case TaskParams.MEMBER:
        if (editedTask.userId !== value) {
          changedTaskParameters = { userId: value, userName: label };
          this.setState({ isMemberEmpty: false });
          this.showQualificationWarning(editedTask.jobtypeId, value);
        }
        if (value === '') {
          changedTaskParameters = { userId: '', userName: '' };
          this.setState({ isMemberEmpty: true });
          this.showQualificationWarning(editedTask.jobtypeId, value);
        }
        break;
      case TaskParams.WORKSITE:
        let correctWorksite: WorksiteWithKey | undefined;

        if (worksites && value && label) {
          correctWorksite = worksites.find(worksite => worksite.key === value);
          changedTaskParameters = {
            worksite: {
              id: correctWorksite!.key,
              name: correctWorksite!.name,
              nick: correctWorksite!.nick,
              note: correctWorksite!.note,
            },
            location: correctWorksite!.location,
          };
          if (
            (!editedTask.customerId && editedTask.worksite) ||
            (changedTaskParameters.worksite && !editedTask.customerId)
          ) {
            const customerToAdd = this.props.customers.find(
              customer => customer.key === correctWorksite!.customer,
            );
            changedTaskParameters = {
              ...changedTaskParameters,
              customerId: customerToAdd!.key,
              customerName: customerToAdd!.name,
            };
          }
        } else {
          changedTaskParameters = {
            worksite: undefined,
          };
        }

        break;

      case TaskParams.CUSTOMER:
        if (editedTask.customerId !== value) {
          changedTaskParameters = { customerId: value, customerName: label };
        }

        break;

      case TaskParams.JOBTYPE:
        if (editedTask.jobtypeId !== value) {
          const newTitle =
            editedTask.title && !editedTask.title.userModified
              ? { title: { userModified: false, value: label } }
              : {};
          changedTaskParameters = {
            jobtypeId: value,
            jobtypeName: label,
            ...newTitle,
          };
          this.showQualificationWarning(value, editedTask.userId);
        }

        break;

      case TaskParams.VAT:
        if (vats) {
          const correctVat = vats.find(vat => vat.key === value);

          changedTaskParameters = {
            vat: correctVat,
          };
        }
        break;

      case TaskParams.ALARM:
        const valueAsNumber = Number(value);

        changedTaskParameters = { alarm: { minutes: valueAsNumber } };

        break;

      case TaskParams.STATUS:
        if (editedTask.status !== value) {
          changedTaskParameters = { status: value as TaskStatus };
        }
        break;

      case TaskParams.PRIORITY:
        const priorityvalueAsNumber = Number(value);
        if (
          editedTask.priority &&
          editedTask.priority.value !== priorityvalueAsNumber
        ) {
          changedTaskParameters = {
            priority: {
              label,
              value: priorityvalueAsNumber,
            },
          };
        }

        break;
    }

    this.props.dispatch(paramChange(changedTaskParameters, companyId));
  };

  /**
   * Function returns correct member according to key parameter
   * @param {string} key
   */
  private getSelectedUserName = (key: string) => {
    const { members } = this.props;
    if (!members) {
      return i18n().ui.no_members;
    }
    const member = members.find(item => item.key === key);
    return member ? member.name : i18n().ui.select_member;
  };

  /**
   * Gets correct priority for task
   * @returns Corret label or unknown
   */
  private getCorrectPriority = (priority: number) => {
    const result = getPriorityMap().find(
      realPriority => realPriority.value === priority,
    );

    if (result) {
      return result.label;
    } else {
      return i18n().ui.unknown_priority;
    }
  };

  /**
   * Checks that particular member has or not all the requirments for particular jobtype
   *
   * @param {string?} jobtypeId
   * @param {string?} userId
   *
   * @returns {boolean} hasAllRequirements
   */
  private hasUserAllRequirments = (jobtypeId?: string, userId?: string) => {
    let hasAllRequirements = true;
    const taskJobtypeId = jobtypeId;
    const taskUserId = userId;
    if (
      this.props.jobTypes &&
      taskUserId &&
      this.props.members.find(member => member.key === taskUserId)
    ) {
      const taskJobtype = this.props.jobTypes.find(
        jobtype => jobtype.id === taskJobtypeId,
      );
      if (taskJobtype && taskJobtype.requiredQualifications) {
        const requiredQualifications = taskJobtype.requiredQualifications;
        const selectedMember = this.props.members.find(
          member => member.key === taskUserId,
        )!;

        for (const qualification in requiredQualifications) {
          if (
            !selectedMember.qualifications ||
            !selectedMember.qualifications[qualification]
          ) {
            hasAllRequirements = false;
            break;
          }
        }
      }
    }
    return hasAllRequirements;
  };

  /**
   * Checks should qualification warning be shown and set stae with result
   *
   * @param {string?} jobtypeId
   * @param {string?} userId
   */
  private showQualificationWarning = (jobtypeId?: string, userId?: string) => {
    const hasAllRequirements = this.hasUserAllRequirments(jobtypeId, userId);
    this.setState({ showQualificationWarning: !hasAllRequirements });
  };

  /**
   * Retrieves the shift template name from Firebase. At first, call for shift document data
   * from SHIFTS collection to receive shiftTemplateId. Then looking for shift name of template
   * used for a specific shift (in SHIFTTEMPLATES collection). Also checks shift id exists
   * to enable the shift template name display in Task Editor (EventEditorDialog).
   */

  private getShiftTemplateName = async () => {
    const { editedTask, companyId } = this.props;

    try {
      const shiftDocData = await (
        await getShiftsTableRef(companyId).doc(editedTask.shiftId).get()
      ).data();

      if (shiftDocData && shiftDocData.shiftTemplateId) {
        const shiftTemplateDocData = await (
          await getShiftTemplatesTableRef(companyId)
            .doc(shiftDocData.shiftTemplateId)
            .get()
        ).data();

        this.setState({
          shiftName:
            shiftTemplateDocData && shiftTemplateDocData.name
              ? shiftTemplateDocData.name
              : undefined,
        });
      }
    } catch (error) {
      console.error(
        'Error while getting shift template from database: ',
        error,
      );
    }
  };

  /**
   * Determines if the edited task is repeating
   *
   * @returns {boolean}
   */
  private isTaskRepeating(): boolean {
    const { editedTask } = this.props;
    if (
      editedTask.repeatingTaskId !== undefined &&
      editedTask.repeatingTaskId !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getRepetitionEndDate = async () => {
    const { editedTask, companyId } = this.props;
    let endDate: Moment | undefined;
    try {
      await getThisAndFollowingRepeatingTasks(companyId, editedTask).then(
        docs => {
          const tasks = collectionToJsonWithIds<TaskWithKey>(docs);
          const startDates = tasks
            .map(task => moment(task.start))
            .filter(date => date !== undefined);
          endDate = moment.max(startDates);
        },
      );
    } catch (error) {
      console.error(
        'Error while getting repeating tasks from database: ',
        error,
      );
    }
    return endDate;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TaskFormContainerProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    editedTask: state.eventEditor.editedResource,
    userId: state.eventEditor.userId,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(TaskFormContainer),
);
