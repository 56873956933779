import {
  Company,
  GeneralSettings,
  Settings,
  AdvancedSettings,
  CompanyStatus,
} from '../schema/index';
import { applyDefaults } from './applyDefaults';
import { Contact } from '@shared/schema';

const DEFAULT_GENERAL_SETTINGS: GeneralSettings = {
  activeHours: { start: 6 * 360 * 10000, end: 22 * 360 * 10000 },
  timelineStyle: 24,
  enableCustomer: false,
  enableJobType: false,
  enableWorksite: false,
  enableWorksiteNick: false,
  enableVat: false,
  enableCustomerTaskId: false,
};

const DEFAULT_ADVANCED_SETTINGS: AdvancedSettings = {
  enableTaskBank: false,
  status: CompanyStatus.ACTIVE,
};

export const DEFAULT_SETTINGS: Settings = {
  general: DEFAULT_GENERAL_SETTINGS,
  advanced: DEFAULT_ADVANCED_SETTINGS,
};

export const DEFAULT_COMPANY: Company = {
  name: '',
  contactPerson: '',
  email: '',
  address: '',
  postalCode: '',
  city: '',
  phone: '',
  logoURL: '',
  companyID: '',
  settings: DEFAULT_SETTINGS,
};

export const DEFAULT_CONTACT: Contact = {
  name: '',
  email: '',
  phone: '',
  role: '',
};

/**
 * Makes a deep copy of an object so that it complies to the Company
 * interface and contains default values for any missing mandatory fields.
 *
 * Note that all extra fields present in database are rejected in which
 * case you should add them to the interface.
 *
 * @param data Raw object (from i.e. database)
 */
export const applyCompanyDefaults = (data: any): Company => {
  return applyDefaults(data, DEFAULT_COMPANY) as Company;
};
