import {
  Button,
  Dialog,
  StyledComponentProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core/';
import { i18n } from '@shared/locale';
import { MemberWithKey, TimerChangeLog } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment/index';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import moment from 'moment';
import * as React from 'react';
import CustomTableCell from 'theme/styles/tableCell';
import styles from './../../../Task/containers/WorkTimeReportContainer/components/MemberWorkTimeTable/styles';

export interface TimerChangeLogProps extends StyledComponentProps {
  timerChangeLog: TimerChangeLog[] | undefined;
  open: boolean;
  closeChangeLog: (open: boolean) => void;
  selectedMember?: MemberWithKey;
  members: MemberWithKey[];
}

class TimerChangeLogDialog extends React.Component<TimerChangeLogProps> {
  constructor(props: TimerChangeLogProps) {
    super(props);
  }

  public render() {
    const { classes = {}, timerChangeLog, open, members } = this.props;
    return (
      <Dialog
        open={open}
        classes={{
          paper: classes.changeLogPaper,
          container: classes.changeLogContainer,
        }}
      >
        <h1 className={classes.changeLogHeadline}>{i18n().ui.changelog}</h1>
        <div className={classes.changeLogTableWrapper}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.time}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().concepts.member}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.edited_user}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.change}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody data-test="tableBody">
              {timerChangeLog &&
                timerChangeLog.map((log: TimerChangeLog, index) => {
                  const editedUser =
                    (log.editedMemberId &&
                      members.find(user => user.key === log.editedMemberId)) ||
                    undefined;

                  return (
                    <TableRow className={classes.row} key={index}>
                      <CustomTableCell
                        className={classes.tableCell}
                        data-test="timestamp"
                      >
                        {moment(log.timestamp).format('DD.MM.YYYY HH:mm')}
                      </CustomTableCell>
                      <CustomTableCell
                        className={classes.tableCell}
                        data-test="memberName"
                      >
                        {log.memberName}
                      </CustomTableCell>
                      <CustomTableCell
                        className={classes.tableCell}
                        data-test="editedMemberName"
                      >
                        {editedUser ? editedUser.name : i18n().ui.unknown_user}
                      </CustomTableCell>
                      <CustomTableCell
                        className={classes.tableCell}
                        data-test="changes"
                      >
                        <div className={classes.changeLogChange}>
                          {momentDurationFormat(
                            log.oldValue,
                            DurationFormatPreset.HHMM,
                          ) +
                            ' \u2192 ' +
                            momentDurationFormat(
                              log.newValue,
                              DurationFormatPreset.HHMM,
                            )}
                        </div>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <Button
          classes={{
            root: classes.changeLogButton,
          }}
          onClick={this.dialogClose}
        >
          {i18n().ui.close}
        </Button>
      </Dialog>
    );
  }

  /**
   * Closes dialog, passes boolean value to TaskReportTable.tsx
   */
  private dialogClose = () => {
    this.props.closeChangeLog(false);
  };
}

export default withStyles(styles, { withTheme: true })(TimerChangeLogDialog);
