import { Divider, ListItem, ListItemText, WithTheme } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { i18n } from '@shared/locale';
import { WithKey } from '@shared/schema';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import * as _ from 'lodash';
import * as React from 'react';

interface TaskReportFilterSelectProps<T> extends WithTheme {
  open: boolean;
  values?: Array<T & WithKey> | T[];
  hiddenValues?: Array<T & WithKey> | T[];
  handleClose: () => void;
  handleOpen: (anchorEl: HTMLElement) => void;
  title: string;
  anchorEl?: HTMLElement;
  handleSelectAllClick: () => void;
  handleDeselectAllClick: () => void;
}

class TaskReportFilterSelect<T> extends React.Component<
  TaskReportFilterSelectProps<T>
> {
  public render() {
    const {
      theme,
      open,
      handleClose,
      handleOpen,
      title,
      anchorEl,
      handleSelectAllClick,
      handleDeselectAllClick,
      values,
      hiddenValues,
    } = this.props;

    return (
      <div>
        <CustomSelect
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          title={title}
          anchorEl={anchorEl}
        >
          <ListItem
            key={'Select all'}
            button={true}
            onClick={() => handleSelectAllClick()}
            style={{
              background: _.isEmpty(hiddenValues)
                ? theme.palette.divider
                : 'transparent',
            }}
          >
            <ListItemText>{i18n().ui.choose_all}</ListItemText>
          </ListItem>
          <ListItem
            key={'Deselect all'}
            button={true}
            onClick={() => handleDeselectAllClick()}
            style={{
              background:
                values && hiddenValues && hiddenValues.length === values.length
                  ? theme.palette.divider
                  : 'transparent',
            }}
          >
            <ListItemText>{i18n().ui.deselect_all}</ListItemText>
          </ListItem>
          <Divider key="divider" />
          {this.props.children}
        </CustomSelect>
      </div>
    );
  }

  public isWithKey = <Z extends WithKey>(value: (Z & WithKey) | Z) => {
    return (value as Z & WithKey).key !== undefined;
  };
}

export default withTheme()(TaskReportFilterSelect);
