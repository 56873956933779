/**
 * Make object compatible with template object
 *
 * TODO Should probably use cloud functions to handle getting full and valid
 * data object from database. This is currently msainly only used for Company default
 * settings and making deep copies of Company. Doesn't even handle Arrays.
 *
 * @param data Raw data from database
 * @param defaults Object containing default data and required and allowed fields.
 */
export const applyDefaults = (data: any, defaults: any) => {
  const result = {};

  if (typeof data !== 'object' || data === null) {
    data = {};
  }

  for (const k in defaults) {
    if (data[k] !== undefined && typeof defaults[k] === typeof data[k]) {
      result[k] =
        typeof data[k] === 'object' && data[k] !== null
          ? applyDefaults(data[k], defaults[k])
          : data[k];
    } else if (defaults[k] !== undefined) {
      result[k] =
        typeof defaults[k] === 'object' && defaults[k] !== null
          ? applyDefaults({}, defaults[k])
          : defaults[k];
    }
  }

  return result;
};

export default applyDefaults;
