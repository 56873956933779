import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import theme from 'theme';

import { setLocale, SupportedLocale } from '../../@shared/locale/index';
import './index.css';
const lang: SupportedLocale = navigator.language as SupportedLocale;
const storedLocale = localStorage.getItem('locale') as SupportedLocale;
if (storedLocale) {
  setLocale(storedLocale);
} else {
  setLocale(lang && lang === SupportedLocale.FI ? lang : SupportedLocale.EN);
}

ReactDOM.hydrate(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement,
);

registerServiceWorker();
