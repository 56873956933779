import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  StyledComponentProps,
  TextField,
  withStyles,
} from '@material-ui/core/';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { i18n } from '@shared/locale';
import {
  CompanyId,
  Contact,
  Customer,
  CustomerWithKey,
  Schema,
  Settings,
  WorksiteRef,
  WorksiteWithKey,
} from '@shared/schema';

import ACSelect from 'components/ACSelect';
import ContactList from 'components/ContactList';
import firebaseApp from 'firebaseApp';
import { ApplicationState } from 'reducers';
import { cancelEdit } from 'reducers/customer/customerActions';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
  validateForm,
} from 'ts-form-validation';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import * as validator from 'validator';
import styles from './styles';

export interface CustomerProps extends DispatchProp, StyledComponentProps {
  editing: boolean;
  companyId: CompanyId;
  editedCustomer?: Customer;
  settings: Settings;
}

export interface WorksiteSelectOption {
  label: string;
  value: string;
}

interface State {
  editMode: boolean;
  customer: CustomerWithKey;
  isContactListValid: boolean;
  selectedWorksiteOptions: WorksiteSelectOption[];
  worksiteOptions: WorksiteSelectOption[];
  worksiteRefs: WorksiteRef;
  form: Form<Customer>;
}

export enum CustomerEditorDialogFieldNames {
  NAME = 'name',
  CUSTOMERNUMBER = 'customerNumber',
  ADDRESS = 'address',
  POSTALCODE = 'postalCode',
  CITY = 'city',
}

const emptyCustomer: Customer = {
  address: '',
  city: '',
  contacts: [],
  customerNumber: '',
  name: '',
  postalCode: '',
  tasks: {},
  worksiteRefs: {},
};

export const rules: FormValidationRules<CustomerWithKey> = {
  defaultMessages: {
    requiredField: () => i18n().ui.required_fields,
  },
  fields: {
    name: {
      required: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 2, max: 30 }) && {
          type: MessageType.ERROR,
          message: i18n().ui.customer_name_length_must_be,
        },
    },
    customerNumber: {
      required: false,
      validate: (value: string) =>
        !validator.isLength(value, { min: 1, max: 30 }) &&
        value !== '' && {
          type: MessageType.ERROR,
          message: i18n().ui.customer_number_length_must_be,
        },
    },
    address: {
      required: false,
      validate: (value: string) =>
        !validator.isLength(value, { min: 2, max: 30 }) &&
        value !== '' && {
          type: MessageType.ERROR,
          message: i18n().ui.customer_address_length_must_be,
        },
    },
    postalCode: {
      required: false,
      trim: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 3, max: 10 }) &&
        value !== '' && {
          type: MessageType.ERROR,
          message: i18n().ui.please_enter_valid_postal_code,
        },
    },
    city: {
      required: false,
      trim: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 2, max: 30 }) &&
        value !== '' && {
          type: MessageType.ERROR,
          message: i18n().ui.customer_city_length_must_be,
        },
    },
  },
  validateForm: form => {
    const messages = {};

    return { ...form, messages };
  },
};

const initialState: State = {
  editMode: false,
  customer: {
    ...emptyCustomer,
  } as CustomerWithKey,
  isContactListValid: true,
  selectedWorksiteOptions: [],
  worksiteOptions: [],
  worksiteRefs: {},
  form: initForm<CustomerWithKey>(
    {
      ...emptyCustomer,
    } as CustomerWithKey,
    rules,
  ),
};

export class CustomerEditorDialog extends React.Component<
  CustomerProps,
  State
> {
  constructor(props: CustomerProps) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  public handleRequestClose = () => {
    this.props.dispatch && this.props.dispatch(cancelEdit());

    this.setState(initialState);
  };

  public componentWillReceiveProps(nextProps: CustomerProps) {
    const { settings } = this.props;
    const editMode = !!nextProps.editedCustomer;

    let customer: CustomerWithKey;
    if (editMode && this.props.editedCustomer !== nextProps.editedCustomer) {
      customer = {
        key: this.state.customer.key,
        ...nextProps.editedCustomer,
      } as CustomerWithKey;
    } else {
      customer = {
        ...emptyCustomer,
      } as CustomerWithKey;
    }

    try {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.WORKSITE)
        .where('customer', '==', '')
        .get()
        .then(snapshot => {
          if (!snapshot) {
            return;
          }

          const worksitesWithKey: WorksiteWithKey[] = collectionToJsonWithIds(
            snapshot,
          );

          const worksiteOptions: WorksiteSelectOption[] = [];
          const worksiteRefs: WorksiteRef = {};

          worksitesWithKey.forEach(worksite => {
            worksiteOptions.push({
              label:
                (settings.general.enableWorksiteNick && worksite.nick) ||
                worksite.name,
              value: worksite.key,
            });

            worksiteRefs[worksite.key] = {
              name: worksite.name,
              nick: worksite.nick,
            };
          });

          this.setState({
            worksiteOptions: [
              ...this.state.worksiteOptions,
              ...worksiteOptions,
            ],
            worksiteRefs: {
              ...this.state.worksiteRefs,
              ...worksiteRefs,
            },
          });
        });
    } catch (err) {
      console.error('Error while fetching worksites from database', err);
    }

    const customerWorksiteOptions: WorksiteSelectOption[] = [];
    const customerWorksiteRefs: WorksiteRef = {};

    Object.keys(customer.worksiteRefs).forEach(key => {
      const worksiteRef = customer.worksiteRefs[key];

      customerWorksiteOptions.push({
        label:
          (settings.general.enableWorksiteNick &&
            worksiteRef.nick !== '' &&
            worksiteRef.nick) ||
          worksiteRef.name,
        value: key,
      });

      customerWorksiteRefs[key] = { ...worksiteRef };
    });

    this.setState(state => ({
      form: {
        ...state.form,
        values: {
          ...state.form.values,
          key: customer.key,
          name: customer.name,
          customerNumber: customer.customerNumber,
          address: customer.address,
          postalCode: customer.postalCode,
          city: customer.city,
          contacts: customer.contacts,
          worksiteRefs: customer.worksiteRefs,
          tasks: customer.tasks,
        },
        isFormValid: customer.name ? true : false,
      },
      editMode,
      selectedWorksiteOptions: customerWorksiteOptions,
      worksiteOptions: [...customerWorksiteOptions],
      worksiteRefs: customerWorksiteRefs,
    }));
  }

  public handleContactListChange = (
    contacts: Contact[],
    isContactListValid: boolean,
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        values: {
          ...this.state.form.values,
          contacts,
        },
      },
      isContactListValid,
    });
  };

  public render() {
    const { classes = {}, editing, settings } = this.props;

    const { values } = this.state.form;

    const customerNameField = `${i18n().ui.customer_name}*`;
    if (!editing) {
      return null;
    }

    return (
      <Dialog open={editing} className={classes.root}>
        <DialogTitle data-test="dialogTitle">
          {this.state.editMode
            ? i18n().ui.modify_customer
            : i18n().ui.add_customer}
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <Divider />

              {this.renderField(
                CustomerEditorDialogFieldNames.NAME,
                customerNameField,
                classes.textField,
              )}
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <Divider />
              {this.renderField(
                CustomerEditorDialogFieldNames.CUSTOMERNUMBER,
                i18n().ui.customer_number,
                classes.textField,
              )}
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <Divider />
              {this.renderField(
                CustomerEditorDialogFieldNames.ADDRESS,
                i18n().ui.address,
                classes.textField,
              )}
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PlaceIcon style={{ visibility: 'hidden' }} />
              </ListItemIcon>
              {this.renderField(
                CustomerEditorDialogFieldNames.POSTALCODE,
                i18n().ui.postalcode,
                classes.postalCodeTextField,
              )}
              <Divider />

              {this.renderField(
                CustomerEditorDialogFieldNames.CITY,
                i18n().ui.city,
                classes.cityTextField,
              )}
            </ListItem>
            <ListItem style={{ flex: 1 }}>
              <ListItemText
                primary={i18n().ui.contact_persons}
                className={classes.textField}
              />
            </ListItem>
            <ContactList
              contacts={values.contacts ? values.contacts : []}
              customerName={values.name}
              onChange={this.handleContactListChange}
              companyId={this.props.companyId}
            />
            {settings.general.enableWorksite && (
              <>
                <ListItem style={{ flex: 1 }}>
                  <ListItemText
                    primary={i18n().ui.customers_worksites}
                    className={classes.textField}
                  />
                </ListItem>
                <ListItem style={{ flex: 1 }}>
                  <FormControl className={classes.formControlACSelect}>
                    <ACSelect
                      data-test="ACSelector"
                      options={this.state.worksiteOptions}
                      onChange={this.handleWorksiteChange}
                      value={this.state.selectedWorksiteOptions}
                      placeholder={
                        this.state.worksiteOptions.length > 0
                          ? `${i18n().ui.select_worksite}`
                          : `${i18n().ui.no_worksites}`
                      }
                      isClearable
                      isMulti
                    />
                  </FormControl>
                </ListItem>
              </>
            )}
          </List>
          <p>{i18n().ui.required_fields}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} data-test="cancelButton">
            {i18n().ui.cancel}
          </Button>
          <Button
            disabled={!this.isFormValid()}
            color="secondary"
            onClick={this.handleSave}
            data-test="onHandleSaveData"
          >
            {i18n().ui.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  private renderField = (
    key: CustomerEditorDialogFieldNames,
    label: string,
    className: string | undefined,
  ) => {
    const {
      values: { [key]: value },
    } = this.state.form;
    return (
      <TextField
        label={label}
        className={className}
        margin="normal"
        onChange={this.handleChange(key)}
        onBlur={this.handleDialogBlur(key)}
        error={this.checkErrorStatus(key)}
        helperText={this.getErrorMessage(key)}
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
        data-test={`${key}-TextField`}
      />
    );
  };

  private isFormValid(): boolean {
    const { isFormValid } = this.state.form;

    const valid = isFormValid && this.state.isContactListValid;

    return valid;
  }

  private handleSave = () => {
    if (!this.props.dispatch) {
      return;
    }

    if (this.state.editMode) {
      this.updateCustomer(
        this.props.companyId,
        this.state.form.values as CustomerWithKey,
      );
    } else {
      this.createCustomer(
        this.props.companyId,
        this.state.form.values as CustomerWithKey,
      );
    }

    this.handleRequestClose();
  };

  private handleChange = (name: keyof Customer) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const values = {
      ...this.state.form.values,
      [name]: e.target.value,
    };

    const form = validateForm(
      {
        ...this.state.form,
        values,
      },
      {
        usePreprocessor: false,
      },
    );

    this.setState({
      form,
    });
  };

  private handleWorksiteChange = (
    selectedWorksiteOptions: WorksiteSelectOption[],
  ) => {
    this.setState({
      selectedWorksiteOptions,
      worksiteOptions: [...this.state.worksiteOptions],
    });
  };

  private handleDialogBlur = (key: keyof Customer) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let form = { ...this.state.form };
    const filled = {
      ...form.filled,
      [key]: true,
    };

    form = validateForm({
      ...this.state.form,
      filled,
    });

    this.setState({
      form,
    });
  };

  private checkErrorStatus = (
    target: CustomerEditorDialogFieldNames,
  ): boolean => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  private getErrorMessage = (target: CustomerEditorDialogFieldNames) => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    return message && message.message;
  };

  private updateCustomer = (
    companyId: CompanyId,
    customer: CustomerWithKey,
  ) => {
    const { selectedWorksiteOptions } = this.state;

    const data = { ...customer };
    const customerId = customer.key;
    delete data.key;

    const batch = firebaseApp.firestore().batch();
    const refsToBeRemoved = data.worksiteRefs;
    data.worksiteRefs = {};

    for (const option of selectedWorksiteOptions) {
      const worksiteUpdate = {
        customer: customerId,
      };

      const worksitePath = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.WORKSITE)
        .doc(option.value);

      if (refsToBeRemoved[option.value]) {
        delete refsToBeRemoved[option.value];
      } else {
        batch.update(worksitePath, worksiteUpdate);
      }
    }

    delete customer.worksiteRefs;

    const customerPath = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.CUSTOMERS)
      .doc(customerId);

    batch.update(customerPath, customer);

    for (const ref in refsToBeRemoved) {
      if (ref) {
        const removeCustomer = {
          customer: '',
        };

        const worksitePath = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.WORKSITE)
          .doc(ref);

        batch.update(worksitePath, removeCustomer);
      }
    }

    try {
      batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  private createCustomer = (companyId: string, customer: CustomerWithKey) => {
    delete customer.key;

    const { selectedWorksiteOptions, worksiteRefs } = this.state;

    const customerRef = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.CUSTOMERS)
      .doc();

    const customerId = customerRef.id;

    const batch = firebaseApp.firestore().batch();

    customer.worksiteRefs = {};

    for (const option of selectedWorksiteOptions) {
      customer.worksiteRefs[option.value] = worksiteRefs[option.value];

      const worksiteUpdate = {
        customer: customerId,
      };

      const worksitePath = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.WORKSITE)
        .doc(option.value);

      batch.update(worksitePath, worksiteUpdate);
    }

    const customerPath = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.CUSTOMERS)
      .doc(customerId);

    batch.set(customerPath, customer);

    batch.commit();
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<CustomerProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    editing: state.customer.editing,
    editedCustomer: state.customer.editedCustomer,
    settings: state.company.activeCompany.settings,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(CustomerEditorDialog),
);
