import {
  Collapse,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { StyledComponentProps, WithTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowDropDown,
  ArrowDropUp,
  CreateOutlined,
  Help,
} from '@material-ui/icons';
import { i18n } from '@shared/locale';
import { MemberWithKey, Settings, TaskWithKey } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import { roundToNext } from '@shared/utils/timeUtils';
import classNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import CustomTableCell from 'theme/styles/tableCell';
import { getTaskWorkingTime, getUserTimerLogsDuration } from 'utils/tasksUtil';
import styles from './styles';

interface Props extends StyledComponentProps, WithTheme {
  task: TaskWithKey;
  members: MemberWithKey[];
  settings: Settings;
  numberOfColumns: number;
  handleChangeWorkingTimeButtonClicked: (task: TaskWithKey) => void;
  handleShowTimerChangeLogButtonClicked: (task: TaskWithKey) => void;
}

interface State {
  open: boolean;
}

class NewTaskReportTableRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  public render() {
    const {
      task,
      members,
      settings,
      numberOfColumns,
      classes = {},
      handleChangeWorkingTimeButtonClicked,
      handleShowTimerChangeLogButtonClicked,
    } = this.props;

    const { open } = this.state;

    let taskWithMultipleUsers = false;
    let userid: string | undefined;
    let taskUser: MemberWithKey | undefined;
    let listOfTaskUsersIds: Array<string | undefined> = [];

    // Check that if task is multi-user or just one member has many log records
    if (task.timerLog) {
      listOfTaskUsersIds = _.uniq(task.timerLog.map(log => log.memberId));
      if (listOfTaskUsersIds.length > 1) {
        taskWithMultipleUsers = true;
      } else if (
        listOfTaskUsersIds.length === 1 &&
        listOfTaskUsersIds[0] !== undefined
      ) {
        userid = listOfTaskUsersIds[0];
      }
    }

    // If it is not multi-user task, trying to find the exact member, because we dont believe now to task.userNane :)
    if (taskWithMultipleUsers === false && userid !== undefined) {
      taskUser = members.find(member => member.key === userid);
    }

    const userNameCellText = taskWithMultipleUsers
      ? i18n().ui.multiple_users
      : taskUser
      ? taskUser.name
      : i18n().ui.unknown_user;
    return (
      <React.Fragment>
        <TableRow
          className={classNames(classes.tableCell, classes.multiUserRow)}
        >
          <CustomTableCell className={classes.tableCell}>
            <div className={classes.userCell}>
              {userNameCellText}
              {taskWithMultipleUsers && (
                <IconButton onClick={() => this.setState({ open: !open })}>
                  {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
              )}
            </div>
          </CustomTableCell>
          <CustomTableCell className={classes.tableCell}>
            {(task.title && task.title.value) || task.jobtypeName}
          </CustomTableCell>
          {settings.general.enableCustomerTaskId && (
            <CustomTableCell className={classes.tableCell}>
              {task.customerTaskId || i18n().ui.no_task_id}
            </CustomTableCell>
          )}
          {settings.general.enableJobType && (
            <CustomTableCell className={classes.tableCell}>
              {task.jobtypeName}
            </CustomTableCell>
          )}
          {settings.general.enableCustomer && (
            <CustomTableCell className={classes.tableCell}>
              {task.customerName ? task.customerName : i18n().ui.no_customer}
            </CustomTableCell>
          )}
          {settings.general.enableWorksite &&
            (settings.general.enableWorksiteNick &&
            task.worksite &&
            task.worksite.nick &&
            task.worksite.nick !== '' ? (
              <CustomTableCell className={classes.tableCell}>
                {task.worksite.nick}
              </CustomTableCell>
            ) : (
              <CustomTableCell className={classes.tableCell}>
                {task.worksite ? task.worksite.name : i18n().ui.no_worksite}
              </CustomTableCell>
            ))}
          <CustomTableCell className={classes.tableCell}>
            {task.timerLog &&
              moment(task.timerLog[0].started).format('D.M.YYYY HH:mm')}
          </CustomTableCell>
          <CustomTableCell className={classes.tableCell}>
            {task.timerLog &&
              moment(task.timerLog[task.timerLog.length - 1].ended).format(
                'D.M.YYYY HH:mm',
              )}
          </CustomTableCell>
          <CustomTableCell className={classes.tableCell}>
            <div className={classes.workingHoursContainer}>
              {momentDurationFormat(
                roundToNext(getTaskWorkingTime(task), 'minute'),
                DurationFormatPreset.HHMM,
              )}

              <IconButton
                onClick={() => handleChangeWorkingTimeButtonClicked(task)}
                disabled={
                  taskWithMultipleUsers ? false : taskUser ? false : true
                }
              >
                {<CreateOutlined />}
              </IconButton>
            </div>
          </CustomTableCell>
          {settings.general.enableVat && (
            <CustomTableCell className={classes.tableCell}>
              {task.vat && task.vat.percent >= 0
                ? `${task.vat.percent}% (${task.vat.name})`
                : i18n().ui.select_vat}
            </CustomTableCell>
          )}
          <CustomTableCell>
            {task.timerChangeLog ? (
              <div onClick={() => handleShowTimerChangeLogButtonClicked(task)}>
                <Help classes={{ root: classes.logIcon }} />
              </div>
            ) : (
              ''
            )}
          </CustomTableCell>
        </TableRow>
        {taskWithMultipleUsers && (
          <TableRow className={classes.multipleUsersRow}>
            <CustomTableCell
              className={classes.multipleUsersCell}
              colSpan={numberOfColumns}
            >
              <Collapse in={open}>
                <div className={classes.multipleUsersTableContainer}>
                  <Table className={classes.multipleUsersTable}>
                    <TableHead className={classes.customTableHead}>
                      <TableRow className={classes.customTableHead}>
                        <CustomTableCell className={classes.headCell}>
                          {i18n().ui.name}
                        </CustomTableCell>
                        <CustomTableCell className={classes.headCell}>
                          {i18n().ui.started}
                        </CustomTableCell>
                        <CustomTableCell className={classes.headCell}>
                          {i18n().ui.completed}
                        </CustomTableCell>
                        <CustomTableCell className={classes.headCell}>
                          {i18n().ui.working_time}
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listOfTaskUsersIds.map((id, index) => {
                        const user = members.find(i => i.key === id);

                        const started =
                          id && this.getTaskStartedTimestampForUserId(task, id);
                        const ended =
                          id && this.getTaskEndedTimestampForUserId(task, id);
                        const workingTime =
                          user &&
                          getUserTimerLogsDuration(task, false, user.key);

                        return (
                          <TableRow key={(user && user.key) || index}>
                            <CustomTableCell>
                              {(user && user.name) || i18n().ui.unknown_user}
                            </CustomTableCell>
                            <CustomTableCell>
                              {started &&
                                moment(started).format('D.M.YYYY HH:mm')}
                            </CustomTableCell>
                            <CustomTableCell>
                              {ended && moment(ended).format('D.M.YYYY HH:mm')}
                            </CustomTableCell>
                            <CustomTableCell>
                              {workingTime &&
                                momentDurationFormat(
                                  roundToNext(workingTime, 'minute'),
                                  DurationFormatPreset.HHMM,
                                )}
                            </CustomTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Collapse>
            </CustomTableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  private getTaskStartedTimestampForUserId(task: TaskWithKey, userId: string) {
    let startDate;
    if (task.timerLog) {
      const userLogs = task.timerLog.filter(log => log.memberId === userId);

      if (userLogs) {
        startDate = userLogs.sort(
          (log1, log2) => log1.started - log2.started,
        )[0].started;
      }
    }

    return startDate;
  }

  private getTaskEndedTimestampForUserId(task: TaskWithKey, userId: string) {
    let startDate;
    if (task.timerLog) {
      const userLogs = task.timerLog.filter(
        log => log.memberId === userId && log.ended,
      );

      if (userLogs) {
        startDate = userLogs.sort((log1, log2) => log1.ended! - log2.ended!)[
          userLogs.length - 1
        ].ended;
      }
    }

    return startDate;
  }
}

export default withStyles(styles, { withTheme: true })(NewTaskReportTableRow);
