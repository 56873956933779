import { ActionCreator, AnyAction } from 'redux';

export const enum ImportTasksActionType {
  IMPORT_TASKS_NEW = 'IMPORT_TASKS_NEW',
  IMPORT_TASKS_CANCEL = 'IMPORT_TASKS_CANCEL',
}

export interface NewImportTasksAction extends AnyAction {
  type: ImportTasksActionType.IMPORT_TASKS_NEW;
}

export const newImportTasks: ActionCreator<NewImportTasksAction> = () => {
  return {
    type: ImportTasksActionType.IMPORT_TASKS_NEW,
  };
};

export interface CancelImportTasksAction extends AnyAction {
  type: ImportTasksActionType.IMPORT_TASKS_CANCEL;
}

export const cancelImportTasks: ActionCreator<CancelImportTasksAction> = () => ({
  type: ImportTasksActionType.IMPORT_TASKS_CANCEL,
});

export type ImportTasksAction =
  | AnyAction
  | NewImportTasksAction
  | CancelImportTasksAction;
