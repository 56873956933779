/**
 *
 *
 */

export const enum NAVIGATION_PATH {
  ACCOUNT = '/__account',
  LOGIN = '/__login',
  MAP = '/__map',
  HOME = '/__home',
  TASKBANK = '/__taskbank',
  CLIENTS = '/__clients',
  CUSTOMERS = '/__customers',
  RESOURCES = '/__resources',
  RESOURCETIMEREPORTS = '/__resource_timereports',
  REPORTS = '/__reports',
  SETTINGS = '/__settings',
  BACKUP = '/__backup__',
  AWAIT_AUTH = '/__await_auth',
  CHAT = '/__chat',
}

export function verifyEndSlash(url: string): string {
  if (url === '') {
    return '';
  }
  const char = url.charAt(url.length - 1);

  return char === '/' ? url : url + '/';
}

function removeBeginningSlash(url: string): string {
  if (url === '') {
    return '';
  }
  return url.charAt(0) === '/' ? url.substr(1, url.length) : url;
}
/**
 * Extract the name of file described in the url
 *
 * @param {string} uri url address
 */
export function extractName(uri: string) {
  if (!uri) {
    return undefined;
  }

  const index = uri.lastIndexOf('/');
  if (index < 0) {
    return uri;
  }
  return uri.substring(index, uri.length);
}

/**
 * Extract path form router match object and target
 * @param {*} match React Router match -object
 * @param {string} target Target resource name
 */
export function extractPath(match: any, target: string) {
  let url = match.url;

  url = verifyEndSlash(url);
  target = removeBeginningSlash(target);

  return url + target;
}
