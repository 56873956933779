import { StyledComponentProps, WithTheme } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { i18n } from '@shared/locale';
import {
  GeneralSettings,
  MemberWithKey,
  Settings,
  TaskWithKey,
} from '@shared/schema';
import SortingHeadTableCell from 'components/SortingHeadTableCell/SortingHeadTableCell';
import { ApplicationState } from 'reducers';
import CustomTableCell from 'theme/styles/tableCell';
import {
  OrderByValues,
  OrderOptions,
} from '../../containers/TaskReportContainer/TaskReportContainer';
import TimerChangeLogDialog from '../TimerChangeLog/TimerChangeLog';
import ChangeWorkingTimeDialog from './ChangeWorkingTimeDialog/ChangeWorkingTimeDialog';
import NewTaskReportTableRow from './NewTaskReportTableRow';
import { styles } from './styles';

export interface TaskReportTableProps
  extends DispatchProp,
    Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  settings: Settings;
  selectedMember?: MemberWithKey;
  order: OrderOptions;
  orderBy: OrderByValues;
  showNoTaskNotification: boolean;
  taskList: TaskWithKey[];
  handleCloseSnackBar: () => void;
  handleTableSortChange: (value: OrderByValues) => void;
  settingsOverrides?: Partial<GeneralSettings>;
  members: MemberWithKey[];
}

interface State {
  openChangeWorkingTimeDialog: boolean;
  openTimerChangeLogDialog: boolean;
  selectedTask?: TaskWithKey;
  isPreparing: boolean;
}

export class TaskReportTable extends React.Component<
  TaskReportTableProps,
  State
> {
  constructor(props: TaskReportTableProps) {
    super(props);

    this.state = {
      openChangeWorkingTimeDialog: false,
      openTimerChangeLogDialog: false,
      isPreparing: true,
    };
  }

  public render() {
    const { classes = {}, settings } = this.props;

    return (
      <div style={{ maxHeight: '100%' }}>
        {
          this.props.taskList && this.props.taskList.length > 0 && (
            <div className={classes.rootContainer}>
              <div
                className={classes.tableContainer}
                data-test="tableContainer"
              >
                <Table>
                  <TableHead>
                    <TableRow
                      classes={{ root: classes.tableHead }}
                      data-test="tableHeadRow"
                    >
                      <CustomTableCell className={classes.tableCellHeader}>
                        {i18n().concepts.member}
                      </CustomTableCell>
                      <SortingHeadTableCell
                        data-test="sortingHeadTableCellTaskName"
                        selectedOrderBy={this.props.orderBy}
                        orderBy={OrderByValues.taskName}
                        order={this.props.order}
                        handleSortClick={this.handleTableSortChange}
                        title={i18n().ui.task}
                      />
                      {settings.general.enableCustomerTaskId && (
                        <CustomTableCell className={classes.tableCellHeader}>
                          {i18n().ui.task_id}
                        </CustomTableCell>
                      )}
                      {settings.general.enableJobType && (
                        <SortingHeadTableCell
                          data-test="sortingHeadTableCellJobtypeName"
                          selectedOrderBy={this.props.orderBy}
                          orderBy={OrderByValues.jobtypeName}
                          order={this.props.order}
                          handleSortClick={this.handleTableSortChange}
                          title={i18n().concepts.jobtype}
                        />
                      )}
                      {settings.general.enableCustomer && (
                        <SortingHeadTableCell
                          data-test="sortingHeadTableCellCustomerName"
                          selectedOrderBy={this.props.orderBy}
                          orderBy={OrderByValues.customerName}
                          order={this.props.order}
                          handleSortClick={this.handleTableSortChange}
                          title={i18n().ui.customer}
                        />
                      )}
                      {settings.general.enableWorksite && (
                        <SortingHeadTableCell
                          data-test="sortingHeadTableCellWorksiteName"
                          selectedOrderBy={this.props.orderBy}
                          orderBy={OrderByValues.worksiteName}
                          order={this.props.order}
                          handleSortClick={this.handleTableSortChange}
                          title={i18n().ui.worksite}
                        />
                      )}
                      <SortingHeadTableCell
                        data-test="sortingHeadTableCellStartedDate"
                        selectedOrderBy={this.props.orderBy}
                        orderBy={OrderByValues.startedDate}
                        order={this.props.order}
                        handleSortClick={this.handleTableSortChange}
                        title={i18n().ui.started}
                      />
                      <SortingHeadTableCell
                        data-test="sortingHeadTableCellCompletedDate"
                        selectedOrderBy={this.props.orderBy}
                        orderBy={OrderByValues.completedDate}
                        order={this.props.order}
                        handleSortClick={this.handleTableSortChange}
                        title={i18n().ui.completed}
                      />
                      <SortingHeadTableCell
                        data-test="sortingHeadTableCellWorkingHours"
                        selectedOrderBy={this.props.orderBy}
                        orderBy={OrderByValues.workingHours}
                        order={this.props.order}
                        handleSortClick={this.handleTableSortChange}
                        title={i18n().ui.working_time}
                      />
                      {settings.general.enableVat && (
                        <CustomTableCell className={classes.tableCellHeader}>
                          {i18n().ui.vat_base}
                        </CustomTableCell>
                      )}

                      <CustomTableCell className={classes.tableCellHeader}>
                        {''}
                      </CustomTableCell>

                      {/*
                    TODO: Write logic for opening/displayng messages  
                   
                   <CustomTableCell className={classes.tableCellHeader}>
                      Messages
                    </CustomTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {this.drawTable()}
                  </TableBody>
                </Table>
              </div>
            </div>
          )

          // ))
        }
        {this.state.selectedTask && this.state.openChangeWorkingTimeDialog && (
          <ChangeWorkingTimeDialog
            members={this.props.members}
            open={this.state.openChangeWorkingTimeDialog}
            task={this.state.selectedTask}
            onClose={() => {
              this.setState({ openChangeWorkingTimeDialog: false });
            }}
          />
        )}
        {this.state.selectedTask && (
          <TimerChangeLogDialog
            timerChangeLog={this.state.selectedTask.timerChangeLog}
            open={this.state.openTimerChangeLogDialog}
            closeChangeLog={() => {
              this.setState({ openTimerChangeLogDialog: false });
            }}
            members={this.props.members}
          />
        )}
      </div>
    );
  }

  /**
   * Handles when sorting changes
   */
  private handleTableSortChange = (value: OrderByValues) => {
    this.props.handleTableSortChange(value);
  };

  /**
   * Iterate through this.props.taskList, format the data and generate table rows
   */
  private drawTable = () => {
    if (!this.props.taskList) {
      // this.setState({ isPreparing: false });
      return null;
    }

    const { taskList, settings } = this.props;

    const numberOfColumns: number =
      6 +
      [
        settings.general.enableJobType,
        settings.general.enableWorksite,
        settings.general.enableCustomer,
        settings.general.enableCustomerTaskId,
        settings.general.enableVat,
      ].reduce(
        (val1, val2): number =>
          (val1 !== undefined ? Number(val1) : 0) +
          (val2 !== undefined ? Number(val2) : 0),
        0,
      );

    const rows = taskList.map((task, index) => (
      <NewTaskReportTableRow
        key={index}
        task={task}
        settings={this.props.settings}
        members={this.props.members}
        numberOfColumns={numberOfColumns}
        handleChangeWorkingTimeButtonClicked={(selectedTask: TaskWithKey) => {
          this.setState({
            openChangeWorkingTimeDialog: true,
            selectedTask,
          });
        }}
        handleShowTimerChangeLogButtonClicked={(selectedTask: TaskWithKey) => {
          this.setState({
            openTimerChangeLogDialog: true,
            selectedTask,
          });
        }}
      />
    ));

    // this.setState({ isPreparing: false });
    return rows;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TaskReportTableProps>,
) => {
  return {
    ...ownProps,
    selectedMember: state.taskReport.selectedMember,
    settings: state.company.activeCompany.settings,
  } as TaskReportTableProps;
};

export default withRouter<any>(
  connect<TaskReportTableProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(TaskReportTable),
  ),
);
