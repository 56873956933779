import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

export interface LoadingSpinnerProps {
  classes: any;
  size?: number;
  message?: string;
}

class LoadingSpinner extends React.PureComponent<LoadingSpinnerProps> {
  public render() {
    const { classes, size, message } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.progress}>
          <CircularProgress className={classes.circle} size={size || 80} />
          {message && (
            <div className={classes.text}>
              <br />
              {message}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '7rem',
    margin: 'auto',
  },
  circle: {
    display: 'block',
    margin: 'auto',
    color: theme.palette.secondary.main,
  },
  progress: {
    alignItems: 'center',
    height: '50%',
  },
  text: {
    color: 'gray',
  },
});

export default withStyles(styles)(LoadingSpinner);
