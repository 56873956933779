import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  formControlSmall: {
    margin: theme.spacing.unit,
  },
  tasksList: {
    maxHeight: '278px',
    overflow: 'auto',
    marginTop: '5px',
  },
  list: {
    padding: '15px',
    marginTop: '10px',
    backgroundColor: '#f1f1f1',
    borderRadius: '5px',
  },
  listItem: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  checkboxListItem:{
    paddingLeft:'33px'
  },
  helpIcon: {
    marginLeft:'7px'
  },
  listText: {
    fontSize: '16px',
    lineHeight: '1.2',
  },
  textField: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  taskHeadline: {
    fontSize: '1.2rem',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    marginLeft: '-5px',
  },
  taskListHeadline: {
    fontSize: '1.2rem',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    paddingTop: '15px',
  },
  addButton: {
    marginTop: '15px',
    marginBottom: '15px',
    marginLeft: '10px',
  },
  errorMessageHelperText: {
    marginTop: '3px',
    fontSize: '12px',
  },
  errorMessageWarning: {
    fontWeight: 1000,
    fontStyle: 'italic',
    color: theme.priorities.high.color,
    paddingTop: '3px',
    marginLeft: '30px',
  },
});

export default styles;
