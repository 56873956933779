import { Theme, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { extractName, extractPath } from 'paths';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export interface DrawerItemProps extends RouteComponentProps {
  currentPath: string | undefined;
  path: string;
  icon: JSX.Element;
  title: string;
  theme: Theme;
  drawerOpen: boolean;
}

interface DrawerItemState {
  tooltipOnOFF: boolean;
}

export class DrawerItem extends React.PureComponent<
  DrawerItemProps,
  DrawerItemState
> {
  public state: DrawerItemState = {
    tooltipOnOFF: false,
  };

  public render() {
    const {
      currentPath,
      path,
      icon,
      title,
      theme,
      history,
      match,
    } = this.props;

    return !this.props.drawerOpen ? (
      <Tooltip
        data-test="tooltip"
        disableTouchListener={this.props.drawerOpen}
        disableHoverListener={this.props.drawerOpen}
        disableFocusListener
        title={title}
        placement="right"
      >
        <ListItem
          button={true}
          selected={
            currentPath !== undefined && path === extractName(currentPath)
          }
          onClick={() => history.replace(extractPath(match, path))}
          style={{
            backgroundColor:
              path === currentPath
                ? theme.toolbar.listItem.selected
                : 'transparent',
          }}
        >
          <ListItemIcon style={{ marginLeft: '8px' }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Tooltip>
    ) : (
      <ListItem
        button={true}
        selected={
          currentPath !== undefined && path === extractName(currentPath)
        }
        onClick={() => history.replace(extractPath(match, path))}
        style={{
          backgroundColor:
            path === currentPath
              ? theme.toolbar.listItem.selected
              : 'transparent',
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  }
}

export default withRouter(DrawerItem);
