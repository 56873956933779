import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  dateTimePicker: {
    '& div': {
      '& fieldset': {
        borderRadius: '0px',
      },
    },
  },
  calendar: {
    maxHeight: '35px',
    backgroundColor: 'white',
    maxWidth: '185px',
    borderRadius: 0,
  },
});
