import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  taskListItem: {
    backgroundColor: theme.colors.white,
    margin: '2px 0 2px 0',
    borderRadius: '5px',
  },
  header: {
    flexDirection: 'row',
    marginLeft: '5%',
    alignSelf: 'center',
  },
  headerText: {
    fontSize: '17px',
  },
  subHeaderText: {
    marginTop: '5px',
    fontSize: '13px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  chatTaskContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: '5px',
  },
  expansionDetails: {
    flexDirection: 'column',
  },
  worksite: {
    marginBottom: '10px',
    fontSize: '16px',
  },
  done: {
    borderBottom: `5px solid ${theme.timeline.status.done.background}`,
    marginBottom: '5px',
  },
  active: {
    borderBottom: `5px solid ${theme.timeline.status.active.background}`,
    marginBottom: '5px',
  },
  undone: {
    borderBottom: `5px solid ${theme.timeline.status.undone.background}`,
    marginBottom: '5px',
  },
  paused: {
    borderBottom: `5px solid ${theme.timeline.status.paused.background}`,
    marginBottom: '5px',
  },
  late: {
    borderBottom: `5px solid ${theme.timeline.status.late.background}`,
    marginBottom: '5px',
  },
});

export default styles;
