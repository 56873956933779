import { TextField } from '@material-ui/core';
import {
  StyledComponentProps,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import * as React from 'react';

export interface HeaderBarProps extends StyledComponentProps {
  /**
   * Placeholder text for search component
   */
  searchComponentPlaceholder: string;
  /**
   * Search on change handler
   * @param {string}filter string value
   */
  searchOnChangeHandler: (filter: string) => void;
}

class HeaderBar extends React.PureComponent<HeaderBarProps> {
  public render() {
    const { classes = {}, searchComponentPlaceholder } = this.props;
    return (
      <div className={classes.container}>
        <TextField
          placeholder={searchComponentPlaceholder}
          className={classes.searchInput}
          margin="normal"
          onChange={this.searchOnChangeHandler}
        />
        <div className={classes.buttons}>{this.props.children}</div>
      </div>
    );
  }

  /**
   * Search change handler
   */
  private searchOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.searchOnChangeHandler(e.target.value);
  };
}

/**
 * Styles for HeadBar component
 * @param theme
 */
const styles = (theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: '56px',
    background: theme.colors.grey,
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    'flex-wrap': 'wrap',
  },

  buttons: {
    display: 'flex',
  },

  searchInput: {
    width: '350px',
    marginRight: '48px',
  },
});

export default withStyles(styles)(HeaderBar);
