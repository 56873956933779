import { StyledComponentProps, Theme } from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ChatMessage } from '@shared/schema';
import moment from 'moment';
import * as React from 'react';

import classNames from 'classnames';

export interface ChatMessageBubbleProps extends StyledComponentProps {
  data: ChatMessage;
  sameUser: boolean;
  itsMe: boolean;
}

class ChatMessageBubble extends React.PureComponent<ChatMessageBubbleProps> {
  public render() {
    const { data, itsMe, classes = {} } = this.props;

    let messageClass = itsMe ? classes.messageRight : classes.messageLeft;
    if (data.id === undefined) {
      messageClass = classNames(messageClass, classes.messageSending);
    }

    return (
      <div className={messageClass}>
        <div
          className={itsMe ? classes.chatBubbleLeft : classes.chatBubbleRight}
        >
          <div className={classes.chatMessageText}>{data.message}</div>
          <div className={classes.chatDateLeft}>
            {`${data.userName} ${moment(data.date).format('L HH:mm')}`}
          </div>
        </div>
      </div>
    );
  }

  public renderAvatar(
    classes: Partial<ClassNameMap<any>>,
    message: ChatMessage,
    sameUser: boolean,
  ) {
    if (!message.avatar || sameUser) {
      return null;
    }
    return (
      <img
        alt={message.userName}
        className={classes.avatar}
        src={message.avatar}
      />
    );
  }
}

const styles = (theme: Theme) => ({
  messageRight: {
    position: 'relative',
    minHeight: 66,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  messageLeft: {
    position: 'relative',
    minHeight: 66,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  chatUserInfoLeft: {
    padding: 8,
    width: 44,
    textAlign: 'center',
    position: 'absolute',
  },
  chatUserInfoRight: {
    padding: 8,
    width: 44,
    textAlign: 'center',
    position: 'absolute',
    right: 0,
  },
  chatUserName: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 9,
  },
  chatBubbleLeft: {
    margin: 4,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'rgba(153, 202, 60, 0.18)',
    marginLeft: 60,
    minHeight: 54,
    overflowWrap: 'break-word',
    minWidth: '12%',
    maxWidth: '70%',
  },
  chatBubbleRight: {
    margin: 4,
    padding: 10,
    borderRadius: 10,
    backgroundColor: '#E5E5E5;',
    marginRight: 60,
    minHeight: 54,
    overflowWrap: 'break-word',
    minWidth: '12%',
    maxWidth: '70%',
  },
  chatDateLeft: {
    marginTop: 6,
    color: '#3c3c3ca0',
    textAlign: 'right',
    fontSize: 10,
  },
  chatDateRight: {
    marginTop: 6,
    color: '#3c3c3ca0',
    textAlign: 'left',
    fontSize: 10,
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  messageSending: {
    opacity: 0.33,
  },
});

export default withStyles(styles as any, { withTheme: true })(
  ChatMessageBubble,
);
