import { ActionCreator, AnyAction } from 'redux';
import {
  JobTypeWithKey,
  WorksiteWithKey,
} from '../../../../../@shared/schema/index';

import {
  CustomerWithKey,
  HourTypeLabel,
  MemberWithKey,
  TaskWithKey,
} from '@shared/schema';
import {
  OrderByValues,
  OrderOptions,
  TaskStatusRadioButtonOptions,
} from '../../../containers/Private/Reports/containers/TaskReport/containers/Task/containers/TaskReportContainer/TaskReportContainer';

export const enum TaskReportActionType {
  UPDATE_HIDDEN_MEMBERS = 'UPDATE_HIDDEN_MEMBERS',
  UPDATE_HIDDEN_JOBTYPES = 'UPDATE_HIDDEN_JOBTYPES',
  UPDATE_HIDDEN_CUSTOMERS = 'UPDATE_HIDDEN_CUSTOMERS',
  UPDATE_HIDDEN_STATUSES = 'UPDATE_HIDDEN_STATUSES',
  UPDATE_HIDDEN_WORKSITES = 'UPDATE_HIDDEN_WORKSITES',

  UPDATE_SELECTED_MEMBER = 'UPDATE_SELECTED_MEMBER',
  UPDATE_JOBTYPE_SEARCH = 'UPDATE_JOBTYPE_SEARCH',
  UPDATE_SELECTED_HOUR_TYPE = 'UPDATE_SELECTED_HOUR_TYPE',
  UPDATE_SELECTED_CUSTOMER = 'UPDATE_SELECTED_CUSTOMER',
  UPDATE_STARTED_DATE_START = 'UPDATE_STARTED_DATE_START',
  UPDATE_STARTED_DATE_END = 'UPDATE_STARTED_DATE_END',
  UPDATE_COMPLETED_DATE_START = 'UPDATE_COMPLETED_DATE_START',
  UPDATE_COMPLETED_DATE_END = 'UPDATE_COMPLETED_DATE_END',
  UPDATE_SELECTED_TASK_STATUS = 'UPDATE_SELECTED_TASK_STATUS',
  UPDATE_TASK_LIST = 'UPDATE_TASK_LIST',
  UPDATE_FILTERED_TASK_LIST = 'UPDATE_FILTERED_TASK_LIST',
  UPDATE_TABLE_SORT_OPTIONS = 'UPDATE_TABLE_SORT_OPTIONS',
  UPDATE_SELECTED_TASK = 'UPDATE_SELECTED_TASK',

  UPDATE_ISTABLEREADY = 'UPDATE_ISTABLEREADY',
}

export interface UpdateIsTableReadyAction extends AnyAction {
  type: TaskReportActionType.UPDATE_ISTABLEREADY;
  payload: {
    isTableReady: boolean;
  };
}
export interface UpdateHiddenWorksitesAction extends AnyAction {
  type: TaskReportActionType.UPDATE_HIDDEN_WORKSITES;
  payload: {
    hiddenWorksites: WorksiteWithKey[] | undefined;
  };
}
export interface UpdateHiddenMembersAction extends AnyAction {
  type: TaskReportActionType.UPDATE_HIDDEN_MEMBERS;
  payload: {
    hiddenMembers: MemberWithKey[] | undefined;
  };
}

export interface UpdateHiddenJobtypesAction extends AnyAction {
  type: TaskReportActionType.UPDATE_HIDDEN_JOBTYPES;
  payload: {
    hiddenJobtypes: JobTypeWithKey[] | undefined;
  };
}

export interface UpdateHiddenCustomersAction extends AnyAction {
  type: TaskReportActionType.UPDATE_HIDDEN_CUSTOMERS;
  payload: {
    hiddenCustomers: CustomerWithKey[] | undefined;
  };
}

export interface UpdateHiddenStatusesAction extends AnyAction {
  type: TaskReportActionType.UPDATE_HIDDEN_STATUSES;
  payload: {
    hiddenStatuses: TaskStatusRadioButtonOptions[] | undefined;
  };
}
export interface UpdateJobtypeSearchAction extends AnyAction {
  type: TaskReportActionType.UPDATE_JOBTYPE_SEARCH;
  payload: {
    jobTypeSearch: string;
  };
}

export interface UpdateSelectedHourTypeAction extends AnyAction {
  type: TaskReportActionType.UPDATE_SELECTED_HOUR_TYPE;
  payload: {
    selectedHourType: HourTypeLabel;
  };
}

export interface UpdateSelectedCustomerAction extends AnyAction {
  type: TaskReportActionType.UPDATE_SELECTED_CUSTOMER;
  payload: {
    selectedCustomer?: CustomerWithKey;
  };
}

export interface UpdateStartedDateStart extends AnyAction {
  type: TaskReportActionType.UPDATE_STARTED_DATE_START;
  payload: {
    startedDateStart?: Date;
  };
}

export interface UpdateStartedDateEnd extends AnyAction {
  type: TaskReportActionType.UPDATE_STARTED_DATE_END;
  payload: {
    startedDateEnd?: Date;
  };
}

export interface UpdateCompletedDateStart extends AnyAction {
  type: TaskReportActionType.UPDATE_COMPLETED_DATE_START;
  payload: {
    completedDateStart?: Date;
  };
}

export interface UpdateCompletedDateEnd extends AnyAction {
  type: TaskReportActionType.UPDATE_COMPLETED_DATE_END;
  payload: {
    completedDateEnd?: Date;
  };
}

export interface UpdateSelectedTaskStatusAction extends AnyAction {
  type: TaskReportActionType.UPDATE_SELECTED_TASK_STATUS;
  payload: {
    selectedTaskStatus: TaskStatusRadioButtonOptions;
  };
}

export interface UpdateTaskListAction extends AnyAction {
  type: TaskReportActionType.UPDATE_TASK_LIST;
  payload: {
    taskList: TaskWithKey[];
  };
}

export interface UpdateFilteredTaskListAction extends AnyAction {
  type: TaskReportActionType.UPDATE_FILTERED_TASK_LIST;
  payload: {
    filteredTaskList: TaskWithKey[];
  };
}

export interface UpdateTableSortOptionsAction extends AnyAction {
  type: TaskReportActionType.UPDATE_TABLE_SORT_OPTIONS;
  payload: {
    order?: OrderOptions;
    orderBy?: OrderByValues;
  };
}

export interface UpdateSelectedMemberAction extends AnyAction {
  type: TaskReportActionType.UPDATE_SELECTED_MEMBER;
  payload: {
    selectedMember?: MemberWithKey;
  };
}

export interface UpdateSelectedTask extends AnyAction {
  type: TaskReportActionType.UPDATE_SELECTED_TASK;
  payload: {
    selectedTaskKey: string | undefined;
  };
}

export const updateIsTableReady: ActionCreator<UpdateIsTableReadyAction> = (
  isTableReady: boolean,
) => {
  return {
    type: TaskReportActionType.UPDATE_ISTABLEREADY,
    payload: {
      isTableReady,
    },
  };
};

export const updateHiddenWorksites: ActionCreator<UpdateHiddenWorksitesAction> = (
  hiddenWorksites: WorksiteWithKey[] | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_HIDDEN_WORKSITES,
    payload: {
      hiddenWorksites,
    },
  };
};

export const updateHiddenStatuses: ActionCreator<UpdateHiddenStatusesAction> = (
  hiddenStatuses: TaskStatusRadioButtonOptions[] | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_HIDDEN_STATUSES,
    payload: {
      hiddenStatuses,
    },
  };
};

export const updateHiddenMembers: ActionCreator<UpdateHiddenMembersAction> = (
  hiddenMembers: MemberWithKey[] | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_HIDDEN_MEMBERS,
    payload: {
      hiddenMembers,
    },
  };
};

export const updateHiddenJobtypes: ActionCreator<UpdateHiddenJobtypesAction> = (
  hiddenJobtypes: JobTypeWithKey[] | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_HIDDEN_JOBTYPES,
    payload: {
      hiddenJobtypes,
    },
  };
};

export const updateHiddenCustomers: ActionCreator<UpdateHiddenCustomersAction> = (
  hiddenCustomers: CustomerWithKey[] | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_HIDDEN_CUSTOMERS,
    payload: {
      hiddenCustomers,
    },
  };
};

export const updateJobTypeSearch: ActionCreator<UpdateJobtypeSearchAction> = (
  jobTypeSearch: string,
) => {
  return {
    type: TaskReportActionType.UPDATE_JOBTYPE_SEARCH,
    payload: {
      jobTypeSearch,
    },
  };
};

export const updateSelectedHourType: ActionCreator<UpdateSelectedHourTypeAction> = (
  selectedHourType: HourTypeLabel,
) => {
  return {
    type: TaskReportActionType.UPDATE_SELECTED_HOUR_TYPE,
    payload: {
      selectedHourType,
    },
  };
};

export const updateSelectedCustomer: ActionCreator<UpdateSelectedCustomerAction> = (
  selectedCustomer?: CustomerWithKey,
) => {
  return {
    type: TaskReportActionType.UPDATE_SELECTED_CUSTOMER,
    payload: {
      selectedCustomer,
    },
  };
};

export const updateStartedDateStart: ActionCreator<UpdateStartedDateStart> = (
  startedDateStart?: Date,
) => {
  return {
    type: TaskReportActionType.UPDATE_STARTED_DATE_START,
    payload: {
      startedDateStart,
    },
  };
};

export const updateStartedDateEnd: ActionCreator<UpdateStartedDateEnd> = (
  startedDateEnd?: Date,
) => {
  return {
    type: TaskReportActionType.UPDATE_STARTED_DATE_END,
    payload: {
      startedDateEnd,
    },
  };
};

export const updateCompletedDateStart: ActionCreator<UpdateCompletedDateStart> = (
  completedDateStart?: Date,
) => {
  return {
    type: TaskReportActionType.UPDATE_COMPLETED_DATE_START,
    payload: {
      completedDateStart,
    },
  };
};

export const updateCompletedDateEnd: ActionCreator<UpdateCompletedDateEnd> = (
  completedDateEnd?: Date,
) => {
  return {
    type: TaskReportActionType.UPDATE_COMPLETED_DATE_END,
    payload: {
      completedDateEnd,
    },
  };
};

export const updateSelectedTaskStatus: ActionCreator<UpdateSelectedTaskStatusAction> = (
  selectedTaskStatus: TaskStatusRadioButtonOptions,
) => {
  return {
    type: TaskReportActionType.UPDATE_SELECTED_TASK_STATUS,
    payload: {
      selectedTaskStatus,
    },
  };
};

export const updateTaskList: ActionCreator<UpdateTaskListAction> = (
  taskList: TaskWithKey[],
) => {
  return {
    type: TaskReportActionType.UPDATE_TASK_LIST,
    payload: {
      taskList,
    },
  };
};

export const updateFilteredTaskList: ActionCreator<UpdateFilteredTaskListAction> = (
  filteredTaskList: TaskWithKey[],
) => {
  return {
    type: TaskReportActionType.UPDATE_FILTERED_TASK_LIST,
    payload: {
      filteredTaskList,
    },
  };
};

export const updateTableSortOptions: ActionCreator<UpdateTableSortOptionsAction> = (
  order?: OrderOptions,
  orderBy?: OrderByValues,
) => {
  return {
    type: TaskReportActionType.UPDATE_TABLE_SORT_OPTIONS,
    payload: {
      order,
      orderBy,
    },
  };
};

export const updateSelectedMember: ActionCreator<UpdateSelectedMemberAction> = (
  selectedMember?: MemberWithKey,
) => {
  return {
    type: TaskReportActionType.UPDATE_SELECTED_MEMBER,
    payload: { selectedMember },
  };
};

export const updateSelectedTask: ActionCreator<UpdateSelectedTask> = (
  selectedTaskKey: string | undefined,
) => {
  return {
    type: TaskReportActionType.UPDATE_SELECTED_TASK,
    payload: {
      selectedTaskKey,
    },
  };
};

export type TaskReportAction =
  | AnyAction
  | UpdateHiddenMembersAction
  | UpdateHiddenJobtypesAction
  | UpdateHiddenCustomersAction
  | UpdateHiddenStatusesAction
  | UpdateHiddenWorksitesAction
  | UpdateFilteredTaskListAction
  | UpdateSelectedHourTypeAction
  | UpdateJobtypeSearchAction
  | UpdateSelectedCustomerAction
  | UpdateSelectedTaskStatusAction
  | UpdateTableSortOptionsAction
  | UpdateTaskListAction
  | UpdateStartedDateStart
  | UpdateStartedDateEnd
  | UpdateCompletedDateStart
  | UpdateCompletedDateEnd
  | UpdateSelectedMemberAction
  | UpdateIsTableReadyAction
  | UpdateSelectedTask;
