import { Company, CompanyId } from '@shared/schema';
import { ActionCreator, AnyAction } from 'redux';

export const enum CompanyActionType {
  COMPANY_UPDATE = 'COMPANY_UPDATE',
}

export interface UpdateCompanyAction extends AnyAction {
  type: CompanyActionType.COMPANY_UPDATE;
  payload: {
    companyId: CompanyId;
    company: Company;
  };
}

export const updateCompany: ActionCreator<UpdateCompanyAction> = (
  companyId: CompanyId,
  company: Company,
) => {
  return {
    type: CompanyActionType.COMPANY_UPDATE,
    payload: {
      companyId,
      company,
    },
  };
};

export type CompanyAction = AnyAction | UpdateCompanyAction;
