import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  shiftEditorDialog: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 'auto',
    width: 'fit-content',
    maxWidth: '900px',
  },
  paper: {
    width: '900px',
  },
  dialogTitle: {
    paddingLeft: '39px',
  },
  dialogContent: {
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
    padding: '0 24px 12px 24px',
  },
  confirmDialogContainer: {},
  dialogFooter: {
    backgroundColor: theme.colors.grey,
    margin: 0,
    padding: '8px 4px',
  },
  listItem: {
    height: '125px',
  },
  importFileName: {
    fontSize: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '5px',
  },
  listTextPrimary: {
    fontSize: '20px',
    lineHeight: '1.2',
  },
  listText: {
    fontSize: '16px',
    lineHeight: '1.2',
  },
  tasksList: {
    maxHeight: '550px',
    overflow: 'auto',
    marginTop: '5px',
    backgroundColor: '#eee',
  },
  done: {
    color: 'green',
    position: 'absolute',
    marginTop: '-2px',
  },
  close: {
    color: 'red',
    position: 'absolute',
    marginTop: '-2px',
  },
  csvReader: {
    paddingLeft: '16px',
  },
  downloadButton: {
    height: '35px',
    width: '150px',
    margin: 'auto 0px',
    backgroundColor: '#99ca3c',
    minWidth: '120px',
    borderColor: 'rgba(0,0,255,0.25)',
    boxShadow: '2px 2px 4px grey',
    '&:active': { boxShadow: 'none' },
  },
  dialogContainer: {
    display: 'flex',
    paddingLeft: '16px',
  },
  buttonContainer: {
    flexGrow: 3,
    paddingRight: '55px',
  },
  buttonHeader: {
    paddingBottom: '5px',
  },
  instructionsContainer: {
    flexGrow: 9,
  },
  instructionsHeader: {
    marginBlockStart: 0,
    marginBlockEnd: '1em',
  },
  buttonRoot: {
    padding: '0px',
  },
  list: {
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    paddingInlineStart: '30px',
    color: '#000',
    fontSize: '0.875rem',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  uploadContainer: {},
  downloadContainer: {
    paddingTop: '75px',
    paddingBottom: '5px',
  },
  csvReaderInput: { display: 'none' },
  csvReaderLabel: {
    width: '150px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export default styles;
