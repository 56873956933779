import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import tableLayout from '../../../../../../../../../../theme/styles/table';

export const styles = (theme: Theme): StyleRules<string> => ({
  ...{
    ...tableLayout(theme),
    tableCell: {
      width: 'calc(100%/7)',
      border: `0.25px solid ${theme.colors.tableCellBorder}`,
      padding: '4px 12px 4px 24px',
    },
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    width: '300px',
  },

  workingTimeValuesContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '20px',
  },

  previousWorkingTimeContainer: {
    display: 'flex',
  },

  previousWorkingTimeValue: {
    marginLeft: '3px',
  },

  newWorkingTimeContainer: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
  },

  newWorkingTimeValue: {
    marginTop: '3px',
    marginLeft: '3px',
  },
  textField: {
    width: '70px',
    marginTop: '0',
    marginBottom: '0',
  },
});

export default styles;
