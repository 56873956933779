import { WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { i18n } from '@shared/locale';
import { Settings } from '@shared/schema';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { AuthState } from 'reducers/auth/authReducer';
import GeneralSettingsContainer from '../containers/GeneralSettings';
import JobTypesContainer from '../containers/JobTypes';
import QualificationsContainer from '../containers/Qualifications';
import VatsContainer from '../containers/Vat';
import HourTypeContainer from './HourTypes/containers/HourTypeContainer';
import ShiftsContainer from './Shifts/containers/ShiftsContainer';
import styles from './styles';
import UserSettingsContainer from './UserSettings/';

export interface SettingsContainerProps
  extends StyledComponentProps,
    DispatchProp<any>,
    WithTheme {
  companyId: string;
  classes?: any;
  settings: Settings;
  auth: AuthState;
}

export const enum SettingsTabType {
  GENERAL = 'GENERAL',
  JOB_TYPES = 'JOB_TYPES',
  QUALIFICATIONS = 'QUALIFICATIONS',
  VATS = 'VATS',
  HOUR_TYPES = 'HOUR_TYPES',
  ADVANCED = 'ADVANCED',
  USER_SETTINGS = 'USER_SETTINGS',
  SHIFTS = 'SHIFTS',
}

interface State {
  currentTab: SettingsTabType;
}

export class SettingsContainer extends React.Component<
  SettingsContainerProps,
  State
> {
  public state: State = {
    currentTab: SettingsTabType.GENERAL,
  };

  public render() {
    const { classes = {}, settings, auth } = this.props;
    return (
      <div className={classes.container}>
        {/* Tabs are currently hidden cause not all pages implemented. */}
        <div className={classes.tabsBar}>
          <Tabs
            className={classes.button}
            value={this.state.currentTab}
            onChange={this.handleTabChange}
            variant="standard"
          >
            <Tab
              label={i18n().ui.general_settings}
              value={SettingsTabType.GENERAL}
            />
            <Tab label={i18n().ui.user} value={SettingsTabType.USER_SETTINGS} />
            <Tab label={i18n().ui.jobtypes} value={SettingsTabType.JOB_TYPES} />
            <Tab
              label={i18n().ui.qualifications}
              value={SettingsTabType.QUALIFICATIONS}
            />
            {settings.general.enableVat && (
              <Tab label={i18n().ui.VAT} value={SettingsTabType.VATS} />
            )}
            <Tab
              label={i18n().ui.hourtypes}
              value={SettingsTabType.HOUR_TYPES}
            />
            <Tab label={i18n().ui.shifts} value={SettingsTabType.SHIFTS} />
          </Tabs>
        </div>
        {this.state.currentTab === SettingsTabType.GENERAL && (
          <GeneralSettingsContainer companyId={this.props.companyId} />
        )}
        {this.state.currentTab === SettingsTabType.USER_SETTINGS && (
          <UserSettingsContainer userInfo={auth.firebaseUser} />
        )}
        {this.state.currentTab === SettingsTabType.JOB_TYPES && (
          <JobTypesContainer companyId={this.props.companyId} />
        )}
        {this.state.currentTab === SettingsTabType.QUALIFICATIONS && (
          <QualificationsContainer companyId={this.props.companyId} />
        )}
        {this.state.currentTab === SettingsTabType.VATS && (
          <VatsContainer companyId={this.props.companyId} />
        )}
        {this.state.currentTab === SettingsTabType.HOUR_TYPES && (
          <HourTypeContainer companyId={this.props.companyId} />
        )}
        {this.state.currentTab === SettingsTabType.SHIFTS && (
          <ShiftsContainer companyId={this.props.companyId} />
        )}
      </div>
    );
  }

  private handleTabChange = (event: any, newValue: SettingsTabType) => {
    this.setState({
      currentTab: newValue,
    });
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<SettingsContainerProps>,
) => {
  return {
    ...ownProps,
    auth: state.auth,
    company: state.company,
    settings: state.company.activeCompany.settings,
  } as SettingsContainerProps;
};

export default withRouter<any>(
  connect<SettingsContainerProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(SettingsContainer),
  ),
);
