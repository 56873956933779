import { Reducer } from 'redux';

import { CompanyId, Worksite } from '@shared/schema';
import { VenueAction, VenueActionType } from './venuesActions';

export interface VenueState {
  editing: boolean;
  companyId?: CompanyId;

  editedVenue?: Worksite | undefined;
}

const initialState: VenueState = {
  editing: false,
  editedVenue: undefined,
};

export const venuesReducer: Reducer<VenueState> = (
  state = initialState,
  action: VenueAction,
) => {
  switch (action.type) {
    case VenueActionType.VENUES_NEW: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedVenue: undefined,
      };
    }

    case VenueActionType.VENUES_CANCEL: {
      return {
        ...state,
        editing: false,
        editedVenue: undefined,
      };
    }

    // case VenueActionType.VENUES_CREATE: {
    //   return {
    //     ...state,
    //     editing: true,
    //     companyId: action.payload.companyId,
    //     editedVenue: undefined
    //   };
    // }

    case VenueActionType.VENUES_UPDATE: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedVenue: {
          ...action.payload.venue,
        },
      };
    }
    default:
      return state;
  }
};
