import { StyleRules } from '@material-ui/core/styles';

// TODO notify window width with min width

const styles = (): StyleRules<string> => ({
  textField: {
    width: '100%',
  },
  contactList: {
    padding: '0',
  },
  explanatoryLabel: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  grantAccessButton: {
    fontWeight: 'bold',
  },
  contacts: {
    backgroundColor: '#f5f5f5', // TODO Use theme.colors.lightestgrey, currently fails with tests
    borderStyle: 'dashed',
    borderWidth: '0 0 1px 0',
    borderColor: '#929292', // TODO Use theme.colors.grey, currently fails with tests
    padding: 0,
  },
  contact: {
    borderStyle: 'dashed',
    borderWidth: '1px 0 0 0',
    borderColor: '#929292', // TODO Use theme.colors.grey, currently fails with tests
    padding: '0 0 0 24px',
  },
  leftIcon: {
    marginRight: '16px;',
  },
  defaultRadioButton: {
    padding: 0,
  },
  removeButton: {
    marginTop: '4px',
  },
});

export default styles;
