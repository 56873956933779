import { Button, TextField } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import Send from '@material-ui/icons/Send';
import { i18n } from '@shared/locale';
import {
  ChatMessage,
  CompanyId,
  MemberWithKey,
  Schema,
  TaskWithKey,
} from '@shared/schema';
import { validateString } from '@shared/validators';
import moment from 'moment';
import * as React from 'react';
import { getTasksTableRef } from 'utils/tasksUtil';
import ChatMessageBubble from '../../components/ChatMessageBubble';
import styles from '../styles';

export interface ChatContainerProps extends StyledComponentProps {
  companyId: CompanyId;
  task: TaskWithKey;
  member?: MemberWithKey;
  /**
   * Callback when messages have been marked as read in database.
   */
  onReadChat?: (lastMessageId?: string, timestamp?: number) => void;
  /**
   * Callback when new member submitted message has been added to
   * and its last read message ID has been updated to database.
   */
  onSentMessage?: (messageId?: string, timestamp?: number) => void;
}

export interface State {
  messages: ChatMessage[];
  newMessage: string;
  isMessageValid: boolean | undefined;
}

export class ChatContainer extends React.Component<ChatContainerProps, State> {
  private messagesEnd: React.RefObject<any>;
  private unsubscribeChat: () => void;

  constructor(props: ChatContainerProps) {
    super(props);

    this.state = {
      newMessage: '',
      messages: [],
      isMessageValid: undefined,
    };

    this.messagesEnd = React.createRef();
  }

  public componentDidMount() {
    this.subscribeChat();
  }

  // public shouldComponentUpdate(prevProps: ChatContainerProps, state: State) {
  //   return (
  //     this.props.companyId !== prevProps.companyId ||
  //     this.props.task.key !== prevProps.task.key ||
  //     this.hasNewMessages(state.messages) ||
  //     this.state.newMessage !== state.newMessage
  //   );
  // }

  public componentDidUpdate(prevProps: ChatContainerProps) {
    // Re-subscribe chat only when subscription path changes.
    // Also, need to clear messages first before getting new from snapshot.
    if (
      this.props.companyId !== prevProps.companyId ||
      this.props.task.key !== prevProps.task.key
    ) {
      this.setState({ messages: [] }, this.subscribeChat);
    }
  }

  public componentWillUnmount() {
    this.unsubscribeChat && this.unsubscribeChat();
  }

  public render() {
    const { classes = {} } = this.props;
    const { newMessage, isMessageValid, messages } = this.state;

    return (
      <>
        <div className={classes.chatMessages}>
          {messages.map((item, index) => this.renderChatItem(item, index))}
          <div ref={this.messagesEnd} />
        </div>
        <div className={classes.chatButtons}>
          <TextField
            id="newMessage"
            name="newMessage"
            value={newMessage}
            onChange={this.handleMessageChange}
            multiline={true}
            className={classes.chatInput}
            placeholder={i18n().ui.write_message}
            onKeyUp={this.handleEnterKeyPress}
            data-test="chatTextfield"
          />
          <Button
            disabled={!isMessageValid}
            color="primary"
            className={classes.sendButton}
            onClick={this.handleSendButton}
            data-test="chatButton"
          >
            <Send />
          </Button>
        </div>
      </>
    );
  }

  /**
   * Subscribe to chat updates. Will unsubscribe any existing subscription.
   */
  public subscribeChat = () => {
    const { companyId, task } = this.props;

    this.unsubscribeChat && this.unsubscribeChat();

    this.unsubscribeChat = getTasksTableRef(companyId)
      .doc(task.key)
      .collection(Schema.CHAT)
      .onSnapshot(snapshot => {
        const messages: ChatMessage[] = [];

        snapshot.forEach(doc => {
          const message = doc.data() as ChatMessage;
          message.id = doc.id;
          messages.push(message);
        });

        // Sort from oldest to newest
        messages.sort((a, b) => a.date - b.date);

        if (this.hasNewMessages(messages)) {
          this.markAsRead(messages);
          this.setState({ messages }, this.scrollToBottom);
        }
      });
  };

  /**
   * Renders one chatBubble if member from props is same as datas uid
   *
   * @param {ChatMessage} data
   * @param {number} index
   */
  private renderChatItem = (data: ChatMessage, index: number) => {
    const { member } = this.props;

    const itsMe = member ? member.key === data.uid : false;
    const sameUser = itsMe && index > 0;

    return (
      <ChatMessageBubble
        key={index}
        itsMe={itsMe}
        sameUser={sameUser}
        data={data}
        data-test="chatBubble"
      />
    );
  };

  /**
   * Scroll to last message
   */
  private scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  /**
   * Called when chats textfield input changes. Function will also validate the string and
   * will change chatValidity according what validateString returns...
   */
  private handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newMessage = event.target.value;

    this.setState({
      newMessage,
      isMessageValid: validateString(newMessage, 1),
    });
  };

  /**
   * Function for when user writes chat message and presses Enter key to send it.
   */
  private handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.handleSendButton();
    }
  };

  /**
   * Handle sending new chat message
   */
  private handleSendButton = () => {
    const { companyId, task, member, onSentMessage } = this.props;
    const { newMessage } = this.state;

    if (member && validateString(newMessage, 1)) {
      const timestamp = moment().valueOf();

      const chatMessage: ChatMessage = {
        date: timestamp,
        message: newMessage,
        uid: member.key || '',
        userName: member.name || '',
        avatar: member.photoURL || '',
      };

      const taskRef = getTasksTableRef(companyId).doc(task.key);

      taskRef
        .collection(Schema.CHAT)
        .add(chatMessage)
        .then(result => {
          taskRef
            .update({
              ['hasReadChat.' + member.key]: result.id,
              ['hasReadChat.timestamp']: timestamp,
            })
            .then(() => onSentMessage && onSentMessage(result.id, timestamp))
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));

      this.setState(
        {
          messages: [...this.state.messages, chatMessage],
          newMessage: '',
          isMessageValid: false,
        },
        this.scrollToBottom,
      );
    }
  };

  /**
   * Compare current messages to list of new messages.
   *
   * @param newMessages List of new messages to compare with `state.messages`
   * @returns True if two message lists differ
   */
  private hasNewMessages = (newMessages: ChatMessage[]) => {
    const { messages } = this.state;

    if (newMessages.length !== messages.length) {
      return true;
    }

    for (let i = 0; i < messages.length; i++) {
      if (newMessages[i].id !== messages[i].id) {
        return true;
      }
    }

    return false;
  };

  /**
   * Mark messages in given list as read for current user.
   *
   * @param messages
   *        List of messages to be considered as up-to-date.
   *        Must be sorted from oldest to newest!
   */
  private markAsRead = (messages: ChatMessage[]) => {
    const { task, companyId, member, onReadChat } = this.props;

    if (!member || messages.length === 0) {
      return;
    }

    const lastMessage = messages[messages.length - 1];

    // If newest message is owned by member, we do not need to do anything,
    // since read status has already been updated when member submitted the message.
    if (lastMessage.uid === member.key) {
      return;
    }

    const memberLastMessageId =
      task.hasReadChat && task.hasReadChat[member.key!];

    if (memberLastMessageId !== lastMessage.id) {
      const timestamp = moment().valueOf();

      getTasksTableRef(companyId)
        .doc(task.key)
        .update({
          ['hasReadChat.' + member.key]: lastMessage.id,
        })
        .then(() => onReadChat && onReadChat(lastMessage.id!, timestamp))
        .catch(error => console.error(error));
    }
  };
}

export default withStyles(styles, { withTheme: true })(ChatContainer);
