import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  List,
  ListItem,
  Switch,
  Typography,
} from '@material-ui/core';
import applyDefaults from '@shared/defaults/applyDefaults';
import { i18n } from '@shared/locale/index';
import { Company } from '@shared/schema';

import * as React from 'react';
import { CompanyFieldValidity } from '../containers/GeneralSettingsContainer';
import CompanySettingsActiveHours from './CompanySettingsActiveHours';

export interface CompanySettingsFormProps {
  company: Company;
  fieldValidity: CompanyFieldValidity;
  onCompanyChange: (
    company: Company,
    fieldValidity: CompanyFieldValidity,
  ) => void;
  classes?: any;
}

export enum FieldName {
  TIMELINE_STYLE = 'timelineStyle',
  ENABLE_WORKSITE_NICK = 'enableWorksiteNick',
  ENABLE_CUSTOMER = 'enableCustomer',
  ENABLE_WORKSITE = 'enableWorksite',
  ENABLE_JOB_TYPE = 'enableJobType',
  ENABLE_VAT = 'enableVat',
  ENABLE_CUSTOMER_TASK_ID = 'enableCustomerTaskId',
}
class CompanySettingsForm extends React.Component<
  CompanySettingsFormProps,
  any
> {
  public handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { company, fieldValidity } = this.props;
    const { settings } = company;

    const general = { ...settings.general };

    switch (name) {
      case FieldName.TIMELINE_STYLE:
        general[name] = parseInt(event.target.value, 10);
        break;

      case FieldName.ENABLE_CUSTOMER:
      case FieldName.ENABLE_JOB_TYPE:
      case FieldName.ENABLE_WORKSITE:
      case FieldName.ENABLE_WORKSITE_NICK:
      case FieldName.ENABLE_VAT:
      case FieldName.ENABLE_CUSTOMER_TASK_ID:
        general[name] = event.target.checked;
        break;
    }

    this.props.onCompanyChange(
      applyDefaults(
        {
          settings: {
            general,
          },
        },
        company,
      ) as Company,
      {
        ...fieldValidity,
      },
    );
  };

  public render() {
    const { company, classes = {} } = this.props;
    const { settings } = company;

    return (
      <List>
        <ListItem>
          <Typography variant="h5" className={classes.title}>
            {i18n().ui.ui_options}
          </Typography>
        </ListItem>
        {/* <ListItem>
          <FormControl className={classes.formControl}>
            <FormLabel>Aikajanan tyyli</FormLabel>
            <RadioGroup
              aria-label="Aikajanan tyyli"
              name="timelineStyle"
              value={`${settings.general.timelineStyle}`}
              onChange={this.handleChange(FieldName.TIMELINE_STYLE)}
              row
            >
              <FormControlLabel value="8" label="8h" control={<Radio />} />
              <FormControlLabel value="12" label="12h" control={<Radio />} />
              <FormControlLabel value="24" label="24h" control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </ListItem> */}
        <ListItem>
          <FormControl className={classes.formControl}>
            <FormLabel> {i18n().ui.additional_field_visibility}</FormLabel>
            <FormGroup>
              <FormControlLabel
                label={i18n().ui.customer}
                control={<Switch checked={settings.general.enableCustomer} />}
                onChange={this.handleChange(FieldName.ENABLE_CUSTOMER)}
              />
              {/* <FormControlLabel
                label="Asiakas"
                id="customer_formcontollabel"
                control={
                  <Switch
                    id="customer_switch"
                    checked={settings.general.enableCustomer}
                  />
                }
                onChange={this.handleChange(FieldName.ENABLE_CUSTOMER)}
              /> */}
              <FormControlLabel
                label={i18n().ui.worksite}
                control={<Switch checked={settings.general.enableWorksite} />}
                onChange={this.handleChange(FieldName.ENABLE_WORKSITE)}
              />
              <FormControlLabel
                label={i18n().ui.worksite_nickname}
                control={
                  <Switch checked={settings.general.enableWorksiteNick} />
                }
                onChange={this.handleChange(FieldName.ENABLE_WORKSITE_NICK)}
              />
              {/*  <FormControlLabel
                label="Työlaji"
                control={<Switch checked={settings.general.enableJobType} />}
                onChange={this.handleChange(FieldName.ENABLE_JOB_TYPE)}
              /> */}
              <FormControlLabel
                label={i18n().ui.VAT}
                control={<Switch checked={settings.general.enableVat} />}
                onChange={this.handleChange(FieldName.ENABLE_VAT)}
              />
              <FormControlLabel
                label={i18n().ui.task_id}
                control={
                  <Switch checked={settings.general.enableCustomerTaskId} />
                }
                onChange={this.handleChange(FieldName.ENABLE_CUSTOMER_TASK_ID)}
              />
            </FormGroup>
          </FormControl>
        </ListItem>
        <CompanySettingsActiveHours
          company={this.props.company}
          fieldValidity={this.props.fieldValidity}
          onCompanyChange={this.props.onCompanyChange}
        />
      </List>
    );
  }
}

export default CompanySettingsForm;
