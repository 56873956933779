import { Schema } from '@shared/schema';
import firebaseApp from 'firebaseApp';

/**
 * Returns reference to shifts table from firebase
 *
 * @param {string | undefined} companyId
 */
export function getShiftsTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.SHIFTS);
}

/**
 * Returns reference to shift instance with shift id or returns empty instance reference
 *
 * @param {string | undefined} companyId
 * @param {string | undefined} id?
 */
export function getShiftDocRef(
  companyId: string | undefined,
  id?: string | undefined,
) {
  return id
    ? getShiftsTableRef(companyId).doc(id)
    : getShiftsTableRef(companyId).doc();
}

/**
 * Returns reference to shift templates table from firebase
 *
 * @param {string | undefined} companyId
 */
export function getShiftTemplatesTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.SHIFTTEMPLATES);
}
