import { Button, Grid } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import { applyCompanyDefaults, DEFAULT_COMPANY } from '@shared/defaults';
import { i18n } from '@shared/locale/index';
import { Company, Schema } from '@shared/schema';
import classNames from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import Geocode from 'react-geocode';
import { Prompt } from 'react-router-dom';
import muiTheme from 'theme';
import CompanyDetailsForm from '../components/CompanyDetailsForm';
import CompanySettingsForm from '../components/CompanySettingsForm';
import styles from '../styles';

export interface GeneralSettingsContainerProps extends StyledComponentProps {
  companyId: string;
  classes?: any;
}

interface State {
  isLoading: boolean;
  hasChanged: boolean;
  originalCompany: Company;
  modifiedCompany: Company;
  fieldValidity: CompanyFieldValidity;
}

export interface CompanyFieldValidity {
  name?: boolean;
  contactPerson?: boolean;
  email?: boolean;
  address?: boolean;
  postalCode?: boolean;
  city?: boolean;
  phone?: boolean;
  logoURL?: boolean;
}

class GeneralSettingsContainer extends React.Component<
  GeneralSettingsContainerProps,
  State
> {
  public state: State = {
    isLoading: true,
    hasChanged: false,
    originalCompany: DEFAULT_COMPANY,
    modifiedCompany: DEFAULT_COMPANY,
    fieldValidity: {},
  };

  private unsubscribeCompany: () => void;

  public componentDidMount() {
    this.unsubscribeCompany = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
        const originalCompany = applyCompanyDefaults(snapshot.data());

        this.setState({
          isLoading: false,
          originalCompany,
          modifiedCompany: this.state.hasChanged
            ? this.state.modifiedCompany
            : applyCompanyDefaults(originalCompany),
        });
      });
  }

  public componentWillUnmount() {
    this.unsubscribeCompany && this.unsubscribeCompany();

    this.state.hasChanged &&
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    const { hasChanged, modifiedCompany, fieldValidity } = this.state;
    const { classes = {} } = this.props;

    return (
      <div
        className={classes.container}
        style={{ color: muiTheme.palette.primary.main }}
      >
        <Prompt
          when={hasChanged}
          message={location => i18n().ui.unsaved_changes}
        />
        <Grid container className={classes.bar}>
          <Grid item>
            <Button
              size="small"
              color="primary"
              className={classes.button}
              disabled={!hasChanged}
              onClick={this.save}
            >
              <SaveIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              {i18n().ui.save_changes}
            </Button>
            <Button
              size="small"
              className={classes.button}
              disabled={!hasChanged}
              onClick={this.reset}
            >
              <UndoIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              {i18n().ui.cancel_changes}
            </Button>
            <div className={classes.privacyLinkContainer}>
              <a href={i18n().links.privacy_policy} target="_blank">
                {i18n().ui.privacy_policy}
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="stretch">
          <Grid item xs>
            <CompanyDetailsForm
              company={modifiedCompany}
              fieldValidity={fieldValidity}
              onCompanyChange={this.handleCompanyChange}
            />
          </Grid>
          {/* <Grid item xs>
            <CompanyLogoForm
              company={modifiedCompany}
              fieldValidity={fieldValidity}
              onCompanyChange={this.handleCompanyChange}
            />
          </Grid> */}
          <Grid item xs>
            <CompanySettingsForm
              company={modifiedCompany}
              fieldValidity={fieldValidity}
              onCompanyChange={this.handleCompanyChange}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  private handleCompanyChange = (
    modifiedCompany: Company,
    fieldValidity: CompanyFieldValidity,
  ) => {
    !this.state.hasChanged &&
      window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.setState({
      hasChanged: true,
      modifiedCompany,
      fieldValidity,
    });
  };

  private handleBeforeUnload = (event: any) => {
    event.preventDefault();

    // This is for older browsers only. Newer browsers use genric message.
    event.returnValue = i18n().ui.unsaved_changes;
  };

  private isValid(): boolean {
    const { fieldValidity } = this.state;

    for (const k in fieldValidity) {
      if (!fieldValidity[k]) {
        return false;
      }
    }

    return true;
  }

  private save = async () => {
    const { modifiedCompany, originalCompany } = this.state;
    const { companyId } = this.props;
    if (!this.state.hasChanged || !this.isValid()) {
      return;
    }

    let companyToBeSaved: Company = modifiedCompany;

    // If address, postalcode or city has changed, try to find new coords for company
    if (
      originalCompany.address !== modifiedCompany.address ||
      originalCompany.postalCode !== modifiedCompany.postalCode ||
      originalCompany.city !== modifiedCompany.city
    ) {
      Geocode.setApiKey(CONFIG.apiKeys.googleMapKey);
      await Geocode.fromAddress(
        `${modifiedCompany.address} ${modifiedCompany.postalCode} ${modifiedCompany.city}`,
      ).then(
        (response: any) => {
          if (
            response &&
            response.results[0] &&
            response.results[0].geometry &&
            response.results[0].geometry.location
          ) {
            const { lat, lng } = response.results[0].geometry.location;
            companyToBeSaved = {
              ...modifiedCompany,
              geoLocation: {
                la: lat,
                lo: lng,
                t: new Date().getTime(),
              },
            };
          }
        },
        (error: any) => {
          console.error(error);
        },
      );
    }

    firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .update(companyToBeSaved);

    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    this.setState({
      hasChanged: false,
      originalCompany: applyCompanyDefaults(this.state.modifiedCompany),
    });
  };

  private reset = () => {
    if (!this.state.hasChanged) {
      return;
    }

    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    this.setState({
      hasChanged: false,
      modifiedCompany: applyCompanyDefaults(this.state.originalCompany),
      fieldValidity: {},
    });
  };
}

export default withStyles(styles, { withTheme: true })(
  GeneralSettingsContainer,
);
