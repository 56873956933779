import { Button, TextField } from '@material-ui/core';
import { i18n } from '@shared/locale/index';
import { User } from '@shared/schema';
import * as React from 'react';
//  import ProviderDataForm from '../ProviderDataForm';

export interface AccountProps {
  initialValues: User;
  onSubmit: () => void;
  onUpdateAccount: any;
}

class AccountForm extends React.Component<AccountProps> {
  constructor(props: AccountProps) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }
  public handleChange(event: any) {
    const { initialValues, onUpdateAccount } = this.props;

    const editedAccount = {
      ...initialValues,
      displayName: event.target.value,
    };
    onUpdateAccount && onUpdateAccount(editedAccount);
  }

  public render() {
    const {
      initialValues: { displayName = '', email = '', home = '' },
      onSubmit,
    } = this.props;

    return (
      <div style={styles.container}>
        <h4>{i18n().ui.account}</h4>
        <TextField
          name="displayName"
          placeholder={i18n().ui.name}
          onChange={this.handleChange}
          value={displayName}
          data-test="displayName"
        />
        <TextField
          disabled={true}
          name="email"
          placeholder={i18n().ui.email}
          value={email}
          data-test="email"
        />
        <TextField
          disabled={true}
          name="home"
          placeholder={i18n().ui.company}
          value={home}
          data-test="home"
        />
        <Button
          type="submit"
          onClick={onSubmit}
          style={styles.submit}
          data-test="submitButton"
        >
          {i18n().ui.save}
        </Button>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    margin: '.2rem',
    alignItems: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
  submit: {
    width: '30%',
    minWidth: '192px',
    marginTop: '1.5rem',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
  },
};

export default AccountForm;
