import {
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import MailIcon from '@material-ui/icons/Mail';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import applyDefaults from '@shared/defaults/applyDefaults';
import { i18n } from '@shared/locale/index';
import { Company } from '@shared/schema';
import {
  isEmail,
  isPhoneNumber,
  isPostalCode,
  validateString,
} from '@shared/validators';
import classNames from 'classnames';
import * as React from 'react';

export interface CompanyDetailsFormProps {
  company: Company;
  fieldValidity?: CompanyDetailsFieldValidity;
  onCompanyChange: (
    company: Company,
    fieldValidity: CompanyDetailsFieldValidity,
  ) => void;
  classes?: any;
}

enum FieldName {
  NAME = 'name',
  CONTACT_PERSON = 'contactPerson',
  EMAIL = 'email',
  ADDRESS = 'address',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  PHONE = 'phone',
}

export interface CompanyDetailsFieldValidity {
  name?: boolean;
  contactPerson?: boolean;
  email?: boolean;
  address?: boolean;
  postalCode?: boolean;
  city?: boolean;
  phone?: boolean;
}

/**
 * Sub-form for GeneralSettingsContainer
 */
const CompanyDetailsForm = (props: CompanyDetailsFormProps) => {
  const { company, classes = {}, fieldValidity = {} } = props;
  const textFieldClassName = classNames(classes.field, classes.textField);

  const handleChange = (name: string) => (event: any) => {
    const newFieldValidity = { ...fieldValidity };
    const fieldValue = event.target.value;
    if (fieldValue === props.company[name]) {
      return;
    }

    switch (name) {
      case FieldName.NAME:
        newFieldValidity.name = validateString(fieldValue, 1);
        break;

      case FieldName.EMAIL:
        newFieldValidity.email = fieldValue ? isEmail(fieldValue) : true;
        break;

      case FieldName.POSTAL_CODE:
        newFieldValidity.postalCode = fieldValue
          ? isPostalCode(fieldValue)
          : true;
        break;

      case FieldName.PHONE:
        newFieldValidity.phone = fieldValue ? isPhoneNumber(fieldValue) : true;
        break;
    }

    props.onCompanyChange(
      applyDefaults(
        {
          [name]: fieldValue,
        },
        props.company,
      ) as Company,
      newFieldValidity,
    );
  };

  return (
    <List>
      <ListItem>
        <Typography variant="h5">{i18n().ui.basic_information}</Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <TextField
          key={FieldName.NAME}
          label={i18n().ui.company_name}
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.NAME)}
          value={company.name}
          error={fieldValidity.name !== undefined && !fieldValidity.name}
          helperText={
            fieldValidity.name !== undefined &&
            !fieldValidity.name &&
            i18n().ui.type_company_name
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <TextField
          key={FieldName.CONTACT_PERSON}
          label={i18n().ui.contact_person}
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.CONTACT_PERSON)}
          value={company.contactPerson}
          error={
            fieldValidity.contactPerson !== undefined &&
            !fieldValidity.contactPerson
          }
          helperText={
            fieldValidity.contactPerson !== undefined &&
            !fieldValidity.contactPerson &&
            i18n().ui.type_contactperson_name
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <TextField
          key={FieldName.EMAIL}
          label={i18n().ui.email}
          type="email"
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.EMAIL)}
          value={company.email}
          error={fieldValidity.email !== undefined && !fieldValidity.email}
          helperText={
            fieldValidity.email !== undefined &&
            !fieldValidity.email &&
            i18n().ui.type_correct_email
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <TextField
          key={FieldName.ADDRESS}
          label={i18n().ui.address}
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.ADDRESS)}
          value={company.address}
          error={fieldValidity.address !== undefined && !fieldValidity.address}
          helperText={
            fieldValidity.address !== undefined &&
            !fieldValidity.address &&
            i18n().ui.type_address
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MapIcon style={{ visibility: 'hidden' }} />
        </ListItemIcon>
        <TextField
          key={FieldName.POSTAL_CODE}
          label={i18n().ui.postalcode}
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.POSTAL_CODE)}
          value={company.postalCode}
          error={
            fieldValidity.postalCode !== undefined && !fieldValidity.postalCode
          }
          helperText={
            fieldValidity.postalCode !== undefined &&
            !fieldValidity.postalCode &&
            i18n().ui.type_postalcode
          }
        />
        <TextField
          key={FieldName.CITY}
          label={i18n().ui.city}
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.CITY)}
          value={company.city}
          error={fieldValidity.city !== undefined && !fieldValidity.city}
          helperText={
            fieldValidity.city !== undefined &&
            !fieldValidity.city &&
            i18n().ui.type_city
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LocalPhoneIcon />
        </ListItemIcon>
        <TextField
          key={FieldName.PHONE}
          label={i18n().ui.phone}
          type="tel"
          className={textFieldClassName}
          margin="normal"
          onChange={handleChange(FieldName.PHONE)}
          value={company.phone}
          error={fieldValidity.phone !== undefined && !fieldValidity.phone}
          helperText={
            fieldValidity.phone !== undefined &&
            !fieldValidity.phone &&
            i18n().ui.type_phonenumber
          }
        />
      </ListItem>
    </List>
  );
};

export default CompanyDetailsForm;
