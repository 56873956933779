import * as React from 'react';
import { Helmet } from 'react-helmet';

import { i18n } from '@shared/locale';

/**
 * Header content for the html page
 *
 * @author Ville Venäläinen
 */
export default class Header extends React.PureComponent {
  public render() {
    return (
      <Helmet>
        <title>
          {`${i18n().branding.app_name} - ${
            i18n().branding.app_description
          } - ${i18n().ui.dashboard} - ${i18n().branding.copyright}`}
        </title>
        <meta name="description" content={i18n().branding.app_description} />
        <meta name="theme-color" content="#008f68" />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.4.2/css/all.css"
          integrity="sha384-/rXc/GQVaYpyDdyxK+ecHPVYJSN9bmVFBvjA/9eOB+pb3F2w2N6fc5qB9Ew5yIns"
          crossOrigin="anonymous"
        />
        {/*
        <script
          crossOrigin="anonymous"
          src="https://unpkg.com/react@16/umd/react.production.min.js"
        />
        <script
          crossOrigin="anonymous"
          src="https://unpkg.com/react-dom@16/umd/react-dom.production.min.js"
        />
        */}
        <script src="/__/firebase/5.8.2/firebase-app.js" />
        <script src="/__/firebase/5.8.2/firebase-firestore.js" />
        <script src="/__/firebase/5.8.2/firebase-storage.js" />
        <script src="/__/firebase/5.8.2/firebase-auth.js" />

        <meta
          name="version"
          content={`${CONFIG.build.version} build: ${CONFIG.build.build}`}
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#fffbuild" />
        <meta name="application-name" content="MOWO" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-icon-180x180.png"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          name="apple-mobile-web-app-title"
          content="MOWO - mobile workflow"
        />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta name="msapplication-TileImage" content="icons-3c33626a874656062074e1df8aeafa6c/mstile-144x144.png" />
        <meta name="msapplication-config" content="icons-3c33626a874656062074e1df8aeafa6c/browserconfig.xml" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="icons-3c33626a874656062074e1df8aeafa6c/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="icons-3c33626a874656062074e1df8aeafa6c/favicon-16x16.png"
        />
        <link
          rel="shortcut icon"
          href="icons-3c33626a874656062074e1df8aeafa6c/favicon.ico"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-320x460.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-640x920.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-640x1096.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-750x1294.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-1182x2208.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-1242x2148.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-748x1024.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-768x1004.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-1496x2048.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)"
          href="icons-3c33626a874656062074e1df8aeafa6c/apple-touch-startup-image-1536x2008.png"
        />
      </Helmet>
    );
  }
}
