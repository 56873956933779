import {
  EditorState,
  RepeatingTasks,
  Repetition,
  Schema,
  TaskStatus,
  TaskWrite,
} from '@shared/schema';
import firebase from 'firebase';
import moment from 'moment';
import { createResourceEvent } from 'reducers/events/eventActions';

export function formatDate(dateTime?: moment.Moment): string {
  let m = dateTime;
  if (!m) {
    m = moment(new Date());
  }
  return m.format('YYYY-MM-DD');
}

export function formatTime(dateTime: moment.Moment): string {
  const m = dateTime || moment(new Date());
  return m.format('HH:mm');
}

export function durationToStr(milliseconds: number): string {
  // TODO use moment
  let minutes = Math.ceil(milliseconds / 60000);
  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let s = '' + hours;
  if (s.length < 2) {
    s = '0' + s;
  }
  let m = '' + minutes;
  if (m.length < 2) {
    m = '0' + m;
  }
  return s + ':' + m;
}

export function isUnidentifiedState(status: TaskStatus): boolean {
  return !(
    status === TaskStatus.DONE ||
    status === TaskStatus.UNDONE ||
    status === TaskStatus.ACTIVE ||
    status === TaskStatus.PAUSED
  );
}

export function nextWorkDay(date: moment.Moment) {
  const day: number = date.day();
  let add: number = 1;

  if (day >= 5) {
    add = 8 - day;
  }
  date.add(add, 'days');
  return date;
}

function createCopyFromDate(
  start: number,
  duration: number,
  source: TaskWrite,
  repeatingTaskArrayId?: string,
): TaskWrite {
  const result = { ...source };
  const end = start + duration;
  if (result && result.worksite && !result.worksite.id) {
    delete result.worksite;
    delete result.location;
  }

  if (result && result.vat && !result.vat.name) {
    delete result.vat;
  }

  result.start = start;
  result.end = end.valueOf();
  if (repeatingTaskArrayId) {
    result.repeatingTaskId = repeatingTaskArrayId;
  }

  return result;
}

async function generateDocumentKey(companyId: string | undefined) {
  if (companyId) {
    try {
      const document = firebase
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.TASKS)
        .doc();

      if (document && document.id) {
        return document.id;
      }
    } catch (error) {
      console.error('Error getting document key ', error);
    }
  } else {
    console.error('Things failed with generateDocumentKey');
  }
  return undefined;
}

export async function createRepeatingEvents(
  dispatch: any,
  companyId: string | undefined,
  duration: number,
  repetition: Repetition,
  resourceEvent: TaskWrite,
  editorState?: EditorState,
  repeatingTaskArrayId?: string,
) {
  const count = repetition.count;
  const step = repetition.step;
  let date = moment(resourceEvent.start);
  const idArray: string[] = [];

  switch (repetition.type) {
    case 'd':
      for (let i = 0; i < count; i++) {
        if (
          (editorState === EditorState.EDIT && i !== 0) ||
          editorState === EditorState.NEW
        ) {
          const event = createCopyFromDate(
            date.valueOf(),
            duration,
            resourceEvent,
            repeatingTaskArrayId,
          );
          const key = await generateDocumentKey(companyId);
          const response = dispatch(createResourceEvent(companyId, event, key));
          date.add(step, 'days');
          if (response && response.key) {
            idArray.push(response.key);
          }
        } else {
          date.add(step, 'days');
        }
      }
      break;

    case 'w':
      for (let i = 0; i < count; i++) {
        if (
          (editorState === EditorState.EDIT && i !== 0) ||
          editorState === EditorState.NEW
        ) {
          const event = createCopyFromDate(
            date.valueOf(),
            duration,
            resourceEvent,
            repeatingTaskArrayId,
          );

          const key = await generateDocumentKey(companyId);
          const response = dispatch(createResourceEvent(companyId, event, key));
          date.add(step, 'weeks');
          if (response && response.key) {
            idArray.push(response.key);
          }
        } else {
          date.add(step, 'weeks');
        }
      }
      break;

    case 'wd':
      for (let i = 0; i < count; i++) {
        if (
          (editorState === EditorState.EDIT && i !== 0) ||
          editorState === EditorState.NEW
        ) {
          const event = createCopyFromDate(
            date.valueOf(),
            duration,
            resourceEvent,
            repeatingTaskArrayId,
          );

          const key = await generateDocumentKey(companyId);
          const response = dispatch(createResourceEvent(companyId, event, key));
          date = nextWorkDay(date);
          if (response && response.key) {
            idArray.push(response.key);
          }
        } else {
          date = nextWorkDay(date);
        }
      }
      break;

    case 'm':
      for (let i = 0; i < count; i++) {
        if (
          (editorState === EditorState.EDIT && i !== 0) ||
          editorState === EditorState.NEW
        ) {
          const event = createCopyFromDate(
            date.valueOf(),
            duration,
            resourceEvent,
            repeatingTaskArrayId,
          );

          const key = await generateDocumentKey(companyId);
          const response = dispatch(createResourceEvent(companyId, event, key));
          date.add(step, 'months');
          if (response && response.key) {
            idArray.push(response.key);
          }
        } else {
          date.add(step, 'months');
        }
      }
      break;
  }

  /**
   * Creates repeatingTasks table if IdArray exists
   */
  if (idArray !== [] && repeatingTaskArrayId) {
    const dataToBeSaved: RepeatingTasks = { tasks: idArray };
    try {
      firebase
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.REPEATINGTASKS)
        .doc(repeatingTaskArrayId)
        .set(dataToBeSaved);
    } catch (error) {
      console.error('Error creating repeating task table ', error);
    }
  }
}
