import { i18n } from '@shared/locale';
import { User, UserRole } from '@shared/schema';
import AuthMessage from 'components/AuthMessage';
import Login from 'containers/public/LoginPage';
import * as firebase from 'firebase';
import { extractPath, NAVIGATION_PATH } from 'paths';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { ApplicationState } from 'reducers/index';
import Private from './Private';

export interface DashboardProps extends RouteComponentProps<any> {
  firebaseUser: firebase.User | undefined;
  activeRole: UserRole;
  appUser: User;
}

class Containers extends React.PureComponent<DashboardProps> {
  public handleLogOut = () => {
    localStorage.removeItem('isLogging');
    localStorage.removeItem('signInMethodGoogle');
    firebase.auth().signOut();
  };
  public render() {
    const { firebaseUser, activeRole, match, appUser } = this.props;
    // TODO move public and private routers to their folder -containers
    switch (activeRole) {
      case UserRole.SYSTEM_ADMIN:
      case UserRole.ADMIN:
      case UserRole.END_CUSTOMER:
        // Individual elements will decide whether they are allowed
        // for non-admins hence we let normal user pass through.
        return (
          <Switch>
            <Route
              path={extractPath(match, NAVIGATION_PATH.LOGIN)}
              component={Login}
            />
            <Route component={Private} />
          </Switch>
        );

      case UserRole.PENDING:
        return (
          <Switch>
            <Route path={extractPath(match, NAVIGATION_PATH.AWAIT_AUTH)} />
            <AuthMessage
              title={i18n().auth.your_account_is_waiting_approval}
              message={i18n().auth.please_wait_for_admin_to_verify_your_login}
              buttonLabel={i18n().ui.log_out}
              onButtonClick={this.handleLogOut}
            />
          </Switch>
        );

      case UserRole.USER:
        return (
          <Switch>
            <Route path={extractPath(match, NAVIGATION_PATH.AWAIT_AUTH)} />
            <AuthMessage
              title={
                i18n().auth.you_dont_have_permission_to_use_admin_dashboard
              }
              message={i18n().auth.please_contact_your_company_admin}
              buttonLabel={i18n().ui.log_out}
              onButtonClick={this.handleLogOut}
            />
          </Switch>
        );

      case UserRole.CLOSED: {
        if (firebaseUser) {
          return (
            <Switch>
              <Route path={extractPath(match, NAVIGATION_PATH.AWAIT_AUTH)} />
              <AuthMessage
                title={i18n().auth.your_account_has_been_closed}
                message={i18n().auth.please_contact_your_company_admin}
                buttonLabel={i18n().ui.log_out}
                onButtonClick={this.handleLogOut}
              />
            </Switch>
          );
        } else {
          return (
            <Switch>
              <Route
                path={extractPath(match, NAVIGATION_PATH.LOGIN)}
                component={Login}
              />
              <Redirect to={NAVIGATION_PATH.LOGIN} />
            </Switch>
          );
        }
      }

      // case UserRole.END_CUSTOMER: {
      //   console.log('End customer');
      //   if (firebaseUser) {
      //     <Switch>
      //       <Route path={extractPath(match, NAVIGATION_PATH.REPORTS)} />
      //       {/* <AuthMessage
      //       title={i18n().auth.your_account_has_been_closed}
      //       message={i18n().auth.please_contact_your_company_admin}
      //       buttonLabel={i18n().ui.log_out}
      //       onButtonClick={this.handleLogOut}
      //     /> */}
      //     </Switch>;
      //   }
      // }

      default: {
        if (firebaseUser && appUser && !appUser.home) {
          return (
            <AuthMessage
              title={i18n().auth.unauthorized}
              message={i18n().auth.you_dont_have_permission_to_use_app}
              buttonLabel={i18n().ui.log_out}
              onButtonClick={this.handleLogOut}
            />
          );
        } else {
          /*
           * List here pages that are available without login
           */
          return (
            <Switch>
              <Route
                path={extractPath(match, NAVIGATION_PATH.LOGIN)}
                component={Login}
              />
              <Redirect to={NAVIGATION_PATH.LOGIN} />
            </Switch>
          );
        }
      }
    }
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    firebaseUser: state.auth.firebaseUser,
    activeRole: state.auth.activeRole,
    appUser: state.auth.appUser,
  };
};

export default withRouter(connect(mapStateToProps)(Containers));
