import { Settings, Task } from '@shared/schema';
import moment from 'moment';
import * as React from 'react';
import { TimelineItem } from 'vis';

/**
 * Unclutters timelineOptions.tsx code taking some of its reusable code to
 * separate function that is also needed in TimelineContainer component.
 * Takes params which contains all needed information to build task content to both
 * timeline and tooltip with restriction if some specific information is
 * restricted to show from CompanySettingsForm.tsx
 *
 * @param task {Task} all task related information
 * @param settings {Settings} all settings information
 */
export const renderTaskContent = (task: Task, settings: Settings) => {
  const address =
    settings.general.enableWorksite && task.location
      ? task.location.address
      : '';

  const city =
    settings.general.enableWorksite && task.location ? task.location.city : '';
  let location = address;

  if (city) {
    location += ', ' + city;
  }

  const worksiteName =
    settings.general.enableWorksite &&
    task.worksite &&
    ((settings.general.enableWorksiteNick && task.worksite.nick) ||
      task.worksite.name);

  const jobtypeName =
    (task.title && task.title.userModified && task.title.value) ||
    task.jobtypeName;

  const start = moment(task.start).format('HH:mm');
  const end = moment(task.end).format('HH:mm');
  return (
    <div className="content">
      <div className="event-title">
        <span className="event-task">
          {worksiteName && <>{worksiteName}&nbsp;&bull; </>}
          {jobtypeName}
        </span>
      </div>
      <div>{location}</div>
      <div>
        {start}-{end}
      </div>
    </div>
  );
};

export const renderShiftContent = (shift: TimelineItem) => {
  const start = moment(shift.start).format('HH:mm');
  const end = moment(shift.end).format('HH:mm');
  return (
    <div className="timeline-shift-item">
      <p>
        {shift.content} {start}-{end}
      </p>
    </div>
  );
};
