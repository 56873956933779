import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Theme,
} from '@material-ui/core';
import {
  StyledComponentProps,
  StyleRules,
  withStyles,
} from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import { dequeueWarning } from 'reducers/timeline/timelineActions';

interface SnackbarWarningProps extends StyledComponentProps, DispatchProp {
  warningMessages: string[];
}

interface State {
  open: boolean;
  message: string;
}

class SnackbarWarning extends React.Component<SnackbarWarningProps, State> {
  constructor(props: SnackbarWarningProps) {
    super(props);

    this.state = {
      open: false,
      message: '',
    };
  }

  public componentDidUpdate(prevProps: SnackbarWarningProps) {
    if (this.props.warningMessages.length > prevProps.warningMessages.length) {
      if (this.state.open) {
        this.setState({ open: false });
      } else {
        this.processQueue();
      }
    }
  }

  /**
   * Checks the messages in queue from props. If there is a message, setState with first message and dispatch to the next message in the queue
   */
  public processQueue = () => {
    const { warningMessages } = this.props;
    if (warningMessages.length > 0) {
      this.setState({
        message: warningMessages[0],
        open: true,
      });
      this.props.dispatch(dequeueWarning());
    }
  };

  public render() {
    const { classes = {} } = this.props;
    const { open, message } = this.state;
    const showDuration = 4 * 1000;
    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className={classes.snackbar}
        autoHideDuration={showDuration}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <div className={classes.messageContainer}>
              <div className={classes.message}>{message}</div>
            </div>
          }
          action={
            <IconButton key="close" onClick={this.handleClose}>
              <Clear className={classes.snackbarCloseIcon} />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }

  /**
   * Triggered on snackbar hide
   */
  private handleExited = () => {
    this.processQueue();
  };

  /**
   * Trigered on snackbar cross button press to hide a snackbar.
   */
  private handleClose = () => {
    this.setState({
      open: false,
    });
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<SnackbarWarningProps>,
) => {
  return {
    ...ownProps,
    warningMessages: state.timeline.messagesForWarningSnackbar,
  };
};

const styles = (theme: Theme): StyleRules<string> => ({
  snackbar: {
    marginBottom: '2%',
  },

  snackbarContent: {
    background: theme.mapMarkerThemes.paused.color,
  },

  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  infoIcon: {
    marginRight: '12px',
  },
  snackbarCloseIcon: {
    color: theme.colors.white,
  },
  message: {
    fontSize: '16px',
  },
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(SnackbarWarning),
);
