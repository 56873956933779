import {
  InputAdornment,
  StyledComponentProps,
  withStyles,
  WithTheme,
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { DateTimePicker } from 'material-ui-pickers';
import * as React from 'react';
import { styles } from './styles';

interface TaskReportDateTimePickerProps
  extends StyledComponentProps,
    WithTheme {
  maxDate?: Date | null;
  minDate?: Date | null;
  clearable: Date | null;
  value: Date | null;
  onChange: (date: Date) => void;
}

const TaskReportDateTimePicker = (props: TaskReportDateTimePickerProps) => {
  const { classes = {} } = props;

  return (
    <DateTimePicker
      data-test="datetimepicker"
      className={classes.dateTimePicker}
      maxDate={props.maxDate || undefined}
      minDate={props.minDate || undefined}
      clearable={true}
      variant="outlined"
      value={props.value}
      onChange={props.onChange}
      ampm={false}
      format="D.M.YYYY HH:mm"
      InputProps={{
        classes: {
          root: classes.calendar,
        },
        endAdornment: (
          <InputAdornment position="end">
            <DateRange />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default withStyles(styles, { withTheme: true })(
  TaskReportDateTimePicker,
);
