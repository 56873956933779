import { AnyAction, combineReducers } from 'redux';
import { AuthAction } from './auth/authActions';
import { authReducer, AuthState } from './auth/authReducer';
import { CompanyAction } from './company/companyActions';
import { companyReducer, CompanyState } from './company/companyReducer';
import { CustomerAction } from './customer/customerActions';
import { customerReducer, CustomerState } from './customer/customerReducer';
import { EventAction } from './events/eventActions';
import { eventReducer, EventState } from './events/eventReducer';
import { ImportTasksAction } from './importTasks/importTasksAction';
import {
  importTasksReducer,
  ImportTasksState,
} from './importTasks/importTasksReducer';
import { NaviAction } from './navi/naviActions';
import { naviReducer, NaviState } from './navi/naviReducer';
import { reportsReducer, ReportsState } from './reports/reportsReducer';
import { TaskReportAction } from './reports/taskReport/reportActions';
import {
  taskReportReducer,
  TaskReportState,
} from './reports/taskReport/reportReducer';
import { ResourcesAction } from './resources/resourcesActions';
import { resourcesReducer, ResourcesState } from './resources/resourcesReducer';
import { ShiftAction } from './shifts/shiftAction';
import { shiftReducer, ShiftState } from './shifts/shiftReducer';
import { TimelineAction } from './timeline/timelineActions';
import { timelineReducer, TimelineState } from './timeline/timelineReducer';
import { VenueAction } from './venues/venuesActions';
import { venuesReducer, VenueState } from './venues/venuesReducer';

export type AppAction =
  | AnyAction
  | AuthAction
  | NaviAction
  | CompanyAction
  | ResourcesAction
  | TimelineAction
  | VenueAction
  | EventAction
  | CustomerAction
  | TaskReportAction
  | ImportTasksAction
  | ShiftAction;

export interface ApplicationState {
  auth: AuthState;
  navi: NaviState;
  company: CompanyState;
  resources: ResourcesState;
  /**
   * TODO Rename to `taskEditor`
   */
  eventEditor: EventState;
  timeline: TimelineState;
  venue: VenueState;
  customer: CustomerState;
  reports: ReportsState;
  taskReport: TaskReportState;
  importTasks: ImportTasksState;
  shiftEditor: ShiftState;
}

export const appReducer = combineReducers<ApplicationState | undefined>({
  auth: authReducer,
  navi: naviReducer,
  company: companyReducer,
  resources: resourcesReducer,
  eventEditor: eventReducer,
  timeline: timelineReducer,
  venue: venuesReducer,
  customer: customerReducer,
  reports: reportsReducer,
  taskReport: taskReportReducer,
  importTasks: importTasksReducer,
  shiftEditor: shiftReducer,
});

export const rootReducer = (
  state: ApplicationState | undefined,
  action: AppAction,
) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
