import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core/';
import { StyledComponentProps } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/EmailRounded';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PersonIcon from '@material-ui/icons/Person';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import classNames from 'classnames';
import * as React from 'react';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
  validateForm,
} from 'ts-form-validation';

import { i18n } from '@shared/locale';
import { Contact, MemberWithKey, Schema, UserRole } from '@shared/schema';
import { isEmail, isPhoneNumber, validateString } from '@shared/validators';
import styles from './styles';
import firebaseApp from 'firebaseApp';

/**
 * ContactList props
 */
export interface ContactListProps extends StyledComponentProps {
  customerName: string;
  contacts: Contact[];
  onChange: (contacts: Contact[], isValid: boolean) => void;
  companyId: string;
}

/**
 * Single contact form
 */
interface ContactForm {
  name: string;
  email: string;
  phone: string;
}

/**
 * Form validation rules for single contact form in a list.
 */
const rules: FormValidationRules<ContactForm> = {
  fields: {
    name: {
      required: false,
      validate: (value: string) =>
        !!value &&
        !validateString(value, 2) && {
          type: MessageType.ERROR,
          message: i18n().ui.please_enter_contact_person_name,
        },
    },

    email: {
      required: false,
      trim: true,
      validate: (value: string) =>
        !!value &&
        !isEmail(value) && {
          type: MessageType.ERROR,
          message: i18n().ui.please_enter_valid_email,
        },
    },

    phone: {
      required: false,
      validate: (value: string) =>
        !!value &&
        !isPhoneNumber(value) && {
          type: MessageType.ERROR,
          message: i18n().ui.please_enter_phone_number,
        },
    },
  },
};

/**
 * ContactList state
 */
interface State {
  forms: Array<Form<ContactForm>>;
  defaultIndex: number;
  pendingUsersForContacts: string[];
  usersForContacts: string[];
}

/**
 * A user-modifiable list of contact subforms, in which new contacts can be
 * added and existing removed, with self-validation.
 *
 * All fields are optional. Empty forms are not returned within onChange.
 *
 * Note that currently this does not update state from new props since there
 * is no known reliable way to merge new contacts list to existing form values.
 *
 * @export
 * @class ContactList
 * @extends {React.Component<ContactListProps, State>}
 */
export class ContactList extends React.Component<ContactListProps, State> {
  constructor(props: ContactListProps) {
    super(props);

    const { contacts } = props;
    let defaultIndex = 0;
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].default) {
        defaultIndex = i;
        break;
      }
    }

    this.state = {
      forms: contacts.map(contact =>
        validateForm(
          initForm(
            {
              name: contact.name,
              email: contact.email,
              phone: contact.phone,
            },
            rules,
          ),
        ),
      ),
      defaultIndex,
      usersForContacts: [],
      pendingUsersForContacts: [],
    };
  }

  public async componentDidMount() {
    const { contacts } = this.props;

    this.getMembersForContacts(contacts);
  }

  public componentWillReceiveProps(props: ContactListProps) {
    const { contacts } = props;
    let defaultIndex = 0;

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].default) {
        defaultIndex = i;
        break;
      }
    }

    this.setState({
      forms: contacts.map(contact =>
        validateForm(
          initForm(
            {
              name: contact.name,
              email: contact.email,
              phone: contact.phone,
            },
            rules,
          ),
        ),
      ),
      defaultIndex,
    });
  }

  /**
   * Render component.
   *
   * @returns JSX Element
   * @memberof ContactList
   */
  public render() {
    const { classes = {} } = this.props;
    const { forms, defaultIndex } = this.state;

    return (
      <List className={classes.contactList}>
        <List data-test="contacts" className={classes.contacts}>
          {forms.map((form, index) => (
            <List key={index} data-test="contact" className={classes.contact}>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <Divider />
                {this.renderField(index, 'name', i18n().ui.contact_person)}
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <Divider />
                {this.renderField(index, 'email', i18n().ui.email)}
              </ListItem>
              {this.state.pendingUsersForContacts.includes(
                this.state.forms[index].values.email,
              ) && (
                <Typography className={classes.explanatoryLabel}>
                  {i18n().ui.contact_has_pending_invite}
                </Typography>
              )}
              {this.state.usersForContacts.includes(
                this.state.forms[index].values.email,
              ) && (
                <Typography className={classes.explanatoryLabel}>
                  {i18n().ui.contact_has_account}
                </Typography>
              )}
              {!this.state.usersForContacts.includes(
                this.state.forms[index].values.email,
              ) &&
                !this.state.pendingUsersForContacts.includes(
                  this.state.forms[index].values.email,
                ) && (
                  <>
                    <Typography className={classes.explanatoryLabel}>
                      You can grant this customer access to log in and view task
                      reports
                    </Typography>
                    <Button
                      disabled={
                        !this.state.forms[index].values.email ||
                        !this.state.forms[index].values.name ||
                        !this.state.forms[index].isFormValid
                      }
                      className={classes.grantAccessButton}
                      onClick={() =>
                        this.handleGrantAccess(
                          this.state.forms[index].values.name,
                          this.state.forms[index].values.email,
                          this.props.customerName,
                        )
                      }
                    >
                      Grant access
                    </Button>
                  </>
                )}
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneIcon />
                </ListItemIcon>
                <Divider />
                {this.renderField(index, 'phone', i18n().ui.phone)}
              </ListItem>
              <ListItem>
                <Grid container justify="space-between">
                  <Grid item>
                    <FormControlLabel
                      control={<Radio />}
                      label={i18n().ui.default}
                      title={i18n().ui.set_this_contact_as_default}
                      checked={index === defaultIndex}
                      disabled={this.isFormEmpty(index)}
                      onClick={this.handleDefaultRadioClick(index)}
                      className={classes.defaultRadioButton}
                      data-test="defaultRadioButton"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      title={i18n().ui.remove_contact_person}
                      size="large"
                      onClick={this.handleRemoveClick(index)}
                      className={classNames(
                        classes.button,
                        classes.removeButton,
                      )}
                      data-test="removeButton"
                    >
                      <RemoveCircleIcon
                        fontSize="default"
                        className={classes.leftIcon}
                      />
                      {i18n().ui.remove}
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          ))}
        </List>
        <ListItem>
          <Button
            title={i18n().ui.add_contact_person}
            size="large"
            onClick={this.handleAddClick}
            className={classNames(
              classes.button,
              classes.contactListAddItemButton,
            )}
            data-test="addButton"
          >
            <AddCircleIcon fontSize="default" className={classes.leftIcon} />
            {i18n().ui.add_contact_person}
          </Button>
        </ListItem>
      </List>
    );
  }

  /**
   * Render single TextField.
   *
   * @private
   * @param index Form index
   * @param key Field name
   * @param label Field label text
   * @param [props] Additional TextField props
   * @memberof ContactList
   */
  private renderField(
    index: number,
    key: keyof ContactForm,
    label: string,
    props?: Partial<TextFieldProps>,
  ) {
    const { classes = {} } = this.props;
    const {
      values: { [key]: value },
    } = this.state.forms[index];

    return (
      <TextField
        {...props}
        label={label}
        value={value}
        error={this.checkErrorStatus(index, key)}
        helperText={this.getErrorMessage(index, key)}
        margin="normal"
        variant="standard"
        onBlur={this.handleBlur(index, key)}
        onChange={this.handleChange(index, key)}
        className={classes.textField}
        data-test={`${key}TextField`}
      />
    );
  }
  private getErrorMessage = (index: number, key: keyof ContactForm) => {
    const {
      messages: { [key]: message },
    } = this.state.forms[index];

    return message && message.message;
  };

  private checkErrorStatus = (
    index: number,
    key: keyof ContactForm,
  ): boolean => {
    const {
      messages: { [key]: message },
    } = this.state.forms[index];

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  /**
   * Check if form at index has all it's fields empty.
   *
   * @private
   * @param index Form index
   * @returns true if all the fields in form are empty.
   * @memberof ContactList
   */
  private isFormEmpty = (index: number): boolean => {
    const values = this.state.forms[index].values;
    for (const key in values) {
      if (values[key]) {
        return false;
      }
    }
    return true;
  };

  /**
   * Read form data to new Contact[] object and pass
   * it to onChange with form validity status.
   *
   * @private
   * @memberof ContactList
   */
  private triggerOnChange = () => {
    const { forms, defaultIndex } = this.state;

    const contacts: Contact[] = [];
    let isValid = true;
    let defaultFound = false;

    forms.forEach((form, index) => {
      const { values, isFormValid } = form;

      // Treat empty forms as nothing.
      if (this.isFormEmpty(index)) {
        return;
      }

      const contact: Contact = { ...values };

      if (index === defaultIndex) {
        contact.default = true;
        defaultFound = true;
      }

      isValid = isValid && isFormValid;

      contacts.push(contact);
    });

    if (!defaultFound && contacts.length > 0) {
      contacts[0].default = true;
    }

    this.props.onChange(contacts, isValid);
  };

  /**
   * Return function which handles change events for given form and field.
   *
   * Sets field value and validates form.
   *
   * @private
   * @param index Form index
   * @param key Field name
   * @memberof ContactList
   */
  private handleChange = (index: number, key: keyof ContactForm) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const forms = [...this.state.forms];
    const form = forms[index];

    forms[index] = validateForm({
      ...form,
      values: {
        ...form.values,
        [key]: event.target.value,
      },
    });

    this.setState(
      {
        forms,
      },
      this.triggerOnChange,
    );
  };

  /**
   * Return function which handles blur events for given form and field.
   *
   * Marks form field as filled and validates form.
   *
   * @private
   * @param index Form index
   * @param key Field name
   * @memberof ContactList
   */
  private handleBlur = (index: number, key: keyof ContactForm) => () => {
    const forms = [...this.state.forms];
    const form = forms[index];

    forms[index] = validateForm({
      ...form,
      filled: {
        ...form.filled,
        [key]: true,
      },
    });

    this.setState({
      forms,
    });
  };

  /**
   * Return function whick will handle remove button click for given index.
   *
   * If removed contact was default, set first contact as default
   * if there's still contacts left.
   *
   * @private
   * @param index Form index
   * @memberof ContactList
   */
  private handleRemoveClick = (index: number) => () => {
    let { forms, defaultIndex } = this.state;

    forms = [...forms];
    forms.splice(index, 1);

    if (index === defaultIndex) {
      defaultIndex = 0;
    }

    this.setState(
      {
        forms,
        defaultIndex,
      },
      this.triggerOnChange,
    );
  };

  /**
   * Return function which handles default contact radio button click.
   *
   * Sets defaultIndex to given index.
   *
   * @templateprivate
   * @param index Form index
   * @memberof ContactList
   */
  private handleDefaultRadioClick = (index: number) => () => {
    if (this.isFormEmpty(index)) {
      return;
    }

    this.setState(
      {
        defaultIndex: index,
      },
      this.triggerOnChange,
    );
  };

  /**
   * Handle add button click.
   *
   * Adds empty contact at the end of list.
   *
   * @private
   * @memberof ContactList
   */
  private handleAddClick = () => {
    const forms = [...this.state.forms];

    const values: ContactForm = {
      name: '',
      email: '',
      phone: '',
    };
    forms.push(initForm(values, rules));

    // Does not trigger onChange cause we're neglecting
    // empty form until user puts something in it.
    this.setState({ forms });
  };

  /**
   * Check whether the customer has an account to access reports
   *
   * @private
   * @memberof ContactList
   */
  private userExists = async (email: string) => {
    if (!email) {
      return false;
    }

    let userExists = false;

    const userSnapshot = firebaseApp
      .firestore()
      .collection(Schema.USERS)
      .where('email', '==', email);

    await userSnapshot.get().then(doc => {
      if (doc && doc.size > 0) {
        userExists = true;
        // console.log(collectionToJsonWithIds(doc));
        console.log('User exists');
      }
    });

    return userExists;
  };

  /**
   * Check whether the customer has a pending account to access reports
   *
   * @private
   * @memberof ContactList
   */
  private userPending = async (email: string) => {
    if (!email) {
      return false;
    }

    let pendingUserExists = false;

    const pendingUserSnapshot = firebaseApp
      .firestore()
      .collection(Schema.PENDING_USERS)
      .doc(email);

    await pendingUserSnapshot.get().then(doc => {
      if (doc && doc.exists) {
        pendingUserExists = true;
      }
    });
    return pendingUserExists;
  };

  private getMembersForContacts = async (contacts: Contact[]) => {
    console.log('getting members for contacts');
    for (const contact of contacts) {
      this.userExists(contact.email).then(exists => {
        if (exists) {
          this.setState(state => {
            const newEmails = state.usersForContacts.concat(contact.email);
            return {
              ...state,
              usersForContacts: newEmails,
            };
          });
        } else {
          this.userPending(contact.email).then(pendingExists => {
            if (pendingExists) {
              this.setState(state => {
                const newEmails = state.pendingUsersForContacts.concat(
                  contact.email,
                );
                return {
                  ...state,
                  pendingUsersForContacts: newEmails,
                };
              });
            }
          });
        }
      });
    }
  };

  /**
   *
   * Creates a pending user for the contact with a "Customer" role
   *
   * @param email
   * @memberof ContactList
   */
  private handleGrantAccess = async (
    name: string,
    email: string,
    customerCompanyName: string,
  ) => {
    const endCustomerMember: MemberWithKey = {
      key: '',
      active: true,
      name: name,
      email: email,
      photoURL: '',
      phone: '',
      role: UserRole.END_CUSTOMER,
      jobtypes: {},
      workTimes: [],
      qualifications: {},
      isContact: true,
      endCustomerCompanyName: customerCompanyName,
    };

    let userExists = false;
    let pendingUserExists = false;

    try {
      userExists = await this.userExists(email);

      if (!userExists) {
        pendingUserExists = await this.userPending(email);
      }

      if (!userExists && !pendingUserExists) {
        firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.MEMBERS)
          .add(endCustomerMember)
          .then(newMemberDocRef => {
            console.log('success');
            newMemberDocRef.get().then(newMemberDoc => {
              const newMemberEmail = newMemberDoc.get('email');
              this.setState(state => {
                const newPendingUsers = state.pendingUsersForContacts.concat(
                  newMemberEmail,
                );
                return {
                  ...state,
                  pendingUsersForContacts: newPendingUsers,
                };
              });
            });
            // this.getMembersForContacts(this.props.contacts);
          });
      }
    } catch (e) {
      console.error('Failed to grant end customer access: ', e);
    }
  };
}

export default withStyles(styles, { withTheme: true })(ContactList);
