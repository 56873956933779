/**
 * Template for creating reducers.
 *
 * Use this template to create new reducers to system. Copy the whole folder and rename needed parts
 * and add new reducer as a part of ApplicationState and Redux at `../index.tsx`
 *
 * Replace all example/EXAMPLE -texts in this template with descriptive short name of your reducer-
 *
 * @author Ville Venäläinen
 */
import { DateRange } from '@shared/schema';
import moment from 'moment';
import { ActionCreator, AnyAction } from 'redux';

export const enum TimelineActionType {
  TIMELINE_UPDATE_START_END_HOURS = 'TIMELINE_UPDATE_START_END_HOURS',

  TIMELINE_GO_TODAY = 'TIMELINE_GO_TODAY',
  TIMELINE_GO_TO_PICKED_DAY = 'TIMELINE_GO_TO_PICKED_DAY',

  TIMELINE_STEP_BACKWARD = 'TIMELINE_STEP_BACKWARD',
  TIMELINE_STEP_FORWARD = 'TIMELINE_STEP_FORWARD',

  TIMELINE_SET_RANGE = 'TIMELINE_SET_RANGE',

  TIMELINE_SET_MEMBER_FILTER = 'TIMELINE_SET_MEMBER_FILTER',
  TIMELINE_ORDER_TASKS_BY_ASSIGN = 'TIMELINE_ORDER_TASKS_BY_ASSIGN',
  TIMELINE_HIDE_MEMBERS = 'TIMELINE_HIDE_MEMBERS',
  TIMELINE_REVEAL_MEMBERS = 'TIMELINE_REVEAL_MEMBERS',
  TIMELINE_SET_HIDDEN_MEMBERS = 'TIMELINE_SET_HIDDEN_MEMBERS',
  TIMELINE_HIDE_ALL_MEMBERS = 'TIMELINE_HIDE_ALL_MEMBERS',
  TIMELINE_REVEAL_ALL_MEMBERS = 'TIMELINE_REVEAL_ALL_MEMBERS',
  TIMELINE_REVEAL_PRIORITIES = 'TIMELINE_REVEAL_PRIORITIES',
  TIMELINE_HIDE_PRIORITIES = 'TIMELINE_HIDE_PRIORITIES',
  TIMELINE_REVEAL_ALL_PRIORITIES = 'TIMELINE_REVEAL_ALL_PRIORITIES',
  TIMELINE_REVEAL_JOBTYPES = 'TIMELINE_REVEAL_JOBTYPES',
  TIMELINE_HIDE_JOBTYPES = 'TIMELINE_HIDE_JOBTYPES',
  TIMELINE_REVEAL_ALL_JOBTYPES = 'TIMELINE_REVEAL_ALL_JOBTYPES',
  TIMELINE_REVEAL_ALL_ASSIGNED_TASKS = 'TIMELINE_REVEAL_ALL_ASSIGNED_TASKS',
  TIMELINE_REVEAL_QUALIFICATIONS = 'TIMELINE_REVEAL_QUALIFICATIONS',
  TIMELINE_HIDE_QUALIFICATIONS = 'TIMELINE_HIDE_QUALIFICATIONS',
  TIMELINE_SET_DATERANGE = 'TIMELINE_SET_DATERANGE',
  OPEN_WARNING = 'OPEN_WARNING',
  DEQUEUE_WARNING = 'DEQUEUE_WARNING',
}

export interface UpdateStartEndHours extends AnyAction {
  type: TimelineActionType.TIMELINE_UPDATE_START_END_HOURS;
}

export const updateStartEndHours: ActionCreator<UpdateStartEndHours> = (
  startHour?: number,
  endHour?: number,
) => ({
  type: TimelineActionType.TIMELINE_UPDATE_START_END_HOURS,
  payload: { startHour, endHour },
});

export interface GoToDayAction extends AnyAction {
  type: TimelineActionType.TIMELINE_GO_TODAY;
}

export const goToday: ActionCreator<GoToDayAction> = () => ({
  type: TimelineActionType.TIMELINE_GO_TODAY,
});

export interface GoToPickedDayAction extends AnyAction {
  type: TimelineActionType.TIMELINE_GO_TO_PICKED_DAY;
}

export const goToPickedDay: ActionCreator<GoToPickedDayAction> = (
  date: Date,
) => ({
  type: TimelineActionType.TIMELINE_GO_TO_PICKED_DAY,
  payload: date,
});

export interface StepBackwardAction extends AnyAction {
  type: TimelineActionType.TIMELINE_STEP_BACKWARD;
  payload: number;
}

export const stepBackward: ActionCreator<StepBackwardAction> = (
  days: number,
) => ({
  type: TimelineActionType.TIMELINE_STEP_BACKWARD,
  payload: days,
});

export interface StepForwardAction extends AnyAction {
  type: TimelineActionType.TIMELINE_STEP_FORWARD;
  payload: number;
}

export const stepForward: ActionCreator<StepForwardAction> = (
  days: number,
) => ({
  type: TimelineActionType.TIMELINE_STEP_FORWARD,
  payload: days,
});

export interface SetRangeAction extends AnyAction {
  type: TimelineActionType.TIMELINE_SET_RANGE;
  payload: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

export const setRange: ActionCreator<SetRangeAction> = (
  start: moment.Moment,
  end: moment.Moment,
) => ({
  type: TimelineActionType.TIMELINE_SET_RANGE,
  payload: {
    start,
    end,
  },
});

export interface RevealTasksFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_JOBTYPES;
  payload: string | string[];
}

export interface SetDateRangeAction extends AnyAction {
  type: TimelineActionType.TIMELINE_SET_DATERANGE;
  payload: DateRange;
}

export interface RevealQualificationsFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_QUALIFICATIONS;
  payload: string | string[];
}

export interface HideQualificationsFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_HIDE_QUALIFICATIONS;
  payload: string | string[];
}
export interface RevealPrioritiesFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_PRIORITIES;
  payload: number | number[];
}

export interface HidePrioritiesFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_HIDE_PRIORITIES;
  payload: number | number[];
}
export const revealQualifications: ActionCreator<RevealQualificationsFilterAction> =
  (qualificationKeys: string | string[]) => ({
    type: TimelineActionType.TIMELINE_REVEAL_QUALIFICATIONS,
    payload: qualificationKeys,
  });

export const hideQualifications: ActionCreator<HideQualificationsFilterAction> =
  (qualifications: string | string[]) => ({
    type: TimelineActionType.TIMELINE_HIDE_QUALIFICATIONS,
    payload: qualifications,
  });

export const revealPriorities: ActionCreator<RevealPrioritiesFilterAction> = (
  priorityKeys: number | number[],
) => ({
  type: TimelineActionType.TIMELINE_REVEAL_PRIORITIES,
  payload: priorityKeys,
});

export const hidePriorities: ActionCreator<HidePrioritiesFilterAction> = (
  priorities: number | number[],
) => ({
  type: TimelineActionType.TIMELINE_HIDE_PRIORITIES,
  payload: priorities,
});
export const revealTasks: ActionCreator<RevealTasksFilterAction> = (
  tasks: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_REVEAL_JOBTYPES,
  payload: tasks,
});

export const setDateRange: ActionCreator<SetDateRangeAction> = (
  selectedDateRange: DateRange,
) => ({
  type: TimelineActionType.TIMELINE_SET_DATERANGE,
  payload: selectedDateRange,
});

export interface HideTasksFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_HIDE_JOBTYPES;
  payload: string | string[];
}

export const hideTasks: ActionCreator<HideTasksFilterAction> = (
  tasks: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_HIDE_JOBTYPES,
  payload: tasks,
});

export interface HideMembersFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_HIDE_MEMBERS;
  payload: string | string[];
}

export const hideMembers: ActionCreator<HideMembersFilterAction> = (
  resources: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_HIDE_MEMBERS,
  payload: resources,
});

export interface RevealMembersFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_MEMBERS;
  payload: string | string[];
}

export const revealMembers: ActionCreator<RevealMembersFilterAction> = (
  resources: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_REVEAL_MEMBERS,
  payload: resources,
});

export interface OrderTasksByAssign extends AnyAction {
  type: TimelineActionType.TIMELINE_ORDER_TASKS_BY_ASSIGN;
}

export const orderTasksByAssign: ActionCreator<OrderTasksByAssign> = () => ({
  type: TimelineActionType.TIMELINE_ORDER_TASKS_BY_ASSIGN,
});

export interface SetHiddenMembersFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_SET_HIDDEN_MEMBERS;
  payload: string | string[];
}

export const setHiddenMembers: ActionCreator<SetHiddenMembersFilterAction> = (
  members: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_SET_HIDDEN_MEMBERS,
  payload: members,
});

export interface RevealAllMembersFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_ALL_MEMBERS;
}
export const revealAllMembers: ActionCreator<RevealAllMembersFilterAction> = (
  members: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_REVEAL_ALL_MEMBERS,
});
export interface RevealAllAssignedTasksFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_ALL_ASSIGNED_TASKS;
}
export const revealAllAssignedTasks: ActionCreator<RevealAllAssignedTasksFilterAction> =
  (members: string | string[]) => ({
    type: TimelineActionType.TIMELINE_REVEAL_ALL_ASSIGNED_TASKS,
  });
export const hideAllMembers: ActionCreator<HideAllMembersFilterAction> = (
  members: string | string[],
) => ({
  type: TimelineActionType.TIMELINE_HIDE_ALL_MEMBERS,
  payload: members,
});

export interface HideAllMembersFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_HIDE_ALL_MEMBERS;
  payload: string | string[];
}

export interface RevealAllTasksFilterAction extends AnyAction {
  type: TimelineActionType.TIMELINE_REVEAL_ALL_JOBTYPES;
}

export const revealAllTasks: ActionCreator<RevealAllTasksFilterAction> =
  () => ({
    type: TimelineActionType.TIMELINE_REVEAL_ALL_JOBTYPES,
  });

export interface OpenWarningAction extends AnyAction {
  type: TimelineActionType.OPEN_WARNING;
}

export const openWarning: ActionCreator<OpenWarningAction> = (
  message: string,
) => {
  return {
    type: TimelineActionType.OPEN_WARNING,
    payload: message,
  };
};
export interface DequeueWarningAction extends AnyAction {
  type: TimelineActionType.DEQUEUE_WARNING;
}

export const dequeueWarning: ActionCreator<DequeueWarningAction> = () => ({
  type: TimelineActionType.DEQUEUE_WARNING,
});

export type TimelineAction =
  | AnyAction
  | UpdateStartEndHours
  | GoToDayAction
  | GoToPickedDayAction
  | HideMembersFilterAction
  | RevealAllMembersFilterAction
  | RevealMembersFilterAction
  | SetHiddenMembersFilterAction
  | RevealTasksFilterAction
  | HideTasksFilterAction
  | SetRangeAction
  | StepBackwardAction
  | StepForwardAction
  | SetDateRangeAction
  | OpenWarningAction
  | DequeueWarningAction;
