import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Snackbar,
  TextField,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from '@shared/locale';
import { validateString } from '@shared/validators';
import firebase from 'firebase';
import { isEqual } from 'lodash';
import * as React from 'react';
import UserSettingsHeader from '../components/UserSettingsHeader';
import UserSettingsInfoAndActions from '../components/UserSettingsInfoAndActions';
import styles from '../styles';

interface UserSettingsState {
  changePwDialogVisible: boolean;
  newPassword: string;
  newPasswordValid: boolean;
  newPwBlurCalled: boolean;
  confirmPassword: string;
  confirmPasswordValid: boolean;
  confirmPwBlurCalled: boolean;
  actionInProgress: boolean;
  oldPassword: string;
  openSnack: boolean;
  snackMessage: string;
}

export interface UserSettingsProps extends StyledComponentProps {
  userInfo?: firebase.User;
}

export class UserSettingsContainer extends React.Component<
  UserSettingsProps,
  UserSettingsState
> {
  constructor(props: UserSettingsProps) {
    super(props);
    this.state = {
      changePwDialogVisible: false,
      newPassword: '',
      newPasswordValid: false,
      newPwBlurCalled: false,
      confirmPassword: '',
      confirmPasswordValid: false,
      confirmPwBlurCalled: false,
      actionInProgress: false,
      oldPassword: '',
      openSnack: false,
      snackMessage: '',
    };
  }

  public render() {
    const { classes = {}, userInfo } = this.props;
    const {
      changePwDialogVisible,
      newPassword,
      confirmPassword,
      actionInProgress,
      newPasswordValid,
      confirmPasswordValid,
      newPwBlurCalled,
      confirmPwBlurCalled,
      oldPassword,
      openSnack,
      snackMessage,
    } = this.state;

    return (
      <div className={classes.mainContainer}>
        {userInfo && (
          <div className={classes.displayFlex}>
            <UserSettingsHeader
              userImage={userInfo.photoURL}
              userName={userInfo.displayName}
            />
            <UserSettingsInfoAndActions
              userEmail={userInfo.email}
              userName={userInfo.displayName}
              openChangePwDialog={this.openChangePwDialog}
            />
          </div>
        )}

        {changePwDialogVisible && (
          <Dialog
            data-test="changePasswordDialog"
            open={true}
            onClose={this.closeChangePwDialog}
          >
            <DialogTitle>{i18n().auth.change_pw}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {actionInProgress ? (
                <CircularProgress className={classes.circleLoader} size={40} />
              ) : (
                <FormControl>
                  <FormControl className={classes.formMargin}>
                    <TextField
                      value={oldPassword}
                      className={classes.input}
                      type="password"
                      placeholder={i18n().auth.enter_old_password}
                      onChange={this.onPwChange('oldPw')}
                      data-test="oldPasswordField"
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      value={newPassword}
                      className={classes.input}
                      type="password"
                      placeholder={i18n().auth.enter_new_pw}
                      onChange={this.onPwChange('newPw')}
                      onBlur={this.validatePw('newPw', true)}
                      error={!newPasswordValid && newPwBlurCalled}
                      helperText={i18n().auth.pw_too_short}
                      data-test="newPasswordField"
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      value={confirmPassword}
                      className={classes.input}
                      type="password"
                      placeholder={i18n().auth.confirm_pw}
                      onChange={this.onPwChange('confirmPw')}
                      onBlur={this.validatePw('confirmPw', true)}
                      error={!confirmPasswordValid && confirmPwBlurCalled}
                      helperText={i18n().auth.pw_dont_match}
                      data-test="confirmPasswordField"
                    />
                  </FormControl>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={actionInProgress}
                onClick={this.closeChangePwDialog}
                data-test="cancelButton"
              >
                {i18n().ui.cancel}
              </Button>
              <Button
                disabled={
                  actionInProgress ||
                  !confirmPasswordValid ||
                  !newPasswordValid ||
                  oldPassword === ''
                }
                onClick={this.changePassword}
                className={classes.changePwTextColor}
                data-test="changePasswordButton"
              >
                {i18n().ui.save_changes}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={snackMessage}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }

  /**
   * Closes SnackBar
   */
  private handleSnackClose = () => {
    this.setState({ openSnack: false, snackMessage: '' });
  };

  /**
   * Called everytime user inputs text to password fields
   * @param {string} correctPwToModify
   * @event event
   */
  private onPwChange = (correctPwToModify: string) => (event: any) => {
    const newValue: string = event.target.value;

    const { newPwBlurCalled, newPassword } = this.state;

    switch (correctPwToModify) {
      case 'confirmPw':
        this.setState({
          confirmPassword: newValue,
          confirmPasswordValid: isEqual(newPassword, newValue),
        });

        break;

      case 'newPw':
        if (newPwBlurCalled) {
          this.setState({
            newPassword: newValue,
            newPasswordValid: validateString(newValue, 6),
          });
        } else {
          this.setState({ newPassword: newValue });
        }
        break;

      case 'oldPw':
        this.setState({ oldPassword: newValue });

        break;
    }
  };

  /**
   * Validates new and confirmPasswords
   * @param {string} correctPwToValidate
   * @param {boolean} isBlur
   */
  private validatePw = (correctPwToValidate: string, isBlur: boolean) => () => {
    const { confirmPassword, newPassword } = this.state;

    switch (correctPwToValidate) {
      case 'confirmPw':
        if (isBlur) {
          this.setState({
            confirmPasswordValid: isEqual(confirmPassword, newPassword),
            confirmPwBlurCalled: true,
          });
        } else {
          this.setState({
            confirmPasswordValid: isEqual(confirmPassword, newPassword),
          });
        }

        break;

      case 'newPw':
        if (isBlur) {
          this.setState({
            newPasswordValid: validateString(newPassword, 6),
            newPwBlurCalled: true,
          });
        } else {
          this.setState({
            newPasswordValid: validateString(newPassword, 6),
          });
        }

        break;
    }
  };

  /**
   * Function for actual password change
   */
  private changePassword = () => {
    const { newPassword, oldPassword } = this.state;
    const { userInfo } = this.props;

    this.setState({ actionInProgress: true });
    const user = firebase.auth().currentUser;

    if (user && userInfo && userInfo.email) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        userInfo.email,
        oldPassword,
      );
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updatePassword(newPassword)
            .then(resolve => {
              this.setState({
                changePwDialogVisible: false,
                newPassword: '',
                newPasswordValid: false,
                newPwBlurCalled: false,
                confirmPassword: '',
                confirmPasswordValid: false,
                confirmPwBlurCalled: false,
                actionInProgress: false,
                oldPassword: '',
                openSnack: true,
                snackMessage: i18n().auth.pw_changed_succesfully,
              });
            })
            .catch((err: Error) => {
              console.error(err);
              this.setState({
                changePwDialogVisible: false,
                newPassword: '',
                newPasswordValid: false,
                newPwBlurCalled: false,
                confirmPassword: '',
                confirmPasswordValid: false,
                confirmPwBlurCalled: false,
                actionInProgress: false,
                oldPassword: '',
                openSnack: true,
                snackMessage: i18n().auth.please_try_again,
              });
            });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            changePwDialogVisible: false,
            newPassword: '',
            newPasswordValid: false,
            newPwBlurCalled: false,
            confirmPassword: '',
            confirmPasswordValid: false,
            confirmPwBlurCalled: false,
            actionInProgress: false,
            oldPassword: '',
            openSnack: true,
            snackMessage: i18n().auth.invalid_pw,
          });
        });
    }
  };

  /**
   * Opens dialog where password inputs are
   */
  private openChangePwDialog = () => {
    this.setState({ changePwDialogVisible: true });
  };

  /**
   * Closes dialog where password inputs are and resets state
   */
  private closeChangePwDialog = () => {
    this.setState({
      changePwDialogVisible: false,
      newPassword: '',
      newPasswordValid: false,
      newPwBlurCalled: false,
      confirmPassword: '',
      confirmPasswordValid: false,
      confirmPwBlurCalled: false,
      actionInProgress: false,
      oldPassword: '',
    });
  };
}

export default withStyles(styles, { withTheme: true })(UserSettingsContainer);
