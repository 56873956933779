import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  StyledComponentProps,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from '@shared/locale';
import {
  CustomerWithKey,
  getPriorityMap,
  ImportTemplate,
  JobTypeWithKey,
  MemberWithKey,
  Schema,
  TaskWithKey,
  WorksiteRef,
  WorksiteWithKey,
} from '@shared/schema';
import { resolveTimeString } from '@shared/utils/timeUtils';
import LoadingSpinner from 'components/LoadingSpinner';
import firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import Geocode from 'react-geocode';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import { cancelImportTasks } from 'reducers/importTasks/importTasksAction';
import { TaskStatus } from '../../../../../../../@shared/schema/index';
import styles from './styles';

interface ImportTasksDialogProps extends StyledComponentProps, DispatchProp {
  companyId?: string;
  members: MemberWithKey[];
  worksites: WorksiteWithKey[];
  jobtypes: JobTypeWithKey[];
  customers: CustomerWithKey[];
}

interface State {
  tasks: TaskWithKey[];
  taskSupportData: TaskSupportData[];
  confirmDialog: boolean;
  loading: boolean;
  importSuccessful?: boolean;
  downloading: boolean;
  downloadSuccessful?: boolean;
  fileInfo: IFileInfo;
}

interface TimeFrame {
  start: number;
  end: number;
}

interface TaskSupportData {
  taskKey: string;
  email: string;
  businessId: string;
  worksiteId: string;
  worksiteCustomerId: string;
}

class ImportTasksDialog extends React.Component<ImportTasksDialogProps, State> {
  constructor(props: ImportTasksDialogProps) {
    super(props);
    this.state = {
      tasks: [],
      taskSupportData: [],
      confirmDialog: false,
      loading: false,
      importSuccessful: undefined,
      downloading: false,
      downloadSuccessful: undefined,
      fileInfo: {
        name: '',
        size: 0,
        type: '',
      },
    };
  }

  public render() {
    const { classes = {} } = this.props;
    const {
      tasks,
      loading,
      importSuccessful,
      confirmDialog,
      downloading,
      downloadSuccessful,
    } = this.state;

    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        className={classes.shiftEditorDialog}
        classes={{ paper: classes.paper }}
        maxWidth={'lg'}
      >
        <DialogTitle className={classes.dialogTitle}>
          {i18n().ui.import_tasks}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {tasks.length === 0 && (
            <div className={classes.dialogContainer}>
              <div className={classes.buttonContainer}>
                <div className={classes.uploadContainer}>
                  <Typography className={classes.buttonHeader}>
                    {i18n().ui.import_filled_csv_chart}
                  </Typography>

                  <Button
                    color="primary"
                    className={classes.downloadButton}
                    classes={{ root: classes.buttonRoot }}
                  >
                    <CSVReader
                      cssInputClass={classes.csvReaderInput}
                      cssLabelClass={classes.csvReaderLabel}
                      label={i18n().ui.select_file}
                      onFileLoaded={(data, fileInfo) => {
                        this.handleUpload(data);
                        this.setState({ fileInfo });
                      }}
                    />
                  </Button>
                </div>
                <div className={classes.downloadContainer}>
                  <Typography className={classes.buttonHeader}>
                    {i18n().ui.download_example_csv_import_form}
                  </Typography>
                  <Button
                    onClick={this.handleDownloadTemplate}
                    variant="outlined"
                    color="primary"
                    className={classes.downloadButton}
                  >
                    {!downloading &&
                      downloadSuccessful === undefined &&
                      i18n().ui.download}
                    {downloading && (
                      <CircularProgress
                        style={{ height: '20px', width: '20px' }}
                      />
                    )}
                    {!downloading && downloadSuccessful && i18n().ui.downloaded}
                    {!downloading &&
                      downloadSuccessful === false &&
                      i18n().ui.error}
                  </Button>
                </div>
              </div>
              <div className={classes.instructionsContainer}>
                <h4 className={classes.instructionsHeader}>
                  {i18n().ui.tips_to_fill_out_the_form}
                </h4>
                <Typography>
                  {'- ' + i18n().ui.only_required_field_is_jobtype}
                </Typography>
                <Typography>{'- ' + i18n().ui.customer_task_id_tip}</Typography>
                <Typography>
                  {'- ' +
                    i18n().ui.there_are_many_options_to_fill_timeframe +
                    ':'}
                </Typography>
                <ul className={classes.list}>
                  <li>{i18n().ui.weeknumber_creates_flexible_task_tip}</li>
                  <li>{i18n().ui.date_format_tip}</li>
                  <li>{i18n().ui.start_end_time_tip}</li>
                </ul>
                <Typography>
                  {'- ' +
                    i18n().ui.new_worksites_customers_jobtypes_are_created_tip}
                </Typography>
              </div>
            </div>
          )}
          {this.state.fileInfo.name && (
            <Typography className={classes.importFileName} color="primary">
              {i18n().ui.preview +
                ' - ' +
                this.state.fileInfo.name +
                this.state.fileInfo.type}
            </Typography>
          )}
          {tasks.length > 0 && (
            <List className={classes.tasksList}>
              {tasks.map(task => {
                return (
                  <ListItem
                    key={task.id}
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                  >
                    <ListItemText
                      id={task.id}
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.listTextPrimary}
                            color="primary"
                          >
                            {task.customerTaskId
                              ? task.jobtypeName +
                                ' (' +
                                task.customerTaskId +
                                ')'
                              : task.jobtypeName}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.listText}
                            color="primary"
                          >
                            {(task.location &&
                              task.location.address +
                                ' ' +
                                task.location.postalCode +
                                ' ' +
                                task.location.city) ||
                              i18n().ui.no_location_set}
                          </Typography>
                          {this.resolveVisibleBusinessId(task)}
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.listText}
                            color="primary"
                          >
                            {resolveTimeString(task)}
                          </Typography>

                          {this.resolveVisibleMemberEmail(task)}

                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.listText}
                            color="primary"
                          >
                            {i18n().ui.task_info +
                              ': ' +
                              (task.desc &&
                                task.desc.substring(0, 75) +
                                  (task.desc.length > 75 ? '...' : ''))}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button color="default" onClick={this.handleClose}>
            {i18n().ui.cancel}
          </Button>
          <Button
            color="default"
            disabled={tasks.length === 0}
            onClick={this.handleImportClick}
          >
            {i18n().ui.import}
          </Button>
        </DialogActions>
        <Dialog aria-labelledby="simple-dialog-title" open={confirmDialog}>
          <div className={classes.confirmDialogContainer}>
            {!loading && (
              <DialogTitle id="alert-dialog-title">
                {i18n().ui.import_tasks}
              </DialogTitle>
            )}
            {!loading && importSuccessful === undefined && (
              <DialogContent>
                {i18n().ui.import_confirm +
                  ' ' +
                  tasks.length +
                  ' ' +
                  i18n().ui.tasks +
                  '? ' +
                  i18n().ui
                    .new_worksites_and_jobtypes_will_be_created_automatically}
              </DialogContent>
            )}
            {importSuccessful === false && (
              <DialogContent>
                {i18n().ui.something_went_wrong_check_csv}
              </DialogContent>
            )}
            {importSuccessful === true && (
              <DialogContent>{i18n().ui.import_complete}</DialogContent>
            )}
            {loading && importSuccessful === undefined && <LoadingSpinner />}
          </div>
          {!loading && (
            <DialogActions className={classes.dialogFooter}>
              {importSuccessful === undefined && (
                <>
                  <Button color="default" onClick={this.handleCancel}>
                    {i18n().ui.cancel}
                  </Button>
                  <Button color="default" onClick={this.handleImport}>
                    {i18n().ui.import}
                  </Button>
                </>
              )}
              {importSuccessful !== undefined && (
                <Button color="default" onClick={this.handleClose}>
                  {i18n().ui.close}
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </Dialog>
    );
  }

  /**
   * Closes import dialog
   */
  private handleClose = () => {
    this.setState({
      loading: false,
      importSuccessful: undefined,
      confirmDialog: false,
    });
    this.props.dispatch(cancelImportTasks());
  };

  /**
   * Closes confirmDialog
   */
  private handleCancel = () => {
    this.setState({
      loading: false,
      importSuccessful: undefined,
      confirmDialog: false,
    });
  };

  /**
   * Opens confirmDialog
   */
  private handleImportClick = () => {
    this.setState({ confirmDialog: true });
  };

  /**
   * Handler when download button is pressed.
   * Gets csv file from firebase storage and downloads it to users computer
   */
  private handleDownloadTemplate = () => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const csvRef = storageRef.child('templates/Mowo csv-tuonti mallipohja.csv');

    this.setState({
      downloading: true,
    });

    csvRef
      .getDownloadURL()
      .then(url => {
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        this.setState({
          downloading: false,
          downloadSuccessful: true,
        });
      })
      .catch(error => {
        console.error('Error downloading file: ', error);
        this.setState({
          downloading: false,
          downloadSuccessful: false,
        });
      });
  };

  /**
   * Executes after pressing import, checks which customers, jobtypes, worksites and tasks need to be created,
   * stores them into batch and commits into firebase
   */
  private handleImport = async () => {
    const { jobtypes, members, customers, worksites, companyId } = this.props;
    const { tasks, taskSupportData } = this.state;

    /**
     * Arrays of recently created items, used to check that if item is already created during previous loop
     */
    const newJobTypes: JobTypeWithKey[] = [];
    const newCustomers: CustomerWithKey[] = [];
    const newWorksites: WorksiteWithKey[] = [];

    /**
     * Array of already created customers during worksitesToBeAdded loop so they dont get created twice
     */
    const refAddedCustomers: CustomerWithKey[] = [];

    /**
     * Using promises because new worksites will need geo coords from geocodeAPI
     */
    const worksitePromises: Array<Promise<WorksiteWithKey>> = [];
    const batch = firebaseApp.firestore().batch();

    this.setState({ loading: true });

    tasks.forEach(async task => {
      /**
       * Check if support data is found on task
       * Used with member,customerId & worksiterefs
       */
      const foundTaskSupportData = taskSupportData.find(
        data => data.taskKey === task.key,
      );

      /**
       * Checks if jobtypes need to be created
       */
      const foundJobtype =
        jobtypes.find(jobtype => jobtype.name === task.jobtypeName) ||
        newJobTypes.find(newJobType => newJobType.name === task.jobtypeName);

      if (foundJobtype) {
        if (foundJobtype.key) {
          task.jobtypeId = foundJobtype.key;
        }
      } else {
        const jobtypeRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.JOBTYPES)
          .doc();

        const key = jobtypeRef.id;

        task.jobtypeId = key;

        const newJobType: JobTypeWithKey = {
          key,
          name: task.jobtypeName,
        };
        batch.set(jobtypeRef, newJobType);
        newJobTypes.push(newJobType);
      }

      /**
       * Checks if worksites need to be created
       */
      const foundWorksite =
        worksites.find(
          worksite =>
            worksite.location.address ===
              (task.location && task.location.address) &&
            worksite.location.city === (task.location && task.location.city),
        ) ||
        newWorksites.find(
          newWorksite =>
            newWorksite.location.address ===
              (task.location && task.location.address) &&
            newWorksite.location.city === (task.location && task.location.city),
        );

      if (foundWorksite) {
        if (foundTaskSupportData) {
          /**
           * Put new customer to already existing worksite and add ref to customer TODO HERE
           */
          const foundCustomer = customers.find(
            customer => customer.key === foundWorksite.customer,
          );
          if (foundCustomer) {
            foundWorksite.customer = foundCustomer.key;
            worksitePromises.push(this.resolveWorksite(foundWorksite, true));
          }
        }
        if (task.worksite) {
          task.worksite.id = foundWorksite.key;
        }
      } else {
        let key = '';
        if (foundTaskSupportData) {
          key = foundTaskSupportData.worksiteId;
        }
        if (task.worksite) {
          task.worksite.id = key;
        }

        const newWorksite: WorksiteWithKey = {
          key,
          nick: '',
          name: (task.worksite && task.worksite.name) || '',
          note: '',
          location: {
            address: (task.location && task.location.address) || '',
            postalCode: (task.location && task.location.postalCode) || '',
            city: (task.location && task.location.city) || '',
          },
        };

        worksitePromises.push(this.resolveWorksite(newWorksite));
        newWorksites.push(newWorksite);
      }

      /**
       * Checks if customers need to be created
       */
      if (foundTaskSupportData && foundTaskSupportData.businessId) {
        const foundCustomer =
          customers.find(
            customer =>
              customer.customerNumber === foundTaskSupportData.businessId,
          ) ||
          newCustomers.find(
            newCustomer =>
              newCustomer.customerNumber === foundTaskSupportData.businessId,
          );
        if (foundCustomer) {
          task.customerId = foundCustomer.key;
          task.customerName = foundCustomer.name;
        } else {
          const customerRef = firebaseApp
            .firestore()
            .collection(Schema.COMPANIES)
            .doc(companyId)
            .collection(Schema.CUSTOMERS)
            .doc();

          const key = customerRef.id;

          task.customerId = key;
          task.customerName = foundTaskSupportData.businessId;

          const newCustomer: CustomerWithKey = {
            key,
            name: foundTaskSupportData.businessId,
            customerNumber: foundTaskSupportData.businessId,
            worksiteRefs: {},
            address: '',
            city: '',
            contacts: [],
            postalCode: '',
            tasks: {},
          };

          newCustomers.push(newCustomer);
        }
      }

      /**
       * Assign member to task, if no member found task will be left as free task
       */
      if (foundTaskSupportData) {
        const foundMember = members.find(
          member => member.email === foundTaskSupportData.email,
        );
        if (foundMember) {
          task.userId = foundMember.key;
          task.userName = foundMember.name;
        }
      }

      /**
       * Add task to batch
       */
      const taskRef = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.TASKS)
        .doc(task.key);

      batch.set(taskRef, task);
    });

    /**
     * Executes after all promises have been solved
     */
    Promise.all(worksitePromises)
      .then(worksitesToBeAdded => {
        worksitesToBeAdded.forEach(worksite => {
          /**
           * Checks for what customerIds need to be added to worksites and which worksite refs need to be added to customer
           */
          const foundTaskSupportData = taskSupportData.find(
            data => data.worksiteId === worksite.key,
          );
          if (foundTaskSupportData && foundTaskSupportData.businessId) {
            const foundCustomer =
              customers.find(
                customer =>
                  customer.customerNumber === foundTaskSupportData.businessId,
              ) ||
              newCustomers.find(
                newCustomer =>
                  newCustomer.customerNumber ===
                  foundTaskSupportData.businessId,
              );
            const foundOldCustomer = customers.find(
              customer => customer.key === worksite.customer,
            );

            /**
             * Previus customer exists, deleting it from old customer
             */
            if (foundOldCustomer && foundOldCustomer.key !== '') {
              const oldCustomerRefs = foundOldCustomer.worksiteRefs;
              delete oldCustomerRefs[worksite.key];

              const oldCustomerRef = firebaseApp
                .firestore()
                .collection(Schema.COMPANIES)
                .doc(companyId)
                .collection(Schema.CUSTOMERS)
                .doc(foundOldCustomer.key);

              batch.set(oldCustomerRef, foundOldCustomer);
            }

            /**
             * Adding customerId to worksite
             */
            if (foundCustomer) {
              worksite.customer = foundCustomer.key;

              /**
               * Adding ref to new customer
               */
              let customerRefs = foundCustomer.worksiteRefs;
              const newRef: WorksiteRef = {
                [`${worksite.key}`]: {
                  name: worksite.name,
                  nick: worksite.nick || '',
                },
              };
              if (!customerRefs[worksite.key]) {
                customerRefs = Object.assign(customerRefs, newRef);
                foundCustomer.worksiteRefs = customerRefs;
              }

              const customerRef = firebaseApp
                .firestore()
                .collection(Schema.COMPANIES)
                .doc(companyId)
                .collection(Schema.CUSTOMERS)
                .doc(foundCustomer.key);
              refAddedCustomers.push(foundCustomer);
              batch.set(customerRef, foundCustomer);
            }
          }
          const worksiteRef = firebaseApp
            .firestore()
            .collection(Schema.COMPANIES)
            .doc(companyId)
            .collection(Schema.WORKSITE)
            .doc(worksite.key);
          batch.set(worksiteRef, worksite);
        });

        /**
         * Adding customers that have not yet been added to batch
         * Made for tasks which don't have worksite but still have customer set
         */
        newCustomers.forEach(customer => {
          const foundTask = tasks.find(
            task => task.customerId === customer.key,
          );
          const foundDuplicate = refAddedCustomers.find(
            alreadyAddedCustomer => alreadyAddedCustomer.key === customer.key,
          );
          if (foundTask && !foundDuplicate) {
            const customerRef = firebaseApp
              .firestore()
              .collection(Schema.COMPANIES)
              .doc(companyId)
              .collection(Schema.CUSTOMERS)
              .doc(customer.key);
            batch.set(customerRef, customer);
          }
        });
        try {
          batch.commit().then(() => {
            this.setState({ importSuccessful: true, loading: false });
          });
        } catch (error) {
          console.error('Error while commiting tasks into firebase -> ', error);
          this.setState({ importSuccessful: false, loading: false });
        }
      })
      .catch(error => {
        console.error('Error while importing tasks -> ', error.message);
        this.setState({ importSuccessful: false, loading: false });
      });
  };

  /**
   * Resolves worksite, provides geo coordinates if address exists
   */
  private resolveWorksite = async (
    worksite: WorksiteWithKey,
    exists?: boolean,
  ) => {
    const newWorksite = worksite;

    if (!newWorksite.location || !newWorksite.location.address || exists) {
      return newWorksite;
    }

    Geocode.setApiKey(CONFIG.apiKeys.googleMapKey);
    await Geocode.fromAddress(
      `${newWorksite.location.address} ${newWorksite.location.postalCode} ${newWorksite.location.city}`,
    ).then(
      (response: any) => {
        if (
          response &&
          response.results[0] &&
          response.results[0].geometry &&
          response.results[0].geometry.location
        ) {
          const { lat, lng } = response.results[0].geometry.location;
          const geoLocation = {
            la: lat,
            lo: lng,
            t: new Date().getTime(),
          };
          newWorksite.geoLocation = geoLocation;

          return newWorksite;
        }
        return newWorksite;
      },
      (error: any) => {
        console.error(error);
      },
    );
    return newWorksite;
  };

  /**
   * Checks if member is found
   */
  private resolveVisibleMemberEmail = (task: TaskWithKey) => {
    const { taskSupportData } = this.state;
    const { classes = {}, members } = this.props;

    const foundMemberEmail = taskSupportData.find(
      data => data.taskKey === task.key,
    );
    if (foundMemberEmail) {
      const foundMember = members.find(
        member => member.email === foundMemberEmail.email,
      );
      if (foundMember) {
        return (
          <Typography
            component="span"
            variant="body2"
            className={classes.listText}
            color="primary"
          >
            {foundMemberEmail.email + ' - ' + foundMember.name}
            <Tooltip
              title={i18n().ui.will_be_assigned_to + ' ' + foundMember.name}
            >
              <Done className={classes.done} />
            </Tooltip>
          </Typography>
        );
      }
      return (
        <Typography
          component="span"
          variant="body2"
          className={classes.listText}
          color="primary"
        >
          {foundMemberEmail.email}
          <Tooltip title={i18n().ui.member_not_found_task_will_be_set_as_free}>
            <CloseIcon className={classes.close} />
          </Tooltip>
        </Typography>
      );
    }
    return (
      <Typography
        component="span"
        variant="body2"
        className={classes.listText}
        color="primary"
      >
        {i18n().ui.free_task}
      </Typography>
    );
  };

  private resolveVisibleBusinessId = (task: TaskWithKey) => {
    const { taskSupportData } = this.state;
    const { classes = {}, customers } = this.props;

    const foundTaskSupportData = taskSupportData.find(
      data => data.taskKey === task.key,
    );
    if (foundTaskSupportData) {
      const foundCustomer = customers.find(
        customer => customer.customerNumber === foundTaskSupportData.businessId,
      );
      if (foundCustomer) {
        return (
          <Typography
            component="span"
            variant="body2"
            className={classes.listText}
            color="primary"
          >
            {foundCustomer.name}
            <Tooltip
              title={
                i18n().ui.task_will_be_carried_out_for_customer +
                ' ' +
                foundCustomer.name
              }
            >
              <Done className={classes.done} />
            </Tooltip>
          </Typography>
        );
      }
      if (foundTaskSupportData.businessId) {
        return (
          <Typography
            component="span"
            variant="body2"
            className={classes.listText}
            color="primary"
          >
            {foundTaskSupportData.businessId}
            <Tooltip
              title={
                i18n().ui.business_id_not_found_creating_new +
                ' ' +
                foundTaskSupportData.businessId +
                '. ' +
                i18n().ui.name_can_be_changed_later_from_customers_panel
              }
            >
              <CloseIcon className={classes.close} />
            </Tooltip>
          </Typography>
        );
      }
    }
    return (
      <Typography
        component="span"
        variant="body2"
        className={classes.listText}
        color="primary"
      >
        {i18n().ui.business_id_not_set}
      </Typography>
    );
  };

  /**
   * Executes when file is attached, parses csv data and saves data into state
   */
  private handleUpload = (csvData: any[]) => {
    const { companyId } = this.props;
    const parsedDataArray: ImportTemplate[] = [];

    csvData.forEach((value, index) => {
      if (index !== 0 && value[5] !== '') {
        const unit: ImportTemplate = {
          customerTaskId: value[0],
          weekNumber: value[1],
          date: value[2],
          startTime: value[3],
          endTime: value[4],
          jobType: value[5],
          address: value[6],
          postalIndex: value[7],
          city: value[8],
          businessId: value[9],
          memberEmail: value[10],
          extraInfo: value[11],
        };
        parsedDataArray.push(unit);
      }
    });

    const tasks: TaskWithKey[] = [];
    const taskSupportDataArray: TaskSupportData[] = [];

    parsedDataArray.forEach(parsedData => {
      const ref = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.TASKS)
        .doc();

      const key = ref.id;

      const foundWorksite = this.props.worksites.find(
        worksite =>
          worksite.location.address === parsedData.address &&
          worksite.location.city === parsedData.city,
      );

      let worksiteKey = '';
      if (!foundWorksite) {
        const worksiteRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.WORKSITE)
          .doc();
        worksiteKey = worksiteRef.id;
      }

      const taskSupportData: TaskSupportData = {
        taskKey: key,
        email: parsedData.memberEmail,
        businessId: parsedData.businessId,
        worksiteId: (foundWorksite && foundWorksite.key) || worksiteKey,
        worksiteCustomerId: (foundWorksite && foundWorksite.customer) || '',
      };

      const timeframe: TimeFrame = this.resolveTime(parsedData);

      const newTask: TaskWithKey = {
        key,
        archived: false,
        created: new Date().getTime(),
        start: timeframe.start || 0,
        end: timeframe.end || 0,
        duration:
          timeframe.start && timeframe.end
            ? timeframe.end - timeframe.start
            : 0,
        priority: getPriorityMap()[1],
        status: TaskStatus.UNDONE,
        jobtypeId: '',
        jobtypeName: parsedData.jobType,
        worksite: {
          id: '',
          name: parsedData.address,
          nick: '',
          note: '',
        },
        location: {
          address: parsedData.address,
          postalCode: parsedData.postalIndex,
          city: parsedData.city,
        },
        desc: parsedData.extraInfo,
        date:
          moment(parsedData.startTime, 'HH:mm', true).isValid() ||
          !parsedData.startTime
            ? parsedData.date
            : '',
        weekNumber: parsedData.weekNumber,
        customerTaskId: parsedData.customerTaskId,
        flexible: this.resolveFlexible(parsedData),
      };

      tasks.push(newTask);
      taskSupportDataArray.push(taskSupportData);
    });
    this.setState({
      tasks,
      taskSupportData: taskSupportDataArray,
    });
  };

  /**
   * Resolves timeframe for task after csv parsing
   */
  private resolveTime = (data: ImportTemplate) => {
    const timeFrame: TimeFrame = {
      start: 0,
      end: 0,
    };

    if (data.startTime && data.endTime && data.date) {
      if (moment(data.startTime, 'DD.MM.YYYY HH:mm', true).isValid()) {
        timeFrame.start = moment(data.startTime, 'DD.MM.YYYY HH:mm').valueOf();
        timeFrame.end = moment(data.endTime, 'DD.MM.YYYY HH:mm').valueOf();
      }
      if (moment(data.startTime, 'HH:mm', true).isValid()) {
        timeFrame.start = moment(
          data.date + ' ' + data.startTime,
          'DD.MM.YYYY HH:mm',
        ).valueOf();
        timeFrame.end = moment(
          data.date + ' ' + data.endTime,
          'DD.MM.YYYY HH:mm',
        ).valueOf();
      }

      return timeFrame;
    }
    if (data.startTime && data.endTime) {
      timeFrame.start = moment(data.startTime, 'DD.MM.YYYY HH:mm').valueOf();
      timeFrame.end = moment(data.endTime, 'DD.MM.YYYY HH:mm').valueOf();
      return timeFrame;
    }
    if (data.startTime && data.endTime === '') {
      timeFrame.start = moment(data.startTime).valueOf();
      return timeFrame;
    }
    if (data.date) {
      return timeFrame;
    }
    if (data.weekNumber) {
      return timeFrame;
    }
    return timeFrame;
  };

  /**
   * Resolves flexible status for task
   */
  private resolveFlexible = (parsedData: ImportTemplate) => {
    let value = false;
    if (parsedData.weekNumber && !parsedData.startTime && !parsedData.endTime) {
      value = true;
    }
    if (parsedData.date && !parsedData.startTime && !parsedData.endTime) {
      value = true;
    }
    return value;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ImportTasksDialogProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    importTasksDialogState: state.importTasks.importTasksDialogState,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ImportTasksDialog),
);
