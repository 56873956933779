import {
  IconButton,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import * as React from 'react';
import styles from './styles';

export interface SelectedMembersProps extends StyledComponentProps {
  name: string;
  memberKey: string;
  onClickRemoveMember(memberToRemoveKey: string): void;
}

/**
 * One specified selected member with correct information
 * @param props
 */
export const SelectedMember = (props: SelectedMembersProps) => {
  const { classes = {}, memberKey } = props;

  return (
    <div className={classes.memberPubble}>
      <div data-test="selectedMemberName" className={classes.memberName}>
        {props.name}
      </div>
      <div className={classes.clearIconBackground}>
        <IconButton
          className={classes.IconButton}
          onClick={() => props.onClickRemoveMember(memberKey)}
        >
          <Clear nativeColor="white" />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SelectedMember);
