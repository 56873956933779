import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';

import {
  StyledComponentProps,
  StyleRules,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import {
  CompanyId,
  CustomerWithKey,
  Schema,
  Settings,
  Worksite,
  WorksiteWithKey,
} from '@shared/schema';
import LoadingSpinner from 'components/LoadingSpinner';
import firebaseApp from 'firebaseApp';
import { ApplicationState } from 'reducers';
import { newVenue, updateVenue } from 'reducers/venues/venuesActions';

import {
  Button,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import HeaderBar from 'components/HeaderBar';
import SortingHeadTableCell from 'components/SortingHeadTableCell/SortingHeadTableCell';
import tableLayout from '../../../../theme/styles/table';
import CustomTableCell from '../../../../theme/styles/tableCell';
import Editor from '../components/Editor';

export interface WorksiteProps
  extends Partial<DispatchProp<any>>,
    StyledComponentProps {
  editing?: boolean;
  companyId: CompanyId;
  editedWorksite?: Worksite;
  settings: Settings;
}

interface State {
  isLoading: boolean;
  worksites: Worksite[];
  customers: CustomerWithKey[];
  page: number;
  rowsPerPage: number;
  filter: string;
  order: OrderOptions;
  orderBy: OrderByValues;
}

export enum OrderByValues {
  worksiteName = 'worksiteName',
  worksiteAddress = 'worksiteAddress',
  customerName = 'customerName',
}

export enum OrderOptions {
  asc = 'asc',
  desc = 'desc',
}

export class Worksites extends React.Component<WorksiteProps, State> {
  public state: State = {
    isLoading: true,
    worksites: [],
    customers: [],
    page: 0,
    rowsPerPage: 50,
    filter: '',
    order: OrderOptions.asc,
    orderBy: OrderByValues.worksiteName,
  };
  private unsubscribeVenues: () => void;
  private unsubscribeCustomers: () => void;

  public componentDidMount() {
    this.unsubscribeVenues = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.WORKSITE)
      .orderBy('name')
      .onSnapshot(snapshot => {
        const worksites: Worksite[] = [];

        snapshot.forEach(doc => {
          const data = doc.data() as WorksiteWithKey;
          data.key = doc.id;
          worksites.push(data);
        });
        this.setState({
          isLoading: false,
          worksites,
        });
      });
    this.unsubscribeCustomers = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.CUSTOMERS)
      .orderBy('name')
      .onSnapshot(snapshot => {
        const customers: CustomerWithKey[] = [];
        snapshot.forEach(doc => {
          const data = doc.data() as CustomerWithKey;
          data.key = doc.id;
          customers.push(data);
        });
        this.setState({
          isLoading: false,
          customers,
        });
      });
  }

  public componentWillUnmount() {
    this.unsubscribeVenues && this.unsubscribeVenues();
    this.unsubscribeCustomers && this.unsubscribeCustomers();
  }

  public render() {
    const { settings, classes = {} } = this.props;
    const { worksites, filter, page, rowsPerPage } = this.state;

    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <HeaderBar
          searchOnChangeHandler={this.searchOnChangeHandler}
          searchComponentPlaceholder={i18n().ui.search_worksites}
        >
          <Button
            className={classes.addNewButton}
            onClick={this.handleFabButtonClick}
          >
            {i18n().ui.add_new}
          </Button>
        </HeaderBar>
        <div className={classes.container}>
          <div className={classes.tableBody}>
            <Table>
              <TableHead>
                <TableRow classes={{ root: classes.tableHead }}>
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellWorksiteName"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.worksiteName}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.name}
                  />
                  {settings.general.enableWorksiteNick && (
                    <CustomTableCell className={classes.tableCellHeader}>
                      {i18n().ui.nick_name}
                    </CustomTableCell>
                  )}
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellWorksiteAddress"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.worksiteAddress}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.address}
                  />
                  <CustomTableCell className={classes.tableCellHeader}>
                    {i18n().ui.phone}
                  </CustomTableCell>
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellCustomerName"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.customerName}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.customer}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {worksites &&
                  worksites
                    .filter(
                      item =>
                        item.name.toLowerCase().indexOf(filter.toLowerCase()) >
                        -1,
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        className={classes.row}
                        key={(row as WorksiteWithKey).key}
                        hover={true}
                        onClick={this.rowOnClickHandler(row)}
                      >
                        <CustomTableCell className={classes.tableCell}>
                          {row.name}
                        </CustomTableCell>
                        {settings.general.enableWorksiteNick && (
                          <CustomTableCell className={classes.tableCell}>
                            {row.nick}
                          </CustomTableCell>
                        )}
                        <CustomTableCell className={classes.tableCell}>
                          {row.location.address}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {row.phone}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {this.getCustomerName(
                            row.customer ? row.customer : '',
                          )}
                        </CustomTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>

          <div className={classes.tableFooter}>
            <TablePagination
              classes={{
                toolbar: classes.tablePagination,
                select: classes.paginationSelect,
              }}
              component="div"
              count={worksites.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage={i18n().ui.rows_per_page}
            />
          </div>

          <Editor
            editing={this.props.editing}
            editedWorksite={this.props.editedWorksite as WorksiteWithKey}
            companyId={this.props.companyId}
          />
        </div>
      </>
    );
  }

  private getCustomerName = (customerId: string) => {
    const correctCustomer = this.state.customers.find(
      customer => customer.key === customerId,
    );
    return correctCustomer ? correctCustomer.name : '';
  };

  private searchOnChangeHandler = (filter: string) => {
    this.setState({ filter });
  };

  private handleFabButtonClick = () => {
    const { companyId, dispatch } = this.props;
    dispatch && dispatch(newVenue(companyId));
  };

  private rowOnClickHandler = (worksite: Worksite) => () => {
    const { companyId, dispatch } = this.props;
    dispatch && dispatch(updateVenue(companyId, worksite));
  };

  private handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    this.setState({ page });
  };

  private handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({ rowsPerPage: Number(e.target.value) });
  };

  /**
   * Sort the worksite list according to selected value and order, then setState {worksites: Worksite[]}
   *
   * @param {TaskWithKey[]} tasks
   */
  private sortWorksiteList = () => {
    let worksiteSortedList = [...this.state.worksites];
    const { order, orderBy } = this.state;
    switch (orderBy) {
      case OrderByValues.worksiteName: {
        worksiteSortedList = this.workisteNameSort(worksiteSortedList);
        break;
      }
      case OrderByValues.worksiteAddress: {
        worksiteSortedList = this.worksiteAddressSort(worksiteSortedList);
        break;
      }
      case OrderByValues.customerName: {
        worksiteSortedList = this.worksiteCustomerSort(worksiteSortedList);
        break;
      }
      default:
        return;
    }
    if (order === OrderOptions.desc) {
      worksiteSortedList.reverse();
    }
    this.setState({
      worksites: worksiteSortedList,
    });
  };

  /** Handle table order change
   * Invoked everytime when user click to orderable table title cell.
   * If the same cell cliked, then just change the "order", if another cell clicked, then set the "order" to default 'asc' and set new "orderBy" value
   * At the end, calls sortWorksiteList()
   *
   * @param {OrderByValues} orderBy
   */
  private handleTableSortChange = (orderBy: OrderByValues) => {
    if (this.state.orderBy === orderBy) {
      this.setState(
        {
          order: this.getOppositeOrderOption(),
        },
        () => {
          this.sortWorksiteList();
        },
      );
    } else {
      this.setState(
        {
          order: OrderOptions.asc,
          orderBy,
        },
        () => {
          this.sortWorksiteList();
        },
      );
    }
  };

  /**
   * Returns the opposite value of "order" variable from state
   */
  private getOppositeOrderOption = () => {
    const order = this.state.order;
    if (order === OrderOptions.asc) {
      return OrderOptions.desc;
    } else {
      return OrderOptions.asc;
    }
  };

  /**
   * Sort provided worksite array by worksite name
   *
   * @param {Worksite[]} worksites
   */
  private workisteNameSort = (worksites: Worksite[]) => {
    worksites.sort((worksite1, worksite2) =>
      this.worksiteDeafultSort(worksite1, worksite2),
    );
    return worksites;
  };

  /**
   * Sort provided worksite array by worksite address
   *
   * @param {Worksite[]} worksites
   */
  private worksiteAddressSort = (worksites: Worksite[]) => {
    worksites.sort((worksite1, worksite2) => {
      const worksite1Name = worksite1.location.address.trim();
      const worksite2Name = worksite2.location.address.trim();
      const result = worksite1Name.localeCompare(worksite2Name);
      return result === 0
        ? this.worksiteDeafultSort(worksite1, worksite2)
        : result;
    });
    return worksites;
  };

  /**
   * Sort provided worksite array by customer name
   *
   * @param {Worksite[]} worksites
   */
  private worksiteCustomerSort = (worksites: Worksite[]) => {
    worksites.sort((worksite1, worksite2) => {
      let worksite1Customer = '';
      let worksite2Customer = '';
      if (worksite1.customer) {
        worksite1Customer = this.getCustomerName(worksite1.customer).trim();
      }
      if (worksite2.customer) {
        worksite2Customer = this.getCustomerName(worksite2.customer).trim();
      }
      const result = this.possibleEmptylValuesSort(
        worksite1Customer,
        worksite2Customer,
      );
      return result === 0
        ? this.worksiteDeafultSort(worksite1, worksite2)
        : result;
    });
    return worksites;
  };

  /**
   * Function used by sorting methods to place data with empty records to the bottom in 'asc' order
   *
   * @param {string} value1
   * @param {string} value2
   */
  private possibleEmptylValuesSort = (value1: string, value2: string) => {
    let result = value1.localeCompare(value2);
    if (!value1 && value2) {
      result = 1;
    } else if (value1 && !value2) {
      result = -1;
    }
    return result;
  };

  /**
   * Default sorting method that sort two worsites according to their names
   *
   * @param {Worksite} worksite1
   * @param {Worksite} worksite2
   */
  private worksiteDeafultSort = (workiste1: Worksite, workiste2: Worksite) => {
    const workite1Name = workiste1.name.trim();
    const workite2Name = workiste2.name.trim();
    return workite1Name.localeCompare(workite2Name);
  };
}

const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    margin: '20px',
    marginTop: '35px',
    height: '100%',
  },
  addNewButton: {
    marginLeft: '10px',
    height: '65%',
    margin: 'auto 0px',
    backgroundColor: '#99ca3c',
    minWidth: '120px',
    border: '1px solid',
    borderColor: 'rgba(0,0,255,0.25)',
    textTransform: 'none',
  },
  sortLabel: {
    color: 'white',
    '&:hover': { color: 'white' },
    '&:focus': { color: 'white' },
    '& svg': {
      color: 'white',
      opacity: 0.4,
    },
  },
  sortableCell: {
    cursor: 'pointer',
  },
  ...tableLayout(theme),
});

const mapStateToProps = (state: ApplicationState, ownProps: WorksiteProps) => {
  return {
    ...ownProps,
    editing: state.venue.editing,
    editedWorksite: state.venue.editedVenue,
    settings: state.company.activeCompany.settings,
  };
};

export default connect<WorksiteProps>(mapStateToProps)(
  withRouter<any>(withStyles(styles, { withTheme: true })(Worksites)),
);
