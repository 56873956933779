import { CompanyId, Member, MemberWithKey } from '@shared/schema';
import { ActionCreator, AnyAction } from 'redux';

export const enum ResourcesActionType {
  RESOURCES_NEW = 'RESOURCES_NEW',
  RESOURCES_EDIT = 'RESOURCES_EDIT',
  RESOURCES_CANCEL = 'RESOURCES_CANCEL_EDIT',
  // RESOURCES_CREATE = "RESOURCES_CREATE",
  RESOURCES_UPDATE = 'RESOURCES_UPDATE',
}

export interface NewResourceAction extends AnyAction {
  type: ResourcesActionType.RESOURCES_NEW;
  payload: {
    companyId: CompanyId;
    resource: Member;
    allMembers: MemberWithKey[];
  };
}

// export interface CreateResourceAction extends AnyAction {
//   type: ResourcesActionType.RESOURCES_CREATE;
//   payload: {
//     companyId: CompanyId;
//     resource: Resource;
//   };
// }

export interface ResourcesUpdate extends AnyAction {
  type: ResourcesActionType.RESOURCES_UPDATE;
  payload: {
    companyId: CompanyId;
    resource: Member;
  };
}

export interface EditResourceAction extends AnyAction {
  type: ResourcesActionType.RESOURCES_EDIT;
  payload: {
    companyId: CompanyId;
    resource: Member;
    allMembers: MemberWithKey[];
  };
}

export interface CancelResourceEditAction extends AnyAction {
  type: ResourcesActionType.RESOURCES_CANCEL;
}

export const updateResource: ActionCreator<ResourcesUpdate> = (
  companyId: CompanyId,
  resource: Member,
) => {
  return {
    type: ResourcesActionType.RESOURCES_UPDATE,
    payload: {
      companyId,
      resource,
    },
  };
};

export const newResource: ActionCreator<NewResourceAction> = (
  companyId: CompanyId,
  resource: Member,
  allMembers: MemberWithKey[],
) => {
  return {
    type: ResourcesActionType.RESOURCES_NEW,
    payload: {
      companyId,
      resource,
      allMembers,
    },
  };
};

export const editResource: ActionCreator<EditResourceAction> = (
  companyId: CompanyId,
  resource: Member,
  allMembers: MemberWithKey[],
) => {
  return {
    type: ResourcesActionType.RESOURCES_EDIT,
    payload: {
      companyId,
      resource,
      allMembers,
    },
  };
};

export const cancelEdit: ActionCreator<CancelResourceEditAction> = () => {
  return {
    type: ResourcesActionType.RESOURCES_CANCEL,
  };
};

export type ResourcesAction =
  | AnyAction
  | NewResourceAction
  | CancelResourceEditAction
  | EditResourceAction
  | ResourcesUpdate;
