import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 'auto',
    width: 'fit-content',
  },
  paper: {
    minWidth: '600px',
    height: '95%',
    maxHeight: '95%',
    overflowY: 'auto',
  },
  title: {
    position: 'relative',
  },
  titleButton: {
    position: 'absolute',
    right: '10px',
    color: '#fff',
    background: '#99CA3C',
    borderRadius: '100px',
    minWidth: '36px',
    padding: '10px',
  },
  saveButton: {
    color: '#34AD00',
    '&:hover': {
      backgroundColor: 'rgba(153, 202, 60, 0.33)',
    },
  },
  deleteButton: {
    position: 'absolute',
    left: '0',
  },
  dialogContainer: {
    maxHeight: '75vh',
  },
  field: {
    minWidth: '300px',
    minHeight: '40px',
  },
  editorColumn: {
    display: 'table-cell',
    width: '100%',
  },
  chatMessageContainer: {
    borderLeft: '0.5px solid #323232',
  },
  chatMessages: {
    maxHeight: '95%',
    height: '85%',
    overflowY: 'auto',
  },
  chatInput: {
    width: '80%',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflow: 'auto',
    maxHeight: '72px',
  },
  chatButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '110px',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '5px',
  },

  sendButton: {
    color: 'white',
    backgroundColor: '#99CA3C',
    borderRadius: '50px',
    height: '64px',
    marginLeft: '1%',

    '&:hover': {
      backgroundColor: '#7ba330',
    },
    '&:disabled': {
      backgroundColor: theme.colors.grey,
    },
  },
  dialogFooter: {
    backgroundColor: '#f2f2f2',
    margin: 0,
    padding: '8px 4px',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  formControlDate: {
    margin: theme.spacing.unit,
    width: '184px',
  },
  formControlSmall: {
    margin: theme.spacing.unit,
    width: '164px',
  },
  uploadFormControl: {
    border: '1px solid #d6d6d6',
    width: '100%',
  },
  listText: {
    position: 'absolute',
    top: 0,
    // marginLeft: '16px'
  },
  listItemSmallText: {
    fontSize: '12px',
  },
  listTextWithIcon: {
    position: 'absolute',
    top: 0,
    marginLeft: '16px',
  },
  clearButton: {
    minWidth: 5,
    padding: 0,
  },
  active: {
    // backgroundColor: 'rgba(255, 192, 30, 0.5)',
    color: 'white',
  },

  done: {
    // backgroundColor: 'green',
    color: 'white',
  },

  undone: {
    // backgroundColor: 'lightgray',
    color: 'white',
  },

  late: {
    backgroundColor: 'red',
    color: 'white',
  },
  displayNone: {
    display: 'none',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  dialogContent: {
    width: 600,
    minHeight: 500,
    padding: '0 24px 12px 24px',
  },
  mapContent: {
    width: 600,
    minHeight: 500,
    padding: 0,
  },
  iconContainer: {
    color: theme.mapMarkerThemes.default.color,
    position: 'absolute',
  },
  worksiteIcon: {
    width: '40px',
    height: '40px',
    transform: 'translate(-50%, -100%)',
  },
  tabs: {
    backgroundColor: '#f2f2f2',
    width: '100%',
    display: 'flex',
    flex: 1,
    maxHeight: '48px',
  },
  tab: {
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
  },
  chatIcon: {
    width: '10%',
    height: '10%',
    verticalAlign: 'middle',
  },
  iconAligment: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabRoot: {
    display: 'flex',
    flex: 1,
  },
  formControllLabel: {
    '& span': {
      fontSize: '15px',
    },
  },
});

export default styles;
