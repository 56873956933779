import { CircularProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'reducers';

interface ProgressLoaderProps {
  currentProgress: number;
  totalProgress: number;
  progressMessage: string;
}

class ProgressLoader extends React.Component<ProgressLoaderProps> {
  public render() {
    const { currentProgress, totalProgress, progressMessage } = this.props;

    const percentage = Math.round((currentProgress / totalProgress) * 100);

    if (totalProgress > 0) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0 20px',
          }}
        >
          {/* <Typography>{`${currentProgress}/${totalProgress}`}</Typography> */}
          <Typography
            style={{
              margin: '0 20px',
            }}
          >{`${percentage}%`}</Typography>
          <Typography style={{ paddingRight: 20 }}>
            {percentage === 100 ? 'Success' : progressMessage}
          </Typography>

          {percentage < 100 && <CircularProgress />}
        </div>
      );
    }

    return <></>;
  }
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ProgressLoaderProps>,
) => {
  return {
    ...ownProps,
    totalProgress: state.shiftEditor.totalProgress,
    currentProgress: state.shiftEditor.currentProgress,
    progressMessage: state.shiftEditor.progressMessage,
  } as ProgressLoaderProps;
};
export default connect(mapStateToProps)(ProgressLoader);
