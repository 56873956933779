import { StyledComponentProps, withStyles } from '@material-ui/core';
import { i18n } from '@shared/locale';
import { MemberWithKey } from '@shared/schema';
import * as React from 'react';
import SelectedMember from './comonents/SelectedMember';
import styles from './styles';

export interface MemberListProps extends StyledComponentProps {
  selectedMembers: MemberWithKey[];
  onClickRemoveMember(memberToRemoveKey: string): void;
}

/**
 * List of members
 * Gets and shows selected members from props
 * @param props
 */
export const MemberList = (props: MemberListProps) => {
  const { classes = {}, onClickRemoveMember } = props;

  return (
    <div className={classes.bar}>
      <div className={classes.headerText}>{i18n().ui.selected_members}:</div>
      <div className={classes.memberList} data-test="memberList">
        {props.selectedMembers.map(
          m =>
            m.key && (
              <SelectedMember
                key={m.key}
                name={m.name}
                memberKey={m.key}
                onClickRemoveMember={onClickRemoveMember}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(MemberList);
