import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Chat } from '@material-ui/icons';
import * as React from 'react';

import { ChatIconState } from '@shared/schema';
import muiTheme from 'theme';

export interface ChatStatusIconProps extends SvgIconProps {
  state: ChatIconState;
  /**
   * By default icon is not rendered if state is UNDEFINED. Set this to true
   * to make icon transparent instead. Useful when you want the icon to not
   * change layout when UNDEFINED and instead keep occupying it's original space.
   */
  renderUndefined?: boolean;
  /**
   * Show READ state instead.
   */
  disableUndefined?: boolean;
}

/**
 * General purpose chat status icon showing if there are unread messages.
 */
const ChatStatusIcon = (props: ChatStatusIconProps) => {
  const { state, renderUndefined = false, disableUndefined = false } = props;

  if (
    !disableUndefined &&
    state === ChatIconState.UNDEFINED &&
    !renderUndefined
  ) {
    return null;
  }

  const color =
    state === ChatIconState.UNREAD
      ? muiTheme.palette.secondary.main
      : state === ChatIconState.READ || disableUndefined
      ? muiTheme.colors.darkGrey
      : muiTheme.colors.transparent;

  const iconProps = {
    ...props,
    style: {
      ...props.style,
      color,
    },
  };

  delete iconProps.state;
  delete iconProps.renderUndefined;
  delete iconProps.disableUndefined;

  return <Chat {...iconProps} />;
};

export default ChatStatusIcon;
