import { assign, difference, each, intersection, keys, omit } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import * as vis from 'vis'; /// dist/vis-timeline-graph2d.min';
import 'vis/dist/vis-timeline-graph2d.min.css';

// const noop = function() {};
const events = [
  'currentTimeTick',
  'click',
  'contextmenu',
  'doubleClick',
  'groupDragged',
  'changed',
  'rangechange',
  'rangechanged',
  'select',
  'timechange',
  'timechanged',
];

const callbacks = [
  /**
   * Fired when a new item is about to be added. If not implemented, the item will be added with default text contents.
   */
  'onAdd',
  /**
   * Fired when an item is about to be updated. This function typically has to show a dialog where the user change the item. If not implemented, nothing happens.
   */
  'onUpdate',
  /**
   *  Fired when an object is dropped in to an existing timeline item.
   */
  'onDropObjectOnItem',
  /**
   * Fired when an item has been moved. If not implemented, the move action will be accepted.
   */
  'onMove',
  /**
   *  Fired repeatedly while an item is being moved (dragged). Can be used to adjust the items start, end, and/or group to allowed regions.
   */
  'onMoving',
  /**
   * Fired when an item is about to be deleted. If not implemented, the item will be always removed.
   */
  'onRemove',
];

const eventDefaultProps = {};

export interface CustomTime {
  id: string;
  datetime: Date;
}

export interface TimelineProps<I, G> {
  items: vis.DataSet<I>;
  groups: G[];
  options?: vis.TimelineOptions;
  selection: any[];
  customTimes?: {
    [id: string]: CustomTime;
  };
  animate?: boolean | object;
  currentTime?: string | Date | number;
  selectionOptions?: string | Date | number;
  className?: string;
  start?: Date;
  end?: Date;

  currentTimeTickHandler?: (event: any) => void;
  clickHandler?: (event: any) => void;
  contextmenuHandler?: (event: any) => void;
  doubleClickHandler?: (event: any) => void;
  groupDraggedHandler?: (event: any) => void;
  changedHandler?: (event: any) => void;
  rangechangeHandler?: (event: any) => void;
  rangechangedHandler?: (event: any) => void;
  selectHandler?: (event: any) => void;
  timechangeHandler?: (event: any) => void;
  timechangedHandler?: (event: any) => void;
}

interface State {
  customTimes: CustomTime[];
}

export default class Timeline<I, G> extends React.Component<
  TimelineProps<I, G>,
  State
> {
  public static defaultProps = assign(
    {
      items: [],
      groups: [],
      options: {},
      selection: [],
      customTimes: {},
    },
    eventDefaultProps,
  );

  public state: State = {
    // NOTE we store custom times on the state to enable us to diff with new
    // custom times and add or remove the elements with visjs
    customTimes: [],
  };

  private container: React.RefObject<any>;
  private $timelineElement: vis.Timeline | null;

  constructor(props: TimelineProps<I, G>) {
    super(props);
    this.container = React.createRef();
  }

  public componentWillUnmount() {
    if (this.$timelineElement) {
      try {
        this.$timelineElement.destroy();
        this.$timelineElement = null;
      } catch (error) {
        console.error(error);
      }
    }
  }

  public componentDidMount() {
    /*
     * TODO implement server side rendering support in here
     */

    this.$timelineElement = new vis.Timeline(
      this.container.current,
      [],
      this.props.options,
    );

    events.forEach(event => {
      if (this.$timelineElement && this.props[`${event}Handler`]) {
        this.$timelineElement.on(event, this.props[`${event}Handler`]);
      }
    });
    this.init();
  }

  public componentDidUpdate() {
    this.init();
  }

  /***
   * Function zooms timeline window by button.
   * it needs amount as parameter and it must be between 0-1.
   * NOTICE! amount you give is PERCENTAGE NOTICE!
   *
   * @param {number} zoomPercentage
   */
  public zoomIn(zoomPercentage: number) {
    if (this.$timelineElement) {
      this.zoomInCalculation(zoomPercentage);
      this.updateWindowRange();
    }
  }

  /***
   * Function zooms timeline window by button.
   * it needs amount as parameter and it must be between 0-1.
   * NOTICE! amount you give is PERCENTAGE NOTICE!
   *
   * @param {number} zoomPercentage
   */
  public zoomOut(zoomPercentage: number) {
    if (this.$timelineElement) {
      this.zoomOutCalculation(zoomPercentage);
      this.updateWindowRange();
    }
  }

  /**
   * Returns array of visible item id's
   * @return {vis.IdType} - array of id's
   */
  public getVisibleItems(): vis.IdType[] | undefined {
    if (this.$timelineElement) {
      return this.$timelineElement.getVisibleItems();
    }
    return undefined;
  }

  public shouldComponentUpdate(nextProps: TimelineProps<I, G>) {
    const {
      items,
      groups,
      options,
      selection,
      customTimes,
      start,
      end,
    } = this.props;

    const itemsChange = items !== nextProps.items;
    const groupsChange = groups !== nextProps.groups;
    const optionsChange = options !== nextProps.options;
    const customTimesChange = customTimes !== nextProps.customTimes;
    const selectionChange = selection !== nextProps.selection;
    const startChanged = start !== nextProps.start;
    const endChanged = end !== nextProps.end;

    return (
      itemsChange ||
      groupsChange ||
      optionsChange ||
      customTimesChange ||
      selectionChange ||
      startChanged ||
      endChanged
    );
  }

  public init() {
    const {
      items,
      groups,
      options,
      selection,
      selectionOptions = {} as any,
      customTimes = [],
      animate = true,
      currentTime,
      // start,
      // end,
    } = this.props;

    let timelineOptions = options;

    callbacks.forEach(callback => {
      const event = this.props[callback];
      if (event && timelineOptions) {
        timelineOptions[callback] = event;
      }
    });

    if (animate && options) {
      // If animate option is set, we should animate the timeline to any new
      // start/end values instead of jumping straight to them
      timelineOptions = omit(options, 'start', 'end');

      /*
       * TODO setWindow method does not exist in the latest vis package. We need to see if need this at all
       */
      // this.$timelineElement &&
      //   options.start &&
      //   options.end &&
      // this.$timelineElement.setWindow(options.start, options.end, {
      //   animation: animate as boolean,
      // });
    }

    try {
      this.$timelineElement &&
        timelineOptions &&
        this.$timelineElement.setOptions(timelineOptions);
    } catch (error) {
      console.error(error);
    }

    const groupsDataset = new vis.DataSet();
    groupsDataset.add(groups);
    this.$timelineElement && this.$timelineElement.setGroups(groupsDataset);

    // if (this.props.groupTemplate) {
    //   this.$el.groupTemplate = this.props.groupTemplate;
    // }
    if (this.$timelineElement) {
      this.$timelineElement.setItems(items);
      this.$timelineElement.setSelection(selection, selectionOptions);

      if (currentTime) {
        this.$timelineElement.setCurrentTime(currentTime);
      }
    }

    // diff the custom times to decipher new, removing, updating
    const customTimeKeysPrev = keys(this.state.customTimes);
    const customTimeKeysNew = keys(customTimes);
    const customTimeKeysToAdd = difference(
      customTimeKeysNew,
      customTimeKeysPrev,
    );
    const customTimeKeysToRemove = difference(
      customTimeKeysPrev,
      customTimeKeysNew,
    );
    const customTimeKeysToUpdate = intersection(
      customTimeKeysPrev,
      customTimeKeysNew,
    );

    // NOTE this has to be in arrow function so context of `this` is based on
    // this.$el and not `each`
    each(
      customTimeKeysToRemove,
      id => this.$timelineElement && this.$timelineElement.removeCustomTime(id),
    );
    each(customTimeKeysToAdd, id => {
      const datetime = customTimes[id];
      this.$timelineElement &&
        this.$timelineElement.addCustomTime(datetime, id);
    });
    each(customTimeKeysToUpdate, id => {
      const datetime = customTimes[id];
      this.$timelineElement &&
        this.$timelineElement.setCustomTime(datetime, id);
    });

    /**
     * This is a hack to cope with some mysterious state that causes timeline to be hidden in when the screen opens
     */
  }

  public render() {
    return <div ref={this.container} className={this.props.className} />;
  }

  public updateCurrentTime() {
    if (this.$timelineElement) {
      this.$timelineElement.setCurrentTime(new Date());
    }
  }

  public setWindow(
    start: Date,
    end: Date,
    options?: vis.TimelineAnimationOptions,
    callback?: () => void,
  ) {
    if (this.$timelineElement) {
      this.$timelineElement.setWindow(start, end, options, callback);
    }
  }

  /***
   * Function will update timeline window range to redux when zoomIn or zoomOut function is called.
   */
  public updateWindowRange() {
    if (this.$timelineElement && this.props.rangechangedHandler) {
      const currentWindow = this.$timelineElement.getWindow();
      this.props.rangechangedHandler({ ...currentWindow, byUser: true });
    }
  }

  /***
   * Does same functionality as vis.js zoomIn function
   */
  public zoomInCalculation(percentage: number) {
    if (
      this.$timelineElement &&
      this.props.options &&
      this.props.options.hiddenDates
    ) {
      const range = this.$timelineElement.getWindow();
      const start = range.start.valueOf();
      const end = range.end.valueOf();
      const interval = end - start;
      const newInterval = interval / (1 + percentage);
      const distance = (interval - newInterval) / 2;
      const newStart = start + distance;
      const newEnd = end - distance;

      this.$timelineElement.setWindow(newStart, newEnd, { animation: false });
    }
  }

  /***
   * This function makes same thing as the vis.js, with one exception.
   * When user zooms to that point when start and end is in the same day function will check if hiddendates are exesting and then,
   * if the hiddendates start and end hours are same then it will add more to zoom percentage.
   */
  public zoomOutCalculation(percentage: number) {
    if (this.$timelineElement) {
      const range = this.$timelineElement.getWindow();
      const start = range.start.valueOf();
      const end = range.end.valueOf();
      const interval = end - start;
      let newStart = start - (interval * percentage) / 2;
      let newEnd =
        end +
        (interval * percentage) / 2 +
        moment.duration(1, 'minute').asMilliseconds();
      const momentOldStart = moment(start);
      const momentOldEnd = moment(end).add(1, 'hour');
      if (this.props.options && this.props.options.hiddenDates) {
        const hiddenStart = moment(this.props.options.hiddenDates[0].start);
        const hiddenEnd = moment(this.props.options.hiddenDates[0].end);
        if (
          momentOldStart.hours() === hiddenEnd.hours() &&
          (momentOldEnd.hours() ===
            (hiddenStart.hours() === 0 ? 0 : hiddenStart.hours()) ||
            momentOldEnd.hours() - 1 ===
              (hiddenStart.hours() === 0 ? 0 : hiddenStart.hours()))
        ) {
          newStart = moment(start).subtract(1, 'day').valueOf();
          newEnd = moment(end).add(moment.duration(1, 'day')).valueOf();
        }
      }

      this.$timelineElement.setWindow(newStart, newEnd, { animation: false });
    }
  }
}
