import { Button, Typography, WithTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as vis from 'vis';

import { i18n } from '@shared/locale';
import {
  ChatIconState,
  CompanyId,
  JobtypeSet,
  MemberSet,
  Schema,
  Settings,
  Shift,
  ShiftTemplateWithKey,
  ShiftWithKey,
  Task,
  TaskWithKey,
  User,
  UserId,
} from '@shared/schema';
import TimelineComponent from 'components/Timeline';
import firebaseApp from 'firebaseApp';
import { ApplicationState } from 'reducers';
import {
  editResourceEvent,
  newResourceEvent,
  setChatIconState,
} from 'reducers/events/eventActions';
import { EventState } from 'reducers/events/eventReducer';
import {
  setRange,
  updateStartEndHours,
} from 'reducers/timeline/timelineActions';
import { QualificationSet } from 'reducers/timeline/timelineReducer';
import { getTasksTableRef } from 'utils/tasksUtil';
import { CompanyParams } from '../../../MainLayout';
import { renderTaskContent } from './renderTaskContent';
import { styles } from './styles';
import { getUpdatedOption, zoomMin } from './timelineOptions';

export interface TimelineItem {
  id: string;
  content: string;
  data: Task;
  group: UserId | undefined;
  start: Date;
  end: Date;
  companyId: CompanyId | undefined;

  /**
   * Title for timeline item
   */
  taskTitle: string | undefined;
  /**
   * @deprecated
   * taskTitle will be used instead
   */
  displayWorksite: boolean;
  /**
   * @deprecated
   * taskTitle will be used instead
   */
  enableWorksiteNick: boolean;
  theme: Theme;
  title: string;
  settings: Settings;
  chatIconState: ChatIconState;
  type?: 'box' | 'point' | 'range' | 'background' | undefined;
}

export interface TimelineComponentProps
  extends RouteComponentProps<CompanyParams>,
    DispatchProp<any>,
    StyledComponentProps,
    WithTheme {
  currentDate: moment.Moment;
  end: moment.Moment;
  editorOpen: boolean;
  groups: any[];
  filter?: string;
  hiddenMembers: MemberSet;
  hiddenJobtypes: JobtypeSet;
  hiddenQualifications: QualificationSet;
  companyId: CompanyId | undefined;
  deleteDialogOpen: boolean;
  deleteEventId: any;
  deleteEventTitle: string;
  settings: Settings;
  eventEditor: EventState;
  user?: User;
}

interface State {
  error: null;
  isLoading: boolean;
  items: vis.DataSet<vis.TimelineItem>;
  fetch: {
    start: moment.Moment | undefined;
    end: moment.Moment | undefined;
  };
  originalTasks: Task[];
  allTasks: TaskWithKey[];
  originalShifts: ShiftWithKey[];
  shiftTemplates: ShiftTemplateWithKey[];
  reloadShifts: boolean;
}

interface LastMessageSubscription {
  lastMsgKey: string | undefined;
  unsubscribe: () => void;
}

interface LastMessageSubscriptionMap {
  [key: string]: LastMessageSubscription;
}

interface ShiftBackground extends Shift {
  id: string;
  start: number;
  end: number;
  name: string;
  userId: string;
}

export class Timeline extends React.Component<TimelineComponentProps, State> {
  private options = getUpdatedOption();

  private unsubscribeTasks: () => void;
  private unsubscribeShifts: () => void;
  private unsubscribeShiftTemplates: () => void;
  private chatSubscriptions: LastMessageSubscriptionMap = {};

  private timelineComponent: React.RefObject<
    TimelineComponent<vis.TimelineItem, any>
  >;

  constructor(props: TimelineComponentProps) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      items: new vis.DataSet(),
      fetch: {
        start: undefined,
        end: undefined,
      },
      allTasks: [],
      originalTasks: [],
      originalShifts: [],
      shiftTemplates: [],
      reloadShifts: false,
    };

    this.timelineComponent = React.createRef();
  }

  public componentDidMount() {
    this.validateEventRange(this.props.currentDate, this.props.end);

    this.unsubscribeShifts = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.SHIFTS)
      .onSnapshot(snapshot => {
        const shifts: ShiftWithKey[] = [];
        snapshot.forEach(data => {
          const shift = data.data() as ShiftWithKey;
          shift.key = data.id;
          shifts.push({
            ...shift,
          });
        });
        this.setState({ originalShifts: shifts });
      });

    this.unsubscribeShiftTemplates = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId)
      .collection(Schema.SHIFTTEMPLATES)
      .onSnapshot(snapshot => {
        const templates: ShiftTemplateWithKey[] = [];
        snapshot.forEach(data => {
          const shift = data.data() as ShiftTemplateWithKey;
          shift.key = data.id;
          templates.push({
            ...shift,
          });
        });
        this.setState({ shiftTemplates: templates });
      });

    // TODO FOR ME: Import this value from timelineOptions, coz it's universal
    const minInterval = zoomMin;

    if (this.timelineComponent.current) {
      const timeline = this.timelineComponent.current;
      if (timeline && this.options.hiddenDates) {
        const hiddenStart = moment(this.options.hiddenDates[0].start); // f.e. 22:00
        const hiddenEnd = moment(this.options.hiddenDates[0].end); // f.e. 06:00

        // Saves valid timeframe to Redux
        this.props.dispatch(
          updateStartEndHours(
            moment.duration(hiddenEnd.hours(), 'hours').asMilliseconds(),
            moment.duration(hiddenStart.hours(), 'hours').asMilliseconds(),
          ),
        );

        // start-end of permitted time
        const start = moment().set({
          hour: hiddenEnd.hours(),
          minute: hiddenEnd.minutes(),
          second: 0,
        });
        const end = moment().set({
          hour: hiddenStart.hours() || 24,
          minute: 0,
          second: 0,
        });

        // variable for holding values that will be used for timeline
        let newStart = moment();
        let newEnd = moment();

        // total hrs for interval and start-end
        const minIntervalHours = moment.duration(minInterval).hours();
        const startEndHours = Math.abs(
          moment.duration(end.diff(start)).hours() === 0
            ? 24
            : moment.duration(end.diff(start)).hours(),
        );

        // hours diff, to see for how many times interval between start-end available time in single day smaller than minimum interval
        const hoursDiff = minIntervalHours / startEndHours;
        // round hours diff
        const roundedHoursDiff = Math.ceil(hoursDiff);

        // Checks that stored dates in Redux are valid for displaying, if it is not, then fall to rest of the code
        if (
          this.props.currentDate.hour() >= start.hour() &&
          (this.props.end.hour() || 24) <= (end.hour() || 24)
        ) {
          return;
        }

        // at first check if difference is bigger than 1, if it's bigger than 1, then we can't place start-end frame on a single day
        if (roundedHoursDiff > 1) {
          if (roundedHoursDiff % 2 === 0) {
            newStart = start
              .subtract((roundedHoursDiff - 2) / 2 + 1, 'day')
              .set(
                startEndHours !== 1
                  ? { hours: start.hours() + Math.ceil(startEndHours / 2) }
                  : { hours: start.hours(), minutes: 30 },
              );
            newEnd = end
              .add((roundedHoursDiff - 2) / 2 + 1, 'day')
              .set(
                startEndHours !== 1
                  ? { hours: end.hours() - Math.ceil(startEndHours / 2) }
                  : { hours: start.hours(), minutes: 30 },
              );
          } else if (roundedHoursDiff % 2 !== 0) {
            newStart = start.subtract(roundedHoursDiff - 2, 'days');
            newEnd = end.add(roundedHoursDiff - 2, 'days');
          }

          if (
            moment(this.options.hiddenDates[0].start).hours() === 0 &&
            startEndHours === 1
          ) {
            newEnd.subtract(1, 'day');
          }
        } else {
          // if diference is 1, then we can show the full day
          newStart = start;
          newEnd = end;
        }

        this.props.dispatch(
          updateStartEndHours(
            moment.duration(hiddenEnd.hours(), 'hours').asMilliseconds(),
            moment.duration(hiddenStart.hours(), 'hours').asMilliseconds(),
          ),
        );
        this.props.dispatch(setRange(newStart, newEnd));
      }
    }
  }

  public componentWillReceiveProps(nextProps: TimelineComponentProps) {
    this.validateEventRange(nextProps.currentDate, nextProps.end);
  }

  public componentDidUpdate(
    prevProps: TimelineComponentProps,
    prevState: State,
  ) {
    const {
      allTasks,
      originalTasks,
      originalShifts,
      shiftTemplates,
      reloadShifts,
    } = this.state;
    if (this.timelineComponent.current) {
      const { currentDate, end } = this.props;

      this.timelineComponent.current.setWindow(
        new Date(currentDate.valueOf()),
        new Date(end.valueOf()),
      );
    }

    if (
      this.props.settings.general.enableWorksite !==
        prevProps.settings.general.enableWorksite ||
      prevState.originalTasks !== originalTasks
    ) {
      for (const task of originalTasks) {
        const tooltip = renderToStaticMarkup(
          renderTaskContent(task, this.props.settings),
        );

        const item = this.state.items.get(task.id!) as TimelineItem;

        const newItem: TimelineItem = {
          ...item,
          id: task.id!,
          content: task.worksite ? task.worksite.name : '',
          data: task,
          group: task.userId,
          start: new Date(task.start), // TODO check new firebase date handling
          end: new Date(task.end),
          companyId: this.props.companyId,
          displayWorksite: this.props.settings.general.enableWorksite,
          enableWorksiteNick: this.props.settings.general.enableWorksiteNick,
          settings: this.props.settings,
          theme: this.props.theme,
          title: tooltip,
          taskTitle: task.title ? task.title.value : undefined,
          chatIconState: item ? item.chatIconState : ChatIconState.UNDEFINED,
        };

        // NOTE!
        // This is wrong way, DON'T DO THIS. This is only for this timeline
        this.state.items.update(newItem);

        this.checkForUnreadMessages(newItem.id);
      }
    }

    let shiftBackgroundItemsToBeDeleted: ShiftWithKey[] = [];

    // Check if shifts have been removed
    if (prevState.originalShifts.length > originalShifts.length) {
      shiftBackgroundItemsToBeDeleted = prevState.originalShifts.filter(
        prevShift =>
          !originalShifts.find(
            originalShift => prevShift.key === originalShift.key,
          ),
      );
    }

    if (
      !isEqual(prevState.allTasks, allTasks) ||
      !isEqual(prevState.originalShifts, originalShifts) ||
      !isEqual(prevState.shiftTemplates, shiftTemplates) ||
      reloadShifts
    ) {
      const shiftBackgroundItems: vis.TimelineItem[] = [];
      const taskShifts: string[] = allTasks
        .filter(task => task.shiftId !== undefined)
        .map(task => task.shiftId!);

      const uniqueTaskShifts: string[] = [];
      taskShifts.forEach(
        taskShift =>
          !uniqueTaskShifts.includes(taskShift) &&
          uniqueTaskShifts.push(taskShift),
      );

      const shiftsToShow: ShiftWithKey[] = originalShifts.filter(
        shift => shift.key && uniqueTaskShifts.includes(shift.key),
      );

      shiftsToShow.forEach(shift => {
        const shiftTemplate = shiftTemplates.find(
          template => template.key === shift.shiftTemplateId,
        );

        const newShift: ShiftBackground = {
          id: shift.key!,
          userId: '',
          start: 0,
          end: 0,
          shiftTemplateId: shift.shiftTemplateId,
          name: shiftTemplate ? shiftTemplate.name : '',
          taskIds: shift.taskIds,
        };

        const shiftTasks: Task[] = [];

        shift.taskIds.forEach(taskId => {
          const task: TaskWithKey | undefined = allTasks.find(
            separateTask => separateTask.key === taskId,
          );
          if (task) {
            shiftTasks.push(task);
          }
        });

        if (shiftTasks.length > 0) {
          const startTimes = shiftTasks
            .map(task => task.start)
            .sort((a, b) => a - b);

          const endTimes = shiftTasks
            .map(task => task.end)
            .sort((a, b) => b - a);

          newShift.start = startTimes[0];
          newShift.end = endTimes[0];

          const newShiftItem: vis.TimelineItem = {
            id: newShift.id,
            start: newShift.start,
            end: newShift.end,
            content: newShift.name,
            type: 'background',
            group: shiftTasks[0].userId,
          };

          shiftBackgroundItems.push(newShiftItem);
        }
      });

      /**
       * If shifts are being removed, delete background objects related to them aswell
       */
      shiftBackgroundItemsToBeDeleted.forEach(itemToBeDeleted => {
        if (itemToBeDeleted.key) {
          this.state.items.remove(itemToBeDeleted.key);
        }
        itemToBeDeleted.taskIds.forEach(id => {
          this.state.items.remove(id);
        });
      });
      this.state.items.update(shiftBackgroundItems);

      this.setState({ reloadShifts: false });
    }
  }

  public componentWillUnmount() {
    this.unsubscribeTasks && this.unsubscribeTasks();
    this.unsubscribeShifts && this.unsubscribeShifts();
    this.unsubscribeShiftTemplates && this.unsubscribeShiftTemplates();

    for (const subscription of Object.values(this.chatSubscriptions)) {
      subscription.unsubscribe();
    }
    this.chatSubscriptions = {};
  }

  public render() {
    const { groups, classes = {} } = this.props;

    const groupsArray = groups;
    if (!groups.find(group => group.id === '')) {
      const userNotSelected = {
        id: '',
        content: i18n().ui.no_member_selected,
        photoURL: '',
      };
      groupsArray.unshift(userNotSelected);
    }

    return (
      <div className={classNames(classes.timeLine)}>
        {groups.length === 0 && (
          <Typography className={classes.noWorkersText}>
            {i18n().ui.members_not_found}
          </Typography>
        )}
        <TimelineComponent
          ref={this.timelineComponent}
          className={classNames(classes.timelineContainer)}
          options={this.options}
          groups={groupsArray}
          items={this.state.items}
          doubleClickHandler={this.onDoubleClick}
          rangechangedHandler={this.onRangeChanged}
          changedHandler={this.onChangeHandler}
        />

        <div className={classNames(classes.footer)}>
          <Button
            className={classNames(classes.zoom)}
            color="primary"
            onClick={this.onZoomIn}
            data-test="zoomInButton"
          >
            <ZoomIn className={classNames(classes.zoomIcon)} />
            {i18n().ui.zoom_in}
          </Button>
          <Button
            className={classNames(classes.zoom)}
            color="primary"
            onClick={this.onZoomOut}
            data-test="zoomOutButton"
          >
            <ZoomOut className={classNames(classes.zoomIcon)} />
            {i18n().ui.zoom_out}
          </Button>
        </div>
      </div>
    );
  }

  /**
   * User has double clicked on the time line area
   */
  private onDoubleClick = (event: any) => {
    const { group, time, what } = event;
    const { companyId, dispatch } = this.props;

    if (what !== 'group-label' && what !== 'axis') {
      // Time will be rounded to 15 minutes
      const roundedTime = moment(time);
      roundedTime.minutes(Math.round(moment().minute() / 15) * 15).second(0);

      if (what === 'background') {
        dispatch(newResourceEvent(companyId, group, roundedTime));
        dispatch(setChatIconState(ChatIconState.UNDEFINED));
      } else {
        const item = this.state.items.get().find(i => i.id === event.item) as
          | TimelineItem
          | undefined;

        if (item) {
          const task: TaskWithKey = {
            ...item.data,
            key: item.id,
          };

          dispatch(editResourceEvent(companyId, task));
          dispatch(setChatIconState(item.chatIconState));
        }
      }
    }
  };

  /**
   * @param {object} event original event triggering the rangechanged.
   * @param {number} event.start  timestamp of the current start of the window
   * @param {number} event.end  timestamp of the current end of the window
   * @param {boolean} event.byUser  hange happened because of user drag/zoom.
   */
  private onRangeChanged = (event: any) => {
    const { start, end, byUser } = event;
    if (byUser) {
      this.props.dispatch(setRange(start, end));
    }
  };

  /***
   * Function uses timeline ref to zoom in the timeline when user presses zoomIn button.
   * Zoom amount is given in percents between 0 and 1. For now function zooms in for one whole step ex. from 14 days to 7 days.
   * Until the minimum and maximum amount is reached.
   */
  private onZoomIn = () => {
    if (this.timelineComponent.current) {
      this.timelineComponent.current.zoomIn(0.5);
    }
  };

  /***
   * Function uses timeline ref to zoom out the timeline when user presses zoomOut button.
   * Zoom amount is given in percents between 0 and 1. For now function zooms out for one whole step ex. from 7 days to 14 days.
   * Until the minimum and maximum amount is reached.
   */
  private onZoomOut = () => {
    if (this.timelineComponent.current) {
      this.timelineComponent.current.zoomOut(0.5);
    }
  };

  private validateEventRange = (
    currentDate: moment.Moment,
    end: moment.Moment,
  ) => {
    const { fetch } = this.state;

    // Scroll range is 10 days to both directions
    const scrollRange = 10;
    const fetchLimit = 2;

    if (!end) {
      end = moment(currentDate).add(1, 'days');
    }

    let changeRange = false;

    if (!(fetch.start && fetch.end)) {
      changeRange = true;
    } else {
      const startDelta = moment
        .duration(currentDate.valueOf() - fetch.start.valueOf())
        .days();

      const endDelta = moment
        .duration(fetch.end.valueOf() - end.valueOf())
        .days();

      changeRange = startDelta <= fetchLimit || endDelta <= fetchLimit;
    }

    if (changeRange) {
      this.unsubscribeTasks && this.unsubscribeTasks();

      // NOTE!
      // This is wrong way, DON'T DO THIS. This is only for this timeline
      // TODO Make more robust version that will remove only necessery data
      // this.state.items.clear();

      // TODO Mutating existing state object, should create new
      fetch.start = moment(currentDate).subtract(scrollRange, 'days');
      fetch.end = moment(end).add(scrollRange, 'days');

      this.setState({ fetch });

      this.unsubscribeTasks = getTasksTableRef(this.props.companyId)
        .where('archived', '==', false)
        .where('start', '>=', fetch.start.valueOf())
        .where('start', '<=', fetch.end.valueOf())
        .onSnapshot(snapshot => {
          const originalTasks: Task[] = [];

          snapshot.docChanges().forEach(change => {
            try {
              if (change.type === 'added' || change.type === 'modified') {
                const task = change.doc.data() as Task;
                task.id = change.doc.id;

                // If task has no end value dont show it in timeline
                if (task.end > 0) {
                  originalTasks.push(task);
                }
              }

              if (change.type === 'removed') {
                // NOTE!
                // This is wrong way, DON'T DO THIS. This is only for this timeline
                this.state.items.remove(change.doc.id);
              }
            } catch (error) {
              console.error(error.message);
            }
          });

          const allTasksInRange: Task[] = [];
          snapshot.forEach(doc => {
            const taskInRange = doc.data() as TaskWithKey;
            taskInRange.key = doc.id;
            allTasksInRange.push(taskInRange);
          });

          this.setState({
            originalTasks,
            items: this.state.items,
            reloadShifts: true,
            allTasks: allTasksInRange,
          });
        });
    }
  };

  /**
   * Change handler for timeline.
   * This will get all visible timeline items and add onSnapshotListener to it.
   * This way we can get
   */
  private onChangeHandler = () => {
    if (this.timelineComponent.current) {
      const visibleTaskKeys = this.timelineComponent.current.getVisibleItems();

      if (visibleTaskKeys) {
        // Unsubscribe tasks that are not visible
        for (const key of Object.keys(this.chatSubscriptions)) {
          if (!visibleTaskKeys.includes(key)) {
            this.chatSubscriptions[key].unsubscribe();
            delete this.chatSubscriptions[key];
          }
        }

        // Subscribe tasks that are not subscribed
        for (const key of visibleTaskKeys) {
          if (!this.chatSubscriptions[key]) {
            this.subscribeLastChatMessage(key);
          }
        }
      }
    }
  };

  /**
   * Subscribe to task's last chat msg
   *
   * @param {vis.IdType} taskKey
   */
  private subscribeLastChatMessage = (taskKey: vis.IdType) => {
    const { companyId } = this.props;

    const unsubscribe = getTasksTableRef(companyId)
      .doc(taskKey.toString())
      .collection(Schema.CHAT)
      .orderBy('date', 'desc')
      .limit(1)
      .onSnapshot(chat => {
        chat.forEach(message => {
          this.chatSubscriptions[taskKey].lastMsgKey = message.id;
          this.checkForUnreadMessages(taskKey);
        });
      });

    this.chatSubscriptions[taskKey] = {
      lastMsgKey: undefined,
      unsubscribe,
    };
  };

  /**
   * Check if there is new messages for current user
   *
   * @param {vis.IdType} taskKey - Key of task
   */
  private checkForUnreadMessages = (taskKey: vis.IdType) => {
    const { user, eventEditor, dispatch } = this.props;

    const item = this.state.items.get(taskKey) as TimelineItem;
    const subscription = this.chatSubscriptions[taskKey];

    if (!subscription || !item || !item.data || !user) {
      return;
    }

    let chatIconState = ChatIconState.UNDEFINED;

    if (subscription.lastMsgKey !== undefined) {
      const userLastMsgKey =
        item.data.hasReadChat && item.data.hasReadChat[user.id];

      chatIconState =
        userLastMsgKey === subscription.lastMsgKey
          ? ChatIconState.READ
          : ChatIconState.UNREAD;
    }

    if (item.chatIconState !== chatIconState) {
      // NOTE!
      // This is wrong way, DON'T DO THIS. This is only for this timeline
      this.state.items.update({
        ...item,
        chatIconState,
      } as vis.TimelineItem);

      // In case this task is currently being edited, change dialog's tab icon
      if (taskKey === eventEditor.editedResource.key) {
        dispatch(setChatIconState(chatIconState));
      }
    }
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TimelineComponentProps>,
) => {
  return {
    ...ownProps,
    hiddenMembers: state.timeline.hiddenMembers,
    hiddenJobtypes: state.timeline.hiddenJobtypes,
    hiddenQualifications: state.timeline.hiddenQualifications,
    filter: state.timeline.filter,
    deleteEventTitle: state.eventEditor.deleteEventTitle,
    end: state.timeline.end,
    currentDate: state.timeline.currentDate,
    settings: state.company.activeCompany.settings,
    eventEditor: state.eventEditor,
    user: state.auth.appUser,
  } as TimelineComponentProps;
};

export default withRouter<any>(
  connect<TimelineComponentProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(Timeline),
  ),
);
