import {
  InputAdornment,
  StyledComponentProps,
  withStyles,
  WithTheme,
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { i18n } from '@shared/locale/index';
import { DatePicker } from 'material-ui-pickers';
import * as React from 'react';
import { styles } from './styles';

interface TaskReportDateTimePickerProps
  extends StyledComponentProps,
    WithTheme {
  maxDate?: Date;
  minDate?: Date;
  clearable?: Date;
  dateValue?: Date;
  onChange: (date: Date) => void;
}

/**
 * DatePicker with custom styling
 * using same styling base as taskReportDateTimePicker
 * @param props
 */
const WorkTimeReportDatePicker = (props: TaskReportDateTimePickerProps) => {
  const { classes = {}, dateValue, onChange } = props;

  return (
    <DatePicker
      className={classes.datePicker}
      data-test="startedDateStartPicker"
      onChange={onChange}
      value={dateValue}
      animateYearScrolling={false}
      cancelLabel={i18n().ui.cancel}
      clearLabel={i18n().ui.clear}
      variant="outlined"
      format={'DD.MM.YYYY'}
      mask={(value: string | RegExp) =>
        value ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] : []
      }
      clearable
      disableOpenOnEnter
      InputProps={{
        classes: {
          root: classes.calendar,
        },
        endAdornment: (
          <InputAdornment position="end">
            <DateRange />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default withStyles(styles, { withTheme: true })(
  WorkTimeReportDatePicker,
);
