import * as React from 'react';
import ShiftsSettings from '../components/ShiftsSettings';

export interface ShiftsContainerProps {
  companyId: string;
  classes?: any;
}

const QualificationsContainer = (props: ShiftsContainerProps) => (
  <ShiftsSettings companyId={props.companyId} />
);

export default QualificationsContainer;
