import {
  FormControl,
  FormLabel,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  StyledComponentProps,
  StyleRules,
  withStyles,
  WithTheme,
} from '@material-ui/core/styles';
import applyDefaults from '@shared/defaults/applyDefaults';
import { DEFAULT_COMPANY } from '@shared/defaults/index';
import { i18n } from '@shared/locale/index';
import { Company } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment/index';
import moment from 'moment';
import * as React from 'react';
import { CompanyFieldValidity } from '../containers/GeneralSettingsContainer';

export interface CompanySettingsActiveHoursProps
  extends StyledComponentProps,
    WithTheme {
  company: Company;
  fieldValidity: CompanyFieldValidity;
  onCompanyChange: (
    company: Company,
    fieldValidity: CompanyFieldValidity,
  ) => void;
}

interface State {
  start: number;
  end: number;
}

export class CompanySettingsActiveHours extends React.Component<
  CompanySettingsActiveHoursProps,
  State
> {
  public state: State = {
    start: 6,
    end: 22,
  };

  constructor(props: CompanySettingsActiveHoursProps) {
    super(props);
  }

  public componentDidMount() {
    const company = this.props.company;
    const defaultCompanyActiveHours = DEFAULT_COMPANY.settings.general
      .activeHours!;
    const start = company.settings.general.activeHours
      ? company.settings.general.activeHours.start
      : defaultCompanyActiveHours.start;
    const end = company.settings.general.activeHours
      ? company.settings.general.activeHours.end
      : defaultCompanyActiveHours.end;
    this.setState({
      start: moment.duration(start, 'millisecond').asHours(),
      end: moment.duration(end, 'millisecond').asHours(),
    });
  }

  public componentDidUpdate(prevProps: CompanySettingsActiveHoursProps) {
    if (prevProps !== this.props) {
      const activeHours = this.props.company.settings.general.activeHours;
      activeHours &&
        this.setState({
          start: moment.duration(activeHours.start, 'milliseconds').asHours(),
          end: moment.duration(activeHours.end, 'milliseconds').asHours(),
        });
    }
  }

  public render() {
    const { classes = {} } = this.props;

    return (
      <>
        <ListItem>
          <Typography variant="h5" className={classes.title}>
            {i18n().ui.timeline_settings}
          </Typography>
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <FormLabel>
              {i18n().ui.set_the_time_frame_for_timeline_view}
            </FormLabel>
            <div className={classes.activeHoursSelectionContainer}>
              <FormControl>
                <InputLabel>{i18n().ui.start_noun}</InputLabel>
                <Select
                  value={this.state.start}
                  name="start"
                  onChange={this.handleChange}
                  data-test="selectStart"
                >
                  {this.hourArray.map((hour: number) => {
                    if (hour >= this.state.end) {
                      return null;
                    }
                    return (
                      <MenuItem value={hour} key={hour}>
                        {hour !== 0
                          ? momentDurationFormat(
                              moment.duration(hour, 'hour'),
                              'hh:mm',
                            )
                          : '00:00'}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>{i18n().ui.end_noun}</InputLabel>
                <Select
                  data-test="selectEnd"
                  value={this.state.end}
                  name="end"
                  onChange={this.handleChange}
                >
                  {this.hourArray.map((hour: number) => {
                    if (hour <= this.state.start) {
                      return null;
                    }
                    return (
                      <MenuItem value={hour} key={hour}>
                        {momentDurationFormat(
                          moment.duration(hour, 'hour'),
                          'hh:mm',
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </FormControl>
        </ListItem>
      </>
    );
  }

  /**
   * Handle changes for both dropdowns used for selecting active hours
   * Checks which dropdown was triggered and updates activeHours settings accordingly
   * At the end, send updated company to onCompanyChange
   */
  private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const activeHours = 'activeHours';
    let start = this.state.start;
    let end = this.state.end;
    const { company, fieldValidity } = this.props;
    const { settings } = company;
    const general = { ...settings.general };
    switch (event.target.name) {
      case 'start':
        start = Number(event.target.value);
        break;
      case 'end':
        end = Number(event.target.value);
        break;
      default:
        return;
    }
    this.setState({ start, end });
    general[activeHours] = {
      start: moment.duration(start, 'hours').asMilliseconds(),
      end: moment.duration(end, 'hours').asMilliseconds(),
    };
    this.props.onCompanyChange(
      applyDefaults(
        {
          settings: {
            general,
          },
        },
        company,
      ) as Company,
      {
        ...fieldValidity,
      },
    );
  };

  private get hourArray(): number[] {
    const arr = [];
    let lastHour = 24;
    const firstHour = 0;
    let c = lastHour - firstHour + 1;
    while (c--) {
      arr[c] = lastHour--;
    }
    return arr;
  }
}

const styles = (): StyleRules<string> => ({
  activeHoursSelectionContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px',
  },
});

export default withStyles(styles, { withTheme: true })(
  CompanySettingsActiveHours,
);
