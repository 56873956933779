/**
 * Multiple named keyframe animations
 */
export interface KeyframeAnimations {
  /** Animation name */
  [animationName: string]: KeyframeAnimation;
}

/**
 * Keyframe animation
 */
export interface KeyframeAnimation {
  /** Animation phase, e.g. 'from', 'to', '0%', '50%' */
  [animationPhase: string]: {
    /** CSS parameter in camelCase or dashed */
    [parameterName: string]: string;
  };
}

const uncamelize = (str: string): string => {
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);

    if (char !== char.toLowerCase()) {
      result += `-${char.toLowerCase()}`; // Add dash
    } else {
      result += char;
    }
  }

  return result;
};

const animationNames = {};
let animationStyles: HTMLElement | undefined;

/**
 * Inject new keyframe animations to HTML document.
 *
 * Animation names here are global and will throw run-time error if attempted
 * to inject twice with same name. They are inserted as-is so don't get too fancy.
 *
 * keyframe-rule vendor prefixes (-webkit-keyframes, -moz-keyframes) are
 * automatically generated, but individual properties need prefixes if applicable.
 * (e.g. -webkit-transform + -moz-transform + transform)
 *
 * Property names can be given either camelCase (borderWidth, webkitTransform)
 * or dashed ("border-width", "-webkit-transform")
 *
 * @param animations Object containing animations to inject.
 */
const injectKeyFrameAnimations = (animations: KeyframeAnimations) => {
  if (!animationStyles) {
    animationStyles = document.createElement('style');
    document.getElementsByTagName('head')[0].appendChild(animationStyles);
  }

  let webkitCss = '';
  let mozCss = '';
  let css = '';

  for (const name in animations) {
    if (name) {
      if (animationNames[name]) {
        console.error(`Animation ${name} already has keyframes defined!`);
        continue;
      }

      animationNames[name] = true;

      webkitCss += `@-webkit-keyframes ${name} {\n`;
      mozCss += `@-moz-keyframes ${name} {\n`;
      css += `@keyframes ${name} {\n`;

      for (const phase in animations[name]) {
        if (phase) {
          webkitCss += `\t${phase} {\n`;
          mozCss += `\t${phase} {\n`;
          css += `\t${phase} {\n`;

          for (const key in animations[name][phase]) {
            if (key) {
              let cssKey = key.indexOf('-') === -1 ? uncamelize(key) : key;
              if (key.indexOf('webkit') === 0 || key.indexOf('moz') === 0) {
                cssKey = `-${cssKey}`;
              }

              const valueLine = `\t\t${cssKey}: ${
                animations[name][phase][key]
              };\n`;

              if (cssKey.indexOf('-webkit-') === 0) {
                webkitCss += valueLine;
              } else if (cssKey.indexOf('-moz-') === 0) {
                mozCss += valueLine;
              } else {
                webkitCss += valueLine;
                mozCss += valueLine;
                css += valueLine;
              }
            }
          }

          webkitCss += '\t}\n';
          mozCss += '\t}\n';
          css += '\t}\n';
        }
      }
      webkitCss += '}\n\n';
      mozCss += '}\n\n';
      css += '}\n\n';
    }
  }

  animationStyles.innerHTML += webkitCss + mozCss + css;
};

export default injectKeyFrameAnimations;
