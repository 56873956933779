import { ActionCreator, AnyAction } from "redux";

export const enum NaviActionType {
  /**
   * Drawer toggled
   */
  NAVI_DRAWER_OPEN_STATE_CHANGED = "NAVI_DRAWER_OPEN_STATE_CHANGED",

  NAVI_DRAWER_MINIFY_STATE_CHANGED = "NAVI_DRAWER_MINIFY_STATE_CHANGED",

  NAVI_PATH_STATE_CHANGED = "NAVI_PATH_STATE_CHANGED",

  NAVI_SET_NAVI_BUTTONS_FUN = "NAVI_SET_NAVI_BUTTONS_FUN",

  /**
   * Trigger navi buttons to redraw a new state
   */
  NAVI_BUTTON_STAGE_CHANGE = "NAVI_BUTTON_STAGE_CHANGE",

  /**
   * Set full screen mode on / off
   */
  NAVI_FULLSCREEN = "NAVI_SET_FULLSCREEN"
}

export interface NaviDrawerStateChangedAction extends AnyAction {
  type: NaviActionType.NAVI_DRAWER_OPEN_STATE_CHANGED;
  payload: boolean;
}

export interface NaviDrawerMinifyStateChangedAction extends AnyAction {
  type: NaviActionType.NAVI_DRAWER_MINIFY_STATE_CHANGED;
  payload: boolean;
}

export interface NaviSetNaviButtonsAction extends AnyAction {
  type: NaviActionType.NAVI_SET_NAVI_BUTTONS_FUN;
  payload: () => JSX.Element[];
}

export interface NaviButtonStageChangeAction extends AnyAction {
  type: NaviActionType.NAVI_BUTTON_STAGE_CHANGE;
}

export interface NaviSetFullScreenAction extends AnyAction {
  type: NaviActionType.NAVI_FULLSCREEN;
  payload: boolean;
}

export const drawerStateChanged: ActionCreator<NaviDrawerStateChangedAction> = (
  drawerOpen: boolean
) => ({
  type: NaviActionType.NAVI_DRAWER_OPEN_STATE_CHANGED,
  payload: drawerOpen
});

export const setDrawerMinifyState: ActionCreator<
  NaviDrawerMinifyStateChangedAction
> = (minified: boolean) => ({
  type: NaviActionType.NAVI_DRAWER_MINIFY_STATE_CHANGED,
  payload: minified
});

export const setFullscreenMode: ActionCreator<NaviSetFullScreenAction> = (
  payload: boolean
) => ({
  type: NaviActionType.NAVI_FULLSCREEN,
  payload
});

export const setNaviButtons: ActionCreator<NaviSetNaviButtonsAction> = (
  payload: () => JSX.Element[]
) => ({
  type: NaviActionType.NAVI_SET_NAVI_BUTTONS_FUN,
  payload
});

export const naviButtonStageChange: ActionCreator<
  NaviButtonStageChangeAction
> = () => ({
  type: NaviActionType.NAVI_BUTTON_STAGE_CHANGE
});

export type NaviAction =
  | AnyAction
  | NaviDrawerStateChangedAction
  | NaviDrawerMinifyStateChangedAction
  // | NaviPathStateChangedAction
  | NaviSetNaviButtonsAction
  | NaviButtonStageChangeAction;
