import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

import { ChatIconState, CompanyId, Schema, TaskWithKey } from '@shared/schema';
import ChatStatusIcon from 'components/ChatStatusIcon';
import { getTasksTableRef } from 'utils/tasksUtil';

export interface SubscribeChatStatusIconProps extends SvgIconProps {
  companyId: CompanyId;
  task: TaskWithKey;
  userId: string;
}

interface State {
  iconState: ChatIconState;
}

/**
 * Chat status icon which can subscribe to check read
 * messages status of user's messages on its own.
 */
export class SubscribeChatStatusIcon extends React.Component<
  SubscribeChatStatusIconProps,
  State
  > {
  private unsubscribe: () => void;

  constructor(props: SubscribeChatStatusIconProps) {
    super(props);

    this.state = {
      iconState: ChatIconState.UNDEFINED,
    };
  }

  public componentDidMount() {
    this.subscribe();
  }

  public componentDidUpdate(prevProps: SubscribeChatStatusIconProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.task.key !== this.props.task.key ||
      prevProps.userId !== this.props.userId
    ) {
      this.unsubscribe && this.unsubscribe();
      this.subscribe();
    }
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  public render() {
    const iconProps = { ...this.props };

    delete iconProps.companyId;
    delete iconProps.task;
    delete iconProps.userId;

    return <ChatStatusIcon {...iconProps} state={this.state.iconState} />;
  }

  private subscribe = () => {
    const { companyId, task, userId } = this.props;

    this.unsubscribe = getTasksTableRef(companyId)
      .doc(task.key)
      .collection(Schema.CHAT)
      .orderBy('date', 'desc')
      .limit(1)
      .onSnapshot(chat => {
        chat.forEach(message => {
          const iconState =
            !task.hasReadChat || message.id !== task.hasReadChat[userId]
              ? ChatIconState.UNREAD
              : ChatIconState.READ;

          this.setState({ iconState });
        });
      });

    this.setState({ iconState: ChatIconState.UNDEFINED });
  };
}

export default SubscribeChatStatusIcon;
