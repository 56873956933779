import { CompanyId } from '@shared/schema';
import * as React from 'react';
import HourSettings from '../components/HourSettings/index';

export interface HourTypeContainerProps {
  companyId: CompanyId;
  classes?: any;
}

const HourTypeContainer = (props: HourTypeContainerProps) => (
  <HourSettings companyId={props.companyId} />
);

export default HourTypeContainer;
