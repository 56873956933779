import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import injectKeyFrameAnimations from 'utils/keyframeAnimations';

injectKeyFrameAnimations({
  map_icon_select_animation: {
    '0%': {
      transform: 'translate(-50%, -100%) scale(1)',
      transformOrigin: '50% 100%',
    },
    '50%': {
      transform: 'translate(-50%, -100%) scale(2)',
      transformOrigin: '50% 100%',
    },
    '100%': {
      transform: 'translate(-50%, -100%) scale(1)',
      transformOrigin: '50% 100%',
    },
  },
});

const styles = (theme: Theme): StyleRules<string> => ({
  base: {
    '&.default': {
      color: theme.mapMarkerThemes.default.color,
    },
    '&.undone': {
      color: theme.mapMarkerThemes.undone.color,
    },
    '&.active': {
      color: theme.mapMarkerThemes.active.color,
    },
    '&.paused': {
      color: theme.mapMarkerThemes.paused.color,
    },
    '&.late': {
      color: theme.mapMarkerThemes.late.color,
    },
  },
  infoWindow: {
    width: '250px',
    minHeight: '100px',
    maxHeight: '400px',
    top: '10px',
    right: '200px',
    overflowY: 'auto',
    cursor: 'default',
    zIndex: 1000,
    paddingBottom: '5px',
  },
  searchDrawer: {
    top: '64px',
    width: '16.7%',
    overflowY: 'auto',
    marginBottom: '40%',
  },
  searchButton: {
    width: '100%',
    height: '31px',
    backgroundColor: '#99CA3C',
    borderRadius: '4px',
    fontSize: '18px',
    lineHeight: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  filterContainer: {
    padding: '0px 30px 20px 30px',
  },
  infoWindowHeadline: {
    padding: '10px 5px 0px 10px',
    fontFamily: 'Ubuntu',
  },
  worksiteIcon: {
    width: '40px',
    height: '40px',
    transform: 'translate(-50%, -100%)',
  },
  selectedIcon: {
    width: '40px',
    height: '40px',
    transform: 'translate(-50%, -100%)',
    animation: 'map_icon_select_animation .9s ease-in-out 1',
    animationIterationCount: '2',
  },

  infoWindowCaption: {
    padding: '0px 5px 5px 10px',
    fontFamily: 'Ubuntu',
  },
  infoWindowText: {
    paddingLeft: '10px',
    paddingRight: '5px',
    fontFamily: 'Ubuntu',
  },
  infoWindowTaskHeader: {
    padding: '10px 5px 5px 10px',
    fontFamily: 'Ubuntu',
  },
  newTask: {
    height: '50px',
    padding: '5px 5px 10px 5px',
    margin: '10px 10px 5px 10px',
    backgroundColor: '#99CA3C',
  },
  worksiteTask: {
    height: '50px',
    padding: '5px 5px 10px 5px',
    margin: '0px 10px 10px 10px',

    '&.default': {
      borderBottom: '5px solid #696969',
    },
    '&.undone': {
      borderBottom: '5px solid #696969',
    },
    '&.active': {
      borderBottom: '5px solid #249DDA',
    },
    '&.paused': {
      borderBottom: '5px solid #FFA500',
    },
    '&.late': {
      borderBottom: '5px solid #C80000',
    },
  },
  taskName: {
    float: 'left',
    top: '50%',
    paddingTop: '10px',
    paddingLeft: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '85%',
  },
  avatarIcon: {
    width: '22px',
    height: '22px',
    borderRadius: '20px',
    float: 'right',
    marginTop: '10px',
    marginRight: '8px',
  },
  resultsContainer: {
    position: 'fixed',
    backgroundColor: '#F5F5F5',
    zIndex: 1000,
    top: '64px',
    right: '16.7%',
    width: '16.7%',
    height: '100%',
    boxShadow: '-6px 0px 10px rgba(0, 0, 0, 0.25)',
    paddingTop: '0px',
    flex: '1 0 auto',
    marginBottom: '10%',
  },
  resultsTitle: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '21px',
    cursor: 'default',
  },
  resultsList: {
    padding: '0px 20px 20px 20px',
    maxHeight: '90%',
    overflowY: 'auto',
  },
  resultCard: {
    margin: '10px 0px 0px 0px',
    padding: '0px 10px 0px 10px',
    display: 'flex',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.55)',
    borderRadius: '3px',
    flexDirection: 'row',
    height: '50px',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  selectedCard: {
    margin: '10px 0px 0px 0px',
    padding: '0px 10px 0px 10px',
    display: 'flex',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.55)',
    borderRadius: '3px',
    flexDirection: 'row',
    height: '50px',
    backgroundColor: '#99CA3C',
    cursor: 'pointer',
  },
  resultName: {
    lineHeight: '50px',
    height: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
  },
});
export default styles;
