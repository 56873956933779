import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { i18n } from '@shared/locale';
import {
  CompanyId,
  DialogStatus,
  HourType,
  HourTypeWithKey,
  Schema,
} from '@shared/schema';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import SettingsListItem from 'containers/Private/Settings/components/SettingsListItem';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import muiTheme from 'theme';
import HourTypeDialog from './components/HourTypeDialog';
import styles from './styles';

interface HourSettingsProps extends StyledComponentProps {
  companyId: CompanyId | undefined;
}

interface State {
  hourTypes: HourTypeWithKey[];
  deleteHourType: HourTypeWithKey | undefined;
  modifyHourType: HourTypeWithKey | undefined;
  newHourType: HourTypeWithKey | undefined;
  filter: string;
  selectMultiple: boolean;
  selectedItems: SelectedHourTypes;
  deleteMultiple: boolean;
  modifychecked: false;
  openDeleteMultipleDialog: boolean;
  openNewModifyDialog: boolean;
  dialogTitle: string;
  dialogStatus: DialogStatus | undefined;
  hiddenMultiplier: boolean;
}

interface SelectedHourTypes {
  [key: string]: boolean;
}

export class HourSettings extends React.Component<HourSettingsProps, State> {
  private unsubscribeHourTypes: () => void;

  constructor(props: HourSettingsProps) {
    super(props);

    this.state = {
      hourTypes: [],
      newHourType: undefined,
      deleteHourType: undefined,
      modifyHourType: undefined,
      filter: '',
      selectMultiple: false,
      selectedItems: {},
      deleteMultiple: false,
      modifychecked: false,
      openDeleteMultipleDialog: false,
      openNewModifyDialog: false,
      dialogTitle: '',
      dialogStatus: undefined,
      hiddenMultiplier: true,
    };
  }

  public componentDidMount() {
    const { companyId } = this.props;
    if (companyId) {
      this.unsubscribeHourTypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.HOURTYPES)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const hourTypes: HourType[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const hourType = data.data() as HourTypeWithKey;
            hourType.key = data.id;
            hourTypes.push({
              ...hourType,
            });
          });

          hourTypes.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });

          this.setState({
            hourTypes,
          });
        });
    } else {
      console.log('Company id is not set @HourSettings');
    }
  }

  public componentWillUnmount() {
    this.unsubscribeHourTypes && this.unsubscribeHourTypes();
  }

  public render() {
    const { classes = {} } = this.props;
    const {
      hourTypes,
      filter,
      selectMultiple,
      selectedItems,
      openNewModifyDialog,
      dialogTitle,
      modifyHourType,
      deleteHourType,
      openDeleteMultipleDialog,
    } = this.state;

    const defaultString = `(${i18n().ui.default})`;
    return (
      <div
        className={classNames(classes.container)}
        style={{ color: muiTheme.palette.primary.main }}
      >
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.bar}>
            <FormControl className={classes.topFormControl}>
              <TextField
                id="search"
                placeholder={i18n().ui.search_for_hour_types}
                className={classes.searchInput}
                margin="normal"
                onChange={this.handleFilterChange()}
                data-test="filterInput"
              />
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleOpenNewDialog}
              >
                <AddIcon className={classes.icon} />
                {i18n().ui.add_new}
              </Button>
              <Button
                variant="contained"
                className={classNames(
                  classes.button,
                  selectMultiple ? classes.selected : '',
                )}
                onClick={this.handleSelectMultiple}
                data-test="selectMultipleButton"
              >
                <DoneAllIcon className={classes.icon} />
                {i18n().ui.select_multiple}
              </Button>
              {selectMultiple && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleOpenDeleteMultiple}
                  data-test="openDeleteMultipleDialogButton"
                >
                  <DeleteIcon className={classes.icon} />
                  {i18n().ui.delete_selected}
                </Button>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.itemContainer}>
            <Grid container spacing={24} data-test="hourTypeGrid">
              {hourTypes.map((item: HourTypeWithKey) => {
                if (
                  filter.length === 0 ||
                  item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
                ) {
                  return (
                    <SettingsListItem
                      key={item.name}
                      id={item.key!}
                      name={`${item.name} ${
                        !this.state.hiddenMultiplier
                          ? `${item.multiplier}x`
                          : ''
                      } ${item.default ? defaultString : ''}`}
                      onModify={this.handleHourTypeModify}
                      onSelect={this.handleItemSelect}
                      onDelete={this.handleHourTypeDelete}
                      selected={selectedItems[item.key!]}
                      selectActive={selectMultiple}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>

        {deleteHourType || openDeleteMultipleDialog ? (
          <DeleteDialog
            dialogTitle={`${dialogTitle}`}
            handleDelete={
              this.state.deleteMultiple
                ? this.handleDeleteMultiple
                : this.handleDeleteItem
            }
            handleCloseDialog={this.handleDialogClose}
          />
        ) : (
          <></>
        )}
        {openNewModifyDialog || modifyHourType ? (
          <HourTypeDialog
            dialogTitle={dialogTitle}
            modifiedHourType={this.state.modifyHourType}
            dialogType={this.state.dialogStatus}
            companyId={this.props.companyId ? this.props.companyId : ''}
            handleCloseDialog={this.handleDialogClose}
            updateHourTypes={this.updateHourTypes}
            hourTypes={this.state.hourTypes}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  private handleFilterChange = () => (e: React.BaseSyntheticEvent) => {
    this.setState({
      filter: e.target.value,
    });
  };

  private handleSelectMultiple = () => {
    this.setState({
      selectMultiple: !this.state.selectMultiple,
      selectedItems: {},
    });
  };

  private handleHourTypeDelete = (hourTypeKey: string) => {
    this.setState({
      deleteHourType: this.getHourTypeByKey(hourTypeKey),
      dialogTitle: i18n().ui.want_to_delete_hour_type,
    });
  };

  private handleOpenDeleteMultiple = () => {
    this.setState({
      openDeleteMultipleDialog: true,
      deleteMultiple: true,
      dialogTitle: i18n().ui.want_to_delete_selected_hour_types,
    });
  };

  private handleOpenNewDialog = () => {
    this.setState({
      openNewModifyDialog: true,
      dialogStatus: DialogStatus.NEW,
      dialogTitle: i18n().ui.add_hour_type,
    });
  };

  private handleDialogClose = (): void => {
    this.setState({
      openDeleteMultipleDialog: false,
      openNewModifyDialog: false,
      deleteMultiple: false,
      dialogTitle: '',
      dialogStatus: undefined,
      modifyHourType: undefined,
      deleteHourType: undefined,
    });
  };

  /**
   * Delete specific items
   * Method is passed to DeleteDialog component
   */
  private handleDeleteItem = (): void => {
    const { deleteHourType } = this.state;
    if (deleteHourType && deleteHourType.key) {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.HOURTYPES)
        .doc(deleteHourType.key)
        .delete()
        .then(() => {
          this.setState({
            deleteHourType: undefined,
            openDeleteMultipleDialog: false,
          });
        })
        .catch(error => {
          console.log('Delete failed for some magical reason', error);
        });
    }
  };

  private handleHourTypeModify = (hourTypeKey: string) => {
    this.setState({
      modifyHourType: this.getHourTypeByKey(hourTypeKey),
      dialogStatus: DialogStatus.MODIFY,
      dialogTitle: i18n().ui.edit_hour_type,
    });
  };

  private handleItemSelect = (hourTypeKey: string, selected: boolean) => {
    const newSelectedItems = this.state.selectedItems;
    if (selected) {
      newSelectedItems[hourTypeKey] = true;
    } else {
      delete newSelectedItems[hourTypeKey];
    }
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  private handleDeleteMultiple = () => {
    const { selectedItems } = this.state;
    const batch = firebaseApp.firestore().batch();

    for (const key in selectedItems) {
      if (selectedItems[key]) {
        const itemRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.HOURTYPES)
          .doc(key);

        batch.delete(itemRef);
      }
    }

    batch
      .commit()
      .then(() => {
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
          openDeleteMultipleDialog: false,
        });
      })
      .catch(error => {
        console.log(
          'Something went wrong while deleting multiple @ VatSettings',
          error,
        );
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
          openDeleteMultipleDialog: false,
        });
      });
  };

  /**
   * Updates all tasks if new/modifed task default value is changed to true
   */
  private updateHourTypes(hourTypes: HourTypeWithKey[], companyId: string) {
    const batch = firebaseApp.firestore().batch();
    try {
      hourTypes.forEach(element => {
        const hourTypeUpdate = {
          default: false,
        };
        const hourTypePath = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.HOURTYPES)
          .doc(element.key);

        batch.update(hourTypePath, hourTypeUpdate);
      });
      batch.commit();
    } catch (error) {
      console.log('Batch update failed', error.message);
    }
  }

  private getHourTypeByKey = (key: string) => {
    return this.state.hourTypes
      ? this.state.hourTypes.find(item => item.key === key)
      : undefined;
  };
}

export default withStyles(styles, { withTheme: true })(HourSettings);
