import { StyledComponentProps, Theme, WithTheme } from '@material-ui/core';
import { TableSortLabel } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import tableLayout from 'theme/styles/table';
import CustomTableCell from 'theme/styles/tableCell';

export interface SortingHeadTableCellProps
  extends StyledComponentProps,
    WithTheme {
  selectedOrderBy: string;
  order: 'desc' | 'asc';
  orderBy: string;
  handleSortClick: (orderBy: string) => void;
  title: string;
}

export class SortingHeadTableCell extends React.Component<
  SortingHeadTableCellProps
> {
  public render() {
    const { classes = {} } = this.props;

    return (
      <CustomTableCell
        data-test="tableCell"
        className={classNames(classes.tableCellHeader, classes.sortableCell)}
        onClick={this.handleSortClick}
      >
        <TableSortLabel
          data-test="tableSortLabel"
          active={this.props.selectedOrderBy === this.props.orderBy}
          direction={
            this.props.selectedOrderBy === this.props.orderBy
              ? this.props.order
              : 'asc'
          }
          className={classes.sortLabel}
        >
          {this.props.title}
        </TableSortLabel>
      </CustomTableCell>
    );
  }

  private handleSortClick = () => {
    this.props.handleSortClick(this.props.orderBy);
  };
}

export const styles = (theme: Theme): StyleRules<string> => ({
  sortLabel: {
    color: 'white',
    '&:hover': { color: 'white' },
    '&:focus': { color: 'white' },
    '& svg': {
      color: 'white',
      opacity: 0.4,
    },
  },
  sortableCell: {
    cursor: 'pointer',
  },
  ...tableLayout(theme),
});

export default withStyles(styles, { withTheme: true })(SortingHeadTableCell);
