import { Repetition, Schema, TaskWithKey } from '@shared/schema';
import firebaseApp from 'firebaseApp';
import { Moment } from 'moment';
import moment from 'moment';
import { getTasksTableRef } from './tasksUtil';

/**
 * Returns reference to repeatingTasks table from firebase
 *
 * @param {string | undefined} companyId
 */
export function getRepeatingTasksTableRef(
  companyId: string | undefined,
): firebase.firestore.CollectionReference {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.REPEATINGTASKS);
}

/**
 * Returns reference to repeatingTasks instance with repeatingTask id or returns empty instance reference
 *
 * @param {string | undefined} companyId
 * @param {string | undefined} id?
 */
export function getRepeatingTasksDocRef(
  companyId: string | undefined,
  id?: string | undefined,
): firebase.firestore.DocumentReference {
  return id
    ? getRepeatingTasksTableRef(companyId).doc(id)
    : getRepeatingTasksTableRef(companyId).doc();
}

/**
 * Returns array of tasks with same repeatingTaskId and start time bigger the selected task
 *
 * @param {string |undefined} companyId
 * @param {TaskWithKey} task
 */
export async function getThisAndFollowingRepeatingTasks(
  companyId: string | undefined,
  task: TaskWithKey,
): Promise<firebase.firestore.QuerySnapshot> {
  const repId = task.repeatingTaskId;
  return await getTasksTableRef(companyId)
    .where('repeatingTaskId', '==', repId)
    .where('start', '>=', task.start)
    .get();
}

export async function getAllRepeatingTasks(
  companyId: string | undefined,
  task: TaskWithKey,
): Promise<firebase.firestore.QuerySnapshot> {
  const repId = task.repeatingTaskId;
  return await getTasksTableRef(companyId)
    .where('repeatingTaskId', '==', repId)
    .get();
}

export async function getShiftTasks(
  companyId: string | undefined,
  task: TaskWithKey,
): Promise<firebase.firestore.QuerySnapshot> {
  const shiftId = task.shiftId;
  return await getTasksTableRef(companyId)
    .where('shiftId', '==', shiftId)
    .get();
}

export async function getAllRepeatingShiftTasks(
  companyId: string | undefined,
  task: TaskWithKey,
): Promise<firebase.firestore.QuerySnapshot> {
  const repeatingShiftId = task.repeatingShiftId;
  return await getTasksTableRef(companyId)
    .where('repeatingShiftId', '==', repeatingShiftId)
    .get();
}

export function getRepeatCount(
  repetition: Repetition,
  startDate: Moment,
  endDate: Moment,
) {
  let repeatCount: number = 0;
  if (endDate > startDate) {
    if (repetition.type === 'wd') {
      repeatCount = countWorkdaysBetween(startDate, endDate);
    }
    if (repetition.type === 'd') {
      repeatCount = countDaysBetween(startDate, endDate);
    }
    if (repetition.type === 'w') {
      repeatCount = countWeeksBetween(startDate, endDate);
    }
    if (repetition.type === 'm') {
      repeatCount = countMonthsBetween(startDate, endDate);
    }
    if (repeatCount < 1) {
      repeatCount = 1;
    }
    return repeatCount;
  } else {
    return 0;
  }
}

export function countDaysBetween(start: Moment, end: Moment) {
  let startDate = moment(start).startOf('day');
  let endDate = moment(end).startOf('day');

  if (
    startDate.dayOfYear() === endDate.dayOfYear() &&
    startDate.year() === endDate.year()
  ) {
    return 0;
  }

  if (endDate.isBefore(startDate)) {
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  return endDate.diff(startDate, 'days') + 1;
}

export function countWeeksBetween(start: Moment, end: Moment) {
  let startDate = moment(start).startOf('day');
  let endDate = moment(end).startOf('day');

  if (
    startDate.dayOfYear() === endDate.dayOfYear() &&
    startDate.year() === endDate.year()
  ) {
    return 0;
  }

  if (endDate.isBefore(startDate)) {
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  const weeks = Math.floor(1 + endDate.diff(startDate, 'day') / 7);

  return weeks;
}

export function countMonthsBetween(start: Moment, end: Moment) {
  let startDate = moment(start).startOf('day');
  let endDate = moment(end).startOf('day');

  if (
    startDate.dayOfYear() === endDate.dayOfYear() &&
    startDate.year() === endDate.year()
  ) {
    return 0;
  }

  if (endDate.isBefore(startDate)) {
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  const months = Math.floor(1 + endDate.diff(startDate, 'day') / 30);

  return months;
}

export function countWorkdaysBetween(start: Moment, end: Moment) {
  let startDate = moment(start).startOf('day');
  let endDate = moment(end).startOf('day');
  // EDIT : start at 1
  let adjust = 1;

  if (
    startDate.dayOfYear() === endDate.dayOfYear() &&
    startDate.year() === endDate.year()
  ) {
    return 0;
  }

  if (endDate.isBefore(startDate)) {
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  // Check if first date starts on weekends
  if (startDate.day() === 6) {
    // Saturday
    // Move date to next week monday
    startDate.day(8);
  } else if (startDate.day() === 0) {
    // Sunday
    // Move date to current week monday
    startDate.day(1);
  }

  // Check if second date starts on weekends
  if (endDate.day() === 6) {
    // Saturday
    // Move date to current week friday
    endDate.day(5);
  } else if (endDate.day() === 0) {
    // Sunday
    // Move date to previous week friday
    endDate.day(-2);
  }

  const day1Week = startDate.week();
  let day2Week = endDate.week();

  // Check if two dates are in different week of the year
  if (day1Week !== day2Week) {
    // Check if second date's year is different from first date's year
    if (day2Week < day1Week) {
      day2Week += day1Week;
    }
    // Calculate adjust value to be substracted from difference between two dates
    adjust += -2 * (day2Week - day1Week);
  }

  return endDate.diff(startDate, 'days') + adjust;
}

export const chunkArray = (array: any[], chunkSize: number): any[][] => {
  const results: any[][] = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }

  return results;
};
