import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { CSVLink } from 'react-csv';

import { i18n } from '@shared/locale';
import { CsvHeader, HourTypeWithKey, MemberWithKey } from '@shared/schema';
import { WorkTimeReports } from '@shared/schema/reports';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import LoadingSpinner from 'components/LoadingSpinner';
import styles from './styles';

export interface CsvExportDialogProps extends StyledComponentProps {
  reports: WorkTimeReports;
  selectedMembers?: MemberWithKey[];
  selectedMember?: MemberWithKey;
  hourTypes: HourTypeWithKey[];
  closeGenerateCsv: () => void;
}

interface State {
  generatingCsv: boolean;
  generatedCsvData: SingleMemberCsvRow[] | MultiMemberCsvRow[] | undefined;
  generatedCsvHeaders: CsvHeader[] | undefined;
}

interface HourTypeCsvRow {
  [hourTypeKey: string]: string;
}

type MultiMemberCsvRow = HourTypeCsvRow & {
  memberName: string;
};

type SingleMemberCsvRow = HourTypeCsvRow & {
  day: string;
  totalHours: string;
  hasChanges: string;
};

export class CsvExportDialog extends React.Component<
  CsvExportDialogProps,
  State
> {
  constructor(props: CsvExportDialogProps) {
    super(props);

    this.state = {
      generatingCsv: true,
      generatedCsvData: undefined,
      generatedCsvHeaders: undefined,
    };
  }

  public componentDidMount() {
    this.generateCsv();
  }

  public render() {
    const { selectedMember, classes = {}, closeGenerateCsv } = this.props;
    const { generatedCsvData, generatedCsvHeaders, generatingCsv } = this.state;

    return (
      <div>
        <Dialog open={true} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            {generatingCsv ? (
              <div>
                <LoadingSpinner />
                <h3 className={classes.heading}>
                  ...{i18n().ui.generating_csv}
                </h3>
              </div>
            ) : (
              <div>
                <h3 className={classes.heading}>
                  {i18n().ui.generating_csv_done}
                </h3>
                <h4 className={classes.heading}>
                  {i18n().ui.press_to_export_csv}
                </h4>
                <CSVLink
                  data-test="csvlink"
                  data={generatedCsvData}
                  headers={generatedCsvHeaders}
                  className={classNames(classes.downloadLink)}
                  filename={
                    selectedMember
                      ? `${selectedMember.name}_WorkTimeReport.csv`
                      : 'WorkTimeReport.csv'
                  }
                  separator={','}
                >
                  <div className={classes.exportCsvButton}>
                    <Button
                      onClick={closeGenerateCsv}
                      className={classNames(classes.button)}
                      color="primary"
                    >
                      <ImportExport />
                      {i18n().ui.export_csv}
                    </Button>
                  </div>
                </CSVLink>
              </div>
            )}
            <DialogActions>
              <Button onClick={closeGenerateCsv} color="primary">
                {i18n().ui.close}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  /**
   * Generates CSV for either multiple or single member
   */
  private generateCsv = () => {
    const { selectedMember } = this.props;

    const generatedCsvHeaders = selectedMember
      ? this.generateSingleMemberCsvHeaders()
      : this.generateMultipleMembersCsvHeaders();

    const generatedCsvData = selectedMember
      ? this.generateSingleMemberCsvData()
      : this.generateMultipleMembersCsvData();

    // Set timeout is for default timer for creating csv
    // because otherwise small csv will flash dialog loadingSpinner
    setTimeout(() => {
      this.setState({
        generatingCsv: false,
        generatedCsvHeaders,
        generatedCsvData,
      });
    }, 1000);
  };

  /**
   * Generate CSV headers for multiple members
   */
  private generateMultipleMembersCsvHeaders = (): CsvHeader[] => [
    {
      label: i18n().ui.name,
      key: 'memberName',
    },

    ...this.props.hourTypes.map(hourType => ({
      label: hourType.name,
      key: hourType.key || '',
    })),
  ];

  /**
   * Generates CSV data for multiple members
   */
  private generateMultipleMembersCsvData = (): MultiMemberCsvRow[] => {
    const { reports, selectedMembers, hourTypes } = this.props;

    if (!selectedMembers) {
      return [];
    }

    const zeroTime = momentDurationFormat(0, DurationFormatPreset.HHMM);

    return selectedMembers.map(({ name: memberName, key: memberKey }) => {
      const totals: { [hourTypeKey: string]: number } = {};

      if (reports && memberKey && reports[memberKey]) {
        for (const { data } of reports[memberKey].ALL) {
          for (const { hourtype, time } of data) {
            if (hourtype && time) {
              if (totals[hourtype] === undefined) {
                totals[hourtype] = 0;
              }
              totals[hourtype] += time;
            }
          }
        }
      }

      const memberRow: MultiMemberCsvRow = {
        memberName,
      };

      for (const { key } of hourTypes) {
        if (key) {
          memberRow[key] = totals[key]
            ? momentDurationFormat(totals[key], DurationFormatPreset.HHMM)
            : zeroTime;
        }
      }

      return memberRow;
    });
  };

  /**
   * Generates CSV headers for single member data
   */
  private generateSingleMemberCsvHeaders = (): CsvHeader[] => [
    {
      label: i18n().ui.day,
      key: 'day',
    },

    ...this.props.hourTypes.map(hourType => ({
      label: hourType.name,
      key: hourType.key || '',
    })),

    {
      label: i18n().ui.total,
      key: 'totalHours',
    },
    {
      label: i18n().ui.change,
      key: 'hasChanges',
    },
  ];

  /**
   * Generates CSV headers for multiple members data
   */
  private generateSingleMemberCsvData = (): SingleMemberCsvRow[] => {
    const { hourTypes, reports, selectedMember } = this.props;

    if (
      !selectedMember ||
      !reports ||
      !selectedMember.key ||
      !reports[selectedMember.key]
    ) {
      return [];
    }

    const zeroTime = momentDurationFormat(0, DurationFormatPreset.HHMM);

    return reports[selectedMember.key].ALL.map(
      ({ key: workingHoursKey, data, changeLog }) => {
        const dayRow: SingleMemberCsvRow = {
          day: moment.utc(workingHoursKey).format('DD.MM.YYYY'),
          hasChanges: changeLog && changeLog.length > 0 ? 'x' : '',
          totalHours: zeroTime,
        };

        for (const { key } of hourTypes) {
          if (key) {
            dayRow[key] = zeroTime;
          }
        }

        let totalTime = 0;

        for (const { hourtype, time } of data) {
          if (hourtype && time && dayRow[hourtype] !== undefined) {
            totalTime += time;
            dayRow[hourtype] = momentDurationFormat(
              time,
              DurationFormatPreset.HHMM,
            );
          }
        }

        dayRow.totalHours = momentDurationFormat(
          totalTime,
          DurationFormatPreset.HHMM,
        );

        return dayRow;
      },
    );
  };
}

export default withStyles(styles, { withTheme: true })(CsvExportDialog);
