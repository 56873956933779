import { Grid, WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import {
  MemberWithKey,
  Schema,
  Settings,
  TaskWithKey,
} from '@shared/schema/index';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { getTasksTableRef } from 'utils/tasksUtil';
import ChatTaskListContainer from '../components/ChatTaskList/ChatTaskListContainer';
import ChatViewContainer from '../components/ChatView/ChatViewContainer';
import styles from './styles';

export interface ChatGroupContainerProps
  extends DispatchProp<any>,
  StyledComponentProps,
  WithTheme {
  companyId: string;
  firebaseUid: string;
  settings: Settings;
}

export interface State {
  members: MemberWithKey[];
  chatTasks: TaskWithKey[];
  openTask?: TaskWithKey;
}

export class ChatGroupContainer extends React.Component<
  ChatGroupContainerProps,
  State
  > {
  private unsubscribeTasks: () => void;

  constructor(props: ChatGroupContainerProps) {
    super(props);

    this.state = {
      members: [],
      chatTasks: [],
      openTask: undefined,
    };
  }

  public componentDidMount = async () => {
    const { companyId } = this.props;

    this.unsubscribeTasks = getTasksTableRef(companyId)
      .where('hasReadChat.timestamp', '>', 0)
      .where('archived', '==', false)
      .orderBy('hasReadChat.timestamp', 'desc')
      .onSnapshot(snapshot => {
        const chatTasks: TaskWithKey[] = collectionToJsonWithIds(snapshot);
        this.setState({ chatTasks });
      });

    await this.getMemberDataForChatList();
  };

  public componentWillUnmount = () => {
    this.unsubscribeTasks && this.unsubscribeTasks();
  };

  public render() {
    const { classes = {}, companyId, firebaseUid, settings } = this.props;
    const { members, chatTasks, openTask } = this.state;

    const firebaseMember = this.getMember(firebaseUid);
    const taskMember = openTask && this.getMember(openTask.userId!);

    return (
      <Grid
        container
        direction="row"
        justify="center"
        spacing={8}
        className={classes.groupContainer}
      >
        <Grid item xs className={classes.taskGridContainer}>
          <ChatTaskListContainer
            companyId={companyId}
            firebaseUid={firebaseUid}
            chatMembers={members}
            chatTasks={chatTasks}
            openTaskId={this.handleSelectTask}
            settings={settings}
            data-test="chatTaskListContainer"
          />
        </Grid>
        <Grid item xs={9} className={classes.chatView}>
          {openTask && firebaseMember && (
            <ChatViewContainer
              companyId={companyId}
              taskMember={taskMember}
              editedTask={openTask}
              appUser={firebaseMember}
              data-test="chatViewContainer"
            />
          )}
        </Grid>
      </Grid>
    );
  }

  /**
   * Returns all members form companys member collection and sets them to state
   */
  private getMemberDataForChatList = async () => {
    const { companyId } = this.props;

    const result = await firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.MEMBERS)
      .get();

    const members: MemberWithKey[] = collectionToJsonWithIds(result);
    this.setState({
      members,
    });
  };

  /**
   * Finds opened task from chatTask list with id. If found set it to state.
   * @param {string} selectedTaskId
   */
  private handleSelectTask = (selectedTaskId: string) => {
    const { chatTasks } = this.state;

    const openTask = chatTasks.find(task => task.key === selectedTaskId);

    if (openTask) {
      this.setState({
        openTask,
      });
    }
  };

  /**
   * Returns member from members list if it is found otherwise returns undefined.
   * @param {string} memberId
   */
  private getMember = (memberId: string) => {
    return this.state.members.find(member => member.key === memberId);
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ChatGroupContainerProps>,
) => {
  return {
    ...ownProps,
    appUser: state.auth.appUser,
    settings: state.company.activeCompany.settings,
  };
};

export default withRouter<any>(
  withStyles(styles, { withTheme: true })(
    connect<any>(mapStateToProps)(ChatGroupContainer),
  ),
);
