import { StyledComponentProps, withStyles } from '@material-ui/core';
import * as React from 'react';
import styles from './styles';

export interface ClusterMarkerProps extends StyledComponentProps {
  onClick?: () => void;
  clusteredItemCount: number;
  lat: number;
  lng: number;
}

export class ClusterMarkerComponent extends React.Component<
  ClusterMarkerProps
> {
  constructor(props: ClusterMarkerProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { classes = {} } = this.props;

    return (
      <div className={classes.outerCircle} onClick={this.props.onClick}>
        <div className={classes.innerCircle} data-test="clusterCount">
          {this.props.clusteredItemCount}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClusterMarkerComponent);
