import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    border: 0,
    padding: 0,
    margin: 0,
    boxShadow: 'none',
  },
  repetitionEditorContainer: {
    width: 'auto',
  },
  field: {
    width: '100%',
    minWidth: '300px',
    minHeight: '40px',
  },
  disabledField: {
    margin: '0',
    color: 'grey',
    fontSize: '16px',
  },
  summary: {
    display: 'flex',
  },
  content: {
    transition: 'height 3s',
    transitionTimingFunction: 'ease-in-out',
    overflowY: 'hidden',
  },
  closed: {
    height: 0,
    overflowY: 'hidden',
    transition: 'height 3s',
    transitionTimingFunction: 'ease-in-out',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  formControlSmall: {
    margin: theme.spacing.unit,
    width: 40,
  },
});
