import {
  Paper,
  StyledComponentProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import * as React from 'react';
import styles from './styles';

export interface PopperNotePubleProps extends StyledComponentProps {
  note?: string;
}

/**
 * Renders PopperNotePuble with notes
 * @param props
 */
export const PopperNotePuble = (props: PopperNotePubleProps) => {
  const { classes = {}, note } = props;

  return (
    <Paper elevation={1} className={classes.paper}>
      <Typography variant="h5" component="h5">
        {i18n().ui.notes_and_notices}:
      </Typography>
      <Typography component="p" data-test="note">
        {note}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(PopperNotePuble);
