import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  memberPubble: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0.5rem',
    margin: '5px 10px',
    alignItems: 'center',
    minHeight: '40px',
    backgroundColor: '#EDF5DC',
    borderRadius: '40px',
    justifyContent: 'space-between',
  },
  memberName: {
    margin: '5px',
  },
  clearIconBackground: {
    display: 'flex',
    backgroundColor: '#99CA3C',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '45px',
  },
  IconButton: {
    padding: '0px',
  },
});

export default styles;
