import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '30px',
    backgroundColor: theme.colors.lightGrey,
  },
  userSettingsHeader: {
    flex: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    minHeight: '100%',
  },
  avatar: {
    margin: '25px 50px 0px 50px',
    width: 200,
    height: 200,
  },
  grid: {
    flex: 1,
  },
  userName: {
    flex: 1,
    marginBottom: '20px',
    marginTop: '20px',
  },
  userInfoAndActionsComponent: {
    flex: 10,
    height: '100%',
    paddingTop: 20,
  },
  userSettingsInfoList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  userSettingsInfoListItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  circleLoader: {
    marginLeft: 5,
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changePwButton: {
    backgroundColor: '#99CA3C',
  },
  changePwTextColor: {
    color: '#99CA3C',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    minWidth: '250px',
  },
  formMargin: {
    marginBottom: '15px',
  },
});

export default styles;
