// import { ThunkAction } from "redux-thunk";
import {
  ChatIconState,
  CompanyId,
  Member,
  MemberWithKey,
  Schema,
  Task,
  TaskDialogState,
  TaskStatus,
  TaskTimer,
  TaskWithKey,
  UserId,
} from '@shared/schema';
import { sumTotalTimerTime } from '@shared/utils/timeUtils';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import moment from 'moment';
import { ActionCreator, AnyAction } from 'redux';
import { getTasksTableRef } from 'utils/tasksUtil';

export type TaskCallback = (event: Task) => void;
export type MemberCallback = (res: Member) => void;

export const enum TaskErrorType {
  TASK_NOT_FOUND = 'TASK_NOT_FOUND',
  ANOTHER_TIMER_IS_ALREADY_RUNNING = 'ANOTHER_TIMER_IS_ALREADY_RUNNING',
  FIRESTORE_ERROR = 'FIRESTORE_ERROR',
  MISSING_ARGUMENT = 'MISSING_ARGUMENT',
  UNEXPECTED_STATUS = 'UNEXPECTED_STATUS',
}
export interface TaskError {
  type: TaskErrorType;
  message: string;
  errorObject?: any;
}

export const enum TaskActionType {
  TASK_NEW = 'TASK_NEW',
  TASK_EDIT = 'TASK_EDIT',
  TASK_CANCEL_EDIT = 'TASK_CANCEL_EDIT',
  TASK_CREATE = 'TASK_CREATE',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST',
  TASK_CANCEL_DELETE = 'TASK_CANCEL_DELETE',
  TASK_MOVE = 'TASK_MOVE',
  TASK_PARAM_CHANGE = 'TASK_PARAM_CHANGE',
  TASK_SET_DIALOG_STATE = 'TASK_SET_DIALOG_STATE',
  TASK_SET_CHAT_ICON_STATE = 'TASK_SET_CHAT_ICON_STATE',
}

export interface TaskNewAction extends AnyAction {
  type: TaskActionType.TASK_NEW;
  payload: {
    companyId: CompanyId;
    userId?: UserId;
    startDateTime?: moment.Moment;
    resourceEvent: null;
  };
}

export const newResourceEvent: ActionCreator<TaskNewAction> = (
  companyId: string,
  userId?: string,
  startDateTime?: any,
) => {
  return {
    type: TaskActionType.TASK_NEW,
    payload: {
      companyId,
      userId,
      startDateTime,
      resourceEvent: null,
    },
  };
};

export interface TaskEditAction extends AnyAction {
  type: TaskActionType.TASK_EDIT;
  payload: {
    companyId: CompanyId;
    resourceEvent: Task;
  };
}

export const editResourceEvent: ActionCreator<TaskEditAction> = (
  companyId: string,
  task: Task,
) => {
  return {
    type: TaskActionType.TASK_EDIT,
    payload: {
      companyId,
      resourceEvent: task,
    },
  };
};

export interface TaskCancelEditAction extends AnyAction {
  type: TaskActionType.TASK_CANCEL_EDIT;
}

export const cancelEdit: ActionCreator<TaskCancelEditAction> = () => ({
  type: TaskActionType.TASK_CANCEL_EDIT,
});

export interface TaskCreateAction extends AnyAction {
  type: TaskActionType.TASK_CREATE;
}

export const createResourceEvent: ActionCreator<TaskCreateAction> = (
  companyId: string,
  task: Task,
  presetKey?: string,
) => {
  // TODO error handling
  if (companyId && task && !presetKey) {
    getTasksTableRef(companyId)
      .add(task)
      .catch((error: Error) => {
        console.error(error.message);
      });
  } else if (companyId && task && presetKey) {
    getTasksTableRef(companyId)
      .doc(presetKey)
      .set(task)
      .catch((error: Error) => {
        console.error(error.message);
      });
  } else {
    console.error('Things failed @ createResourceEvent eventActions');
  }

  return {
    type: TaskActionType.TASK_CREATE,
    payload: {},
    key: presetKey,
  };
};

export interface UpdateResourceEventAction extends AnyAction {
  type: TaskActionType.TASK_UPDATE;
}

export const updateResourceEvent: ActionCreator<UpdateResourceEventAction> = (
  companyId: string,
  aNewResourceEvent: Task,
) => {
  // TODO error handling
  updateResourceActiveResourceEventInfo(companyId, aNewResourceEvent);

  if (aNewResourceEvent.desc === undefined) {
    delete aNewResourceEvent.desc;
  }

  return {
    type: TaskActionType.TASK_UPDATE,
    payload: {},
  };
};

export const updateRepeatingResourceEvent: ActionCreator<UpdateResourceEventAction> = (
  companyId: string,
  aNewResourceEvent: Task[],
) => {
  // TODO error handling

  aNewResourceEvent.forEach(task => {
    updateResourceActiveResourceEventInfo(companyId, task);

    if (task.desc === undefined) {
      delete task.desc;
    }
  });

  return {
    type: TaskActionType.TASK_UPDATE,
    payload: {},
  };
};

/**
 * Update task to firestore
 *
 * @param {string} companyId
 * @param {Task} aNewTask
 * @returns {Promise<void>}
 */
async function updateFirestoreTask(
  companyId: string,
  aNewTask: Task,
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await getTasksTableRef(companyId).doc(aNewTask.id).update(aNewTask);

      resolve();
    } catch (e) {
      console.error('error updating task, ', e);
      reject(e);
    }
  });
}

/**
 * Tries get member from database with passed company id and member id
 *
 * @param {string} companyId
 * @param {string | undefined} uid
 * @returns {Promise<MemberWithKey>}
 */
async function loadFirestoreMember(
  companyId: string,
  uid: string | undefined,
): Promise<MemberWithKey> {
  return new Promise<MemberWithKey>(async (resolved, rejected) => {
    const memberDocRef = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.MEMBERS)
      .doc(uid);

    try {
      const snapshot = await memberDocRef.get();

      if (snapshot.exists) {
        const member = snapshot.data() as MemberWithKey;
        member.key = snapshot.id;
        resolved(member);
      } else {
        rejected('not found');
      }
    } catch (error) {
      console.error(error);
      rejected(error);
    }
  });
}

// TODO ActionCreator and action
export function deleteResourceEvent(
  companyId: string | undefined,
  eventId: string,
) {
  getTasksTableRef(companyId).doc(eventId).delete();

  return {
    type: TaskActionType.TASK_DELETE,
    payload: {},
  };
}

export function deleteResourceEventRequest(
  deleteTask: TaskWithKey,
  deleteEventId: string,
  deleteEventTitle: string,
) {
  return {
    type: TaskActionType.TASK_DELETE_REQUEST,
    payload: {
      deleteTask,
      deleteEventId,
      deleteEventTitle,
    },
  };
}

export function deleteResourceEventCancel() {
  return {
    type: TaskActionType.TASK_CANCEL_DELETE,
    payload: {},
  };
}

// TODO ActionCreator and Action
export function moveResourceEvent(
  companyId: CompanyId,
  id: string,
  userId: UserId,
  start: number,
  end: number,
) {
  if (!companyId) {
    console.error('Company id is not set.');
    return;
  }
  if (!userId) {
    console.error('userId id is not set.');
    return;
  }

  try {
    getTasksTableRef(companyId)
      .doc(id)
      .update({
        duration: end - start,
        userId,
        start,
        end,
      });
  } catch (err) {
    console.error('Update duration time error: ', err);
  }

  return {
    type: TaskActionType.TASK_MOVE,
    payload: {},
  };
}

/**
 * Updates task status correspondingly
 *
 * @param {string} companyId
 * @param {Task} aNewTask Task with all updates
 * @param {Task} currentTask Task without all updates
 * @returns { Promise<Task>}
 */
function updateTaskState(
  companyId: string,
  aNewTask: Task,
  currentTask: Task,
): Promise<Task> {
  return new Promise<Task>(async (resolve, reject) => {
    const currentStatus: TaskStatus = currentTask.status;
    const newStatus: TaskStatus = aNewTask.status;
    if (currentStatus === newStatus) {
      resolve(aNewTask);
      return;
    }

    try {
      if (aNewTask.userId !== '') {
        const member = await loadFirestoreMember(companyId, aNewTask.userId);

        switch (newStatus) {
          case TaskStatus.UNDONE: {
            /**
             * what to do when resource event becomes back to undone?
             */
            const updatedTask = await closeTimerForTask(
              companyId,
              member,
              aNewTask,
            );

            resolve(updatedTask);
            break;
          }

          case TaskStatus.ACTIVE: {
            const updatedTask = await activateTimerForTask(
              companyId,
              member,
              aNewTask,
            );

            resolve(updatedTask);
            break;
          }

          case TaskStatus.PAUSED:
          case TaskStatus.DONE: {
            const updatedTask = await closeTimerForTask(
              companyId,
              member,
              aNewTask,
            );

            resolve(updatedTask);
            break;
          }

          default: {
            reject({
              type: 'ILLEGAL_ARGUMENT',
              message: `Unidentified resource event status: ${newStatus}`,
            });
          }
        }
      } else {
        resolve(aNewTask);
        return;
      }
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

/**
 * Stops activeTimer for a task if it is running. Also, updating timerLog with activeTimer and fully deleting activeTimer
 * Updating related member by deleting activeTask
 *
 * @param {string} companyId
 * @param {Member} member
 * @param {Task} task
 * @returns {Promise<Task>}
 */
/* TODO Unit tests for this function more importantly timerLog and activeTimer */
function closeTimerForTask(
  companyId: string,
  member: Member,
  task: Task,
): Promise<Task> {
  return new Promise<Task>(async (resolve, reject) => {
    if (task.activeTimer) {
      task.activeTimer.ended = moment().valueOf();
      if (task.userId !== '') {
        // It is very unlikely that user has no ID
        task.activeTimer.memberId = task.userId || '';
      }
      if (!task.timerLog) {
        task.timerLog = [];
      }
      task.timerLog.push(task.activeTimer);

      task.timerTotal = sumTotalTimerTime(task.timerLog);

      task.activeTimer = firebase.firestore.FieldValue.delete() as any; // TODO typing
    }

    await updateFirestoreResource(task.userId, companyId, {
      activeTask: firebase.firestore.FieldValue.delete() as any, // TODO typing,
    });
    updateFirestoreTask(companyId, task);
    resolve(task);
  });
}

// function sendResourceError(resourceEventError: ResourceEventError) {
//   console.error(`${resourceEventError.type}: ${resourceEventError.message}`);
// }

function updateFirestoreResource(
  uid: string | undefined,
  companyId: CompanyId,
  mergedFields: Partial<Member>,
): Promise<void> {
  const memberDocRef = firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.MEMBERS)
    .doc(uid);

  return new Promise<void>(async (resolve, reject) => {
    try {
      await memberDocRef.set({ ...mergedFields }, { merge: true });
      resolve();
    } catch (e) {
      console.error('error updating member, ', e);
      reject(e);
    }
  });
}

/**
 * Tries get task from database with passed company id and task id
 *
 * @param {string | undefined} companyId
 * @param {string} taskId
 * @returns { Promise<Task>}
 */
function loadFirestoreTask(
  companyId: string | undefined,
  taskId: string,
): Promise<Task> {
  return new Promise<Task>(async (resolve, reject) => {
    const eventDocRef = getTasksTableRef(companyId).doc(taskId);

    try {
      const snapshot = await eventDocRef.get();
      if (snapshot.exists) {
        const task = snapshot.data() as Task;
        task.id = snapshot.id;

        resolve(task);
      } else {
        reject('not found');
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

function activateTimerForResourceEventPlain(
  companyId: string,
  userId: string,
  aNewResourceEvent: Task,
): Promise<Task> {
  return new Promise<Task>(async (resolve, reject) => {
    const activeTimer: TaskTimer = {
      started: moment().valueOf(),
      memberId: userId,
    };
    aNewResourceEvent.activeTimer = activeTimer;

    try {
      await updateFirestoreTask(companyId, aNewResourceEvent);
      if (aNewResourceEvent.vat) {
        delete aNewResourceEvent.vat;
      }
      await updateFirestoreResource(aNewResourceEvent.userId, companyId, {
        activeTask: aNewResourceEvent,
      });
    } catch (e) {
      console.error(e);
    }
  });
}

/**
 * Checks that user for a task does not have any activeTask in database, if there is an activeTask, then pausing it and deleting active timer.
 * Activates timer for a desired task
 *
 * @param {string} companyId
 * @param {MemberWithKey} member
 * @param {Task} aNewTask
 * @returns {Promise<Task>}
 */
function activateTimerForTask(
  companyId: string,
  member: MemberWithKey,
  aNewTask: Task,
): Promise<Task> {
  return new Promise<Task>(async (resolve, reject) => {
    if (aNewTask.status !== TaskStatus.ACTIVE) {
      reject({
        type: 'UNEXPECTED_STATUS',
        message: `Timer activation called when state is ${aNewTask.status}`,
      });
      return;
    }

    const activeEvent = member.activeTask as Task;

    if (activeEvent && activeEvent.id !== aNewTask.id && activeEvent.id) {
      const oldActiveTask = await loadFirestoreTask(companyId, activeEvent.id);
      // Set task to pause only if it is actually ACTIVE. F.e. if in reality it is in DONE status, the no need to make it paused.
      if (oldActiveTask) {
        if (oldActiveTask.status === TaskStatus.ACTIVE) {
          oldActiveTask.status = TaskStatus.PAUSED;
        }
        await closeTimerForTask(companyId, member, oldActiveTask);
      }
    }

    if (member.key) {
      await activateTimerForResourceEventPlain(companyId, member.key, aNewTask);
    }
  });
}

/**
 * Update user's active resource based on status change
 * @param {string} companyId
 * @param {Task} task
 */
function updateResourceActiveResourceEventInfo(
  companyId: string,
  task: Task,
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    if (!companyId) {
      reject({
        type: 'ILLEGAL_ARGUMENT',
        message: 'CompanyId was not defined',
      });
      return;
    }
    if (!task) {
      reject({
        type: 'ILLEGAL_ARGUMENT',
        message: 'task was not defined',
      });
      return;
    }

    if (!task.id) {
      reject({
        type: 'ILLEGAL_ARGUMENT',
        message: 'task has no id',
      });
      return;
    }

    try {
      // Get initial version of task without all updates for conditional code execution based on comparing updated and initial task versions.
      const currentTask = await loadFirestoreTask(companyId, task.id);

      /**
       * case 1: user's state changes from active to done
       */
      /**
       * case 2: user's state changes from active to undone
       */
      /**
       * case 3: resource event will be moved to another user
       */
      if (task.userId === currentTask.userId) {
        await updateTaskState(companyId, task, currentTask);
        await updateFirestoreTask(companyId, task);
      } else {
        if (task.userId === '' || task.userId === undefined) {
          await updateFirestoreTask(companyId, task);
          resolve();
        } else {
          switch (task.status) {
            case TaskStatus.UNDONE:
              /**
               * Easy switch... no need to update current user's state
               */
              await updateFirestoreTask(companyId, task);
              resolve();
              break;

            case TaskStatus.ACTIVE:
            case TaskStatus.PAUSED: {
              await updateFirestoreTask(companyId, task);
              await updateTaskState(companyId, task, currentTask);
              break;
            }
            case TaskStatus.DONE: {
              const newResource = await loadFirestoreMember(
                companyId,
                task.userId,
              );

              await closeTimerForTask(companyId, newResource, currentTask);
              await updateFirestoreTask(companyId, task);
              await updateTaskState(companyId, task, currentTask);

              break;
            }
            default:
              reject({
                type: 'ILLEGAL_ARGUMENT',
                message: `Unidentified resource event status: ${currentTask.status}`,
              });
          }
        }
      }
    } catch (e) {
      reject({
        type: 'FIRESTORE_ERROR',
        message: `Resource event not found. (error message: ${e.message}`,
        errorObject: e,
      });
    }
  });
}

export interface TaskParamChangeAction extends AnyAction {
  type: TaskActionType.TASK_PARAM_CHANGE;
  payload: {
    companyId: CompanyId;
    changedParameters: any;
  };
}

export const paramChange: ActionCreator<TaskParamChangeAction> = (
  changedTaskParameters: Partial<Task>,
  companyId: CompanyId,
) => {
  return {
    type: TaskActionType.TASK_PARAM_CHANGE,
    payload: {
      companyId,
      changedParameters: changedTaskParameters,
    },
  };
};

export interface TaskSetDialogStateAction extends AnyAction {
  type: TaskActionType.TASK_SET_DIALOG_STATE;
  payload: TaskDialogState;
}

export const setDialogState: ActionCreator<TaskSetDialogStateAction> = (
  newTaskDialogState: TaskDialogState,
) => {
  return {
    type: TaskActionType.TASK_SET_DIALOG_STATE,
    payload: newTaskDialogState,
  };
};

export interface TaskSetChatIconStateAction extends AnyAction {
  type: TaskActionType.TASK_SET_CHAT_ICON_STATE;
  payload: ChatIconState;
}

export const setChatIconState: ActionCreator<TaskSetChatIconStateAction> = (
  newChatIconState: ChatIconState,
) => {
  return {
    type: TaskActionType.TASK_SET_CHAT_ICON_STATE,
    payload: newChatIconState,
  };
};

export type EventAction =
  | AnyAction
  | TaskCancelEditAction
  | TaskEditAction
  | TaskNewAction
  | TaskCreateAction
  | TaskParamChangeAction
  | TaskSetDialogStateAction
  | TaskSetChatIconStateAction;
