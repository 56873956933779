import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { i18n } from '@shared/locale/index';
import {
  CompanyId,
  DialogStatus,
  Qualification,
  QualificationWithKey,
  Schema,
} from '@shared/schema';
import classNames from 'classnames';
import SettingsListItem from 'containers/Private/Settings/components/SettingsListItem';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import muiTheme from 'theme';
import styles from './styles';

interface QualificationsSettingProps extends StyledComponentProps {
  companyId: CompanyId | undefined;
}

interface State {
  qualifications: QualificationWithKey[];
  deleteQualification: QualificationWithKey | undefined;
  modifyQualification: QualificationWithKey | undefined;
  newQualification: Qualification | undefined;
  filter: string;
  selectMultiple: boolean;
  selectedItems: SelectedQualifications;
  deleteMultiple: boolean;
}

interface SelectedQualifications {
  [key: string]: boolean;
}

class QualificationsSettings extends React.Component<
  QualificationsSettingProps,
  State
> {
  private unsubscribeQualifications: () => void;

  constructor(props: QualificationsSettingProps) {
    super(props);
    this.state = {
      qualifications: [],
      newQualification: undefined,
      deleteQualification: undefined,
      modifyQualification: undefined,
      filter: '',
      selectMultiple: false,
      selectedItems: {},
      deleteMultiple: false,
    };
  }

  public componentDidMount() {
    const { companyId } = this.props;
    if (companyId) {
      this.unsubscribeQualifications = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.QUALIFICATIONS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const qualifications: Qualification[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const qualification = data.data() as QualificationWithKey;
            qualification.key = data.id;
            qualifications.push({
              ...qualification,
            });
          });

          qualifications.sort((a, b) => {
            if (!a.name.toLowerCase() || !b.name.toLowerCase()) {
              return 0;
            }
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });

          this.setState({
            qualifications,
          });
        });
    } else {
      console.error('Company id is not set @QualificationSettings');
    }
  }

  public componentWillUnmount() {
    this.unsubscribeQualifications && this.unsubscribeQualifications();
  }

  public render() {
    const { classes = {} } = this.props;
    const {
      qualifications,
      newQualification,
      modifyQualification,
      deleteQualification,
      filter,
      selectMultiple,
      selectedItems,
      deleteMultiple,
    } = this.state;

    return (
      <div
        className={classNames(classes.container)}
        style={{ color: muiTheme.palette.primary.main }}
      >
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.bar}>
            <FormControl className={classes.topFormControl}>
              <TextField
                id="search"
                placeholder={i18n().ui.search_qualifications}
                className={classes.searchInput}
                margin="normal"
                onChange={this.handleFilterChange()}
              />
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleAddNewQualification}
              >
                <AddIcon className={classes.icon} />
                {i18n().ui.add_new}
              </Button>
              <Button
                variant="contained"
                className={classNames(
                  classes.button,
                  selectMultiple ? classes.selected : '',
                )}
                onClick={this.handleSelectMultiple}
              >
                <DoneAllIcon className={classes.icon} />
                {i18n().ui.select_multiple}
              </Button>
              {selectMultiple && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleDeleteMultiple}
                >
                  <DeleteIcon className={classes.icon} />
                  {i18n().ui.delete_selected}
                </Button>
              )}
              {/* selectMultiple && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  <EyeIcon className={classes.icon} />
                  Piilota valitut
                </Button>
              ) */}
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.itemContainer}>
            <Grid container spacing={24}>
              {qualifications.map((item: QualificationWithKey) => {
                if (
                  filter.length === 0 ||
                  item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
                ) {
                  return (
                    <SettingsListItem
                      key={item.name}
                      id={item.key!}
                      name={item.name}
                      onModify={this.handleQualificationModify}
                      onDelete={this.handleQualificationDelete}
                      selectActive={selectMultiple}
                      onSelect={this.handleItemSelect}
                      selected={selectedItems[item.key!]}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={newQualification !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.add_qualification}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18n().ui.name}
              type="text"
              value={newQualification ? newQualification.name : ''}
              onChange={this.handleNewDialogNameChange()}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.NEW)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleNewDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={modifyQualification !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.modify_qualification}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18n().ui.name}
              type="text"
              value={modifyQualification ? modifyQualification.name : ''}
              onChange={this.handleModifyDialogNameChange()}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.MODIFY)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleModifyDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteQualification !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_qualification_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.DELETE)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteMultiple}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_selected_qualifications_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose(DialogStatus.DELETEMULTIPLE)}
            >
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteMultipleDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private handleNewDialogNameChange = () => (e: any) => {
    const newQualification: QualificationWithKey = this.state.newQualification
      ? { ...this.state.newQualification }
      : { name: '' };
    newQualification.name = e.target.value;
    this.setState({
      newQualification,
    });
  };
  private handleModifyDialogNameChange = () => (e: any) => {
    const modifyQualification: QualificationWithKey = this.state
      .modifyQualification
      ? { ...this.state.modifyQualification }
      : { name: '' };
    modifyQualification.name = e.target.value;

    this.setState({
      modifyQualification,
    });
  };

  private handleSelectMultiple = () => {
    this.setState({
      selectMultiple: !this.state.selectMultiple,
      selectedItems: {},
    });
  };

  private handleItemSelect = (qualificationKey: string, selected: boolean) => {
    const newSelectedItems = this.state.selectedItems;
    if (selected) {
      newSelectedItems[qualificationKey] = true;
    } else {
      delete newSelectedItems[qualificationKey];
    }
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  private handleFilterChange = () => (e: any) => {
    this.setState({
      filter: e.target.value,
    });
  };

  /**
   * Creating new object to newQualification to make the dialog visible to make new qualification
   */
  private handleAddNewQualification = () => {
    this.setState({
      newQualification: {
        name: '',
      },
    });
  };

  /**
   * Assigning selected elemento to state to be modified and the dialog to be visible
   */
  private handleQualificationModify = (qualifKey: string) => {
    this.setState({
      modifyQualification: this.getQualificationByKey(qualifKey),
    });
  };

  /**
   * Assigning selected elemento to state to be deleted and the dialog to be visible
   */
  private handleQualificationDelete = (qualifKey: string) => {
    this.setState({
      deleteQualification: this.getQualificationByKey(qualifKey),
    });
  };

  private handleDeleteMultiple = () => {
    this.setState({
      deleteMultiple: true,
    });
  };

  private handleNewDialogConfirm = () => {
    const { newQualification } = this.state;
    if (newQualification) {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.QUALIFICATIONS)
        .add(newQualification)
        .then(() => {
          this.setState({
            newQualification: undefined,
          });
        })
        .catch(error => {
          console.error(
            'Creating new qualification failed for some magical reason',
            error,
          );
        });
    }
  };

  private handleModifyDialogConfirm = () => {
    const { modifyQualification } = this.state;
    if (modifyQualification && modifyQualification.key) {
      const modifiedQualification: Qualification = {
        id: modifyQualification.key,
        name: modifyQualification.name,
      };
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.QUALIFICATIONS)
        .doc(modifyQualification.key)
        .update(modifiedQualification)
        .then(() => {
          this.setState({
            modifyQualification: undefined,
          });
        })
        .catch(error => {
          console.error('Modify failed for some magical reason', error);
        });
    }
  };

  private handleDeleteDialogConfirm = () => {
    const { deleteQualification } = this.state;
    if (deleteQualification && deleteQualification.key) {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.QUALIFICATIONS)
        .doc(deleteQualification.key)
        .delete()
        .then(() => {
          this.setState({
            deleteQualification: undefined,
          });
        })
        .catch(error => {
          console.error('Delete failed for some magical reason', error);
        });
    }
  };

  private handleDeleteMultipleDialogConfirm = () => {
    const { selectedItems } = this.state;
    const batch = firebaseApp.firestore().batch();

    for (const key in selectedItems) {
      if (selectedItems[key]) {
        const itemRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.QUALIFICATIONS)
          .doc(key);

        batch.delete(itemRef);
      }
    }

    batch
      .commit()
      .then(() => {
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      })
      .catch(error => {
        console.error(
          'Something went wrong while deleting multiple @ QualificationsSettings',
          error,
        );
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      });
  };

  /**
   * Handling dialog closes
   */
  private handleDialogClose = (dialogStatus: DialogStatus) => () => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        this.setState({
          newQualification: undefined,
        });
        break;
      case DialogStatus.MODIFY:
        this.setState({
          modifyQualification: undefined,
        });
        break;
      case DialogStatus.DELETE:
        this.setState({
          deleteQualification: undefined,
        });
        break;
      case DialogStatus.DELETEMULTIPLE:
        this.setState({
          deleteMultiple: false,
        });
        break;
    }
  };

  private getQualificationByKey = (key: string) => {
    for (const qualification of this.state.qualifications) {
      if (qualification.key === key) {
        return qualification;
      }
    }
    return undefined;
  };
}

export default withStyles(styles, { withTheme: true })(QualificationsSettings);
