import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  tabsBar: {
    background: '#E5E5E5',
  },
});

export default styles;
