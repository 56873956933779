import { CompanyId, Schema, User } from '@shared/schema';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import { ActionCreator, AnyAction } from 'redux';
import store from 'store';
import { isServerSide } from 'utils/devUtils';

let unsubscribeUser: () => void;

export const enum AuthActionType {
  /**
   * User state on firebase auth changed. Hapens on sign in and out
   */
  AUTH_FIREBASE_USER_STATE_CHANGED = 'AUTH_FIREBASE_USER_STATE_CHANGED',
  /**
   * Update last error to state
   */
  AUTH_FIREBASE_SET_ERROR = 'AUTH_FIREBASE_SET_ERROR',
  /**
   * Set authentication status
   */
  AUTH_FIREBASE_SET_AUTHENTICATING = 'AUTH_FIREBASE_SET_AUTHENTICATING',
  /**
   * Trigger when app specific user info has changed or loaded.
   */
  AUTH_APP_USER_INFO_UPDATED = 'AUTH_APP_USER_INFO_UPDATED',
  /**
   * User has switched between his or her active organization.
   */
  AUTH_APP_USER_ACTIVE_ORG_CHANGED = 'AUTH_APP_USER_ACTIVE_ORG_CHANGED',
  /**
   * User has opened registration form
   */
  AUTH_OPEN_REGISTRATION = 'AUTH_OPEN_REGISTRATION',
}

export interface FirebaseUserAuthStateChangedAction extends AnyAction {
  type: AuthActionType.AUTH_FIREBASE_USER_STATE_CHANGED;
  payload: firebase.User | undefined;
}

export interface AppUserAuthStateChangedAction extends AnyAction {
  type: AuthActionType.AUTH_APP_USER_INFO_UPDATED;
  payload: User | undefined;
}

export interface AppUserActiveOrganizationChangedAction extends AnyAction {
  type: AuthActionType.AUTH_APP_USER_ACTIVE_ORG_CHANGED;
  payload: CompanyId;
}

export interface FirebaseAuthSetErrorAction extends AnyAction {
  type: AuthActionType.AUTH_FIREBASE_SET_ERROR;
  payload: firebase.auth.Error;
}

export interface FirebaseAuthSetAuthenticatingAction extends AnyAction {
  type: AuthActionType.AUTH_FIREBASE_SET_AUTHENTICATING;
  payload: boolean;
}

export interface AuthOpenRegistration extends AnyAction {
  type: AuthActionType.AUTH_OPEN_REGISTRATION;
  payload: boolean;
}

/**
 * Called when a authentication has triggered from firebase auth.
 *
 * @param user New user info. Contains authenticated user's info or if signed out the value will be undefined.
 */
export const firebaseUserStateChanged: ActionCreator<
  FirebaseUserAuthStateChangedAction
> = (user: firebase.User | undefined) => ({
  type: AuthActionType.AUTH_FIREBASE_USER_STATE_CHANGED,
  payload: user,
});

/**
 * Application
 *
 *  info updated in firestore
 *
 * @param user New application user info
 */
export const appUserStateChanged: ActionCreator<
  AppUserAuthStateChangedAction
> = (user: User | undefined) => ({
  type: AuthActionType.AUTH_APP_USER_INFO_UPDATED,
  payload: user,
});

export const appUserActiveOrganizationChanged: ActionCreator<
  AppUserActiveOrganizationChangedAction
> = (organizationId: CompanyId) => ({
  type: AuthActionType.AUTH_APP_USER_ACTIVE_ORG_CHANGED,
  payload: organizationId,
});

export const updateFirestoreAuthError: ActionCreator<
  FirebaseAuthSetErrorAction
> = (error: firebase.auth.Error) => ({
  type: AuthActionType.AUTH_FIREBASE_SET_ERROR,
  payload: error,
});

export const updateFirestoreAuthenticatingState: ActionCreator<
  FirebaseAuthSetAuthenticatingAction
> = (value: boolean) => ({
  type: AuthActionType.AUTH_FIREBASE_SET_AUTHENTICATING,
  payload: value,
});

export const openRegistration: ActionCreator<AuthOpenRegistration> = (
  registrationEnabled: boolean,
) => ({
  type: AuthActionType.AUTH_OPEN_REGISTRATION,
  payload: registrationEnabled,
});

if (!isServerSide()) {
  firebaseApp.auth().onAuthStateChanged((user: firebase.User) => {
    if (user) {
      unsubscribeUser = firebaseApp
        .firestore()
        .collection(Schema.USERS)
        .doc(user.uid)
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
          const userData: User = snapshot.data() as User;
          store.dispatch(appUserStateChanged(userData));
        });

      // Getting all required data.

      // firebaseApp
      //   .firestore()
      //   .collection(Schema.PENDING_USERS)
      //   .doc(user.email!)
      //   .get()
      //   .then((doc: firebase.firestore.DocumentSnapshot) => {
      //     const pendingUser = doc.data();

      //     if (pendingUser) {
      //       // Adding new User to users table

      //       const userToBeSaved = {
      //         displayName: pendingUser.displayName,
      //         email: user.email,
      //         home: pendingUser.home,
      //         id: user.uid,
      //         companies: pendingUser.companies,
      //         photoURL: pendingUser.photoURL,
      //       };

      //       firebaseApp
      //         .firestore()
      //         .collection(Schema.USERS)
      //         .doc(user.uid)
      //         .set(userToBeSaved);

      //       // Deleting the pendingUser after user has been created

      //       firebaseApp
      //         .firestore()
      //         .collection(Schema.PENDING_USERS)
      //         .doc(user.email!)
      //         .delete();
      //     }
      //   });
    } else {
      unsubscribeUser && unsubscribeUser();
      store.dispatch(appUserStateChanged(undefined));
    }

    store.dispatch(firebaseUserStateChanged(user));
  });
}

if (!isServerSide()) {
  const auth = firebaseApp.auth();

  /*
   * Firebase cloud functions's version of auth do not have setPersistence function. So we need to check its
   * existence dynamically and also call it dynamically
   */
  if (auth.setPersistence) {
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        console.info('Local auth persistence enabled');
      })
      .catch(error => {
        store.dispatch(updateFirestoreAuthError(error));
      });
  }
}

export type AuthAction =
  | AnyAction
  | AppUserAuthStateChangedAction
  | AppUserActiveOrganizationChangedAction
  | FirebaseUserAuthStateChangedAction;
