import { Avatar, WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { MemberWithKey, TaskWithKey } from '@shared/schema';
import ChatContainer from 'containers/Private/Timeline/components/EventEditorDialog/containers/ChatContainer/ChatContainer';
import * as React from 'react';
import styles from './styles';

// tslint:disable-next-line: no-var-requires
const defaultUserImageUrl = require('../../../../../../src/assets/images/ProfileImage.png');

export interface ChatViewContainerProps
  extends StyledComponentProps,
    WithTheme {
  companyId: string;
  editedTask: TaskWithKey;
  appUser: MemberWithKey;
  taskMember?: MemberWithKey;
  onReadChat?: (lastMessageId: string, timestamp: number) => void;
  onSentMessage?: (messageId: string, timestamp: number) => void;
}

export class ChatViewContainer extends React.Component<ChatViewContainerProps> {
  constructor(props: ChatViewContainerProps) {
    super(props);
  }

  public render() {
    const {
      companyId,
      editedTask,
      appUser,
      classes = {},
      taskMember,
      onReadChat,
      onSentMessage,
    } = this.props;

    const avatarURL =
      taskMember && taskMember.photoURL
        ? taskMember.photoURL
        : defaultUserImageUrl;

    return (
      <>
        <div className={classes.userBar}>
          <Avatar
            src={avatarURL}
            className={classes.avatar}
            data-test="avatar"
          />{' '}
          {taskMember && taskMember.name ? taskMember.name : ''}
        </div>
        <div className={classes.chatContainer}>
          <ChatContainer
            companyId={companyId}
            task={editedTask}
            member={appUser}
            onReadChat={onReadChat}
            onSentMessage={onSentMessage}
            data-test="chatContainer"
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatViewContainer);
