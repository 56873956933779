import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    height: 'calc(100% - 56px)',
    display: 'flex',
    flexDirection: 'column',
  },
  bar: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 1rem',
    alignItems: 'center',
    backgroundColor: theme.colors.lightGrey,
    minHeight: '110px',
  },
  jobtypeSearchBar: {
    alignSelf: 'flex-start',
    paddingTop: '33px',
    paddingRight: '35px',
    paddingLeft: '15px',
  },
  hourTypeAndCustomersDropdown: {
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    justifyContent: 'space-around',
  },
  timeFrameContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    justifyContent: 'space-around',
  },
  timeSelectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  calendarInput: {
    maxHeight: '37px',
  },
  calendarFieldset: {
    borderRadius: 0,
  },
  button: {
    display: 'flex',
    flex: 1,
    border: `1px solid ${theme.colors.black}`,
    height: '100%',
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    textTransform: 'none',
    minHeight: 'inherit',
  },
  dropDownButton: {
    textTransform: 'none',
    fontWeight: 300,
    fontSize: '14px',
  },
  statusRadioButton: {
    padding: '4px',
    marginLeft: '0px',
  },
  downloadLink: {
    display: 'flex',
    marginRight: '30px',
    width: '135px',
    minHeight: '45px',
    height: 'auto',
    marginLeft: 'auto',
    textDecoration: 'none',
    color: 'black',
  },
  diabledLink: {
    pointerEvents: 'none',
  },
  dateText: {
    textAlign: 'end',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    marginRight: '15px',
    width: '99px',
    fontWeight: 300,
    fontSize: '14px',
  },
  dateLine: {
    marginLeft: '7px',
    marginRight: '7px',
    height: '7px',
    alignSelf: 'center',
  },
  taskStatusRadioButtonsContainer: {
    marginLeft: '45px',
  },
  h2style: {
    marginBlockEnd: 0,
  },
  memberInfoContainer: {
    overflow: 'auto',
    height: '100%',
    margin: '30px 30px 18px 30px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '85%',
  },
  formControl: {
    width: '30%',
    '& div': {
      '& p': {
        fontSize: '20px',
      },
    },
  },
  memberInfo: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
  },
  memberEmailAndPhone: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  memberInfoLabel: {
    fontSize: '14px',
  },
});

export default styles;
