import * as React from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { SupportedLocale } from '@shared/locale/index';
import { connect, DispatchProp } from 'react-redux';

import {
  Radio,
  RadioGroup,
  StyledComponentProps,
  WithTheme,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  CompanyId,
  DateRange,
  getPriorityMap,
  JobtypeSet,
  JobTypeWithKey,
  MemberSet,
  MemberWithKey,
  Priority,
  QualificationWithKey,
  Settings,
  ViewState,
} from '@shared/schema';
import moment from 'moment';
import Calendar from 'react-calendar';
import { ApplicationState } from 'reducers';
import {
  goToday,
  goToPickedDay,
  hideAllMembers,
  hideMembers,
  hidePriorities,
  hideQualifications,
  hideTasks,
  revealAllAssignedTasks,
  revealAllMembers,
  revealMembers,
  revealPriorities,
  revealQualifications,
  revealTasks,
  setDateRange,
  stepBackward,
  stepForward,
} from 'reducers/timeline/timelineActions';
import { QualificationSet } from 'reducers/timeline/timelineReducer';
import { i18n } from '../../../../../../../@shared/locale/index';
import { newResourceEvent } from '../../../../../reducers/events/eventActions';
import { styles } from './styles';

import classNames from 'classnames';
import ProgressLoader from 'components/ProgressLoader';
import { newImportTasks } from 'reducers/importTasks/importTasksAction';
import { newShift } from 'reducers/shifts/shiftAction';

let locale = localStorage.getItem('locale') as SupportedLocale;
if (locale) {
  locale = locale.substring(0, locale.indexOf('_')) as SupportedLocale;
} else {
  locale = 'en' as SupportedLocale;
}

export enum DateRangeFormats {
  daily = 'daily',
  weekly = 'weekly',
  all = 'all',
}

interface TimelineBarProps
  extends DispatchProp<any>,
    StyledComponentProps,
    WithTheme {
  currentDate: moment.Moment;
  jobtypes: JobTypeWithKey[];
  qualifications: QualificationWithKey[];
  hiddenMembers: MemberSet;
  hiddenJobtypes: JobtypeSet;
  hiddenQualifications: QualificationSet;
  hiddenPriorities: number[];
  filter?: string | undefined;
  members: MemberWithKey[];
  settings: Settings;
  companyId: CompanyId;
  viewState?: ViewState;
}

interface State {
  selectedPriority: string;
  selectedAssign: string;
  openDatePicker: boolean;
  openJobtypeFilter: boolean;
  openQualificationFilter: boolean;
  openMemberFilter: boolean;
  openDateRangeFilter: boolean;
  anchorEl: any;
  anchorOriginVertical: any;
  anchorOriginHorizontal: any;
  transformOriginVertical: any;
  transformOriginHorizontal: any;
  positionTop: number;
  positionLeft: number;
  anchorReference: any;
  selectMultiple: boolean;
  openSortByPriority: boolean;
  selectedDateRangeFormat: DateRangeFormats;
  selectedDateRange: DateRange;
  selectedWeekNumber: number;
}

// @connect(({ timeline: { currentDate, filter, hiddenMembers } }) => ({
//   currentDate,
//   hiddenMembers,
//   filter
// }))
export class TimelineBar extends React.Component<TimelineBarProps, State> {
  public state = {
    selectedPriority: 'high',
    selectedAssign: 'all',
    openDatePicker: false,
    openJobtypeFilter: false,
    openMemberFilter: false,
    openDateRangeFilter: false,
    openQualificationFilter: false,
    anchorEl: null,
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'center',
    positionTop: 200, // Just so the popover can be spotted more easily
    positionLeft: 400, // Same as above
    anchorReference: 'anchorEl',
    selectMultiple: false,
    openSortByPriority: false,
    selectedDateRangeFormat: DateRangeFormats.weekly,
    selectedDateRange: {
      start: 0,
      end: 0,
    },
    selectedWeekNumber: 0,
  };

  constructor(props: TimelineBarProps) {
    super(props);

    moment.locale(locale);
  }

  public componentDidMount() {
    const { currentDate } = this.props;
    moment.locale(locale);

    const selectedWeekNumber = currentDate.isoWeek();
    const firstDay = currentDate.clone().startOf('isoWeek').valueOf();
    const lastDay = currentDate.clone().endOf('isoWeek').valueOf();

    const selectedDateRange: DateRange = {
      start: firstDay,
      end: lastDay,
    };

    this.setState({
      selectedDateRange,
      selectedWeekNumber,
    });
  }

  public handlePriorityClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: false,
      openMemberFilter: false,
      openDateRangeFilter: false,
      openQualificationFilter: false,
      openSortByPriority: true,
      anchorEl: e.target,
    });
  };

  public handleCalendarClickButton = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: true,
      openJobtypeFilter: false,
      openMemberFilter: false,
      openDateRangeFilter: false,
      openQualificationFilter: false,
      openSortByPriority: false,
      anchorEl: e.target,
    });
  };

  public handleJobtypeFilterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: true,
      openMemberFilter: false,
      openDateRangeFilter: false,
      openQualificationFilter: false,
      openSortByPriority: false,
      anchorEl: e.target,
    });
  };

  public handleQualificationFilterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: false,
      openMemberFilter: false,
      openDateRangeFilter: false,
      openQualificationFilter: true,
      openSortByPriority: false,
      anchorEl: e.target,
    });
  };

  public handleMemberFilterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: false,
      openMemberFilter: true,
      openDateRangeFilter: false,
      openQualificationFilter: false,
      openSortByPriority: false,
      anchorEl: e.target,
    });
  };

  public handleDateRangeFilterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: false,
      openMemberFilter: false,
      openDateRangeFilter: true,
      openQualificationFilter: false,
      openSortByPriority: false,
      anchorEl: e.target,
    });
  };

  public handleRequestClose = () => {
    this.setState({
      openDatePicker: false,
      openJobtypeFilter: false,
      openMemberFilter: false,
      openDateRangeFilter: false,
      openQualificationFilter: false,
      openSortByPriority: false,
    });
  };

  public onImportTasksClick = () => {
    this.props.dispatch(newImportTasks());
  };

  public onNewTaskClick = () => {
    this.props.dispatch(newResourceEvent(this.props.companyId));
  };

  public onNewShiftClick = () => {
    this.props.dispatch(newShift());
  };

  public onTodayClick = () => {
    this.props.dispatch(goToday());
  };

  public onBackwardClick = () => {
    if (
      this.state.selectedDateRangeFormat === DateRangeFormats.weekly &&
      this.props.viewState === ViewState.TASKBANK
    ) {
      this.props.dispatch(stepBackward(7));
    } else {
      this.props.dispatch(stepBackward(1));
    }
  };

  public onForwardClick = () => {
    if (
      this.state.selectedDateRangeFormat === DateRangeFormats.weekly &&
      this.props.viewState === ViewState.TASKBANK
    ) {
      this.props.dispatch(stepForward(7));
    } else {
      this.props.dispatch(stepForward(1));
    }
  };

  public onDayPicked = (date: Date | Date[]) => {
    const pickedDate = Array.isArray(date)
      ? date.length > 0
        ? date[0]
        : undefined
      : date;

    if (pickedDate) {
      this.props.dispatch(goToPickedDay(moment(pickedDate)));
    }
    this.handleRequestClose();
  };

  public getCorrectPriorityName = () => {
    const { hiddenPriorities } = this.props;

    switch (hiddenPriorities.length) {
      // If 1 priority
      case 1:
        const priority = getPriorityMap().find(
          item => hiddenPriorities[0] === item.value,
        );

        return `${priority!.label}`;

      // If 2 priorities
      case 2:
        const priority1 = getPriorityMap().find(
          item => hiddenPriorities[0] === item.value,
        );
        const priority2 = getPriorityMap().find(
          item => hiddenPriorities[1] === item.value,
        );
        return `${priority1!.label} & ${priority2!.label}`;

      // If 0 or all priorities
      default:
        return i18n().filters.all_priorities;
    }
  };

  public render() {
    const {
      classes = {},
      currentDate,
      jobtypes,
      members = [],
      qualifications,
      theme,
      hiddenMembers = {},
      hiddenJobtypes = {},
      hiddenQualifications,
      hiddenPriorities,
      viewState,
    } = this.props;
    const {
      openDatePicker,
      anchorEl,
      positionTop,
      positionLeft,
      openJobtypeFilter,
      openQualificationFilter,
      openMemberFilter,
      openDateRangeFilter,
      openSortByPriority,
      selectedAssign,
      selectedDateRangeFormat,
      selectedDateRange,
      selectedWeekNumber,
    } = this.state;
    const priorities: Priority[] = getPriorityMap();

    currentDate.locale(locale);

    const pickedDate = currentDate.toDate() || new Date();
    const hiddenMemberCount = Object.getOwnPropertyNames(hiddenMembers).length;

    this.updateDateRange();

    return (
      <div className={classes.bar}>
        <div className={classes.filters}>
          {viewState === ViewState.TASKBANK && (
            <Button
              data-test="priorityButton"
              className={classes.filterPrioritiesButton}
              onClick={this.handlePriorityClick}
            >
              {this.getCorrectPriorityName()}
              <ArrowDropDownIcon />
            </Button>
          )}
          <Button
            className={classes.filterUsersButton}
            onClick={this.handleMemberFilterClick}
            data-test="handleMemberFilter"
          >
            {viewState === ViewState.TASKBANK
              ? (hiddenMemberCount > 0 && i18n().filters.selected_tasks) ||
                i18n().filters.all_tasks
              : (hiddenMemberCount > 0 && i18n().ui.selected_members) ||
                i18n().ui.all_members ||
                (hiddenMemberCount === members.length &&
                  i18n().ui.no_member_selected)}
            <ArrowDropDownIcon />
          </Button>
          <Popover
            open={openMemberFilter}
            anchorEl={anchorEl}
            anchorPosition={{ top: positionTop, left: positionLeft }}
            onClose={this.handleRequestClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List>
              {viewState === ViewState.TIMELINE && (
                <>
                  <ListItem
                    button={true}
                    onClick={() => {
                      this.props.dispatch(revealAllMembers());
                    }}
                    style={{
                      backgroundColor:
                        hiddenMemberCount === 0
                          ? theme.palette.divider
                          : 'transparent',
                    }}
                  >
                    <ListItemText primary={i18n().ui.all_members} />
                  </ListItem>
                  <ListItem
                    button={true}
                    onClick={this.hideAllMembers}
                    style={{
                      backgroundColor:
                        hiddenMemberCount === members.length
                          ? theme.palette.divider
                          : 'transparent',
                    }}
                  >
                    <ListItemText primary={i18n().ui.no_members} />
                  </ListItem>
                </>
              )}
              {viewState === ViewState.TASKBANK && (
                <>
                  <RadioGroup
                    aria-label="assign"
                    name="assign"
                    className={classes.group}
                    value={selectedAssign}
                    onChange={this.handleSortByAssigned}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label={i18n().filters.all_tasks}
                    />
                    <FormControlLabel
                      value="assigned"
                      control={<Radio />}
                      label={i18n().filters.all_assigned}
                    />
                    <FormControlLabel
                      value="unassigned"
                      control={<Radio />}
                      label={i18n().filters.all_unassigned}
                    />
                  </RadioGroup>
                </>
              )}
              <hr />
              {members.map((data: MemberWithKey) => {
                const hidden =
                  data.key !== undefined && hiddenMembers[data.key] === true;

                return (
                  <ListItem
                    key={data.key}
                    button={true}
                    onClick={this.handleToggleMemberVisibility(hidden, data)}
                    style={{
                      backgroundColor: hidden
                        ? theme.palette.divider
                        : 'transparent',
                      color: hidden ? 'rgba(64,64,64,0.8)' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      {hidden ? (
                        <CheckBoxOutlineIcon />
                      ) : (
                        <CheckBoxIcon color="secondary" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={data.name} />
                  </ListItem>
                );
              })}
            </List>
          </Popover>

          <Button
            className={classes.button}
            onClick={this.handleJobtypeFilterClick}
          >
            {hiddenJobtypes && Object.keys(hiddenJobtypes).length > 0
              ? i18n().ui.filter_on
              : viewState === ViewState.TASKBANK
              ? i18n().ui.filter_by_jobtype
              : i18n().ui.filter_by_proficiency}
            <ArrowDropDownIcon />
          </Button>
          <>
            <Button
              className={classes.button}
              onClick={this.handleQualificationFilterClick}
            >
              {(hiddenQualifications &&
                Object.keys(hiddenQualifications).length > 0 &&
                i18n().ui.filter_on) ||
                i18n().ui.filter_by_qualification}
              <ArrowDropDownIcon />
            </Button>

            {viewState === ViewState.TASKBANK && (
              <Button
                className={classes.filterDateRangeButton}
                onClick={this.handleDateRangeFilterClick}
              >
                {selectedDateRangeFormat === DateRangeFormats.daily
                  ? i18n().ui.daily
                  : selectedDateRangeFormat === DateRangeFormats.weekly
                  ? i18n().ui.week
                  : i18n().ui.all}
                <ArrowDropDownIcon />
              </Button>
            )}
            <Popover
              open={openDateRangeFilter}
              anchorEl={anchorEl}
              anchorPosition={{ top: positionTop, left: positionLeft }}
              onClose={this.handleRequestClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List>
                <>
                  <RadioGroup
                    className={classes.group}
                    value={selectedDateRangeFormat}
                    onChange={this.handleFormatChange}
                  >
                    <FormControlLabel
                      value={DateRangeFormats.daily}
                      control={<Radio />}
                      label={i18n().ui.daily}
                    />
                    <FormControlLabel
                      value={DateRangeFormats.weekly}
                      control={<Radio />}
                      label={i18n().ui.week}
                    />
                    <FormControlLabel
                      value={DateRangeFormats.all}
                      control={<Radio />}
                      label={i18n().ui.all}
                    />
                  </RadioGroup>
                </>
              </List>
            </Popover>

            {selectedDateRangeFormat !== DateRangeFormats.all && (
              <IconButton
                data-test="BackButton"
                className={classes.button}
                onClick={this.onBackwardClick}
              >
                <ArrowLeftIcon />
              </IconButton>
            )}
            {selectedDateRangeFormat !== DateRangeFormats.all && (
              <Button
                className={classNames(
                  classes.button,
                  classes.calendarButtonWeek,
                )}
                onClick={this.handleCalendarClickButton}
              >
                {(selectedDateRangeFormat === DateRangeFormats.daily ||
                  viewState === ViewState.TIMELINE) &&
                  (currentDate ? (
                    <div className={classes.weeklyContainer}>
                      <p className={classes.weekNumberText}>
                        {currentDate.format('dddd')}
                      </p>
                      <p className={classes.weekDaysText}>
                        {moment(currentDate).format('D.M.YYYY')}
                      </p>
                    </div>
                  ) : (
                    'empty'
                  ))}
                {viewState === ViewState.TASKBANK &&
                  selectedDateRangeFormat === DateRangeFormats.weekly &&
                  (selectedDateRange &&
                  selectedDateRange.start &&
                  selectedDateRange.end ? (
                    <div className={classes.weeklyContainer}>
                      <p className={classes.weekNumberText}>
                        {i18n().ui.week_single + ' ' + selectedWeekNumber}
                      </p>
                      <p className={classes.weekDaysText}>
                        {moment(selectedDateRange.start).format('D.M. ') +
                          String.fromCharCode(8211) +
                          moment(selectedDateRange.end).format(' D.M.')}
                      </p>
                    </div>
                  ) : (
                    'empty'
                  ))}
              </Button>
            )}

            {selectedDateRangeFormat !== DateRangeFormats.all && (
              <IconButton
                data-test="ForwardButton"
                className={classes.button}
                onClick={this.onForwardClick}
              >
                <ArrowRightIcon />
              </IconButton>
            )}

            {viewState === ViewState.TIMELINE && (
              <Button
                className={classes.todayButton}
                onClick={this.onTodayClick}
                data-test="TodayButton"
              >
                {i18n().ui.today}
              </Button>
            )}
          </>
        </div>
        <div className={classes.buttons}>
          <ProgressLoader />
          <Button
            data-test="addNewButton"
            className={classes.addNewButton}
            onClick={this.onImportTasksClick}
          >
            {i18n().ui.import_tasks}
          </Button>
          <Button
            data-test="addNewButton"
            className={classes.addNewButton}
            onClick={this.onNewShiftClick}
          >
            {i18n().ui.add_shift}
          </Button>
          <Button
            data-test="addNewButton"
            className={classes.addNewButton}
            onClick={this.onNewTaskClick}
          >
            {i18n().ui.add_task}
          </Button>
        </div>

        <Popover
          open={openDatePicker}
          anchorEl={anchorEl}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Calendar
            onChange={this.onDayPicked}
            value={pickedDate}
            showWeekNumbers={true}
            activeStartDate={pickedDate}
            onClickDay={this.handleRequestClose}
            className={classes.datePicker}
            locale={locale}
          />
        </Popover>
        <Popover
          open={openSortByPriority}
          anchorEl={anchorEl}
          // anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {priorities.map(data => {
              const hidden =
                data.value !== undefined &&
                hiddenPriorities.includes(data.value);
              return (
                <ListItem
                  key={data.value}
                  button={true}
                  onClick={this.handleTogglePriorityVisibility(hidden, data)}
                  style={{
                    backgroundColor: hidden
                      ? theme.palette.divider
                      : 'transparent',
                    color: hidden ? 'rgba(64,64,64,0.5)' : 'black',
                  }}
                >
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxIcon color="secondary" />
                    ) : (
                      <CheckBoxOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={data.label} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
        <Popover
          open={openJobtypeFilter}
          anchorEl={anchorEl}
          // anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {jobtypes.map(data => {
              const hidden =
                data.key !== undefined && hiddenJobtypes[data.key] === true;
              return (
                <ListItem
                  key={data.key}
                  button={true}
                  onClick={this.handleToggleJobtypeVisibility(hidden, data)}
                  style={{
                    backgroundColor: hidden
                      ? theme.palette.divider
                      : 'transparent',
                    color: hidden ? 'rgba(64,64,64,0.5)' : 'black',
                  }}
                >
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxIcon color="secondary" />
                    ) : (
                      <CheckBoxOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={data.name} />
                </ListItem>
              );
            })}
          </List>
        </Popover>

        <Popover
          open={openQualificationFilter}
          anchorEl={anchorEl}
          // anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {qualifications.map(data => {
              const hidden =
                data.key !== undefined &&
                hiddenQualifications[data.key] === true;
              return (
                <ListItem
                  key={data.key}
                  button={true}
                  onClick={this.handleToggleQualificationVisibility(
                    hidden,
                    data,
                  )}
                  style={{
                    backgroundColor: hidden
                      ? theme.palette.divider
                      : 'transparent',
                    color: hidden ? 'rgba(64,64,64,0.5)' : 'black',
                  }}
                >
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxIcon color="secondary" />
                    ) : (
                      <CheckBoxOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={data.name} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </div>
    );
  }

  private handleSortByAssigned = (event: React.BaseSyntheticEvent) => {
    const selectedAssign = event.target.value;
    this.setState({ selectedAssign });
    switch (selectedAssign) {
      case 'all':
        this.props.dispatch(revealAllMembers());
        break;
      case 'assigned':
        this.props.dispatch(revealAllAssignedTasks());
        break;
      case 'unassigned':
        this.hideAllMembers();
        break;
    }
  };
  private hideAllMembers = () => {
    const members: string[] = [];
    this.props.members.forEach(member => {
      members.push(member.key as string);
    });
    this.props.dispatch(hideAllMembers(members));
  };

  /**
   * Checks DateRangeFormat and updates dateRange accordingly
   */
  private updateDateRange = () => {
    const { currentDate } = this.props;
    const { selectedDateRangeFormat } = this.state;

    const selectedWeekNumber = currentDate.isoWeek();
    let start;
    let end;
    let selectedDateRange: DateRange;

    if (selectedDateRangeFormat === DateRangeFormats.weekly) {
      start = currentDate.clone().startOf('isoWeek').valueOf();
      end = currentDate.clone().endOf('isoWeek').valueOf();

      selectedDateRange = {
        start,
        end,
      };
    } else if (selectedDateRangeFormat === DateRangeFormats.daily) {
      start = moment(currentDate).startOf('day').valueOf();
      end = moment(currentDate).endOf('day').valueOf();

      selectedDateRange = {
        start,
        end,
      };
    } else {
      selectedDateRange = {
        start: undefined,
        end: undefined,
      };
      this.props.dispatch(setDateRange(selectedDateRange));
    }
    if (
      selectedDateRange &&
      (selectedDateRange.start !== this.state.selectedDateRange.start ||
        selectedDateRange.end !== this.state.selectedDateRange.end)
    ) {
      this.props.dispatch(setDateRange(selectedDateRange));
      this.setState({
        selectedDateRange,
        selectedWeekNumber,
      });
    }
  };

  private handleToggleJobtypeVisibility =
    (hidden: boolean, jobtype: JobTypeWithKey) => () => {
      hidden
        ? this.props.dispatch(revealTasks(jobtype.key))
        : this.props.dispatch(hideTasks(jobtype.key));
    };

  private handleToggleQualificationVisibility =
    (hidden: boolean, qualification: QualificationWithKey) => () => {
      hidden
        ? this.props.dispatch(revealQualifications(qualification.key))
        : this.props.dispatch(hideQualifications(qualification.key));
    };
  private handleTogglePriorityVisibility =
    (hidden: boolean, priority: Priority) => () => {
      hidden
        ? this.props.dispatch(revealPriorities(priority.value))
        : this.props.dispatch(hidePriorities(priority.value));
    };

  private handleToggleMemberVisibility =
    (hidden: boolean, member: MemberWithKey) => () => {
      hidden
        ? this.props.dispatch(revealMembers(member.key))
        : this.props.dispatch(hideMembers(member.key));
    };

  private handleFormatChange = (event: any) => {
    this.setState({
      selectedDateRangeFormat: event.target.value,
      openDateRangeFilter: false,
    });
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TimelineBarProps>,
) => {
  return {
    ...ownProps,
    currentDate: state.timeline.currentDate,
    hiddenMembers: state.timeline.hiddenMembers,
    hiddenJobtypes: state.timeline.hiddenJobtypes,
    hiddenQualifications: state.timeline.hiddenQualifications,
    hiddenPriorities: state.timeline.hiddenPriorities,
    filter: state.timeline.filter,
    settings: state.company.activeCompany.settings,
  } as TimelineBarProps;
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(TimelineBar),
);
