import { Grid } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from '@shared/locale/index';
import { GeoLocation, WorksiteWithKey } from '@shared/schema/index';
import { Component } from 'react';
import * as React from 'react';
import styles from './styles';

interface ResultsContainerProps extends StyledComponentProps {
  results: WorksiteWithKey[];
  onClose: () => void;
  selectedCoordinates: (
    geoLocation: GeoLocation | undefined,
    key: string | undefined,
  ) => void;
}

interface State {
  selected: string;
}

class ResultsContainer extends Component<ResultsContainerProps, State> {
  constructor(props: ResultsContainerProps) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  public render() {
    const { classes = {}, results } = this.props;
    return (
      <div className={classes.resultsContainer}>
        <CloseIcon onClick={this.closeHandler} />

        <Grid container={true} className={classes.resultsList}>
          <h4 className={classes.resultsTitle}>{i18n().ui.search_results}</h4>
          {results &&
            results.map((result: WorksiteWithKey, index) => (
              <Grid item={true} key={index} xs={12}>
                <div
                  className={
                    this.state.selected === result.key
                      ? classes.selectedCard
                      : classes.resultCard
                  }
                  onClick={this.onCardClick(result.geoLocation, result.key)}
                >
                  <div className={classes.resultName} title={result.name}>
                    {result.name}
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }

  /**
   * Passes geoLocation to SearchDrawer
   * @param geoLocation Selected worksite's coordinates
   */
  private onCardClick = (
    geoLocation: GeoLocation | undefined,
    key: string,
  ) => () => {
    this.props.selectedCoordinates(geoLocation, key);
    this.setState({
      selected: key,
    });
  };

  /**
   * Closes ResultsContainer
   */
  private closeHandler = () => {
    this.props.onClose();
  };
}

export default withStyles(styles, { withTheme: true })(ResultsContainer);
