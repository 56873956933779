/**
 * # FirebaseApp.ts
 *
 * Configuration for the Firebase app.
 *
 * @author Ville Venäläinen
 */

import * as firebase from 'firebase';
import { isServerSide } from 'utils/devUtils';

// TODO REMOVE FROM ACTUAL VERSION!!!
// console.log(JSON.stringify(CONFIG.firebase));
/**
 * Note that firebase modules must be included as external scripts on App.tsx's helmet script
 */
const firebaseApp = firebase.initializeApp(CONFIG.firebase);
firebaseApp.firestore().settings({
  timestampsInSnapshots: true,
});

if (!isServerSide()) {
  firebaseApp
    .firestore()
    .enablePersistence()
    .catch((err: any) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.error(
          'Unable to active persistence because of multiple instances of application.',
        );
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.error(
          'Unable to active persistence because of legacy browser.',
        );
      }
    })
    .then(() => {
      console.info('firestore persistence enabled.');
    });
}

export default firebaseApp;
