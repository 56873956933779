import { IconButton, ListItemIcon, WithTheme } from '@material-ui/core';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@material-ui/core/';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { Clear, ImportExport, Maximize } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { i18n } from '@shared/locale';
import {
  CsvHeader,
  CSVRow,
  CustomerWithKey,
  getHourTypeMap,
  JobTypeWithKey,
  MemberWithKey,
  Schema,
  Settings,
  TaskWithKey,
  WorksiteWithKey,
} from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import { getTaskTimerTotal, roundToNext } from '@shared/utils/timeUtils';
import LoadingSpinner from 'components/LoadingSpinner';
import classNames from 'classnames';
import firebaseApp from 'firebaseApp';
import * as _ from 'lodash';
import { isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import {
  updateFilteredTaskList,
  updateHiddenWorksites,
  updateSelectedHourType,
  updateStartedDateEnd,
  updateStartedDateStart,
  updateTableSortOptions,
  updateTaskList,
} from 'reducers/reports/taskReport/reportActions';
import { TaskReportFilters } from 'reducers/reports/taskReport/reportReducer';
import { ActionCreator, AnyAction } from 'redux';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import {
  getAllUsersIdsFromTimerLogs,
  getUserTimerLogsDuration,
} from 'utils/tasksUtil';
import { WithKey } from '../../../../../../../../../../../@shared/schema/index';
import {
  updateHiddenJobtypes,
  updateHiddenMembers,
} from '../../../../../../../../../reducers/reports/taskReport/reportActions';
import {
  updateHiddenCustomers,
  updateHiddenStatuses,
} from '../../../../../../../../../reducers/reports/taskReport/reportActions';
import { updateIsTableReady } from '../../../../../../../../../reducers/reports/taskReport/reportActions';
import {
  getTaskEndedTimestampForUserId,
  getTaskStartedTimestampForUserId,
} from '../../../../../../../../../utils/tasksUtil';
import NoTasksReportNotification from '../../../../components/NoTasksReportNotification';
import TaskReportDateTimePicker from '../../components/TaskReportDateTimePicker/TaskReportDateTimePicker';
import TaskReportFilterSelect from '../../components/TaskReportFiltersSelect/TaskReportFilterSelect';
import TaskReportTable from '../../components/TaskReportTable/TaskReportTable';
import styles from './styles';

export interface TaskReportContainerProps
  extends DispatchProp,
    Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  companyId: string;
  settings: Settings;
  members: MemberWithKey[];
  selectedMember?: MemberWithKey;
  hiddenMembers?: MemberWithKey[];
  hiddenCustomers?: CustomerWithKey[];
  hiddenJobtypes?: JobTypeWithKey[];
  hiddenStatuses?: TaskStatusRadioButtonOptions[];
  hiddenWorksites?: WorksiteWithKey[];
  selectedCustomer?: CustomerWithKey;
  jobTypeSearch: string;
  selectedHourType: HourTypeLabel;
  startedDateStart?: Date;
  startedDateEnd?: Date;
  completedDateStart?: Date;
  completedDateEnd?: Date;
  selectedTaskStatus: TaskStatusRadioButtonOptions;
  taskList: TaskWithKey[];
  filteredTaskList: TaskWithKey[];
  filterOptions: TaskReportFilters;
  orderBy: OrderByValues;
  order: OrderOptions;
  isTableReady: boolean;
}

export enum TaskStatusRadioButtonOptions {
  DONE = 'DONE',
  PAUSED = 'PAUSED',
}

export const TaskStatusesOptionsLabels = new Map<
  TaskStatusRadioButtonOptions,
  string
>([
  [TaskStatusRadioButtonOptions.DONE, i18n().ui.done],
  [TaskStatusRadioButtonOptions.PAUSED, i18n().ui.paused],
]);

export enum OrderByValues {
  userName = 'userName',
  taskName = 'taskName',
  jobtypeName = 'jobtypeName',
  completedDate = 'completedDate',
  customerName = 'customerName',
  startedDate = 'startedDate',
  worksiteName = 'worksiteName',
  memberName = 'memberName',
  workingHours = 'workingHours',
  status = 'status',
}

export enum OrderOptions {
  asc = 'asc',
  desc = 'desc',
}

interface State {
  anchorEl: any;
  openMembers: boolean;
  openCustomers: boolean;
  openHours: boolean;
  openStatuses: boolean;
  jobtypes: JobTypeWithKey[];
  customers: CustomerWithKey[];
  statuses: TaskStatusRadioButtonOptions[];
  worksites: WorksiteWithKey[];
  showNoTaskNotification: boolean;
  openJobtypes: boolean;
  openWorksites: boolean;
  isLoading: boolean;
}

export interface HourTypeLabel {
  label: string;
  value: string;
}

export const HOURTYPE_MAP: HourTypeLabel[] = [
  {
    label: i18n().ui.total_hours,
    value: 'totalhours',
  },
  {
    label: i18n().ui.daily_hours,
    value: 'dailyhours',
  },
];

export class TaskReportContainer extends React.Component<
  TaskReportContainerProps,
  State
> {
  get isFilteredTaskListEmpty(): boolean {
    return this.props.filteredTaskList.length === 0;
  }
  private unsubscribeTasks: () => void;
  private unsubscribeCustomers: () => void;
  private unsubscribeJobtypes: () => void;
  private unsubscribeWorksites: () => void;

  constructor(props: TaskReportContainerProps) {
    super(props);

    this.state = {
      anchorEl: null,
      openMembers: false,
      openCustomers: false,
      openHours: false,
      openStatuses: false,
      openWorksites: false,
      customers: [],
      jobtypes: [],
      worksites: [],
      showNoTaskNotification: false,
      openJobtypes: false,
      statuses: [
        TaskStatusRadioButtonOptions.DONE,
        TaskStatusRadioButtonOptions.PAUSED,
      ],
      isLoading: true,
    };
  }

  /**
   * When task report is mounted, fetch all the customers for displaying them in customer selection dropdown menu
   */
  public componentDidMount = () => {
    const { companyId } = this.props;

    if (companyId) {
      this.getCustomers(companyId);

      this.getJobtypes(companyId);

      this.getTaskList(companyId);

      this.getWorksites(companyId);
    }
  };

  public componentDidUpdate(
    prevProps: Readonly<TaskReportContainerProps>,
    prevState: Readonly<State>,
  ) {
    // Fetch tasks ONLY if selected member is changed
    /* if (this.props.selectedMember !== prevProps.selectedMember) {
      this.props.selectedMember &&
        this.props.selectedMember.key &&
        this.getTaskList(this.props.companyId, this.props.selectedMember.key);
    }
 */

    if (
      this.props.filterOptions.startedDateStart !==
        prevProps.filterOptions.startedDateStart ||
      this.props.filterOptions.startedDateEnd !==
        prevProps.filterOptions.startedDateEnd
    ) {
      this.props.dispatch(updateTaskList([]));
      this.props.dispatch(updateFilteredTaskList([]));
      this.props.dispatch(updateIsTableReady(false));
      this.getTaskList(this.props.companyId);
    }

    //  Filter task when any search option is chnaged
    if (
      this.props.order !== prevProps.order ||
      this.props.orderBy !== prevProps.orderBy ||
      !isEqual(this.props.hiddenMembers, prevProps.hiddenMembers) ||
      !isEqual(this.props.hiddenJobtypes, prevProps.hiddenJobtypes) ||
      !isEqual(this.props.hiddenCustomers, prevProps.hiddenCustomers) ||
      !isEqual(this.props.hiddenWorksites, prevProps.hiddenWorksites) ||
      !isEqual(this.props.hiddenStatuses, prevProps.hiddenStatuses) ||
      (this.props.taskList !== prevProps.taskList &&
        this.props.taskList.length !== 0)
    ) {
      this.props.dispatch(updateIsTableReady(false));
      this.filterTaskList();
    }
  }

  public componentWillUnmount() {
    this.unsubscribeTasks && this.unsubscribeTasks();
    this.unsubscribeCustomers && this.unsubscribeCustomers();
    this.unsubscribeJobtypes && this.unsubscribeJobtypes();
    this.unsubscribeWorksites && this.unsubscribeWorksites();
  }

  public render() {
    const {
      anchorEl,
      jobtypes,
      openMembers,
      openJobtypes,
      customers,
      openCustomers,
      openStatuses,
      statuses,
      openWorksites,
      worksites,
    } = this.state;

    const {
      classes = {},
      selectedHourType,
      filteredTaskList,
      order,
      orderBy,
      members,
      hiddenMembers,
      hiddenJobtypes,
      hiddenCustomers,
      hiddenStatuses,
      hiddenWorksites,
    } = this.props;

    const { startedDateEnd, startedDateStart } = this.props.filterOptions;

    return (
      <div className={classes.container}>
        <div className={classes.bar}>
          <TaskReportFilterSelect
            open={openMembers}
            values={members}
            hiddenValues={hiddenMembers}
            handleClose={this.handleSelectMemberClose}
            handleOpen={this.handleSelectMembersOpen}
            anchorEl={anchorEl}
            handleSelectAllClick={() =>
              this.props.dispatch(updateHiddenMembers(undefined))
            }
            handleDeselectAllClick={() =>
              this.props.dispatch(updateHiddenMembers(members))
            }
            title={i18n().ui.select_member}
          >
            {members.map(member => {
              const hidden =
                hiddenMembers &&
                hiddenMembers.findIndex(mem => mem.key === member.key) !== -1;
              return (
                <ListItem
                  key={member.key}
                  button={true}
                  onClick={() =>
                    this.handleSelectClick(
                      member,
                      hiddenMembers,
                      updateHiddenMembers,
                    )
                  }
                >
                  <ListItemText>{member.name}</ListItemText>
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxOutlineIcon />
                    ) : (
                      <CheckBoxIcon color="secondary" />
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </TaskReportFilterSelect>

          <TaskReportFilterSelect
            open={openJobtypes}
            values={jobtypes}
            hiddenValues={hiddenJobtypes}
            handleClose={this.handleSelectMemberClose}
            handleOpen={this.handleSelectJobtypesOpen}
            anchorEl={anchorEl}
            handleSelectAllClick={() =>
              this.props.dispatch(updateHiddenJobtypes(undefined))
            }
            handleDeselectAllClick={() =>
              this.props.dispatch(updateHiddenJobtypes(jobtypes))
            }
            title={i18n().ui.select_jobtype}
          >
            {jobtypes.map(jobtype => {
              const hidden =
                hiddenJobtypes &&
                hiddenJobtypes.findIndex(job => job.key === jobtype.key) !== -1;
              return (
                <ListItem
                  key={jobtype.key}
                  button={true}
                  onClick={() =>
                    this.handleSelectClick(
                      jobtype,
                      hiddenJobtypes,
                      updateHiddenJobtypes,
                    )
                  }
                >
                  <ListItemText>{jobtype.name}</ListItemText>
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxOutlineIcon />
                    ) : (
                      <CheckBoxIcon color="secondary" />
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </TaskReportFilterSelect>
          <TaskReportFilterSelect
            open={openCustomers}
            values={customers}
            hiddenValues={hiddenCustomers}
            handleClose={this.handleSelectMemberClose}
            handleOpen={this.handleCustomersSelectOpen}
            anchorEl={anchorEl}
            handleSelectAllClick={() =>
              this.props.dispatch(updateHiddenCustomers(undefined))
            }
            handleDeselectAllClick={() =>
              this.props.dispatch(updateHiddenCustomers(customers))
            }
            title={i18n().ui.select_customer}
          >
            {customers.map(customer => {
              const hidden =
                hiddenCustomers &&
                hiddenCustomers.findIndex(cust => cust.key === customer.key) !==
                  -1;
              return (
                <ListItem
                  key={customer.key}
                  button={true}
                  onClick={() =>
                    this.handleSelectClick(
                      customer,
                      hiddenCustomers,
                      updateHiddenCustomers,
                    )
                  }
                >
                  <ListItemText>{customer.name}</ListItemText>
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxOutlineIcon />
                    ) : (
                      <CheckBoxIcon color="secondary" />
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </TaskReportFilterSelect>

          <TaskReportFilterSelect
            open={openStatuses}
            values={statuses}
            hiddenValues={hiddenStatuses}
            handleClose={this.handleSelectMemberClose}
            handleOpen={this.handelStatusesSelectOpen}
            anchorEl={anchorEl}
            handleSelectAllClick={() =>
              this.props.dispatch(updateHiddenStatuses(undefined))
            }
            handleDeselectAllClick={() =>
              this.props.dispatch(updateHiddenStatuses(statuses))
            }
            title={i18n().ui.select_status}
          >
            {statuses.map(status => {
              const hidden =
                hiddenStatuses &&
                hiddenStatuses.findIndex(
                  hiddenStatus => hiddenStatus === status,
                ) !== -1;
              return (
                <ListItem
                  key={status}
                  button={true}
                  onClick={() => this.handleStatusesSelectClick(status)}
                >
                  <ListItemText style={{ textTransform: 'capitalize' }}>
                    {TaskStatusesOptionsLabels.get(status)}
                  </ListItemText>
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxOutlineIcon />
                    ) : (
                      <CheckBoxIcon color="secondary" />
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </TaskReportFilterSelect>

          <TaskReportFilterSelect
            open={openWorksites}
            values={worksites}
            hiddenValues={hiddenWorksites}
            handleClose={this.handleSelectMemberClose}
            handleOpen={this.handelWorksitesSelectOpen}
            anchorEl={anchorEl}
            handleSelectAllClick={() =>
              this.props.dispatch(updateHiddenWorksites(undefined))
            }
            handleDeselectAllClick={() =>
              this.props.dispatch(updateHiddenWorksites(worksites))
            }
            title={i18n().ui.select_worksite}
          >
            {worksites.map(worksite => {
              const hidden =
                hiddenWorksites &&
                hiddenWorksites.findIndex(
                  hiddenWorksite => hiddenWorksite.key === worksite.key,
                ) !== -1;
              return (
                <ListItem
                  key={worksite.key}
                  button={true}
                  onClick={() =>
                    this.handleSelectClick(
                      worksite,
                      hiddenWorksites,
                      updateHiddenWorksites,
                    )
                  }
                >
                  <ListItemText style={{ textTransform: 'capitalize' }}>
                    {worksite.name}
                  </ListItemText>
                  <ListItemIcon>
                    {hidden ? (
                      <CheckBoxOutlineIcon />
                    ) : (
                      <CheckBoxIcon color="secondary" />
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </TaskReportFilterSelect>
          <div className={classes.hourTypeAndCustomersDropdown}>
            <Popover
              data-test="hoursPopover"
              id="simple-popper"
              open={this.state.openHours}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              onClick={this.handleClose}
              anchorOrigin={{
                vertical: 160,
                horizontal: 300,
              }}
            >
              {/* ENUM FORM THESE */}
              <List data-test="listHourType">
                <ListItem
                  button
                  selected={
                    selectedHourType.value === getHourTypeMap()[0].value
                  }
                  onClick={event =>
                    this.handleHoursChange(event, getHourTypeMap()[0])
                  }
                >
                  <ListItemText primary={getHourTypeMap()[0].label} />
                </ListItem>
                <ListItem
                  button
                  selected={
                    this.props.selectedHourType.value ===
                    getHourTypeMap()[1].value
                  }
                  onClick={event =>
                    this.handleHoursChange(event, getHourTypeMap()[1])
                  }
                >
                  <ListItemText primary={getHourTypeMap()[1].label} />
                </ListItem>
              </List>
            </Popover>
          </div>
          <div className={classes.timeFrameContainer}>
            <div className={classes.timeSelectionContainer}>
              <div className={classNames(classes.dateText)}>
                {i18n().ui.started}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TaskReportDateTimePicker
                    data-test="startedDateStartPicker"
                    maxDate={startedDateEnd}
                    clearable={startedDateStart}
                    value={startedDateStart}
                    onChange={this.handleStartedDateStartChange}
                  />
                  <div className={classNames(classes.dateLine)}>
                    <Maximize />
                  </div>
                  <TaskReportDateTimePicker
                    data-test="startedDateEndPicker"
                    minDate={startedDateStart}
                    clearable={startedDateEnd}
                    value={startedDateEnd}
                    onChange={this.handleStartedDateEndChange}
                  />
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    onClick={this.handleLastDayButtonClick}
                  >
                    {i18n().ui.last_day}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={this.handleLastWeekButtonClick}
                  >
                    {i18n().ui.last_week}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={this.hanldeLastMonthButtonClick}
                  >
                    {i18n().ui.last_month}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <CSVLink
            data-test="csvlink"
            data={this.parseCsvData()}
            headers={this.createCsvHeaders()}
            className={classNames(
              classes.downloadLink,
              this.isFilteredTaskListEmpty && classes.diabledLink,
            )}
            filename={
              moment().format('DD.MM.YYYY hh:mm:ss') + '_TimeReport.csv'
            }
            separator={','}
          >
            <Button
              disabled={this.isFilteredTaskListEmpty}
              className={classNames(classes.button)}
              color="primary"
            >
              <ImportExport />
              {i18n().ui.export_csv}
            </Button>
          </CSVLink>
        </div>
        <div className={classes.memberInfoContainer}>
          {!this.isFilteredTaskListEmpty && (
            <TaskReportTable
              data-test="taskReportTable"
              showNoTaskNotification={this.state.showNoTaskNotification}
              taskList={filteredTaskList}
              handleCloseSnackBar={this.handleCloseSnackBar}
              order={order}
              orderBy={orderBy}
              handleTableSortChange={this.handleTableSortChange}
              members={this.props.members}
            />
          )}
          {
            <NoTasksReportNotification
              data-test="noTasksReportNotification"
              isVisible={this.state.showNoTaskNotification}
              action={
                <IconButton key="close" onClick={this.handleCloseSnackBar}>
                  <Clear className={classes.snackbarCloseIcon} />
                </IconButton>
              }
            />
          }
          {!this.props.isTableReady && <LoadingSpinner />}
        </div>
      </div>
    );
  }

  public handleCustomersSelectOpen = (element: EventTarget) => {
    this.setState({
      openMembers: false,
      openJobtypes: false,
      openCustomers: true,
      anchorEl: element,
    });
  };

  private handleLastDayButtonClick = () => {
    this.props.dispatch(updateStartedDateEnd(moment().startOf('day').toDate()));
    this.props.dispatch(
      updateStartedDateStart(
        moment().subtract(1, 'day').startOf('day').toDate(),
      ),
    );
  };

  private handleLastWeekButtonClick = () => {
    this.props.dispatch(updateStartedDateEnd(moment().startOf('day').toDate()));
    this.props.dispatch(
      updateStartedDateStart(
        moment().subtract(1, 'week').startOf('day').toDate(),
      ),
    );
  };

  private hanldeLastMonthButtonClick = () => {
    this.props.dispatch(updateStartedDateEnd(moment().startOf('day').toDate()));
    this.props.dispatch(
      updateStartedDateStart(
        moment().subtract(1, 'month').startOf('day').toDate(),
      ),
    );
  };

  private handelWorksitesSelectOpen = (element: EventTarget) => {
    this.setState({
      openMembers: false,
      openJobtypes: false,
      openCustomers: false,
      anchorEl: element,
      openStatuses: false,
      openWorksites: true,
    });
  };

  private handelStatusesSelectOpen = (element: EventTarget) => {
    this.setState({
      openMembers: false,
      openJobtypes: false,
      openCustomers: false,
      anchorEl: element,
      openStatuses: true,
      openWorksites: false,
    });
  };

  private handleSelectMembersOpen = (element: EventTarget) => {
    this.setState({
      openMembers: true,
      openJobtypes: false,
      openCustomers: false,
      anchorEl: element,
      openStatuses: false,
      openWorksites: false,
    });
  };

  private handleSelectJobtypesOpen = (element: EventTarget) => {
    this.setState({
      openMembers: false,
      openJobtypes: true,
      openCustomers: false,
      anchorEl: element,
      openStatuses: false,
      openWorksites: false,
    });
  };

  private handleSelectMemberClose = () => {
    this.setState({
      openMembers: false,
      openJobtypes: false,
      openCustomers: false,
      openStatuses: false,
      openWorksites: false,
      anchorEl: undefined,
    });
  };

  private handleSelectClick = <T extends WithKey, A extends AnyAction>(
    value: T & WithKey,
    hiddenValues: Array<T & WithKey> | undefined,
    updateFunction: ActionCreator<A>,
  ) => {
    let filteredHiddenValues: Array<T & WithKey> | undefined = [];
    if (hiddenValues && hiddenValues.find(i => i.key === value.key)) {
      filteredHiddenValues = _.cloneDeep(hiddenValues);
      filteredHiddenValues.splice(
        filteredHiddenValues.findIndex(
          hiddenValue => hiddenValue.key === value.key,
        ),
        1,
      );
    } else {
      filteredHiddenValues = (hiddenValues &&
        [...hiddenValues].concat(value)) || [value];
    }
    this.props.dispatch(updateFunction(filteredHiddenValues));
  };

  private handleStatusesSelectClick = (
    status: TaskStatusRadioButtonOptions,
  ) => {
    const hiddenStatuses = _.cloneDeep(this.props.hiddenStatuses);
    let filteredHiddenStatuses: TaskStatusRadioButtonOptions[] | undefined = [];

    if (
      hiddenStatuses &&
      hiddenStatuses.find(hiddenStatus => hiddenStatus === status)
    ) {
      filteredHiddenStatuses = hiddenStatuses;
      filteredHiddenStatuses.splice(
        filteredHiddenStatuses.findIndex(
          hiddenStatus => hiddenStatus === status,
        ),
        1,
      );
    } else {
      filteredHiddenStatuses = (hiddenStatuses &&
        [...hiddenStatuses].concat(status)) || [status];
    }

    this.props.dispatch(updateHiddenStatuses(filteredHiddenStatuses));
  };

  private getWorksites = async (companyId: string) => {
    try {
      this.unsubscribeJobtypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.WORKSITE)
        .orderBy('name')
        .onSnapshot(snapshot => {
          const worksites: WorksiteWithKey[] = snapshot.empty
            ? []
            : collectionToJsonWithIds<WorksiteWithKey>(snapshot);
          this.setState({
            worksites,
          });
        });
    } catch (error) {
      console.error('Error retrieving the worksites for report view: ' + error);
    }
  };

  private getJobtypes = async (companyId: string) => {
    try {
      this.unsubscribeJobtypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.JOBTYPES)
        .orderBy('name')
        .onSnapshot(snapshot => {
          const jobtypes: JobTypeWithKey[] = snapshot.empty
            ? []
            : collectionToJsonWithIds<JobTypeWithKey>(snapshot);
          this.setState({
            jobtypes,
          });
        });
    } catch (error) {
      console.error('Error retrieving the jobtypes for report view: ' + error);
    }
  };

  /**
   * Asynchronously fetches all customers from Firebase and setSate "customers: CustomerWithKey[]"
   *
   * @param {string} companyId
   */
  private getCustomers = async (companyId: string) => {
    try {
      let customers: CustomerWithKey[] = [];
      this.unsubscribeCustomers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.CUSTOMERS)
        .orderBy('name')
        .onSnapshot(snapshot => {
          customers = snapshot.empty
            ? []
            : collectionToJsonWithIds<CustomerWithKey>(snapshot);
          this.setState({
            customers,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Asynchronously fetches all tasks for particular user from Firebase and dispatch "taskList: TaskWithKey[]"
   *
   * @param {string} companyId
   * @param {string} memberId
   */
  private getTaskList = async (companyId: string) => {
    const startDate = this.props.filterOptions.startedDateStart;
    const endDate = this.props.filterOptions.startedDateEnd;
    try {
      this.setState({ showNoTaskNotification: false });
      let tasks: TaskWithKey[] = [];

      if (startDate) {
        this.unsubscribeTasks = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.TASKS)
          .where('start', '>=', startDate.valueOf())
          .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            tasks = snapshot.empty
              ? []
              : collectionToJsonWithIds<TaskWithKey>(snapshot);
            const validTasks = tasks.filter(task => task.timerLog);
            this.props.dispatch(updateTaskList(validTasks));

            if (validTasks.length < 1) {
              this.setState({ showNoTaskNotification: true });
              this.props.dispatch(updateIsTableReady(true));
            }
          });
      } else if (endDate) {
        this.unsubscribeTasks = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.TASKS)
          .where('end', '<=', endDate.valueOf())
          .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            tasks = snapshot.empty
              ? []
              : collectionToJsonWithIds<TaskWithKey>(snapshot);
            const validTasks = tasks.filter(task => task.timerLog);
            this.props.dispatch(updateTaskList(validTasks));
            if (validTasks.length < 1) {
              this.setState({ showNoTaskNotification: true });
              this.props.dispatch(updateIsTableReady(true));
            }
          });
      } else {
        this.unsubscribeTasks = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.TASKS)
          .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            tasks = snapshot.empty ? [] : collectionToJsonWithIds(snapshot);
            const validTasks = tasks.filter(task => task.timerLog);
            this.props.dispatch(updateTaskList(validTasks));
            if (validTasks.length < 1) {
              this.setState({ showNoTaskNotification: true });
              this.props.dispatch(updateIsTableReady(true));
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Filter tasks from state.taskList and pass task list to sortTaskList
   * @param memberId MemberId
   */
  private filterTaskList() {
    const tasks: TaskWithKey[] = [];
    // this.setState({ isLoading: true });
    const {
      hiddenMembers,
      hiddenCustomers,
      hiddenJobtypes,
      hiddenStatuses,
      hiddenWorksites,
    } = this.props;
    const { startedDateStart, startedDateEnd } = this.props.filterOptions;

    const hiddenMembersIds: Array<string | undefined> = hiddenMembers
      ? hiddenMembers.map(i => i.key)
      : [];
    const hiddenCustomersIds: Array<string | undefined> = hiddenCustomers
      ? hiddenCustomers.map(i => i.key)
      : [];
    const hiddenJobtypesIds: Array<string | undefined> = hiddenJobtypes
      ? hiddenJobtypes.map(i => i.key)
      : [];

    const hiddenWorksitesIds: Array<string | undefined> = hiddenWorksites
      ? hiddenWorksites.map(i => i.key)
      : [];

    for (const task of this.props.taskList) {
      if (hiddenJobtypesIds.includes(task.jobtypeId)) {
        continue;
      }

      if (hiddenCustomersIds.includes(task.customerId)) {
        continue;
      }

      if (task.worksite && hiddenWorksitesIds.includes(task.worksite.id)) {
        continue;
      }

      if (
        (startedDateStart &&
          moment(startedDateStart).valueOf() > task.timerLog![0].started) ||
        (startedDateEnd &&
          moment(startedDateEnd).valueOf() < task.timerLog![0].started)
      ) {
        continue;
      }

      if (
        hiddenStatuses &&
        hiddenStatuses
          .map(status => status.toString())
          .includes(task.status.toString())
      ) {
        continue;
      }

      if (task.timerLog && task.timerLog.length > 0) {
        const listOfTaskMembersIds = _.uniq(
          task.timerLog.map(log => log.memberId),
        );

        const matchingHiddenMemebersIds = _.intersection(
          hiddenMembersIds,
          listOfTaskMembersIds,
        );

        if (matchingHiddenMemebersIds.length === listOfTaskMembersIds.length) {
          continue;
        }
        tasks.push(task);
      }
    }
    this.sortTaskList(tasks);
  }

  /**
   * Sort the task list according to selected value and order, then setState {showNoTaskNotification: boolean} and dispatch filteredTasks
   *
   * @param {TaskWithKey[]} tasks
   */
  private sortTaskList = (tasks: TaskWithKey[]) => {
    let sortedTasks: TaskWithKey[] = [];
    const { orderBy, order } = this.props;
    switch (orderBy) {
      case OrderByValues.taskName: {
        sortedTasks = this.taskNameSort(tasks);
        break;
      }
      case OrderByValues.jobtypeName: {
        sortedTasks = this.taskJobtypeSort(tasks);
        break;
      }
      case OrderByValues.completedDate: {
        sortedTasks = this.taskCompletedDateSort(tasks);
        break;
      }
      case OrderByValues.startedDate: {
        sortedTasks = this.taskStartedDateSort(tasks);
        break;
      }
      case OrderByValues.customerName: {
        sortedTasks = this.taskCustomerSort(tasks);
        break;
      }
      case OrderByValues.worksiteName: {
        sortedTasks = this.taskWorksiteSort(tasks);
        break;
      }
      case OrderByValues.workingHours: {
        sortedTasks = this.taskWorkingHoursSort(tasks);
        break;
      }
      default:
        return;
    }
    if (order === OrderOptions.desc) {
      sortedTasks.reverse();
    }
    this.setState({
      showNoTaskNotification: tasks.length === 0,
    });
    this.props.dispatch(updateIsTableReady(true));
    this.props.dispatch(updateFilteredTaskList(sortedTasks));
  };

  /**
   * Create CSV headers for exporting task report.
   * It goes through settings and include or exclude related CSV columns
   */
  private createCsvHeaders = () => {
    const { settings } = this.props;

    // Every CSV report will these columns despite selected settings
    const headers: CsvHeader[] = [
      { label: i18n().concepts.member, key: 'member' },
      { label: i18n().ui.task_name, key: 'task' },
      { label: i18n().ui.started, key: 'started' },
      { label: i18n().ui.completed, key: 'completed' },
      { label: i18n().ui.working_time, key: 'workingTime' },
    ];

    // Go through settings and paste rows after "task" column
    if (settings.general.enableVat) {
      headers.push({ label: i18n().ui.VAT, key: 'vat' });
    }
    if (settings.general.enableWorksite) {
      headers.splice(1, 0, { label: i18n().ui.worksite, key: 'worksite' });
    }
    if (settings.general.enableCustomer) {
      headers.splice(1, 0, { label: i18n().ui.customer, key: 'customer' });
    }
    if (settings.general.enableJobType) {
      headers.splice(1, 0, { label: i18n().concepts.jobtype, key: 'jobtype' });
    }
    if (settings.general.enableCustomerTaskId) {
      headers.splice(2, 0, {
        label: i18n().ui.task_id,
        key: 'customerTaskId',
      });
    }

    return headers;
  };

  // TODO: Possible refactoring - same data formatting is performed in TaskReportTable for rendering table data
  /**
   * Parse task report data in CSV format
   */
  private parseCsvData = () => {
    const { filteredTaskList, members } = this.props;

    const filteredCsvTaskList: CSVRow[] = [];

    filteredTaskList &&
      filteredTaskList.forEach((task: TaskWithKey) => {
        const listOfTaskUsersIds = getAllUsersIdsFromTimerLogs(task);

        listOfTaskUsersIds.forEach(id => {
          const user = members.find(member => id === member.key);
          const userName = user ? user.name : i18n().ui.unknown_user;
          const startedDate = getTaskStartedTimestampForUserId(task, id);
          const completedDate = getTaskEndedTimestampForUserId(task, id);
          const workingTime = getUserTimerLogsDuration(task, false, id);

          const row = this.generateRowForCSV(
            task,
            userName,
            id,
            startedDate,
            completedDate,
            workingTime,
          );

          filteredCsvTaskList.push(row);
        });
      });

    return filteredCsvTaskList;
  };

  private generateRowForCSV = (
    task: TaskWithKey,
    userName: string,
    userId: string,
    startedDate?: number,
    completedDate?: number,
    workingTime?: number,
  ) => {
    const { settings } = this.props;

    const csvRow: CSVRow = {
      member: userName,
      task: task.title ? task.title.value : task.jobtypeName,
      started: startedDate
        ? moment(startedDate).format('D.M.YYYY HH:mm')
        : task.timerLog
        ? moment(task.timerLog[0].started).format('D.M.YYYY HH:mm')
        : ' ',
      completed: completedDate
        ? moment(completedDate).format('D.M.YYYY HH:mm')
        : task.timerLog
        ? moment(task.timerLog[task.timerLog.length - 1].ended).format(
            'D.M.YYYY HH:mm',
          )
        : ' ',
      workingTime:
        (workingTime &&
          momentDurationFormat(workingTime, DurationFormatPreset.HHMMSS)) ||
        momentDurationFormat(
          roundToNext(
            getUserTimerLogsDuration(task, false, userId),
            'millisecond',
          ),
          DurationFormatPreset.HHMMSS,
        ),
    };

    if (settings.general.enableJobType) {
      csvRow.jobtype = task.jobtypeName;
    }
    if (settings.general.enableCustomer) {
      csvRow.customer = task.customerName || i18n().ui.no_customer;
    }
    if (settings.general.enableWorksite) {
      if (settings.general.enableWorksiteNick) {
        csvRow.worksite = task.worksite
          ? task.worksite.nick
          : i18n().ui.no_worksite;
      } else {
        csvRow.worksite = task.worksite
          ? task.worksite.name
          : i18n().ui.no_worksite;
      }
    }
    if (settings.general.enableVat) {
      csvRow.vat =
        task.vat && task.vat.percent >= 0
          ? `${task.vat.percent}% (${task.vat.name})`
          : i18n().ui.select_vat;
    }

    if (settings.general.enableCustomerTaskId) {
      csvRow.customerTaskId = task.customerTaskId ? task.customerTaskId : '';
    }

    return csvRow;
  };

  private handleCloseSnackBar = () => {
    this.setState({
      showNoTaskNotification: false,
    });
  };

  private handleStartedDateStartChange = (date: Date) => {
    this.props.dispatch(updateStartedDateStart(date));
  };

  private handleStartedDateEndChange = (date: Date) => {
    this.props.dispatch(updateStartedDateEnd(date));
  };

  private handleClose = () => {
    this.setState({
      openCustomers: false,
      openHours: false,
    });
  };

  private handleHoursChange = (event: any, newValue: HourTypeLabel) => {
    this.props.dispatch(updateSelectedHourType(newValue));
  };

  /** Handle table order change
   * Invoked everytime when user click to orderable table title cell.
   * If the same cell cliked, then just change the "order", if another cell clicked, then dispatch the "order" to default 'asc' and dispatch new "orderBy" value
   * After all, in cDU calls filterTaskList()
   *
   * @param {OrderByValues} orderBy
   */
  private handleTableSortChange = (orderBy: OrderByValues) => {
    if (this.props.orderBy === orderBy) {
      this.props.dispatch(
        updateTableSortOptions(this.getOppositeOrderOption(), undefined),
      );
    } else {
      this.props.dispatch(updateTableSortOptions(OrderOptions.asc, orderBy));
    }
  };

  /**
   * Returns the opposite value of "order" variable from props
   */
  private getOppositeOrderOption = () => {
    const order = this.props.order;
    if (order === OrderOptions.asc) {
      return OrderOptions.desc;
    } else {
      return OrderOptions.asc;
    }
  };

  /**
   * Sort provided task array by task name
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskNameSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => {
      let task1Name = task1.jobtypeName;
      let task2Name = task2.jobtypeName;
      if (task1.title) {
        task1Name = task1.title.value;
      }
      if (task2.title) {
        task2Name = task2.title.value;
      }
      const result = task1Name.localeCompare(task2Name);
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Sort provided task array by task jobtype name
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskJobtypeSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => {
      const task1Jobtype = task1.jobtypeName;
      const task2Jobtype = task2.jobtypeName;
      const result = task1Jobtype.localeCompare(task2Jobtype);
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Sort provided task array by task customer name
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskCustomerSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => {
      const task1CustomerName = task1.customerName || '';
      const task2CustomerName = task2.customerName || '';
      const result = this.possibleEmptylValuesSort(
        task1CustomerName,
        task2CustomerName,
      );
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Sort provided task array by task worksite name
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskWorksiteSort = (tasks: TaskWithKey[]) => {
    const isWorkisteNicknameEnabled =
      this.props.settings.general.enableWorksiteNick;
    tasks.sort((task1, task2) => {
      let task1Worksite = '';
      let task2Worksite = '';
      if (task1.worksite) {
        task1Worksite = isWorkisteNicknameEnabled
          ? task1.worksite.nick
          : task1.worksite.name;
      }
      if (task2.worksite) {
        task2Worksite = isWorkisteNicknameEnabled
          ? task2.worksite.nick
          : task2.worksite.name;
      }
      const result = this.possibleEmptylValuesSort(
        task1Worksite,
        task2Worksite,
      );
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Sort provided task array by task completion date
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskCompletedDateSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => this.taskDefaultSort(task1, task2));
    return tasks;
  };

  /**
   * Sort provided task array by task starting date
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskStartedDateSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => {
      const task1Started = task1.timerLog![0].started;
      const task2Started = task2.timerLog![0].started;
      const result = task1Started - task2Started;
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Sort provided task array by working hours
   *
   * @param {TaskWithKey[]} tasks
   */
  private taskWorkingHoursSort = (tasks: TaskWithKey[]) => {
    tasks.sort((task1, task2) => {
      const task1WorkingHours = getTaskTimerTotal(task1);
      const task2WorkingHours = getTaskTimerTotal(task2);
      const result = task1WorkingHours - task2WorkingHours;
      return result === 0 ? this.taskDefaultSort(task1, task2) : result;
    });
    return tasks;
  };

  /**
   * Function used by sorting methods to place data with empty records to the bottom in 'asc' order
   *
   * @param {string} value1
   * @param {string} value2
   */
  private possibleEmptylValuesSort = (value1: string, value2: string) => {
    let result = value1.localeCompare(value2);
    if (!value1 && value2) {
      result = 1;
    } else if (value1 && !value2) {
      result = -1;
    }
    return result;
  };

  /**
   * Default sorting method that sorts two tasks according to their completion dates
   *
   * @param {TaskWithKey} task1
   * @param {TaskWithKey} task2
   */
  private taskDefaultSort = (task1: TaskWithKey, task2: TaskWithKey) => {
    const task1Ended = task1.timerLog![task1.timerLog!.length - 1].ended!;
    const task2Ended = task2.timerLog![task2.timerLog!.length - 1].ended!;
    return task1Ended - task2Ended;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TaskReportContainerProps>,
) => {
  return {
    ...ownProps,
    settings: state.company.activeCompany.settings,
    selectedMember: state.taskReport.selectedMember,
    selectedHourType: state.taskReport.selectedHourType,
    filterOptions: state.taskReport.filterOptions,
    taskList: state.taskReport.taskList,
    filteredTaskList: state.taskReport.filteredTaskList,
    order: state.taskReport.order,
    orderBy: state.taskReport.orderBy,
    hiddenMembers: state.taskReport.hiddenMembers,
    hiddenJobtypes: state.taskReport.hiddenJobtypes,
    hiddenCustomers: state.taskReport.hiddenCustomers,
    hiddenStatuses: state.taskReport.hiddenStatuses,
    hiddenWorksites: state.taskReport.hiddenWorksites,
    isTableReady: state.taskReport.isTableReady,
  } as TaskReportContainerProps;
};

export default withRouter<any>(
  connect<TaskReportContainerProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(TaskReportContainer),
  ),
);
