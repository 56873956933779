import { ActionCreator, AnyAction } from 'redux';

export const enum ShiftActionType {
  SHIFT_NEW = 'SHIFT_NEW',
  SHIFT_CANCEL = 'SHIFT_CANCEL',
  SET_TOTAL_PROGRESS = 'SET_TOTAL_PROGRESS',
  SET_CURRENT_PROGRESS = 'SET_CURRENT_PROGRESS',
  SET_PROGRESS_MESSAGE = 'SET_PROGRESS_MESSAGE',
  INCREMENT_PROGRESS = 'INCREMENT_PROGRESS',
}

export interface NewShiftAction extends AnyAction {
  type: ShiftActionType.SHIFT_NEW;
}

export const newShift: ActionCreator<NewShiftAction> = () => {
  return {
    type: ShiftActionType.SHIFT_NEW,
  };
};

export interface CancelShiftAction extends AnyAction {
  type: ShiftActionType.SHIFT_CANCEL;
}

export const cancelShift: ActionCreator<CancelShiftAction> = () => ({
  type: ShiftActionType.SHIFT_CANCEL,
});
export interface SetTotalProgressAction extends AnyAction {
  type: ShiftActionType.SET_TOTAL_PROGRESS;
  payload: number;
}

export const setTotalProgress: ActionCreator<SetTotalProgressAction> = (
  totalProgress: number,
) => ({
  type: ShiftActionType.SET_TOTAL_PROGRESS,
  payload: totalProgress,
});

export interface SetCurrentProgressAction extends AnyAction {
  type: ShiftActionType.SET_CURRENT_PROGRESS;
  payload: number;
}

export const setCurrentProgress: ActionCreator<SetCurrentProgressAction> = (
  currentProgress: number,
) => ({
  type: ShiftActionType.SET_CURRENT_PROGRESS,
  payload: currentProgress,
});

export interface IncrementProgressAction extends AnyAction {
  type: ShiftActionType.INCREMENT_PROGRESS;
}

export const incrementProgress: ActionCreator<IncrementProgressAction> = (
  currentProgress: number,
) => ({
  type: ShiftActionType.INCREMENT_PROGRESS,
});

export interface SetProgressMessage extends AnyAction {
  type: ShiftActionType.SET_PROGRESS_MESSAGE;
  payload: string;
}

export const setProgressMessage: ActionCreator<SetProgressMessage> = (
  message: string,
) => ({
  type: ShiftActionType.SET_PROGRESS_MESSAGE,
  payload: message,
});

export type ShiftAction =
  | AnyAction
  | NewShiftAction
  | CancelShiftAction
  | SetTotalProgressAction
  | SetCurrentProgressAction
  | SetProgressMessage;
