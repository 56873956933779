import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  badge: {
    marginTop: '8px',
    marginRight: '5px',
    height: '15px',
    width: '15px',
  },
});

export default styles;
