import { MemberWithKey, WorkingHoursWithKey } from '@shared/schema/index';
import { ReportType } from '@shared/schema/reports/index';
import { ActionCreator, AnyAction } from 'redux';
import { HourTypeWithKey } from '../../../../@shared/schema/index';

export const enum ReportsActionType {
  REPORTS_UPDATE_MEMBERLIST = 'REPORTS_UPDATE_MEMBERLIST',
  REPORTS_UPDATE_TIME_RANGE_FILTERS = 'REPORTS_UPDATE_TIME_RANGE_FILTERS',
  REPORTS_UPDATE_SELECTED_MEMBER = 'REPORTS_UPDATE_SELECTED_MEMBER',
  REPORTS_UPDATE_SELECTED_EXPORT_CSV_DATA = 'REPORTS_UPDATE_SELECTED_EXPORT_CSV_DATA',
  REPORTS_UPDATE_SELECTED_DATE = 'REPORTS_UPDATE_SELECTED_DATE',
  REPORTS_UPDATE_USER_DATA = 'REPORTS_UPDATE_USER_DATA',
  REPORTS_DELETE_USER_DATA = 'REPORTS_DELETE_USER_DATA',
  REPORTS_UPDATE_HOURTYPES = 'REPORTS_UPDATE_HOURTYPES',
}

export interface UpdateMemberReportsAction extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_MEMBERLIST;
  payload: {
    selectedMembers: MemberWithKey[];
  };
}

export interface UpdateTimeRangeReportsAction extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_TIME_RANGE_FILTERS;
  payload: {
    dateStart: Date;
    dateEnd: Date;
  };
}

export interface UpdateSelectedMemberReportsAction extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_SELECTED_MEMBER;
  payload: {
    selectedMember: MemberWithKey;
  };
}

export interface UpdatedSelectedDatesHours extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_SELECTED_DATE;
  payload: {
    selectedDateKey: string;
  };
}
export interface UpdateUserReportData extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_USER_DATA;
  payload: {
    userId: string;
    reportType: ReportType;
    data: WorkingHoursWithKey[];
  };
}

export interface DeleteUserFromReportData extends AnyAction {
  type: ReportsActionType.REPORTS_DELETE_USER_DATA;
  payload: {
    userId: string;
  };
}

export interface UpdateHourTypes extends AnyAction {
  type: ReportsActionType.REPORTS_UPDATE_HOURTYPES;
  payload: {
    hourTypes: HourTypeWithKey[];
  };
}

export const updateTimeRangeFilters: ActionCreator<
  UpdateTimeRangeReportsAction
> = (dateStart: Date, dateEnd: Date) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_TIME_RANGE_FILTERS,
    payload: {
      dateStart,
      dateEnd,
    },
  };
};

export const updateSelectedMembers: ActionCreator<UpdateMemberReportsAction> = (
  selectedMembers: MemberWithKey[],
) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_MEMBERLIST,
    payload: {
      selectedMembers,
    },
  };
};

export const updateSelectedMember: ActionCreator<
  UpdateSelectedMemberReportsAction
> = (selectedMember: MemberWithKey) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_SELECTED_MEMBER,
    payload: {
      selectedMember,
    },
  };
};

export const updateSelectedDate: ActionCreator<UpdatedSelectedDatesHours> = (
  selectedDateKey: string,
) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_SELECTED_DATE,
    payload: {
      selectedDateKey,
    },
  };
};

export const updateUserReportData: ActionCreator<UpdateUserReportData> = (
  userId: string,
  reportType: ReportType,
  data: WorkingHoursWithKey[],
) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_USER_DATA,
    payload: {
      userId,
      reportType,
      data,
    },
  };
};

export const deleteUserFromReportData: ActionCreator<
  DeleteUserFromReportData
> = (userId: string) => {
  return {
    type: ReportsActionType.REPORTS_DELETE_USER_DATA,
    payload: {
      userId,
    },
  };
};

export const updateHourTypes: ActionCreator<UpdateHourTypes> = (
  hourTypes: HourTypeWithKey[],
) => {
  return {
    type: ReportsActionType.REPORTS_UPDATE_HOURTYPES,
    payload: {
      hourTypes,
    },
  };
};

export type ReportsAction =
  | AnyAction
  | UpdateMemberReportsAction
  | UpdateTimeRangeReportsAction
  | UpdateSelectedMemberReportsAction
  | UpdatedSelectedDatesHours
  | UpdateUserReportData
  | DeleteUserFromReportData
  | UpdateHourTypes;
