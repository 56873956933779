import { Button, withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { i18n } from '@shared/locale';
import ErrorBoundary from 'components/ErrorBoundary';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'reducers';
import styled from 'styled-components';
import { validateForm } from 'ts-form-validation';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
} from 'ts-form-validation';
import * as validator from 'validator';
import { openRegistration } from '../../reducers/auth/authActions';
import {
  Environment,
  Label,
  LoginImage,
  PrivacyPolicyContainer,
  ResetButtonContainer,
  TextFieldInput,
} from './LoginPage';
import {
  getLogo,
  LoginButtonContainer,
  LoginWithEmailContainer,
} from './LoginPage';
import styles from './styles';

interface Register {
  email: string;
  password: string;
  confirm_password: string;
}
interface State {
  snackCanOpen: boolean;
  registerForm: Form<Register>;
  snackError: string;
}
enum RegisterFieldNames {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

export const RegisterPaper = styled('div')`
  padding: 2rem 2rem 0.5rem 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: auto;
  background-color: none;
  color: white;
`;

const rules: FormValidationRules<Register> = {
  defaultMessages: {
    requiredField: () => i18n().auth.required_fields_login,
  },
  fields: {
    email: {
      required: true,
      validate: (value: string) =>
        !validator.isEmail(value) && {
          type: MessageType.ERROR,
          message: i18n().ui.invalid_email,
        },
    },
    password: {
      required: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 6 }) && {
          type: MessageType.ERROR,
          message: i18n().auth.pw_six_characters,
        },
    },

    confirm_password: {
      required: true,
    },
  },
  validateForm: form => {
    const messages = {};
    const { password, confirm_password } = form.values;

    if (
      form.filled.password &&
      form.filled.confirm_password &&
      password !== confirm_password
    ) {
      return {
        ...form,
        messages: {
          ...messages,
          confirm_password: {
            type: MessageType.ERROR,
            message: i18n().auth.pw_dont_match,
          },
        },
        formMessage: {
          type: MessageType.ERROR,
          message: i18n().auth.pw_dont_match,
        },
      };
    }
    return { ...form, messages };
  },
};

class RegisterPage extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      snackCanOpen: false,
      snackError: '',
      registerForm: initForm<Register>(
        {
          email: '',
          password: '',
          confirm_password: '',
        },
        rules,
      ),
    };
  }
  public render() {
    const { classes } = this.props;
    const { snackCanOpen, registerForm, snackError } = this.state;
    const { values, isFormValid } = registerForm;

    return (
      <RegisterPaper>
        <LoginImage src={getLogo()} />
        <ErrorBoundary>
          {CONFIG === undefined ||
            (CONFIG.build.environment !== 'production' && (
              <Environment>
                {CONFIG ? CONFIG.build.environment : 'develop'}
              </Environment>
            ))}
        </ErrorBoundary>

        <LoginWithEmailContainer>
          <Label>{i18n().ui.email}</Label>
          <TextFieldInput
            variant="filled"
            type="email"
            margin="dense"
            placeholder={i18n().ui.email}
            value={values.email}
            onChange={this.handleChange(RegisterFieldNames.EMAIL)}
            onBlur={this.handleDialogBlur(RegisterFieldNames.EMAIL)}
            error={this.checkErrorStatus(RegisterFieldNames.EMAIL)}
            helperText={this.getErrorMessage(RegisterFieldNames.EMAIL)}
            InputProps={{
              classes: { input: classes.input },
            }}
            FormHelperTextProps={{ classes: { error: classes.text } }}
          />
          <br />
          <Label>{i18n().auth.pw}</Label>
          <TextFieldInput
            variant="filled"
            type="password"
            margin="dense"
            placeholder={i18n().auth.pw}
            value={values.password}
            onChange={this.handleChange(RegisterFieldNames.PASSWORD)}
            onBlur={this.handleDialogBlur(RegisterFieldNames.PASSWORD)}
            error={this.checkErrorStatus(RegisterFieldNames.PASSWORD)}
            helperText={this.getErrorMessage(RegisterFieldNames.PASSWORD)}
            InputProps={{
              classes: { input: classes.input },
            }}
            FormHelperTextProps={{ classes: { error: classes.text } }}
          />
          <Label>{i18n().auth.confirm_pw}</Label>
          <TextFieldInput
            variant="filled"
            type="password"
            margin="dense"
            placeholder={i18n().auth.pw}
            value={values.confirm_password}
            onChange={this.handleChange(RegisterFieldNames.CONFIRM_PASSWORD)}
            onBlur={this.handleDialogBlur(RegisterFieldNames.CONFIRM_PASSWORD)}
            error={this.checkErrorStatus(RegisterFieldNames.CONFIRM_PASSWORD)}
            helperText={this.getErrorMessage(
              RegisterFieldNames.CONFIRM_PASSWORD,
            )}
            InputProps={{
              classes: { input: classes.input },
            }}
            FormHelperTextProps={{ classes: { error: classes.text } }}
          />
          <br />
          <LoginButtonContainer>
            <Button
              type="button"
              variant="contained"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={this.confirmRegister}
              disabled={!isFormValid}
              classes={{
                disabled: classes.disabled,
              }}
            >
              <span style={{ color: isFormValid ? 'black' : 'grey' }}>
                {i18n().auth.register}
              </span>
            </Button>
          </LoginButtonContainer>
          <br />
          <br />
          <ResetButtonContainer>
            <Button
              type="button"
              variant="text"
              classes={{ text: classes.text }}
              onClick={this.handleCancelClick}
            >
              {i18n().ui.cancel}
            </Button>
          </ResetButtonContainer>
          <br />
        </LoginWithEmailContainer>
        {snackError &&
          snackCanOpen &&
          this.showNotificationSnackBar(snackError)}
        <PrivacyPolicyContainer>
          <a
            className={classes.privacy_policy_text}
            href={i18n().links.privacy_policy}
            target="_blank"
          >
            {i18n().ui.privacy_policy}
          </a>
        </PrivacyPolicyContainer>
      </RegisterPaper>
    );
  }

  /**
   * Dispatch a registration state to redux
   */
  private handleCancelClick = () => {
    this.props.dispatch(openRegistration(false));
  };

  private handleChange = (item: keyof Register) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const values = {
      ...this.state.registerForm.values,
      [item]: e.target.value,
    };

    const registerForm = validateForm(
      {
        ...this.state.registerForm,
        values,
      },
      {
        usePreprocessor: false,
      },
    );

    this.setState({
      registerForm,
    });
  };

  private handleDialogBlur = (item: keyof Register) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let registerForm = { ...this.state.registerForm };

    const filled = {
      ...registerForm.filled,
      [item]: true,
    };

    registerForm = validateForm({
      ...this.state.registerForm,
      filled,
    });

    this.setState({
      registerForm,
    });
  };

  private checkErrorStatus = (target: RegisterFieldNames): boolean => {
    const {
      messages: { [target]: message },
    } = this.state.registerForm;

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  private getErrorMessage = (target: RegisterFieldNames) => {
    const {
      messages: { [target]: message },
    } = this.state.registerForm;

    return message && message.message;
  };

  /**
   * Check that the information is correct before trying to register
   */
  private confirmRegister = () => {
    const {
      email,
      password,
      confirm_password,
    } = this.state.registerForm.values;
    if (
      email &&
      password &&
      confirm_password &&
      password === confirm_password &&
      password.length >= 6
    ) {
      this.onRegisterWithEmail(email, password);
    }
  };
  /**
   * Displays given message in SnackBar component
   * @param {string} message
   */
  private showNotificationSnackBar = (message: string) => {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={true}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span>{message}</span>}
          onClose={this.resetSnackError}
        />
      </div>
    );
  };

  /**
   *  Resets SnackBar state to initial state.
   */
  private resetSnackError = () => {
    this.setState({ snackError: '', snackCanOpen: false });
  };

  /**
   * Register with email address
   */

  private onRegisterWithEmail = (email: string, password: string) => {
    if (email && password) {
      firebaseApp
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          this.setState({ snackError: i18n().ui.completed });
        })
        .catch(error => {
          console.log(error);
          switch (error.message) {
            case 'The email address is badly formatted.':
              this.setState({
                snackError: i18n().auth.email_bad_format,
                snackCanOpen: true,
              });
              break;
            case 'The password is invalid or the user does not have a password.':
              this.setState({
                snackError: i18n().auth.invalid_pw,
                snackCanOpen: true,
              });
              break;
            case 'The email address is already in use by another account.':
              this.setState({
                snackError: i18n().auth.user_already_exists,
                snackCanOpen: true,
              });
              break;
            default:
              this.setState({
                snackError: i18n().auth.something_went_wrong,
                snackCanOpen: true,
              });
              break;
          }
        });
    }
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: any): any => {
  return {
    ...ownProps,
    authenticating: state.auth.authenticating,
    firebaseError: state.auth.firebaseError,
    appUser: state.auth.appUser,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(RegisterPage),
);
