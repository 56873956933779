import { Reducer } from 'redux';

import { MemberWithKey, WorkingHoursByHourTypeForMember } from '@shared/schema';
import { WorkTimeReports } from '@shared/schema/reports';
import { HourTypeWithKey } from '../../../../@shared/schema/index';
import { ReportsAction, ReportsActionType } from './reportsActions';

export interface ReportsState {
  selectedMembers: MemberWithKey[];
  dateStart: Date;
  dateEnd: Date;
  selectedMember: MemberWithKey | undefined;
  dataToExport: WorkingHoursByHourTypeForMember[] | undefined;
  selectedDateKey: string | undefined;
  hourTypes: HourTypeWithKey[];
  reports: WorkTimeReports | undefined;
}

const initialState: ReportsState = {
  selectedMembers: [],
  dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  dateEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  hourTypes: [],
  selectedMember: undefined,
  dataToExport: undefined,
  selectedDateKey: undefined,
  reports: undefined,
};

export const reportsReducer: Reducer<ReportsState> = (
  state = initialState,
  action: ReportsAction,
) => {
  switch (action.type) {
    case ReportsActionType.REPORTS_UPDATE_MEMBERLIST:
      return {
        ...state,
        selectedMembers: action.payload.selectedMembers,
      };

    case ReportsActionType.REPORTS_UPDATE_TIME_RANGE_FILTERS:
      return {
        ...state,
        dateStart: action.payload.dateStart,
        dateEnd: action.payload.dateEnd,
      };

    case ReportsActionType.REPORTS_UPDATE_SELECTED_MEMBER:
      return {
        ...state,
        selectedMember:
          state.selectedMember === undefined
            ? action.payload.selectedMember
            : undefined,
      };

    case ReportsActionType.REPORTS_UPDATE_SELECTED_EXPORT_CSV_DATA:
      return {
        ...state,
        dataToExport: action.payload.dataToExport,
      };

    case ReportsActionType.REPORTS_UPDATE_SELECTED_DATE:
      return {
        ...state,
        selectedDateKey: action.payload.selectedDateKey,
      };

    case ReportsActionType.REPORTS_UPDATE_USER_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.userId]: {
            [action.payload.reportType]: action.payload.data,
          },
        },
      };

    case ReportsActionType.REPORTS_DELETE_USER_DATA:
      const newState = { ...state };
      let newUserData: WorkTimeReports = {};

      newState.selectedMembers.forEach(sMember => {
        if (
          newState.reports &&
          sMember.key &&
          action.payload.userId !== sMember.key
        ) {
          newUserData = {
            ...newUserData,
            [sMember.key]: newState.reports[sMember.key],
          };
        }
      });

      return {
        ...state,
        reports: newUserData,
      };

    case ReportsActionType.REPORTS_UPDATE_HOURTYPES:
      return {
        ...state,
        hourTypes: action.payload.hourTypes,
      };

    default:
      return state;
  }
};
