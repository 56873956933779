import { Reducer } from 'redux';

import {
  CustomerWithKey,
  getHourTypeMap,
  HourTypeLabel,
  JobTypeWithKey,
  MemberWithKey,
  TaskWithKey,
  WorksiteWithKey,
} from '@shared/schema';
import {
  OrderByValues,
  OrderOptions,
  TaskStatusRadioButtonOptions,
} from 'containers/Private/Reports/containers/TaskReport/containers/Task/containers/TaskReportContainer/TaskReportContainer';
import { TaskReportAction, TaskReportActionType } from './reportActions';

export interface TaskReportFilters {
  jobTypeSearch: string; // Filter
  selectedCustomer?: CustomerWithKey; // Filter
  startedDateStart: Date | null; // filter
  startedDateEnd: Date | null; // Filter
  completedDateStart: Date | null; // Filter
  completedDateEnd: Date | null; // Filter
}
export interface TaskReportState {
  hiddenMembers?: MemberWithKey[];
  hiddenCustomers?: CustomerWithKey[];
  hiddenJobtypes?: JobTypeWithKey[];
  hiddenStatuses?: TaskStatusRadioButtonOptions[];
  hiddenWorksites?: WorksiteWithKey[];
  selectedMember?: MemberWithKey;
  selectedHourType: HourTypeLabel;
  filterOptions: TaskReportFilters;
  taskList: TaskWithKey[];
  filteredTaskList: TaskWithKey[];
  orderBy: OrderByValues;
  order: OrderOptions;
  selectedTaskKey: string | undefined;
  isTableReady: boolean;
}

export const initialState: TaskReportState = {
  filterOptions: {
    jobTypeSearch: '',
    startedDateStart: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1,
      0,
      0,
    ),
    startedDateEnd: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
      23,
      59,
    ),
    completedDateStart: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1,
      0,
      0,
    ),
    completedDateEnd: null,
  },
  isTableReady: false,
  selectedHourType: { ...getHourTypeMap()[0] },
  taskList: [],
  filteredTaskList: [],
  order: OrderOptions.asc,
  orderBy: OrderByValues.completedDate,
  selectedTaskKey: undefined,
};

export const taskReportReducer: Reducer<TaskReportState> = (
  state = initialState,
  action: TaskReportAction,
) => {
  switch (action.type) {
    case TaskReportActionType.UPDATE_ISTABLEREADY:
      return {
        ...state,
        isTableReady: action.payload.isTableReady,
      };
    case TaskReportActionType.UPDATE_HIDDEN_WORKSITES:
      return {
        ...state,
        hiddenWorksites: action.payload.hiddenWorksites,
      };
    case TaskReportActionType.UPDATE_HIDDEN_STATUSES:
      return {
        ...state,
        hiddenStatuses: action.payload.hiddenStatuses,
      };
    case TaskReportActionType.UPDATE_HIDDEN_MEMBERS:
      return {
        ...state,
        hiddenMembers: action.payload.hiddenMembers,
      };
    case TaskReportActionType.UPDATE_HIDDEN_JOBTYPES:
      return {
        ...state,
        hiddenJobtypes: action.payload.hiddenJobtypes,
      };
    case TaskReportActionType.UPDATE_HIDDEN_CUSTOMERS:
      return {
        ...state,
        hiddenCustomers: action.payload.hiddenCustomers,
      };
    case TaskReportActionType.UPDATE_FILTERED_TASK_LIST:
      return {
        ...state,
        filteredTaskList: action.payload.filteredTaskList,
      };
    case TaskReportActionType.UPDATE_SELECTED_HOUR_TYPE:
      return { ...state, selectedHourType: action.payload.selectedHourType };
    case TaskReportActionType.UPDATE_JOBTYPE_SEARCH:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          jobTypeSearch: action.payload.jobTypeSearch,
        },
      };
    case TaskReportActionType.UPDATE_SELECTED_CUSTOMER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedCustomer: action.payload.selectedCustomer,
        },
      };
    case TaskReportActionType.UPDATE_SELECTED_TASK_STATUS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          selectedTaskStatus: action.payload.selectedTaskStatus,
        },
      };
    case TaskReportActionType.UPDATE_TABLE_SORT_OPTIONS:
      return {
        ...state,
        order: action.payload.order || state.order,
        orderBy: action.payload.orderBy || state.orderBy,
      };
    case TaskReportActionType.UPDATE_TASK_LIST:
      return { ...state, taskList: action.payload.taskList };
    case TaskReportActionType.UPDATE_STARTED_DATE_START:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          startedDateStart: action.payload.startedDateStart,
        },
      };
    case TaskReportActionType.UPDATE_STARTED_DATE_END:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          startedDateEnd: action.payload.startedDateEnd,
        },
      };
    case TaskReportActionType.UPDATE_COMPLETED_DATE_START:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          completedDateStart: action.payload.completedDateStart,
        },
      };
    case TaskReportActionType.UPDATE_COMPLETED_DATE_END:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          completedDateEnd: action.payload.completedDateEnd,
        },
      };
    case TaskReportActionType.UPDATE_SELECTED_MEMBER:
      return { ...state, selectedMember: action.payload.selectedMember };

    case TaskReportActionType.UPDATE_SELECTED_TASK:
      return { ...state, selectedTaskKey: action.payload.selectedTaskKey };

    default:
      return state;
  }
};
