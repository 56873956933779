import { Tab, Tabs } from '@material-ui/core';
import { WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { i18n } from '@shared/locale';
import { MemberWithKey, Schema, UserRole } from '@shared/schema';
import LoadingSpinner from 'components/LoadingSpinner';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { ApplicationState } from '../../../../reducers/index';
import styles from './styles';
import TaskReportsContainer from './TaskReport/containers/Task';
import ProgressReportContainer from './TaskReport/containers/Task/containers/ProgressReportContainer/ProgressReportContainer';
import WorkTimeReportsContainer from './TaskReport/containers/Task/containers/WorkTimeReportContainer/WorkTimeReports';

export interface ReportsContainerProps
  extends StyledComponentProps,
    DispatchProp<any>,
    WithTheme {
  companyId: string;
  activeRole: UserRole;
}

export const enum ReportsTabType {
  TASK = 'TASK',
  WORKING_HOURS = 'WORKING_HOURS',
  PROGRESS_REPORT = 'PROGRESS_REPORT',
}

interface State {
  currentTab: ReportsTabType;
  members: MemberWithKey[];
  isLoading: boolean;
}

export class ReportsContainer extends React.Component<
  ReportsContainerProps,
  State
> {
  public state: State = {
    currentTab: ReportsTabType.TASK,
    members: [],
    isLoading: true,
  };

  private unsubscribeMembers: () => void;

  public componentDidMount() {
    if (this.props.activeRole === UserRole.END_CUSTOMER) {
      this.setState({
        currentTab: ReportsTabType.PROGRESS_REPORT,
        isLoading: false,
      });
    } else {
      this.getUsers(this.props.companyId);
    }
  }

  public componentWillUnmount() {
    this.unsubscribeMembers && this.unsubscribeMembers();
  }

  public render() {
    const { classes = {} } = this.props;
    if (this.state.isLoading) {
      return <LoadingSpinner data-test="loadingSpinner" />;
    }
    return (
      <div className={classes.container} data-test="reportsContainer">
        <div className={classes.tabsBar}>
          <Tabs
            data-test="tabsBar"
            value={this.state.currentTab}
            onChange={this.handleTabChange}
            variant="standard"
          >
            {this.props.activeRole !== UserRole.END_CUSTOMER && (
              <Tab label={i18n().ui.task_report} value={ReportsTabType.TASK} />
            )}
            {this.props.activeRole !== UserRole.END_CUSTOMER && (
              <Tab
                label={i18n().ui.working_time_report}
                value={ReportsTabType.WORKING_HOURS}
              />
            )}
            {this.props.activeRole === UserRole.END_CUSTOMER && (
              <Tab
                label={i18n().ui.task_progress_report}
                value={ReportsTabType.PROGRESS_REPORT}
              />
            )}
          </Tabs>
        </div>
        {this.props.activeRole !== UserRole.END_CUSTOMER &&
          this.state.currentTab === ReportsTabType.TASK && (
            <TaskReportsContainer
              data-test="taskReportContainer"
              companyId={this.props.companyId}
              members={this.state.members}
            />
          )}
        {this.props.activeRole !== UserRole.END_CUSTOMER &&
          this.state.currentTab === ReportsTabType.WORKING_HOURS && (
            <WorkTimeReportsContainer
              companyId={this.props.companyId}
              members={this.state.members}
            />
          )}
        {this.props.activeRole === UserRole.END_CUSTOMER &&
          this.state.currentTab === ReportsTabType.PROGRESS_REPORT && (
            <ProgressReportContainer companyId={this.props.companyId} />
          )}
      </div>
    );
  }

  /**
   * Asynchronously fetches all members from Firebase and setSate "member: Member[]"
   *
   * @param {string} companyId
   */
  private getUsers = async (companyId: string) => {
    try {
      this.unsubscribeMembers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.MEMBERS)
        .orderBy('name')
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const members: MemberWithKey[] = snapshot.empty
            ? []
            : collectionToJsonWithIds<MemberWithKey>(snapshot);
          this.setState({
            members,
            isLoading: false,
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Handles tab changes by setState {currentTab: ReportsTabType}
   */
  private handleTabChange = (
    event: React.ChangeEvent,
    newValue: ReportsTabType,
  ) => {
    this.setState({
      currentTab: newValue,
    });
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ReportsContainerProps>,
) => {
  return {
    ...ownProps,
    activeRole: state.auth.activeRole,
  } as ReportsContainerProps;
};

export default withRouter<any>(
  connect<ReportsContainerProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(ReportsContainer),
  ),
);
