import { Reducer } from 'redux';
import { DateRange, JobtypeSet, MemberSet } from '@shared/schema';
import moment from 'moment';
import { TimelineAction, TimelineActionType } from './timelineActions';

/**
 * Interface describing this reducers part of application state
 */
export interface TimelineState {
  readonly currentDate: moment.Moment;
  readonly end: moment.Moment;
  readonly startHour?: number;
  readonly endHour?: number;
  readonly filter?: string;
  readonly hiddenMembers: MemberSet;
  readonly hiddenJobtypes: JobtypeSet;
  readonly hiddenQualifications: QualificationSet;
  readonly hiddenPriorities: number[];
  readonly selectedDateRange: DateRange;
  messagesForWarningSnackbar: string[];
}

export interface QualificationSet {
  [qualificationId: string]: boolean;
}
/**
 * Default state values used when state is not yer defined
 */

export const defaultState: TimelineState = {
  currentDate: moment().set({ hour: 6, minute: 0, second: 0 }),
  end: moment().set({ hour: 22, minute: 0, second: 0 }),
  startHour: undefined,
  endHour: undefined,
  filter: undefined,
  hiddenMembers: {},
  hiddenJobtypes: {},
  hiddenQualifications: {},
  hiddenPriorities: [],
  selectedDateRange: {
    start: undefined,
    end: undefined,
  },
  messagesForWarningSnackbar: [],
};

/**
 * Actual reducer that will do changes to state based on actions
 *
 * @param state
 * @param action
 */
export const timelineReducer: Reducer<TimelineState> = (
  state = defaultState,
  action: TimelineAction,
) => {
  switch (action.type) {
    case TimelineActionType.TIMELINE_UPDATE_START_END_HOURS: {
      return {
        ...state,
        startHour: action.payload.startHour || state.startHour,
        endHour: action.payload.endHour || state.endHour,
      };
    }
    case TimelineActionType.TIMELINE_STEP_BACKWARD: {
      return {
        ...state,
        currentDate: moment(state.currentDate).subtract(action.payload, 'days'),
        end: moment(state.end).subtract(action.payload, 'days'),
      };
    }

    case TimelineActionType.TIMELINE_STEP_FORWARD: {
      return {
        ...state,
        currentDate: moment(state.currentDate).add(action.payload, 'days'),
        end: moment(state.end).add(action.payload, 'days'),
      };
    }

    case TimelineActionType.TIMELINE_GO_TODAY: {
      const today = moment().set({
        hour: moment.duration(state.startHour, 'milliseconds').asHours(),
        minutes: 0,
        seconds: 0,
      });
      const goTodayDelta = state.currentDate.diff(state.end, 'milliseconds');
      return {
        ...state,
        currentDate: today,
        end: moment(today).add(Math.abs(goTodayDelta), 'milliseconds'),
      };
    }
    case TimelineActionType.TIMELINE_GO_TO_PICKED_DAY: {
      const newState = { ...state };
      newState.currentDate = action.payload;
      newState.currentDate.set({
        hour: moment.duration(state.startHour, 'milliseconds').asHours(),
      });
      // IF the end of the timeframe on timeline is placed at absolut start of the day with zero minutes, seconds and milliseconds, then end of the timeframe brakes; Possible fix is just add a millisecond to an end date
      const delta2 = state.currentDate.diff(state.end, 'milliseconds') + 1;
      newState.end = moment(newState.currentDate).add(
        Math.abs(delta2),
        'milliseconds',
      );
      return newState;
    }

    case TimelineActionType.TIMELINE_SET_RANGE: {
      return {
        ...state,
        currentDate: moment(action.payload.start),
        end: moment(action.payload.end),
      };
    }

    case TimelineActionType.TIMELINE_SET_MEMBER_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case TimelineActionType.TIMELINE_HIDE_MEMBERS: {
      const members = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenMembers = { ...state.hiddenMembers };

      for (const member of members) {
        hiddenMembers[member] = true;
      }

      state = { ...state, hiddenMembers };
      return state;
    }
    case TimelineActionType.TIMELINE_HIDE_ALL_MEMBERS: {
      const members = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenMembers = { ...state.hiddenMembers };

      for (const member of members) {
        hiddenMembers[member] = true;
      }
      if (hiddenMembers['']) {
        delete hiddenMembers[''];
      }
      state = { ...state, hiddenMembers };
      return state;
    }
    case TimelineActionType.TIMELINE_REVEAL_MEMBERS: {
      const members2 = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenMembers2 = { ...state.hiddenMembers };

      for (const member of members2) {
        delete hiddenMembers2[member];
      }

      state = { ...state, hiddenMembers: hiddenMembers2 };
      return state;
    }

    case TimelineActionType.TIMELINE_SET_HIDDEN_MEMBERS: {
      const membersList = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenMembers3 = {};

      for (const member of membersList) {
        hiddenMembers3[member] = true;
      }

      return {
        ...state,
        hiddenMembers: hiddenMembers3,
      };
    }

    case TimelineActionType.TIMELINE_REVEAL_QUALIFICATIONS: {
      const qualificationKeys = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenQualifications =
        (state.hiddenQualifications && { ...state.hiddenQualifications }) || {};

      for (const qualificationKey of qualificationKeys) {
        delete hiddenQualifications[qualificationKey];
      }

      return { ...state, hiddenQualifications };
    }

    case TimelineActionType.TIMELINE_HIDE_QUALIFICATIONS: {
      const qualificationKeys = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenQualifications =
        (state.hiddenQualifications && { ...state.hiddenQualifications }) || {};

      for (const qualificationKey of qualificationKeys) {
        hiddenQualifications[qualificationKey] = true;
      }

      return { ...state, hiddenQualifications };
    }
    case TimelineActionType.TIMELINE_REVEAL_PRIORITIES: {
      const priorityKey = action.payload;

      const hiddenPriorities =
        (state.hiddenPriorities && [...state.hiddenPriorities]) || [];
      hiddenPriorities.splice(hiddenPriorities.indexOf(priorityKey), 1);
      return { ...state, hiddenPriorities };
    }

    case TimelineActionType.TIMELINE_HIDE_PRIORITIES: {
      const priorityKeys = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenPriorities = state.hiddenPriorities && [
        ...state.hiddenPriorities,
      ];
      for (const priorityKey of priorityKeys) {
        hiddenPriorities.push(priorityKey);
      }

      return { ...state, hiddenPriorities };
    }
    case TimelineActionType.TIMELINE_REVEAL_JOBTYPES: {
      const jobtypeKeys = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenTasks =
        (state.hiddenJobtypes && { ...state.hiddenJobtypes }) || {};

      for (const jobtypeKey of jobtypeKeys) {
        delete hiddenTasks[jobtypeKey];
      }

      return { ...state, hiddenJobtypes: hiddenTasks };
    }

    case TimelineActionType.TIMELINE_HIDE_JOBTYPES: {
      const members = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const hiddenTasks =
        (state.hiddenJobtypes && { ...state.hiddenJobtypes }) || {};

      for (const member of members) {
        hiddenTasks[member] = true;
      }

      state = { ...state, hiddenJobtypes: hiddenTasks };
      return state;
    }
    case TimelineActionType.TIMELINE_ORDER_TASKS_BY_ASSIGN:
      return {
        ...state,
      };
    case TimelineActionType.TIMELINE_REVEAL_ALL_MEMBERS:
      return {
        ...state,
        hiddenMembers: {},
      };
    case TimelineActionType.TIMELINE_REVEAL_ALL_ASSIGNED_TASKS:
      return {
        ...state,
        hiddenMembers: { '': true },
      };
    case TimelineActionType.TIMELINE_REVEAL_ALL_JOBTYPES:
      return {
        ...state,
        hiddenJobtypes: {},
      };

    case TimelineActionType.TIMELINE_SET_DATERANGE:
      return {
        ...state,
        selectedDateRange: action.payload,
      };

    case TimelineActionType.OPEN_WARNING: {
      const messagesForWarningSnackbar = [...state.messagesForWarningSnackbar];
      messagesForWarningSnackbar.push(action.payload);
      return {
        ...state,
        messagesForWarningSnackbar,
      };
    }
    case TimelineActionType.DEQUEUE_WARNING: {
      const messagesForWarningSnackbar = [...state.messagesForWarningSnackbar];
      messagesForWarningSnackbar.shift();
      return {
        ...state,
        messagesForWarningSnackbar,
      };
    }

    default:
      return state;
  }
};
