import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  input: {
    backgroundColor: 'white',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '5px',
    height: '1.8em',
  },

  text: {
    color: `${theme.colors.white} !important`,
  },
  disabled: {
    backgroundColor: `${theme.colors.lightGrey} !important`,
  },
  acselector: {
    borderBottomColor: `${theme.colors.white}`,
  },
  googleButton: {
    margin: '0 auto 0 auto',
  },
  privacy_policy_text: {
    color: theme.colors.white,
    textDecoration: 'none',
  },
});

export default styles;
