import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export default (theme: Theme): StyleRules<string> => ({
  tableHead: {
    height: '40px',
    background: theme.palette.primary.main,
  },
  row: {
    height: '40px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  tableBody: {
    height: 'calc(100% - 152px)',
    overflow: 'auto',
  },
  tableCellHeader: {
    position: 'sticky',
    border: '0.25px solid #d0d0d0',
    top: 0,
    zIndex: 2,
  },
  tableCell: {
    border: '0.25px solid #d0d0d0',
  },
  tableFooter: {
    height: '40px',
    background: theme.palette.primary.main,
  },
  tablePagination: {
    height: '40px',
    minHeight: '40px',
  },
  paginationSelect: {
    background: '#fff',
    color: '#000',
    '&:focus': {
      background: '#fff',
    },
  },
});
