import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  userBar: {
    height: '7%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  avatar: {
    marginRight: '15px',
    marginLeft: '15px',
  },
  chatContainer: {
    height: '93%',
    maxHeight: '93%',
  },
});

export default styles;
