export const validateString = (string: string, minLength: number) => {
  return string !== undefined && string.trim().length >= minLength;
};

export const isEmail = (email: string) => {
  const regexp: RegExp = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
  return regexp.test(email.toLowerCase());
};

export const isPostalCode = (postalCode: number | string) => {
  try {
    const postalCodeString = postalCode.toString();
    if (postalCodeString.length !== 5) {
      return false;
    }

    // Check that postal code only contains numbers
    if (!/^\d+$/.test(postalCodeString)) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};

// TODO User google's phonumber library to check validity
export const isPhoneNumber = (phone: string | undefined) => {
  const pattern: RegExp = /^(\(?\+?[0-9]*\)?)?[0-9_\ \(\)]*$/;

  return phone !== undefined && pattern.test(phone);
};
