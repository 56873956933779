import * as React from 'react';
import { Button, MenuItem, Select, WithTheme } from '@material-ui/core';
import TaskReportTable from '../../components/TaskReportTable/TaskReportTable';
import {
  OrderByValues,
  OrderOptions,
} from '../TaskReportContainer/TaskReportContainer';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import styles from './styles';
import classNames from 'classnames';
import { Maximize } from '@material-ui/icons';
import { i18n } from '@shared/locale';
import TaskReportDateTimePicker from '../../components/TaskReportDateTimePicker/TaskReportDateTimePicker';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { Schema, TaskStatus, TaskWithKey, User } from '@shared/schema';
import firebaseApp from 'firebaseApp';
import NoTasksReportNotification from '../../../../components/NoTasksReportNotification';
import moment from 'moment';

interface ProgressReportContainerProps
  extends DispatchProp,
    Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  companyId: string;
  appUser: User;
}

interface State {
  startedDateStart: Date;
  startedDateEnd: Date;
  selectedTaskStatus: TaskStatusAll | TaskStatus;
  tasks: TaskWithKey[];
}

enum TaskStatusAll {
  ALL = 'ALL',
}

export class ProgressReportContainer extends React.Component<
  ProgressReportContainerProps,
  State
> {
  private unsubscribeTasks: () => void;

  constructor(props: ProgressReportContainerProps) {
    super(props);

    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 7);

    this.state = {
      startedDateStart: startDate,
      startedDateEnd: new Date(),
      selectedTaskStatus: TaskStatusAll.ALL,
      tasks: [],
    };
  }

  componentDidMount() {
    this.getTaskList(this.props.companyId);
  }

  componentWillUnmount() {
    this.unsubscribeTasks && this.unsubscribeTasks();
  }

  private handleStartedDateStartChange = (date: Date) => {
    this.setState({
      startedDateStart: date,
    });
  };

  private handleStartedDateEndChange = (date: Date) => {
    this.setState({
      startedDateEnd: date,
    });
  };

  private handleTaskStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.setState({
      selectedTaskStatus: event.target.value as TaskStatusAll | TaskStatus,
    });
  };

  private handleFetchTasks = () => {
    console.log('Fetching tasks');
    this.getTaskList(this.props.companyId);
  };

  /**
   * Asynchronously fetches all tasks for particular user from Firebase and dispatch "taskList: TaskWithKey[]"
   *
   * @param {string} companyId
   * @param {string} memberId
   */
  private getTaskList = async (companyId: string) => {
    try {
      this.unsubscribeTasks = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.TASKS)
        .where('customerName', '==', this.props.appUser.endCustomerCompanyName)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          let tasks: TaskWithKey[] = snapshot.empty
            ? []
            : collectionToJsonWithIds<TaskWithKey>(snapshot);
          this.setState({
            tasks,
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  private filterTasksByCurrentFilters = (
    tasks: TaskWithKey[],
  ): TaskWithKey[] => {
    return tasks
      .filter(
        task =>
          this.state.selectedTaskStatus === TaskStatusAll.ALL ||
          task.status === this.state.selectedTaskStatus,
      )
      .filter(
        task =>
          task.timerLog === undefined ||
          task.timerLog[0] === undefined ||
          moment(new Date(task.timerLog[0].started)).isAfter(
            this.state.startedDateStart,
          ),
      )
      .filter(
        task =>
          task.timerLog === undefined ||
          task.timerLog[0] === undefined ||
          moment(new Date(task.timerLog[0].started)).isBefore(
            this.state.startedDateEnd,
          ),
      );
  };

  render() {
    const { classes = {} } = this.props;
    const { startedDateStart, startedDateEnd, selectedTaskStatus } = this.state;

    const filteredTasks = this.filterTasksByCurrentFilters(this.state.tasks);
    return (
      <div className={classes.container}>
        <div className={classes.bar}>
          <Select
            value={selectedTaskStatus}
            onChange={this.handleTaskStatusChange}
          >
            <MenuItem value={TaskStatusAll.ALL}>{i18n().ui.all}</MenuItem>
            <MenuItem value={TaskStatus.UNDONE}>{i18n().ui.undone}</MenuItem>
            <MenuItem value={TaskStatus.ACTIVE}>{i18n().ui.active}</MenuItem>
            <MenuItem value={TaskStatus.DONE}>{i18n().ui.done}</MenuItem>
            <MenuItem value={TaskStatus.PAUSED}>{i18n().ui.paused}</MenuItem>
            <MenuItem value={TaskStatus.LATE}>{i18n().ui.late}</MenuItem>
          </Select>

          <div className={classes.timeFrameContainer}>
            <div className={classes.timeSelectionContainer}>
              <div className={classNames(classes.dateText)}>
                {i18n().ui.started}
              </div>
              <TaskReportDateTimePicker
                data-test="startedDateStartPicker"
                maxDate={startedDateEnd}
                clearable={startedDateStart}
                value={startedDateStart}
                onChange={this.handleStartedDateStartChange}
              />
              <div className={classNames(classes.dateLine)}>
                <Maximize />
              </div>
              <TaskReportDateTimePicker
                data-test="startedDateEndPicker"
                minDate={startedDateStart}
                clearable={startedDateEnd}
                value={startedDateEnd}
                onChange={this.handleStartedDateEndChange}
              />
            </div>
          </div>
          <Button
            disabled={
              this.props.appUser.endCustomerCompanyName === undefined ||
              this.props.appUser.endCustomerCompanyName === null ||
              this.props.appUser.endCustomerCompanyName === ''
            }
            onClick={this.handleFetchTasks}
          >
            Refresh
          </Button>
        </div>
        <TaskReportTable
          showNoTaskNotification={false}
          order={OrderOptions.asc}
          orderBy={OrderByValues.startedDate}
          taskList={filteredTasks}
          settingsOverrides={{
            enableStatus: true,
          }}
        />
        <NoTasksReportNotification
          isVisible={filteredTasks.length === 0}
          action={<></>}
          textOverride={i18n().ui.no_tasks}
        />
      </div>
    );
  }
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ProgressReportContainerProps>,
) => {
  return {
    ...ownProps,
    settings: state.company.activeCompany.settings,
    appUser: state.auth.appUser,
  } as ProgressReportContainerProps;
};

export default withRouter<any>(
  connect<ProgressReportContainerProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(ProgressReportContainer),
  ),
);
