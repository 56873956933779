import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import tableLayout from 'theme/styles/table';

export const styles = (theme: Theme): StyleRules<string> => ({
  tableWrapper: {
    maxHeight: '100vh',
    height: '63vh',
    overflow: 'auto',
  },

  tableContainer: {
    marginTop: '1rem',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
  tableHead: {
    width: '50%',
  },
  ...{
    ...tableLayout(theme),
    tableCell: {
      width: 'calc(100%/7)',
      border: `0.25px solid ${theme.colors.tableCellBorder}`,
    },
  },
  ...{
    ...tableLayout(theme),
    tableInnerCell: {
      padding: 0,
      width: 'calc(100%/7)',
    },
  },
  ...{
    ...tableLayout(theme),
    iconButtonCell: {
      padding: '0 16px !important',
      border: `0.25px solid ${theme.colors.tableCellBorder}`,
    },
  },
  tableCellHeaderNoPadding: {
    padding: '0px',
    paddingRight: '0px',
  },
  tableCellError: {
    color: 'red',
  },
  memberInfo: {
    marginBottom: '1rem',
  },
  memberHeader: {
    marginTop: '2rem',
    marginBottom: '0.5rem',
  },

  iconButton: {
    padding: '0px',
    backgroundColor: '#99CA3C',
  },
  logIcon: {
    padding: 0,
    margin: 0,
    width: '28px',
    height: '28px',
    display: 'flex',
    color: '#525252',
  },
  changeLogPaper: {
    padding: 29,
    maxWidth: '900px',
  },
  changeLogHeadline: {
    fontSize: '18px',
    paddingLeft: '6px',
    paddingBottom: '18px',
  },
  changeLogButton: {
    padding: '6px 8px',
    marginTop: '45px',
    width: '80px',
    marginLeft: 'auto',
  },
  changeLogTableWrapper: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  changeLogChange: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  iconButtons: {
    padding: '0px',
  },
  iconButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

export default styles;
