import { StyledComponentProps, TableRow, withStyles } from '@material-ui/core';
import { MemberWithKey } from '@shared/schema';
import { HourTypeWithKey, WorkingHoursWithKey } from '@shared/schema/index';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import CustomTableCell from 'theme/styles/tableCell';
import styles from './styles';

export interface WorkTimeTableRowProps
  extends DispatchProp,
    StyledComponentProps {
  member: MemberWithKey;
  hourTypes: HourTypeWithKey[];
  data: WorkingHoursWithKey[] | undefined;
  selectMember(member: MemberWithKey): void;
}

export interface WorkTimeTableRowState {
  allHoursWithinRange: number;
}

export class WorkTimeTableRow extends React.Component<
  WorkTimeTableRowProps,
  WorkTimeTableRowState
> {
  constructor(props: WorkTimeTableRowProps) {
    super(props);

    this.state = {
      allHoursWithinRange: 0,
    };
  }

  public render() {
    const { classes = {}, member, selectMember, hourTypes } = this.props;

    return (
      <TableRow
        onClick={() => selectMember(member)}
        className={classes.row}
        data-test="tableRow"
        hover={true}
      >
        <CustomTableCell className={classes.tableCell}>
          {member.name}
        </CustomTableCell>
        <CustomTableCell className={classes.tableCell}>
          {member.email}
        </CustomTableCell>
        <CustomTableCell className={classes.tableCell}>
          {momentDurationFormat(this.getAllHours(), DurationFormatPreset.HHMM)}
        </CustomTableCell>
        {hourTypes.map((hType: HourTypeWithKey) =>
          this.getTimeByHourType(hType),
        )}
      </TableRow>
    );
  }

  private getTimeByHourType = (hourType: HourTypeWithKey) => {
    const { classes = {}, data } = this.props;

    let value = 0;

    if (data) {
      data.forEach(workDay => {
        /**
         * Goes all working days data separately through
         */
        workDay.data.forEach(d => {
          /**
           * Goes all hourtypes through
           */
          if (hourType.key === d.hourtype) {
            value += d.time;
          }
        });
      });
    }

    return (
      <CustomTableCell
        key={hourType.key}
        className={classes.tableCell}
        data-test="hoursByHourTypeCell"
      >
        {momentDurationFormat(value, DurationFormatPreset.HHMM)}
      </CustomTableCell>
    );
  };

  /**
   * Handles with members workingdays and builds
   * showable data to render
   */

  private getAllHours = () => {
    const { data } = this.props;

    let allHours: number = 0;

    /**
     * Goes through all workingDays and counts all
     * hours together
     */
    data &&
      data.forEach(workDay => {
        workDay.data.forEach(d => {
          allHours += d.time;
        });
      });

    return allHours;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<WorkTimeTableRowProps>,
) => {
  return {
    ...ownProps,
    dataToExport: state.reports.dataToExport,
  } as WorkTimeTableRowProps;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(WorkTimeTableRow),
);
