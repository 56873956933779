import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

/**
 *  Decalare module for createMuiTheme
 */
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    /**
     * Common custom colors
     */
    colors: {
      transparent: string;
      white: string;
      lightGrey: string;
      grey: string;
      darkGrey: string;
      darkerGrey: string;
      black: string;

      tableCellBorder: string;
    };

    /**
     * Warning color
     */
    warning: {
      color: string;
    };

    /**
     * Taskbank priority colors
     */
    priorities: {
      high: {
        color: string;
      };
      normal: {
        color: string;
      };
      low: {
        color: string;
      };
    };

    /**
     * Timeline specific layout items
     */
    timeline: {
      /**
       * Task status colors
       */
      status: {
        undone: {
          background: string;
          color: string;
          icon: string;
        };
        late: {
          background: string;
          color: string;
          icon: string;
        };
        active: {
          background: string;
          color: string;
          icon: string;
        };
        paused: {
          background: string;
          color: string;
          icon: string;
        };
        done: {
          background: string;
          color: string;
          icon: string;
        };
      };

      /**
       * Left side labelset colors
       */
      labelset: {
        avatar: {
          background: string;
        };
      };
    };

    mapMarkerThemes: {
      default: {
        color: string;
      };
      undone: {
        color: string;
      };
      active: {
        color: string;
      };
      paused: {
        color: string;
      };
      done: {
        color: string;
      };
      late: {
        color: string;
      };
    };

    /**
     * Toolbar
     */
    toolbar: {
      /**
       * List item style
       */
      listItem: {
        selected: string;
      };
    };
  }
  interface ThemeOptions {
    /**
     * Common custom colors
     */
    colors?: {
      transparent?: string;
      white?: string;
      lightGrey?: string;
      grey?: string;
      darkGrey?: string;
      black?: string;
      darkerGrey?: string;

      tableCellBorder?: string;
    };

    /**
     * Warning color
     */
    warning: {
      color: string;
    };

    /**
     * Taskbank priority colors
     */
    priorities?: {
      high: {
        color: string;
      };
      normal: {
        color: string;
      };
      low: {
        color: string;
      };
    };

    /**
     * Timeline specific layout items
     */
    timeline?: {
      /**
       * Task status colors
       */
      status: {
        undone: {
          background: string;
          color: string;
          icon: string;
        };
        late: {
          background: string;
          color: string;
          icon: string;
        };
        active: {
          background: string;
          color: string;
          icon: string;
        };
        paused: {
          background: string;
          color: string;
          icon: string;
        };
        done: {
          background: string;
          color: string;
          icon: string;
        };
      };

      /**
       * Left side labelset colors
       */
      labelset: {
        avatar: {
          background: string;
        };
      };
    };

    mapMarkerThemes: {
      default: {
        color: string;
      };
      undone: {
        color: string;
      };
      active: {
        color: string;
      };
      paused: {
        color: string;
      };
      done: {
        color: string;
      };
      late: {
        color: string;
      };
    };

    /**
     * Toolbar
     */
    toolbar: {
      /**
       * List item style
       */
      listItem: {
        selected: string;
      };
    };
  }
}

const muiTheme = createMuiTheme({
  /**
   * Common custom colors
   */
  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    lightGrey: '#EEEEEE',
    grey: '#e5e5e5',
    darkGrey: '#929292',
    black: '#000000',
    darkerGrey: '#6b6b6b',

    tableCellBorder: '#d0d0d0',
  },

  warning: {
    color: '#ffc107',
  },

  priorities: {
    high: {
      color: '#C80000',
    },
    normal: {
      color: '#99CA3C',
    },
    low: {
      color: '#007F01',
    },
  },

  /**
   * Timeline style
   */
  timeline: {
    status: {
      undone: {
        background: '#525252',
        color: '#696969',
        icon: 'schedule',
      },
      late: {
        background: '#C80000',
        color: '#FFFFFF',
        icon: 'error-outline',
      },
      active: {
        background: '#249DDA',
        color: '#FFFFFF',
        icon: 'loop',
      },
      paused: {
        background: '#FFA500',
        color: '#FFFFFF',
        icon: 'pause',
      },
      done: {
        background: '#99CA3C',
        color: '#FFFFFF',
        icon: 'done',
      },
    },
    labelset: {
      avatar: {
        background: '#fff',
      },
    },
  },

  mapMarkerThemes: {
    default: {
      color: '#696969',
    },
    undone: {
      color: '#696969',
    },
    active: {
      color: '#249DDA',
    },
    paused: {
      color: '#FFA500',
    },
    done: {
      color: '#99CA3C',
    },
    late: {
      color: '#C80000',
    },
  },

  /**
   * Toolbar style
   */
  toolbar: {
    listItem: {
      selected: 'rgba(153, 202, 60, 0.18)',
    },
  },
  typography: {
    fontFamily: 'Ubuntu',
    useNextVariants: true,
  },

  /**
   * Material ui palette
   */
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      main: '#323232',
    },
    secondary: {
      main: '#99CA3C',
    },
    error: {
      light: '#e57373',
      main: '#d32f2f',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
  },

  /**
   * Override material ui component basic layout
   */
  overrides: {
    /**
     * Set default style for list item icon
     */
    MuiListItemIcon: {
      root: {
        color: '#000',
      },
    },
    /**
     * Set default style for form label
     */
    MuiFormLabel: {
      root: {
        color: '#000',
      },
    },
    /**
     * Set default style for checkbox
     */
    MuiCheckbox: {
      root: {
        color: '#767676',
      },
    },
    /**
     * Set default style for radio
     */
    MuiRadio: {
      root: {
        color: '#767676',
      },
    },
  },
});

export enum CssBreakpoint {
  PHONE = '320px',
  TABLET_SMALL = '481px',
  TABLET_PORTRAIT = '641px',
  TABLET_LANDSCAPE = '961px',
  DESKTOP = '1025px',
  DESKTOP_HIGH = '1281px',
}

export default muiTheme;
