import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';

import { Button, ListItem, ListItemText } from '@material-ui/core';
import { Schema } from '@shared/schema';
import classNames from 'classnames';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import { FileUploadStatus, MultiUploadFile } from '..';
import styles from './styles';

interface UploadListItemProps extends StyledComponentProps {
  firestoreFilePath: string;
  onChange: (multiUploadFile: MultiUploadFile) => void;
  onDelete: (uploadFileKey: string) => void;
  fileRef: MultiUploadFile;
}

class UploadListItem extends React.Component<UploadListItemProps, any> {
  public uploadTask: any;
  constructor(props: UploadListItemProps) {
    super(props);
    this.state = {};
  }

  public handleDelete = () => {
    this.props.onDelete(this.props.fileRef.key);
  };

  public componentDidMount() {
    const { fileRef, firestoreFilePath } = this.props;
    if (fileRef.status === FileUploadStatus.WAITING) {
      const storageRef = firebaseApp.storage().ref();

      const metadata = {
        contentType: fileRef.mimeType,
      };

      this.uploadTask = storageRef
        .child(`${Schema.COMPANIES}/${firestoreFilePath}/${fileRef.fileName}`)
        .put(fileRef.file, metadata);

      this.uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          this.setState({ progress });

          if (this.props.fileRef.status !== FileUploadStatus.INPROGRESS) {
            const newFileRef: MultiUploadFile = { ...this.props.fileRef };
            newFileRef.status = FileUploadStatus.INPROGRESS;
            this.props.onChange(newFileRef);
          }
        },
        (error: any) => {
          switch (error.code) {
            case 'storage/canceled':
              // cancel() was called for uploadTask
              break;
          }
        },
        () => {
          this.uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadUrl: string) => {
              this.setState({
                downloadUrl,
              });

              const newFileRef: MultiUploadFile = { ...this.props.fileRef };
              newFileRef.status = FileUploadStatus.DONE;
              newFileRef.url = downloadUrl;
              this.props.onChange(newFileRef);
            });
        },
      );
    }
  }

  public componentWillUnmount() {
    if (
      this.props.fileRef.status !== FileUploadStatus.DONE &&
      this.uploadTask
    ) {
      this.uploadTask.cancel();
    }
  }

  public handleNameClick = () => {
    const { fileRef } = this.props;
    if (fileRef.url) {
      window.open(fileRef.url, '_blank');
    }
  };

  public render() {
    const { classes = {}, fileRef } = this.props;
    return (
      <>
        <ListItem>
          <ListItemText
            primary={this.props.fileRef.fileName}
            onClick={this.handleNameClick}
            className={classNames(
              fileRef.url ? classes.pointer : '',
              classes.cursiveGrey,
            )}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleDelete}
            disabled={this.props.fileRef.status !== FileUploadStatus.DONE}
          >
            <DeleteIcon className={classes.rightIcon} />
          </Button>
        </ListItem>
        <div
          className={classNames(
            classes.loader,
            this.state.progress === 100 ? classes.displayNone : '',
          )}
          style={{ width: `${this.state.progress}%` }}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UploadListItem);
