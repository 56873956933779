import { Reducer } from 'redux';

import { User, UserRole } from '@shared/schema';
import { AuthAction, AuthActionType } from './authActions';

export interface AuthState {
  /**
   * Firebase's user auth info. If authenticated, this will contain
   * the basic info provided by Firebase.
   */
  readonly firebaseUser: firebase.User | undefined;
  /**
   * App specific user info. This info will contain the data that
   * is actually used by the system.
   */
  readonly appUser: User | undefined;
  /**
   * Role of user in current organization
   */
  readonly activeRole: UserRole | undefined;

  readonly firebaseError: firebase.auth.Error | undefined;

  /**
   * True if registration form is active
   */
  readonly registrationEnabled: boolean;
  /**
   * True if autehntication is in progress
   */
  readonly authenticating: boolean;
}

export const defaultAuthState: AuthState = {
  firebaseUser: undefined,
  appUser: undefined,
  activeRole: undefined,
  // activeCompany: undefined,
  registrationEnabled: false,
  firebaseError: undefined,
  authenticating: false,
};

export const authReducer: Reducer<AuthState> = (
  state = defaultAuthState,
  action: AuthAction,
) => {
  switch (action.type) {
    case AuthActionType.AUTH_FIREBASE_USER_STATE_CHANGED: {
      return {
        ...state,
        firebaseUser: action.payload,
        firebaseError: undefined,
        authenticating: false,
      };
    }

    case AuthActionType.AUTH_APP_USER_INFO_UPDATED: {
      const appUser = action.payload as User;

      let activeRole;
      // UserRole.CLOSED;

      // TODO tests
      if (appUser) {
        if (appUser.systemAdmin) {
          activeRole = UserRole.SYSTEM_ADMIN;
        } else if (
          appUser.companies &&
          appUser.home &&
          appUser.companies[appUser.home] !== undefined
        ) {
          activeRole = appUser.companies[appUser.home];
        }
      }

      return {
        ...state,
        appUser: action.payload,
        activeRole,
      };
    }

    // case AuthActionType.AUTH_APP_USER_ACTIVE_ORG_CHANGED: {
    //   const activeOrganization =
    //     state.appUser && state.appUser.organizations
    //       ? (state.appUser.organizations[action.payload] as UserOrganizatioInfoWithId)
    //       : state.activeOrganization;
    //   // activeOrganization.id = action.payload;
    //   return {
    //     ...state,
    //     activeOrganization,
    //   };
    // }

    /**
     * Set the last error info to state
     */
    case AuthActionType.AUTH_FIREBASE_SET_ERROR: {
      return {
        firebaseError: action.payload,
        authenticating: false,
        ...state,
      };
    }

    case AuthActionType.AUTH_FIREBASE_SET_AUTHENTICATING: {
      return {
        authenticating: action.payload,
        ...state,
      };
    }

    case AuthActionType.AUTH_OPEN_REGISTRATION: {
      return {
        ...state,
        registrationEnabled: action.payload,
      };
    }

    default:
      return state;
  }
};
