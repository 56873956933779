import { WithTheme } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { i18n } from '@shared/locale/index';
import {
  CompanyId,
  CustomerWithKey,
  Filter,
  Groups,
  ImportTasksDialogState,
  JobtypeSet,
  JobTypeWithKey,
  Member,
  MemberSet,
  MemberWithKey,
  Qualification,
  QualificationWithKey,
  Schema,
  Settings,
  ShiftDialogState,
  Task,
  TaskDialogState,
  TaskWithKey,
  UserRole,
  VatWithKey,
  ViewState,
  WorksiteWithKey,
} from '@shared/schema';
import classNames from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import {
  deleteResourceEvent,
  deleteResourceEventCancel,
} from 'reducers/events/eventActions';
import { QualificationSet } from 'reducers/timeline/timelineReducer';
import store from 'store';
import muiTheme from 'theme';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { CompanyParams } from '../../MainLayout/index';
import EventEditorDialog from '../components/EventEditorDialog';
import TaskDeleteDialog from '../components/EventEditorDialog/components/TaskDeleteDialog';
import ImportTasksDialog from '../components/ImportTasksDialog/ImportTasksDialog';
import ShiftEditorDialog from '../components/ShiftEditorDialog/ShiftEditorDialog';
import SnackbarWarning from '../components/SnackbarWarning/SnackbarWarning';
import TaskBank from '../components/TaskBank';
import Timeline from '../components/Timeline';
import TimelineBar from '../components/TimelineBar';
import { styles } from './styles';

export interface TimelineContainerProps
  extends RouteComponentProps<CompanyParams>,
    DispatchProp<any>,
    StyledComponentProps,
    WithTheme {
  currentDate: moment.Moment;
  end: moment.Moment;
  taskDialogState: TaskDialogState;
  shiftDialogState: ShiftDialogState;
  importTasksDialogState: ImportTasksDialogState;
  filter?: string;
  hiddenMembers: MemberSet;
  hiddenJobtypes: JobtypeSet;
  hiddenQualifications: QualificationSet;
  hiddenPriorities: number[];
  companyId: CompanyId | undefined;
  deleteDialogOpen: boolean;
  deleteTask?: TaskWithKey;
  deleteEventId: any;
  deleteEventTitle: string;
  settings: Settings;
  viewState: ViewState;
}

interface State {
  error: null;
  isLoading: boolean;
  deleteDialogOpen: boolean;
  deleteEventId: any;
  deleteCallback: any;
  deleteEventTitle: string;
  members: Member[];
  worksites: WorksiteWithKey[];
  groups: Groups[];
  jobtypes: JobTypeWithKey[];
  qualifications: QualificationWithKey[];
  customers: CustomerWithKey[];
  vats: VatWithKey[];
  originalTasks: Task[];
  filters: Filter;
}

export class TaskContainer extends React.Component<
  TimelineContainerProps,
  State
> {
  private unsubscribeMembers: () => void;
  private unsubscribeQualifs: () => void;

  private companyRef: firebase.firestore.DocumentReference;

  constructor(props: TimelineContainerProps) {
    super(props);

    this.state = {
      error: null,
      members: [],
      isLoading: true,
      deleteDialogOpen: false,
      deleteCallback: undefined,
      deleteEventId: undefined,
      deleteEventTitle: '',
      groups: [],
      worksites: [],
      jobtypes: [],
      qualifications: [],
      vats: [],
      originalTasks: [],
      filters: {},
      customers: [],
    };
  }
  public sortMembers(members: Member[]) {
    return members.sort((a, b) => {
      return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
    });
  }
  public componentDidMount() {
    const { companyId, viewState } = this.props;
    if (companyId) {
      this.companyRef = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId);

      this.unsubscribeMembers = this.companyRef
        .collection(Schema.MEMBERS)
        .where('active', '==', true)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const members: MemberWithKey[] = collectionToJsonWithIds(snapshot);
          this.sortMembers(members);
          if (
            viewState === ViewState.TASKBANK &&
            members &&
            members.find(member => member.key === '') === undefined
          ) {
            const userNotSelected: MemberWithKey = {
              active: false,
              email: '',
              key: '',
              name: i18n().ui.no_member_selected,
              phone: '',
              photoURL: '',
              qualifications: {},
              jobtypes: {},
              role: UserRole.USER,
              workTimes: [],
            };

            members.unshift(userNotSelected);
          }

          if (this.state.members.length !== members.length) {
            this.setState({
              groups: this.filterGroups(members),
            });
          }

          this.setState({
            members,
          });
        });

      this.companyRef
        .collection(Schema.WORKSITE)
        .orderBy('nick')
        .get()
        .then(snapShot => {
          const worksites = collectionToJsonWithIds<WorksiteWithKey>(snapShot);
          worksites.unshift({
            name: i18n().ui.no_worksite,
            key: '',
            nick: '',
            note: '',
            location: { id: '', address: '', postalCode: '', city: '' },
          });

          this.setState({
            worksites,
          });
        });

      this.companyRef
        .collection(Schema.JOBTYPES)
        .orderBy('name')
        .get()
        .then(snapShot => {
          const jobTypes = collectionToJsonWithIds<JobTypeWithKey>(snapShot);
          // jobTypes.unshift({ name: i18n().ui.jobtype_name, key: '' });

          this.setState({
            jobtypes: jobTypes,
          });
        });

      this.companyRef
        .collection(Schema.CUSTOMERS)
        .orderBy('name')
        .get()
        .then(snapShot => {
          const customers = collectionToJsonWithIds<CustomerWithKey>(snapShot);
          customers.unshift({
            name: i18n().ui.no_customer,
            worksiteRefs: {},
            key: '',
          });

          this.setState({
            customers,
          });
        });
      this.companyRef
        .collection(Schema.VATS)
        .orderBy('name')
        .get()
        .then(snapShot => {
          const vats = collectionToJsonWithIds<VatWithKey>(snapShot);
          vats.unshift({
            key: 'undefined',
            default: false,
            name: i18n().ui.select_vat,
            percent: -1,
          });

          this.setState({
            vats,
          });
        });

      this.unsubscribeQualifs = this.companyRef
        .collection(Schema.QUALIFICATIONS)
        .orderBy('name')
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const qualifications: Qualification[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const qualification = data.data() as QualificationWithKey;
            qualification.key = data.id;
            qualifications.push({
              ...qualification,
            });
          });

          this.setState({
            qualifications,
          });
        });
    }
    if (viewState === ViewState.TASKBANK) {
      this.setState({
        filters: this.handleFilters(this.props),
      });
    }
  }

  public componentWillReceiveProps(nextProps: TimelineContainerProps) {
    const { viewState } = this.props;
    if (
      nextProps.hiddenMembers !== this.props.hiddenMembers ||
      nextProps.hiddenJobtypes !== this.props.hiddenJobtypes ||
      nextProps.hiddenQualifications !== this.props.hiddenQualifications ||
      nextProps.hiddenPriorities !== this.props.hiddenPriorities
    ) {
      viewState === ViewState.TASKBANK
        ? this.setState({
            filters: this.handleFilters(nextProps),
            groups: this.filterGroups(this.state.members, nextProps),
          })
        : this.setState({
            groups: this.filterGroups(this.state.members, nextProps),
          });
    }
  }
  public componentWillUnmount() {
    this.unsubscribeMembers && this.unsubscribeMembers();
    this.unsubscribeQualifs && this.unsubscribeQualifs();
  }

  public handleDeleteDialogClose = () => {
    store.dispatch(deleteResourceEventCancel());
  };

  public handleDeleteItem = () => {
    const { deleteEventId, companyId } = this.props;

    store.dispatch(deleteResourceEvent(companyId, deleteEventId));
  };

  public render() {
    const {
      filters,
      error,
      jobtypes,
      members,
      worksites,
      groups,
      qualifications,
      vats,
      customers,
    } = this.state;

    const {
      classes = {},
      companyId,
      deleteDialogOpen,
      settings,
      viewState,
      taskDialogState,
      shiftDialogState,
      importTasksDialogState,
    } = this.props;

    if (!members || members.length === 0) {
      return <LoadingSpinner />;
    }

    return (
      <div
        className={classNames(classes.container)}
        style={{ color: muiTheme.palette.primary.main }}
      >
        {error ? (
          <Snackbar open={!!error} message={error} autoHideDuration={4000} />
        ) : null}
        <TimelineBar
          jobtypes={jobtypes}
          members={members}
          qualifications={qualifications}
          companyId={companyId}
          viewState={viewState}
        />
        {viewState === ViewState.TASKBANK ? (
          <TaskBank
            companyId={companyId}
            filters={filters}
            members={members}
            settings={settings}
            viewState={viewState}
          />
        ) : (
          <Timeline
            className={classNames(classes.timelineContainer)}
            groups={groups}
            companyId={companyId}
          />
        )}

        {taskDialogState !== TaskDialogState.CLOSED && (
          <EventEditorDialog
            companyId={companyId}
            jobTypes={jobtypes}
            members={members}
            worksites={worksites}
            qualifications={qualifications}
            vats={vats}
            customers={customers}
            groups={groups}
            viewState={viewState}
          />
        )}

        {this.openDeleteDialog() && (
          <TaskDeleteDialog
            task={this.props.deleteTask}
            isOpen={deleteDialogOpen}
            onClose={this.handleDeleteDialogClose}
            onSendRequest={this.handleDeleteDialogClose}
          />
        )}

        {shiftDialogState === ShiftDialogState.OPEN && (
          <ShiftEditorDialog
            members={members}
            companyId={companyId}
            customers={customers}
            vats={vats}
            worksites={worksites}
            jobtypes={jobtypes}
          />
        )}

        {importTasksDialogState === ImportTasksDialogState.OPEN && (
          <ImportTasksDialog
            members={members}
            companyId={companyId}
            worksites={worksites}
            jobtypes={jobtypes}
            customers={customers}
          />
        )}
        <SnackbarWarning />
      </div>
    );
  }

  public openDeleteDialog = () => {
    return this.props.deleteTask && this.props.deleteTask.status !== 'ACTIVE';
  };
  /**
   * Gets correct filters
   * @returns filters object
   */
  private handleFilters = (props: TimelineContainerProps) => {
    const filters: Filter = {
      priorities: props.hiddenPriorities,
      members: props.hiddenMembers,
      jobtypes: props.hiddenJobtypes,
    };
    return filters;
  };

  /**
   * Gets array of filtered selection
   * @returns groups array
   */
  private filterGroups = (
    members: MemberWithKey[],
    useProps?: TimelineContainerProps,
    filter = useProps ? useProps.filter : this.props.filter,
    hiddenResources = useProps
      ? useProps.hiddenMembers
      : this.props.hiddenMembers,
  ) => {
    let propsForThisMethod = this.props;
    if (useProps) {
      propsForThisMethod = useProps;
    }

    const groups: Groups[] = [];
    const hidden = hiddenResources || ({} as any);

    // Get all selected task filters as a neat list of strings
    const selectedTaskFilters: string[] = [];
    if (propsForThisMethod.hiddenJobtypes) {
      for (const key in propsForThisMethod.hiddenJobtypes) {
        if (propsForThisMethod.hiddenJobtypes[key]) {
          selectedTaskFilters.push(key);
        }
      }
    }

    const selectedQualifFilters: string[] = [];
    if (propsForThisMethod.hiddenQualifications) {
      for (const key in propsForThisMethod.hiddenQualifications) {
        if (propsForThisMethod.hiddenQualifications[key]) {
          selectedQualifFilters.push(key);
        }
      }
    }

    members.forEach(member => {
      // If valid stays true user is good to be shown
      let valid = true;

      // Skip all the logic if there are filters on tasks and resource doesnt have any services
      if (
        selectedTaskFilters.length > 0 &&
        (!member.jobtypes || Object.keys(member.jobtypes).length === 0)
      ) {
        valid = false;
      }

      if (
        selectedQualifFilters.length > 0 &&
        (!member.qualifications ||
          Object.keys(member.qualifications).length === 0)
      ) {
        valid = false;
      }

      if (valid) {
        // Go trough all the selected task filters and make sure that user has all of them
        for (const task of selectedTaskFilters) {
          if (!member.jobtypes[task]) {
            valid = false;
            break;
          }
        }

        for (const qualifKey of selectedQualifFilters) {
          if (!member.qualifications[qualifKey]) {
            valid = false;
            break;
          }
        }

        // Lastly check if the user is filtered out by resource filters
        valid &&
          (hidden[member.key!] === undefined || !hidden[member.key!]) &&
          member.key &&
          groups.push({
            id: member.key,
            content: member.name,
            photoURL: member.photoURL,
          });
      }
    });

    const userNotSelected = {
      id: '',
      content: i18n().ui.no_member_selected,
      photoURL: '',
    };

    groups.unshift(userNotSelected);
    return groups;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: TimelineContainerProps,
): TimelineContainerProps => {
  return {
    ...ownProps,
    deleteDialogOpen: state.eventEditor.deleteDialogOpen,
    deleteTask: state.eventEditor.deleteTask,
    deleteEventId: state.eventEditor.deleteEventId,
    deleteEventTitle: state.eventEditor.deleteEventTitle,
    hiddenMembers: state.timeline.hiddenMembers,
    hiddenJobtypes: state.timeline.hiddenJobtypes,
    hiddenQualifications: state.timeline.hiddenQualifications,
    hiddenPriorities: state.timeline.hiddenPriorities,
    filter: state.timeline.filter,
    end: state.timeline.end,
    currentDate: state.timeline.currentDate,
    taskDialogState: state.eventEditor.taskDialogState,
    settings: state.company.activeCompany.settings,
    shiftDialogState: state.shiftEditor.shiftDialogState,
    importTasksDialogState: state.importTasks.importTasksDialogState,
  };
};

export default withRouter<any>(
  connect<TimelineContainerProps>(mapStateToProps)(
    withStyles(styles, { withTheme: true })(TaskContainer),
  ),
);
