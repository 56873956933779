import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import { i18n } from '@shared/locale';
import {
  getResourceRole,
  JobTypeWithKey,
  MemberWithKey,
  QualificationWithKey,
  Schema,
  UserRole,
} from '@shared/schema';
import { isEmail, isPhoneNumber, validateString } from '@shared/validators';
import classNames from 'classnames';
import CheckboxGrid from 'components/CheckboxGrid';
import { CheckboxGridItem } from 'components/CheckboxGrid/CheckboxGrid';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import { cancelEdit } from 'reducers/resources/resourcesActions';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import styles from './styles';

// Worktime editor disabled until developed further
/* import WorkTimeEditor from '../WorkTimeEditor';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add'; */

export interface ResourceEditorDialogProps
  extends Partial<DispatchProp<any>>,
    StyledComponentProps {
  editing?: boolean;
  companyId: string;
  editedMember?: MemberWithKey;
  tasks: JobTypeWithKey[];
  qualifications: QualificationWithKey[];
  allMembers: MemberWithKey[];
}

interface ErrorMessages {
  email?: string;
  name?: string;
  phone?: string;
}

interface ResourceRoleDesc {
  role: UserRole;
  name: string;
}

interface State {
  editMode: boolean;
  member?: MemberWithKey;
  fieldValidity: ResourceEditorDialogValidity;
  errorMessages: ErrorMessages;
  errorMessage: string;
}

interface ResourceEditorDialogValidity {
  name?: boolean | undefined;
  email?: boolean | undefined;
  emailExists?: boolean | undefined;
  phone?: boolean | undefined;
}

enum ResourceEditorFieldNames {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
}

const getEmptyMember = (): MemberWithKey => ({
  key: '',
  active: true,
  name: '',
  email: '',
  photoURL: '',
  phone: '',
  role: UserRole.USER,
  jobtypes: {},
  workTimes: [],
  qualifications: {},
});

// Why is this named like this?
export class ResourceEditorDialog extends React.Component<
  ResourceEditorDialogProps,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      editMode: false,
      errorMessages: {},
      member: getEmptyMember(),
      fieldValidity: {} as ResourceEditorDialogValidity,
      errorMessage: '',
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  public handleRequestClose = () => {
    this.props.dispatch && this.props.dispatch(cancelEdit());
    this.setState({
      member: getEmptyMember(),
      errorMessages: {},
    });
  };

  public handleSave = () => {
    if (!this.props.dispatch) {
      return;
    }
    const source: MemberWithKey | undefined = this.state.member;
    if (!source) {
      return;
    }
    const member: MemberWithKey = { ...source };

    if (member.workTimes) {
      for (const row of member.workTimes) {
        delete row.key;
      }
    }

    if (this.state.editMode) {
      this.updateResource(this.props.companyId, member);
      this.handleRequestClose();
    } else {
      this.createResource(this.props.companyId, member);
      this.handleRequestClose();
    }
  };

  public componentWillReceiveProps(nextProps: ResourceEditorDialogProps) {
    let member: MemberWithKey = this.state.member
      ? { ...this.state.member }
      : ({} as any);
    let fieldValidity = {};

    let editMode; /* boolean = nextProps.editedResource !== null; */

    if (nextProps.editedMember) {
      editMode = true;
      fieldValidity = {
        name: true,
        email: true,
        phone: true,
      } as ResourceEditorDialogValidity;
    } else {
      editMode = false;
      member = getEmptyMember();
    }
    if (editMode && this.props.editedMember !== nextProps.editedMember) {
      member = { ...nextProps.editedMember } as MemberWithKey;
      if (member.workTimes) {
        for (let i = 0; i < member.workTimes.length; i++) {
          member.workTimes[i].key = i.toString();
        }
      }
    }
    this.setState({
      editMode,
      member,
      fieldValidity,
    });
  }

  public isFormValid(): boolean {
    const member: MemberWithKey = this.state.member || ({} as any);

    if (member.phone) {
      if (!isPhoneNumber(member.phone)) {
        return false;
      }
    }

    const valid: boolean =
      validateString(member.name, 1) &&
      isEmail(member.email || '') &&
      this.checkErrorMessages();

    return valid;
  }

  public checkErrorMessages = () => {
    return (
      this.state.errorMessages.email === undefined &&
      this.state.errorMessages.name === undefined &&
      this.state.errorMessages.phone === undefined
    );
  };

  public handleBlur = (name: keyof ResourceEditorDialogValidity) => (
    event: any,
  ) => {
    const fieldValue: string = event.target.value;
    const errorMessages = { ...this.state.errorMessages };
    const { editedMember } = this.props;
    switch (name) {
      case ResourceEditorFieldNames.NAME:
        errorMessages.name = validateString(fieldValue, 1)
          ? undefined
          : i18n().ui.name_too_short;
        break;

      case ResourceEditorFieldNames.EMAIL:
        errorMessages.email =
          fieldValue && isEmail(fieldValue)
            ? undefined
            : i18n().ui.invalid_email;

        if (
          (!errorMessages.email &&
            editedMember &&
            editedMember.email !== fieldValue) ||
          (!errorMessages.email && !editedMember)
        ) {
          errorMessages.email = this.props.allMembers.find(
            member => member.email === fieldValue,
          )
            ? i18n().ui.email_already_in_use
            : undefined;
        }
        break;

      case ResourceEditorFieldNames.PHONE:
        errorMessages.phone =
          fieldValue !== null &&
          fieldValue.length > 0 &&
          isPhoneNumber(fieldValue)
            ? undefined
            : i18n().ui.invalid_phone;
        break;
      default:
    }

    this.setState({
      errorMessages,
    });
  };

  public render() {
    const { classes = {}, qualifications, editing } = this.props;
    const jobTypes = this.props.tasks;
    const { errorMessages } = this.state;

    const RESOURCE_ROLE_MAP: ResourceRoleDesc[] = [
      {
        role: UserRole.PENDING,
        name: i18n().ui.role_awaiting,
      },
      {
        role: UserRole.USER,
        name: i18n().ui.role_user,
      },
      {
        role: UserRole.ADMIN,
        name: i18n().ui.role_admin,
      },
      {
        role: UserRole.CLOSED,
        name: i18n().ui.role_closed,
      },
    ];

    if (!editing) {
      return null;
    }

    const { member = getEmptyMember(), editMode } = this.state;

    return (
      <Dialog open={editing} className={classes.root}>
        {editMode ? (
          <DialogTitle>{i18n().ui.edit_member}</DialogTitle>
        ) : (
          <DialogTitle>{i18n().ui.new_member}</DialogTitle>
        )}
        <DialogContent>
          <form className={classes.container} autoComplete="off">
            <List>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={i18n().ui.name + '*'}
                  className={classNames(classes.field, classes.textField)}
                  margin="normal"
                  onChange={this.handleChange(ResourceEditorFieldNames.NAME)}
                  onBlur={this.handleBlur(ResourceEditorFieldNames.NAME)}
                  value={member.name}
                  error={errorMessages.name !== undefined}
                  helperText={errorMessages.name}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>

                <Select
                  className={classes.field}
                  value={getResourceRole(member)}
                  onChange={this.handleChange('role')}
                  input={<Input name="role" id="role" />}
                >
                  {RESOURCE_ROLE_MAP.map(item => (
                    <MenuItem
                      className={classes.field}
                      key={item.role}
                      button={true}
                      value={item.role}
                    >
                      <ListItemText
                        primary={item.name}
                        className={classes.listText}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={i18n().ui.email + '*'}
                  type="email"
                  className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange(ResourceEditorFieldNames.EMAIL)}
                  onBlur={this.handleBlur(ResourceEditorFieldNames.EMAIL)}
                  value={member.email}
                  error={this.state.errorMessages.email !== undefined}
                  helperText={this.state.errorMessages.email}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={i18n().ui.phone}
                  className={classes.textField}
                  margin="normal"
                  type="tel"
                  onChange={this.handleChange(ResourceEditorFieldNames.PHONE)}
                  onBlur={this.handleBlur(ResourceEditorFieldNames.PHONE)}
                  value={member.phone}
                  error={this.state.errorMessages.phone !== undefined}
                  helperText={this.state.errorMessages.phone}
                />
              </ListItem>
              {this.state.member && this.state.member.role === UserRole.ADMIN && (
                <ListItem
                  disabled={
                    this.getContactPersonCount() >= 3 &&
                    this.props.editedMember &&
                    !this.props.editedMember.isContact
                  }
                  button={true}
                  onClick={this.handleCheck(this.state.member)}
                >
                  <ListItemIcon>
                    {this.state.member && this.state.member.isContact ? (
                      <CheckBoxIcon color="secondary" />
                    ) : (
                      <CheckBoxOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={'Contact person'} />
                </ListItem>
              )}
              {jobTypes && jobTypes.length > 0 && (
                <>
                  <ListItem>
                    <Typography variant="h6" className={classes.title}>
                      {i18n().ui.proficiencies}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <CheckboxGrid
                      items={jobTypes.map(
                        (jobType): CheckboxGridItem => ({
                          key: jobType.key!,
                          label: jobType.name,
                          checked:
                            member.jobtypes && member.jobtypes[jobType.key!],
                        }),
                      )}
                      onChange={this.handleJobTypeChange}
                    />
                  </ListItem>
                </>
              )}
              {qualifications && qualifications.length > 0 && (
                <>
                  <ListItem>
                    <Typography variant="h6" className={classes.title}>
                      {i18n().ui.qualifications}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <CheckboxGrid
                      items={qualifications.map(
                        (qualification): CheckboxGridItem => ({
                          key: qualification.key!,
                          label: qualification.name,
                          checked:
                            member.qualifications &&
                            member.qualifications[qualification.key!],
                        }),
                      )}
                      onChange={this.handleQualificationChange}
                    />
                  </ListItem>
                </>
              )}
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose}>{i18n().ui.cancel}</Button>
          <Button
            disabled={!this.isFormValid()}
            color="secondary"
            onClick={this.handleSave}
          >
            {i18n().ui.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  private getContactPersonCount = () => {
    return this.props.allMembers.filter(member => member.isContact).length;
  };
  private handleJobTypeChange = (items: CheckboxGridItem[]) => {
    const jobtypes = {};
    items.forEach(item => {
      if (item.checked) {
        jobtypes[item.key] = true;
      }
    });

    const member = {
      ...this.state.member!,
      jobtypes,
    };

    this.setState({ member });
  };
  private handleCheck = (data: MemberWithKey | undefined) => () => {
    const checked = (data && data.isContact) || false;
    const member = { ...this.state.member!, isContact: !checked };
    this.setState({ member });
  };
  private handleQualificationChange = (items: CheckboxGridItem[]) => {
    const qualifications = {};
    items.forEach(item => {
      if (item.checked) {
        qualifications[item.key] = true;
      }
    });

    const member = {
      ...this.state.member!,
      qualifications,
    };

    this.setState({ member });
  };

  private updateResource = async (companyId: string, member: MemberWithKey) => {
    try {
      if (member.workTimes && member.workTimes.length === 0) {
        member.workTimes = firebase.firestore.FieldValue.delete() as any;
      }
      if (
        member.jobtypes &&
        Object.getOwnPropertyNames(member.jobtypes).length === 0
      ) {
        member.jobtypes = firebase.firestore.FieldValue.delete() as any;
      }

      member.active =
        member.role === UserRole.USER ||
        member.role === UserRole.ADMIN ||
        member.role === UserRole.SYSTEM_ADMIN;

      const data = { ...member };
      const key = member.key;
      delete data.key;

      const { editedMember } = this.props;

      if (editedMember) {
        // Check if email is already in users
        firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.MEMBERS)
          .doc(key)
          .update(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  private handleChange = (name: string) => (event: any) => {
    const fieldValue: string = event.target.value;
    const member = { ...this.state.member };
    member[name] = fieldValue;
    this.setState({
      member: member as any,
    });
  };

  private createResource = async (companyId: string, member: MemberWithKey) => {
    // Check if email is already in members
    try {
      const memberSnapShot = await firebaseApp
        .firestore()
        .collection(Schema.USERS)
        .where('email', '==', member.email)
        .get();

      const memberUser: MemberWithKey[] = collectionToJsonWithIds(
        memberSnapShot,
      );

      if (memberUser !== undefined && memberUser.length > 0) {
        firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.MEMBERS)
          .doc(memberUser[0].key)
          .set(member);
      } else {
        firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.MEMBERS)
          .add(member);
      }
    } catch (error) {
      console.error('Getting member info failed@ResourceEditorDialog', error);
    }
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ResourceEditorDialogProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    editedMember: state.resources.editedMember,
    editing: state.resources.editing,
    allMembers: state.resources.allMembers,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ResourceEditorDialog),
);
