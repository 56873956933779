import * as React from 'react';
import { Route, Switch } from 'react-router';

import MainLayout from './MainLayout';

/**
 * Root component of private side of application
 */
export default class Private extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/:companyId/" component={MainLayout} />
        <div>Company not found</div>
      </Switch>
    );
  }
}
