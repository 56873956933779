import { Button, Slide, TextField } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from '@shared/locale/index';
import {
  CustomerWithKey,
  TaskWithKey,
  WorksiteWithKey,
} from '@shared/schema/index';
import { GeoLocation } from '@shared/schema/index';
import { Component } from 'react';
import * as React from 'react';
import ResultsContainer from '../components/ResultsContainer';
import styles from './styles';

interface SearchDrawerProps extends StyledComponentProps {
  worksites: WorksiteWithKey[];
  customers: CustomerWithKey[];
  tasks: TaskWithKey[];
  showSearchDrawer: boolean;
  closeSearchHandler: () => void;
  selectedCoordinates: (
    geoLocation: GeoLocation | undefined,
    key: string | undefined,
  ) => void;
}

interface State {
  filter: string;
  filterText: string;
  showResultsContainer: boolean;
  filteredResults: WorksiteWithKey[];
}

class SearchDrawer extends Component<SearchDrawerProps, State> {
  constructor(props: SearchDrawerProps) {
    super(props);
    this.state = {
      filter: 'name',
      filterText: '',
      showResultsContainer: false,
      filteredResults: [],
    };
  }

  public render() {
    const { classes = {} } = this.props;
    const { showResultsContainer, filteredResults } = this.state;

    return (
      <>
        <Drawer
          anchor="right"
          variant="persistent"
          open={this.props.showSearchDrawer}
          classes={{ paper: classes.searchDrawer }}
          onKeyPress={this.onEnterPress}
        >
          <CloseIcon onClick={this.closeDrawer} />
          <div className={classes.filterContainer}>
            <RadioGroup
              name="filters"
              className={classes.group}
              onChange={this.handleFilterChange}
              value={this.state.filter}
            >
              <FormControlLabel
                value="name"
                control={<Radio />}
                label={i18n().ui.worksite_name}
              />
              <FormControlLabel
                value="nick"
                control={<Radio />}
                label={i18n().ui.worksite_nickname}
              />
              <FormControlLabel
                value="address"
                control={<Radio />}
                label={i18n().ui.address}
              />
              <FormControlLabel
                value="city"
                control={<Radio />}
                label={i18n().ui.city}
              />
              <FormControlLabel
                value="customerName"
                control={<Radio />}
                label={i18n().ui.customer_name}
              />
              <FormControlLabel
                value="memberName"
                control={<Radio />}
                label={i18n().concepts.member}
              />
            </RadioGroup>
            <TextField
              id="search"
              placeholder={i18n().ui.search_from_map}
              className={classes.searchInput}
              margin="normal"
              onChange={this.handleFilterTextChange}
              value={this.state.filterText}
            />
            <Button
              className={classes.searchButton}
              onClick={this.search}
              disabled={this.state.filterText.length === 0}
            >
              {i18n().ui.search_optional}
            </Button>
          </div>
        </Drawer>
        <Slide direction="left" in={showResultsContainer} unmountOnExit>
          <ResultsContainer
            results={filteredResults}
            onClose={this.closeResultsContainer}
            selectedCoordinates={this.passSelectedCoordinates}
          />
        </Slide>
      </>
    );
  }

  /**
   * Stores changed filterText into state.
   */
  public handleFilterTextChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    this.setState({ filterText: event.target.value });
  };

  /**
   * Stores changed filter into state.
   */
  private handleFilterChange = (
    event: React.ChangeEvent<unknown>,
    value: string,
  ) => {
    this.setState({
      filter: value,
    });
  };

  /**
   * Executes search function if user pressed enter
   * @param event Keyboard event which key was pressed
   */
  private onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.search();
    }
  };

  /**
   * Search which uses filter text and radio button selection to find correct worksites.
   * Stores search results in state.
   */
  private search = () => {
    const { filter, filterText } = this.state;
    const { worksites, customers, tasks } = this.props;

    let filteredResults: WorksiteWithKey[] = [];

    switch (filter) {
      case 'name':
        filteredResults = worksites.filter(
          item =>
            item.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1,
        );
        break;

      case 'nick':
        filteredResults = worksites.filter(
          item =>
            item.nick.toLowerCase().indexOf(filterText.toLowerCase()) > -1,
        );
        break;

      case 'address':
        filteredResults = worksites.filter(
          item =>
            item.location.address
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) > -1,
        );
        break;

      case 'city':
        filteredResults = worksites.filter(
          item =>
            item.location.city.toLowerCase().indexOf(filterText.toLowerCase()) >
            -1,
        );
        break;

      case 'customerName':
        const filteredCustomers = customers.filter(
          customer =>
            customer.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1,
        );

        filteredResults = worksites.filter(item =>
          filteredCustomers.find(customer => customer.key === item.customer),
        );
        break;

      case 'memberName':
        const filteredTasks = tasks.filter(task =>
          task.userName
            ? task.userName.toLowerCase().indexOf(filterText.toLowerCase()) > -1
            : undefined,
        );

        filteredResults = worksites.filter(item =>
          filteredTasks.find(task =>
            task.worksite ? task.worksite.id === item.key : false,
          ),
        );
        break;
    }

    this.setState({
      showResultsContainer: true,
      filteredResults,
    });
  };

  /**
   * Passes resultsDrawer click coordinates to MapContainer
   * @param geoLocation Selected worksite's coordinates
   */
  private passSelectedCoordinates = (
    geoLocation: GeoLocation | undefined,
    key: string | undefined,
  ) => {
    this.props.selectedCoordinates(geoLocation, key);
  };

  /**
   * Closes SearchDrawer and ResultsContainer. Resets fields.
   */
  private closeDrawer = () => {
    this.setState({
      showResultsContainer: false,
      filterText: '',
      filter: 'name',
    });
    this.props.closeSearchHandler();
  };

  /**
   * Closes ResultsContainer only
   */
  private closeResultsContainer = () => {
    this.setState({
      showResultsContainer: false,
    });
  };
}

export default withStyles(styles, { withTheme: true })(SearchDrawer);
