import { Tooltip, WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import {
  ArrowDownward,
  ArrowUpward,
  Cached,
  Done,
  Pause,
} from '@material-ui/icons';
import { i18n } from '@shared/locale';
import {
  CompanyId,
  DateRange,
  Filter,
  getPriorityMap,
  MemberWithKey,
  Settings,
  TaskStatus,
  TaskWithKey,
  User,
  ViewState,
} from '@shared/schema';
import { sortTasks } from '@shared/utils';
import {
  generateDurationText,
  sumTotalTimerTime,
} from '@shared/utils/timeUtils';
import classNames from 'classnames';
import MultiPurposeDialog from 'components/MultiPurposeDialog';
import SubscribeChatStatusIcon from 'components/SubscribeChatStatusIcon';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import moment from 'moment';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { editResourceEvent } from 'reducers/events/eventActions';
import store from 'store';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { deactivateTaskWithBatch, getTasksTableRef } from 'utils/tasksUtil';
import { getMemberDocRef } from '../../../../../utils/memberUtils';
import { getTaskDocRef } from '../../../../../utils/tasksUtil';
import { styles } from './styles';

// tslint:disable-next-line: no-var-requires
const placeholderImage = require('../../../../../assets/images/ProfileImage.png');

export interface TaskBankProps
  extends Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  companyId: CompanyId | undefined;
  firebaseUser: firebase.User | undefined;
  appUser: User | undefined;
  filters: Filter;
  members: MemberWithKey[];
  settings: Settings;
  viewState: ViewState;
  selectedDateRange: DateRange;
}
interface State {
  tasks: TaskWithKey[];
  originalTasks: TaskWithKey[];
  taskId: string;
  showWarning: boolean;
  onAgreeWarning: () => void;
  onCancelWarning: () => void;
}

export class TaskBank extends React.Component<TaskBankProps, State> {
  private unsubscribeEvents: () => void;
  constructor(props: TaskBankProps) {
    super(props);

    this.state = {
      tasks: [],
      originalTasks: [],
      taskId: '',
      showWarning: false,
      onAgreeWarning: () => undefined,
      onCancelWarning: () => {
        this.setState({ showWarning: false });
      },
    };
  }

  public componentWillUnmount() {
    this.unsubscribeEvents && this.unsubscribeEvents();
  }
  public componentDidMount() {
    this.getTaskData();
  }

  /* UNSAFE Will be removed in React version 17 */
  public componentWillReceiveProps(nextProps: TaskBankProps) {
    const newTasks = this.filterTasks(
      this.state.originalTasks,
      nextProps.filters,
      nextProps.selectedDateRange,
    );
    this.setState({ tasks: newTasks });
  }

  /***
   * Function creates statusList based on the enum TaskStatus from shared/schema
   * @returns {string[]}
   *
   */
  public createTaskStatusList = (): string[] => {
    let statusList: string[] = Object.keys(TaskStatus).map(k => {
      return TaskStatus[k];
    });

    statusList = statusList.filter(element => element !== TaskStatus.LATE);
    return statusList;
  };

  /***
   * Function will return correct classname for task which will change the little border bottom color for task
   * @param {TaskWithKey} task
   */
  public returnStatusClassName = (task: TaskWithKey) => {
    let statusClassName;

    switch (task.status) {
      case TaskStatus.UNDONE:
        const today = moment();
        const taskStart = moment(task.start);
        if (task.startTime) {
          taskStart.isBefore(today)
            ? (statusClassName = TaskStatus.LATE)
            : (statusClassName = TaskStatus.UNDONE);
        } else {
          const dueDate = moment(task.start).startOf('date');
          today.isAfter(dueDate, 'day') && task.start !== 0
            ? (statusClassName = TaskStatus.LATE)
            : (statusClassName = TaskStatus.UNDONE);
        }
        break;
      case TaskStatus.PAUSED:
        statusClassName = TaskStatus.PAUSED;
        break;
      case TaskStatus.ACTIVE:
        statusClassName = TaskStatus.ACTIVE;
        break;
      case TaskStatus.DONE:
        statusClassName = TaskStatus.DONE;
        break;
    }
    return statusClassName;
  };

  /***
   * Function will return correct img tag for task if user exist and if user has photoURL and if not then it will return grey generic img
   * @param {TaskWithKey} task
   */
  public returnAvatarIcon = (task: TaskWithKey) => {
    const { members, classes = {} } = this.props;
    let avatarIcon;
    const correctMember: MemberWithKey | undefined = members.find(
      member => member.key === task.userId,
    );

    if (correctMember && correctMember.key !== '') {
      const sourceImage = correctMember.photoURL
        ? correctMember.photoURL
        : placeholderImage;
      avatarIcon = (
        <img
          className={classNames(classes.avatarIcon)}
          alt={correctMember.name}
          src={sourceImage}
        />
      );
    } else {
      avatarIcon = undefined;
    }
    return avatarIcon;
  };

  public filterTasks = (
    tasks: TaskWithKey[],
    filter: Filter,
    selectedDateRange: DateRange,
  ) => {
    // Filter Jobtypes
    if (filter.jobtypes) {
      const jobtypeFilters: string[] = [];
      for (const key in filter.jobtypes) {
        if (filter.jobtypes[key]) {
          jobtypeFilters.push(key);
        }
      }
      if (jobtypeFilters.length >= 1) {
        tasks = tasks.filter(task => jobtypeFilters.includes(task.jobtypeId));
      }
    }
    // Filter members
    if (filter.members) {
      const memberFilters: string[] = [];
      for (const key in filter.members) {
        if (filter.members[key]) {
          memberFilters.push(key);
        }
      }
      if (memberFilters.length >= 1) {
        tasks = tasks.filter(
          task => !memberFilters.includes(task.userId as string),
        );
      }
    }
    // Filter priorities
    if (filter.priorities && filter.priorities.length >= 1) {
      tasks = tasks.filter(task =>
        filter.priorities!.includes(task.priority!.value),
      );
    }
    // Filter Daterange
    if (selectedDateRange && selectedDateRange.start && selectedDateRange.end) {
      tasks = tasks.filter(task => {
        return (
          task.start >= selectedDateRange.start! &&
          task.start < selectedDateRange.end!
        );
      });
    }
    tasks = sortTasks(tasks);
    return tasks;
  };

  public render() {
    const { classes = {} } = this.props;
    return (
      <div className={classNames(classes.main)}>
        <div className={classNames(classes.overflowContainer)}>
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
          >
            {this.createDroppablesAndDraggablesFromList()}
          </DragDropContext>
        </div>
        <MultiPurposeDialog
          open={this.state.showWarning}
          dialogTitle={i18n().ui.another_task_active}
          dialogContent={i18n().ui.start_another_task_question}
          handleAccept={this.state.onAgreeWarning}
          handleCloseDialog={this.state.onCancelWarning}
        />
      </div>
    );
  }

  /**
   * Function will return tasks by status, but if status is not found in any of the tasks function will return empty array.
   * @param {string} status
   * @param {TaskStatus} correctStatus
   * @param {TaskWithKey[]} result
   */
  private getTasksByStatus = (status: string) => {
    const correctStatus: TaskStatus = status as TaskStatus;
    const result: TaskWithKey[] = this.state.tasks.filter(
      task => task.status === correctStatus,
    );

    return result;
  };

  /**
   * Shows warning dialog that only one task can be active for a user. Takes batch as parameter to commit the changes and hide the dialog when user presses "Yes" answer option in the dialog.
   *
   * @param {firebase.firestore.WriteBatch} batch
   */
  private showWarning = (batch: firebase.firestore.WriteBatch) => {
    this.setState({
      showWarning: true,
      onAgreeWarning: () => {
        batch.commit();
        this.state.onCancelWarning();
      },
    });
  };

  /***
   * When user stops to drag an draggable task
   * @param {DragStart} result includes:
   * @param {string} draggableId
   * @param {string} mode
   * @param {object} source includes:
   *    @param {string} droppableId
   *    @param {number} index
   * @param {string} type */

  private onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    const { companyId, firebaseUser, members } = this.props;
    const { taskId, tasks } = this.state;

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    const newStatus = destination.droppableId as TaskStatus;

    const batch = firebaseApp.firestore().batch();

    let selectedTask: TaskWithKey = {
      status: TaskStatus.UNDONE,
      start: 0,
      end: 0,
      jobtypeId: '',
      jobtypeName: '',
      duration: 0,
    };

    const newTasks = tasks.map(task => {
      if (task.key !== taskId) {
        return task;
      }

      selectedTask = task;

      return {
        ...task,
        status: newStatus,
      };
    });

    if (
      firebaseUser &&
      newStatus === TaskStatus.ACTIVE &&
      !selectedTask.userId
    ) {
      // Getting real userName from members table for user instead of firebaseUser.name
      for (const member of members) {
        if (member.key === firebaseUser.uid) {
          selectedTask.userId = firebaseUser.uid;
          selectedTask.userName = member.name;
          break;
        }
      }
    }

    const taskUpdate: Partial<TaskWithKey> = {
      userName: selectedTask.userName || '',
      userId: selectedTask.userId || '',
      status: newStatus,
    };

    const now = moment().valueOf();

    const taskMember = [...members].find(
      member => member.key === selectedTask.userId,
    );

    if (selectedTask.activeTimer) {
      selectedTask.activeTimer.ended = now;
      if (selectedTask.userId !== '') {
        selectedTask.activeTimer.memberId = selectedTask.userId || '';
      }
      selectedTask.timerLog = selectedTask.timerLog || [];
      selectedTask.timerLog.push(selectedTask.activeTimer);
      selectedTask.timerTotal = sumTotalTimerTime(selectedTask.timerLog);

      delete selectedTask.activeTimer;

      taskUpdate.activeTimer = firebase.firestore.FieldValue.delete() as any;
      taskUpdate.timerLog = selectedTask.timerLog;
      taskUpdate.timerTotal = selectedTask.timerTotal;

      if (taskMember && taskMember.activeTask) {
        const activeTask: Partial<MemberWithKey> = {
          activeTask: firebase.firestore.FieldValue.delete() as any,
        };

        batch.update(getMemberDocRef(companyId, taskMember.key), activeTask);
      }
    }

    if (newStatus === TaskStatus.ACTIVE) {
      // Apply updates for the task
      selectedTask.activeTimer = {
        started: now,
        memberId: selectedTask.userId || '',
      };
      taskUpdate.activeTimer = selectedTask.activeTimer;

      taskUpdate.updatedByAdmin = true;

      const activeTask: Partial<MemberWithKey> = {
        activeTask: { ...selectedTask, ...taskUpdate },
      };

      // If there is an active task for a task's user(member), then show the warning dialog and save changes to batch without committing them
      if (
        taskMember &&
        taskMember.activeTask &&
        taskMember.activeTask.key &&
        taskMember.activeTask.key !== selectedTask.key
      ) {
        // Trying to stop active task
        deactivateTaskWithBatch(companyId, taskMember.activeTask.key, batch);

        batch.update(getMemberDocRef(companyId, taskMember.key), activeTask);

        batch.update(getTaskDocRef(companyId, taskId), taskUpdate);

        this.showWarning(batch);

        // Stop function execution to show warning dialog. On dialog confirmation button press all batch changes will be committed there or nothing happen on 'cancel' button press
        return;

        // If there is no active task for a user(member), then just copy the resulting task as activeEvent
      } else if (
        newStatus === TaskStatus.ACTIVE &&
        taskMember &&
        taskMember.activeTask === undefined
      ) {
        batch.update(getMemberDocRef(companyId, taskMember.key), activeTask);
      }
    }

    if (newStatus === TaskStatus.DONE) {
      taskUpdate.completionDate = now;
    }

    batch.update(getTaskDocRef(companyId, taskId), taskUpdate);

    // Redux implementation caused weird bugs with taskBank so using simple firestore set here.
    try {
      this.setState({ tasks: newTasks });
      batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  /***
   * When starting to drag an draggable task
   * @param {DragStart} result includes:
   *    @param {string} draggableId
   *    @param {string} mode
   */

  private onDragStart = (result: DragStart) => {
    const id: string = result.draggableId;

    this.setState({
      taskId: id,
    });
  };

  /***
   * Function for opening event dialog
   * @param {string} key
   * @param {React.SyntheticEvent} event (needed to work even if not used)
   */
  private onClick = (key: string | undefined) => (
    event: React.SyntheticEvent,
  ) => {
    const { tasks } = this.state;
    const { companyId } = this.props;
    const taskInfo = tasks.find(element => element.key === key);

    const eventResource: TaskWithKey | undefined = taskInfo
      ? {
          ...taskInfo,
          id: key,
        }
      : undefined;
    store.dispatch(editResourceEvent(companyId, eventResource));
  };

  /***
   * Function returns all tasks from firestore and checks if state is not same as result from store then put them to state
   *
   */
  private getTaskData = () => {
    this.unsubscribeEvents = getTasksTableRef(this.props.companyId)
      .where('archived', '==', false)
      .onSnapshot(snapshot => {
        // let originalTasks: TaskWithKey[] = [...this.state.originalTasks];

        const originalTasks = collectionToJsonWithIds(
          snapshot,
        ) as TaskWithKey[];

        /* Add priority to task if it doesnt have it (normal priority) */
        originalTasks.forEach(originalTask => {
          originalTask.priority = originalTask.priority
            ? originalTask.priority
            : getPriorityMap()[1];
        });

        const tasks = collectionToJsonWithIds(snapshot) as TaskWithKey[];
        tasks.forEach(task => {
          task.priority = task.priority ? task.priority : getPriorityMap()[1];
        });
        const filteredTasks = this.filterTasks(
          tasks,
          this.props.filters,
          this.props.selectedDateRange,
        );

        if (this.state.tasks !== filteredTasks) {
          this.setState({
            originalTasks,
            tasks: filteredTasks,
          });
        }
      });
  };

  /***
   * Creates Droppable areas and draggable items and returns them to render
   *
   */
  private createDroppablesAndDraggablesFromList = () => {
    const { classes = {}, viewState, appUser } = this.props;

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
      ...draggableStyle,
    });

    const rows: any[] = [];
    this.createTaskStatusList().forEach((status: string, index) => {
      rows.push(
        <Droppable droppableId={status} key={status}>
          {provided1 => (
            <div ref={provided1.innerRef} className={classNames(classes.list)}>
              <div className={classNames(classes.listheader)}>
                <h3 className={classNames(classes.h3)}>
                  {i18n().ui[status.toLowerCase()]}
                </h3>
                <div className={classNames(classes.headerIcon)}>
                  {this.returnCorrectStatusIcon(status as TaskStatus)}
                </div>
              </div>

              {this.getTasksByStatus(status).map((task: TaskWithKey, i) => (
                <Draggable
                  key={task.key}
                  draggableId={task.key ? task.key : i.toString()}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <div
                      onClick={this.onClick(task.key)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                      className={classNames(
                        classes.item,
                        classes[
                          (this.returnStatusClassName(
                            task,
                          ) as string).toLowerCase()
                        ],
                        snapshot.isDragging
                          ? classes.itemIsdragging
                          : classes.itemNodragging,
                      )}
                    >
                      {appUser && appUser.home && (
                        <SubscribeChatStatusIcon
                          companyId={appUser.home}
                          task={task}
                          userId={appUser.id}
                          style={{
                            position: 'absolute',
                            width: '17px',
                            height: '17px',
                            top: '8px',
                            right: '8px',
                          }}
                        />
                      )}
                      <div className={classNames(classes.leftSide)}>
                        <h3 className={classNames(classes.h4)}>
                          {task.title && task.title.userModified
                            ? task.title.value
                            : task.jobtypeName}
                        </h3>
                        <p className={classNames(classes.p)}>
                          {task && task.location
                            ? `${task.location.address}${
                                task.location.address &&
                                (task.location.postalCode || task.location.city)
                                  ? ','
                                  : ''
                              } ${task.location.postalCode} ${
                                task.location.city
                              }
                              `
                            : null}
                          <br />
                          {generateDurationText(task, viewState)}
                          <br />
                        </p>
                      </div>
                      <div className={classNames(classes.rightSide)}>
                        <div className={classNames(classes.priorityIconArea)}>
                          {this.returnCorrectPriorityIconAndColor(task)}
                        </div>
                        <Tooltip title={task.userName} placement="bottom-start">
                          <div>{this.returnAvatarIcon(task)}</div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided1.placeholder}
            </div>
          )}
        </Droppable>,
      );
    });

    return rows;
  };

  /***
   * Function return correct status icon to droppable area based on status
   * @param {TaskStatus} status
   */
  private returnCorrectStatusIcon = (status: TaskStatus) => {
    let icon;
    switch (status) {
      case TaskStatus.ACTIVE:
        icon = <Cached />;
        break;
      /* TODO UNDONE ICON??? */
      case TaskStatus.UNDONE:
        icon = undefined;
        break;
      case TaskStatus.PAUSED:
        icon = <Pause />;
        break;
      case TaskStatus.DONE:
        icon = <Done />;
        break;
    }

    return icon;
  };
  /***
   * Function returns correct priority icon and color for task
   * @param {TaskWithKey} task
   */
  private returnCorrectPriorityIconAndColor = (task: TaskWithKey) => {
    const { classes = {} } = this.props;
    let prioIcon;
    if (task.priority && task.priority.value) {
      switch (task.priority.value) {
        case 1:
          prioIcon = (
            <ArrowUpward
              className={classNames(classes.high, classes.priority)}
            />
          );
          break;
        case 0:
          prioIcon = undefined;
          break;
        case -1:
          prioIcon = (
            <ArrowDownward
              className={classNames(classes.low, classes.priority)}
            />
          );
          break;
      }
    }

    return prioIcon;
  };
}
const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TaskBankProps>,
) => {
  return {
    ...ownProps,
    firebaseUser: state.auth.firebaseUser,
    appUser: state.auth.appUser,
    selectedDateRange: state.timeline.selectedDateRange,
  };
};

export default withRouter<any>(
  withStyles(styles, { withTheme: true })(
    connect<any>(mapStateToProps)(TaskBank as any),
  ),
);
