import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { DialogStatus } from '@shared/schema';
import * as React from 'react';
import { i18n } from '../../../../@shared/locale/index';

interface MultiPurposeDialogProps {
  dialogTitle: string;
  dialogStatus?: DialogStatus;
  dialogContent?: string;
  dialogButtonText?: string;
  open: boolean;
  handleAccept: (event: React.MouseEvent) => void;
  handleCloseDialog: (event: React.MouseEvent) => void;
}

export default class MultiPurposeDialog extends React.Component<
  MultiPurposeDialogProps
> {
  public static defaultProps: Pick<MultiPurposeDialogProps, 'open'> = {
    open: true,
  };

  public render() {
    const {
      dialogTitle,
      handleAccept,
      handleCloseDialog,
      dialogContent,
      dialogButtonText,
      open,
    } = this.props;

    return (
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        {dialogContent && <DialogContent>{dialogContent}</DialogContent>}

        <DialogActions>
          <Button onClick={handleCloseDialog}>{i18n().ui.cancel}</Button>
          <Button onClick={handleAccept} color="secondary">
            {dialogButtonText || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
