import { Avatar, Badge, Tooltip, WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { Schema, TaskWithKey } from '@shared/schema';
import * as React from 'react';
import { getTasksTableRef } from 'utils/tasksUtil';
import styles from './styles';

export interface ChatBadgeProps extends StyledComponentProps, WithTheme {
  avatarURL: string;
  userName: string;
  max?: number;
  userId: string;
  task: TaskWithKey;
  companyId: string;
  open?: boolean;
}

export interface State {
  showBadge: boolean;
}

export class ChatBadge extends React.Component<ChatBadgeProps, State> {
  private unsubribeTaskChat: () => void;

  constructor(props: ChatBadgeProps) {
    super(props);
    this.state = {
      showBadge: false,
    };
  }

  public componentDidMount = () => {
    this.checkUnreadMessages();
  };

  public componentWillUnmount = () => {
    this.unsubribeTaskChat && this.unsubribeTaskChat();
  };

  public getSnapshotBeforeUpdate(prevProps: ChatBadgeProps, prevState: State) {
    if (
      prevProps.task.hasReadChat &&
      this.props.task.hasReadChat &&
      prevProps.task.hasReadChat[prevProps.userId] !==
      this.props.task.hasReadChat[this.props.userId]
    ) {
      return true;
    }
    return null;
  }

  public componentDidUpdate(
    prevProps: ChatBadgeProps,
    prevState: State,
    snapshot: boolean,
  ) {
    if (snapshot !== null) {
      this.checkUnreadMessages();
    }
  }

  public render() {
    const { classes = {}, avatarURL, max, userName, open } = this.props;
    const { showBadge } = this.state;

    return (
      <Badge
        color="error"
        max={max ? max : 10}
        classes={{ badge: classes.badge }}
        variant="dot"
        invisible={open ? open : !showBadge}
        data-test="badge"
      >
        <Tooltip title={userName} placement="top" data-test="tooltip">
          <Avatar src={`${avatarURL}`} data-test="avatar" />
        </Tooltip>
      </Badge>
    );
  }

  /**
   * Creates onSnapshot listener to certain tasks chat subcollection
   */
  private checkUnreadMessages = () => {
    const { task, userId, companyId } = this.props;
    if (task.hasReadChat && userId !== '') {
      const hasRead: string = task.hasReadChat[userId];

      this.unsubribeTaskChat = getTasksTableRef(companyId)
        .doc(task.key)
        .collection(Schema.CHAT)
        .orderBy('date', 'desc')
        .limit(1)
        .onSnapshot(chat => {
          chat.forEach(chatmsg => {
            const showBadge: boolean = chatmsg.id !== hasRead;

            this.setState({
              showBadge,
            });
          });
        });
    }
  };
}

export default withStyles(styles, { withTheme: true })(ChatBadge);
