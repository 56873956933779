import { addHours, format, newValidDate } from 'ts-date';

import { Task, TaskTimer, TaskWithKey, ViewState } from '../schema';
import { i18n } from '../locale';
import { WorkingHours } from '../schema/index';
import moment from 'moment';

/**
 * Generate human readable duration text from resource event data
 * @param {Task} task Resource event data
 * @returns Formatted time
 */
export function generateDurationText(task: TaskWithKey, viewState?: ViewState) {
  const end: string =
    task.end > 0 ? (format(newValidDate(task.end), 'H:mm') as string) : '';
  const start =
    new Date(task.start).getMinutes() > 0 || new Date(task.start).getHours() > 0
      ? (format(newValidDate(task.start), 'H:mm') as string)
      : '';

  const isSingleDay = moment(task.start).isSame(task.end, 'day');

  /**
   * Show date if its available and no accurate times exist
   */
  if (
    (task.start === undefined || task.start === 0) &&
    (task.end === undefined || task.end === 0) &&
    task.date !== undefined &&
    task.date !== ''
  ) {
    return task.date;
  }
  /**
   * Show weeknumber if its the only time related data available
   */
  if (
    (task.start === undefined || task.start === 0) &&
    (task.end === undefined || task.end === 0) &&
    task.weekNumber !== undefined &&
    task.weekNumber !== ''
  ) {
    return i18n().ui.week_text + ' ' + task.weekNumber;
  }

  // TODO Just use this momentDurationFormat call
  // let durationText = momentDurationFormat(
  //   task.duration,
  //   DurationFormatPreset.SHORT_HM_TRIM_LARGE
  // );
  let durationText: string = '';
  if (task.duration && task.duration > 0) {
    let minutes = Math.ceil(task.duration / 60000);
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    const hourText = hours !== 1 ? i18n().time.hours : i18n().time.hour;

    if (hours < 1) {
      durationText = `(${minutes} min)`;
    } else {
      if (minutes === 0) {
        durationText = `(${hours} ${hourText})`;
      } else {
        durationText = `(${hours} ${hourText} ${minutes} min)`;
      }
    }
  }

  const symbol = start && end ? ' - ' : '';
  let dateText = '';
  const startDate = format(new Date(task.start), 'D.M.YYYY');
  const endDate = format(new Date(task.end), 'D.M.YYYY');
  if (viewState === ViewState.TASKBANK) {
    dateText = `${startDate} ${start && start !== '' ? start : ''}${symbol}${
      start ? end : ''
    }`;
  } else {
    dateText = `${start}${symbol}${end}`;
  }

  if (!isSingleDay && endDate) {
    dateText =
      startDate + ' ' + start + ' ' + symbol + ' ' + endDate + ' ' + end;
  }

  return `${task.start ? dateText + ' ' : ''}${durationText}`;
}

/**
 * Show duration in textual form.
 *
 * Example: 1 h 2 min 34 s
 * @param milliseconds Duration in milliseconds
 * @returns Formatted time
 */
export function displayDurationText(milliseconds: number): string {
  // TODO just use this momentDurationFormat call
  // return momentDurationFormat(
  //   milliseconds,
  //   DurationFormatPreset.SHORT_HMS_TRIM_LARGE
  // );

  if (typeof milliseconds !== 'number') {
    throw new Error('Expected number, received ' + typeof milliseconds);
  }

  if (milliseconds < 0) {
    throw new Error('input is negative: ' + milliseconds);
  }

  let seconds = Math.ceil(milliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  const hourText = hours !== 1 ? i18n().time.hours : i18n().time.hour;
  let result: string;

  if (hours < 1) {
    result = `${minutes} min ${seconds} s`;
  } else {
    if (minutes === 0 && seconds === 0) {
      result = `${hours} ${hourText}`;
    } else {
      if (minutes !== 0) {
        result = `${hours} ${hourText} ${minutes} min ${seconds} s`;
      } else {
        result = `${hours} ${hourText} ${seconds} s`;
      }
    }
  }
  return result;
}

/**
 * Return current spend time on active timer
 * (including history timed sessions for same event)
 *
 * @param {*} data
 * @returns milliseconds
 */
export function getActiveTimerDuration(
  task: TaskWithKey,
  id: string | undefined,
): number {
  if (!task) {
    return 0;
  }
  const activeTimer: TaskTimer = task.activeTimer as TaskTimer;
  const timerLog = task.timerLog as TaskTimer[];

  let sum = sumTotalTimerTime(timerLog);

  if (task.timerTotalFixed && Array.isArray(task.timerTotalFixed)) {
    const found = task.timerTotalFixed.find(fix => fix.memberId === id);
    if (found) {
      sum += found.value;
    }
  }
  return sum + getActiveTime(activeTimer);
}

/**
 * Get active time
 *
 * @param {*} activeTimer
 * @returns milliseconds
 */
export function getActiveTime(activeTimer: TaskTimer): number {
  if (!activeTimer) {
    return 0;
  }

  const sum = newValidDate().valueOf() - activeTimer.started;
  return sum;
}

/**
 * Calculate total time of timers
 *
 * @param { Array<TaskTimer>} timers
 * @returns milliseconds
 */
export function sumTotalTimerTime(timers: TaskTimer[] | undefined): number {
  if (!timers || timers.length == 0) {
    return 0;
  }

  let sum = 0;

  for (const timer of timers) {
    if (timer.ended !== undefined) {
      sum += timer.ended - timer.started;
    } else {
      sum += newValidDate().valueOf() - timer.started;
    }
  }

  return sum;
}

/**
 * Max time datepicker can pick for duration: 23 hours 59 minutes
 */
export const DATEPICKER_MAX_TIME = (24 * 60 - 1) * 60 * 1000;

/**
 * Get available working hours for single day.
 *
 * Max working time per day is 23 hours 59 minutes. This
 * subtracts all hour type working hours from this maximum value.
 *
 * Also rounds time to nearest full minute which can be
 * realistically inputted with DatePicker
 *
 * @param workingHours
 *        WorkingHours or already calculated used hours
 * @param excludeHourTypeKey
 *        Hour type key to exclude from total.
 *        Most likely the hour type currently being edited.
 * @param dayMaxTime
 *        Custom max time for a day.
 * @returns milliseconds
 */
export const calculateAvailableWorkingHours = (
  workingHours?: WorkingHours | number,
  excludeHourTypeKey?: string | false,
  dayMaxTime: number = DATEPICKER_MAX_TIME,
): number => {
  const used =
    typeof workingHours === 'number'
      ? workingHours
      : calculateTotalWorkingHours(workingHours, excludeHourTypeKey);

  const available = Math.max(0, dayMaxTime - used);

  return roundToPrevious(available, 'minute');
};

/**
 * Calculate total working hours
 *
 * @param workingHours
 * @param excludeHourTypeKey
 *        Hour type key to exclude from total.
 *        Most likely the hour type currently being edited.
 * @returns milliseconds
 */
export const calculateTotalWorkingHours = (
  workingHours?: WorkingHours,
  excludeHourTypeKey?: string | false,
): number => {
  if (!workingHours) {
    return 0;
  }

  let total = 0;

  for (const data of workingHours.data) {
    if (!excludeHourTypeKey || excludeHourTypeKey !== data.hourtype) {
      total += data.time;
    }
  }

  return total;
};

/**
 * Round date to next 15 minutes
 */
export function roundToNext15Minutes(date: Date): Date {
  const result = newValidDate(date.getTime()) as Date;
  const minutes = date.getMinutes();

  if (minutes === 0) {
    return result;
  }

  if (minutes <= 15) {
    result.setMinutes(15);
    return result;
  }
  if (minutes <= 30) {
    result.setMinutes(30);
    return result;
  }
  if (minutes <= 45) {
    result.setMinutes(45);
    return result;
  }
  result.setMinutes(0);
  return addHours(result, 1);
}

/**
 * Get actual timerTotal value for task
 *
 * Basically `task.timerTotal + task.timerTotalFixed`
 *
 * @param task Task to get timerTotal value for
 * @returns milliseconds
 */
export const getTaskTimerTotal = (task: Task | undefined) => {
  if (task) {
    if (task.timerTotalFixed && Array.isArray(task.timerTotalFixed)) {
      const found = task.timerTotalFixed.find(
        fix => fix.memberId === task.userId,
      );
      if (found && task.timerTotal) {
        return task.timerTotal + found.value;
      }
    }
    return task.timerTotal || 0;
  }
  return 0;
};
/**
 * Units used by `roundTo` function family
 */
export type RoundToUnit =
  | 'millisecond'
  | 'S'
  | 'second'
  | 's'
  | '5s'
  | '10s'
  | '15s'
  | '30s'
  | 'minute'
  | 'm'
  | '5m'
  | '10m'
  | '15m'
  | '30m'
  | 'hour'
  | 'h'
  | 'day'
  | 'd'
  | 'week'
  | 'w';

const unitMultipliers: { [key in RoundToUnit]: number } = {
  week: 7 * 24 * 60 * 60 * 1000,
  w: 7 * 24 * 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  d: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  h: 60 * 60 * 1000,
  minute: 60 * 1000,
  m: 60 * 1000,
  second: 1000,
  s: 1000,
  millisecond: 1,
  S: 1,
  '5s': 5 * 1000,
  '10s': 10 * 1000,
  '15s': 15 * 1000,
  '30s': 30 * 1000,
  '5m': 5 * 60 * 1000,
  '10m': 10 * 60 * 1000,
  '15m': 15 * 60 * 1000,
  '30m': 30 * 60 * 1000,
};

/**
 * Rounds time in milliseconds to full unit.
 *
 * @param milliseconds Time in milliseconds to round
 * @param unit Target unit or divider value.
 * @param roundingFunction Function used in rounding. Default: `Math.round`
 * @returns milliseconds
 */
export const roundTo = (
  milliseconds: number,
  unit: number | RoundToUnit,
  roundingFunction?: (input: number) => number,
): number => {
  const divider = typeof unit === 'string' ? unitMultipliers[unit] || 0 : unit;
  return (roundingFunction || Math.round)(milliseconds / divider) * divider;
};

/**
 * Rounds duration in milliseconds to next full unit.
 *
 * @param milliseconds Time in milliseconds to round
 * @param unit Target unit or divider value
 * @returns milliseconds
 */
export const roundToNext = (
  milliseconds: number,
  unit: number | RoundToUnit,
): number => roundTo(milliseconds, unit, Math.ceil);

/**
 * Rounds duration in milliseconds to previous full unit.
 *
 * @param milliseconds Time in milliseconds to round
 * @param unit Target unit or divider value
 * @returns milliseconds
 */
export const roundToPrevious = (
  milliseconds: number,
  unit: number | RoundToUnit,
): number => roundTo(milliseconds, unit, Math.floor);

/**
 * Resolves most accurate timestring from given task
 * @param task TaskWithKey
 */
export const resolveTimeString = (task: TaskWithKey) => {
  if (task.start && task.end && task.date) {
    return (
      task.date +
      ' (' +
      moment(task.start).format('HH:mm') +
      ' - ' +
      moment(task.end).format('HH:mm') +
      ')'
    );
  }
  if (task.start && !task.end) {
    return moment(task.start).format('DD.MM.YYYY HH:mm');
  }
  if (task.start && task.end) {
    return (
      moment(task.start).format('DD.MM.YYYY HH:mm') +
      ' - ' +
      moment(task.end).format('DD.MM.YYYY HH:mm')
    );
  }
  if (task.date) {
    return task.date;
  }
  if (task.weekNumber) {
    return i18n().ui.week_text + ' ' + task.weekNumber;
  }
  return '';
};
