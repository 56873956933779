import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    height: 'calc(100% - 56px)',
    background: 'white',
    overflow: 'hidden',
  },
  bar: {
    height: 'fit-content',
  },
  topFormControl: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    background: '#F5F5F5',
  },
  itemContainer: {
    height: 'fit-content',
  },
  bottomFormControl: {},
  searchInput: {
    flex: '0 0 auto',
    width: '20%',
  },
  button: {
    color: '#000',
    background: 'rgba(0,0,0,0)',
    margin: '10px 10px 0 10px',
    width: '15%',
    boxShadow: 'none',
    flex: '0 0 auto',
    '&:hover': {
      background: 'rgba(0,0,0,0)',
    },
  },
  selected: {
    background: 'rgba(80, 80, 80, 0.20)',
  },
  icon: {
    marginRight: '5px',
  },
});
export default styles;
