import { Popper } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  StyledComponentProps,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { ClearOutlined, CreateOutlined, Save } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventNote from '@material-ui/icons/EventNote';
import Help from '@material-ui/icons/Help';
import classNames from 'classnames';
import * as _ from 'lodash';
import { TimePicker } from 'material-ui-pickers';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { i18n } from '@shared/locale/';
import {
  Change,
  ChangeLog,
  ChangeType,
  HoursByHourType,
  HourTypeData,
  HourTypeWithKey,
  WorkingHoursWithKey,
  WorkTimeNotesByHourType,
} from '@shared/schema';
import { Schema } from '@shared/schema/index';
import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import {
  calculateTotalWorkingHours,
  DATEPICKER_MAX_TIME,
} from '@shared/utils/timeUtils';
import firebaseApp from 'firebaseApp';
import { ApplicationState } from 'reducers';
import { updateSelectedDate } from 'reducers/reports/reportsActions';
import CustomTableCell from 'theme/styles/tableCell';
import ChangeLogDialog from './../ChangeLog/ChangeLog';
import PopperNotePuble from './components/PopperNotePuble';
import styles from './styles';

export interface WorkTimeTableMemberRowProps
  extends DispatchProp,
    StyledComponentProps {
  hourTypes: HourTypeWithKey[];
  hoursByHourType: HoursByHourType;
  notesByHourType: WorkTimeNotesByHourType;
  workDate: string;
  changeLog: ChangeLog[];
  selectedDateKey?: string;
  month: string;
  memberId: string;
  firebaseUser: firebase.User | undefined;
  companyId: string;
}

interface State {
  dialogOpen: boolean;
  hasChanges: boolean;
  hasTooManyHours: boolean;
  workingDay: WorkingHoursWithKey;
  workingDayCopy: WorkingHoursWithKey;
  hoursByHourType: HoursByHourType;
  showNote: boolean;
  popperAnchorEl?: any;
  popperMsg?: string;
  changeLogOpen: boolean;
  allHoursForOneDay: number;
}

/**
 * Initialized TimePicker default picked time
 */
let defaultTime = new Date().setFullYear(2000, 0, 1);
defaultTime = new Date(defaultTime).setHours(0, 0, 0, 0);

/**
 * Renders one table row with one member data
 * @param props
 */
export class WorkTimeTableMemberRow extends React.Component<
  WorkTimeTableMemberRowProps,
  State
> {
  constructor(props: WorkTimeTableMemberRowProps) {
    super(props);
    this.state = this.getInitialState(props);
  }

  /**
   * componentWillReceiveProps
   */
  public componentWillReceiveProps(nextProps: WorkTimeTableMemberRowProps) {
    this.setState(this.getInitialState(nextProps));
  }

  /**
   * Render
   */
  public render() {
    const { classes = {}, workDate, selectedDateKey, changeLog } = this.props;
    const {
      workingDay,
      changeLogOpen,
      hasChanges,
      hasTooManyHours,
      dialogOpen,
      allHoursForOneDay,
      showNote,
      popperAnchorEl,
      popperMsg,
    } = this.state;

    return (
      <>
        <Dialog open={!!(selectedDateKey && dialogOpen)}>
          <DialogTitle id="alert-dialog-title">{i18n().ui.caution}</DialogTitle>
          <DialogContent>
            {hasTooManyHours
              ? i18n().ui.total_working_hours_per_day_max_24h
              : hasChanges
              ? i18n().ui.unsaved_changes
              : i18n().ui.cancel_editing}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogWindow}>
              {hasTooManyHours ? i18n().ui.ok : i18n().ui.cancel}
            </Button>
            {!hasTooManyHours && (
              <Button
                onClick={
                  hasChanges
                    ? this.cancelModifiedDateData
                    : this.changeSelectedDate
                }
              >
                {i18n().ui.continue}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <TableRow className={classes.row}>
          <CustomTableCell className={classes.iconButtonCell} data-test="date">
            <div className={classes.iconButtonContainer}>
              {selectedDateKey !== workDate ? (
                <Tooltip title={i18n().ui.modify}>
                  <IconButton
                    className={classes.iconButtons}
                    onClick={this.activateModifyingWorkDayData}
                  >
                    <CreateOutlined />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title={i18n().ui.save}>
                    <IconButton
                      className={classes.iconButtons}
                      onClick={this.saveModifiedWorkDayData}
                    >
                      <Save />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={i18n().ui.cancel}>
                    <IconButton
                      className={classes.iconButtons}
                      onClick={this.cancelModifyingWorkDayData}
                    >
                      <ClearOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </CustomTableCell>
          <CustomTableCell className={classes.tableCell} data-test="date">
            {moment.utc(workDate).format('DD.MM.YYYY')}
          </CustomTableCell>

          {workingDay.data.map((data, index) => (
            <CustomTableCell
              key={data.hourtype}
              className={classes.tableCell}
              data-test="hoursByHourTypeCell"
            >
              {selectedDateKey !== workDate ? (
                <>
                  {!isNaN(data.time) ? ( // Checks if value is not NaN otherwise value is 0
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <div data-test={`duration-${index}`}>
                        {
                          momentDurationFormat(
                            data.time,
                            DurationFormatPreset.HHMM,
                          ) // Converts all values to hours
                        }
                      </div>
                      {data.note && (
                        <div style={{ paddingLeft: '8px', paddingTop: '1px' }}>
                          <EventNote
                            onMouseOver={this.onMouseOver(data)}
                            onMouseOut={this.onMouseOut}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    '00:00'
                  )}
                </>
              ) : (
                <TimePicker
                  ampm={false}
                  value={returnCorrectTimeOrDate(workingDay.data[index].time)}
                  onChange={this.onChangeHoursByHourType(data.hourtype)}
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                  format={'HH:mm'}
                  cancelLabel={i18n().ui.cancel}
                  clearLabel={i18n().ui.clear}
                  invalidDateMessage={i18n().ui.give_valid_time}
                  initialFocusedDate={defaultTime}
                  keyboardIcon={<AccessTimeIcon />}
                  keyboard
                  clearable
                  disableOpenOnEnter
                  data-test="time"
                />
              )}
            </CustomTableCell>
          ))}
          <CustomTableCell
            className={
              allHoursForOneDay > DATEPICKER_MAX_TIME
                ? classNames(classes.tableCell, classes.tableCellError)
                : classes.tableCell
            }
            data-test="overallHours"
          >
            {momentDurationFormat(allHoursForOneDay, DurationFormatPreset.HHMM)}
          </CustomTableCell>
          <CustomTableCell data-test="changeLogIcon">
            {changeLog && changeLog.length > 0 ? (
              <div onClick={this.onChangeLogClick}>
                <Help classes={{ root: classes.logIcon }} />
              </div>
            ) : (
              ''
            )}
          </CustomTableCell>

          <ChangeLogDialog
            changeLog={changeLog}
            open={changeLogOpen}
            closeChangeLog={this.closeChangeLog}
          />
        </TableRow>

        {selectedDateKey !== workDate && (
          <Popper open={showNote} anchorEl={popperAnchorEl} placement="top">
            <PopperNotePuble note={popperMsg} />
          </Popper>
        )}
      </>
    );
  }

  /**
   * Get initial state
   */
  private getInitialState(props: WorkTimeTableMemberRowProps): State {
    const workingDay: WorkingHoursWithKey = buildWorkDayDataObjectBase(
      props.hourTypes,
      props.hoursByHourType,
      props.notesByHourType,
      props.workDate,
      props.month,
      props.changeLog,
    );

    return {
      dialogOpen: false,
      hasChanges: false,
      hasTooManyHours: false,
      showNote: false,
      changeLogOpen: false,
      workingDay,
      workingDayCopy: _.cloneDeep(workingDay),
      hoursByHourType: props.hoursByHourType,
      allHoursForOneDay: calculateTotalWorkingHours(workingDay),
    };
  }

  /**
   * When hovering on tableCell shows popper note if
   * logged hours by hourtype has one
   */
  private onMouseOver = (item: HourTypeData) => (e: any) => {
    if (item.note !== '') {
      this.setState({
        showNote: true,
        popperAnchorEl: e.target,
        popperMsg: item.note,
      });
    }
    this.setState({
      changeLogOpen: false,
    });
  };

  /**
   * Closes popper
   */
  private onMouseOut = () =>
    this.setState({
      showNote: false,
    });

  /**
   * Opens Changelog
   */
  private onChangeLogClick = () =>
    this.setState({
      changeLogOpen: true,
    });

  /**
   * Activates editing workday hours
   */
  private activateModifyingWorkDayData = () => {
    const { workDate, dispatch, selectedDateKey } = this.props;
    const { dialogOpen, workingDay } = this.state;

    if (selectedDateKey) {
      this.setState({
        dialogOpen: !dialogOpen,
        changeLogOpen: false,
      });
    } else {
      dispatch(updateSelectedDate(workDate));

      this.setState({
        workingDayCopy: _.cloneDeep(workingDay),
        changeLogOpen: false,
      });
    }
  };

  /**
   * Cancel editing workday hours
   */
  private cancelModifyingWorkDayData = () => {
    const { dispatch } = this.props;
    const { hasChanges } = this.state;

    if (hasChanges) {
      this.setState({ dialogOpen: true });
    } else {
      dispatch(updateSelectedDate(undefined));
    }
  };

  /**
   * Closes dialog
   */
  private closeDialogWindow = () =>
    this.setState({
      dialogOpen: false,
      hasTooManyHours: false,
    });

  /**
   * Changes changeLogOpen boolean to false to prevent certain bugs.
   * @param open Boolean value from Changelog.tsx
   */
  private closeChangeLog = (open: boolean) =>
    this.setState({
      changeLogOpen: false,
    });

  /**
   * Changes selected editing workday
   */
  private changeSelectedDate = () => {
    const { workDate, dispatch, selectedDateKey } = this.props;
    const { workingDayCopy } = this.state;

    if (selectedDateKey) {
      this.setState({
        dialogOpen: false,
        hasChanges: false,
        hasTooManyHours: false,
        workingDay: _.cloneDeep(workingDayCopy),
      });
    }

    dispatch(updateSelectedDate(workDate));
  };

  /**
   * Cancel editing workday hours in Dialog
   */
  private cancelModifiedDateData = () => {
    const { dispatch } = this.props;
    const { workingDayCopy } = this.state;

    dispatch(updateSelectedDate(undefined));

    this.setState({
      dialogOpen: false,
      hasChanges: false,
      hasTooManyHours: false,
      workingDay: _.cloneDeep(workingDayCopy),
    });
  };

  /**
   * Saves modified data to database
   */
  private saveModifiedWorkDayData = () => {
    const { workingDay, hasChanges } = this.state;
    const {
      companyId,
      memberId,
      workDate,
      dispatch,
      firebaseUser,
    } = this.props;

    if (!firebaseUser) {
      throw new Error('Can not save modified data: no firebaseUser');
    }

    if (!hasChanges) {
      dispatch(updateSelectedDate(undefined));
      return;
    }

    const allHoursForOneDay = calculateTotalWorkingHours(workingDay);

    if (allHoursForOneDay > DATEPICKER_MAX_TIME) {
      this.setState({
        dialogOpen: true,
        hasTooManyHours: true,
      });

      return;
    }

    const changes: Change[] = [];

    for (const data of workingDay.data) {
      const { hourtype } = data;

      const newValue = data.time;
      const oldValue = this.getPreviousTotalTime(hourtype);

      if (newValue !== oldValue) {
        changes.push({
          hourtypeId: hourtype,
          hourtypeName: this.getHourTypeName(hourtype),
          changeType: ChangeType.TIME,
          oldValue,
          newValue,
        });
      }
    }

    // No need to save entries with empty values to database
    workingDay.data = workingDay.data.filter(data => data.time || data.note);

    if (!workingDay.changeLog) {
      workingDay.changeLog = [];
    }

    workingDay.changeLog.push({
      memberName: firebaseUser.displayName || '',
      memberId: firebaseUser.uid || '',
      timestamp: moment().valueOf(),
      changes,
    });

    const workingHours = { ...workingDay };
    delete workingHours.key;

    try {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.MEMBERS)
        .doc(memberId)
        .collection(Schema.WORKINGHOURS)
        .doc(workDate)
        .set(workingHours);

      this.setState({
        dialogOpen: false,
        hasTooManyHours: false,
        allHoursForOneDay,
      });

      dispatch(updateSelectedDate(undefined));
    } catch (error) {
      console.error(
        'Failed to save WorkTimeTableMemberRow modified values',
        error,
      );
    }
  };

  /**
   * Finds previous workingTime from array
   * @param hourTypekey Current hourtype
   * @returns Previous working time
   */
  private getPreviousTotalTime = (hourTypeKey: string | undefined) => {
    const { workingDayCopy } = this.state;
    let workingHourTime: number = 0;
    if (workingDayCopy && workingDayCopy.data && hourTypeKey) {
      workingDayCopy.data.forEach(workingHour => {
        if (workingHour.hourtype === hourTypeKey) {
          workingHourTime = workingHour.time;
        }
      });
    }
    return workingHourTime;
  };
  /**
   * Finds name for hourtype key
   * @param hourTypekey Current hourtype
   * @returns Name for hourtype
   */
  private getHourTypeName = (hourTypeKey: string | undefined) => {
    const { hourTypes } = this.props;
    const selectedHourtype = hourTypes.find(
      hourtype => hourtype.key === hourTypeKey,
    );
    if (selectedHourtype) {
      return selectedHourtype.name;
    } else {
      return '';
    }
  };

  /**
   * Handles TimePicker changes
   *
   * @param hourTypeKey
   */
  private onChangeHoursByHourType = (hourTypeKey?: string) => (
    newTime: moment.Moment,
  ) => {
    const { workingDay } = this.state;

    const time = (newTime.hours() * 60 + newTime.minutes()) * 60 * 1000;

    const newWorkingDay: WorkingHoursWithKey = {
      ...workingDay,
      data: [],
    };

    for (const data of workingDay.data) {
      if (data.hourtype === hourTypeKey) {
        if (data.time === time) {
          return;
        }
        newWorkingDay.data.push({ ...data, time });
      } else {
        newWorkingDay.data.push(data);
      }
    }

    const allHoursForOneDay = calculateTotalWorkingHours(newWorkingDay);

    this.setState({
      workingDay: newWorkingDay,
      hasChanges: true,
      allHoursForOneDay,
    });
  };
}

/**
 * Converts workingday time data for TimePicker readable form
 */
export const returnCorrectTimeOrDate = (time: number) => {
  if (time > 0) {
    const date = moment().set({ h: 0, m: 0, s: 0, ms: 0 });
    const duration = date.valueOf() + time;
    return duration;
  }

  return null;
};

/**
 * Makes object base for handling workingDay data
 * with all hourtypes and those data
 * @param hourTypes
 * @param workTimeByHours
 * @param notesByHours
 * @param workDate
 * @param month
 */
export const buildWorkDayDataObjectBase = (
  hourTypes: HourTypeWithKey[],
  workTimeByHours: HoursByHourType,
  notesByHours: WorkTimeNotesByHourType,
  workDate: string,
  month: string,
  changeLog: ChangeLog[],
): WorkingHoursWithKey => {
  /**
   * WorkingHoursWithKey object needs to be inizialized
   * before use. Inizialized it with empty data
   */
  const workingDay: WorkingHoursWithKey = {
    data: [
      {
        time: 0,
        hourtype: '',
        note: '',
      },
    ],
    month: '',
    changeLog: [],
  };

  /**
   * Go through all hourtypes and every iteration
   * push on object to workingDay array that contains
   * all data for one hourtype
   */
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < hourTypes.length; i++) {
    const objectToAssign: HourTypeData = {
      hourtype: hourTypes[i] && hourTypes[i].key,
      time:
        workTimeByHours[`${hourTypes[i].key}`] !== undefined
          ? workTimeByHours[`${hourTypes[i].key}`]
          : 0,
      note:
        notesByHours[`${hourTypes[i].key}`] !== undefined
          ? notesByHours[`${hourTypes[i].key}`]
          : '',
    };

    workingDay.data && workingDay.data.push(objectToAssign);
  }

  /**
   * Assignes key to specific what data of day is
   */
  workingDay.key = workDate;
  /**
   * Month is for mobile use only
   */
  workingDay.month = month;

  /**
   * Changelog from either mobile/dashboard edits.
   */
  workingDay.changeLog = changeLog;

  /**
   * Shift that first unneccesary inizialized data
   * object away
   */
  workingDay.data.shift();

  return workingDay;
};

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<WorkTimeTableMemberRowProps>,
) => {
  return {
    ...ownProps,
    settings: state.company.activeCompany.settings,
    selectedMembers: state.reports.selectedMembers,
    startDate: state.reports.dateStart,
    endDate: state.reports.dateEnd,
    selectedMember: state.reports.selectedMember,
    selectedDateKey: state.reports.selectedDateKey,
    firebaseUser: state.auth.firebaseUser,
  } as WorkTimeTableMemberRowProps;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(WorkTimeTableMemberRow),
);
