import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { i18n } from '@shared/locale/index';
import { GeoLocation } from '@shared/schema';
import GoogleMap from 'google-map-react';
import * as React from 'react';
import Marker from '../../components/MapMarker';
import styles from '../styles';

export interface MapContainerProps extends StyledComponentProps {
  worksiteGeoLocation: GeoLocation | undefined;
}

export class TaskMapContainer extends React.Component<MapContainerProps> {
  constructor(props: MapContainerProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { worksiteGeoLocation, classes = {} } = this.props;

    if (worksiteGeoLocation) {
      const center = {
        lat: worksiteGeoLocation.la,
        lng: worksiteGeoLocation.lo,
      };

      return (
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMap
            bootstrapURLKeys={{ key: CONFIG.apiKeys.googleMapKey }}
            defaultCenter={center}
            defaultZoom={15}
            options={this.createMapOptions}
          >
            <Marker lat={worksiteGeoLocation.la} lng={worksiteGeoLocation.lo} />
          </GoogleMap>
        </div>
      );
    }
    return (
      <p className={classes.noCoordinatesText}>{i18n().ui.no_coordinates}</p>
    );
  }

  /**
   * Options for Map
   * @returns fullscreen control as disabled
   */
  private createMapOptions = () => {
    return {
      fullscreenControl: false,
    };
  };
}

export default withStyles(styles, { withTheme: true })(TaskMapContainer);
