import { Button, Snackbar, SnackbarContent } from '@material-ui/core';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import { InfoRounded } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { i18n } from '@shared/locale';
import {
  CompanyId,
  CustomerWithKey,
  GeoLocation,
  JobTypeWithKey,
  MemberIcons,
  MemberWithKey,
  QualificationWithKey,
  Schema,
  TaskStatus,
  TaskWithKey,
  VatWithKey,
  WorksiteWithKey,
} from '@shared/schema';
import { getUuid } from '@shared/utils';
import { DbscanPoint } from '@turf/clusters-dbscan';
import { FeatureCollection, Point, Properties } from '@turf/turf';
import * as turf from '@turf/turf';
import LoadingSpinner from 'components/LoadingSpinner';
import EventEditorDialog from 'containers/Private/Timeline/components/EventEditorDialog';
import firebaseApp from 'firebaseApp';
import GoogleMap, { ChangeEventValue } from 'google-map-react';
import * as _ from 'lodash';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { newResourceEvent } from 'reducers/events/eventActions';
import { newVenue } from 'reducers/venues/venuesActions';
import { getTasksTableRef } from 'utils/tasksUtil';
import WorksiteEditor from '../../Venues/components/Editor';
import ClusterMarkerComponent from '../components/ClusterMarker/ClusterMarkerComponent';
import Marker from '../components/Marker';
import SearchDrawer from '../components/SearchDrawer';
import styles from './styles';
export interface MapProps
  extends Partial<DispatchProp<any>>,
    StyledComponentProps {
  companyId: CompanyId;
}

interface State {
  isLoading: boolean;
  worksites: WorksiteWithKey[];
  tasks: TaskWithKey[];
  customers: CustomerWithKey[];
  members: MemberWithKey[];
  jobTypes: JobTypeWithKey[];
  vats: VatWithKey[];
  qualifications: QualificationWithKey[];
  markers: JSX.Element[];
  infoWindows: JSX.Element[];
  mapZoom?: number;
  defaultZoom: number;
  companyCoordinates: GeoLocation;
  showDrawer: boolean;
  selectedCoordinates: GeoLocation | undefined;
  mapCenter: GeoLocation | undefined;
  pickingMode: boolean;
  pickedCoordinates: Coordinates;
  options: Options;
  newWorksiteId: string | undefined;
}

interface Coordinates {
  lat: number | undefined;
  lng: number | undefined;
}

interface Options {
  fullscreenControl: boolean;
  draggableCursor: string;
}

/**
 * Zoom level for map
 */
export const zoomLevelByLowerScaleBound = {
  '0': 1000000000,
  '1': 500000000,
  '2': 200000000,
  '3': 50000000,
  '4': 25000000,
  '5': 12500000,
  '6': 6500000,
  '7': 3000000,
  '8': 1500000,
  '9': 750000,
  '10': 360000,
  '11': 180000,
  '12': 90000,
  '13': 45000,
  '14': 22500,
  '15': 12500,
  '16': 5000,
  '17': 2500,
  '18': 1500,
  '19': 750,
  '20': 500,
  '21': 250,
  '22': 100,
  '23': 0,
} as { [key: string]: number };

/**
 * Gets specific zoom level scales from zoomLevelByLowerScaleBound object by
 * what zoom is in google maps by that time
 *
 * @param {number} val
 */
export const getZoomLevel = (val: number) => {
  return zoomLevelByLowerScaleBound['' + val];
};

export class MapContainer extends React.Component<MapProps, State> {
  private unsubscribeWorksites: () => void;
  private unsubscribeTasks: () => void;
  private unsubscribeCustomers: () => void;
  private unsubscribeMembers: () => void;
  private unsubscribeJobTypes: () => void;
  private unsubscribeVats: () => void;
  private unsubscribeQualifications: () => void;

  /**
   * Instance for Google map
   * type is any because in GoogleMap component the direct Map is typed as any
   */
  private mapInstance: any = {};
  constructor(props: MapProps) {
    super(props);
    this.state = {
      isLoading: true,
      worksites: [],
      tasks: [],
      customers: [],
      members: [],
      jobTypes: [],
      vats: [],
      qualifications: [],
      markers: [],
      infoWindows: [],
      mapZoom: undefined,
      defaultZoom: 6,
      companyCoordinates: {
        la: 63.0156308,
        lo: 25.9579426,
        t: 0,
      },
      selectedCoordinates: {
        la: 0,
        lo: 0,
        t: 0,
      },
      showDrawer: false,
      mapCenter: undefined,
      pickingMode: false,
      pickedCoordinates: {
        lat: undefined,
        lng: undefined,
      },
      options: { fullscreenControl: false, draggableCursor: 'grab' },
      newWorksiteId: undefined,
    };
  }
  public componentDidMount = async () => {
    // Get all required data for markers and their tasks.
    let worksites: WorksiteWithKey[] = [];
    try {
      this.unsubscribeWorksites = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.WORKSITE)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          worksites.length > 0 ? (worksites = []) : worksites;
          snapshot.forEach(doc => {
            const data = doc.data() as WorksiteWithKey;
            data.key = doc.id;
            worksites.push(data);
            worksites = worksites.filter(map => map.geoLocation);
          });
          this.setState({
            worksites,
          });
          if (
            this.state.tasks.length !== 0 &&
            this.state.customers.length !== 0 &&
            this.state.members.length !== 0
          ) {
            this.updateMarkers(
              worksites,
              this.state.tasks,
              this.state.customers,
              this.state.members,
              this.state.mapZoom,
            );
          }
        });
    } catch (error) {
      console.error('Unable to get worksites: ', error);
    }

    let tasks: TaskWithKey[] = [];
    try {
      this.unsubscribeTasks = getTasksTableRef(this.props.companyId).onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          tasks.length > 0 ? (tasks = []) : tasks;
          snapshot.forEach(doc => {
            const data = doc.data() as TaskWithKey;
            data.key = doc.id;
            tasks.push(data);
            tasks = tasks.filter(
              task => task.worksite && task.status !== 'DONE',
            );
          });
          this.setState({
            tasks,
          });
          if (
            this.state.worksites.length !== 0 &&
            this.state.customers.length !== 0 &&
            this.state.members.length !== 0
          ) {
            this.updateMarkers(
              this.state.worksites,
              tasks,
              this.state.customers,
              this.state.members,
              this.state.mapZoom,
            );
          }
        },
      );
    } catch (error) {
      console.error('Unable to get tasks: ', error);
    }

    let customers: CustomerWithKey[] = [];
    try {
      this.unsubscribeCustomers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.CUSTOMERS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          customers.length > 0 ? (customers = []) : customers;
          snapshot.forEach(doc => {
            const data = doc.data() as CustomerWithKey;
            data.key = doc.id;
            customers.push(data);
          });
          this.setState({
            customers,
          });
          if (
            this.state.worksites.length !== 0 &&
            this.state.tasks.length !== 0 &&
            this.state.members.length !== 0
          ) {
            this.updateMarkers(
              this.state.worksites,
              this.state.tasks,
              customers,
              this.state.members,
              this.state.mapZoom,
            );
          }
        });
    } catch (error) {
      console.error('Unable to get customers: ', error);
    }

    let members: MemberWithKey[] = [];
    try {
      this.unsubscribeMembers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.MEMBERS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          members.length > 0 ? (members = []) : members;
          snapshot.forEach(doc => {
            const data = doc.data() as MemberWithKey;
            data.key = doc.id;
            members.push(data);
          });
          this.setState({
            members,
          });
          if (
            this.state.worksites.length !== 0 &&
            this.state.tasks.length !== 0 &&
            this.state.customers.length !== 0
          ) {
            this.updateMarkers(
              this.state.worksites,
              this.state.tasks,
              this.state.customers,
              members,
              this.state.mapZoom,
            );
          }
        });
    } catch (error) {
      console.error('Unable to get members: ', error);
    }

    let jobTypes: JobTypeWithKey[] = [];
    try {
      this.unsubscribeJobTypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.JOBTYPES)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          jobTypes.length > 0 ? (jobTypes = []) : jobTypes;
          snapshot.forEach(doc => {
            const data = doc.data() as JobTypeWithKey;
            data.key = doc.id;
            jobTypes.push(data);
          });
          this.setState({
            jobTypes,
          });
        });
    } catch (error) {
      console.error('Unable to get jobTypes: ', error);
    }

    let vats: VatWithKey[] = [];
    try {
      this.unsubscribeVats = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          vats.length > 0 ? (vats = []) : vats;
          snapshot.forEach(doc => {
            const data = doc.data() as VatWithKey;
            data.key = doc.id;
            vats.push(data);
          });
          this.setState({
            vats,
          });
        });
    } catch (error) {
      console.error('Unable to get VATs: ', error);
    }

    let qualifications: QualificationWithKey[] = [];
    try {
      this.unsubscribeQualifications = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.QUALIFICATIONS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          qualifications.length > 0 ? (qualifications = []) : qualifications;
          snapshot.forEach(doc => {
            const data = doc.data() as QualificationWithKey;
            data.key = doc.id;
            qualifications.push(data);
          });
          this.setState({
            qualifications,
          });
        });
    } catch (error) {
      console.error('Unable to get qualifications: ', error);
    }

    let companyCoordinates = this.state.companyCoordinates;
    let defaultZoom = this.state.defaultZoom;

    try {
      const companyDoc = await firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .get();

      const company = companyDoc.data();

      if (company && company.geoLocation) {
        companyCoordinates = company.geoLocation;
        defaultZoom = 14;
      }

      this.updateMarkers(worksites, tasks, customers, members, defaultZoom);

      this.setState({
        companyCoordinates,
        defaultZoom,
        isLoading: false,
        selectedCoordinates: companyCoordinates,
      });
    } catch (error) {
      console.error('Unable to get company data: ', error);
    }
  };

  public componentWillUnmount() {
    this.unsubscribeWorksites && this.unsubscribeWorksites();
    this.unsubscribeTasks && this.unsubscribeTasks();
    this.unsubscribeCustomers && this.unsubscribeCustomers();
    this.unsubscribeMembers && this.unsubscribeMembers();
    this.unsubscribeJobTypes && this.unsubscribeJobTypes();
    this.unsubscribeVats && this.unsubscribeVats();
    this.unsubscribeQualifications && this.unsubscribeQualifications();
  }

  public render() {
    const {
      markers,
      isLoading,
      companyCoordinates,
      defaultZoom,
      worksites,
      customers,
      jobTypes,
      tasks,
      vats,
      qualifications,
      showDrawer,
      pickingMode,
      members,
      newWorksiteId,
    } = this.state;
    const { classes = {}, companyId } = this.props;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    const defaultCenter = {
      lat: companyCoordinates.la,
      lng: companyCoordinates.lo,
    };

    const mapCenter = {
      lat: this.state.mapCenter
        ? this.state.mapCenter.la
        : companyCoordinates.la,
      lng: this.state.mapCenter
        ? this.state.mapCenter.lo
        : companyCoordinates.lo,
    };
    return (
      <>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <SearchIcon
            onClick={this.showDrawer}
            className={classes.searchIcon}
          />
          <Button
            onClick={this.onAddNewWorksiteClick}
            className={classes.addNewWorksite}
          >
            {pickingMode ? i18n().ui.cancel : i18n().ui.add_new_worksite}
          </Button>

          <GoogleMap
            /**
             * Gets google map directly and set it to instance so it can be use later on when
             * zooming to clustering point
             */
            onGoogleApiLoaded={({ map, maps }) => {
              this.mapInstance = map;
            }}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: CONFIG.apiKeys.googleMapKey }}
            defaultCenter={defaultCenter}
            center={mapCenter}
            defaultZoom={defaultZoom}
            onChange={this.onChange}
            options={this.state.options}
            onClick={e => this.onMapClick(e)}
          >
            {markers}
          </GoogleMap>
        </div>

        <SearchDrawer
          worksites={worksites}
          customers={customers}
          tasks={tasks}
          showSearchDrawer={showDrawer}
          closeSearchHandler={this.closeDrawer}
          selectedCoordinates={this.getSelectedLocation}
        />
        <WorksiteEditor
          editing={false}
          pickedCoordinates={this.state.pickedCoordinates}
          companyId={this.props.companyId}
        />
        <Snackbar
          open={pickingMode}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          classes={{ root: classes.snackBar }}
          data-test="snackbar"
        >
          <SnackbarContent
            className={classes.snackbarContent}
            message={
              <div className={classes.messageContainer}>
                <InfoRounded className={classes.infoIcon} />
                <b>{i18n().ui.choose_location}</b>
              </div>
            }
          />
        </Snackbar>
        <EventEditorDialog
          companyId={companyId}
          jobTypes={jobTypes}
          members={members}
          worksites={worksites}
          qualifications={qualifications}
          vats={vats}
          customers={customers}
          newWorksiteId={newWorksiteId || undefined}
        />
      </>
    );
  }

  /*
   * Gets map zoom level every time map updates and sets that to state if the value
   * is not already same in the state
   *
   * @param {ChangeEventValue} e
   */
  private onChange = (e: ChangeEventValue) => {
    if (this.state.mapZoom === undefined) {
      this.updateMarkers(
        this.state.worksites,
        this.state.tasks,
        this.state.customers,
        this.state.members,
        e.zoom,
      );

      this.setState({
        mapZoom: e.zoom,
      });
    } else {
      if (this.state.mapZoom !== e.zoom) {
        this.updateMarkers(
          this.state.worksites,
          this.state.tasks,
          this.state.customers,
          this.state.members,
          e.zoom,
        );

        this.setState({
          mapZoom: e.zoom,
        });
      }
    }
  };

  /**
   * Get correct status for worksite
   * @param key Key value
   * @param statuses Array of worksite's tasks statuses
   */
  private getStatus = (key: string, statuses: TaskStatus[]) => {
    const isTaskLate = this.state.tasks.find(
      task =>
        task.worksite!.id === key &&
        task.status === TaskStatus.UNDONE &&
        task.start < new Date().getTime() &&
        task.start !== 0,
    );
    if (isTaskLate) {
      return TaskStatus.LATE;
    }
    if (statuses.find(status => status === TaskStatus.PAUSED)) {
      return TaskStatus.PAUSED;
    }
    if (statuses.find(status => status === TaskStatus.ACTIVE)) {
      return TaskStatus.ACTIVE;
    }
    if (statuses.find(status => status === TaskStatus.DONE)) {
      return TaskStatus.DONE;
    }
    return TaskStatus.UNDONE;
  };

  /**
   * Handles zoom to specific cluster markers and shows all worksite inside of it
   * @param {DbscanPoint[]} cluster
   */
  private onClickClusterMarker = (cluster: DbscanPoint[]) => () => {
    const bbox = turf.bbox({
      type: 'FeatureCollection',
      features: cluster,
    });

    if (this.mapInstance) {
      this.mapInstance.fitBounds(
        {
          east: bbox[2],
          south: bbox[1],
          north: bbox[3],
          west: bbox[0],
        },
        0,
      );
    }
  };

  /**
   * Update all markers, called when database collections are updated / map is opened
   * @param worksites All worksites with geolocation
   * @param tasks All tasks with worksite
   * @param customers All customers
   * @param members All Members
   */
  private updateMarkers = (
    worksites: WorksiteWithKey[],
    tasks: TaskWithKey[],
    customers: CustomerWithKey[],
    members: MemberWithKey[],
    mapZoom?: number,
    key?: string,
  ) => {
    const markers = [];
    const markersToShow = [];

    for (const worksite of worksites) {
      // Getting all tasks for each worksite
      const worksiteTasks = tasks.filter(
        task =>
          task.worksite &&
          task.worksite.id === worksite.key &&
          task.status !== TaskStatus.DONE,
      );
      // Getting all statuses for comparison
      const statuses = worksiteTasks.map(task => task.status);
      const worksiteStatus = this.getStatus(worksite.key, statuses);

      // Correct customer for each worksite
      const customer = customers.filter(c => c.key === worksite.customer);

      let worksiteCustomer: CustomerWithKey = {
        key: '',
        name: '',
        worksiteRefs: {},
      };
      if (customer && customer.length) {
        worksiteCustomer = customer[0];
      }

      // Getting correct icons for each task
      const avatarsWithId = [];
      for (const worksiteTask of worksiteTasks) {
        const worksiteMembers = members.filter(
          member => member.key === worksiteTask.userId,
        );
        if (worksiteMembers[0] && worksiteMembers[0].photoURL) {
          const avatar: MemberIcons = {
            id: worksiteMembers[0].key,
            photoURL: worksiteMembers[0].photoURL,
          };
          avatarsWithId.push(avatar);
        }
      }

      const marker = {
        key: worksite.key,
        lat: worksite.geoLocation ? worksite.geoLocation.la : 0,
        lng: worksite.geoLocation ? worksite.geoLocation.lo : 0,
        data: worksite,
        status: worksiteStatus,
        worksiteTasks,
        customer: worksiteCustomer,
        memberIcons: avatarsWithId,
        selected: worksite.key === key,
      };
      markers.push(marker);
    }

    const points: FeatureCollection<Point, Properties> = {
      type: 'FeatureCollection',
      features: [],
    };

    for (const marker of markers as any) {
      points.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [marker.lng, marker.lat],
        },
        properties: {
          markerId: marker.key,
        },
      });
    }

    if (mapZoom) {
      const clusters = turf.clustersDbscan(
        points,
        getZoomLevel(Math.floor(mapZoom)) / 112,
        {
          units: 'meters',
          minPoints: 2,
        },
      );

      /*
       * The result from above contains an array of points with a cluster property, in stead they're regrouped
       * in Clusters -> array of points object
       */
      const clusterMap = {} as { [key: string]: DbscanPoint[] };

      clusters.features.forEach(point => {
        if (point.properties.cluster === undefined) {
          return;
        }

        if (clusterMap[point.properties.cluster]) {
          clusterMap[point.properties.cluster].push(point);
        } else {
          clusterMap[point.properties.cluster] = [];
          clusterMap[point.properties.cluster].push(point);
        }
      });

      for (const feature of clusters.features) {
        if (feature.properties.cluster === undefined) {
          for (const marker of markers) {
            if (marker.key === feature.properties.markerId) {
              markersToShow.push(
                <Marker
                  key={marker.key}
                  lat={marker.lat}
                  lng={marker.lng}
                  data={marker.data}
                  status={marker.status}
                  worksiteTasks={marker.worksiteTasks}
                  customer={marker.customer}
                  memberIcons={marker.memberIcons}
                  selected={marker.data.key === key}
                  newWorksiteId={this.newWorksiteId}
                  companyId={this.props.companyId}
                />,
              );
              break;
            }
          }
        }
      }

      // tslint:disable-next-line
      for (const key in clusterMap) {
        const cluster = clusterMap[key];
        const center = turf.center({
          type: 'FeatureCollection',
          features: cluster,
        });

        markersToShow.push(
          <ClusterMarkerComponent
            onClick={this.onClickClusterMarker(clusterMap[key])}
            key={getUuid()}
            clusteredItemCount={cluster.length}
            lat={center!.geometry!.coordinates[1]}
            lng={center!.geometry!.coordinates[0]}
          />,
        );
      }
    }
    this.setState({
      markers: markersToShow,
    });
  };

  /**
   * Gets selected coordinates from Search drawer
   * @param geoLocation Selected worksite's coordinates.
   */
  private getSelectedLocation = async (
    geoLocation: GeoLocation | undefined,
    key: string | undefined,
  ) => {
    const swLon = this.mapInstance
      .getBounds()
      .getSouthWest()
      .lng();
    const neLon = this.mapInstance
      .getBounds()
      .getNorthEast()
      .lng();
    const distanceLatitude = Math.abs(swLon - neLon) * 0.165;
    const resizedLocation = _.cloneDeep(geoLocation);

    if (resizedLocation) {
      resizedLocation.lo += distanceLatitude;
    }

    this.setState({
      mapCenter: resizedLocation,
      selectedCoordinates: geoLocation,
    });
    this.updateMarkers(
      this.state.worksites,
      this.state.tasks,
      this.state.customers,
      this.state.members,
      this.state.mapZoom,
      key,
    );
  };

  /**
   * Sets pickingMode true, changes cursor into crosshair
   */
  private onAddNewWorksiteClick = () => {
    const { pickingMode } = this.state;

    if (pickingMode) {
      const options: Options = {
        fullscreenControl: false,
        draggableCursor: 'grab',
      };
      this.setState({
        pickingMode: false,
        options,
      });
    } else {
      const options: Options = {
        fullscreenControl: false,
        draggableCursor: 'crosshair',
      };

      this.setState({
        pickingMode: true,
        options,
      });
    }
  };

  /**
   * Gets the coordinates from clicked location if picking mode is active
   * Sets cursor back to normal
   */
  private onMapClick = (event: any) => {
    const { pickingMode } = this.state;
    const { companyId, dispatch } = this.props;
    if (pickingMode) {
      const coordinates: Coordinates = {
        lat: event.lat,
        lng: event.lng,
      };

      const options: Options = {
        fullscreenControl: false,
        draggableCursor: 'grab',
      };

      dispatch && dispatch(newVenue(companyId));

      this.setState({
        pickingMode: false,
        pickedCoordinates: coordinates,
        options,
      });
    }
  };

  /**
   * Opens Drawer from search icon
   */
  private showDrawer = () => {
    this.setState({
      showDrawer: true,
    });
  };

  /**
   * Closes Drawer from SearchDrawer's close icon.
   */
  private closeDrawer = () => {
    this.setState({
      showDrawer: false,
    });
  };

  /**
   * Receive worksite Id from Marker.tsx and save it to state
   * @param id Worksite ID
   */
  private newWorksiteId = (id: string) => {
    const { dispatch, companyId } = this.props;
    this.setState({ newWorksiteId: id });
    dispatch && dispatch(newResourceEvent(companyId));
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<MapProps>,
) => {
  return {
    ...ownProps,
  };
};

export default withRouter<any>(
  withStyles(styles, { withTheme: true })(
    connect<any>(mapStateToProps)(MapContainer),
  ),
);
