import * as React from 'react';
import FullScreen from 'react-full-screen';
import { connect, DispatchProp } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import classNames from 'classnames';
import firebaseApp from 'firebaseApp';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import TaskBankIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/CalendarToday';
import Chat from '@material-ui/icons/Chat';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CustomersIcon from '@material-ui/icons/Contacts';
import Description from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import MapIcon from '@material-ui/icons/Map';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import WorksiteIcon from '@material-ui/icons/Place';
import SettingsIcon from '@material-ui/icons/Settings';

import { Tooltip } from '@material-ui/core';
import { applyCompanyDefaults } from '@shared/defaults';
import { i18n } from '@shared/locale';
import { setLocale, SupportedLocale } from '@shared/locale/index';
import {
  Company,
  CompanyId,
  CompanyStatus,
  Schema,
  UserRole,
  ViewState,
} from '@shared/schema';
import AuthMessage from 'components/AuthMessage';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingSpinner from 'components/LoadingSpinner';
import { extractName, extractPath, NAVIGATION_PATH } from 'paths';
import { ApplicationState } from 'reducers';
import { updateCompany } from 'reducers/company/companyActions';
import { CompanyState } from 'reducers/company/companyReducer';
import { setFullscreenMode } from 'reducers/navi/naviActions';
import Account from '../Account/containers/AccountContainer';
import ChatGroupContainer from '../ChatGroup/containers/ChatGroupContainer';
import Customers from '../Customer/containers/CustomerContainer';
import Map from '../Map/containers/MapContainer';
import ReportsContainer from '../Reports';
import Resources from '../Resources/containers/ResourcesContainer';
import Settings from '../Settings';
import TaskContainer from '../Timeline/containers/TaskContainer';
import Venues from '../Venues/containers/WorksiteContainer';
import ResourceBadge from './components/ResourceBadge';
import DrawerItem from './DrawerItem';
import { styles } from './mainStyle';

export interface CompanyParams {
  companyId: CompanyId;
}

export interface MainLayoutProps
  extends DispatchProp<any>,
    RouteComponentProps<CompanyParams> {
  classes: any;
  theme: any;
  firebaseUser: firebase.User | undefined;
  fullscreen: boolean;
  activeRole: UserRole;
  companyState: CompanyState;
}

interface State {
  isUnknownCompany: boolean;
  anchorEl: any | null;
  mobileMoreAnchorEl: any | null;
  open: boolean;
  locale: SupportedLocale;
  company: Company | undefined;
}

export class MainLayout extends React.Component<MainLayoutProps, State> {
  public state: State = {
    isUnknownCompany: false,
    anchorEl: null,
    mobileMoreAnchorEl: null,
    open: false,
    locale: localStorage.getItem('locale') as SupportedLocale,
    company: undefined,
  };

  private unsubscribeCompany: () => void;

  public componentDidMount() {
    const { companyId } = this.props.match.params;

    if (companyId !== undefined) {
      this.unsubscribeCompany = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
          const data: any = snapshot.data();
          if (data) {
            const company = applyCompanyDefaults(data);
            this.props.dispatch(updateCompany(snapshot.id, company));
          }

          this.setState({
            isUnknownCompany: !data,
            company: data,
          });
        });
    }
  }

  public componentWillUnmount() {
    this.unsubscribeCompany && this.unsubscribeCompany();
  }

  public handleProfileMenuOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  public handleMobileMenuOpen = (event: any) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  public handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  public handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  public handleDrawerClose = () => {
    this.setState({ open: false });
  };

  public render() {
    const { anchorEl, mobileMoreAnchorEl, isUnknownCompany, company } =
      this.state;

    const {
      classes,
      theme,
      firebaseUser,
      activeRole,
      fullscreen,
      match,
      history,
      companyState,
    } = this.props;

    const { companyId } = match.params;
    const status = company && company.settings.advanced.status;

    if (companyId === undefined || isUnknownCompany) {
      return (
        <AuthMessage
          title={i18n().auth.unknown_company}
          message={i18n().auth.company_not_found}
          buttonLabel={i18n().ui.log_out}
          onButtonClick={this.handleLogOut}
          data-test={
            companyId === undefined
              ? 'MainLayout_NoCompanyId'
              : 'MainLayout_UnknownCompany'
          }
        />
      );
    }
    if (
      status &&
      (status === CompanyStatus.CLOSED || status === CompanyStatus.WAITING)
    ) {
      const authTitle =
        status === CompanyStatus.CLOSED
          ? i18n().auth.company_locked
          : status === CompanyStatus.WAITING
          ? i18n().auth.company_pending
          : i18n().auth.unknown_company;
      const authMessage =
        status === CompanyStatus.CLOSED
          ? i18n().auth.company_is_locked
          : status === CompanyStatus.WAITING
          ? i18n().auth.company_is_pending
          : i18n().auth.company_not_found;

      return (
        <AuthMessage
          title={authTitle}
          message={authMessage}
          buttonLabel={i18n().ui.log_out}
          onButtonClick={this.handleLogOut}
          data-test={
            companyId === undefined
              ? 'MainLayout_NoCompanyId'
              : 'MainLayout_UnknownCompany'
          }
        />
      );
    }
    if (companyState.companyId !== companyId) {
      return <LoadingSpinner data-test="MainLayout_LoadingSpinner" />;
    }

    // Block all non-admins for now.
    // Later on we can implement checks per component if needed.
    if (
      activeRole &&
      activeRole !== UserRole.SYSTEM_ADMIN &&
      activeRole !== UserRole.ADMIN &&
      activeRole !== UserRole.END_CUSTOMER
    ) {
      return (
        <AuthMessage
          title={i18n().auth.unauthorized}
          message={i18n().auth.no_permission_to_use_feature}
          buttonLabel={i18n().ui.log_out}
          onButtonClick={this.handleLogOut}
          data-test="MainLayout_Unauthorized"
        />
      );
    }

    let path = extractName(location.pathname);

    if (path === undefined || path === '/') {
      path = NAVIGATION_PATH.HOME;
    }

    const photoURL = firebaseUser ? firebaseUser.photoURL : undefined;
    const displayName =
      firebaseUser && firebaseUser.displayName !== null
        ? firebaseUser.displayName
        : undefined;

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        style={{ width: 300 }}
      >
        <MenuItem className={classes.menuItem} disabled>
          {i18n().ui.language_select}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          selected={this.state.locale === SupportedLocale.FI}
          onClick={this.handleLanguageChange(SupportedLocale.FI)}
        >
          Suomi
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          selected={this.state.locale === SupportedLocale.EN}
          onClick={this.handleLanguageChange(SupportedLocale.EN)}
        >
          English
        </MenuItem>

        <MenuItem
          className={classes.menuItemLogout}
          onClick={this.handleLogOut}
        >
          {i18n().ui.log_out}
        </MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton color="inherit">
            <Badge
              className={classes.margin}
              badgeContent={11}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>{i18n().ui.notifications}</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            {photoURL ? (
              <Avatar alt={displayName} src={photoURL} />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <p>{i18n().ui.profile}</p>
        </MenuItem>
      </Menu>
    );

    return (
      <FullScreen enabled={fullscreen}>
        <div className={classes.root} data-test="MainLayout_Content">
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift,
            )}
          >
            <Toolbar disableGutters={!this.state.open}>
              <IconButton
                data-testid="open-drawer"
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hide,
                )}
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                color="inherit"
                noWrap={true}
              >
                MOWO{' '}
                {CONFIG.build.environment !== 'production'
                  ? `(${CONFIG.build.environment})`
                  : ''}
              </Typography>

              <div className={classes.grow} />

              <ErrorBoundary>
                <IconButton
                  aria-label={i18n().ui.fullscreen_mode}
                  className={classes.fullScreenButton}
                  onClick={this.toggleFullscreen}
                  style={classes.primaryColor}
                >
                  {(fullscreen && <FullScreenExitIcon />) || <FullScreenIcon />}
                </IconButton>
              </ErrorBoundary>

              <div className={classes.sectionDesktop}>
                <Tooltip title={i18n().ui.feedback_form} placement="left">
                  <IconButton
                    className={classNames(classes.feedbackButton)}
                    href={'https://observis.freshdesk.com/support/tickets/new'}
                    target={'_blank'}
                  >
                    <EmailIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n().ui.user} placement="bottom-start">
                  <IconButton
                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    color="inherit"
                  >
                    {photoURL ? (
                      <Avatar alt={displayName} src={photoURL} />
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-haspopup="true"
                  onClick={this.handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMenu}
          {renderMobileMenu}

          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose,
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbar}>
              <span className={classes.toolbarHeader}>{i18n().ui.menu}</span>
              <IconButton
                data-testid="close-drawer"
                classes={{ label: classes.icon }}
                onClick={this.handleDrawerClose}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            <List defaultValue="/" classes={{ root: classes.list }}>
              {(activeRole === UserRole.SYSTEM_ADMIN ||
                activeRole === UserRole.ADMIN) && (
                <>
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.HOME}
                    icon={<DashboardIcon />}
                    title={i18n().ui.timeline}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.TASKBANK}
                    icon={<TaskBankIcon />}
                    title={i18n().ui.taskbank}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.MAP}
                    icon={<MapIcon color={classes.iconColor} />}
                    title={i18n().ui.map}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.CUSTOMERS}
                    icon={<CustomersIcon />}
                    title={i18n().ui.customers}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                  {companyState.activeCompany.settings.general
                    .enableWorksite && (
                    <DrawerItem
                      currentPath={path}
                      path={NAVIGATION_PATH.CLIENTS}
                      icon={<WorksiteIcon />}
                      title={i18n().ui.worksites}
                      theme={theme}
                      drawerOpen={this.state.open}
                    />
                  )}
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.RESOURCES}
                    icon={
                      <ResourceBadge classes={classes} companyId={companyId} />
                    }
                    title={i18n().ui.members}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                </>
              )}
              {/* Disabled Account Tab to get it back just uncomment these...*/}

              {/* <DrawerItem
                currentPath={path}
                path={NAVIGATION_PATH.ACCOUNT}
                icon={<PersonIcon />}
                title="Account"
                theme={theme}
                drawerOpen={this.state.open}
              /> */}
              {(activeRole === UserRole.SYSTEM_ADMIN ||
                activeRole === UserRole.ADMIN) && (
                <>
                  <DrawerItem
                    currentPath={path}
                    path={NAVIGATION_PATH.SETTINGS}
                    icon={<SettingsIcon />}
                    title={i18n().ui.settings}
                    theme={theme}
                    drawerOpen={this.state.open}
                  />
                </>
              )}
              {activeRole === UserRole.SYSTEM_ADMIN && (
                <DrawerItem
                  currentPath={path}
                  path={NAVIGATION_PATH.BACKUP}
                  icon={<PersonIcon />}
                  title={i18n().ui.backup}
                  theme={theme}
                  drawerOpen={this.state.open}
                />
              )}
              <DrawerItem
                currentPath={path}
                path={NAVIGATION_PATH.REPORTS}
                icon={<Description />}
                title={i18n().ui.reports}
                theme={theme}
                drawerOpen={this.state.open}
              />
              {(activeRole === UserRole.SYSTEM_ADMIN ||
                activeRole === UserRole.ADMIN) && (
                <DrawerItem
                  currentPath={path}
                  path={NAVIGATION_PATH.CHAT}
                  icon={<Chat />}
                  title={i18n().ui.chat}
                  theme={theme}
                  drawerOpen={this.state.open}
                />
              )}
            </List>
          </Drawer>
          <main
            className={classNames(
              classes.content,
              this.state.open && classes.contentDrawerOpen,
            )}
          >
            <ErrorBoundary>
              <Switch>
                {/* {this.renderRoutes(match)} */}
                <Route
                  path={extractPath(match, NAVIGATION_PATH.ACCOUNT)}
                  render={() => <Account />}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.CLIENTS)}
                  render={() => <Venues companyId={companyId} />}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.MAP)}
                  render={() => <Map companyId={companyId} />}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.CUSTOMERS)}
                  render={() => (
                    <Customers companyId={companyId} history={history} />
                  )}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.RESOURCES)}
                  render={() => (
                    <Resources companyId={companyId} history={history} />
                  )}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.SETTINGS)}
                  render={() => <Settings companyId={companyId} />}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.BACKUP)}
                  render={() => <div>Backup</div>}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.HOME)}
                  render={() => (
                    <TaskContainer
                      companyId={companyId}
                      viewState={ViewState.TIMELINE}
                    />
                  )}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.TASKBANK)}
                  render={() => (
                    <TaskContainer
                      companyId={companyId}
                      viewState={ViewState.TASKBANK}
                    />
                  )}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.REPORTS)}
                  render={() => <ReportsContainer companyId={companyId} />}
                />
                <Route
                  path={extractPath(match, NAVIGATION_PATH.CHAT)}
                  render={() => (
                    <ChatGroupContainer
                      companyId={companyId}
                      firebaseUid={firebaseUser ? firebaseUser.uid : ''}
                    />
                  )}
                />
              </Switch>
            </ErrorBoundary>
          </main>
        </div>
      </FullScreen>
    );
  }
  public toggleFullscreen = () => {
    const fullscreen = this.props.fullscreen || false;
    this.props.dispatch(setFullscreenMode(!fullscreen));
  };

  // private renderRoutes = (match: match<any>) => {
  //   const defaultRouteIndex: number = NAVIGATION.findIndex(
  //     item => item !== null && item.default === true
  //   );

  //   let defaultRouteItem: NavigationItem | undefined;

  //   const navi = NAVIGATION.slice();
  //   if (defaultRouteIndex >= 0 && NAVIGATION[defaultRouteIndex] !== null) {
  //     defaultRouteItem = NAVIGATION[defaultRouteIndex] as NavigationItem;
  //     navi.splice(defaultRouteIndex, 1);
  //   }

  //   const routes: any[] = navi.map(item => {
  //     if (item === null) {
  //       return <Divider />;
  //     }
  //     return (
  //       <Route
  //         path={extractPath(match, item.path)}
  //         component={() => item.component}
  //       />
  //     );
  //   });

  //   if (defaultRouteItem) {
  //     routes.push(<Route component={() => defaultRouteItem!.component} />);
  //   }
  //   return routes;
  // };
  private handleLanguageChange = (locale: SupportedLocale) => () => {
    window.location.reload();
    setLocale(locale);
    this.setState({ locale });
    localStorage.setItem('locale', locale);
  };

  private handleLogOut = async () => {
    localStorage.removeItem('isLogging');
    localStorage.removeItem('signInMethodGoogle');
    firebaseApp.auth().signOut();
    this.props.dispatch({ type: 'USER_LOGOUT' });
    this.handleMenuClose();
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<MainLayoutProps>,
) => {
  return {
    ...ownProps,
    firebaseUser: state.auth.firebaseUser,
    appUser: state.auth.appUser,
    activeRole: state.auth.activeRole,
    fullscreen: state.navi.fullScreen,
    companyState: state.company,
  };
};

export default withRouter<any>(
  withStyles(styles, { withTheme: true })(
    connect<any>(mapStateToProps)(MainLayout as any),
  ),
);
