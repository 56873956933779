import { Button, List, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';

interface CustomSelectProps {
  title: string;
  open: boolean;
  handleOpen: (element: EventTarget) => void;
  handleClose: () => void;
  anchorEl?: HTMLElement | undefined;
}

interface CustomSelectState {
  open: boolean;
}

export class CustomSelect extends React.Component<
  CustomSelectProps,
  CustomSelectState
> {
  public state: CustomSelectState = {
    open: false,
  };

  public render() {
    const { title, open, handleOpen, handleClose, anchorEl } = this.props;

    return (
      <div>
        <Button
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
            handleOpen(event.target)
          }
        >
          {title}
          <ArrowDropDownIcon />
        </Button>
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          style={{ maxHeight: '65%' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>{this.props.children}</List>
        </Popover>
      </div>
    );
  }
}
