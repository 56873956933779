import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  shiftEditorDialog: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 'auto',
    width: 'fit-content',
  },
  paper: {
    minWidth: '550px',
    maxHeight: '95%',
  },
  dialogContent: {
    overflowY: 'hidden',
    width: 600,
    padding: '0 24px 12px 24px',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  datePicker: {
    marginLeft: '8px',
  },
  repetitionEditor: {
    minHeight: '79px',
    display: 'flex',
  },
  dialogFooter: {
    backgroundColor: theme.colors.grey,
    margin: 0,
    padding: '8px 4px',
  },
  tooltip: {
    fontSize: 13,
  },
  repetitionCountErrorMessage: {
    fontWeight: 1000,
    fontStyle: 'italic',
    color: theme.priorities.high.color,
    paddingLeft: '45px',
    marginTop: '3px',
    fontSize: '12px',
  },
  repetitionDurationError: {
    fontWeight: 1000,
    fontStyle: 'italic',
    color: theme.priorities.high.color,
    paddingLeft: '45px',
    marginTop: '3px',
    fontSize: '12px',
  },
  saveButton: {
    color: '#34AD00',
    '&:hover': {
      backgroundColor: 'rgba(153, 202, 60, 0.33)',
    }
  },
});

export default styles;
