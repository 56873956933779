import { ImportTasksDialogState } from '@shared/schema';
import { Reducer } from 'redux';
import { ImportTasksAction, ImportTasksActionType } from './importTasksAction';

export interface ImportTasksState {
  importTasksDialogState: ImportTasksDialogState;
}

export const defaultImportTasksState: ImportTasksState = {
  importTasksDialogState: ImportTasksDialogState.CLOSED,
};

export const importTasksReducer: Reducer<ImportTasksState> = (
  state = defaultImportTasksState,
  action: ImportTasksAction,
): ImportTasksState => {
  switch (action.type) {
    case ImportTasksActionType.IMPORT_TASKS_NEW:
      return {
        ...state,
        importTasksDialogState: ImportTasksDialogState.OPEN,
      };
    case ImportTasksActionType.IMPORT_TASKS_CANCEL:
      return {
        ...defaultImportTasksState,
      };
    default:
      return state;
  }
};
