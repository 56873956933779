import { StyleRules } from '@material-ui/core/styles';

export const styles = (): StyleRules<string> => ({
  bar: {
    display: 'flex',
    height: '72px',
    backgroundColor: '#E5E5E5',
    justifyContent: 'space-between',
  },
  filters: { display: 'flex' },
  buttons: { display: 'flex', marginRight: '20px' },
  button: {
    minWidth: '72px',
  },

  filterUsersButton: {
    minWidth: '72px',
    marginLeft: '20px',
  },
  filterDateRangeButton: {
    width: '90px',
  },

  filterPrioritiesButton: {
    minWidth: '72px',
    marginLeft: '20px',
  },

  todayButton: {
    minWidth: '72px',
    margin: '0px 5px 0px 5px',
  },

  addNewButton: {
    marginLeft: '10px',
    height: '65%',
    margin: 'auto 0px',
    backgroundColor: '#99ca3c',
    minWidth: '120px',
    borderColor: 'rgba(0,0,255,0.25)',
    boxShadow: '3px 3px 8px grey',
    '&:active': { boxShadow: 'none' },
  },

  popover: {
    pointerEvents: 'none',
  },
  popperClose: {
    pointerEvents: 'none',
  },

  group: {
    marginLeft: '27px',
  },
  datePicker: {
    width: 300,
  },
  selected: {
    background: 'rgba(80, 80, 80, 0.20)',
  },
  icon: {
    marginRight: '5px',
  },
  weeklyContainer: {
    flexDirection: 'column',
    marginTop: '5px',
  },
  weekNumberText: {
    margin: 0,
  },
  weekDaysText: {
    margin: 0,
  },
  calendarButtonWeek: {
    width: '115px',
  },
  calendarButtonDay: {
    width: '250px',
  },
  dateRangeContainer: {
    marginLeft: '10px',
  },
  dateRangeRadioButton: {
    height: '24px',
  },
});
