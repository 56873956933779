import { Grid, WithTheme } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { MemberWithKey, Settings, TaskWithKey } from '@shared/schema/index';
import * as React from 'react';
import ChatTaskListItem from './components/ChatTaskListItem/ChatTaskListItem';
import styles from './styles';

// tslint:disable-next-line: no-var-requires
const defaultUserImageUrl = require('../../../../../../src/assets/images/ProfileImage.png');

export interface ChatTaskListContainerProps
  extends StyledComponentProps,
    WithTheme {
  companyId: string;
  firebaseUid: string;
  chatTasks: TaskWithKey[];
  chatMembers: MemberWithKey[];
  openTaskId: (selectedTaskId: string) => void;
  settings: Settings;
}

export interface State {
  openTask: string;
}

export class ChatTaskListContainer extends React.Component<
  ChatTaskListContainerProps,
  State
> {
  constructor(props: ChatTaskListContainerProps) {
    super(props);
    this.state = {
      openTask: '',
    };
  }

  public render() {
    const { classes = {} } = this.props;
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.listContainer}
        data-test="chatTaskListContainerGrid"
      >
        <Grid item className={classes.taskGridItem}>
          {this.returnChatTaskListItems()}
        </Grid>
      </Grid>
    );
  }

  /**
   * Calls function from props to pass the open task key to paren component.
   * @param {boolean} isExpanded
   * @param {string} newItemKey
   */
  private handleOpenTask = (isExpanded: boolean, newItemKey: string) => {
    if (isExpanded) {
      this.setState({
        openTask: newItemKey,
      });
      this.props.openTaskId(newItemKey);
    }
  };

  /**
   * Returns member according to memberId which is given in params
   * @param {string} memberId
   */
  private returnCorrectMember = (memberId: string) => {
    const { chatMembers } = this.props;

    const correctMember = chatMembers.find(member => member.key === memberId);

    return correctMember;
  };

  /**
   * Returns every task as list item
   */
  private returnChatTaskListItems = () => {
    const { openTask } = this.state;
    const { chatTasks, firebaseUid, companyId, settings } = this.props;

    const items: any[] = [];

    chatTasks.forEach((task, index) => {
      const memberToUse = task.userId
        ? this.returnCorrectMember(task.userId)
        : undefined;
      const avatarURL =
        memberToUse && memberToUse.photoURL
          ? memberToUse.photoURL
          : defaultUserImageUrl;

      const taskKey = task.key ? task.key : '';

      items.push(
        <ChatTaskListItem
          key={taskKey}
          open={taskKey === openTask}
          openTaskFunction={this.handleOpenTask}
          avatarURL={avatarURL}
          userId={firebaseUid}
          task={task}
          companyId={companyId}
          settings={settings}
          data-test={`taskListItem${index}`}
        />,
      );
    });

    return items;
  };
}

export default withStyles(styles, { withTheme: true })(ChatTaskListContainer);
