import { Reducer } from 'redux';

import { CompanyId, Customer } from '@shared/schema';
import { CustomerAction, CustomerActionType } from './customerActions';

export interface CustomerState {
  editing: boolean;
  companyId?: CompanyId;

  editedCustomer?: Customer | undefined;
}

const initialState: CustomerState = {
  editing: false,
  editedCustomer: undefined,
};

export const customerReducer: Reducer<CustomerState> = (
  state = initialState,
  action: CustomerAction,
) => {
  switch (action.type) {
    case CustomerActionType.CUSTOMERS_NEW: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedCustomer: undefined,
      };
    }

    case CustomerActionType.CUSTOMERS_CANCEL: {
      return {
        ...state,
        editing: false,
        editedCustomer: undefined,
      };
    }

    case CustomerActionType.CUSTOMERS_UPDATE: {
      return {
        ...state,
        editing: true,
        companyId: action.payload.companyId,
        editedCustomer: {
          ...action.payload.customer,
        },
      };
    }

    default:
      return state;
  }
};
