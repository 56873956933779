import {
  Snackbar,
  SnackbarContent,
  StyledComponentProps,
  withStyles,
  WithTheme,
} from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { i18n } from '@shared/locale';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { styles } from './styles';

export interface NoTasksReportNotificationProps
  extends Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  isVisible: boolean;
  action: React.ReactElement;
  textOverride?: string;
}

class NoTasksReportNotification extends React.Component<NoTasksReportNotificationProps> {
  public render() {
    const { classes = {}, textOverride } = this.props;

    return (
      <Snackbar
        open={this.props.isVisible}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className={classes.snackbar}
        data-test="snackbar"
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <div className={classes.messageContainer}>
              <InfoRounded className={classes.infoIcon} />
              <b>
                {textOverride
                  ? textOverride
                  : i18n().ui.no_tasks_within_timeframe}
              </b>
            </div>
          }
          action={this.props.action}
          data-test="snackbarContent"
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  NoTasksReportNotification,
);
