import { Reducer } from "redux";

import { NAVIGATION_PATH } from "paths";
import { NaviAction, NaviActionType } from "./naviActions";

export interface NaviState {
  /**
   * True if app's drawer is open.
   */
  readonly drawerOpen: boolean;

  /**
   * True if drawer is shown in minified mode with only item icons. Impact's only on wide screen.
   */
  readonly minified: boolean;

  /**
   * True if full screen mode is active
   */
  readonly fullScreen: boolean;

  readonly currentPath: NAVIGATION_PATH;

  /**
   * List of navi buttons attached to current view
   */
  readonly naviButtons: () => JSX.Element[];

  /**
   * Change trigger navibar changes
   */
  readonly change: number;
}

const emptyNavi = (): JSX.Element[] => {
  return [];
};

const defaultState: NaviState = {
  drawerOpen: false,
  minified: false,
  currentPath: NAVIGATION_PATH.HOME,
  naviButtons: emptyNavi,
  change: 0,
  fullScreen: false
};

export const naviReducer: Reducer<NaviState> = (
  state = defaultState,
  action: NaviAction
) => {
  switch (action.type) {
    case NaviActionType.NAVI_DRAWER_OPEN_STATE_CHANGED: {
      return {
        ...state,
        drawerOpen: action.payload
      };
    }

    case NaviActionType.NAVI_DRAWER_MINIFY_STATE_CHANGED: {
      return {
        ...state,
        minified: action.payload
      };
    }

    case NaviActionType.NAVI_PATH_STATE_CHANGED: {
      return {
        ...state,
        currentPath: action.payload
      };
    }

    case NaviActionType.NAVI_SET_NAVI_BUTTONS_FUN: {
      return {
        ...state,
        naviButtons: action.payload
      };
    }

    case NaviActionType.NAVI_BUTTON_STAGE_CHANGE: {
      const change = state.change < 1000000 ? state.change + 1 : 0;
      return {
        ...state,
        change
      };
    }

    case NaviActionType.NAVI_FULLSCREEN: {
      return {
        ...state,
        fullScreen: action.payload
      };
    }
    default:
      return state;
  }
};
