import withStyles, { StyleRules } from '@material-ui/core/styles/withStyles';
import {
  JobType,
  JobTypeWithKey,
  Member,
  MemberJobTypes,
  MemberQualifications,
  MemberWithKey,
  Qualification,
  QualificationWithKey,
  Schema,
  UserRole,
} from '@shared/schema';
import LoadingSpinner from 'components/LoadingSpinner';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { editResource, newResource } from 'reducers/resources/resourcesActions';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';

import {
  Button,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import HeaderBar from 'components/HeaderBar';
import SortingHeadTableCell from 'components/SortingHeadTableCell/SortingHeadTableCell';
import { CompanyParams } from 'containers/Private/MainLayout';
import tableLayout from '../../../../theme/styles/table';
import CustomTableCell from '../../../../theme/styles/tableCell';
import Editor from '../components/Editor';

export interface ResourcesProps
  extends Partial<RouteComponentProps<CompanyParams>>,
    DispatchProp<any> {
  editing?: boolean;
  companyId: string;
  classes?: any;
}

interface State {
  isLoading: boolean;
  resources: MemberWithKey[];
  tasks: JobTypeWithKey[];
  qualifications: QualificationWithKey[];
  resource?: Member;
  page: number;
  rowsPerPage: number;
  filter: string;
  order: OrderOptions;
  orderBy: OrderByValues;
}

export enum OrderByValues {
  memberName = 'memberName',
  memeberRole = 'memberRole',
  memberEmail = 'memberEmail',
}

export enum OrderOptions {
  asc = 'asc',
  desc = 'desc',
}

export class Resources extends React.PureComponent<ResourcesProps, State> {
  public state: State = {
    isLoading: true,
    resources: [],
    tasks: [],
    qualifications: [],
    page: 0,
    rowsPerPage: 50,
    filter: '',
    order: OrderOptions.asc,
    orderBy: OrderByValues.memberName,
  };
  private companyRef: firebase.firestore.DocumentReference;

  private unsubscribeResources: () => void;

  private unsubscribeTasks: () => void;

  private unsubscribeQualifications: () => void;

  public componentDidMount() {
    this.companyRef = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(this.props.companyId);

    this.unsubscribeResources = this.companyRef
      .collection(Schema.MEMBERS)
      .orderBy('name')
      .onSnapshot(snapShot => {
        const resources: Member[] = collectionToJsonWithIds(snapShot);
        this.setState({
          isLoading: !this.state.resources,
          resource: undefined,
          resources,
        });
      });

    this.unsubscribeTasks = this.companyRef
      .collection(Schema.JOBTYPES)
      .orderBy('name')
      .onSnapshot(snapShot => {
        const tasks: JobType[] = collectionToJsonWithIds(snapShot);
        this.setState({
          isLoading: !this.state.resources,
          tasks,
        });
      });

    this.unsubscribeQualifications = this.companyRef
      .collection(Schema.QUALIFICATIONS)
      .orderBy('name')
      .onSnapshot(snapShot => {
        const qualifications: Qualification[] = collectionToJsonWithIds(
          snapShot,
        );
        this.setState({
          isLoading: !this.state.resources,
          qualifications,
        });
      });
  }

  public componentWillUnmount() {
    this.unsubscribeResources && this.unsubscribeResources();
    this.unsubscribeTasks && this.unsubscribeTasks();
    this.unsubscribeQualifications && this.unsubscribeQualifications();
  }

  public render() {
    const { classes = {} } = this.props;
    const { resources, filter, page, rowsPerPage } = this.state;

    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <HeaderBar
          searchOnChangeHandler={this.searchOnChangeHandler}
          searchComponentPlaceholder={i18n().ui.search_members}
        >
          <Button
            className={classes.addNewButton}
            onClick={this.handleFabButtonClick}
          >
            {i18n().ui.add_new}
          </Button>
        </HeaderBar>
        <div className={classes.container}>
          <div className={classes.tableBody}>
            <Table>
              <TableHead>
                <TableRow classes={{ root: classes.tableHead }}>
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellMemberName"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.memberName}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.name}
                  />
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellMemberRole"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.memeberRole}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.role}
                  />
                  <SortingHeadTableCell
                    data-test="sortingHeadTableCellMemberEmail"
                    selectedOrderBy={this.state.orderBy}
                    orderBy={OrderByValues.memberEmail}
                    order={this.state.order}
                    handleSortClick={this.handleTableSortChange}
                    title={i18n().ui.email}
                  />
                  <CustomTableCell className={classes.tableCellHeader}>
                    {i18n().ui.phone}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableCellHeader}>
                    {i18n().ui.proficiencies}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableCellHeader}>
                    {i18n().ui.qualifications}
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources &&
                  resources
                    .filter(
                      item =>
                        item.name.toLowerCase().indexOf(filter.toLowerCase()) >
                        -1,
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        className={classes.row}
                        key={row.key}
                        hover={true}
                        onClick={this.rowOnClickHandler(row)}
                      >
                        <CustomTableCell className={classes.tableCell}>
                          {row.name}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {this.getRole(row.role)}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {row.email}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {row.phone}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {this.getJobtypes(row.jobtypes)}
                        </CustomTableCell>
                        <CustomTableCell className={classes.tableCell}>
                          {this.getQualifications(row.qualifications)}
                        </CustomTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>

          <div className={classes.tableFooter}>
            <TablePagination
              classes={{
                toolbar: classes.tablePagination,
                select: classes.paginationSelect,
              }}
              component="div"
              count={resources.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage={i18n().ui.rows_per_page}
            />
          </div>

          <Editor
            editing={this.props.editing}
            tasks={this.state.tasks}
            qualifications={this.state.qualifications}
            resource={this.state.resource}
            companyId={this.props.companyId}
          />
        </div>
      </>
    );
  }

  private handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    this.setState({ page });
  };

  private handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.setState({ rowsPerPage: Number(e.target.value) });
  };

  private rowOnClickHandler = (member: Member) => (
    e: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const { companyId, dispatch } = this.props;
    const { resources } = this.state;

    dispatch && dispatch(editResource(companyId, member, resources));
  };

  private handleFabButtonClick = () => {
    const { companyId, dispatch } = this.props;
    dispatch &&
      dispatch(newResource(companyId, undefined, this.state.resources));
  };

  private searchOnChangeHandler = (filter: string) => {
    this.setState({ filter });
  };

  /**
   * Get member job types
   */
  private getJobtypes = (jobtypes: MemberJobTypes) => {
    if (jobtypes) {
      const keys = Object.keys(jobtypes);
      let tasks = '';

      for (const key of keys) {
        const task = this.state.tasks.find(d => d.key === key);
        if (task) {
          if (tasks.length > 0) {
            tasks += ', ';
          }
          let name = task.name;
          name = name.replace('siivotaan', '').trim();
          tasks += name;
        }
      }
      return <span>{tasks}</span>;
    }
    return undefined;
  };

  /**
   * Get user qualifications
   */
  private getQualifications = (memberQualifications: MemberQualifications) => {
    if (memberQualifications) {
      const { qualifications } = this.state;

      const keys = Object.keys(memberQualifications);
      let tasks = '';

      for (const key of keys) {
        const qualification = qualifications.find(d => d.key === key);
        if (qualification) {
          if (tasks.length > 0) {
            tasks += ', ';
          }
          const name = qualification.name;
          tasks += name;
        }
      }
      return tasks;
    }
    return undefined;
  };

  /**
   * Get role of member
   */
  private getRole = (role: string) => {
    switch (role) {
      case UserRole.ADMIN:
        return i18n().ui.role_admin;
      case UserRole.USER:
        return i18n().ui.role_user;
      case UserRole.PENDING:
        return i18n().ui.role_awaiting;
      case UserRole.CLOSED:
        return i18n().ui.role_closed;

      default:
        return role;
    }
  };

  private sortMemberList = () => {
    let memberListSorted = [...this.state.resources];
    const { order, orderBy } = this.state;
    switch (orderBy) {
      case OrderByValues.memberName: {
        memberListSorted = this.memberNameSort(memberListSorted);
        break;
      }
      case OrderByValues.memeberRole: {
        memberListSorted = this.memberRoleSort(memberListSorted);
        break;
      }
      case OrderByValues.memberEmail: {
        memberListSorted = this.memberEmailSort(memberListSorted);
        break;
      }
      default:
        return;
    }
    if (order === OrderOptions.desc) {
      memberListSorted.reverse();
    }
    this.setState({
      resources: memberListSorted,
    });
  };

  /** Handle table order change
   * Invoked everytime when user click to orderable table title cell.
   * If the same cell cliked, then just change the "order", if another cell clicked, then set the "order" to default 'asc' and set new "orderBy" value
   * At the end, calls sortMemberList()
   *
   * @param {OrderByValues} orderBy
   */
  private handleTableSortChange = (orderBy: OrderByValues) => {
    if (this.state.orderBy === orderBy) {
      this.setState(
        {
          order: this.getOppositeOrderOption(),
        },
        () => {
          this.sortMemberList();
        },
      );
    } else {
      this.setState(
        {
          order: OrderOptions.asc,
          orderBy,
        },
        () => {
          this.sortMemberList();
        },
      );
    }
  };

  /**
   * Returns the opposite value of "order" variable from state
   */
  private getOppositeOrderOption = () => {
    const order = this.state.order;
    if (order === OrderOptions.asc) {
      return OrderOptions.desc;
    } else {
      return OrderOptions.asc;
    }
  };

  /**
   * Sort provided member array by member name
   *
   * @param {Member[]} members
   */
  private memberNameSort = (members: Member[]) => {
    members.sort((member1, member2) =>
      this.memberDefaultSort(member1, member2),
    );
    return members;
  };

  /**
   * Sort provided member array by member role
   *
   * @param {Member[]} members
   */
  private memberRoleSort = (members: Member[]) => {
    members.sort((member1, member2) => {
      const member1Role = this.getRole(member1.role).trim();
      const member2Role = this.getRole(member2.role).trim();
      const result = member1Role.localeCompare(member2Role);
      return result === 0 ? this.memberDefaultSort(member1, member2) : result;
    });
    return members;
  };

  /**
   * Sort provided member array by member email
   *
   * @param {Member[]} members
   */
  private memberEmailSort = (members: Member[]) => {
    members.sort((member1, member2) => {
      const member1Email = member1.email;
      const member2Email = member2.email;
      const result = member1Email.localeCompare(member2Email);
      return result === 0 ? this.memberDefaultSort(member1, member2) : result;
    });
    return members;
  };

  /**
   * Default sorting method that sorts two members according to their names
   *
   * @param {Member} member1
   * @param {Member} member2
   */
  private memberDefaultSort = (member1: Member, member2: Member) => {
    const member1Name = member1.name.trim();
    const member2Name = member2.name.trim();
    return member1Name.localeCompare(member2Name);
  };
}

const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    margin: '20px',
    marginTop: '35px',
    height: '100%',
  },
  addNewButton: {
    marginLeft: '10px',
    height: '65%',
    margin: 'auto 0px',
    backgroundColor: '#99CA3C',
    minWidth: '120px',
    border: '1px solid',
    borderColor: 'rgba(0,0,255,0.25)',
    textTransform: 'none',
  },
  closed: {
    color: 'rgba(192,192,192,0.6)',
  },
  pending: {
    color: 'red',
  },
  sortLabel: {
    color: 'white',
    '&:hover': { color: 'white' },
    '&:focus': { color: 'white' },
    '& svg': {
      color: 'white',
      opacity: 0.4,
    },
  },
  sortableCell: {
    cursor: 'pointer',
  },
  ...{
    ...tableLayout(theme),
    tableCell: {
      width: 'calc(100%/7)',
      border: '0.25px solid #d0d0d0',
    },
  },
});

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ResourcesProps>,
) => {
  return {
    ...ownProps,
    editedMember: state.resources.editedMember,
    editing: state.resources.editing,
  };
};

export default withRouter<any>(
  withStyles(styles, { withTheme: true })(
    connect<any>(mapStateToProps)(Resources as any),
  ),
);
