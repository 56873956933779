import {
  Button,
  Dialog,
  StyledComponentProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core/';
import { i18n } from '@shared/locale';
import { Change, ChangeLog, ChangeType } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment/index';
import { DurationFormatPreset } from '@shared/utils/moment/preset';
import moment from 'moment';
import * as React from 'react';
import CustomTableCell from 'theme/styles/tableCell';
import styles from './../MemberWorkTimeTable/styles';

export interface ChangeLogProps extends StyledComponentProps {
  changeLog: ChangeLog[];
  open: boolean;
  closeChangeLog: (open: boolean) => void;
}

interface State {
  isDialogOpen: boolean;
}

export class ChangeLogDialog extends React.Component<ChangeLogProps, State> {
  constructor(props: ChangeLogProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  public componentWillReceiveProps(nextProps: Readonly<ChangeLogProps>) {
    if (nextProps.open === true) {
      this.setState({
        isDialogOpen: true,
      });
    } else {
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  public render() {
    const { classes = {}, changeLog } = this.props;
    const { isDialogOpen } = this.state;

    return (
      <Dialog
        open={isDialogOpen}
        classes={{
          paper: classes.changeLogPaper,
          container: classes.changeLogContainer,
        }}
      >
        <h1 className={classes.changeLogHeadline}>{i18n().ui.changelog}</h1>
        <div className={classes.changeLogTableWrapper}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.time}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().concepts.member}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.change}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody data-test="tableBody">
              {changeLog &&
                changeLog.map((log: ChangeLog) => (
                  <TableRow className={classes.row}>
                    <CustomTableCell
                      className={classes.tableCell}
                      data-test="timestamp"
                    >
                      {moment(log.timestamp).format('DD.MM.YYYY HH:mm')}
                    </CustomTableCell>
                    <CustomTableCell
                      className={classes.tableCell}
                      data-test="memberName"
                    >
                      {log.memberName}
                    </CustomTableCell>
                    <CustomTableCell
                      className={classes.tableCell}
                      data-test="changes"
                    >
                      {log &&
                        log.changes.map(change => this.displayChanges(change))}
                    </CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <Button
          classes={{
            root: classes.changeLogButton,
          }}
          onClick={this.dialogClose}
        >
          {i18n().ui.close}
        </Button>
      </Dialog>
    );
  }

  /**
   * Closes dialog, passes boolean value to WorkTimeTableMemberRow.tsx
   */
  private dialogClose = () => {
    this.setState({ isDialogOpen: false });
    this.props.closeChangeLog(false);
  };

  /**
   * Displays changelog data row by row
   * @param change One change inside changes array
   */
  private displayChanges = (change: Change) => {
    const { classes = {} } = this.props;
    let row: any;

    if (change.changeType === ChangeType.TIME || !change.changeType) {
      row = (
        <div className={classes.changeLogChange}>
          {change.hourtypeName +
            ': ' +
            momentDurationFormat(change.oldValue, DurationFormatPreset.HHMM) +
            ' \u2192 ' +
            momentDurationFormat(change.newValue, DurationFormatPreset.HHMM)}
        </div>
      );
    }
    if (change.changeType === ChangeType.HOURTYPE) {
      row = (
        <div className={classes.changeLogChange}>
          {change.oldValue + ' \u2192 ' + change.newValue}
        </div>
      );
    }
    if (
      (change.changeType === ChangeType.TIME && change.newValue === 0) ||
      (!change.changeType && change.newValue === 0)
    ) {
      row = (
        <div className={classes.changeLogChange}>
          {change.hourtypeName + ': ' + i18n().ui.removed}
        </div>
      );
    }
    return row;
  };
}

export default withStyles(styles, { withTheme: true })(ChangeLogDialog);
