import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
