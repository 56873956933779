import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    height: 'calc(100% - 56px)',
  },
  toolbar: {
    padding: '1.0rem',
  },
  timelineContainer: {
    height: '100%',
  },
  timeLine: {
    height: 'calc(100% - 86px)',
    overflowY: 'hidden',
    marginLeft: '20px',
    marginTop: '20px',
    marginRight: '20px',

    '& .vis-timeline': {
      '& .vis-panel.vis-top': {
        padding: '6px 20px 20px 20px',
      },
      '& .vis-time-axis': {
        '& .vis-text': {
          'font-size': '12px',
        },
        '& .vis-text.vis-major': {
          'padding-bottom': '20px',
        },
      },
      '& .vis-group': {
        '& .vis-item': {
          height: '80px',
          border: '0px',
          '& .vis-item-content': {
            height: '80px',
            padding: '0px',

            '& .timeline-item': {
              height: '100%',
              padding: '10px',
              'border-radius': '3px',
              '& .content': {
                overflow: 'hidden',
              },
            },
          },
        },
      },
    },
    '& .vis-labelset': {
      width: '190px',
      '& .vis-label': {
        'background-color': theme.palette.primary.main,
        color: '#fff',
        'min-height': '80px',
        '& .vis-inner': {
          width: '100%',
          'min-height': '80px',

          '& .avatar': {
            top: '25px',
            left: '10px',
            width: '30px',
            height: '30px',
            background: theme.timeline.labelset.avatar.background,
            position: 'absolute',
            'border-radius': '50%',
          },
          '& .name': {
            'line-height': '70px',
            'padding-left': '45px',
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },
  },
  done: {
    backgroundColor: 'green',
    color: 'black',
  },
  active: {
    backgroundColor: 'orange',
    color: 'white',
  },
  undone: {
    backgroundColor: 'grey',
    color: 'white',
  },
  late: {
    backgroundColor: 'red',
    color: 'white',
  },
  displayNone: {
    display: 'none',
  },
});
