import { WorkingHoursWithKey } from '../index';
export enum ReportType {
  ALL = 'ALL',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface WorkTimeReports {
  [userId: string]: UserWorkTimeReport;
}

export type UserWorkTimeReport = { [key in ReportType]: WorkingHoursWithKey[] };
