import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { DialogStatus } from '@shared/schema';
import * as React from 'react';
import { i18n } from '../../../../@shared/locale/index';

interface DeleteDialogProps {
  dialogTitle: string;
  dialogStatus?: DialogStatus;
  dialogContent?: string;
  handleDelete: (event: React.MouseEvent) => void;
  handleCloseDialog: (event: React.MouseEvent) => void;
}

export default class DeleteDialog extends React.Component<DeleteDialogProps> {
  public render() {
    const {
      dialogTitle,
      handleDelete,
      handleCloseDialog,
      dialogContent,
    } = this.props;

    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        {dialogContent && <DialogContent>{dialogContent}</DialogContent>}

        <DialogActions>
          <Button onClick={handleCloseDialog}>{i18n().ui.cancel}</Button>
          <Button onClick={handleDelete} color="secondary">
            {i18n().ui.delete}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
