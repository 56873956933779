import { Avatar } from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import styles from '../styles';
const profileImage = 'assets/images/ProfileImage.png';

export interface UserSettingsHeaderProps extends StyledComponentProps {
  userName: string | null;
  userImage: string | null;
}

export class UserSettingsHeader extends React.Component<
  UserSettingsHeaderProps
> {
  constructor(props: UserSettingsHeaderProps) {
    super(props);
  }

  public render() {
    const { classes = {}, userName, userImage } = this.props;
    return (
      <div className={classes.userSettingsHeader}>
        <Avatar
          alt={userName ? userName : ''}
          src={userImage ? userImage : profileImage}
          className={classes.avatar}
          data-test="userAvatar"
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UserSettingsHeader);
