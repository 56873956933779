import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.colors.white,
  },
  tabsBar: {
    background: theme.colors.grey,
  },
});

export default styles;
