import moment from 'moment';
import { Reducer } from 'redux';

import {
  ChatIconState,
  CompanyId,
  EditorState,
  getPriorityMap,
  TaskDialogState,
  TaskStatus,
  TaskWithKey,
  UserId,
} from '@shared/schema';
import { EventAction, TaskActionType } from './eventActions';

/**
 * Rename to `TaskEditorState`
 */
export interface EventState {
  taskDialogState: TaskDialogState;
  taskChatIconState: ChatIconState;
  editing: boolean;
  startDateTime?: moment.Moment;
  editorState: EditorState;
  userId?: UserId;
  companyId?: CompanyId;
  deleteDialogOpen: boolean;
  deleteTask?: TaskWithKey;
  /**
   * TODO rename to `deleteTaskId`
   */
  deleteEventId: any;
  /**
   * TODO rename to `deleteTaskTitle`
   */
  deleteEventTitle: string;
  /**
   * If editing, this will have an resoure that is been edited. In new resource mode this is always null
   */
  editedResource: TaskWithKey;
  editedTaskBeforeEditing: TaskWithKey;
}

/**
 * This is empty Task for the initialization
 */
const emptyTask: TaskWithKey = {
  id: '',
  status: TaskStatus.UNDONE,
  priority: getPriorityMap()[1],
  userId: '',
  userName: '',
  customerId: '',
  customerName: '',
  desc: '',
  jobtypeId: '',
  jobtypeName: '',
  start: 0,
  end: 0,
  duration: 0,
  startDate: '',
  startTime: '',
  durationText: '',
  title: {
    userModified: false,
    value: '',
  },
  dateOnly: true,
};

/**
 * TODO rename to `defaultTaskEditorState`
 */
export const defaultEventState: EventState = {
  editing: false,
  editedResource: emptyTask,
  editedTaskBeforeEditing: emptyTask,
  editorState: EditorState.CLOSED,
  deleteDialogOpen: false,
  deleteTask: undefined,
  deleteEventTitle: '',
  deleteEventId: null,
  taskDialogState: TaskDialogState.CLOSED,
  taskChatIconState: ChatIconState.UNDEFINED,
  userId: '',
};

/**
 * TODO rename to `taskEditorReducer`
 */
export const eventReducer: Reducer<EventState> = (
  state = defaultEventState,
  action: EventAction,
): EventState => {
  switch (action.type) {
    case TaskActionType.TASK_NEW:
      return {
        ...state,
        editorState: EditorState.NEW,
        // startDate: action.payload.startDate,
        companyId: action.payload.companyId,
        userId: action.payload.userId,
        startDateTime: action.payload.startDateTime,
        editing: true,
        taskDialogState: TaskDialogState.TASK,
      };

    case TaskActionType.TASK_EDIT:
      return {
        ...state,
        editorState: EditorState.EDIT,
        companyId: action.payload.companyId,
        editedTaskBeforeEditing: {
          ...action.payload.resourceEvent,
        },
        editedResource: {
          ...action.payload.resourceEvent,
        },
        taskDialogState: TaskDialogState.TASK,
      };

    case TaskActionType.TASK_DELETE_REQUEST:
      return {
        ...state,
        deleteDialogOpen: true,
        deleteTask: action.payload.deleteTask,
        deleteEventId: action.payload.deleteEventId,
        deleteEventTitle: action.payload.deleteEventTitle,
      };

    case TaskActionType.TASK_CANCEL_DELETE:
    case TaskActionType.TASK_DELETE:
      return {
        ...state,
        deleteDialogOpen: false,
        // deleteItem: null,
      };

    case TaskActionType.TASK_CANCEL_EDIT:
    case TaskActionType.TASK_CREATE:
    case TaskActionType.TASK_UPDATE:
      return {
        ...state,
        editorState: EditorState.CLOSED,
        editedResource: emptyTask,
        editedTaskBeforeEditing: emptyTask,
        startDateTime: undefined,
        taskDialogState: TaskDialogState.CLOSED,
      };

    case TaskActionType.TASK_PARAM_CHANGE:
      return {
        ...state,
        editedResource: {
          ...state.editedResource,
          ...action.payload.changedParameters,
        },
      };

    case TaskActionType.TASK_SET_DIALOG_STATE:
      return {
        ...state,
        taskDialogState: action.payload,
      };

    case TaskActionType.TASK_SET_CHAT_ICON_STATE:
      return {
        ...state,
        taskChatIconState: action.payload,
      };

    default:
      return state;
  }
};
