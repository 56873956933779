import {
  StyledComponentProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import { HourTypeWithKey, MemberWithKey } from '@shared/schema/index';
import { ReportType, WorkTimeReports } from '@shared/schema/reports';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers/index';
import CustomTableCell from 'theme/styles/tableCell';
import styles from './styles';
import WorkTimeTableRow from './WorkTimeTableRow';

export interface WorkTimeTableProps extends StyledComponentProps, DispatchProp {
  selectedMembers?: MemberWithKey[];
  hourTypes: HourTypeWithKey[];
  startDate?: Date;
  endDate?: Date;
  companyId: string;
  reports?: WorkTimeReports;
  showOrCloseMemberWorktimeData(member: MemberWithKey): void;
}

export class WorkTimeTable extends React.Component<WorkTimeTableProps> {
  constructor(props: WorkTimeTableProps) {
    super(props);
  }

  public render() {
    const { classes = {}, hourTypes, selectedMembers } = this.props;

    return (
      <div className={classes.tableContainer}>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead} data-test="tableHeadRow">
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.name}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.email}
                </CustomTableCell>
                <CustomTableCell className={classes.tableCellHeader}>
                  {i18n().ui.recorded_hours}
                </CustomTableCell>
                {hourTypes.map(hourType => (
                  <CustomTableCell
                    key={hourType.name}
                    className={classes.tableCellHeader}
                  >
                    {hourType.name}:
                  </CustomTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-test="tableBody">
              {selectedMembers &&
                selectedMembers.map(member =>
                  this.getWorkTimeTableRow(ReportType.ALL, member),
                )}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  /**
   * Creates one worktimetable row and gets data for it by ReportType and member
   */
  private getWorkTimeTableRow = (type: ReportType, member: MemberWithKey) => {
    const { reports, hourTypes, showOrCloseMemberWorktimeData } = this.props;

    if (reports) {
      if (member.key && reports[member.key]) {
        return (
          <WorkTimeTableRow
            key={member.key}
            member={member}
            hourTypes={hourTypes}
            selectMember={showOrCloseMemberWorktimeData}
            data={reports[member.key][type]}
          />
        );
      }
    }
    return undefined;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<WorkTimeTableProps>,
) => {
  return {
    ...ownProps,
  } as WorkTimeTableProps;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(WorkTimeTable),
);
