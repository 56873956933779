import { StyledComponentProps, withStyles, WithTheme } from '@material-ui/core';
import { HourType, HourTypeWithKey } from '@shared/schema/index';
import { MemberWithKey, Schema, Settings } from '@shared/schema/index';
import { WorkTimeReports } from '@shared/schema/reports';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from 'reducers';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import {
  updateHourTypes,
  updateSelectedMember,
} from '../../../../../../../../../reducers/reports/reportsActions';
import FilterSettingsBar from './components/FilterSettingsBar/FilterSettingsBar';
import WorkTimeTable from './components/MemberWorkTimeTable/WorkTimeTable';
import WorkTimeTableMember from './components/MemberWorkTimeTable/WorkTimeTableMember';
import styles from './styles';

export interface WorkTimeReportsContainerProps
  extends DispatchProp,
    Partial<RouteComponentProps>,
    StyledComponentProps,
    WithTheme {
  companyId: string;
  members: MemberWithKey[];
  selectedMember?: MemberWithKey;
  selectedMembers?: MemberWithKey[];
  settings: Settings;
  startDate?: Date;
  endDate?: Date;
  reports?: WorkTimeReports;
  hourTypes?: HourTypeWithKey[];
}

export class WorkTimeReportsContainer extends React.Component<
  WorkTimeReportsContainerProps
> {
  /**
   * initilized snapshot listener
   */
  private unsubscribeCompanyHourType: () => void;

  constructor(props: WorkTimeReportsContainerProps) {
    super(props);
  }

  /**
   * Makes snapshot listener for hourtypes
   */
  public componentDidMount() {
    try {
      const companyRef = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId);

      this.unsubscribeCompanyHourType = companyRef
        .collection(Schema.HOURTYPES)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const hourTypes: HourType[] = snapshot.empty
            ? []
            : collectionToJsonWithIds<HourType>(snapshot);

          this.props.dispatch(updateHourTypes(hourTypes));
        });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Cancels snapshot listener
   */
  public componentWillUnmount() {
    this.unsubscribeCompanyHourType && this.unsubscribeCompanyHourType();
  }

  public render() {
    const {
      companyId,
      startDate,
      endDate,
      members,
      selectedMember,
      selectedMembers,
      reports,
    } = this.props;
    const { hourTypes } = this.props;

    return (
      <div>
        <FilterSettingsBar
          members={members}
          companyId={companyId}
          startDate={startDate}
          endDate={endDate}
          hourTypes={hourTypes}
          reports={reports}
        />

        {selectedMember ? (
          <WorkTimeTableMember
            hourTypes={hourTypes}
            companyId={companyId}
            startDate={startDate}
            endDate={endDate}
            reports={reports}
            selectedMember={selectedMember}
            showOrCloseMemberWorktimeData={this.showOrCloseMemberWorktimeData}
            data-test="workTimeTableMember"
          />
        ) : (
          <>
            {(selectedMembers && selectedMembers.length > 0) ||
            reports !== undefined ? (
              <WorkTimeTable
                hourTypes={hourTypes}
                selectedMembers={selectedMembers}
                companyId={companyId}
                startDate={startDate}
                endDate={endDate}
                showOrCloseMemberWorktimeData={
                  this.showOrCloseMemberWorktimeData
                }
                reports={reports}
                data-test="workTimeTable"
              />
            ) : (
              undefined
            )}
          </>
        )}
      </div>
    );
  }

  /**
   * Shows selected member data in separted view or closes it
   */
  private showOrCloseMemberWorktimeData = (
    member: MemberWithKey | undefined,
  ) => {
    this.props.dispatch(updateSelectedMember(member));
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<WorkTimeReportsContainerProps>,
) => {
  return {
    ...ownProps,
    settings: state.company.activeCompany.settings,
    selectedMembers: state.reports.selectedMembers,
    startDate: state.reports.dateStart,
    endDate: state.reports.dateEnd,
    selectedMember: state.reports.selectedMember,
    reports: state.reports.reports,
    hourTypes: state.reports.hourTypes,
  } as WorkTimeReportsContainerProps;
};

export default withRouter<any>(
  connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(WorkTimeReportsContainer),
  ),
);
