import {
  Button,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { i18n } from '@shared/locale';
import * as firebase from 'firebase';
import * as React from 'react';
import styles from '../styles';

interface UserSettingsInfoAndActionsState {
  userReloading: boolean;
  userCanChangePw: boolean;
}

export interface UserSettingsInfoAndActionsProps extends StyledComponentProps {
  userEmail: string | null;
  userName: string | null;
  openChangePwDialog: () => void;
}

export class UserSettingsInfoAndActions extends React.Component<
  UserSettingsInfoAndActionsProps,
  UserSettingsInfoAndActionsState
> {
  private signInMethod = localStorage.getItem('signInMethodGoogle');

  constructor(props: UserSettingsInfoAndActionsProps) {
    super(props);
    this.state = {
      userReloading: true,
      userCanChangePw: false,
    };
  }

  public componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      user
        .reload()
        .then(() => {
          this.setState({ userReloading: false, userCanChangePw: true });
        })
        .catch(err => console.error('Cannot reload user', err));
    } else {
      this.setState({ userReloading: false });
    }
  }

  public render() {
    const {
      classes = {},
      userEmail,
      userName,
      openChangePwDialog,
    } = this.props;
    const { userReloading, userCanChangePw } = this.state;
    const isGoogleSignMethod = this.signInMethod
      ? JSON.parse(this.signInMethod)
      : false;

    return (
      <div className={classes.userInfoAndActionsComponent}>
        <List className={classes.userSettingsInfoList}>
          <ListItem>
            <Typography variant="h5">{i18n().ui.basic_information}</Typography>
          </ListItem>
          <ListItem className={classes.userSettingsInfoListItem}>
            <Typography variant="h6">{i18n().ui.name}</Typography>
            <Typography data-test="userNameField" variant="subtitle2">
              {userName}
            </Typography>
            <Typography variant="h6">{i18n().ui.email}</Typography>
            <Typography data-test="userEmailField" variant="subtitle2">
              {userEmail}
            </Typography>
          </ListItem>
          <ListItem>
            {!isGoogleSignMethod && (
              <Button
                disabled={!userCanChangePw}
                variant="contained"
                onClick={() => openChangePwDialog()}
                className={classes.changePwButton}
                data-test="changePwButton"
              >
                {i18n().auth.change_pw}
                {userReloading && (
                  <CircularProgress
                    data-test="progressCircle"
                    className={classes.circleLoader}
                    size={20}
                  />
                )}
              </Button>
            )}
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  UserSettingsInfoAndActions,
);
