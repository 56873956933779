import { Theme } from '@material-ui/core';
import { i18n } from '@shared/locale';
import { Task, TaskStatus, TaskWithKey } from '@shared/schema';
import { momentDurationFormat } from '@shared/utils/moment';
import profileImage from 'assets/images/ProfileImage.png';
import ChatStatusIcon from 'components/ChatStatusIcon';
import moment from 'moment';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  deleteResourceEventRequest,
  moveResourceEvent,
} from 'reducers/events/eventActions';
import { openWarning } from 'reducers/timeline/timelineActions';
import store from 'store';
import { docToJsonWithId } from 'utils/firestoreUtils';
import { getTasksTableRef } from 'utils/tasksUtil';
import { TimelineItem } from '.';
import { renderShiftContent, renderTaskContent } from './renderTaskContent';

export type TimelineOptionsTemplateFunction = (
  item?: any,
  element?: any,
  data?: any,
) => any;

interface TimelineOptionsModifiedTemplate extends vis.TimelineOptions {
  template?: TimelineOptionsTemplateFunction;
}

export interface GroupTemplateProps {
  group: any;
}

function getStateTheming(data: Task, status: TaskStatus, theme: Theme): any {
  switch (status) {
    case TaskStatus.DONE:
      return theme.timeline.status.done;
    case TaskStatus.ACTIVE:
      return theme.timeline.status.active;
    case TaskStatus.PAUSED:
      return theme.timeline.status.paused;
    case TaskStatus.UNDONE:
    default:
      if (data.start >= new Date().getTime()) {
        return theme.timeline.status.undone;
      } else {
        return theme.timeline.status.late;
      }
  }
}

const now = moment();

export const getUpdatedOption = () => {
  const latestOptions: vis.TimelineOptions = {
    ...options,
    hiddenDates: [
      {
        start: `2018-01-01 ${momentDurationFormat(
          moment.duration(
            store.getState()!.company.activeCompany.settings.general
              .activeHours!.end,
            'millisecond',
          ),
          'hh:mm:ss',
        )}`,
        end: `2018-01-02 ${momentDurationFormat(
          moment.duration(
            store.getState()!.company.activeCompany.settings.general
              .activeHours!.start,
            'millisecond',
          ),
          'hh:mm:ss',
        )}`,
        repeat: 'daily',
      },
    ],
    stack: true,
    margin: {
      item: {
        vertical: 33,
      },
    },
  };

  return latestOptions;
};

export const zoomMin = 360 * 60 * 1000;

const options: TimelineOptionsModifiedTemplate = {
  start: now.valueOf(),
  end: moment(now).add(12, 'hours').valueOf(),
  width: '100%',
  maxHeight: '100%',
  margin: {
    item: 1,
    axis: 0,
  },
  editable: {
    add: false, // add new items by double tapping
    updateTime: true, // drag items horizontally
    updateGroup: true, // drag items from one group to another
    remove: true, // delete an item by tapping the delete button top right
    overrideItems: false, // allow these options to override item.editable
  },
  hiddenDates: [
    {
      start: '2018-01-01 22:00:00',
      end: '2018-01-02 06:00:00',
      repeat: 'daily',
    },
  ],

  verticalScroll: true, // Option to allow graph to have vertical scroll
  zoomKey: 'ctrlKey', // Control key to control zoom
  orientation: 'top', // Where day and time bar goes
  stack: false, //  Reject stacking of items in timeline
  showMajorLabels: true,
  showCurrentTime: true,
  zoomMin, // Minimum zoom level is now in 15min parts
  zoomMax: 14 * 24 * 60 * 60 * 1000, //  Max zoom level is two weeks
  locale: 'fi',
  showTooltips: true, // If true, items with titles will display a tooltip,
  tooltip: {
    followMouse: false, // If true, tooltip will follow mouse when hovering on item
    overflowMethod: 'cap', // As default 'flip', 'cap' will position tooltip inside timeline
  },

  onMove(item: any, callback: any) {
    const companyId = item.companyId;
    const userId = item.group;
    const start = item.start.getTime();
    const end = item.end.getTime();

    if (userId !== item.data.userId && item.data.status === TaskStatus.ACTIVE) {
      const newItem = {
        ...item,
        group: item.data.userId,
        start: item.data.start,
        end: item.data.end,
      };
      store.dispatch(
        openWarning(i18n().ui.member_cant_be_changed_when_task_active),
      );
      callback(newItem); // send back adjusted item
    } else if (userId !== item.data.userId && item.data.shiftId) {
      const newItem = {
        ...item,
        group: item.data.userId,
        start: item.data.start,
        end: item.data.end,
      };
      store.dispatch(openWarning(i18n().ui.task_outside_shift_warning));
      callback(newItem); // send back adjusted item
    } else if (
      item.data.repeatingTaskId &&
      !moment(item.data.start).isSame(item.start, 'day')
    ) {
      const newItem = {
        ...item,
        group: item.data.userId,
        start: item.data.start,
        end: item.data.end,
      };
      store.dispatch(
        openWarning(
          i18n().ui.start_date_for_repeating_tasks_can_not_be_changed,
        ),
      );
      callback(newItem); // send back adjusted item
    } else {
      moveResourceEvent(companyId, item.id, userId, start, end);

      callback(item); // send back adjusted item
    }
  },

  onRemove(item: any, callback: any) {
    if (item.data.status === TaskStatus.ACTIVE) {
      store.dispatch(openWarning(i18n().ui.cannot_delete_active_tasks));
      return;
    }

    getTasksTableRef(item.companyId)
      .doc(item.id)
      .get()
      .then(doc => {
        const task = docToJsonWithId<TaskWithKey>(doc);
        store.dispatch(
          deleteResourceEventRequest(
            task,
            item.id,
            task.worksite ? task.worksite.name : '',
          ),
        );
      });
  },

  /**
   * Event template
   */
  template(item: TimelineItem) {
    if (!item) {
      return;
    }

    const { data, chatIconState, theme, settings } = item;

    if (item.type === 'background') {
      return renderToStaticMarkup(renderShiftContent(item));
    } else {
      const { background } = getStateTheming(data, data.status, theme);

      return renderToStaticMarkup(
        <div
          className="timeline-item"
          style={{
            borderBottom: `5px solid ${background}`,
          }}
        >
          <ChatStatusIcon
            state={chatIconState}
            style={{
              position: 'absolute',
              width: '17px',
              height: '17px',
              top: '8px',
              right: '8px',
            }}
          />
          {renderTaskContent(data, settings)}
        </div>,
      );
    }
  },

  /**
   * User template
   */
  groupTemplate(group: any, element: any) {
    if (!group || group.length === 0) {
      return '';
    } else {
      return renderToStaticMarkup(
        <>
          <div className="avatar">
            <img
              src={group.photoURL || profileImage}
              width={30}
              height={30}
              style={{ borderRadius: '50%' }}
            />
          </div>
          <div className="name">{group.content}</div>
        </>,
      );
    }
  },

  locales: {
    // create a new locale (text strings should be replaced with localized strings)
    fi: {
      current: 'nykyhetki',
      time: 'aika',
    },
  },
};

export default options;
