import { Localization, locales } from './localeType';

export enum SupportedLocale {
  FI = 'fi_FI',
  EN = 'en_UK'
}

export type LocaleMap = { [Key in SupportedLocale]: Localization };

export const defaultLocale: SupportedLocale = SupportedLocale.EN;
//SupportedLocale.FI;

let locale: Localization | undefined = locales[defaultLocale];

let currentLocale: SupportedLocale = defaultLocale;
let testModeOverwrites: any = undefined;
let testModeDefaultValue = '--locale-test-mode--';

export function setLocale(newLocale: SupportedLocale) {
  if (newLocale === currentLocale) {
    return;
  }
  // TODO listener support to trigger on locale change
  currentLocale = newLocale;
  locale = locales[newLocale];
}

type LocalizationStrategy = () => Localization;

/**
 * Normal localization mode strategy used in run time. Function will return the localization object with
 * based on current locale.
 */
const defaultStrategy: LocalizationStrategy = (): Localization => {

  if (locale) {
    return locale;
  }
  // This should only happen when locale is not set at all. So we use default locale here
  // setLocale(SupportedLocale.FI);
  locale = locales[defaultLocale];

  return locale;
};

let proxies: any = {};

/**
 * Test mode locale proxy to mock locale values
 */
const testModeProxyHandler = {
  get: (target: any, name: string) => {
    if (testModeOverwrites[name]) {
      if (!proxies[name]) {
        proxies[name] = new Proxy(testModeOverwrites[name], {
          get: (target: any, name: string) => {
            return target[name] ? target[name] : testModeDefaultValue;
          }
        });
      }
      return proxies[name];
    }
    return testModeDefaultValue;
  }
};

let testProxy: any = undefined;

/**
 * Localization strategy used in a test mode. It will mock values with override values given
 * in `setLocaleTestMode`-function and if no override field is found the the default value given
 * in a same function will be used.
 */
const testModeStrategy: LocalizationStrategy = (): Localization => {
  if (!testProxy) {
    testProxy = new Proxy<any>({} as any, testModeProxyHandler);
  }
  return testProxy;
};

let localizationStrategy = defaultStrategy;

/**
 * Return an active locale object.
 *
 * @export
 * @returns {Localization}
 */
export function i18n(): Localization {
  return localizationStrategy();
}

/**
 * Set localization in test mode. This will freeze localization to return a given default value or
 * one of the overrides when ever i18() -function is called;
 *
 * @export
 * @param {string} [defaultValue='--locale-test-mode--']
 * @param {*} [overwrites={}]
 */
export function setLocaleTestMode(
  defaultValue: string = '--locale-test-mode--',
  overwrites: any = {}
) {
  testModeDefaultValue = defaultValue;
  testModeOverwrites = overwrites;
  localizationStrategy = testModeStrategy;
}
