import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import tableLayout from '../../../../../../../../../theme/styles/table';

export const styles = (theme: Theme): StyleRules<string> => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableHead: {
    width: '50%',
  },
  tableContainer: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    marginTop: '16px',
  },
  ...{
    ...tableLayout(theme),
    tableCell: {
      width: 'calc(100%/7)',
      border: `0.25px solid ${theme.colors.tableCellBorder}`,
      padding: '4px 12px 4px 24px',
    },
  },
  totalTime: {
    textAlign: 'right',
    fontSize: '14px',
    marginTop: '10px',
  },
  snackbarCloseIcon: {
    color: theme.colors.white,
  },
  sortLabel: {
    color: 'white',
    '&:hover': { color: 'white' },
    '&:focus': { color: 'white' },
    '& svg': {
      color: 'white',
      opacity: 0.4,
    },
  },
  sortableCell: {
    cursor: 'pointer',
  },
  taskWorkingHours: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    minWidth: '35px',
    margin: 0,
    marginLeft: 'auto',
  },
  iconButton: {
    padding: '0 5px',
  },
  textField: {
    width: '70px',
    marginTop: '0',
    marginBottom: '0',
  },

  multiUserRow: {
    borderBottom: 'unset',
    '& > *': {
      borderBottom: 'unset',
    },
  },

  tableBody: {
    border: `0.25px solid ${theme.colors.tableCellBorder}`,
  },
  headCell: {
    background: theme.colors.darkerGrey,
    zIndex: 2,
  },

  customTableHead: {
    height: '40px',
  },

  changeWorkingTimeDialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    width: '300px',
  },

  changeWorkingTimeDialogValuesContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '20px',
  },

  workingHoursContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  multipleUsersRow: {
    height: 'auto',
  },

  multipleUsersCell: {
    paddingBottom: 0,
    paddingTop: 0,
    padding: 0,
  },

  multipleUsersTableContainer: {
    padding: '10px',
  },

  multipleUsersTable: {
    background: '#ececec',
  },

  userCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default styles;
