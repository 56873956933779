import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
const styles = (theme: Theme): StyleRules<string> => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    color: 'red!important',
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    color: `${theme.colors.white}`,
  },

  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: `${theme.colors.white}`,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  control: {
    borderBottomColor: `${theme.colors.white}`,
  },
  disabledText: {
    color: `gray`,
 
  },
  text: {
    color: `${theme.colors.white}`,
  },
  disabledUnderline: {
    '&:before': {
      borderBottom: `1px solid gray`,
    },

    '&&&&:hover:before': {
      borderBottom: `1px solid gray`,
    },

    '&:after': {
      borderBottom: `1px solid gray`,
    }
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.colors.white}`,
    },

    '&&&&:hover:before': {
      borderBottom: `1px solid ${theme.colors.white}`,
    },

    '&:after': {
      borderBottom: `1px solid ${theme.colors.white}`,
    }
  }
});
export default styles;
