import { Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import WorksiteIcon from '@material-ui/icons/Place';
import { i18n } from '@shared/locale/index';
import { CompanyId, TaskStatus } from '@shared/schema';
import {
  CustomerWithKey,
  MemberIcons,
  TaskWithKey,
  WorksiteWithKey,
} from '@shared/schema/index';
import classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import styles from './styles';

// tslint:disable-next-line: no-var-requires
const placeholderIcon = require('../../../../../src/assets/images/ProfileImage.png');

export interface MarkerProps extends StyledComponentProps {
  lat: number;
  lng: number;
  data: WorksiteWithKey;
  status: string;
  worksiteTasks: TaskWithKey[];
  customer: CustomerWithKey;
  memberIcons: MemberIcons[];
  selected: boolean;
  newWorksiteId: (id: string) => void;
  companyId: CompanyId;
}

interface State {
  showInfoWindow: boolean;
  showWorksiteTasks: boolean;
  lockInfoWindow: boolean;
  tasks: JSX.Element[];
  customerInfo: JSX.Element[];
  anchorEl: any;
}

export class Marker extends Component<MarkerProps, State> {
  constructor(props: MarkerProps) {
    super(props);
    this.state = {
      showInfoWindow: false,
      showWorksiteTasks: false,
      lockInfoWindow: false,
      tasks: [],
      customerInfo: [],
      anchorEl: undefined,
    };
  }

  public render() {
    const { classes = {}, data, customer, selected } = this.props;
    const { showInfoWindow, showWorksiteTasks, tasks, anchorEl } = this.state;

    return (
      <ClickAwayListener
        key={data.key}
        onClickAway={this.closePopper}
        mouseEvent={'onClick'}
      >
        <>
          <div
            onClick={this.onClick}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            className={classNames(classes.base, this.getStyle())}
            data-test="marker"
          >
            <WorksiteIcon
              className={selected ? classes.selectedIcon : classes.worksiteIcon}
            />
          </div>

          <Popper
            open={showInfoWindow}
            anchorEl={anchorEl}
            placement="bottom-end"
            disablePortal={true}
            style={{ zIndex: 1000 }}
            data-test="popperTest"
            onMouseEnter={this.popperMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <Paper className={classes.infoWindow} elevation={1}>
              <Typography
                variant="h5"
                component="h3"
                className={classes.infoWindowHeadline}
                data-test="nameTest"
              >
                {data.name}
              </Typography>
              <Typography
                variant="caption"
                className={classes.infoWindowCaption}
                data-test="customerTest"
              >
                {customer ? customer.name : ''}
              </Typography>
              <Typography
                component="p"
                className={classes.infoWindowText}
                data-test="addressTest"
              >
                {data.location.address +
                  ', ' +
                  data.location.postalCode +
                  ' ' +
                  data.location.city}
              </Typography>
              <Typography
                component="p"
                className={classes.infoWindowText}
                data-test="phoneTest"
              >
                {data.phone}
              </Typography>

              <Paper
                className={classes.newTask}
                elevation={1}
                onClick={this.onAddNewTaskClick}
              >
                <Typography component="p" className={classes.taskName}>
                  {i18n().ui.new_task}
                </Typography>
              </Paper>

              {showWorksiteTasks && (
                <div data-test="tasks">
                  <Typography
                    className={classes.infoWindowTaskHeader}
                    variant="h6"
                  >
                    {i18n().ui.worksite_tasks}
                  </Typography>
                  {tasks}
                </div>
              )}
            </Paper>
          </Popper>
        </>
      </ClickAwayListener>
    );
  }

  /**
   * Close popper info
   */
  private closePopper = () => {
    this.setState({ showInfoWindow: false, lockInfoWindow: false });
  };

  /**
   * Keeps popper open if it opened right on cursor.
   */
  private popperMouseEnter = () => {
    this.setState({ showInfoWindow: true });
  };

  /**
   * Marker click handler, show/hide & lock/unlock infowindow
   */
  private onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      lockInfoWindow: !this.state.lockInfoWindow,
      showInfoWindow: !this.state.lockInfoWindow,
      anchorEl: event.currentTarget,
    });
  };

  /**
   * Sends selected worksites key to MapContainer
   * which opens eventEditorDialog
   */
  private onAddNewTaskClick = () => {
    const { data } = this.props;
    this.props.newWorksiteId(data.key);
  };

  /**
   * Marker mouse enter, show info window
   */
  private onMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    this.getWorksiteTasks();
    this.setState({
      showInfoWindow: true,
      anchorEl: event.currentTarget,
    });
  };

  /**
   * Marker mouse leave, show info window
   */
  private onMouseLeave = () => {
    const { lockInfoWindow } = this.state;
    if (lockInfoWindow === false) {
      this.setState({
        showInfoWindow: false,
      });
    }
  };

  /**
   * Get tasks of selected worksite and display them
   */
  private getWorksiteTasks = () => {
    const { worksiteTasks, classes = {} } = this.props;
    const tasks = [];
    for (const task of worksiteTasks) {
      if (task.status !== TaskStatus.DONE) {
        tasks.push(
          <Paper
            key={task.key}
            className={classNames(
              classes.worksiteTask,
              this.getWorksiteTaskStyle(task),
            )}
            elevation={1}
          >
            <Typography component="p" className={classes.taskName}>
              {task.title && task.title.userModified
                ? task.title.value
                : task.jobtypeName}
            </Typography>
            {this.getMemberIcon(task)}
          </Paper>,
        );
      } else {
        return;
      }
    }

    this.setState({
      tasks,
      showWorksiteTasks: worksiteTasks.length !== 0,
    });
  };

  /**
   * Get correct icon from members collection and place it on task if found
   * @param task Selected worksite task
   * @returns Correct avatar for selected task
   */
  private getMemberIcon = (task: TaskWithKey) => {
    const { memberIcons, classes = {} } = this.props;
    const correctIcon = memberIcons.find(icon => icon.id === task.userId);
    let avatar: JSX.Element | undefined;
    avatar = (
      <Tooltip title={task.userName} placement="left">
        <img
          className={classNames(classes.avatarIcon)}
          src={
            task.userId
              ? (correctIcon && correctIcon.photoURL) || placeholderIcon
              : ''
          }
        />
      </Tooltip>
    );

    return avatar;
  };

  /**
   * Get marker color
   * @returns Status in lowercase to get correct marker color
   */
  private getStyle = () => {
    const { status } = this.props;
    return status.toLowerCase();
  };

  /**
   * Get task color
   * @param task Selected task data
   * @returns Status in lowercase to get correct worksiteTaskStyle
   */
  private getWorksiteTaskStyle = (task: TaskWithKey) => {
    if (
      task.status === 'UNDONE' &&
      task.start < new Date().getTime() &&
      task.start !== 0
    ) {
      return 'late';
    }

    return task.status.toLowerCase();
  };
}

export default withStyles(styles, { withTheme: true })(Marker);
