import * as React from 'react';
import JobTypesSettings from '../components/JobTypesSettings';

export interface JobTypesContainerProps {
  companyId: string;
  classes?: any;
}

const JobTypesContainer = (props: JobTypesContainerProps) => (
  <JobTypesSettings companyId={props.companyId} />
);

export default JobTypesContainer;
