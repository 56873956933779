import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  StyledComponentProps,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { i18n } from '@shared/locale';
import {
  CustomerWithKey,
  DialogStatus,
  getAlarmMap,
  JobTypeWithKey,
  Schema,
  Settings,
  ShiftTemplateTask,
  ShiftTemplateTimeParams,
  ShiftTemplateWithKey,
  ShiftWithKey,
  TaskParams,
  TaskWithKey,
  TaskWorksite,
  VatWithKey,
  WorksiteWithKey,
} from '@shared/schema';
import classNames from 'classnames';
import ACSelect, { ACSelectValue } from 'components/ACSelect';
import LoadingSpinner from 'components/LoadingSpinner';
import MultiPurposeDialog from 'components/MultiPurposeDialog';
import firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as _ from 'lodash';
import { TimePicker } from 'material-ui-pickers';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ActionMeta } from 'react-select/lib/types';
import { ApplicationState } from 'reducers';

import moment from 'moment';

import { momentDurationFormat } from '@shared/utils/moment';
import { DurationFormatPreset } from '@shared/utils/moment/preset';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CustomersIcon from '@material-ui/icons/Contacts';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import HelpIcon from '@material-ui/icons/Help';
import WorksiteIcon from '@material-ui/icons/Place';
import TimerIcon from '@material-ui/icons/Timer';

import { TaskStatus } from '@shared/schema/index';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { chunkArray } from 'utils/repeatingTasksUtils';
import {
  getShiftsTableRef,
  getShiftTemplatesTableRef,
} from 'utils/shiftsUtils';
import { getTasksTableRef, getWorksiteTableRef } from 'utils/tasksUtil';
import styles from './styles';

let defaultTime = new Date().setFullYear(2000, 0, 1);
defaultTime = new Date(defaultTime).setHours(0, 0, 0, 0);
let defaultDuration = new Date().setFullYear(2000, 0, 1);
defaultDuration = new Date(defaultDuration).setHours(0, 0, 0, 0);

const oneDay = 86400000;

const emptyTask: ShiftTemplateTask = {
  id: '',
  start: 0,
  end: 0,
  jobtypeId: '',
};

export interface ShiftDialogProps extends DispatchProp, StyledComponentProps {
  dialogTitle: string;
  dialogType: DialogStatus | undefined;
  companyId: string;
  modifiedShift: ShiftTemplateWithKey | undefined;
  settings: Settings;
  handleCloseDialog: () => void;
}

interface State {
  loader: boolean;
  form: ShiftTemplateWithKey;
  oldForm: ShiftTemplateWithKey;
  initialForm: ShiftTemplateWithKey;
  jobtypes: JobTypeWithKey[];
  worksites: WorksiteWithKey[];
  customers: CustomerWithKey[];
  vats: VatWithKey[];
  tasks: TaskWithKey[];
  shifts: ShiftWithKey[];
  selectedTask: ShiftTemplateTask;
  taskDialogType: DialogStatus | undefined;
  openUnsavedChangesDialog: boolean;
  openTimelineChangesDialog: boolean;
  selectedTaskForDialog: ShiftTemplateTask;
  showTimePickerError: boolean;
  addedTasks: ShiftTemplateTask[];
  deletedTasks: ShiftTemplateTask[];
  changedTasks: ShiftTemplateTask[];
  isNightShift: boolean;
}

interface UserInfo {
  userId: string;
  userName: string;
}

enum ShiftDialogInputNames {
  NAME = 'name',
  TASKS = 'tasks',
}

export class ShiftDialog extends React.Component<ShiftDialogProps, State> {
  private unsubscribeWorksites: () => void;
  private unsubscribeCustomers: () => void;
  private unsubscribeJobTypes: () => void;
  private unsubscribeVats: () => void;

  constructor(props: ShiftDialogProps) {
    super(props);
    this.state = {
      loader: false,
      form: {
        name: '',
        tasks: [],
      },
      oldForm: {
        name: '',
        tasks: [],
      },
      initialForm: {
        name: '',
        tasks: [],
      },
      jobtypes: [],
      worksites: [],
      customers: [],
      vats: [],
      tasks: [],
      shifts: [],
      selectedTask: _.cloneDeep(emptyTask),
      taskDialogType: undefined,
      openUnsavedChangesDialog: false,
      openTimelineChangesDialog: false,
      selectedTaskForDialog: _.cloneDeep(emptyTask),
      showTimePickerError: false,
      addedTasks: [],
      deletedTasks: [],
      changedTasks: [],
      isNightShift: false,
    };
  }

  public componentDidMount() {
    const { dialogType, modifiedShift, companyId } = this.props;
    let worksites: WorksiteWithKey[] = [];
    try {
      this.unsubscribeWorksites = getWorksiteTableRef(companyId).onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          worksites.length > 0 ? (worksites = []) : worksites;
          snapshot.forEach(doc => {
            const data = doc.data() as WorksiteWithKey;
            data.key = doc.id;
            worksites.push(data);
          });
          this.setState({
            worksites,
          });
        },
      );
    } catch (error) {
      console.error('Unable to get worksites: ', error);
    }

    let customers: CustomerWithKey[] = [];
    try {
      this.unsubscribeCustomers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.CUSTOMERS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          customers.length > 0 ? (customers = []) : customers;
          snapshot.forEach(doc => {
            const data = doc.data() as CustomerWithKey;
            data.key = doc.id;
            customers.push(data);
          });
          this.setState({
            customers,
          });
        });
    } catch (error) {
      console.error('Unable to get customers: ', error);
    }

    let jobtypes: JobTypeWithKey[] = [];
    try {
      this.unsubscribeJobTypes = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.JOBTYPES)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          jobtypes.length > 0 ? (jobtypes = []) : jobtypes;
          snapshot.forEach(doc => {
            const data = doc.data() as JobTypeWithKey;
            data.key = doc.id;
            jobtypes.push(data);
          });
          this.setState({
            jobtypes,
          });
        });
    } catch (error) {
      console.error('Unable to get jobtypes: ', error);
    }

    let vats: VatWithKey[] = [];
    try {
      this.unsubscribeVats = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          vats.length > 0 ? (vats = []) : vats;
          snapshot.forEach(doc => {
            const data = doc.data() as VatWithKey;
            data.key = doc.id;
            vats.push(data);
          });
          this.setState({
            vats,
          });
        });
    } catch (error) {
      console.error('Unable to get VATs: ', error);
    }

    if (dialogType === DialogStatus.MODIFY) {
      const values = modifiedShift;
      if (values) {
        const form = {
          name: values.name,
          key: values.key,
          tasks: values.tasks,
        };

        this.setState({
          form: _.cloneDeep(form),
          initialForm: _.cloneDeep(form),
        });
      }
    }
  }

  public componentWillUnmount() {
    this.unsubscribeWorksites && this.unsubscribeWorksites();
    this.unsubscribeCustomers && this.unsubscribeCustomers();
    this.unsubscribeJobTypes && this.unsubscribeJobTypes();
    this.unsubscribeVats && this.unsubscribeVats();
  }

  public render() {
    const {
      classes = {},
      dialogTitle,
      handleCloseDialog,
      settings,
    } = this.props;
    const {
      form,
      taskDialogType,
      loader,
      customers,
      jobtypes,
      vats,
      worksites,
      selectedTask,
      openUnsavedChangesDialog,
      openTimelineChangesDialog,
      isNightShift,
    } = this.state;

    const sortedTasks = form.tasks
      ? form.tasks.sort((a, b) => a.start - b.start)
      : [];

    return (
      <Dialog open={true}>
        {loader ? (
          <>
            <LoadingSpinner />
            <Typography>Saving changes, please wait...</Typography>
            {/* <ProgressLoader /> */}
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent style={{ minWidth: '400px' }}>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={i18n().ui.shift_name}
                  type="text"
                  fullWidth
                  onChange={this.handleDialogChange(ShiftDialogInputNames.NAME)}
                  value={form.name}
                />
                {sortedTasks.length > 0 && (
                  <Typography className={classes.taskListHeadline}>
                    {i18n().ui.shift_tasks}
                  </Typography>
                )}
                <List className={classes.tasksList}>
                  {sortedTasks.map(value => {
                    return (
                      <ListItem
                        key={value.id}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleToggle(value)}
                        selected={this.state.selectedTask.id === value.id}
                      >
                        <ListItemText
                          id={value.id}
                          primary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.listText}
                                color="primary"
                              >
                                {this.getJobtypeAndWorksiteName(
                                  value.jobtypeId,
                                  value.worksiteId,
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.listText}
                                color="primary"
                              >
                                {value.start && value.end
                                  ? this.getTaskTimeRange(
                                      value.start,
                                      value.end,
                                    )
                                  : i18n().ui.no_timeframe_available}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="comments"
                            onClick={this.removeTask(value.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
                {taskDialogType === undefined && (
                  <Button
                    onClick={this.handleToggle()}
                    color="primary"
                    className={classes.addButton}
                  >
                    {i18n().ui.add_task}
                  </Button>
                )}
              </div>

              {taskDialogType !== undefined && (
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <Typography className={classes.taskHeadline}>
                      {taskDialogType === DialogStatus.NEW
                        ? i18n().ui.add_task_to_shift
                        : i18n().ui.edit_task_in_shift}
                    </Typography>
                  </ListItem>
                  {settings.general.enableCustomer && (
                    <ListItem
                      id="customerListItem"
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <CustomersIcon />
                      </ListItemIcon>
                      <FormControl className={classes.formControl}>
                        {
                          <ACSelect
                            options={customers.map(
                              (value: CustomerWithKey) => ({
                                value: value.key,
                                label: value.name,
                              }),
                            )}
                            value={this.returnCustomer()}
                            onChange={this.handleTaskParamChange(
                              TaskParams.CUSTOMER,
                            )}
                            placeholder={i18n().ui.select_customer}
                            isClearable={true}
                          />
                        }
                      </FormControl>
                    </ListItem>
                  )}
                  {settings.general.enableWorksite && (
                    <ListItem
                      id="worksiteListItem"
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <WorksiteIcon />
                      </ListItemIcon>
                      <FormControl className={classes.formControl}>
                        {
                          <ACSelect
                            options={worksites.map(
                              (value: WorksiteWithKey) => ({
                                value: value.key,
                                label: value.name,
                              }),
                            )}
                            value={this.returnWorksite()}
                            onChange={this.handleTaskParamChange(
                              TaskParams.WORKSITE,
                            )}
                            placeholder={i18n().ui.select_worksite}
                            isClearable={true}
                          />
                        }
                      </FormControl>
                    </ListItem>
                  )}
                  <ListItem
                    id="jobtypeListItem"
                    disableGutters={true}
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <DoneIcon />
                    </ListItemIcon>
                    <FormControl className={classes.formControl}>
                      {
                        <ACSelect
                          options={jobtypes.map((value: JobTypeWithKey) => ({
                            value: value.key,
                            label: value.name,
                          }))}
                          value={this.returnJobtype()}
                          onChange={this.handleTaskParamChange(
                            TaskParams.JOBTYPE,
                          )}
                          placeholder={i18n().ui.select_jobtype + ' *'}
                          isClearable={false}
                          data-test="jobtypeACSelect"
                        />
                      }
                    </FormControl>
                  </ListItem>
                  {settings.general.enableVat && (
                    <ListItem
                      id="vatListItem"
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <EuroSymbolIcon />
                      </ListItemIcon>
                      <FormControl className={classes.formControl}>
                        {
                          <ACSelect
                            options={vats.map((value: VatWithKey) => ({
                              value: value.key,
                              label: `${value.name} ${
                                value.percent >= 0 ? value.percent + ' %' : ''
                              }`,
                            }))}
                            value={this.returnVat()}
                            onChange={this.handleTaskParamChange(
                              TaskParams.VAT,
                            )}
                            placeholder={i18n().ui.select_vat}
                            isClearable={true}
                            data-test="vatACSelect"
                          />
                        }
                      </FormControl>
                    </ListItem>
                  )}

                  <ListItem
                    disableGutters={true}
                    style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <DateRangeIcon />
                    </ListItemIcon>

                    <div className={classes.formControlSmall}>
                      <TimePicker
                        ampm={false}
                        label={i18n().ui.starting_time + ' *'}
                        value={this.returnCorrectTime(
                          selectedTask,
                          ShiftTemplateTimeParams.START,
                        )}
                        onChange={this.handleTaskTimeParamChange(
                          ShiftTemplateTimeParams.START,
                        )}
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        cancelLabel={i18n().ui.cancel}
                        clearLabel={i18n().ui.clear}
                        invalidDateMessage={i18n().ui.give_valid_time}
                        initialFocusedDate={defaultTime}
                        keyboardIcon={<AccessTimeIcon />}
                        keyboard
                        clearable
                        disableOpenOnEnter
                        data-test="start"
                      />
                    </div>
                    <div className={classes.formControlSmall}>
                      <TimePicker
                        ampm={false}
                        label={i18n().ui.ending_time + ' *'}
                        value={this.returnCorrectTime(
                          selectedTask,
                          ShiftTemplateTimeParams.END,
                        )}
                        onChange={this.handleTaskTimeParamChange(
                          ShiftTemplateTimeParams.END,
                        )}
                        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                        format={'HH:mm'}
                        cancelLabel={i18n().ui.cancel}
                        clearLabel={i18n().ui.clear}
                        invalidDateMessage={i18n().ui.give_valid_duration}
                        initialFocusedDate={defaultDuration}
                        keyboardIcon={<TimerIcon />}
                        keyboard
                        clearable
                        disableOpenOnEnter
                        data-test="end"
                      />
                    </div>
                  </ListItem>
                  {this.state.showTimePickerError && (
                    <ListItem
                      className={classNames(
                        classes.errorMessageHelperText,
                        classes.errorMessageWarning,
                      )}
                    >
                      {i18n().ui.missing_start_and_end}
                    </ListItem>
                  )}

                  <ListItem
                    id="checkboxListItem"
                    className={classes.checkboxListItem}
                  >
                    <Checkbox
                      checked={isNightShift}
                      onClick={this.handleNightShiftClick}
                    />
                    <Typography>{i18n().ui.set_task_nextday}</Typography>
                    <Tooltip title={i18n().ui.next_day_tooltip}>
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </ListItem>

                  {selectedTask.start > 0 && (
                    <ListItem
                      id="alarmListItem"
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <AccessAlarmIcon />
                      </ListItemIcon>
                      <FormControl className={classes.formControl}>
                        {
                          <ACSelect
                            options={getAlarmMap().map(value => ({
                              value: value.minutes,
                              label: value.message,
                            }))}
                            value={this.getDialogAlarm()}
                            onChange={this.handleTaskParamChange(
                              TaskParams.ALARM,
                            )}
                            placeholder={i18n().ui.no_alarm}
                            isClearable={false}
                            data-test="alarmACSelect"
                          />
                        }
                      </FormControl>
                    </ListItem>
                  )}

                  <ListItem disableGutters={true} className={classes.listItem}>
                    <ListItemIcon>
                      <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <TextField
                      label={i18n().ui.task_name}
                      placeholder={i18n().ui.optional_name_for_task}
                      value={selectedTask.title ? selectedTask.title.value : ''}
                      className={classes.textField}
                      onChange={this.handleTaskTextFieldsChange(
                        TaskParams.TITLE,
                      )}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-test="taskNameInput"
                    />
                  </ListItem>
                  <ListItem disableGutters={true} className={classes.listItem}>
                    <TextField
                      label={i18n().ui.notes_and_notices}
                      placeholder={i18n().ui.write_notes_here}
                      multiline={true}
                      value={selectedTask.desc || ''}
                      onChange={this.handleTaskTextFieldsChange(
                        TaskParams.DESC,
                      )}
                      className={classes.textField}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-test="taskNotesInput"
                    />
                  </ListItem>
                  <ListItem disableGutters={true} className={classes.listItem}>
                    <Button
                      onClick={this.handleAddClick}
                      disabled={!this.isFormValid()}
                      color="primary"
                    >
                      {taskDialogType === DialogStatus.NEW
                        ? i18n().ui.add
                        : i18n().ui.save}
                    </Button>
                    <Button onClick={this.handleCancelClick} color="primary">
                      {i18n().ui.cancel}
                    </Button>
                  </ListItem>
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>{i18n().ui.cancel}</Button>
              <Button
                onClick={this.handleSaveClick(this.props.dialogType)}
                disabled={taskDialogType !== undefined || form.name === ''}
                color="secondary"
              >
                {i18n().ui.save}
              </Button>
            </DialogActions>
          </>
        )}
        <MultiPurposeDialog
          open={openUnsavedChangesDialog}
          dialogTitle={i18n().ui.caution}
          dialogContent={i18n().ui.unsaved_changes}
          handleAccept={this.handleAcceptUnsavedChanges}
          handleCloseDialog={this.handleCloseMultiPurposeDialog}
        />
        <MultiPurposeDialog
          open={openTimelineChangesDialog}
          dialogTitle={i18n().ui.caution}
          dialogContent={i18n().ui.update_existing_tasks_caution}
          dialogButtonText={i18n().ui.save}
          handleAccept={this.handleAcceptTimelineChanges}
          handleCloseDialog={this.handleCloseMultiPurposeDialog}
        />
      </Dialog>
    );
  }

  /**
   * Function that controls what will happen when user presses another task or new task
   * @param selectedTask Selected task data which will be displayed in form editor
   */
  private handleToggle = (selectedTask?: ShiftTemplateTask) => () => {
    const { form, oldForm, taskDialogType } = this.state;

    this.isNightShiftChecked((selectedTask && selectedTask.start) || 0);

    if (
      _.isEqual(form, oldForm) ||
      oldForm.tasks.length === 0 ||
      (selectedTask && selectedTask.id === this.state.selectedTask.id) ||
      taskDialogType === undefined
    ) {
      this.setState({
        openUnsavedChangesDialog: false,
        selectedTask: selectedTask || _.cloneDeep(emptyTask),
        taskDialogType: selectedTask ? DialogStatus.MODIFY : DialogStatus.NEW,
        oldForm: _.cloneDeep(this.state.form),
      });
    } else {
      this.setState({
        openUnsavedChangesDialog: true,
        selectedTaskForDialog: selectedTask || _.cloneDeep(emptyTask),
      });
    }
  };

  /**
   * Removes task from form.
   * @param id Id of the task to be removed
   */
  private removeTask = (id: string) => () => {
    const { form } = this.state;
    const index = form.tasks.findIndex(task => task.id === id);
    const newForm = form;
    newForm.tasks.splice(index, 1);
    this.setState({
      form: _.cloneDeep(newForm),
    });
  };

  /**
   * Generates proper string to be shown in task list.
   * @param jobtypeId Jobtype Id
   * @param worksiteId Worksite Id
   * @returns Proper string for task's primary text
   */
  private getJobtypeAndWorksiteName = (
    jobtypeId: string,
    worksiteId?: string,
  ) => {
    const jobtypeData = this.state.jobtypes.find(
      jobtype => jobtype.key === jobtypeId,
    );
    let worksiteData;
    if (worksiteId) {
      worksiteData = this.state.worksites.find(
        worksite => worksite.key === worksiteId,
      );
    }
    if (jobtypeData && jobtypeData.name && worksiteData && worksiteData.name) {
      return jobtypeData.name + ' / ' + worksiteData.name;
    } else if (jobtypeData && jobtypeData.name) {
      return jobtypeData.name;
    } else {
      return '';
    }
  };

  /**
   * Generates proper string for timerange in tasklist
   * @param start Start time of the task
   * @param end Ending time of the task
   * @returns Proper text for task's secondary text
   */
  private getTaskTimeRange = (start: number, end: number) => {
    /**
     * Checks if given values are bigger than one day, reduces 24h if true
     */
    const startTime =
      start >= oneDay
        ? momentDurationFormat(start - oneDay, DurationFormatPreset.HHMM)
        : momentDurationFormat(start, DurationFormatPreset.HHMM);
    const endTime =
      end >= oneDay
        ? momentDurationFormat(end - oneDay, DurationFormatPreset.HHMM)
        : momentDurationFormat(end, DurationFormatPreset.HHMM);

    const returnString =
      start >= oneDay
        ? startTime + ' - ' + endTime + ' (' + i18n().ui.next_day + ')'
        : startTime + ' - ' + endTime;

    return returnString;
  };

  /**
   * Gets customer name from selected task's customer ID
   * @returns Customer name
   */
  private returnCustomer = () => {
    const { selectedTask, customers } = this.state;

    let returnValue = {
      value: '',
      label: i18n().ui.select_customer,
    };

    const selectedCustomer = selectedTask.customerId
      ? customers.find(customer => customer.key === selectedTask.customerId)
      : '';

    if (selectedCustomer && selectedCustomer.name && selectedCustomer.key) {
      returnValue = {
        value: selectedCustomer.key,
        label: selectedCustomer.name,
      };
    }
    return returnValue;
  };

  /**
   * Gets worksite name from selected task's worksite ID
   * @returns Worksite name
   */
  private returnWorksite = () => {
    const { selectedTask, worksites } = this.state;

    let returnValue = {
      value: '',
      label: i18n().ui.select_worksite,
    };
    const selectedWorksite = selectedTask.worksiteId
      ? worksites.find(worksite => worksite.key === selectedTask.worksiteId)
      : '';

    if (selectedWorksite && selectedWorksite.name && selectedWorksite.key) {
      returnValue = {
        value: selectedWorksite.key,
        label: selectedWorksite.name,
      };
    }
    return returnValue;
  };

  /**
   * Gets jobtype name from selected task's jobtype ID
   * @returns Jobtype name
   */
  private returnJobtype = () => {
    const { selectedTask, jobtypes } = this.state;

    let returnValue = {
      value: '',
      label: i18n().ui.select_jobtype + ' *',
    };

    const selectedJobtype = selectedTask.jobtypeId
      ? jobtypes.find(jobtype => jobtype.key === selectedTask.jobtypeId)
      : '';

    if (selectedJobtype && selectedJobtype.name && selectedJobtype.key) {
      returnValue = {
        value: selectedJobtype.key,
        label: selectedJobtype.name,
      };
    }

    return returnValue;
  };

  /**
   * Gets vat name from selected task's vat ID
   * @returns Vat name
   */
  private returnVat = () => {
    const { selectedTask, vats } = this.state;

    let returnValue = {
      value: '',
      label: i18n().ui.select_vat,
    };

    const selectedVat = selectedTask.vatId
      ? vats.find(vat => vat.key === selectedTask.vatId)
      : '';

    if (selectedVat && selectedVat.name && selectedVat.percent) {
      returnValue = {
        value: selectedVat.percent.toString(),
        label: selectedVat.name,
      };
    }
    return returnValue;
  };

  /**
   * Returns correct value for timepickers
   * @param selectedTask Selected Task
   * @param formatOfTime Selected Format
   * @returns Correct value
   */
  private returnCorrectTime = (
    selectedTask: ShiftTemplateTask,
    formatOfTime: ShiftTemplateTimeParams,
  ) => {
    const zeroDate = moment().set({ h: 0, m: 0, s: 0, ms: 0 });
    switch (formatOfTime) {
      case ShiftTemplateTimeParams.START:
        if (selectedTask.start && selectedTask.start > 0) {
          return zeroDate.valueOf() + selectedTask.start;
        }
        return null;

      case ShiftTemplateTimeParams.END:
        const duration = selectedTask.end;
        if (duration > 0) {
          return zeroDate.valueOf() + selectedTask.end;
        }
        return null;
    }
    return null;
  };

  /**
   * Checks when textfields input value changes and sets/updates it to states
   */
  private handleDialogChange = (item: keyof ShiftTemplateWithKey) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const form = {
      ...this.state.form,
      [item]: e.target.value,
    };
    this.setState({
      form: _.cloneDeep(form),
    });
  };

  /**
   * Function that handles any changes for customer, jobtype, vat, worksite and alarm
   * @param selected Selected value
   * @param action Action type
   */
  private handleTaskParamChange = (inputName: TaskParams) => (
    selected: ACSelectValue,
    action: ActionMeta,
  ) => {
    const { selectedTask } = this.state;
    const value: string =
      action.action === 'select-option' ? selected.value : '';
    const taskToBeUpdated = selectedTask;

    switch (inputName) {
      case TaskParams.CUSTOMER:
        taskToBeUpdated.customerId = value;
        break;
      case TaskParams.JOBTYPE:
        taskToBeUpdated.jobtypeId = value;
        break;
      case TaskParams.VAT:
        taskToBeUpdated.vatId = value;
        break;
      case TaskParams.WORKSITE:
        taskToBeUpdated.worksiteId = value;
        break;
      case TaskParams.ALARM:
        const valueAsNumber = Number(value);
        taskToBeUpdated.alarm = { minutes: valueAsNumber };

        break;
    }
    this.setState({
      selectedTask: taskToBeUpdated,
    });
  };

  /**
   * Function that handles any changes for start and end values
   * @param newTimeParam New time param as moment
   */
  private handleTaskTimeParamChange = (inputName: ShiftTemplateTimeParams) => (
    newTimeParam: moment.Moment,
  ) => {
    const { selectedTask } = this.state;
    const taskToBeUpdated = selectedTask;

    switch (inputName) {
      case ShiftTemplateTimeParams.START:
        if (newTimeParam) {
          const startMoment = newTimeParam;
          const endDateTime = moment(selectedTask.start)
            .add(startMoment.get('hours'), 'hours')
            .add(startMoment.get('minutes'), 'minutes')
            .valueOf();
          const startStamp = endDateTime - selectedTask.start;

          taskToBeUpdated.start = startStamp;
        } else {
          taskToBeUpdated.start = 0;
        }

        break;

      case ShiftTemplateTimeParams.END:
        if (newTimeParam) {
          const durationMoment = newTimeParam;
          const endDateTime = moment(selectedTask.start)
            .add(durationMoment.get('hours'), 'hours')
            .add(durationMoment.get('minutes'), 'minutes')
            .valueOf();
          const durationStamp = endDateTime - selectedTask.start;

          taskToBeUpdated.end = durationStamp;
        } else {
          taskToBeUpdated.end = 0;
        }

        break;
    }

    this.setState({
      selectedTask: taskToBeUpdated,
      showTimePickerError: newTimeParam === null,
    });
  };

  /**
   * Function that handles any changes for title and description
   * @param inputName Input text
   */
  private handleTaskTextFieldsChange = (inputName: TaskParams) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { selectedTask } = this.state;

    const taskToBeUpdated: ShiftTemplateTask = selectedTask;

    switch (inputName) {
      case TaskParams.TITLE:
        taskToBeUpdated.title = { value: target.value, userModified: true };
        break;

      case TaskParams.DESC:
        taskToBeUpdated.desc = target.value;
        break;
    }

    this.setState({
      selectedTask: taskToBeUpdated,
    });
  };

  /**
   * Function returns correct alarmMap if selectedTask has one
   * @returns Correct option from alarm menu
   */
  private getDialogAlarm = () => {
    const { selectedTask } = this.state;
    let ACAlarmObject: ACSelectValue = {
      label: getAlarmMap()[0].message as string,
      value: getAlarmMap()[0].minutes.toString(),
    };

    if (selectedTask.alarm) {
      const correctAlarm = getAlarmMap().find(
        alarm => selectedTask.alarm!.minutes === alarm.minutes,
      );

      ACAlarmObject = {
        label: correctAlarm!.message as string,
        value: correctAlarm!.minutes.toString(),
      };
    }

    return ACAlarmObject;
  };

  /**
   * Function that check if form is valid
   * @returns Boolean value
   */
  private isFormValid = () => {
    const { selectedTask, showTimePickerError } = this.state;

    let valid = true;
    if (selectedTask.jobtypeId === '') {
      valid = false;
    }
    if (
      selectedTask.start === 0 ||
      selectedTask.end === 0 ||
      showTimePickerError === true
    ) {
      valid = false;
    }

    return valid;
  };

  /**
   * Saves new shift to database by using form data
   */
  private saveNewShiftTemplate = () => {
    const { form } = this.state;
    const { companyId } = this.props;

    if (form) {
      const ref = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.SHIFTTEMPLATES)
        .doc();

      const newShiftToBeSaved: ShiftTemplateWithKey = {
        name: form.name,
        tasks: form.tasks,
      };

      this.setState({ loader: true });

      ref
        .set(newShiftToBeSaved)
        .then(() => {
          this.props.handleCloseDialog();
        })
        .catch(error => {
          console.error('Error while creating new shift, ', error);
          this.setState({ loader: false });
        });
    }
  };

  /**
   * Saves modified shift to database by using form data
   * Checks what type of changes has been made in form and if changes are needed in timeline tasks
   * Opens confirmation dialog if timeline will be affected, otherwise only saves the updated template
   */
  private saveModifiedShiftTemplate = async () => {
    const { form, initialForm } = this.state;
    const { companyId } = this.props;

    let addedTasks: ShiftTemplateTask[] = [];
    let deletedTasks: ShiftTemplateTask[] = [];
    const changedTasks: ShiftTemplateTask[] = [];

    // Check if tasks have been modified
    form.tasks.forEach(task => {
      const changedTask = initialForm.tasks.find(
        initialTask =>
          task.id === initialTask.id && !_.isEqual(task, initialTask),
      );
      if (changedTask) {
        changedTasks.push(task);
      }
    });

    // Check if tasks have been added
    addedTasks = _.differenceWith(form.tasks, initialForm.tasks, _.isEqual);
    addedTasks = addedTasks.filter(task => !changedTasks.includes(task));

    // Check if tasks have been removed
    deletedTasks = _.differenceWith(initialForm.tasks, form.tasks, _.isEqual);
    changedTasks.forEach(changedTask => {
      deletedTasks.forEach((deletedTask, index) => {
        if (deletedTask.id === changedTask.id) {
          deletedTasks.splice(index, 1);
        }
      });
    });

    try {
      await getTasksTableRef(companyId)
        .where('start', '>', moment().valueOf())
        .get()
        .then(taskSnapshot => {
          if (!taskSnapshot) {
            return;
          }

          const tasks: TaskWithKey[] = collectionToJsonWithIds(taskSnapshot);

          firebaseApp
            .firestore()
            .collection(Schema.COMPANIES)
            .doc(companyId)
            .collection(Schema.SHIFTS)
            .get()
            .then(shiftSnapshot => {
              if (!shiftSnapshot) {
                return;
              }

              const shifts: ShiftWithKey[] = collectionToJsonWithIds(
                shiftSnapshot,
              );
              const filteredTasks = tasks
                .filter(task => task.shiftTaskModified !== true)
                .filter(task => task.status !== TaskStatus.ACTIVE);

              const shiftsWithTemplateExist = shifts.find(
                shift => shift.shiftTemplateId === form.key,
              );

              if (shiftsWithTemplateExist) {
                this.setState({
                  openTimelineChangesDialog: true,
                });
              } else {
                if (form) {
                  const modifiedShiftToBeSaved: ShiftTemplateWithKey = {
                    name: form.name,
                    tasks: form.tasks,
                  };

                  try {
                    getShiftTemplatesTableRef(companyId)
                      .doc(form.key)
                      .update(modifiedShiftToBeSaved)
                      .then(() => {
                        this.props.handleCloseDialog();
                      });
                  } catch (error) {
                    console.error('Error updating shift templates, ', error);
                  }
                }
              }
              this.setState({
                tasks: filteredTasks,
                shifts,
                form,
                addedTasks,
                deletedTasks,
                changedTasks,
              });
            });
        });
    } catch (error) {
      console.error('Unable to get tasks: ', error);
    }
  };

  /**
   * Checks which types of changes are needed to existing tasks and changes them accordingly.
   * Also saves changes to shiftTemplate aswell
   * @param tasks All filtered tasks
   * @param shits All shifts
   * @param form Shift template with recent changes
   * @param addedTasks Tasks to be added
   * @param deletedTasks Tasks to be deleted
   * @param changedTasks Tasks to be changed
   */
  private modifyExistingTasks = () => {
    const { companyId } = this.props;
    const {
      customers,
      vats,
      worksites,
      jobtypes,
      tasks,
      shifts,
      form,
      addedTasks,
      deletedTasks,
      changedTasks,
    } = this.state;
    const batch = firebaseApp.firestore().batch();

    try {
      if (addedTasks.length > 0) {
        const shiftsToBeChanged = shifts.filter(
          shift => shift.shiftTemplateId === form.key,
        );

        const shiftChunks: ShiftWithKey[][] = chunkArray(shiftsToBeChanged, 8);

        // Store total number of chunks and current number of chunks to display creation progress in ProgressLoader
        // store.dispatch(setTotalProgress(shiftChunks.length));
        // store.dispatch(setCurrentProgress(0));
        // store.dispatch(setProgressMessage('Saving task changes, please wait'));

        shiftChunks.forEach(shiftChunk => {
          const batchForShiftChunk = firebaseApp.firestore().batch();

          shiftChunk.forEach(shift => {
            const startTimes: number[] = [];
            let userInfo: UserInfo = {
              userId: '',
              userName: '',
            };
            let repeatingShiftId: string = '';

            shift.taskIds.forEach(taskId => {
              const taskToBeChecked = tasks.find(task => task.key === taskId);
              if (
                taskToBeChecked &&
                taskToBeChecked.userId &&
                taskToBeChecked.userName &&
                taskToBeChecked.start > moment().valueOf()
              ) {
                startTimes.push(taskToBeChecked.start);
              }
            });

            // Assume all tasks in shift have same user, so pick first one and use it's user data
            const shiftTasks: TaskWithKey[] = tasks.filter(
              task => task.shiftId === shift.key,
            );

            const firstTask = shiftTasks[0];
            if (firstTask && firstTask.userId && firstTask.userName) {
              userInfo = {
                userId: firstTask.userId,
                userName: firstTask.userName,
              };
              if (firstTask.repeatingShiftId) {
                repeatingShiftId = firstTask.repeatingShiftId;
              }
            }

            if (startTimes.length > 0 && userInfo.userId && userInfo.userName) {
              const earliestStartTime = Math.min.apply(Math, startTimes);
              const taskIdList: string[] = [];
              let taskStartValidation: boolean = true;
              addedTasks.forEach(addedTask => {
                /**
                 * Check if addedTask.start is actually in future
                 * Deny creation if in past, important check for currently ongoing shifts
                 */
                taskStartValidation =
                  moment(earliestStartTime)
                    .startOf('day')
                    .add(addedTask.start)
                    .valueOf() >= moment().valueOf();

                if (taskStartValidation) {
                  const taskJobtype = jobtypes.find(
                    jobtype => jobtype.key === addedTask.jobtypeId,
                  );
                  const taskJobtypeName = taskJobtype ? taskJobtype.name : '';
                  const taskBase: TaskWithKey = {
                    archived: false,
                    created: new Date().getTime(),
                    userId: userInfo.userId,
                    userName: userInfo.userName,
                    jobtypeId: addedTask.jobtypeId,
                    jobtypeName: taskJobtypeName,
                    start: moment(earliestStartTime)
                      .startOf('day')
                      .add(addedTask.start)
                      .valueOf(),
                    end: moment(earliestStartTime)
                      .startOf('day')
                      .add(addedTask.end)
                      .valueOf(),
                    duration: addedTask.end - addedTask.start,
                    status: TaskStatus.UNDONE,
                    alarm: addedTask.alarm,
                    desc: addedTask.desc,
                    priority: addedTask.priority,
                    title: addedTask.title,
                    shiftId: shift.key,
                    shiftTemplateTaskId: addedTask.id,
                  };

                  if (addedTask.customerId) {
                    const taskCustomer = customers.find(
                      customer => customer.key === addedTask.customerId,
                    );
                    if (taskCustomer) {
                      taskBase.customerId = addedTask.customerId;
                      taskBase.customerName = taskCustomer.name;
                    }
                  }

                  if (addedTask.vatId) {
                    const taskVat = vats.find(
                      vat => vat.key === addedTask.vatId,
                    );
                    if (taskVat) {
                      taskBase.vat = taskVat;
                    }
                  }

                  if (addedTask.worksiteId) {
                    const foundWorksite = worksites.find(
                      worksite => worksite.key === addedTask.worksiteId,
                    );
                    if (foundWorksite && foundWorksite.key) {
                      const taskWorksite: TaskWorksite = {
                        id: foundWorksite.key,
                        name: foundWorksite.name,
                        nick: foundWorksite.nick,
                        note: foundWorksite.note,
                      };
                      const taskLocation = foundWorksite.location;
                      taskBase.worksite = taskWorksite;
                      taskBase.location = taskLocation;
                    }
                  }

                  if (addedTask.alarm) {
                    const alarm = addedTask.alarm;
                    taskBase.alarm = alarm;
                  }

                  if (addedTask.title) {
                    const title = addedTask.title;
                    taskBase.title = title;
                  }

                  if (addedTask.desc) {
                    taskBase.desc = addedTask.desc;
                  }

                  if (repeatingShiftId !== '') {
                    taskBase.repeatingShiftId = repeatingShiftId;
                  }

                  Object.keys(taskBase).forEach(key => {
                    if (taskBase[key] === undefined) {
                      delete taskBase[key];
                    }
                  });

                  let docId;
                  try {
                    const document = firebase
                      .firestore()
                      .collection(Schema.COMPANIES)
                      .doc(this.props.companyId)
                      .collection(Schema.TASKS)
                      .doc();

                    docId = document.id;
                    taskIdList.push(docId);
                    const taskRef = getTasksTableRef(companyId).doc(docId);

                    batchForShiftChunk.set(taskRef, taskBase);
                  } catch (error) {
                    console.error(
                      'Failed to create repeating tasks document id.',
                      error,
                    );
                  }
                }
              });
              if (taskStartValidation) {
                const updatedShift: ShiftWithKey = Object.assign(shift);

                const newTaskIdList = _.concat(shift.taskIds, taskIdList);
                updatedShift.taskIds = newTaskIdList;
                const shiftRef = getShiftsTableRef(companyId).doc(shift.key);

                delete updatedShift.key;
                batchForShiftChunk.set(shiftRef, updatedShift);
              }
            }
          });
          batchForShiftChunk
            .commit()
            // .then(() => store.dispatch(incrementProgress()))
            .catch(e => console.error('Failed to modify shifts: ' + e));
        });
      }

      /**
       * If deletedTasks are found, remove them in both tasks and shifts table
       */
      if (deletedTasks.length > 0) {
        let tasksToBeDeleted: TaskWithKey[] = [];
        deletedTasks.forEach(deletedTask => {
          const filteredTasks = tasks.filter(
            task => task.shiftTemplateTaskId === deletedTask.id,
          );
          tasksToBeDeleted = _.concat(tasksToBeDeleted, filteredTasks);
        });

        // To avoid hitting the 500 documents batch limit, splitting tasks into chunks and deleting them in separate batches
        const foundTaskChunks: TaskWithKey[][] = chunkArray(
          tasksToBeDeleted,
          10,
        );

        // Store total number of chunks and current number of chunks to display creation progress in ProgressLoader
        // store.dispatch(setTotalProgress(foundTaskChunks.length));
        // store.dispatch(setCurrentProgress(0));
        // store.dispatch(setProgressMessage('Deleting shift tasks, please wait'));

        foundTaskChunks.forEach(foundChunk => {
          const deletedBatch = firebaseApp.firestore().batch();
          foundChunk.forEach(task => {
            if (task.key) {
              // If found archive task instead of deleting
              if (
                task.activeTimer ||
                (task.timerLog && task.timerLog.length > 0)
              ) {
                const taskToBeArchived = { ...task, archived: true };

                const taskRef = getTasksTableRef(companyId).doc(task.key);

                batch.update(taskRef, taskToBeArchived);
              } else {
                const taskRef = getTasksTableRef(companyId).doc(task.key);

                deletedBatch.delete(taskRef);
              }
            }
          });
          deletedBatch
            .commit()
            // .then(() => store.dispatch(incrementProgress()))
            .catch(e => console.error('Failed to delete shift tasks: ' + e));
        });

        shifts.forEach(shift => {
          const shiftToBeChanged = _.cloneDeep(shift);
          tasksToBeDeleted.forEach(task => {
            const isTaskIdFound = shift.taskIds.find(
              taskId => taskId === task.key,
            );

            if (isTaskIdFound && shift.key) {
              const index = shiftToBeChanged.taskIds.findIndex(
                taskId => taskId === task.key,
              );
              shiftToBeChanged.taskIds.splice(index, 1);
            }
          });

          if (shiftToBeChanged.taskIds.length < shift.taskIds.length) {
            const shiftRef = firebaseApp
              .firestore()
              .collection(Schema.COMPANIES)
              .doc(this.props.companyId)
              .collection(Schema.SHIFTS)
              .doc(shift.key);

            delete shiftToBeChanged.key;
            batch.update(shiftRef, shiftToBeChanged);
          }
        });
      }

      /**
       * If changedTasks are found, update them and store into batch
       */
      if (changedTasks.length > 0) {
        changedTasks.forEach(changedTask => {
          const found = tasks.filter(
            task => task.shiftTemplateTaskId === changedTask.id,
          );

          const foundTaskChunks = chunkArray(found, 10);
          foundTaskChunks.forEach(foundChunk => {
            const changedBatch = firebaseApp.firestore().batch();
            foundChunk.forEach(foundTask => {
              const taskToBeChanged = { ...foundTask };

              if (changedTask.customerId) {
                const taskCustomer = customers.find(
                  customer => customer.key === changedTask.customerId,
                );
                if (taskCustomer) {
                  taskToBeChanged.customerId = changedTask.customerId;
                  taskToBeChanged.customerName = taskCustomer.name;
                }
              }

              if (changedTask.vatId) {
                const taskVat = vats.find(vat => vat.key === changedTask.vatId);
                if (taskVat) {
                  taskToBeChanged.vat = taskVat;
                }
              }

              if (changedTask.worksiteId) {
                const foundWorksite = worksites.find(
                  worksite => worksite.key === changedTask.worksiteId,
                );
                if (foundWorksite && foundWorksite.key) {
                  const taskWorksite: TaskWorksite = {
                    id: foundWorksite.key,
                    name: foundWorksite.name,
                    nick: foundWorksite.nick,
                    note: foundWorksite.note,
                  };
                  const taskLocation = foundWorksite.location;
                  taskToBeChanged.worksite = taskWorksite;
                  taskToBeChanged.location = taskLocation;
                }
              }

              if (changedTask.jobtypeId) {
                taskToBeChanged.jobtypeId = changedTask.jobtypeId;
                const taskJobtype =
                  jobtypes.find(
                    jobtype => jobtype.key === changedTask.jobtypeId,
                  ) || '';
                if (taskJobtype) {
                  taskToBeChanged.jobtypeName = taskJobtype.name;
                }
              }

              if (changedTask.start && changedTask.end) {
                /**
                 * If given times are bigger than oneDay, subract oneDay
                 */
                const startTime =
                  changedTask.start >= oneDay
                    ? changedTask.start - oneDay
                    : changedTask.start;
                const endTime =
                  changedTask.end >= oneDay
                    ? changedTask.end - oneDay
                    : changedTask.end;

                const start = moment(taskToBeChanged.start)
                  .startOf('day')
                  .add(startTime)
                  .valueOf();
                const end = moment(taskToBeChanged.end)
                  .startOf('day')
                  .add(endTime)
                  .valueOf();
                taskToBeChanged.start = start;
                taskToBeChanged.end = end;

                taskToBeChanged.duration = changedTask.end - changedTask.start;
              }

              if (changedTask.alarm) {
                const alarm = changedTask.alarm;
                taskToBeChanged.alarm = alarm;
              }

              if (changedTask.title) {
                const title = changedTask.title;
                taskToBeChanged.title = title;
              }

              if (changedTask.desc) {
                taskToBeChanged.desc = changedTask.desc;
              }

              const taskRef = getTasksTableRef(companyId).doc(
                taskToBeChanged.key,
              );

              delete taskToBeChanged.key;
              changedBatch.update(taskRef, taskToBeChanged);
            });
            changedBatch
              .commit()
              // .then(() => console.log('Changing shift tasks succeeded'))
              .catch(e => {
                console.error('Failed to change shift tasks: ', e);
              });
          });
        });
      }

      /**
       * Update shiftTemplate
       */
      if (form) {
        const modifiedShiftToBeSaved: ShiftTemplateWithKey = {
          name: form.name,
          tasks: form.tasks,
        };
        this.setState({ loader: true });

        const shiftTemplateRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.SHIFTTEMPLATES)
          .doc(form.key);

        batch.update(shiftTemplateRef, modifiedShiftToBeSaved);
      }

      /**
       * Commit all changes stored inside batch and close dialog.
       */
      batch.commit().then(() => {
        this.props.handleCloseDialog();
      });
    } catch (error) {
      console.error('Error doing requested updates to timeline, ', error);
    }
  };

  /**
   * Called when form is opened, checks if isNightshift checkbox should already be checked
   * @param start Selected task's starting time
   */
  private isNightShiftChecked = (start: number) => {
    if (start >= oneDay) {
      this.setState({
        isNightShift: true,
      });
    } else {
      this.setState({
        isNightShift: false,
      });
    }
  };

  /**
   * Handles night shift checkbox click
   */
  private handleNightShiftClick = () => {
    const { isNightShift } = this.state;
    this.setState({ isNightShift: !isNightShift });
  };

  /**
   * Handles Add/Save changes click. Gets data from selectedTask and places them into form
   */
  private handleAddClick = () => {
    const { taskDialogType, selectedTask } = this.state;
    const { form, isNightShift } = this.state;
    const taskToBeAdded = selectedTask;
    const newForm = form;

    if (taskDialogType === DialogStatus.NEW) {
      try {
        const document = firebase
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.TASKS)
          .doc();

        // If start is bigger than end time, add 24h to task end
        if (taskToBeAdded.start >= taskToBeAdded.end) {
          taskToBeAdded.end = taskToBeAdded.end + oneDay;
        }

        // If isNightShift is active, add 24h
        if (isNightShift) {
          taskToBeAdded.start = taskToBeAdded.start + oneDay;
          taskToBeAdded.end = taskToBeAdded.end + oneDay;
        }

        taskToBeAdded.id = document.id;
        newForm.tasks.push(taskToBeAdded);
        this.setState({
          form: newForm,
          selectedTask: _.cloneDeep(emptyTask),
          taskDialogType: undefined,
        });
      } catch (error) {
        console.error('Failed to create repeating tasks document id');
      }
    }
    if (taskDialogType === DialogStatus.MODIFY) {
      const index = form.tasks.findIndex(task => task.id === taskToBeAdded.id);
      if (
        isNightShift &&
        taskToBeAdded.start <= oneDay &&
        taskToBeAdded.end <= oneDay
      ) {
        taskToBeAdded.start += oneDay;
        taskToBeAdded.end += oneDay;
      }

      if (index) {
        newForm.tasks[index] = taskToBeAdded;
      }
      this.setState({
        form: newForm,
        selectedTask: _.cloneDeep(emptyTask),
        taskDialogType: undefined,
      });
    }
  };

  /**
   * Handles if user presses 'Cancel' when editing task.
   */
  private handleCancelClick = () => {
    this.setState({
      selectedTask: _.cloneDeep(emptyTask),
      taskDialogType: undefined,
      form: _.cloneDeep(this.state.oldForm),
      openUnsavedChangesDialog: false,
      openTimelineChangesDialog: false,
      showTimePickerError: false,
    });
  };

  /**
   * Handles if user presses 'Ok' in popup dialog screen
   */
  private handleAcceptUnsavedChanges = () => {
    this.setState({
      openUnsavedChangesDialog: false,
      openTimelineChangesDialog: false,
      showTimePickerError: false,
      selectedTask: _.cloneDeep(this.state.selectedTaskForDialog),
      taskDialogType:
        this.state.selectedTaskForDialog.id !== ''
          ? DialogStatus.MODIFY
          : DialogStatus.NEW,
      form: _.cloneDeep(this.state.oldForm),
    });
  };

  /**
   * Executes modifyExistingTasks function if user pressed 'save' in popup.
   */
  private handleAcceptTimelineChanges = () => {
    this.modifyExistingTasks();

    this.setState({
      openUnsavedChangesDialog: false,
      openTimelineChangesDialog: false,
    });
  };

  /**
   * Handles if user presses 'Cancel' in popup dialog screen
   */
  private handleCloseMultiPurposeDialog = () => {
    this.setState({
      openUnsavedChangesDialog: false,
      openTimelineChangesDialog: false,
      loader: false,
    });
  };

  /**
   * Start specific saving method by what type dialogStatus is
   */
  private handleSaveClick = (dialogStatus: DialogStatus | undefined) => () => {
    if (dialogStatus === DialogStatus.NEW) {
      this.saveNewShiftTemplate();
    }
    if (dialogStatus === DialogStatus.MODIFY) {
      this.saveModifiedShiftTemplate();
    }
    this.setState({ loader: true });
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ShiftDialogProps>,
) => {
  return {
    ...ownProps,
    settings: state.company.activeCompany.settings,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ShiftDialog),
);
