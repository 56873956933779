import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import WorksiteIcon from '@material-ui/icons/Place';
import * as React from 'react';
import { Component } from 'react';
import styles from '../styles';

interface MarkerProps extends StyledComponentProps {
  lat: number;
  lng: number;
}

class Marker extends Component<MarkerProps> {
  constructor(props: MarkerProps) {
    super(props);
  }

  public render() {
    const { classes = {} } = this.props;
    return (
      <div className={classes.iconContainer}>
        <WorksiteIcon className={classes.worksiteIcon} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Marker);
