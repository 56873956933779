import * as React from 'react';
import VatSettings from '../components/VatSettings';

export interface VatContainerProps {
  companyId: string;
  classes?: any;
}

const VatsContainer = (props: VatContainerProps) => (
  <VatSettings companyId={props.companyId} />
);

export default VatsContainer;
