import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const drawerWidth = 240;

export const styles = (theme: Theme): StyleRules<string> => ({
  root: {
    width: '100%',
    height: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  fullScreenButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    transition: 'all 0.3s',
    color: theme.palette.secondary.main,
  },
  hide: {
    // display: "none"
    maxWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    overflow: 'hidden',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  toolbarHeader: {
    width: '100%',
    paddingLeft: '24px',
    fontSize: '22px',
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    overflowY: 'hidden',
    transition: 'margin-left 0.3s',
    marginLeft: '72px',
    paddingTop: '64px',
    height: '100%',
  },
  contentDrawerOpen: {
    marginLeft: drawerWidth,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    height: 10,
  },
  menuItemLogout: {
    marginTop: 15,
  },
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  feedbackButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    padding: '12px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});
