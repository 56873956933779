/*
 * This is an auto generated Typescript type -file
 * made from locale json files. Type Locale type is based on ./json/locale_nn_NN.json -files structure. The
 * type is a merge of json -file fields.
 *
 * PLEASE DO NOT MODIFY THIS FILE.
 *
 * @author Ville Venäläinen / Observis Oy
 */

import { LocaleMap } from '.';

/**
 * Typings to to help Typescript static check for locale keys on json file. This interface is autogenerated from
 * `@shared/locale/json/locale_en_UK.json` -file's field info.
 *
 * @author Ville Venäläinen
 */
export interface Localization {
  concepts: {
    member: string;
    jobtype: string;
  };
  ui: {
    contact_has_pending_invite: string;
    contact_has_account: string;
    join: string;
    dashboard: string;
    reports: string;
    members: string;
    settings: string;
    phone: string;
    log_out: string;
    loading___: string;
    log_in: string;
    profile: string;
    admin: string;
    status: string;
    next: string;
    back: string;
    privacy_policy: string;
    select_vat: string;
    vat_not_defined: string;
    default: string;
    customers_worksites: string;
    select_worksite: string;
    no_worksites: string;
    modify_customer: string;
    add_customer: string;
    customer_name: string;
    customer_number: string;
    address: string;
    postalcode: string;
    city: string;
    customers_contact: string;
    email: string;
    cancel: string;
    save: string;
    customer: string;
    contact_person: string;
    filter_by_jobtype: string;
    filter_by_qualification: string;
    filter_on: string;
    today: string;
    add_new: string;
    selected_members: string;
    all_members: string;
    unknown_filter: string;
    order_by_priority: string;
    highest_first: string;
    lowest_first: string;
    normal_first: string;
    delete: string;
    delete_selected: string;
    select_multiple: string;
    no_member_selected: string;
    member_missing_qualification: string;
    qualification_required: string;
    multiple_qualifications_missing: string;
    multiple_qualifications_required: string;
    modify_task: string;
    new_task: string;
    member_name: string;
    worksite_name: string;
    jobtype_name: string;
    date: string;
    give_valid_date: string;
    time: string;
    give_valid_time: string;
    duration: string;
    notification: string;
    no_alarm: string;
    fifteen_min_before: string;
    thirty_min_before: string;
    hour_before: string;
    unknown_status: string;
    undone: string;
    active: string;
    paused: string;
    late: string;
    completed: string;
    priority: string;
    unknown_priority: string;
    high: string;
    normal: string;
    low: string;
    task_name: string;
    optional_name_for_task: string;
    notes_and_notices: string;
    write_notes_here: string;
    write_message: string;
    message_must_be_atleast_one: string;
    send: string;
    assigned: string;
    unassigned: string;
    company_name: string;
    please_enter_company_name: string;
    please_enter_contact_person_name: string;
    please_enter_valid_email: string;
    please_enter_address: string;
    please_enter_postal_code: string;
    please_enter_city: string;
    please_enter_phone_number: string;
    add: string;
    remove: string;
    remove_selected: string;
    done: string;
    dashboard_home: string;
    map: string;
    customers: string;
    worksites: string;
    backup: string;
    fullscreen_mode: string;
    please_enter_valid_phone_number: string;
    please_enter_valid_postal_code: string;
    work_hours: string;
    vat_base: string;
    export_csv: string;
    time_frame: string;
    all_customers: string;
    daily_hours: string;
    total_hours: string;
    search_from_jobtypes: string;
    unknown_jobtype: string;
    error_resources_not_defined: string;
    minutes_before: string;
    no_members: string;
    select_jobtype: string;
    select_member: string;
    previous: string;
    previous_page: string;
    next_page: string;
    zoom_in: string;
    zoom_out: string;
    add_contact_person: string;
    remove_contact_person: string;
    contact_persons: string;
    set_this_contact_as_default: string;
    hour_type_name: string;
    hour_multiplier: string;
    select_day: string;
    select_time: string;
    select_duration: string;
    ok: string;
    hour_type_length_must_be: string;
    please_enter_valid_hour_type: string;
    want_to_delete_hour_type: string;
    want_to_delete_selected_hour_types: string;
    add_hour_type: string;
    edit_hour_type: string;
    search_for_hour_types: string;
    menu: string;
    user: string;
    feedback_form: string;
    own_tasks: string;
    free_tasks: string;
    done_tasks: string;
    please_enter_nick_name: string;
    further_information: string;
    nick_name: string;
    please_enter_worksite_name: string;
    required_fields: string;
    modify_worksite: string;
    new_worksite: string;
    postalcode_length_must_be: string;
    please_enter_further_informations: string;
    no_tasks: string;
    timeline: string;
    taskbank: string;
    min_date_message: string;
    clear: string;
    give_valid_duration: string;
    max_date_message: string;
    in_worksite: string;
    minutes_after: string;
    general_settings: string;
    jobtypes: string;
    qualifications: string;
    VAT: string;
    hourtypes: string;
    advanced: string;
    worksite_name_length_must_be: string;
    worksite_address_length_must_be: string;
    worksite_city_length_must_be: string;
    worksite_further_information_length_must_be: string;
    search_members: string;
    name: string;
    report: string;
    role: string;
    proficiencies: string;
    show_report: string;
    name_too_short: string;
    invalid_email: string;
    email_already_in_use: string;
    invalid_phone: string;
    edit_member: string;
    new_member: string;
    working_time: string;
    rows_per_page: string;
    selected_member: string;
    delete_task: string;
    delete_dialog_confirm: string;
    caution: string;
    continue: string;
    used_time: string;
    task_info: string;
    worksite_additional_info: string;
    chat: string;
    another_task_active: string;
    start_another_task_question: string;
    assign_to_me: string;
    pause: string;
    start: string;
    modify: string;
    free_this_task: string;
    free_dialog_confirm: string;
    no_customer: string;
    no_worksite: string;
    choose_all: string;
    delete_selections: string;
    search_jobtype: string;
    no_jobtype: string;
    select_customer: string;
    customer_name_length_must_be: string;
    customer_number_length_must_be: string;
    customer_address_length_must_be: string;
    customer_city_length_must_be: string;
    instructions_and_files: string;
    save_changes: string;
    cancel_changes: string;
    unsaved_changes: string;
    basic_information: string;
    ui_options: string;
    additional_field_visibility: string;
    worksite: string;
    worksite_nickname: string;
    type_company_name: string;
    type_contactperson_name: string;
    type_correct_email: string;
    type_address: string;
    type_postalcode: string;
    type_city: string;
    type_phonenumber: string;
    search_jobtypes: string;
    search_qualifications: string;
    search_vat: string;
    add_jobtype: string;
    modify_jobtype: string;
    add_qualification: string;
    modify_qualification: string;
    add_vat: string;
    modify_vat: string;
    search_worksites: string;
    search_customers: string;
    map_temp_text: string;
    delete_jobtype_confirm: string;
    delete_selected_jobtypes_confirm: string;
    delete_qualification_confirm: string;
    delete_selected_qualifications_confirm: string;
    delete_VAT_confirm: string;
    delete_selected_VATs_confirm: string;
    delete_task_confirm: string;
    language_select: string;
    percent: string;
    action_cant_be_cancelled: string;
    account: string;
    company: string;
    notifications: string;
    search: string;
    required_qualifications: string;
    instructions: string;
    task_files: string;
    role_admin: string;
    role_user: string;
    role_awaiting: string;
    role_closed: string;
    no_data_available: string;
    weekday: string;
    day: string;
    week: string;
    month: string;
    repetition: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    day_of_the_week: string;
    starting_time: string;
    ending_time: string;
    delete_worksite_confirm: string;
    delete_worksite: string;
    company_tag: string;
    userinterface_settings: string;
    company_data: string;
    company_tag_taken: string;
    business_id: string;
    please_enter_valid_businessid: string;
    new_company: string;
    new_company_tag: string;
    no_chosen_company: string;
    no_companies_available: string;
    companies: string;
    user_count: string;
    lock_company: string;
    please_enter_valid_company_tag: string;
    task_report: string;
    task_progress_report: string;
    working_time_report: string;
    add_member: string;
    open: string;
    delete_selection: string;
    task: string;
    in_progress: string;
    unsavedcontent: string;
    checking_for_updates: string;
    loading_update: string;
    installing_update: string;
    up_to_date: string;
    update_installed: string;
    something_went_wrong: string;
    worksite_tasks: string;
    update_available: string;
    download_required: string;
    update_is_required: string;
    unlock_company: string;
    started: string;
    no_tasks_within_timeframe: string;
    company_lock_confirm: string;
    company_unlock_confirm: string;
    search_from_map: string;
    search_results: string;
    search_optional: string;
    finished: string;
    all: string;
    paused_tasks: string;
    no_coordinates: string;
    task_has_hours_confirm: string;
    jobtype_already_exists: string;
    total: string;
    recorded_hours: string;
    working_hours: string;
    enter_hours: string;
    arrival_time: string;
    departure_time: string;
    write_notes: string;
    logged_hours: string;
    no_logged_hours: string;
    date_not_available: string;
    whole_hours_example: string;
    hourtype: string;
    year: string;
    cancel_editing: string;
    close: string;
    changelog: string;
    change: string;
    edit_hours: string;
    or: string;
    hourtype_exists: string;
    generating_csv: string;
    press_to_export_csv: string;
    generating_csv_done: string;
    some_results_are_filtered: string;
    daily: string;
    timeline_settings: string;
    set_the_time_frame_for_timeline_view: string;
    start_noun: string;
    end_noun: string;
    add_new_worksite: string;
    choose_location: string;
    postal_code_length_must_be_long: string;
    lack_qualification_warning: string;
    delete_logged_hours_confirm: string;
    removed: string;
    sorting: string;
    sorting_options: string;
    hide_selected: string;
    unhide_selected: string;
    total_working_hours_per_day_max_24h: string;
    cannot_log_more_hours_please_edit_existing_entries: string;
    date_time_warning_message: string;
    missing_member_warning_message: string;
    missing_task_requirements: string;
    you_can_log_max_hhmm_for_this_entry: string;
    week_single: string;
    set_date_to_create_repetitive: string;
    too_many_tasks_error: string;
    update_recurring_task: string;
    delete_recurring_task: string;
    this_task: string;
    this_task_and_following: string;
    repeating_task_member_change_warning: string;
    yes: string;
    shift_save_warning_tooltip: string;
    new_shift: string;
    select_shift: string;
    warning: string;
    add_task: string;
    add_shift: string;
    search_shifts: string;
    delete_shift_confirmation: string;
    delete_multiple_shifts_confirmation: string;
    edit_shift: string;
    shift_name: string;
    no_timeframe_available: string;
    add_task_to_shift: string;
    edit_task_in_shift: string;
    missing_start_and_end: string;
    startdate_later_enddate: string;
    shift_info: string;
    shifts: string;
    shift_tasks: string;
    repeating_task_active_status_delete_warning: string;
    cannot_delete_active_tasks: string;
    member_cant_be_changed_when_task_active: string;
    update_existing_tasks_caution: string;
    task_outside_shift_warning: string;
    start_date_for_repeating_tasks_can_not_be_changed: string;
    delete_from_shift: string;
    this_shift: string;
    delete_from_recurring_shift: string;
    this_shift_and_following: string;
    all_shifts_in_repetition: string;
    set_task_nextday: string;
    next_day_tooltip: string;
    next_day: string;
    only_one_task_active_change_applied_for_edited_task: string;
    filter_by_proficiency: string;
    members_not_found: string;
    end_date: string;
    import_tasks: string;
    import: string;
    import_confirm: string;
    tasks: string;
    new_worksites_and_jobtypes_will_be_created_automatically: string;
    something_went_wrong_check_csv: string;
    import_complete: string;
    will_be_assigned_to: string;
    member_not_found_task_will_be_set_as_free: string;
    free_task: string;
    preview: string;
    no_location_set: string;
    task_will_be_carried_out_for_customer: string;
    business_id_not_found_creating_new: string;
    name_can_be_changed_later_from_customers_panel: string;
    business_id_not_set: string;
    import_filled_csv_chart: string;
    select_file: string;
    download_example_csv_import_form: string;
    download: string;
    downloaded: string;
    error: string;
    tips_to_fill_out_the_form: string;
    customer_task_id_tip: string;
    only_required_field_is_jobtype: string;
    there_are_many_options_to_fill_timeframe: string;
    weeknumber_creates_flexible_task_tip: string;
    date_format_tip: string;
    start_end_time_tip: string;
    new_worksites_customers_jobtypes_are_created_tip: string;
    change_working_time_for_user: string;
    new_working_time: string;
    previous_working_time: string;
    multiple_users: string;
    unknown_user: string;
    task_id: string;
    edited_user: string;
    select_status: string;
    last_day: string;
    last_week: string;
    last_month: string;
    no_tasks_within_timeframe_and_filters: string;
    week_text: string;
    deselect_all: string;
    no_task_id: string;
  };
  branding: {
    app_name: string;
    app_description: string;
    copyright: string;
  };
  links: {
    privacy_policy: string;
  };
  auth: {
    unauthorized: string;
    unknown_company: string;
    company_not_found: string;
    no_permission_to_use_feature: string;
    your_account_is_waiting_approval: string;
    please_wait_for_admin_to_verify_your_login: string;
    you_dont_have_permission_to_use_admin_dashboard: string;
    please_contact_your_company_admin: string;
    you_dont_have_permission_to_use_app: string;
    your_account_has_been_closed: string;
    something_went_wrong: string;
    company_locked: string;
    company_pending: string;
    company_is_locked: string;
    company_is_pending: string;
    email_bad_format: string;
    no_user_record: string;
    invalid_pw: string;
    sigin_failed: string;
    enter_email_for_recovery: string;
    forgot_pw: string;
    recovery_email_sent: string;
    please_try_again: string;
    or: string;
    pw: string;
    register_as_user: string;
    register: string;
    pw_dont_match: string;
    insert_email: string;
    enter_pw: string;
    pw_too_short: string;
    pw_six_characters: string;
    confirm_pw: string;
    user_already_exists: string;
    required_fields_login: string;
    signin_with_google: string;
    pw_changed_succesfully: string;
    change_pw: string;
    please_reauhenticate: string;
    google_sign_in_change_pw_error: string;
    enter_new_pw: string;
    enter_old_password: string;
  };
  filters: {
    all_tasks: string;
    all_assigned: string;
    all_unassigned: string;
    all_priorities: string;
    selected_tasks: string;
    all_statuses: string;
    filter_tasks: string;
  };
  time: {
    hours: string;
    hour: string;
    minutes: string;
    minute: string;
    second: string;
    seconds: string;
    milliseconds: string;
    day: string;
    days: string;
    week: string;
    weeks: string;
    month: string;
    months: string;
    year: string;
    years: string;
    msec: string;
    msecs: string;
    sec: string;
    secs: string;
    min: string;
    mins: string;
    hr: string;
    hrs: string;
    dy: string;
    dys: string;
    wk: string;
    wks: string;
    mo: string;
    mos: string;
    yr: string;
    yrs: string;
    millisecond: string;
  };
  localization_codes: {
    postalcode_locale: string;
    mobile_locale: string;
  };
}

const locale_en_UK: Localization = {
  concepts: {
    member: 'Member',
    jobtype: 'Jobtype',
  },
  ui: {
    contact_has_pending_invite: ' This customer has a pending account invitation to view task progress reports',
    contact_has_account: 'This customer has an account and can view task progress reports',
    join: 'Join',
    dashboard: 'Dashboard',
    reports: 'Reports',
    members: 'Members',
    settings: 'Settings',
    phone: 'Phone',
    log_out: 'Log out',
    loading___: 'Loading...',
    log_in: 'Log in',
    profile: 'Profile',
    admin: 'Admin',
    status: 'Status',
    next: 'Next',
    back: 'Back',
    privacy_policy: 'Privacy Policy',
    select_vat: 'No VAT',
    vat_not_defined: 'VAT not defined',
    default: 'Default',
    customers_worksites: "Customer's worksites",
    select_worksite: 'Select worksite',
    no_worksites: 'No worksites available',
    modify_customer: 'Edit customer',
    add_customer: 'Add customer',
    customer_name: 'Customer name',
    customer_number: 'Customer number',
    address: 'Address',
    postalcode: 'Postal code',
    city: 'City',
    customers_contact: "Customer's contact person",
    email: 'Email',
    cancel: 'Cancel',
    save: 'Save',
    customer: 'Customer',
    contact_person: 'Contact person',
    filter_by_jobtype: 'Filter jobtypes',
    filter_by_qualification: 'Filter qualifications',
    filter_on: 'Filter on',
    today: 'Today',
    add_new: 'Add new',
    selected_members: 'Selected members',
    all_members: 'All members',
    unknown_filter: 'Unknown filter',
    order_by_priority: 'Order by priority',
    highest_first: 'Highest first',
    lowest_first: 'Lowest first',
    normal_first: 'Normal first',
    delete: 'Delete',
    delete_selected: 'Delete selected',
    select_multiple: 'Select multiple',
    no_member_selected: 'Free task',
    member_missing_qualification: 'Selected worker is missing qualification ',
    qualification_required: 'Qualification is required for selected jobtype.',
    multiple_qualifications_missing:
      'Selected worker is missing qualifications ',
    multiple_qualifications_required:
      'These are required for selected jobtype.',
    modify_task: 'Edit task',
    new_task: 'New Task',
    member_name: 'Member name',
    worksite_name: 'Worksite name',
    jobtype_name: 'Jobtype name',
    date: 'Date',
    give_valid_date: 'Give valid date',
    time: 'Time',
    give_valid_time: 'Give valid time',
    duration: 'Duration',
    notification: 'Notification',
    no_alarm: 'No alarm',
    fifteen_min_before: '15 minutes before',
    thirty_min_before: '30 minutes before',
    hour_before: 'An hour before',
    unknown_status: 'Unknown status',
    undone: 'Undone',
    active: 'Active',
    paused: 'Paused',
    late: 'Late',
    completed: 'Completed',
    priority: 'Priority',
    unknown_priority: 'Unknown priority',
    high: 'High',
    normal: 'Normal',
    low: 'Low',
    task_name: 'Task name',
    optional_name_for_task: 'Optional name for task',
    notes_and_notices: 'Notes and notices',
    write_notes_here: 'Write notes here...',
    write_message: 'Write message',
    message_must_be_atleast_one: 'Message must contain at least one character.',
    send: 'Send',
    assigned: 'Assigned first',
    unassigned: 'Unassigned first',
    company_name: 'Company name',
    please_enter_company_name: 'Enter company name',
    please_enter_contact_person_name: 'Enter contact person name',
    please_enter_valid_email: 'Enter valid email address',
    please_enter_address: 'Enter address',
    please_enter_postal_code: 'Enter postal code',
    please_enter_city: 'Enter city',
    please_enter_phone_number: 'Enter phone number',
    add: 'Add',
    remove: 'Remove',
    remove_selected: 'Remove selected',
    done: 'Done',
    dashboard_home: 'Home',
    map: 'Map',
    customers: 'Customers',
    worksites: 'Worksites',
    backup: 'Backup',
    fullscreen_mode: 'Fullscreen mode',
    please_enter_valid_phone_number: 'Enter valid phone number',
    please_enter_valid_postal_code: 'Enter valid postalcode',
    work_hours: 'Work hours (HH:mm)',
    vat_base: 'VAT base',
    export_csv: 'Export CSV',
    time_frame: 'Time frame',
    all_customers: 'All customers',
    daily_hours: 'Daily hours',
    total_hours: 'Total hours',
    search_from_jobtypes: 'Search jobtypes',
    unknown_jobtype: 'unknown jobtype',
    error_resources_not_defined: 'Error: Members not defined',
    minutes_before: 'Minutes before',
    no_members: 'No members',
    select_jobtype: 'Select jobtype',
    select_member: 'Select member',
    previous: 'Previous',
    previous_page: 'Previous page',
    next_page: 'Next page',
    zoom_in: 'Zoom In',
    zoom_out: 'Zoom Out',
    add_contact_person: 'Add contact person',
    remove_contact_person: 'Remove contact person',
    contact_persons: 'Contact persons',
    set_this_contact_as_default: 'Set this contact as default',
    hour_type_name: 'Name',
    hour_multiplier: 'Hour type multiplier',
    select_day: 'Set day',
    select_time: 'Set time',
    select_duration: 'Set duration',
    ok: 'OK',
    hour_type_length_must_be: 'Length must be between 3 and 30 chars',
    please_enter_valid_hour_type: 'Enter hour multiplier',
    want_to_delete_hour_type:
      'Are you sure you want to delete selected hour type?',
    want_to_delete_selected_hour_types:
      'Are you sure you want to delete selected hour types?',
    add_hour_type: 'Add new hourtype',
    edit_hour_type: 'Edit hourtype',
    search_for_hour_types: 'Search hour types',
    menu: 'Menu',
    user: 'User',
    feedback_form: 'Feedback form',
    own_tasks: 'My tasks',
    free_tasks: 'Free tasks',
    done_tasks: 'Done',
    please_enter_nick_name: 'Enter nickname',
    further_information: 'Further information',
    nick_name: 'Nickname',
    please_enter_worksite_name: 'Enter name of the work site',
    required_fields: '* marked fields are required',
    modify_worksite: 'Edit worksite',
    new_worksite: 'New worksite',
    postalcode_length_must_be: 'Postal code must 5 characters long',
    please_enter_further_informations: 'Enter further informations',
    no_tasks: 'No tasks found',
    timeline: 'Timeline',
    taskbank: 'Tasklist',
    min_date_message: 'Date should not be before minimal date\n\n',
    clear: 'Clear',
    give_valid_duration: 'Invalid duration',
    max_date_message: 'Date should not be after maximal date',
    in_worksite: 'in worksite',
    minutes_after: 'minutes from now',
    general_settings: 'General settings',
    jobtypes: 'Job types',
    qualifications: 'Qualifications',
    VAT: 'VAT',
    hourtypes: 'Hour types',
    advanced: 'Advanced',
    worksite_name_length_must_be:
      'Name length must be between 3 and 60 characters long',
    worksite_address_length_must_be:
      'Address length must be between 5 and 90 characters long',
    worksite_city_length_must_be:
      'City length must be between 2 and 30 characters long',
    worksite_further_information_length_must_be:
      'Further information length maximum is 50 characters',
    search_members: 'Search members',
    name: 'Name',
    report: 'Report',
    role: 'Role',
    proficiencies: 'Proficiencies',
    show_report: 'Show report',
    name_too_short: 'Name too short',
    invalid_email: 'Invalid email',
    email_already_in_use: 'Email already in use',
    invalid_phone: 'Invalid phone',
    edit_member: 'Edit member',
    new_member: 'New member',
    working_time: 'Working time',
    rows_per_page: 'Rows per page',
    selected_member: 'Selected member',
    delete_task: 'Delete task',
    delete_dialog_confirm: 'Are you sure you want to delete this task?',
    caution: 'Caution!',
    continue: 'Continue',
    used_time: 'Used time',
    task_info: 'Task info',
    worksite_additional_info: 'Worksite additional details',
    chat: 'Chat',
    another_task_active: 'Another task is already in progress',
    start_another_task_question:
      'Do you want to start a new task? This will pause the task that you currently have in progress.',
    assign_to_me: 'Assign to me',
    pause: 'Pause',
    start: 'Start',
    modify: 'Edit',
    free_this_task: 'Free task',
    free_dialog_confirm: 'Are you sure you want to free this task?',
    no_customer: 'No customer',
    no_worksite: 'No worksite',
    choose_all: 'Select all',
    delete_selections: 'Delete selections',
    search_jobtype: 'Search jobtype',
    no_jobtype: 'No jobtype',
    select_customer: 'Select customer',
    customer_name_length_must_be:
      'Name length must be between 2 and 30 characters long',
    customer_number_length_must_be:
      'Customer number length must be between 1 and 30 characters',
    customer_address_length_must_be:
      'Address length must be between 2 and 30 characters',
    customer_city_length_must_be:
      'City length must be between 2 and 30 characters',
    instructions_and_files: 'Files and instructions',
    save_changes: 'Save changes',
    cancel_changes: 'Undo changes',
    unsaved_changes: 'You have unsaved changes. Do you want to continue?',
    basic_information: 'Basic information',
    ui_options: 'User interface options',
    additional_field_visibility: 'Additional field visibility',
    worksite: 'Worksite',
    worksite_nickname: 'Worksite nickname',
    type_company_name: 'Invalid company name',
    type_contactperson_name: 'Invalid contact person name',
    type_correct_email: 'Invalid email',
    type_address: 'Invalid address',
    type_postalcode: 'Invalid postal code',
    type_city: 'Invalid city name',
    type_phonenumber: 'Invalid phone number',
    search_jobtypes: 'Search jobtypes',
    search_qualifications: 'Search qualifications',
    search_vat: "Search VAT's",
    add_jobtype: 'Add jobtype',
    modify_jobtype: 'Edit jobtype',
    add_qualification: 'Add qualification',
    modify_qualification: 'Edit qualification',
    add_vat: 'Add VAT',
    modify_vat: 'Edit VAT',
    search_worksites: 'Search worksites',
    search_customers: 'Search customers',
    map_temp_text: 'Map is under development and will be released soon!',
    delete_jobtype_confirm: 'Are you sure you want to delete selected jobtype?',
    delete_selected_jobtypes_confirm:
      'Are you sure you want to delete selected jobtypes?',
    delete_qualification_confirm:
      'Are you sure you want to delete selected qualification?',
    delete_selected_qualifications_confirm:
      'Are you sure you want to delete selected qualifications?',
    delete_VAT_confirm: 'Are you sure you want to delete selected VAT?',
    delete_selected_VATs_confirm:
      "Are you sure you want to delete selected VAT's?",
    delete_task_confirm: 'Are you sure you want to delete selected task',
    language_select: 'Language select',
    percent: 'Percent',
    action_cant_be_cancelled: "This action can't be cancelled",
    account: 'Account',
    company: 'Company',
    notifications: 'Notifications',
    search: 'Search',
    required_qualifications: 'Required qualifications',
    instructions: 'Instructions',
    task_files: 'Task attached files',
    role_admin: 'Administrator',
    role_user: 'User',
    role_awaiting: 'Pending',
    role_closed: 'Closed',
    no_data_available: 'No data available',
    weekday: 'Weekdaily',
    day: 'Date',
    week: 'Weekly',
    month: 'Monthly',
    repetition: 'Repetition',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    day_of_the_week: 'Day of the week',
    starting_time: 'Starting time',
    ending_time: 'Ending time',
    delete_worksite_confirm:
      'Are you sure you want to delete selected worksite',
    delete_worksite: 'Delete worksite',
    company_tag: 'Company tag',
    userinterface_settings: 'User interface settings',
    company_data: 'Company information',
    company_tag_taken: 'Company tag taken, edit company tag ',
    business_id: 'Business ID',
    please_enter_valid_businessid: 'Please enter valid business ID',
    new_company: 'New company',
    new_company_tag: 'new_company',
    no_chosen_company: 'No selected companies',
    no_companies_available: 'No companies available',
    companies: 'Companies',
    user_count: 'User quantity',
    lock_company: 'Lock the company',
    please_enter_valid_company_tag: 'Please enter valid company tag',
    task_report: 'Task report',
    task_progress_report: 'Task progress report',
    working_time_report: 'Working time report',
    add_member: 'Add member',
    open: 'Open',
    delete_selection: 'Delete selection',
    task: 'Task',
    in_progress: 'in progress',
    unsavedcontent: 'You have unsaved content. Do you want to continue?',
    checking_for_updates: 'Checking for updates',
    loading_update: 'Loading update',
    installing_update: 'Installing update',
    up_to_date: 'Up to date',
    update_installed: 'Update installed',
    something_went_wrong: 'Something went wrong. Update failed',
    worksite_tasks: "Worksite's tasks",
    update_available: 'A new update is available',
    download_required: 'Download is required to proceed',
    update_is_required: 'Update is required to continue using this app.',
    unlock_company: 'Unlock company',
    started: 'Started',
    no_tasks_within_timeframe: 'No tasks within selected timeframe',
    company_lock_confirm: 'Are you sure you want to lock this company?',
    company_unlock_confirm: 'Are you sure you want to unlock this company?\n',
    search_from_map: 'Search from map',
    search_results: 'Search results',
    search_optional: 'Search',
    finished: 'Finished',
    all: 'All',
    paused_tasks: 'Paused',
    no_coordinates: 'No coordinates available',
    task_has_hours_confirm:
      'This task has some logged hours. It will be archived and accessible through reports but hidden from task view. Proceed?',
    jobtype_already_exists: 'This jobtype already exists',
    total: 'Total',
    recorded_hours: 'Recorded hours',
    working_hours: 'Working hours',
    enter_hours: 'New entry',
    arrival_time: 'Arrival time',
    departure_time: 'Departure time',
    write_notes: 'Write notes',
    logged_hours: 'Logged hours',
    no_logged_hours: 'No logged hours',
    date_not_available: 'Date not available',
    whole_hours_example: 'e.g. 7:30',
    hourtype: 'Hourtype',
    year: 'Year',
    cancel_editing: 'Do you want to cancel editing?',
    close: 'Close',
    changelog: 'Changelog',
    change: 'Change',
    edit_hours: 'Edit entry',
    or: 'OR',
    hourtype_exists:
      'You already have logged entry with this hourtype. Edit the existing entry!',
    generating_csv: 'Generating CSV',
    press_to_export_csv: 'Press the button to export CSV',
    generating_csv_done: 'Generating CSV done',
    some_results_are_filtered: 'Some results are filtered',
    daily: 'Daily',
    timeline_settings: 'Timeline settings',
    set_the_time_frame_for_timeline_view:
      'Set the time frame for timeline view',
    start_noun: 'Start',
    end_noun: 'End',
    add_new_worksite: 'Add new worksite',
    choose_location: 'Pick location from the map',
    postal_code_length_must_be_long:
      'Postalcode must be less than 18 characters long',
    lack_qualification_warning: 'Worker lacks qualification for the task!',
    delete_logged_hours_confirm:
      'Are you sure you want to delete these logged hours?',
    removed: 'Removed',
    sorting: 'Sorting',
    sorting_options: 'Sorting options',
    hide_selected: 'Hide selected',
    unhide_selected: 'Unhide selected',
    total_working_hours_per_day_max_24h:
      'Total working hours for one day can not be more than 24 hours.',
    cannot_log_more_hours_please_edit_existing_entries:
      "You can't log more hours. Please edit existing entries.",
    date_time_warning_message:
      'If Date, Time or Duration is left empty, the task is not shown on Timeline view',
    missing_member_warning_message:
      'If Member is left empty, the task is not shown on Timeline view',
    missing_task_requirements:
      'If Member, Date, Time or Duration is left empty, the task is not shown on Timeline view. Are you sure you want to save?',
    you_can_log_max_hhmm_for_this_entry:
      'You can log max. {{available}} for this entry.',
    week_single: 'Week',
    set_date_to_create_repetitive:
      'Set date in order to create repetitive task',
    too_many_tasks_error:
      'The amount of tasks about to be created is larger than allowed (365)',
    update_recurring_task: 'Update recurring task',
    delete_recurring_task: 'Delete recurring task',
    this_task: 'This task',
    this_task_and_following: 'This task and following',
    repeating_task_member_change_warning:
      'of the tasks are active and can not have their Member changed. Do you want to allow the change for the rest of the tasks?',
    yes: 'Yes',
    shift_save_warning_tooltip:
      'Select Member and Shift Template to be able to save',
    new_shift: 'New shift',
    select_shift: 'Select shift',
    warning: 'Warning',
    add_task: 'Add task',
    add_shift: 'Add shift',
    search_shifts: 'Search shifts',
    delete_shift_confirmation: 'Are you sure you want to delete this shift?',
    delete_multiple_shifts_confirmation:
      'Are you sure you want to delete these shifts?',
    edit_shift: 'Edit shift',
    shift_name: 'Shift name',
    no_timeframe_available: 'No timeframe available',
    add_task_to_shift: 'Add task to a shift',
    edit_task_in_shift: 'Edit task in a shift',
    missing_start_and_end: 'Start and end time values are required',
    startdate_later_enddate:
      'Start date can not be equal or later than end date!',
    shift_info: 'Shift',
    shifts: 'Shifts',
    shift_tasks: 'Shift tasks',
    repeating_task_active_status_delete_warning:
      'of the tasks are active and can not be deleted. Do you want to delete the rest of the tasks?',
    cannot_delete_active_tasks: 'Cannot delete active tasks',
    member_cant_be_changed_when_task_active:
      'Member can not be changed when the task is active',
    update_existing_tasks_caution:
      'Changing this shift will also change already existing ones on timeline. Changes will not be applied to active shift tasks, manually modified shift tasks or shifts in the past. Are you sure you want to save these changes?',
    task_outside_shift_warning:
      "Can't move task inside shift to another member",
    start_date_for_repeating_tasks_can_not_be_changed:
      'Start date for repeating tasks can not be changed',
    delete_from_shift: 'Delete from shift',
    this_shift: 'This shift',
    delete_from_recurring_shift: 'Delete from recurring shift',
    this_shift_and_following: 'This shift and following',
    all_shifts_in_repetition: 'All shifts in this repetition',
    set_task_nextday: 'Set task to next day',
    next_day_tooltip:
      "Checking this will set the starting and ending times of this task to the following day. Useful when creating night shifts that start in the evening of one date and continue into the night of the following date. When creating task that goes over midnight, you don't need to check this box.",
    next_day: 'Next day',
    only_one_task_active_change_applied_for_edited_task:
      'Only one task can be Active at a time. Change applied for the edited task only.',
    filter_by_proficiency: 'Filter proficiencies',
    members_not_found: 'Members not found.',
    end_date: 'End date',
    import_tasks: 'Import tasks',
    import: 'Import',
    import_confirm: 'Are you sure you want to import',
    tasks: 'tasks',
    new_worksites_and_jobtypes_will_be_created_automatically:
      'New worksites and jobtypes will be created automatically.',
    something_went_wrong_check_csv:
      'Something went wrong, check csv file for any errors.',
    import_complete: 'Import complete.',
    will_be_assigned_to: 'Will be assigned to',
    member_not_found_task_will_be_set_as_free:
      'Member not found, task will be set as free',
    free_task: 'Free task',
    preview: 'Preview',
    no_location_set: 'No location set',
    task_will_be_carried_out_for_customer:
      'Task will be carried out for customer',
    business_id_not_found_creating_new:
      'Business ID not found from database, new customer will be created by using business ID',
    name_can_be_changed_later_from_customers_panel:
      'Name can be changed later from customers panel.',
    business_id_not_set: 'Business ID not set',
    import_filled_csv_chart: 'Import your filled csv-form.',
    select_file: 'Select file',
    download_example_csv_import_form: 'Download example csv-import form.',
    download: 'Download',
    downloaded: 'Downloaded',
    error: 'Error',
    tips_to_fill_out_the_form: 'Tips to fill out the form',
    customer_task_id_tip:
      'In the Task ID field, you can enter an ID to help users distinguish between similar tasks.',
    only_required_field_is_jobtype: 'Only required field is jobtype.',
    there_are_many_options_to_fill_timeframe:
      'You can use several different options when setting the task interval',
    weeknumber_creates_flexible_task_tip:
      'Week number creates flexible task to chosen week.',
    date_format_tip:
      'In the Date field you can input only the date (DD.MM.YYYY) or also fill out the Start and End fields (HH:mm)',
    start_end_time_tip:
      'You can also input accurate time straight into Start and End fields (DD.MM.YYYY HH:mm).',
    new_worksites_customers_jobtypes_are_created_tip:
      'New worksites, customers and job types will be created automatically during import process.',
    change_working_time_for_user: 'Change working time for the user',
    new_working_time: 'New working time',
    previous_working_time: 'Previous working time',
    multiple_users: 'Multiple users',
    unknown_user: 'Unknown user',
    task_id: 'Task ID',
    edited_user: 'Edited user',
    select_status: 'Select status',
    last_day: 'Last day',
    last_week: 'Last week',
    last_month: 'Last month',
    no_tasks_within_timeframe_and_filters:
      'No tasks within selected timeframe and filters',
    week_text: 'Week',
    deselect_all: 'Deselect all',
    no_task_id: 'No task ID',
  },
  branding: {
    app_name: 'MOWO',
    app_description: 'Mobile Workflow Management',
    copyright: '© 2019 Observis Oy.',
  },
  links: {
    privacy_policy: 'https://www.observis.fi/legal/MOWO-pp-en.pdf',
  },
  auth: {
    unauthorized: 'Not authorized',
    unknown_company: 'Unknown company',
    company_not_found: 'Company was not found.',
    no_permission_to_use_feature:
      "You don't have permission to use this feature",
    your_account_is_waiting_approval: 'Your account is waiting approval',
    please_wait_for_admin_to_verify_your_login:
      'Please wait for administrator to verify your login.',
    you_dont_have_permission_to_use_admin_dashboard:
      "You don't have permission to use admin dashboard.",
    please_contact_your_company_admin:
      'Please contact administrator of your company.',
    you_dont_have_permission_to_use_app:
      'You do not have permission to use this app. Please contact administrator of your company.',
    your_account_has_been_closed: 'Your account has been closed',
    something_went_wrong:
      'Something went wrong. Please try logging in again or contact your company administrator.',
    company_locked: 'Company is locked',
    company_pending: 'Company is pending',
    company_is_locked:
      'Company is locked from users. Please contact your company person for more information.',
    company_is_pending:
      'Company is pending. Please try again later or contact us for more information if problem continues.',
    email_bad_format: 'Email is incorrectly formatted',
    no_user_record: 'User with this email was not found',
    invalid_pw: 'Invalid password',
    sigin_failed: 'Sign in failed',
    enter_email_for_recovery: 'Enter email for password recovery',
    forgot_pw: 'Forgot password?',
    recovery_email_sent: 'Recovery email sent',
    please_try_again: 'Please try again.',
    or: 'OR',
    pw: 'Password',
    register_as_user: 'Register as user',
    register: 'Register',
    pw_dont_match: "Passwords don't match",
    insert_email: 'Enter email address',
    enter_pw: 'Enter password and confirmation',
    pw_too_short: 'Password is too short',
    pw_six_characters: 'Password must be at least 6 characters.',
    confirm_pw: 'Confirm password',
    user_already_exists: 'User with this email already exists',
    required_fields_login: 'Please enter missing login information',
    signin_with_google: 'Sign in with Google',
    pw_changed_succesfully: 'Password changed successfully!',
    change_pw: 'Change password',
    please_reauhenticate:
      'To change password we need you to re-auhenticate this session',
    google_sign_in_change_pw_error:
      'To change password for Google signed in account do it in Google settings.',
    enter_new_pw: 'Enter new password',
    enter_old_password: 'Enter old password',
  },
  filters: {
    all_tasks: 'All tasks',
    all_assigned: 'All assigned tasks',
    all_unassigned: 'All unassigned tasks',
    all_priorities: 'All priorities',
    selected_tasks: 'Part of tasks',
    all_statuses: 'All statuses',
    filter_tasks: 'Filter tasks',
  },
  time: {
    hours: 'hours',
    hour: 'hour',
    minutes: 'minutes',
    minute: 'minute',
    second: 'second',
    seconds: 'seconds',
    milliseconds: 'milliseconds',
    day: 'day',
    days: 'days',
    week: 'week',
    weeks: 'weeks',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    msec: 'msec',
    msecs: 'msecs',
    sec: 'sec',
    secs: 'secs',
    min: 'min',
    mins: 'mins',
    hr: 'hr',
    hrs: 'hrs',
    dy: 'dy',
    dys: 'dys',
    wk: 'wk',
    wks: 'wks',
    mo: 'mo',
    mos: 'mos',
    yr: 'yr',
    yrs: 'yrs',
    millisecond: 'millisecond',
  },
  localization_codes: {
    postalcode_locale: 'GB',
    mobile_locale: 'en-GB',
  },
};

const locale_fi_FI: Localization = {
  concepts: {
    member: 'Käyttäjä',
    jobtype: 'Työlaji',
  },
  ui: {
    contact_has_pending_invite: ' This customer has a pending account invitation to view task progress reports',
    contact_has_account: 'This customer has an account and can view task progress reports',
    join: 'Liity',
    dashboard: 'Työpöytä',
    reports: 'Raportit',
    members: 'Käyttäjät',
    settings: 'Asetukset',
    phone: 'Puhelinnumero',
    log_out: 'Kirjaudu ulos',
    loading___: 'Ladataan...',
    log_in: 'Kirjaudu',
    profile: 'Profiili',
    admin: 'Ylläpito',
    status: 'Tila',
    next: 'Seuraava',
    back: 'Takaisin',
    privacy_policy: 'Tietosuojakäytäntö',
    select_vat: 'Ei ALV',
    vat_not_defined: 'ALV ei määritelty',
    default: 'Oletus',
    customers_worksites: 'Asiakkaan kohteet',
    select_worksite: 'Valitse kohde',
    no_worksites: 'Kohteita ei saatavilla',
    modify_customer: 'Muokkaa asiakasta',
    add_customer: 'Lisää asiakas',
    customer_name: 'Asiakkaan nimi',
    customer_number: 'Asiakasnumero',
    address: 'Osoite',
    postalcode: 'Postinumero',
    city: 'Kaupunki',
    customers_contact: 'Asiakkaan yhteyshenkilö',
    email: 'Sähköposti',
    cancel: 'Peruuta',
    save: 'Tallenna',
    customer: 'Asiakas',
    contact_person: 'Yhteyshenkilö',
    filter_by_jobtype: 'Suodata työlajeja',
    filter_by_qualification: 'Suodata pätevyyksiä',
    filter_on: 'Suodatus päällä',
    today: 'Tänään',
    add_new: 'Lisää uusi',
    selected_members: 'Osa käyttäjistä',
    all_members: 'Kaikki käyttäjät',
    unknown_filter: 'Tunnistamaton suodatin',
    order_by_priority: 'Järjestä prioriteetin mukaan',
    highest_first: 'Korkein ensin',
    lowest_first: 'Matalin ensin',
    normal_first: 'Normaali ensin',
    delete: 'Poista',
    delete_selected: 'Poista valitut',
    select_multiple: 'Valitse useita',
    no_member_selected: 'Vapaa tehtävä',
    member_missing_qualification: 'Valitulta käyttäjältä puuttuu pätevyys ',
    qualification_required: 'Pätevyyttä tarvitaan valittuun työlajiin.',
    multiple_qualifications_missing:
      'Valitulta käyttäjältä puuttuu pätevyydet ',
    multiple_qualifications_required: 'Näitä tarvitaan valittuun työlajiin.',
    modify_task: 'Muokkaa tehtävää',
    new_task: 'Uusi tehtävä',
    member_name: 'Käyttäjän nimi',
    worksite_name: 'Kohteen nimi',
    jobtype_name: 'Työlajin nimi',
    date: 'Pvm',
    give_valid_date: 'Anna oikea päivämäärä',
    time: 'Aika',
    give_valid_time: 'Anna oikea kellonaika',
    duration: 'Kesto',
    notification: 'Muistutus',
    no_alarm: 'Ei hälytystä',
    fifteen_min_before: '15 minuuttia ennen',
    thirty_min_before: '30 minuuttia ennen',
    hour_before: 'Tuntia ennen',
    unknown_status: 'Tunnistamaton tila',
    undone: 'Tekemättä',
    active: 'Aktiivinen',
    paused: 'Keskeytetty',
    late: 'Myöhässä',
    completed: 'Valmis',
    priority: 'Prioriteetti',
    unknown_priority: 'Tunnistamaton prioriteetti',
    high: 'Korkea',
    normal: 'Normaali',
    low: 'Matala',
    task_name: 'Tehtävän nimi',
    optional_name_for_task: 'Valinnainen nimi tehtävälle',
    notes_and_notices: 'Muistiinpanot ja huomiot',
    write_notes_here: 'Kirjaa lisähuomiot tähän...',
    write_message: 'Kirjoita viesti',
    message_must_be_atleast_one:
      'Viestin pituuden tulee olla vähintään 1 merkki.',
    send: 'Lähetä',
    assigned: 'Varatut ensin',
    unassigned: 'Vapaat ensin',
    company_name: 'Yrityksen nimi',
    please_enter_company_name: 'Syötä yrityksen nimi',
    please_enter_contact_person_name: 'Syötä yhteyshenkilön nimi',
    please_enter_valid_email: 'Syötä oikea sähköpostiosoite',
    please_enter_address: 'Syötä osoite',
    please_enter_postal_code: 'Syötä postinumero',
    please_enter_city: 'Syötä kaupunki',
    please_enter_phone_number: 'Syötä puhelinnumero',
    add: 'Lisää',
    remove: 'Poista',
    remove_selected: 'Poista valitut',
    done: 'Valmis',
    dashboard_home: 'Tilanne',
    map: 'Kartta',
    customers: 'Asiakkaat',
    worksites: 'Kohteet',
    backup: 'Varmuuskopio',
    fullscreen_mode: 'Koko näytön tila',
    please_enter_valid_phone_number: 'Syötä oikea puhelinnumero',
    please_enter_valid_postal_code: 'Syötä oikea postinumero',
    work_hours: 'Työtunnit (HH:mm)',
    vat_base: 'ALV-kanta',
    export_csv: 'Vie CSV',
    time_frame: 'Aikaväli',
    all_customers: 'Kaikki asiakkaat',
    daily_hours: 'Päivittäistunnit',
    total_hours: 'Tunteja yhteensä',
    search_from_jobtypes: 'Hae työlajeista',
    unknown_jobtype: 'tunnistamaton työlaji',
    error_resources_not_defined: 'Virhe: Käyttäjiä ei määritelty',
    minutes_before: 'Minuuttia ennen',
    no_members: 'Ei käyttäjiä',
    select_jobtype: 'Valitse työlaji',
    select_member: 'Valitse käyttäjä',
    previous: 'Edellinen',
    previous_page: 'Edellinen sivu',
    next_page: 'Seuraava sivu',
    zoom_in: 'Lähennä',
    zoom_out: 'Loitonna',
    add_contact_person: 'Lisää yhteyshenkilö',
    remove_contact_person: 'Poista yhteyshenkilö',
    contact_persons: 'Yhteyshenkilöt',
    set_this_contact_as_default:
      'Aseta tämä yhteyshenkilö näytettäväksi oletuksena',
    hour_type_name: 'Nimi',
    hour_multiplier: 'Tuntikerroin',
    select_day: 'Valitse päivä',
    select_time: 'Valitse aika',
    select_duration: 'Valitse kesto',
    ok: 'OK',
    hour_type_length_must_be: 'Pituuden tulee olla väliltä 3-30 merkkiä',
    please_enter_valid_hour_type: 'Syötä tuntikerroin',
    want_to_delete_hour_type: 'Haluatko varmasti poistaa valitun tuntityypin?',
    want_to_delete_selected_hour_types:
      'Haluatko varmasti poistaa valitut tuntityypit?',
    add_hour_type: 'Lisää uusi tuntityyppi',
    edit_hour_type: 'Muokkaa tuntityyppiä',
    search_for_hour_types: 'Hae tuntityypeistä',
    menu: 'Valikko',
    user: 'Käyttäjä',
    feedback_form: 'Palautelomake',
    own_tasks: 'Omat',
    free_tasks: 'Vapaat',
    done_tasks: 'Valmiit',
    please_enter_nick_name: 'Syötä lempinimi',
    further_information: 'Lisätietoja',
    nick_name: 'Lempinimi',
    please_enter_worksite_name: 'Syötä kohteen nimi',
    required_fields: '* merkityt kentät ovat pakollisia',
    modify_worksite: 'Muokkaa kohdetta',
    new_worksite: 'Uusi kohde',
    postalcode_length_must_be: 'Postinumeron tulee olla 5 merkkiä pitkä',
    please_enter_further_informations: 'Syötä lisätietoja',
    no_tasks: 'Ei tehtäviä',
    timeline: 'Aikajana',
    taskbank: 'Tehtävälista',
    min_date_message: 'Antamasi päivämäärä on liian kaukainen',
    clear: 'Tyhjennä',
    give_valid_duration: 'Anna oikea kesto',
    max_date_message: 'Antamasi päivämäärä on liian kaukainen',
    in_worksite: 'kohteessa',
    minutes_after: 'minuutin kuluttua',
    general_settings: 'Yleiset asetukset',
    jobtypes: 'Työlajit',
    qualifications: 'Pätevyydet',
    VAT: 'ALV',
    hourtypes: 'Tuntityypit',
    advanced: 'Edistyneet',
    worksite_name_length_must_be:
      'Nimen on oltava min 3 ja max 60 merkkiä pitkä',
    worksite_address_length_must_be:
      'Osoitteen tulee olla min 5 ja max 90 merkkiä pitkä',
    worksite_city_length_must_be:
      'Kaupungin tulee olla min 2 ja max 30 merkkiä pitkä',
    worksite_further_information_length_must_be:
      'Lisätietojen maksimi pituus on 50 merkkiä',
    search_members: 'Hae käyttäjistä',
    name: 'Nimi',
    report: 'Raportti',
    role: 'Rooli',
    proficiencies: 'Osaamiset',
    show_report: 'Näytä raportti',
    name_too_short: 'Nimi liian lyhyt',
    invalid_email: 'Virheellinen sähköpostiosoite',
    email_already_in_use: 'Sähköposti on jo käytössä',
    invalid_phone: 'Virheellinen puhelinnumero',
    edit_member: 'Muokkaa käyttäjää',
    new_member: 'Uusi käyttäjä',
    working_time: 'Työaika',
    rows_per_page: 'Riviä per sivu',
    selected_member: 'Valittu käyttäjä',
    delete_task: 'Poista tehtävä',
    delete_dialog_confirm: 'Haluatko varmasti poistaa tehtävän?',
    caution: 'Huomio!',
    continue: 'Jatka',
    used_time: 'Käytetty aika',
    task_info: 'Tehtävän tiedot',
    worksite_additional_info: 'Kohteen lisätiedot',
    chat: 'Chat',
    another_task_active: 'Toinen tehtävä on vielä käynnissä',
    start_another_task_question:
      'Haluatko käynnistää uuden tehtävän? Tämä pysäyttää edellisen käynnissä olevan tehtävän.',
    assign_to_me: 'Varaa tehtävä itselle',
    pause: 'Keskeytä',
    start: 'Aloita',
    modify: 'Muokkaa',
    free_this_task: 'Vapauta',
    free_dialog_confirm: 'Haluatko varmasti vapauttaa tehtävän?',
    no_customer: 'Ei asiakasta',
    no_worksite: 'Ei kohdetta',
    choose_all: 'Valitse kaikki',
    delete_selections: 'Poista valinnat',
    search_jobtype: 'Hae työlajia',
    no_jobtype: 'Ei työlajia',
    select_customer: 'Valitse asiakas',
    customer_name_length_must_be:
      'Nimen tulee olla vähintään 2 ja maksimissaan 30 merkkiä pitkä',
    customer_number_length_must_be:
      'Asiakasnumeron tulee olla vähintään 1 ja maksimissaan 30 merkkiä pitkä',
    customer_address_length_must_be:
      'Osoitteen tulee olla vähintään 2 ja maksimissaan 30 merkkiä pitkä',
    customer_city_length_must_be:
      'Kaupungin tulee olla vähintään 2 ja maksimissaan 30 merkkiä pitkä',
    instructions_and_files: 'Ohjeet ja tiedostot',
    save_changes: 'Tallenna muutokset',
    cancel_changes: 'Peru muutokset',
    unsaved_changes: 'Sinulla on tallentamattomia muutoksia. Haluatko jatkaa?',
    basic_information: 'Perustiedot',
    ui_options: 'Käyttöliittymäasetukset',
    additional_field_visibility: 'Lisäkenttien näkyvyys',
    worksite: 'Kohde',
    worksite_nickname: 'Kohteen lempinimi',
    type_company_name: 'Syötä yrityksen nimi',
    type_contactperson_name: 'Syötä yhteyshenkilön nimi',
    type_correct_email: 'Syötä oikea sähköposti',
    type_address: 'Syötä osoite',
    type_postalcode: 'Syötä sähköposti',
    type_city: 'Syötä kaupunki',
    type_phonenumber: 'Syötä puhelinnumero',
    search_jobtypes: 'Hae työlajeista',
    search_qualifications: 'Hae pätevyyksistä',
    search_vat: 'Hae ALV:sta',
    add_jobtype: 'Lisää työlaji',
    modify_jobtype: 'Muokkaa työlajia',
    add_qualification: 'Lisää pätevyys',
    modify_qualification: 'Muokkaa pätevyyttä',
    add_vat: 'Lisää ALV',
    modify_vat: 'Muokkaa ALV:ta',
    search_worksites: 'Hae kohteista',
    search_customers: 'Hae asiakkaista',
    map_temp_text: 'Karttatoiminnallisuutta kehitetään ja se julkaistaan pian!',
    delete_jobtype_confirm: 'Haluatko varmasti poistaa työlajin?',
    delete_selected_jobtypes_confirm:
      'Haluatko varmasti poistaa valitut työlajit?',
    delete_qualification_confirm: 'Haluatko varmasti poistaa pätevyyden?',
    delete_selected_qualifications_confirm:
      'Haluatko varmasti poistaa valitut pätevyydet?',
    delete_VAT_confirm: 'Haluatko varmasti poistaa ALV:n?',
    delete_selected_VATs_confirm: 'Haluatko varmasti poistaa valitut ALV:t?',
    delete_task_confirm: 'Haluatko varmasti poistaa tehtävän',
    language_select: 'Kielivalinta',
    percent: 'Prosentti',
    action_cant_be_cancelled: 'Tätä toimenpidettä ei voi peruttaa',
    account: 'Tili',
    company: 'Yritys',
    notifications: 'Ilmoitukset',
    search: 'Etsi',
    required_qualifications: 'Vaaditut pätevyydet',
    instructions: 'Ohjeistus',
    task_files: 'Tehtävään liittyvät tiedostot',
    role_admin: 'Ylläpitäjä',
    role_user: 'Peruskäyttäjä',
    role_awaiting: 'Odottaa hyväksyntää',
    role_closed: 'Suljettu',
    no_data_available: 'Ei dataa saatavilla',
    weekday: 'Arkipäivä',
    day: 'Päivä',
    week: 'Viikko',
    month: 'Kuukausi',
    repetition: 'Toistuvuus',
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai',
    day_of_the_week: 'Viikonpäivä',
    starting_time: 'Alkaa',
    ending_time: 'Päättyy',
    delete_worksite_confirm: 'Haluatko varmasti poistaa kohteen',
    delete_worksite: 'Poista kohde',
    company_tag: 'Yrityksen tunnus',
    userinterface_settings: 'Käyttöliittymä asetukset',
    company_data: 'Yritykset tiedot',
    company_tag_taken: 'Tunniste varattu, muokkaa tunnistetta',
    business_id: 'Y-tunnus',
    please_enter_valid_businessid: 'Syötä oikean muotoinen Y-tunnus',
    new_company: 'Uusi yritys',
    new_company_tag: 'uusi_yritys',
    no_chosen_company: 'Ei valittua yritystä',
    no_companies_available: 'Ei yrityksia saatavilla',
    companies: 'Yritykset',
    user_count: 'Käyttäjien määrä',
    lock_company: 'Lukitse yritys',
    please_enter_valid_company_tag: 'Syötä oikean muotoinen yritys tunnus',
    task_report: 'Tehtäväraportti',
    task_progress_report: 'Task progress report',
    working_time_report: 'Työaikaraportti',
    add_member: 'Lisää työntekijä',
    open: 'Avaa',
    delete_selection: 'Poista valinta',
    task: 'Tehtävä',
    in_progress: 'käynnissä',
    unsavedcontent: 'Sinulla on tallentamattomia tietoja. Haluatko jatkaa?',
    checking_for_updates: 'Tarkistetaan päivityksiä',
    loading_update: 'Ladataan päivitystä',
    installing_update: 'Asennetaan päivitystä',
    up_to_date: 'Ajan tasalla',
    update_installed: 'Päivitys asennettu',
    something_went_wrong: 'Jotain meni vikaan. Päivitys epäonnistui',
    worksite_tasks: 'Kohteen tehtävät',
    update_available: 'Uusi päivitys on saatavilla',
    download_required: 'Lataus vaaditaan jatkamiseen',
    update_is_required: 'Päivitä sovellus jatkaaksesi sen käyttöä.',
    unlock_company: 'Poista lukitus',
    started: 'Aloitus',
    no_tasks_within_timeframe: 'Ei tehtäviä valitulla aikavälillä',
    company_lock_confirm: 'Oletko varma että haluat lukita yrityksen?',
    company_unlock_confirm: 'Oletko varma että haluat poistaa lukituksen?',
    search_from_map: 'Hae kartalta',
    search_results: 'Hakutulokset',
    search_optional: 'Hae',
    finished: 'Valmistunut',
    all: 'Kaikki',
    paused_tasks: 'Keskeneräiset',
    no_coordinates: 'Ei koordinaatteja saatavilla',
    task_has_hours_confirm:
      'Tehtävällä on kirjattuja tunteja. Tehtävä arkistoidaan, ja se näkyy yhä raporteissa, mutta piilotetaan tehtäväsivulta. Haluatko jatkaa?',
    jobtype_already_exists: 'Tämä työlaji on jo olemassa',
    total: 'Yhteensä',
    recorded_hours: 'Kirjatut tunnit',
    working_hours: 'Tuntikirjaus',
    enter_hours: 'Uusi tuntikirjaus',
    arrival_time: 'Tuloaika',
    departure_time: 'Lähtöaika',
    write_notes: 'Kirjaa huomioitavat asiat',
    logged_hours: 'Kirjatut tunnit',
    no_logged_hours: 'Ei kirjattuja työaikoja',
    date_not_available: 'Päivämäärä ei saatavissa',
    whole_hours_example: 'Esim. 7:30',
    hourtype: 'Tuntityyppi',
    year: 'Vuosi',
    cancel_editing: 'Haluatko keskeyttää muokkauksen?',
    close: 'Sulje',
    changelog: 'Muokkaushistoria',
    change: 'Muutos',
    edit_hours: 'Muokkaa tuntikirjausta',
    or: 'TAI',
    hourtype_exists:
      'Tuntityypillä on jo kirjauksia. Muokkaa olemassa olevaa kirjausta!',
    generating_csv: 'Luodaan CSV:tä',
    press_to_export_csv: 'Paina painiketta ladataksesi CSV tiedoston',
    generating_csv_done: 'CSV:n luonti valmis',
    some_results_are_filtered: 'Osa tuloksista on suodattu pois',
    daily: 'Päivä',
    timeline_settings: 'Aikajanan asetukset',
    set_the_time_frame_for_timeline_view:
      'Valitse aikajanalla näytettävä aikaväli',
    start_noun: 'Alku',
    end_noun: 'Loppu',
    add_new_worksite: 'Lisää uusi kohde',
    choose_location: 'Valitse sijainti kartalta',
    postal_code_length_must_be_long:
      'Postinumero voi olla enintään 18 merkkiä pitkä',
    lack_qualification_warning:
      'Valitulta tekijältä puuttuu vaadittava pätevyys!',
    delete_logged_hours_confirm: 'Haluatko varmasti poistaa kirjatut tunnit?',
    removed: 'Poistettu',
    sorting: 'Suodatus',
    sorting_options: 'Suodatusvaihtoehdot',
    hide_selected: 'Piilota valitut',
    unhide_selected: 'Näytä valitut',
    total_working_hours_per_day_max_24h:
      'Päivän työtuntien yhteenlaskettu määrä ei voi olla yli 24 tuntia.',
    cannot_log_more_hours_please_edit_existing_entries:
      'Et voi kirjata enempää tunteja. Muokkaa olemassa olevia kirjauksia.',
    date_time_warning_message:
      'Jos Päivämäärä, Aika tai Kesto jää tyhjäksi, ei tehtävää näytetä Aikajana-näkymässä',
    missing_member_warning_message:
      'Jos Käyttäjää ei valita, ei tehtävää näytetä Aikajana-näkymässä',
    missing_task_requirements:
      'Jos Käyttäjä, Päivämäärä, Aika tai Kesto jää tyhjäksi, ei tehtävää näytetä Aikajana-näkymässä. Haluatko varmasti tallentaa?',
    you_can_log_max_hhmm_for_this_entry:
      'Voit merkitä enintään {{available}} tälle kirjaukselle.',
    week_single: 'Viikko',
    set_date_to_create_repetitive:
      'Aseta päivämäärä päästäksesi luomaan toistettavan tehtävän',
    too_many_tasks_error:
      'Luotavien tehtävien määrä on suurempi kuin sallittu (365)',
    update_recurring_task: 'Päivitä toistuva tehtävä',
    delete_recurring_task: 'Poista toistuva tehtävä',
    this_task: 'Tämä tehtävä',
    this_task_and_following: 'Tämä ja seuraavat tehtävät',
    repeating_task_member_change_warning:
      'tehtävistä on aktiivisina, eikä niiden käyttäjää voi sen vuoksi vaihtaa. Haluatko tallentaa muutoksen muille tehtäville?',
    yes: 'Kyllä',
    shift_save_warning_tooltip: 'Valitse Käyttäjä ja Vuoro tallentaaksesi',
    new_shift: 'Uusi vuoro',
    select_shift: 'Valitse vuoro',
    warning: 'Varoitus',
    add_task: 'Lisää tehtävä',
    add_shift: 'Lisää vuoro',
    search_shifts: 'Etsi vuoroja',
    delete_shift_confirmation: 'Haluatko varmasti poistaa tämän vuoron?',
    delete_multiple_shifts_confirmation:
      'Haluatko varmasti poistaa nämä vuorot?',
    edit_shift: 'Muokkaa vuoroa',
    shift_name: 'Vuoron nimi',
    no_timeframe_available: 'Ei aikaväliä saatavilla',
    add_task_to_shift: 'Lisää tehtävä vuorolle',
    edit_task_in_shift: 'Muokkaa tehtävää vuorossa',
    missing_start_and_end: 'Tehtävän aloitus ja lopetus ajat ovat pakollisia.',
    startdate_later_enddate:
      'Aloituspäivä ei voi olla sama tai myöhäisempi kuin päättymispäivä!',
    shift_info: 'Vuoro',
    shifts: 'Vuorot',
    shift_tasks: 'Vuoron tehtävät',
    repeating_task_active_status_delete_warning:
      'tehtävistä on aktiivisina, minkä vuoksi poistaminen ei ole mahdollista. Haluatko poistaa muut tehtävät?',
    cannot_delete_active_tasks: 'Käynnissä olevia tehtäviä ei voi poistaa',
    member_cant_be_changed_when_task_active:
      'Käynnissä olevaa tehtävää ei voi siirtää toiselle henkilölle',
    update_existing_tasks_caution:
      'Tämän vuoron muuttaminen vaikuttaa myös jo olemassa oleviin tehtäviin aikajanalla. Nämä muutokset eivät koske aktiivisia vuorotehtäviä, ylläpitäjän manuaalisesti muokkaamia tehtäviä, eikä menneitä tehtäviä. Haluatko varmasti tallentaa nämä muutokset?',
    task_outside_shift_warning:
      'Vuoroon kuuluvaa tehtävää ei voi siirtää jäseneltä toiselle.',
    start_date_for_repeating_tasks_can_not_be_changed:
      'Toistuvien tehtävien aloituspäivää ei voi muuttaa',
    delete_from_shift: 'Poista vuorosta',
    this_shift: 'Tämä vuoro',
    delete_from_recurring_shift: 'Poista toistuvasta vuorosta',
    this_shift_and_following: 'Tämä vuoro ja seuraavat',
    all_shifts_in_repetition: 'Kaikki vuorot',
    set_task_nextday: 'Aseta tehtävä seuraavalle päivälle',
    next_day_tooltip:
      'Valitsemalla tämän asetat tehtävän aloitus -ja lopetusajat tapahtumaan seuraavana päivänä. Hyödyllinen esimerkiksi yövuoroja tehdessä, jotka alkavat illalla ja jatkuvat myöhälle yöhön. Keskiyön ylittävää tehtävää tehdessä tätä laatikkoa ei tarvitse merkitä.',
    next_day: 'Seuraava päivä',
    only_one_task_active_change_applied_for_edited_task:
      'Vain yksi tehtävä voi olla aktiivinen kerrallaan. Muutos tehty vain muokatulle tehtävälle.',
    filter_by_proficiency: 'Suodata osaamisia',
    members_not_found: 'Tekijöitä ei löytynyt.',
    end_date: 'Päättymispäivä',
    import_tasks: 'Tuo tehtäviä',
    import: 'Tuo',
    import_confirm: 'Haluatko varmasti tuoda',
    tasks: 'tehtävää',
    new_worksites_and_jobtypes_will_be_created_automatically:
      'Uudet kohteet ja työlajit luodaan automaattisesti.',
    something_went_wrong_check_csv:
      'Jokin meni pieleen, tarkista onko csv tiedostosi virheellinen.',
    import_complete: 'Tehtävien tuonti onnistui.',
    will_be_assigned_to: 'Tehtävä määrätään käyttäjälle',
    member_not_found_task_will_be_set_as_free:
      'Käyttäjää ei löytynyt, tehtävä asetetaan vapaaksi tehtäväksi.',
    free_task: 'Vapaa tehtävä',
    preview: 'Esikatselu',
    no_location_set: 'Ei sijaintia',
    task_will_be_carried_out_for_customer: 'Tehtävä suoritetaan asiakkaalle',
    business_id_not_found_creating_new:
      'Y-tunnus ei löytynyt tietokannasta, uusi asiakas luodaan käyttäen Y-tunnusta',
    name_can_be_changed_later_from_customers_panel:
      'Nimen voi käydä vaihtamassa myöhemmin asiakkaat paneelista.',
    business_id_not_set: 'Y-tunnusta ei asetettu',
    import_filled_csv_chart: 'Tuo valmiiksi täytetty csv-lomakkeesi.',
    select_file: 'Valitse tiedosto',
    download_example_csv_import_form: 'Lataa esimerkki csv-tuonti lomake.',
    download: 'Lataa',
    downloaded: 'Ladattu',
    error: 'Virhe',
    tips_to_fill_out_the_form: 'Ohjeita kenttien täyttämiseen',
    customer_task_id_tip:
      'Tehtävätunnus kenttään voi halutessaan syöttää tunnuksen, joka auttaa käyttäjiä erottamaan samankaltaiset tehtävät toisistaan',
    only_required_field_is_jobtype: 'Ainut pakollinen kenttä on työ laji.',
    there_are_many_options_to_fill_timeframe:
      'Tehtävän aikaväliä määrittäessä voi käyttää useampaa eri vaihtoehtoa',
    weeknumber_creates_flexible_task_tip:
      'Viikonnumero luo joustavan tehtävän valitulle viikolle.',
    date_format_tip:
      'Päivämäärä kenttään voi syöttää joko pelkästään halutun päivämäärän (DD.MM.YYY) tai sitten myös täyttää sekä Alkaa, että Päättyy kentät (HH:mm).',
    start_end_time_tip:
      'Alkaa ja Päättyy kenttiin voi syöttää myös tarkan ajan kokonaisena (DD.MM.YYYY HH:mm).',
    new_worksites_customers_jobtypes_are_created_tip:
      'Uudet kohteet, asiakkaat ja työlajit luodaan automaattisesti tuomisen yhteydessä.',
    change_working_time_for_user: 'Vaihda työaika käyttäjälle',
    new_working_time: 'Uusi työaika',
    previous_working_time: 'Entinen työaika ',
    multiple_users: 'Useampi käyttäjä',
    unknown_user: 'Tuntematon käyttäjä',
    task_id: 'Tehtävätunnus',
    edited_user: 'Muokattu käyttäjä',
    select_status: 'Valitse tila',
    last_day: 'Viime päivä',
    last_week: 'Viime viikko',
    last_month: 'Viime kuukausi',
    no_tasks_within_timeframe_and_filters:
      'Tehtäviä ei löytynyt käyttäen valittua aikaväliä ja suodattimia',
    week_text: 'Viikko',
    deselect_all: 'Poista valinnat',
    no_task_id: 'Ei tehtävätunnusta',
  },
  branding: {
    app_name: 'MOWO',
    app_description: 'Mobile Workflow Management',
    copyright: '© 2019 Observis Oy.',
  },
  links: {
    privacy_policy: 'https://observis.fi/legal/Tietosuojaseloste_MOWO.pdf',
  },
  auth: {
    unauthorized: 'Ei sallittu',
    unknown_company: 'Tuntematon yritys',
    company_not_found: 'Yrityksen tietoja ei löytynyt.',
    no_permission_to_use_feature:
      'Sinulla ei ole oikeutta käyttää tätä toimintoa.',
    your_account_is_waiting_approval: 'Tilisi odottaa hyväksyntää',
    please_wait_for_admin_to_verify_your_login:
      'Ole hyvä ja odota kunnes ylläpito hyväksyy kirjautumisesi.',
    you_dont_have_permission_to_use_admin_dashboard:
      'Sinulla ei ole tarvittavia oikeuksia ylläpitotyökaluun.',
    please_contact_your_company_admin:
      'Ota yhteyttä organisaatiosi ylläpitäjään.',
    you_dont_have_permission_to_use_app:
      'Sinulla ei ole oikeuksia sovelluksen käyttämiseen. Ota yhteyttä organisaatiosi ylläpitäjään.',
    your_account_has_been_closed: 'Tilisi on suljettu',
    something_went_wrong:
      'Jotain meni vikaan. Yritäthän kirjautumista uudelleen tai ota yhteyttä organisaatiosi ylläpitäjään.',
    company_locked: 'Yritys on lukittu',
    company_pending: 'Yritys odottaa hyväksyntää',
    company_is_locked:
      'Yritys on lukittu käyttäjiltä. Ota yhteyttä yrityksen yhteyshenkilöön saadaksesi lisätietoja.',
    company_is_pending:
      'Yritys odottaa hyväksyntää. Yritä myöhemmin uudelleen tai ota yhteyttä saadaksesi lisätietoja jos ongelma jatkuu.',
    email_bad_format: 'Sähköposti on väärässä muodossa.',
    no_user_record: 'Tällä sähköpostilla ei löytynyt käyttäjätunnusta',
    invalid_pw: 'Väärä salasana',
    sigin_failed: 'Kirjautuminen epäonnistui',
    enter_email_for_recovery: 'Syötä sähköposti salasanan palauttamista varten',
    forgot_pw: 'Unohtuiko salasana?',
    recovery_email_sent: 'Sähköposti salasanan palauttamiseksi lähetetty',
    please_try_again: 'Ole hyvä ja yritä uudelleen.',
    or: 'TAI',
    pw: 'Salasana',
    register_as_user: 'Rekisteröidy käyttäjäksi',
    register: 'Rekisteröidy',
    pw_dont_match: 'Salasanat eivät täsmää',
    insert_email: 'Syötä sähköpostiosoite',
    enter_pw: 'Syötä salasana ja vahvistus',
    pw_too_short: 'Salasana on liian lyhyt',
    pw_six_characters: 'Salasanan tulee olla vähintään 6 merkkiä.',
    confirm_pw: 'Vahvista salasana',
    user_already_exists:
      'Tällä sähköpostiosoitteella on jo olemassaoleva käyttäjä',
    required_fields_login: 'Anna puuttuva kirjautumistieto',
    signin_with_google: 'Kirjaudu Googlella',
    pw_changed_succesfully: 'Salasana vaihdettu!',
    change_pw: 'Vaihda salasana',
    please_reauhenticate:
      'Jotta voit vaihtaa salasanan sinun tulee uudelleen kirjautua.',
    google_sign_in_change_pw_error:
      'Vaihtaaksesi salasanasi Google kirjautuneelle tilille tee se Google asetuksissa.',
    enter_new_pw: 'Kirjoita uusi salasana',
    enter_old_password: 'Kirjoita vanha salasana',
  },
  filters: {
    all_tasks: 'Kaikki tehtävät',
    all_assigned: 'Kaikki varatut tehtävät',
    all_unassigned: 'Kaikki vapaat tehtävät',
    all_priorities: 'Kaikki prioriteetit',
    selected_tasks: 'Osa tehtävistä',
    all_statuses: 'Kaikki tilat',
    filter_tasks: 'Suodata tehtäviä',
  },
  time: {
    hours: 'tuntia',
    hour: 'tunti',
    minutes: 'minuuttia',
    minute: 'minuutti',
    second: 'sekunti',
    seconds: 'sekuntia',
    milliseconds: 'millisekuntia',
    day: 'päivä',
    days: 'päivää',
    week: 'viikko',
    weeks: 'viikkoa',
    month: 'kuukausi',
    months: 'kuukautta',
    year: 'vuosi',
    years: 'vuotta',
    msec: 'ms',
    msecs: 'ms',
    sec: 's',
    secs: 's',
    min: 'min',
    mins: 'min',
    hr: 'h',
    hrs: 'h',
    dy: 'pv',
    dys: 'pv',
    wk: 'vk',
    wks: 'vk',
    mo: 'kk',
    mos: 'kk',
    yr: 'v',
    yrs: 'v',
    millisecond: 'ms',
  },
  localization_codes: {
    postalcode_locale: 'FI',
    mobile_locale: 'fi-FI',
  },
};

/**
 * A map of locale objects included in a system. These objects are mapped from json -files to pure typescript
 * automatically.
 */
export const locales: LocaleMap = {
  en_UK: locale_en_UK,
  fi_FI: locale_fi_FI,
};
