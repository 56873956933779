import {
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core/';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CustomersIcon from '@material-ui/icons/Contacts';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import ACSelect, { ACSelectValue } from 'components/ACSelect';
import * as React from 'react';

import { i18n } from '@shared/locale';
import {
  CompanyId,
  CustomerWithKey,
  Location,
  Schema,
  Settings,
  Worksite,
  WorksiteWithKey,
} from '@shared/schema';
import firebaseApp from 'firebaseApp';
import Geocode from 'react-geocode';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import { cancelEdit } from 'reducers/venues/venuesActions';

import { isPhoneNumber } from '@shared/validators';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
  validateForm,
} from 'ts-form-validation';
import * as validator from 'validator';
import styles from './styles';

export interface WorksiteProps extends DispatchProp, StyledComponentProps {
  editing: boolean;
  companyId: CompanyId;
  pickedCoordinates?: Coordinates;
  editedWorksite?: WorksiteWithKey;
  settings: Settings;
}

interface State {
  editMode: boolean;
  form: Form<WorksiteValidation>;
  customers: CustomerWithKey[];
  previousCustomerId?: string;
  geoLocation: GeoLocation;
  displayWarning: WarningDialogData;
  calculateNewCoordinates: boolean;
  mapEditor: boolean;
}

interface WarningDialogData {
  display: boolean;
  missingNames: string[];
}
interface Coordinates {
  lat: number | undefined;
  lng: number | undefined;
}

export interface WorksiteValidation {
  name: string;
  nick?: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  customer?: string;
  note?: string;
}

export interface GeoLocation {
  la: number;
  lo: number;
  t: number | any;
}

enum WorksiteEditorFieldNames {
  NAME = 'name',
  NICK = 'nick',
  ADDRESS = 'address',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  PHONE = 'phone',
  CUSTOMER = 'customer',
  NOTE = 'note',
}

const rules: FormValidationRules<WorksiteValidation> = {
  defaultMessages: {
    requiredField: () => i18n().ui.required_fields,
  },
  fields: {
    name: {
      required: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 3, max: 60 }) && {
          type: MessageType.ERROR,
          message: i18n().ui.worksite_name_length_must_be,
        },
    },
    nick: {
      required: false,
    },
    address: {
      required: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 5, max: 90 }) && {
          type: MessageType.ERROR,
          message: i18n().ui.worksite_address_length_must_be,
        },
    },
    city: {
      required: true,
      trim: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 2, max: 30 }) && {
          type: MessageType.ERROR,
          message: i18n().ui.worksite_city_length_must_be,
        },
    },
    phone: {
      required: false,
      trim: true,
      validate: (value: string) =>
        !isPhoneNumber(value) &&
        value !== '' && {
          type: MessageType.ERROR,
          message: i18n().ui.please_enter_valid_phone_number,
        },
    },
    postalCode: {
      required: false,
      trim: true,
      validate: (value: string) =>
        !validator.isLength(value, {
          min: 0,
          max: 18,
        }) && {
          type: MessageType.ERROR,
          message: i18n().ui.postal_code_length_must_be_long,
        },
    },
    note: {
      required: false,
    },
  },
  validateForm: form => {
    const messages = {};

    return { ...form, messages };
  },
};

const initialState: State = {
  editMode: false,

  form: initForm<WorksiteValidation>(
    {
      name: '',
      nick: '',
      address: '',
      city: '',
      postalCode: '',
      phone: '',
      note: '',
    },
    rules,
  ),
  customers: [],
  previousCustomerId: '',
  geoLocation: {
    la: 0,
    lo: 0,
    t: 0,
  },
  displayWarning: {
    display: false,
    missingNames: [],
  },
  calculateNewCoordinates: false,
  mapEditor: false,
};

export class WorksiteEditorDialog extends React.Component<
  WorksiteProps,
  State
> {
  public private: () => void;
  private unsubscribeCustomers: () => void;

  constructor(props: WorksiteProps) {
    super(props);

    this.state = { ...initialState };
  }

  public handleRequestClose = () => {
    this.props.dispatch && this.props.dispatch(cancelEdit());
    this.setState({ ...initialState });
  };

  public handleSave = async () => {
    const { calculateNewCoordinates } = this.state;
    if (!this.props.dispatch) {
      return;
    }

    const source: WorksiteValidation = this.state.form.values;
    if (calculateNewCoordinates) {
      await this.fetchCoordinates(
        source.address,
        source.postalCode,
        source.city,
      );
    }
    const worksiteLocation: Location = {
      address: source.address,
      city: source.city,
      postalCode: source.postalCode,
    };

    const geometryLocation: GeoLocation = {
      la: this.state.geoLocation.la,
      lo: this.state.geoLocation.lo,
      t: this.state.geoLocation.t,
    };

    const worksiteToSave: Worksite = {
      name: source.name,
      nick: source.nick ? source.nick : '',
      location: worksiteLocation,
      geoLocation: geometryLocation,
      phone: source.phone,
      note: source.note ? source.note : '',
      customer: source.customer ? source.customer : '',
    };
    if (this.state.geoLocation.la === 0 && this.state.geoLocation.lo === 0) {
      delete worksiteToSave.geoLocation;
    }

    if (this.state.editMode) {
      this.updateWorksite(
        this.props.companyId,
        worksiteToSave,
        this.props.editedWorksite ? this.props.editedWorksite.key : '',
      );
      this.handleRequestClose();
    } else {
      this.createWorksite(this.props.companyId, worksiteToSave);
      this.handleRequestClose();
    }
  };

  public componentWillReceiveProps(nextProps: WorksiteProps) {
    // Getting data for customers field.
    const emptyCustomer: CustomerWithKey = {
      key: '',
      name: i18n().ui.no_customer,
      customerNumber: '',
      address: '',
      postalCode: '',
      city: '',
      contacts: [],
      worksiteRefs: {},
      tasks: {},
    };

    try {
      this.unsubscribeCustomers = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.CUSTOMERS)
        .orderBy('name')
        .onSnapshot(snapshot => {
          const customersData: CustomerWithKey[] = [];

          snapshot.forEach(doc => {
            const data = doc.data() as CustomerWithKey;
            data.key = doc.id;
            customersData.push(data);
          });
          customersData.unshift(emptyCustomer);
          this.setState({
            customers: customersData,
          });
        });
    } catch (error) {
      console.error(error);
    }

    if (nextProps.pickedCoordinates) {
      Geocode.setApiKey(CONFIG.apiKeys.googleMapKey);
      Geocode.fromLatLng(
        nextProps.pickedCoordinates.lat,
        nextProps.pickedCoordinates.lng,
      ).then(
        (response: any) => {
          const addressComponents = response.results[0].address_components;
          const locationData = this.getAddressObject(addressComponents);

          const address = locationData.street + ' ' + locationData.home || '';
          const postalCode = locationData.postal_code || '';
          const city = locationData.city || locationData.region || '';

          const values = {
            ...this.state.form.values,
            address,
            city,
            postalCode,
          };

          const form = validateForm(
            {
              ...this.state.form,
              values,
            },
            {
              usePreprocessor: false,
            },
          );
          if (nextProps.pickedCoordinates) {
            const geoLocationValues: GeoLocation = {
              la: nextProps.pickedCoordinates.lat || 0,
              lo: nextProps.pickedCoordinates.lng || 0,
              t: new Date().getTime(),
            };

            this.setState({
              form,
              geoLocation: geoLocationValues,
              mapEditor: true,
            });
          }
        },
        (error: any) => {
          console.error(error);
        },
      );
    }

    let editMode; // = this.state.editMode;
    nextProps.editedWorksite != null && nextProps.editedWorksite !== undefined
      ? (editMode = true)
      : (editMode = false);

    if (
      editMode &&
      nextProps.editedWorksite &&
      this.props.editedWorksite !== nextProps.editedWorksite
    ) {
      this.setState({
        form: {
          ...this.state.form,
          values: {
            name: nextProps.editedWorksite.name,
            nick: nextProps.editedWorksite.nick,
            address: nextProps.editedWorksite.location.address,
            city: nextProps.editedWorksite.location.city,
            postalCode: nextProps.editedWorksite.location.postalCode,
            phone: nextProps.editedWorksite.phone
              ? nextProps.editedWorksite.phone
              : '',
            note: nextProps.editedWorksite.note,
            customer: nextProps.editedWorksite.customer,
          },
          isFormValid: true,
        },
        previousCustomerId: nextProps.editedWorksite.customer,
      });
    } else {
      this.setState({ ...initialState });
    }
    this.setState({
      editMode,
    });
  }

  public componentWillUnmount() {
    this.unsubscribeCustomers && this.unsubscribeCustomers();
  }

  public render() {
    const { editing, settings, classes = {} } = this.props;

    if (!editing) {
      return null;
    }

    const { values, isFormValid } = this.state.form;
    const { displayWarning } = this.state;
    return (
      <>
        <Dialog open={editing} className={classes.root}>
          <DialogTitle data-test="dialogTitle">
            {this.state.editMode
              ? i18n().ui.modify_worksite
              : i18n().ui.new_worksite}
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={`${i18n().ui.worksite_name}*`}
                  className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange(WorksiteEditorFieldNames.NAME)}
                  onBlur={this.handleDialogBlur(WorksiteEditorFieldNames.NAME)}
                  value={values.name}
                  error={this.checkErrorStatus(WorksiteEditorFieldNames.NAME)}
                  helperText={this.getErrorMessage(
                    WorksiteEditorFieldNames.NAME,
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 60,
                  }}
                  data-test="worksiteEditorDialogName"
                />
              </ListItem>

              {settings.general.enableWorksiteNick && (
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>

                  <Divider />
                  <TextField
                    label={i18n().ui.nick_name}
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange(WorksiteEditorFieldNames.NICK)}
                    value={values.nick}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    data-test="worksiteEditorDialogNickname"
                  />
                </ListItem>
              )}

              <ListItem>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={`${i18n().ui.address}*`}
                  className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange(WorksiteEditorFieldNames.ADDRESS)}
                  onBlur={this.handleDialogBlur(
                    WorksiteEditorFieldNames.ADDRESS,
                  )}
                  value={values.address}
                  error={this.checkErrorStatus(
                    WorksiteEditorFieldNames.ADDRESS,
                  )}
                  helperText={this.getErrorMessage(
                    WorksiteEditorFieldNames.ADDRESS,
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-test="worksiteEditorDialogAddress"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PlaceIcon style={{ visibility: 'hidden' }} />
                </ListItemIcon>

                <TextField
                  label={`${i18n().ui.postalcode}`}
                  className={classes.textField21}
                  margin="normal"
                  onChange={this.handleChange(
                    WorksiteEditorFieldNames.POSTAL_CODE,
                  )}
                  onBlur={this.handleDialogBlur(
                    WorksiteEditorFieldNames.POSTAL_CODE,
                  )}
                  value={values.postalCode}
                  error={this.checkErrorStatus(
                    WorksiteEditorFieldNames.POSTAL_CODE,
                  )}
                  helperText={this.getErrorMessage(
                    WorksiteEditorFieldNames.POSTAL_CODE,
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-test="worksiteEditorDialogPostalCode"
                />

                <TextField
                  label={`${i18n().ui.city}*`}
                  className={classes.textField22}
                  margin="normal"
                  onChange={this.handleChange(WorksiteEditorFieldNames.CITY)}
                  onBlur={this.handleDialogBlur(WorksiteEditorFieldNames.CITY)}
                  value={values.city}
                  error={this.checkErrorStatus(WorksiteEditorFieldNames.CITY)}
                  helperText={this.getErrorMessage(
                    WorksiteEditorFieldNames.CITY,
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-test="worksiteEditorDialogCity"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneIcon />
                </ListItemIcon>

                <Divider />
                <TextField
                  label={i18n().ui.phone}
                  className={classes.textField}
                  margin="normal"
                  type="tel"
                  onChange={this.handleChange(WorksiteEditorFieldNames.PHONE)}
                  onBlur={this.handleDialogBlur(WorksiteEditorFieldNames.PHONE)}
                  value={values.phone}
                  error={this.checkErrorStatus(WorksiteEditorFieldNames.PHONE)}
                  helperText={this.getErrorMessage(
                    WorksiteEditorFieldNames.PHONE,
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-test="worksiteEditorDialogPhone"
                />
              </ListItem>

              {settings.general.enableCustomer && (
                <ListItem className={classes.customerContainer}>
                  <ListItemIcon>
                    <CustomersIcon />
                  </ListItemIcon>
                  <FormControl className={classes.customerField}>
                    <ACSelect
                      options={this.state.customers.map(value => ({
                        value: value.key,
                        label: value.name,
                      }))}
                      value={
                        this.state.editMode
                          ? {
                              value: values.customer ? values.customer : '',
                              label: values.customer
                                ? this.getSelectedCustomerName(values.customer)
                                : '',
                            }
                          : {
                              value: '',
                              label: '',
                            }
                      }
                      onChange={this.handleCustomerChange}
                      placeholder={i18n().ui.select_customer}
                      isClearable={false}
                      menuListHeight={'190px'}
                    />
                  </FormControl>
                </ListItem>
              )}

              <ListItem>
                <TextField
                  label={i18n().ui.further_information}
                  multiline
                  className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange(WorksiteEditorFieldNames.NOTE)}
                  value={values.note}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-test="worksiteEditorDialogFurtherInformation"
                />
              </ListItem>
            </List>
            <p>{i18n().ui.required_fields}</p>
          </DialogContent>
          <DialogActions>
            {this.state.editMode && (
              <Button
                onClick={this.handleDelete}
                color="default"
                className={classes.deleteButton}
              >
                <DeleteForeverIcon />
                {i18n().ui.delete_worksite}
              </Button>
            )}
            <Button onClick={this.handleRequestClose}>
              {i18n().ui.cancel}
            </Button>
            <Button
              data-test="saveButton"
              disabled={
                !isFormValid ||
                this.checkErrorStatus(WorksiteEditorFieldNames.PHONE)
              }
              color="secondary"
              onClick={this.handleSave}
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={displayWarning.display}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n().ui.caution}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" color="primary">
              {`${i18n().ui.delete_worksite_confirm} ${values.name}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose}>{i18n().ui.cancel}</Button>
            <Button
              onClick={this.handleDeleteConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  /**
   * Returns message to specific input field by its name
   */
  private getErrorMessage = (target: WorksiteEditorFieldNames) => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    return message && message.message;
  };

  /**
   * Checks if validation messages contains error messages and returns boolean
   * to textFields error property
   */
  private checkErrorStatus = (target: WorksiteEditorFieldNames): boolean => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  /**
   * Fetches coordinates from given address
   * @param address Given address
   * @param postalCode Given postalCode
   * @param city Given city
   */
  private fetchCoordinates = async (
    address: string,
    postalCode: string,
    city: string,
  ) => {
    Geocode.setApiKey(CONFIG.apiKeys.googleMapKey);
    await Geocode.fromAddress(`${address} ${postalCode} ${city}`).then(
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location;
        const geoLocationValues = {
          la: lat,
          lo: lng,
          t: new Date().getTime(),
        };
        this.setState({ geoLocation: geoLocationValues });
        return geoLocationValues;
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  /**
   * Checks when textfields input value changes and sets/updates it to states
   */
  private handleChange = (item: keyof WorksiteValidation) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const { mapEditor } = this.state;
    const values = {
      ...this.state.form.values,
      [item]: e.target.value,
    };

    const form = validateForm(
      {
        ...this.state.form,
        values,
      },
      {
        usePreprocessor: false,
      },
    );

    if (
      (!mapEditor && item === 'address') ||
      (!mapEditor && item === 'postalCode') ||
      (!mapEditor && item === 'city')
    ) {
      this.setState({
        calculateNewCoordinates: true,
      });
    }

    this.setState({
      form,
    });
  };

  /**
   * Opens delete confirm dialog
   */
  private handleDelete = () => {
    this.setState({
      displayWarning: {
        display: true,
        missingNames: [],
      },
    });
  };

  /**
   * Closes delete confirm dialog
   */
  private handleDeleteClose = () => {
    this.setState({
      displayWarning: {
        display: false,
        missingNames: [],
      },
    });
  };

  /**
   * Closes dialog and fires delete function
   */
  private handleDeleteConfirm = () => {
    this.setState({
      displayWarning: {
        display: false,
        missingNames: [],
      },
    });

    this.delete();
  };

  /**
   * Deletes worksite from database
   */
  private delete = () => {
    const { companyId, editedWorksite } = this.props;

    if (editedWorksite) {
      const worksiteId = editedWorksite.key;
      try {
        firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(companyId)
          .collection(Schema.WORKSITE)
          .doc(worksiteId)
          .delete();
      } catch (error) {
        console.error(
          "Can't delete worksite ",
          editedWorksite.name,
          ' ',
          error,
        );
      }
    } else {
      console.error('Worksite not found');
    }
    this.handleRequestClose();
  };

  /**
   * Updates customer change to state
   * @param selected Selected value
   */
  private handleCustomerChange = (selected: ACSelectValue) => {
    const { value } = selected;

    const newState = { ...this.state.form };

    newState.values.customer = value;
    this.setState({
      form: newState,
    });
  };

  /**
   * Gets customer name by key
   * @param key Customer key
   * @returns Customer name or empty string
   */
  private getSelectedCustomerName = (key: string) => {
    const { customers } = this.state;

    const customer = customers.find(item => item.key === key);
    if (customer) {
      return customer.name;
    }
    return '';
  };

  /**
   * Checks when user stops writing, so validation can be started
   */
  private handleDialogBlur = (key: keyof WorksiteValidation) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let form = { ...this.state.form };
    const filled = {
      ...form.filled,
      [key]: true,
    };

    form = validateForm({
      ...this.state.form,
      filled,
    });

    this.setState({
      form,
    });
  };

  /**
   * Updates worksite data
   * @param companyId Company ID
   * @param worksite Worksite data
   * @param worksiteKey Worksite's unique key
   *
   */
  private updateWorksite = (
    companyId: CompanyId,
    worksite: Worksite,
    worksiteKey: string,
  ) => {
    const data: Worksite = { ...worksite };

    if (data.customer === undefined) {
      data.customer = '';
    }

    firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.WORKSITE)
      .doc(worksiteKey)
      .update(data);
  };

  /**
   * Creates worksite
   * @param companyId Company ID
   * @param worksite Worksite data
   */
  private createWorksite = (companyId: string, worksite: Worksite) => {
    firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.WORKSITE)
      .add(worksite);
  };

  /**
   * Gets the wanted data from google's response and places it in a new object
   * @param addressComponents Addresscomponents from the googles response
   * @returns Object with all vital info inside it
   */
  private getAddressObject = (addressComponents: any) => {
    const ShouldBeComponent = {
      home: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'postal_town',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    };

    const address = {
      home: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: '',
    };
    addressComponents.forEach((component: any) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    return address;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<WorksiteProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    editing: state.venue.editing,
    editedWorksite: state.venue.editedVenue,
    settings: state.company.activeCompany.settings,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(WorksiteEditorDialog),
);
