import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  searchIcon: {
    top: '76px',
    right: '25px',
    zIndex: 1000,
    position: 'absolute',
    width: '43px',
    height: '43px',
    backgroundColor: 'white',
    borderRadius: '25px',
    padding: '8px',
    boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    cursor: 'pointer',
  },
  addNewWorksite: {
    top: '74px',
    right: '91px',
    width: '168px',
    height: '49px',
    zIndex: 1000,
    position: 'absolute',
    borderRadius: '25px',
    padding: '8px',
    boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    textAlign: 'center',
    backgroundColor: '#99CA3C',
    '&:hover': {
      backgroundColor: '#8bb53a',
    },
  },
  snackBar: {
    marginBottom: '15px',
  },
  snackbarContent: {
    background: '#1976D2',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginRight: '12px',
  },
});
export default styles;
