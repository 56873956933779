import { Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import { StyleRules, Theme } from '@material-ui/core/styles';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import * as React from 'react';

/**
 * Props for CheckboxGrid.
 */
export interface CheckboxGridProps extends StyledComponentProps {
  items: CheckboxGridItem[];
  onChange: (items: CheckboxGridItem[]) => void;
}

/**
 * Single checkbox item data to be added to CheckboxGrid.
 */
export interface CheckboxGridItem {
  key: string;
  label: string;
  checked: boolean;
}

interface State {
  items: CheckboxGridItem[];
}

/**
 * Grid of checkbox elements. To be used in dialogs.
 *
 * Uses 2 columns by default and falls back to 1 column on narrow screens.
 */
export class CheckboxGrid extends React.Component<CheckboxGridProps, State> {
  public constructor(props: CheckboxGridProps) {
    super(props);

    this.state = {
      items: props.items.map((item, index) => ({
        key: item.key,
        label: item.label,
        checked: item.checked,
      })),
    };
  }

  public render() {
    const { classes = {} } = this.props;
    const { items } = this.state;

    return (
      <FormGroup>
        <Grid
          container
          spacing={8}
          className={classNames(classes.checkboxGrid)}
        >
          {items.map((item, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={items.length > 1 ? 6 : false}
              className={classNames(
                classes.gridStyles,
                classes.checkboxGridItem,
              )}
              data-test="item"
            >
              <FormControlLabel
                key={item.key}
                label={item.label}
                control={
                  <Checkbox
                    value={item.key}
                    checked={item.checked}
                    onClick={this.onItemClick(index)}
                    data-test="checkbox"
                  />
                }
                className={classes.checkBoxLabel}
                data-test="formControlLabel"
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    );
  }

  private onItemClick = (index: number) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault();

    const items = this.state.items.map(item => ({ ...item }));

    items[index].checked = !items[index].checked;

    this.setState(
      {
        items,
      },
      () => this.props.onChange(items),
    );
  };
}

const styles = (theme: Theme): StyleRules<string> => ({
  checkboxGrid: {
    overflow: 'auto',
  },
  checkboxGridItem: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
});

export default withStyles(styles, { withTheme: true })(CheckboxGrid);
