import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import {
  StyledComponentProps,
  StyleRules,
  Theme,
} from '@material-ui/core/styles';
import { i18n } from '@shared/locale';
import {
  RepeatingTasks,
  ShiftWithKey,
  TaskStatus,
  TaskWithKey,
} from '@shared/schema';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'reducers';
import {
  incrementProgress,
  setCurrentProgress,
  setProgressMessage,
  setTotalProgress,
} from 'reducers/shifts/shiftAction';
import store from 'store';
import {
  collectionToJsonWithIds,
  deleteUndefinedValues,
  docToJsonWithId,
} from 'utils/firestoreUtils';
import {
  chunkArray,
  getAllRepeatingShiftTasks,
  getAllRepeatingTasks,
  getRepeatingTasksDocRef,
  getShiftTasks,
  getThisAndFollowingRepeatingTasks,
} from 'utils/repeatingTasksUtils';
import {
  getShiftsTableRef,
  getTaskDocRef,
  getTasksTableRef,
} from 'utils/tasksUtil';
import RepeatingTaskWarning from './RepeatingTaskWarning';

enum RepeatingTaskDeletion {
  deleteThis = 'deleteThis',
  deleteAll = 'deleteAll',
  deleteFollowing = 'deleteFollowing',
}

enum RepeatingShiftDeletion {
  deleteTask = 'deleteTask',
  deleteShift = 'deleteShift',
  deleteFollowing = 'deleteFollowing',
  deleteAll = 'deleteAll',
}

interface TaskDeleteDialogProps extends StyledComponentProps {
  task: TaskWithKey;
  isOpen: boolean;
  onClose: () => void;
  companyId: string | undefined;
  onSendRequest?: () => void;
}

interface State {
  repeatingTaskDeletionOption: RepeatingTaskDeletion;
  repeatingShiftDeletionOption: RepeatingShiftDeletion;
  isWarningOpen: boolean;
  ratioOfActiveTasks: string;
  activeTasks: TaskWithKey[];
  nonActiveTasks: TaskWithKey[];
  deleteButtonEnabled: boolean;
}

class TaskDeleteDialog extends React.Component<TaskDeleteDialogProps, State> {
  constructor(props: TaskDeleteDialogProps) {
    super(props);

    this.state = {
      repeatingTaskDeletionOption: RepeatingTaskDeletion.deleteThis,
      repeatingShiftDeletionOption: RepeatingShiftDeletion.deleteTask,
      isWarningOpen: false,
      ratioOfActiveTasks: '',
      activeTasks: [],
      nonActiveTasks: [],
      deleteButtonEnabled: true,
    };
  }

  public render() {
    const { task, classes = {}, onClose, isOpen } = this.props;
    const {
      repeatingTaskDeletionOption,
      repeatingShiftDeletionOption,
    } = this.state;

    const hasTimer = task && (task.timerLog || task.timerTotal);

    const deleteText = hasTimer
      ? i18n().ui.task_has_hours_confirm
      : i18n().ui.delete_dialog_confirm;

    return (
      <>
        {task.repeatingTaskId && (
          <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{i18n().ui.delete_recurring_task}</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={repeatingTaskDeletionOption}
                onChange={(e: React.ChangeEvent<{}>, value: string) =>
                  this.setState({
                    repeatingTaskDeletionOption: value as RepeatingTaskDeletion,
                  })
                }
              >
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingTaskDeletion.deleteThis}
                  control={<Radio />}
                  label={i18n().ui.this_task}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingTaskDeletion.deleteFollowing}
                  control={<Radio />}
                  label={i18n().ui.this_task_and_following}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingTaskDeletion.deleteAll}
                  control={<Radio />}
                  label={i18n().filters.all_tasks}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{i18n().ui.cancel}</Button>
              <Button
                disabled={!this.state.deleteButtonEnabled}
                onClick={this.handleDelete}
                color={'primary'}
                autoFocus
              >
                {i18n().ui.delete}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {task.shiftId && (
          <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{i18n().ui.delete_from_shift}</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={repeatingShiftDeletionOption}
                onChange={(e: React.ChangeEvent<{}>, value: string) =>
                  this.setState({
                    repeatingShiftDeletionOption: value as RepeatingShiftDeletion,
                  })
                }
              >
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteTask}
                  control={<Radio />}
                  label={i18n().ui.this_task}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteShift}
                  control={<Radio />}
                  label={i18n().ui.this_shift}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{i18n().ui.cancel}</Button>
              <Button
                disabled={!this.state.deleteButtonEnabled}
                onClick={this.handleDelete}
                color={'primary'}
                autoFocus
              >
                {i18n().ui.delete}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {task.shiftId && task.repeatingShiftId && (
          <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{i18n().ui.delete_from_recurring_shift}</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={repeatingShiftDeletionOption}
                onChange={(e: React.ChangeEvent<{}>, value: string) =>
                  this.setState({
                    repeatingShiftDeletionOption: value as RepeatingShiftDeletion,
                  })
                }
              >
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteTask}
                  control={<Radio />}
                  label={i18n().ui.this_task}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteShift}
                  control={<Radio />}
                  label={i18n().ui.this_shift}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteFollowing}
                  control={<Radio />}
                  label={i18n().ui.this_shift_and_following}
                />
                <FormControlLabel
                  className={classes.formControllLabel}
                  value={RepeatingShiftDeletion.deleteAll}
                  control={<Radio />}
                  label={i18n().ui.all_shifts_in_repetition}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{i18n().ui.cancel}</Button>
              <Button
                disabled={!this.state.deleteButtonEnabled}
                onClick={this.handleDelete}
                color={'primary'}
                autoFocus
              >
                {i18n().ui.delete}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {task && !task.repeatingTaskId && !task.shiftId && (
          <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{i18n().ui.caution}</DialogTitle>
            <DialogContent>
              <DialogContentText color="primary">
                {deleteText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{i18n().ui.cancel}</Button>
              <Button
                disabled={!this.state.deleteButtonEnabled}
                onClick={this.handleDelete}
                color={'primary'}
                autoFocus
              >
                {i18n().ui.delete}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {this.state.isWarningOpen && (
          <RepeatingTaskWarning
            onAgree={this.agreeWarning}
            onCancel={this.cancelWarning}
            ratioOfActiveTasks={this.state.ratioOfActiveTasks}
            warningText={i18n().ui.repeating_task_active_status_delete_warning}
          />
        )}
      </>
    );
  }

  /**
   * Called on delete button click, if task is repeating checks that none of repeating tasks are active, otherwise trigger additional dialog.
   * For non-repeating tasks just calls delete function
   */
  private handleDelete = async () => {
    this.setState({ deleteButtonEnabled: false });
    const { task, companyId } = this.props;
    const {
      repeatingTaskDeletionOption,
      repeatingShiftDeletionOption,
    } = this.state;

    if (task && task.key) {
      const repId = task.repeatingTaskId;
      const shiftId = task.shiftId;
      let tasks: TaskWithKey[] = [];

      if (repId) {
        switch (repeatingTaskDeletionOption) {
          case RepeatingTaskDeletion.deleteFollowing: {
            await getThisAndFollowingRepeatingTasks(companyId, task).then(
              docs => {
                tasks = collectionToJsonWithIds<TaskWithKey>(docs);
              },
            );
            break;
          }

          case RepeatingTaskDeletion.deleteAll: {
            await getAllRepeatingTasks(companyId, task).then(docs => {
              tasks = collectionToJsonWithIds<TaskWithKey>(docs);
            });
            break;
          }
          default:
            break;
        }
      }
      if (shiftId) {
        switch (repeatingShiftDeletionOption) {
          case RepeatingShiftDeletion.deleteShift: {
            await getShiftTasks(companyId, task).then(docs => {
              tasks = collectionToJsonWithIds<TaskWithKey>(docs);
            });
            break;
          }
          case RepeatingShiftDeletion.deleteFollowing: {
            await getAllRepeatingShiftTasks(companyId, task).then(docs => {
              const unfilteredTasks = collectionToJsonWithIds<TaskWithKey>(
                docs,
              );
              // Get all tasks in the same shift
              const tasksInSameShift = unfilteredTasks.filter(
                sameShiftTask => sameShiftTask.shiftId === task.shiftId,
              );
              // Get earliest task start inside same shift
              const earliestShiftTaskStart = tasksInSameShift.reduce(
                (min, sameShiftTask) =>
                  sameShiftTask.start < min ? sameShiftTask.start : min,
                tasksInSameShift[0].start,
              );
              tasks = unfilteredTasks.filter(
                unfilteredTask =>
                  unfilteredTask.start >= earliestShiftTaskStart ||
                  unfilteredTask.shiftId === task.shiftId,
              );
            });

            break;
          }
          case RepeatingShiftDeletion.deleteAll: {
            await getAllRepeatingShiftTasks(companyId, task).then(docs => {
              tasks = collectionToJsonWithIds<TaskWithKey>(docs);
            });
            break;
          }
          default:
            break;
        }
      }
      if (!repId && !shiftId) {
        this.delete();
      } else {
        const activeTasks = tasks.filter(
          taskToFilter => taskToFilter.status === TaskStatus.ACTIVE,
        );
        const nonActiveTasks = tasks.filter(
          taskToFilter => taskToFilter.status !== TaskStatus.ACTIVE,
        );
        if (activeTasks.length > 0) {
          // Shows warning
          this.setState({
            ratioOfActiveTasks: `${activeTasks.length}/${tasks.length}`,
            isWarningOpen: true,
            activeTasks,
            nonActiveTasks,
          });
          return;
        } else {
          this.delete(nonActiveTasks);
        }
      }
    }
  };

  /**
   * Checks selected delete option and perform deletion correspondingly
   *
   * @param {TaskWithKey[]} tasks
   */
  private delete = async (tasks: TaskWithKey[] = []) => {
    const {
      repeatingTaskDeletionOption,
      repeatingShiftDeletionOption,
      nonActiveTasks,
    } = this.state;

    if (this.props.onSendRequest) {
      this.props.onSendRequest();
    }

    const { task, companyId } = this.props;
    if (task && task.key) {
      const batch = firebaseApp.firestore().batch();

      const repId = task.repeatingTaskId;
      const shiftId = task.shiftId;
      if (repId) {
        let repeatingTask: RepeatingTasks = { tasks: [] };

        await getRepeatingTasksDocRef(companyId, repId)
          .get()
          .then(doc => {
            repeatingTask = doc.data() as RepeatingTasks;
          })
          .catch(err => {
            console.error(
              'Error retrieving repeating task array for deleting task',
              err,
            );
          });

        switch (repeatingTaskDeletionOption) {
          case RepeatingTaskDeletion.deleteThis: {
            await this.deleteThisTaskByRepeatingTasksId(
              task,
              repeatingTask,
              batch,
            );
            break;
          }

          case RepeatingTaskDeletion.deleteFollowing: {
            await this.deleteThisAndFollowingTasksByRepeatingTaskId(
              task,
              repeatingTask,
              batch,
              tasks,
            );
            break;
          }

          case RepeatingTaskDeletion.deleteAll: {
            await this.deleteAllTasksByRepeatingTasksId(
              task,
              repeatingTask,
              batch,
              tasks,
            );
            break;
          }
          default:
            break;
        }

        batch
          .commit()
          .catch(err =>
            console.error(
              'Error applying repeating tasks delete changes with batch',
              err,
            ),
          );
      } else if (shiftId) {
        if (
          repeatingShiftDeletionOption === RepeatingShiftDeletion.deleteTask
        ) {
          this.deleteShiftTask(task, batch);
        } else {
          this.deleteShiftTasks(
            nonActiveTasks.length > 0 ? nonActiveTasks : tasks,
            batch,
          );
        }
      } else {
        const taskRef = getTaskDocRef(companyId, task.key);

        if (task.timerLog || task.timerTotal) {
          await taskRef.update({ archived: true });
        } else {
          await taskRef.delete().catch(error => {
            console.error(error);
          });
        }
      }
    } else {
      console.error('Task not found');
    }
  };

  /**
   * Called on "This task" selected option. Delete selected task
   *
   * @param {TaskWithKey} task
   * @param {RepeatingTasks} repeatingTasks
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteThisTaskByRepeatingTasksId = async (
    task: TaskWithKey,
    repeatingTask: RepeatingTasks,
    batch: firebase.firestore.WriteBatch,
  ) => {
    this.deleteRepeatingTasks([task], repeatingTask, batch);
  };

  /**
   * Called on "This and following" selected option. Delete selected task and next task based on 'start' value
   *
   * @param {TaskWithKey} task
   * @param {RepeatingTasks} repeatingTasks
   * @param {firebase.firestore.WriteBatch} batch
   * @param {TaskWithKey} tasks
   */
  private deleteThisAndFollowingTasksByRepeatingTaskId = async (
    task: TaskWithKey,
    repeatingTask: RepeatingTasks,
    batch: firebase.firestore.WriteBatch,
    tasks: TaskWithKey[] = [],
  ) => {
    const { companyId } = this.props;

    let tasksToDelete: TaskWithKey[] = tasks;
    tasksToDelete.length > 0 ||
      (await getThisAndFollowingRepeatingTasks(companyId, task).then(docs => {
        tasksToDelete = collectionToJsonWithIds<TaskWithKey>(docs);
      }));

    this.deleteRepeatingTasks(tasksToDelete, repeatingTask, batch);
  };

  /**
   * Called on "All tasks" selected option. Delete this task and all tasks under same chain
   *
   * @param {TaskWithKey} task
   * @param {RepeatingTasks} repeatingTasks
   * @param {firebase.firestore.WriteBatch} batch
   * @param {TaskWithKey} tasks
   */
  private deleteAllTasksByRepeatingTasksId = async (
    task: TaskWithKey,
    repeatingTask: RepeatingTasks,
    batch: firebase.firestore.WriteBatch,
    tasks: TaskWithKey[] = [],
  ) => {
    const { companyId } = this.props;
    const repId = task.repeatingTaskId;

    let tasksToDelete: TaskWithKey[] = tasks;
    tasksToDelete.length ||
      (await getTasksTableRef(companyId)
        .where('repeatingTaskId', '==', repId)
        .get()
        .then(docs => {
          tasksToDelete = collectionToJsonWithIds<TaskWithKey>(docs);
        }));

    this.deleteRepeatingTasks(tasksToDelete, repeatingTask, batch);
  };

  /**
   * Delete passed task filtering active tasks
   *
   * @param {TaskWithKey[]} tasks
   * @param {RepeatingTasks} repeatingTasks
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteRepeatingTasks = (
    tasks: TaskWithKey[],
    repeatingTask: RepeatingTasks,
    batch: firebase.firestore.WriteBatch,
  ) => {
    const { companyId } = this.props;

    const filteredTasks: TaskWithKey[] = tasks.filter(
      task => task.status !== TaskStatus.ACTIVE,
    );

    if (filteredTasks.length > 0) {
      const filteredRepeatingTasks: RepeatingTasks = {
        tasks: repeatingTask.tasks.filter(
          taskId => !filteredTasks.map(task => task.key).includes(taskId),
        ),
      };

      const repId = filteredTasks[0].repeatingTaskId;

      if (filteredRepeatingTasks.tasks.length === 0) {
        batch.delete(getRepeatingTasksDocRef(companyId, repId));

        filteredTasks.forEach(t => {
          this.deleteTask(t, batch);
        });
      } else {
        batch.update(
          getRepeatingTasksDocRef(companyId, repId),
          filteredRepeatingTasks,
        );

        filteredTasks.forEach(t => {
          this.deleteTask(t, batch);
        });
      }
    } else {
      // Impossible to get so far without detecting that some are still active. Act as last resort
      return;
    }
  };

  /**
   * Deletes task and related task id in shifts table
   * @param task Selected task
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteShiftTask = async (
    task: TaskWithKey,
    batch: firebase.firestore.WriteBatch,
  ) => {
    const { companyId } = this.props;
    try {
      await getShiftsTableRef(companyId)
        .doc(task.shiftId)
        .get()
        .then(doc => {
          if (doc.exists) {
            const shift = docToJsonWithId<ShiftWithKey>(doc);
            const taskIds = shift.taskIds;

            /**
             * If taskIds array length is more or equal than 2 remove id from shift
             * Otherwise delete whole shift from shifts table
             */
            if (taskIds.length >= 2) {
              this.deleteTask(task, batch);
              shift.taskIds = taskIds.filter(taskId => taskId !== task.key);
              delete shift.key;
              batch.update(
                getShiftsTableRef(companyId).doc(task.shiftId),
                shift,
              );
            } else {
              this.deleteTask(task, batch);
              batch.delete(getShiftsTableRef(companyId).doc(task.shiftId));
            }
          }
        });
      batch.commit();
    } catch (error) {
      console.error('Error deleting single shift task, ', error);
    }
  };

  /**
   * Deletes shifts and tasks according to user's selection
   * @param tasks List of tasks about to be deleted
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteShiftTasks = async (
    tasks: TaskWithKey[],
    batch: firebase.firestore.WriteBatch,
  ) => {
    const { companyId } = this.props;
    const { activeTasks } = this.state;
    try {
      const shiftIdList: string[] = [];

      const taskChunks = chunkArray(tasks, 50);

      // Store total number of chunks and current number of chunks to display creation progress in ProgressLoader
      store.dispatch(setTotalProgress(taskChunks.length));
      store.dispatch(setCurrentProgress(0));
      store.dispatch(
        setProgressMessage('Deleting shift tasks, please wait...'),
      );

      taskChunks.forEach(taskChunk => {
        const deleteTasksBatch = firebaseApp.firestore().batch();
        taskChunk.forEach(singleTask => {
          if (
            !shiftIdList.find(id => id === singleTask.shiftId) &&
            singleTask.shiftId
          ) {
            shiftIdList.push(singleTask.shiftId);
          }
          this.deleteTask(singleTask, deleteTasksBatch);
        });
        deleteTasksBatch
          .commit()
          .then(() => store.dispatch(incrementProgress()))
          .catch(e => {
            store.dispatch(setProgressMessage('Error deleting shift tasks'));
            console.error('Failed to delete tasks', e);
          });
      });

      // If active tasks are found, remove all shift related information from them
      if (activeTasks.length > 0) {
        activeTasks.forEach(activeTask => {
          this.deleteShiftInformation(activeTask, batch);
        });
      }

      // Loop all shift id's and delete them by using batch
      shiftIdList.forEach(shiftId => {
        batch.delete(getShiftsTableRef(companyId).doc(shiftId));
      });

      batch.commit();
    } catch (error) {
      console.error('Error deleting shift tasks: ', error);
    }
  };

  /**
   * Deletes or archives passed task
   *
   * @param {TaskWithKey} task
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteTask = (
    task: TaskWithKey,
    batch: firebase.firestore.WriteBatch,
  ) => {
    const { companyId } = this.props;
    if (task.timerLog || task.timerTotal) {
      delete task.repeatingTaskId;
      task.archived = true;
      const validTask = deleteUndefinedValues(task);
      batch.update(getTaskDocRef(companyId, validTask.key), validTask);
    } else {
      batch.delete(getTaskDocRef(companyId, task.key));
    }
  };

  /**
   * Deletes shift information from active tasks
   * @param activeTask Active task which won't be deleted but shift info will be cleared
   * @param {firebase.firestore.WriteBatch} batch
   */
  private deleteShiftInformation = (
    activeTask: TaskWithKey,
    batch: firebase.firestore.WriteBatch,
  ) => {
    const { companyId } = this.props;

    delete activeTask.shiftId;
    delete activeTask.shiftTaskModified;
    delete activeTask.shiftTemplateId;
    delete activeTask.shiftTemplateTaskId;

    batch.set(getTaskDocRef(companyId, activeTask.key), activeTask);
  };

  /**
   * Set warning false and execute delete function
   */
  private agreeWarning = () => {
    this.setState(
      {
        isWarningOpen: false,
      },
      () => this.delete(),
    );
  };

  /**
   * Close the popup
   */
  private cancelWarning = () => {
    this.setState(
      {
        isWarningOpen: false,
      },
      () => this.props.onClose(),
    );
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<TaskDeleteDialogProps>,
) => {
  return {
    ...ownProps,
    companyId: state.company.companyId,
  };
};

const styles = (theme: Theme): StyleRules<string> => ({
  formControllLabel: {
    '& span': {
      fontSize: '16px',
    },
  },
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(TaskDeleteDialog),
);
