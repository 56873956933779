import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  WithTheme,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { AccessTime, LocationOn } from '@material-ui/icons';
import { Settings, TaskStatus, TaskWithKey, ViewState } from '@shared/schema';
import { generateDurationText } from '@shared/utils/timeUtils';
import * as React from 'react';
import ChatBadge from '../../components/ChatBadgeAvatar/ChatBadge';
import styles from './styles';

export interface ChatTaskListItemProps extends StyledComponentProps, WithTheme {
  openTaskFunction: (isExpanded: boolean, newItemKey: string) => void;
  open: boolean;
  avatarURL: string;
  userId: string;
  task: TaskWithKey;
  companyId: string;
  settings: Settings;
}

export class ChatTaskListItem extends React.Component<ChatTaskListItemProps> {
  constructor(props: ChatTaskListItemProps) {
    super(props);
  }

  public render() {
    const {
      classes = {},
      avatarURL,
      open,
      task,
      userId,
      companyId,
    } = this.props;

    const taskUserName = task.userName ? task.userName : '';
    const taskTimeFrame = generateDurationText(task, ViewState.TASKBANK);
    const taskKey = task.key ? task.key : '';
    const taskTitle =
      task.title && task.title.userModified
        ? task.title.value
        : task.jobtypeName;
    const taskCustomer = task.customerName ? task.customerName : undefined;
    const status =
      task.start < new Date().getTime() && task.status === TaskStatus.UNDONE
        ? 'late'
        : task.status.toLowerCase();
    const worksiteName = task.worksite ? task.worksite.name : undefined;
    const worksiteNick = task.worksite ? task.worksite.nick : '';
    const location = task.location ? task.location : undefined;

    return (
      <ExpansionPanel
        expanded={open}
        onChange={this.handlePanelOpenChange(taskKey)}
        className={classes[status]}
        data-test="expansionPanel"
      >
        <ExpansionPanelSummary
          id={taskKey}
          IconButtonProps={{ disableRipple: true }}
          data-test="expansionPanelSummary"
        >
          <ChatBadge
            avatarURL={avatarURL}
            userName={taskUserName}
            userId={userId}
            task={task}
            companyId={companyId}
            open={open}
            data-test="chatBadge"
          />
          <div className={classes.header}>
            <div className={classes.headerText}>{taskTitle}</div>
            {taskCustomer && (
              <div className={classes.subHeaderText}>{taskCustomer}</div>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <div>
            {worksiteName && (
              <div className={classes.worksite}>
                {`${worksiteName}${worksiteNick && `(${worksiteNick})`}`}
              </div>
            )}

            {location && (
              <div className={classes.chatTaskContainer}>
                <div className={classes.icon}>
                  <LocationOn data-test="locationIcon" />
                </div>
                <div>
                  {location
                    ? `${location.address},\n${location.postalCode} ${location.city}`
                    : ''}
                </div>
              </div>
            )}
            {taskTimeFrame && (
              <div className={classes.text}>
                <div className={classes.icon}>
                  <AccessTime data-test="timeIcon" />
                </div>
                <div>{taskTimeFrame}</div>
              </div>
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  /**
   * Calls props function to pass open task key to parent
   */
  private handlePanelOpenChange = (newItemKey: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    this.props.openTaskFunction(isExpanded, newItemKey);
  };
}

export default withStyles(styles, { withTheme: true })(ChatTaskListItem);
