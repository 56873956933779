import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  snackbar: {
    marginBottom: '2%',
  },

  snackbarContent: {
    background: '#1976D2',
    width: '545px',
  },

  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  infoIcon: {
    marginRight: '12px',
  },
});
