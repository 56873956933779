import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  paper: {
    backgroundColor: '#EDF5DC',
    color: 'white',
    maxWidth: '250px',
    padding: '5px',
  },
});

export default styles;
