import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  listContainer: {
    backgroundColor: theme.colors.grey,
    padding: '5px',
    maxHeight: '100%',
    height: 'calc(100% - 10px)',
    overflow: 'auto',
  },
  taskGridItem: {
    maxWidth: '95%',
    width: '95%',
  },
});

export default styles;
