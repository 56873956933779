import * as React from 'react';

import {
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { StyledComponentProps, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import RefreshIcon from '@material-ui/icons/Refresh';
import { i18n } from '@shared/locale/index';
import { Repetition, RepetitionType } from '@shared/schema';
import * as _ from 'lodash';
import { DatePicker } from 'material-ui-pickers';
import { Moment } from 'moment';
import moment from 'moment';
import { getRepeatCount } from 'utils/repeatingTasksUtils';
import { styles } from './styles';

interface RepeatOption {
  key: RepetitionType;
  name: string;
}

interface RepetitionEditorProps extends StyledComponentProps {
  repetitionEndDate?: Moment;
  startDate: Moment;
  isEnabled: boolean;
  onChange: any;
  repetition?: Repetition;
}

interface State {
  repeat: boolean;
  repetition: Repetition;
  selectedDate: Moment;
  repetitionExists: boolean;
}

const defaultRepetition: Repetition = {
  type: RepetitionType.WORKDAY,
  step: 1,
  count: 0,
};

class RepetitionEditor extends React.Component<RepetitionEditorProps, State> {
  constructor(props: RepetitionEditorProps) {
    super(props);

    const repetition = props.repetition ? props.repetition : defaultRepetition;
    this.state = {
      repeat: props.repetition != null,
      repetition,
      selectedDate: props.repetitionEndDate
        ? props.repetitionEndDate
        : props.startDate,
      repetitionExists: false,
    };
  }
  public componentDidMount() {
    const { repetition } = this.props;
    if (repetition) {
      this.setState({
        repetitionExists: true,
      });
    }
  }

  public componentDidUpdate(
    prevProps: RepetitionEditorProps,
    prevState: State,
  ) {
    const { repetition, repeat } = this.state;
    if (!this.props.onChange) {
      return;
    }
    if (prevState.repeat === false && repeat === true) {
      const selectedDate = moment(prevProps.startDate).add(1, 'days');

      const repeatCount: number = getRepeatCount(
        repetition,
        prevProps.startDate,
        moment(prevProps.startDate).add(1, 'days'),
      );

      const newRepetition = { ...repetition };
      newRepetition.count = repeatCount;

      const repeats: Repetition = { ...newRepetition };
      this.props.onChange(repeats, selectedDate);

      this.setState({
        selectedDate,
      });
    } else if (
      !_.isEqual(prevState.repetition, repetition) ||
      !_.isEqual(prevState.repeat, repeat)
    ) {
      if (repeat) {
        const repeats: Repetition = { ...repetition };
        this.props.onChange(repeats, this.state.selectedDate);
      } else {
        this.props.onChange(null, null);
      }
    }

    if (
      this.props.repetitionEndDate &&
      !_.isEqual(prevProps.repetitionEndDate, this.props.repetitionEndDate)
    ) {
      this.setState({ selectedDate: this.props.repetitionEndDate! });
    }
  }

  public onRepeatChecked = () => {
    const { repeat } = this.state;
    let repetition = this.state.repetition;
    /**
     * set defaultRepetition if user turns off repetition editor
     */
    if (repeat) {
      repetition = defaultRepetition;
    }

    this.setState({
      repeat: !this.state.repeat,
      repetition,
    });
  };

  public handleEndDateParamChange = (newEndDateParam: moment.Moment) => {
    const { startDate } = this.props;
    const { repetition, repeat, selectedDate } = this.state;

    const repeatCount: number = getRepeatCount(
      repetition,
      startDate,
      newEndDateParam,
    );

    const newRepetition = { ...repetition };
    newRepetition.count = repeatCount;

    if (repeat) {
      const repeats: Repetition = { ...newRepetition };
      this.props.onChange(repeats, selectedDate);
    }

    this.setState({
      selectedDate: newEndDateParam,
      repetition: newRepetition,
    });
  };

  public handleRepetitionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { startDate } = this.props;
    const { selectedDate, repetition } = this.state;
    const { target } = event;

    const newRepetition = { ...repetition };
    newRepetition.type = target.value as RepetitionType;

    const repeatCount: number = getRepeatCount(
      newRepetition,
      startDate,
      selectedDate,
    );
    newRepetition.count = repeatCount;

    this.setState({
      repetition: newRepetition,
    });
  };

  public render() {
    const { classes = {}, isEnabled } = this.props;
    const { repetitionExists } = this.state;
    const repeatOptions: RepeatOption[] = [
      {
        key: RepetitionType.WORKDAY,
        name: i18n().ui.weekday,
      },
      {
        key: RepetitionType.DAILY,
        name: i18n().ui.daily,
      },
      {
        key: RepetitionType.WEEKLY,
        name: i18n().ui.week,
      },
      {
        key: RepetitionType.MONTHLY,
        name: i18n().ui.month,
      },
    ];

    return (
      <ListItem
        disableGutters={true}
        className={classes.repetitionEditorContainer}
      >
        <ListItemIcon>
          <RefreshIcon />
        </ListItemIcon>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.repeat && isEnabled}
                onChange={this.onRepeatChecked}
                disabled={!isEnabled || repetitionExists}
              />
            }
            label={i18n().ui.repetition}
          />
        </FormControl>
        {this.state.repeat && isEnabled && (
          <>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: '16px' }}
            >
              <Select
                value={this.state.repetition.type}
                onChange={this.handleRepetitionChange}
                input={<Input name="type" id="type" />}
                disabled={repetitionExists}
              >
                {repeatOptions.map(option => (
                  <MenuItem
                    key={option.key}
                    value={option.key}
                    className={classes.field}
                  >
                    {repetitionExists ? (
                      <Typography className={classes.disabledField}>
                        {option.name}
                      </Typography>
                    ) : (
                      <ListItemText primary={option.name} />
                    )}
                  </MenuItem>
                ))}
                }
              </Select>
            </FormControl>
            <FormControl
              className={classes.formControl}
              style={{ marginBottom: '18px' }}
            >
              <div className={classes.formControlDate}>
                <DatePicker
                  id="date"
                  label={i18n().ui.end_date}
                  value={this.state.selectedDate}
                  onChange={this.handleEndDateParamChange}
                  animateYearScrolling={false}
                  invalidDateMessage={i18n().ui.give_valid_date}
                  format={'DD.MM.YYYY'}
                  mask={(value: string | RegExp) =>
                    value
                      ? [
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  cancelLabel={i18n().ui.cancel}
                  clearLabel={i18n().ui.clear}
                  keyboard
                  clearable
                  disableOpenOnEnter
                  disabled={repetitionExists}
                  minDateMessage={i18n().ui.min_date_message}
                  maxDateMessage={i18n().ui.max_date_message}
                  data-test="datepicker"
                />
              </div>
            </FormControl>
          </>
        )}
      </ListItem>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RepetitionEditor);
