import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  button: {
    display: 'flex',
    flex: 1,
    border: `1px solid ${theme.colors.black}`,
    height: '100%',
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    textTransform: 'none',
    minHeight: 'inherit',
  },
  downloadLink: {
    display: 'flex',
    width: '135px',
    minHeight: '45px',
    height: 'auto',
    textDecoration: 'none',
    color: 'black',
    margin: 'auto',
    marginTop: '20px',
  },
  heading: {
    margin: 'auto',
    textAlign: 'center',
  },
  exportCsvButton: {
    margin: 'auto',
  },
});

export default styles;
