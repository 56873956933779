import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  outerCircle: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    boxShadow: '0 0 0 5px black',
    transform: 'translate(-50%, -50%)',
  },
  innerCircle: {
    color: 'white',
    width: '34px',
    height: '34px',
    backgroundColor: 'black',
    textAlign: 'center',
    lineHeight: '34px',
    borderRadius: '50%',
    position: 'relative',
    top: '3px',
    left: '3px',
    fontSize: 'large',
    fontWeight: 'bold',
  },
});
export default styles;
