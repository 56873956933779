import { StyleRules, Theme } from '@material-ui/core/styles';
// TODO notify window width with min width

const styles = (theme: Theme): StyleRules<string> => ({
  root: {
    minWidth: window.innerWidth * 0.4,
  },
  field: {
    minWidth: '400px',
    minHeight: '40px',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  dateField: {
    width: '50%',
  },
  timeField: {
    width: '25%',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 300,
  },
  listText: {
    position: 'absolute',
    top: 0,
    // marginLeft: '16px'
  },
  listItemSmallText: {
    fontSize: '12px',
  },
  listTextWithIcon: {
    position: 'absolute',
    top: 0,
    marginLeft: '16px',
  },

  active: {
    // backgroundColor: 'rgba(255, 192, 30, 0.5)',
    color: 'rgba(255, 192, 30, 0.5)',
  },

  done: {
    // backgroundColor: 'green',
    color: 'green',
  },

  undone: {
    // backgroundColor: 'lightgray',
    color: 'lightgray',
  },

  late: {
    backgroundColor: 'red',
    color: 'white',
  },
  checkBoxLabel: {
     marginBottom: -10 
  },
  gridStylesLarge: {
    paddingRight: '165px',
  },
});

export default styles;
