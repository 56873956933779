import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StyledComponentProps,
  TextField,
  withStyles,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { i18n } from '@shared/locale';
import { setLocale, SupportedLocale } from '@shared/locale/index';
import { User, UserRole } from '@shared/schema';
import ACSelect from 'components/ACSelect';
import { ACSelectValue } from 'components/ACSelect/index';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingSpinner from 'components/LoadingSpinner';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import { NAVIGATION_PATH } from 'paths';
import * as React from 'react';
import GoogleButton from 'react-google-button';
import { connect, DispatchProp } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApplicationState } from 'reducers';
import { updateFirestoreAuthError } from 'reducers/auth/authActions';
import styled from 'styled-components';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
  validateForm,
} from 'ts-form-validation';
import * as validator from 'validator';
import { openRegistration } from '../../reducers/auth/authActions';
import RegisterPage from './RegisterPage';
import styles from './styles';

// tslint:disable: no-var-requires
const background = require('../../assets/images/dashboard_login_background.jpg');
const devLogo = require('../../assets/images/logo-dev.png');
const stagingLogo = require('../../assets/images/logo-staging.png');
const prodLogo = require('../../assets/images/logo.png');

const backgroundImage = `url(${background})`;

const LoginContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  width: 100%;
  font-weight: 400;
  padding-top: 1.5rem;
  background-color: #101e11;
  background-image: ${backgroundImage};
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: auto;
`;

export const LoginImage = styled('img')`
  width: 250px;
  display: block;
  margin: 0 auto 0 auto;
`;

export const LoginPaper = styled('div')`
  padding: 2rem 2rem 0.5rem 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: auto;
  background-color: none;
  color: white;
`;

export const LoginButtonContainer = styled('div')`
  margin: 10px 25% 0 25%;
`;

export const ResetButtonContainer = styled('div')`
  text-align: center;
`;

export const TextFieldInput = styled<any>(TextField)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 10px !important;
  display: flex !important;
`;

export const Environment = styled('div')`
  text-align: center;
  margin: 0.5rem;
  font-size: 1rem;
`;

const GoogleButtonPanel = styled('div')`
  margin: 1.5rem auto 1.5rem auto;
  align-items: center;
  padding-top: 1rem;
`;

export const DividerPanel = styled('div')`
  width: 75%;
  margin: 0 auto 0 auto;
  text-align: center;
`;

export const Label = styled('label')`
  padding-bottom: 2px;
`;

const Header5 = styled('h5')`
  overflow: hidden;
  text-align: center;
  ::before,
  ::after {
    background-color: white;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  ::before {
    right: 1.5em;
    margin-left: -50%;
  }

  ::after {
    left: 1.5em;
    margin-right: -50%;
  }
`;

export const LoginWithEmailContainer = styled('div')`
  width: 85%;
  margin: 0 auto 0 auto;
`;

export const PrivacyPolicyContainer = styled('div')`
  margin: 1.5rem;
  align-items: center;
  text-align: center;
  padding-top: 0.5rem;
`;

export const LocaleChangeContainer = styled('div')`
  margin: 1.5rem;
  align-items: center;
  text-align: center;
  padding-top: 0.5rem;
`;

export interface LoginPageProps
  extends DispatchProp,
    StyledComponentProps<any> {
  authenticating: boolean;
  firebaseError?: firebase.auth.Error;
  appUser?: User;
  registrationEnabled: boolean;
  activeRole: UserRole | undefined;
}

interface LogIn {
  email: string;
  password: string;
}

interface State {
  snackCanOpen: boolean;
  locale: SupportedLocale;
  loginForm: Form<LogIn>;
  forgotPassWordModalOpen: boolean;
  forgortPwModalEmail: string;
  forgotEmailIsValid: boolean | null;
  emailIsSent: boolean;
  sendButtonDisabled: boolean;
  checkEveryTime: boolean;
  snackMessage: string;
}

enum LogInFieldNames {
  EMAIL = 'email',
  PASSWORD = 'password',
}

const rules: FormValidationRules<LogIn> = {
  defaultMessages: {
    requiredField: () => i18n().auth.required_fields_login,
  },
  fields: {
    email: {
      required: true,
      validate: (value: string) =>
        !validator.isEmail(value) && {
          type: MessageType.ERROR,
          message: i18n().ui.invalid_email,
        },
    },
    password: {
      required: true,
    },
  },
  validateForm: form => {
    const messages = {};

    return { ...form, messages };
  },
};

export class LoginPage extends React.Component<LoginPageProps, State> {
  constructor(props: LoginPageProps) {
    super(props);

    const locale = localStorage.getItem('locale') as SupportedLocale;

    this.state = {
      snackCanOpen: false,
      forgotPassWordModalOpen: false,
      forgotEmailIsValid: null,
      forgortPwModalEmail: '',
      emailIsSent: false,
      sendButtonDisabled: false,
      checkEveryTime: false,
      snackMessage: '',
      locale: locale
        ? locale
        : navigator.language === SupportedLocale.FI
        ? navigator.language
        : SupportedLocale.EN,
      loginForm: initForm<LogIn>(
        {
          email: '',
          password: '',
        },
        rules,
      ),
    };
  }
  public componentDidMount() {
    localStorage.removeItem('isLogging');
  }
  public render() {
    const { firebaseError, appUser, classes = {} } = this.props;
    const {
      snackCanOpen,
      locale,
      forgotPassWordModalOpen,
      snackMessage,
    } = this.state;

    const localStorageIslogging = localStorage.getItem('isLogging');
    const locales = [
      { name: 'Suomi', value: SupportedLocale.FI },
      { name: 'English', value: SupportedLocale.EN },
    ];
    const isLogging = localStorageIslogging
      ? JSON.parse(localStorageIslogging)
      : false;

    const { values, isFormValid } = this.state.loginForm;
    if (appUser) {
      if (this.props.activeRole === UserRole.END_CUSTOMER) {
        return <Redirect to={`/${appUser.home}${NAVIGATION_PATH.REPORTS}`} />;
      }
      return <Redirect to={`/${appUser.home}${NAVIGATION_PATH.CUSTOMERS}`} />;
    }
    if (snackCanOpen || isLogging) {
      return <LoadingSpinner />;
    } else {
      return (
        <LoginContainer>
          {(this.props.registrationEnabled && <RegisterPage />) || (
            <LoginPaper>
              <LoginImage src={getLogo()} />
              <ErrorBoundary>
                {CONFIG === undefined ||
                  (CONFIG.build.environment !== 'production' && (
                    <Environment>
                      {CONFIG ? CONFIG.build.environment : 'develop'}
                    </Environment>
                  ))}
              </ErrorBoundary>
              <GoogleButtonPanel>
                <GoogleButton
                  type="light"
                  onClick={this.handleLoginClick}
                  label={i18n().auth.signin_with_google}
                  className={classes.googleButton}
                />
              </GoogleButtonPanel>
              {firebaseError && snackCanOpen && (
                <Snackbar
                  open={firebaseError && snackCanOpen}
                  message={
                    firebaseError
                      ? firebaseError.message
                      : 'Virhe kirjautumisessa'
                  }
                  action="close"
                  autoHideDuration={3000}
                />
              )}
              <DividerPanel>
                <Header5>{i18n().auth.or}</Header5>
              </DividerPanel>
              <LoginWithEmailContainer>
                <Label>{i18n().ui.email}</Label>
                <TextFieldInput
                  variant="filled"
                  type="email"
                  margin="dense"
                  placeholder={i18n().ui.email}
                  value={values.email}
                  onChange={this.handleChange(LogInFieldNames.EMAIL)}
                  onBlur={this.handleDialogBlur(LogInFieldNames.EMAIL)}
                  error={this.checkErrorStatus(LogInFieldNames.EMAIL)}
                  helperText={this.getErrorMessage(LogInFieldNames.EMAIL)}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                  FormHelperTextProps={{ classes: { error: classes.text } }}
                />
                <br />
                <Label>{i18n().auth.pw}</Label>
                <TextFieldInput
                  variant="filled"
                  type="password"
                  margin="dense"
                  placeholder={i18n().auth.pw}
                  value={values.password}
                  onChange={this.handleChange(LogInFieldNames.PASSWORD)}
                  onBlur={this.handleDialogBlur(LogInFieldNames.PASSWORD)}
                  error={this.checkErrorStatus(LogInFieldNames.PASSWORD)}
                  helperText={this.getErrorMessage(LogInFieldNames.PASSWORD)}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                  FormHelperTextProps={{ classes: { error: classes.text } }}
                />
                <br />
                <LoginButtonContainer>
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth={true}
                    onClick={this.onClickLogin}
                    disabled={!isFormValid}
                    classes={{
                      disabled: classes.disabled,
                    }}
                  >
                    <span style={{ color: isFormValid ? 'black' : 'grey' }}>
                      {i18n().ui.log_in}
                    </span>
                  </Button>
                </LoginButtonContainer>
                <br />
                <br />
                <ResetButtonContainer>
                  <Button
                    type="button"
                    variant="text"
                    classes={{ text: classes.text }}
                    onClick={this.showForgortPwDialog}
                  >
                    {i18n().auth.forgot_pw}
                  </Button>
                </ResetButtonContainer>
                <br />
                <ResetButtonContainer>
                  <Button
                    type="button"
                    variant="text"
                    classes={{ text: classes.text }}
                    onClick={this.handleRegisterClick}
                  >
                    {i18n().auth.register}
                  </Button>
                </ResetButtonContainer>
              </LoginWithEmailContainer>
              <LocaleChangeContainer>
                <ACSelect
                  options={locales.map(value => ({
                    value: value.value,
                    label: value.name,
                  }))}
                  value={{
                    value: locale || '',
                    label:
                      locale === SupportedLocale.FI ? 'Suomi' : 'English' || '',
                  }}
                  onChange={this.handleLanguageChange}
                  placeholder={i18n().ui.language_select}
                  whiteTheme={true}
                />
              </LocaleChangeContainer>
              <PrivacyPolicyContainer>
                <a
                  className={classes.privacy_policy_text}
                  href={i18n().links.privacy_policy}
                  target="_blank"
                >
                  {i18n().ui.privacy_policy}
                </a>
              </PrivacyPolicyContainer>
            </LoginPaper>
          )}

          {forgotPassWordModalOpen && this.returnForgotPasswordDialog()}
          {snackMessage !== '' && this.notificationSnacBar(snackMessage)}
        </LoginContainer>
      );
    }
  }

  /**
   * Checks when textfields input value changes and sets/updates it to states
   */
  private handleChange = (item: keyof LogIn) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const values = {
      ...this.state.loginForm.values,
      [item]: e.target.value,
    };

    const loginForm = validateForm(
      {
        ...this.state.loginForm,
        values,
      },
      {
        usePreprocessor: false,
      },
    );

    this.setState({
      loginForm,
    });
  };

  /**
   * Checks when user stops writing, so validation can be started
   * @param {LogIn} item
   */
  private handleDialogBlur = (item: keyof LogIn) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let loginForm = { ...this.state.loginForm };

    const filled = {
      ...loginForm.filled,
      [item]: true,
    };

    loginForm = validateForm({
      ...this.state.loginForm,
      filled,
    });

    this.setState({
      loginForm,
    });
  };

  private returnForgotPasswordDialog = () => {
    const { forgortPwModalEmail, sendButtonDisabled } = this.state;
    const { classes = {} } = this.props;

    return (
      <Dialog
        open={true}
        onClose={this.handleCloseDialog}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title">
          {i18n().auth.forgot_pw}
        </DialogTitle>
        <DialogContent>
          <Label>{i18n().auth.enter_email_for_recovery}</Label>
          <TextFieldInput
            variant="filled"
            type="email"
            margin="dense"
            value={forgortPwModalEmail}
            placeholder={i18n().auth.insert_email}
            onChange={this.handleEmailChange()}
            onBlur={this.handleEmailValidation()}
            error={this.checkEmailErrorStatus()}
            helperText={i18n().auth.email_bad_format}
            InputProps={{
              classes: { input: classes.input },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            {i18n().ui.cancel}
          </Button>
          <Button
            disabled={sendButtonDisabled}
            onClick={this.handleSendButton}
            color="primary"
          >
            {i18n().ui.send}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  private notificationSnacBar = (message: string) => {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={message !== ''}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          onClose={this.closeSnackBar}
          message={<span>{message}</span>}
        />
      </div>
    );
  };

  private handleCloseDialog = () => {
    this.setState({
      forgotPassWordModalOpen: false,
      forgortPwModalEmail: '',
      forgotEmailIsValid: null,
      sendButtonDisabled: false,
      checkEveryTime: false,
    });
  };

  private closeSnackBar = () => {
    this.setState({ snackMessage: '' });
  };

  private checkEmailErrorStatus = () => {
    const { forgotEmailIsValid, forgortPwModalEmail } = this.state;
    const isValid = validator.isEmail(forgortPwModalEmail);
    return forgotEmailIsValid === null ? forgotEmailIsValid : !isValid;
  };

  private handleSendButton = () => {
    const { forgortPwModalEmail } = this.state;
    const isValid = validator.isEmail(forgortPwModalEmail);
    let localizedErrorMessage: string = '';

    if (forgortPwModalEmail && isValid) {
      firebase.auth().languageCode =
        this.state.locale === SupportedLocale.FI ? 'fi' : 'en';
      firebase
        .auth()
        .sendPasswordResetEmail(forgortPwModalEmail)
        .then(() => {
          this.setState({
            forgotPassWordModalOpen: false,
            forgortPwModalEmail: '',
            forgotEmailIsValid: null,
            emailIsSent: true,
            snackMessage: i18n().auth.recovery_email_sent,
          });
        })
        .catch(err => {
          switch (err.message) {
            case 'There is no user record corresponding to this identifier. The user may have been deleted.':
              localizedErrorMessage = `${i18n().auth.no_user_record} ${
                i18n().auth.please_try_again
              }`;
              break;
            default:
              localizedErrorMessage = i18n().auth.something_went_wrong;
              break;
          }

          this.setState({
            snackMessage: localizedErrorMessage,
          });
        });
    } else {
      this.setState({
        sendButtonDisabled: !isValid,
        checkEveryTime: true,
        forgotEmailIsValid: isValid,
      });
    }
  };

  private showForgortPwDialog = () => {
    this.setState({
      forgotPassWordModalOpen: true,
    });
  };

  private handleEmailChange = () => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checkEveryTime } = this.state;
    const isValid = validator.isEmail(event.target.value);
    if (checkEveryTime) {
      this.setState({
        forgortPwModalEmail: event.target.value,
        sendButtonDisabled: !isValid,
        checkEveryTime: true,
      });
    } else {
      this.setState({
        forgortPwModalEmail: event.target.value,
      });
    }
  };

  private handleEmailValidation = () => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const forgotEmailIsValid = validator.isEmail(event.target.value);

    this.setState({
      forgotEmailIsValid,
      sendButtonDisabled: !forgotEmailIsValid,
      checkEveryTime: true,
    });
  };
  /**
   * Checks if validation messages contains error messages and returns boolean
   * to textFields error property
   * @param {LogInFieldNames} target
   */
  private checkErrorStatus = (target: LogInFieldNames): boolean => {
    const {
      messages: { [target]: message },
    } = this.state.loginForm;

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  /**
   * Returns message to specific input field by its name
   * @param {LogInFieldNames} target
   */
  private getErrorMessage = (target: LogInFieldNames) => {
    const {
      messages: { [target]: message },
    } = this.state.loginForm;

    return message && message.message;
  };

  private onClickLogin = async () => {
    const { email, password } = this.state.loginForm.values;
    let localizedErrorMessage: string = '';
    try {
      if (email && password) {
        try {
          await firebaseApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              this.setState({
                snackCanOpen: true,
              });
              localStorage.setItem('signInMethodGoogle', 'false');
            });
        } catch (error) {
          console.info(error);
          switch (error.message) {
            case 'The email address is badly formatted':
              localizedErrorMessage = `${i18n().auth.email_bad_format} ${
                i18n().auth.please_try_again
              }`;
              break;
            case 'The password is invalid or the user does not have a password.':
              localizedErrorMessage = `${i18n().auth.invalid_pw}! ${
                i18n().auth.please_try_again
              }`;
              break;
            case 'There is no user record corresponding to this identifier. The user may have been deleted.':
              localizedErrorMessage = `${i18n().auth.no_user_record} ${
                i18n().auth.please_try_again
              }`;
              break;
            default:
              localizedErrorMessage = `${i18n().auth.sigin_failed} ${
                i18n().auth.please_try_again
              }`;
              break;
          }
          localStorage.setItem('isLogging', 'false');
          this.setState({
            loginForm: {
              ...this.state.loginForm,
              values: {
                ...this.state.loginForm.values,
                email: '',
                password: '',
              },
            },
          });

          this.setState({
            snackCanOpen: false,
            snackMessage: localizedErrorMessage,
          });
        }
      }
    } catch (error) {
      console.error('Error signing in', error);
    }
  };

  /**
   * Handles locale language changes
   * @param {ACSelectValue} selected
   */
  private handleLanguageChange = (selected: ACSelectValue) => {
    const { value } = selected;

    localStorage.setItem('locale', value);
    setLocale(value as SupportedLocale);
    this.setState({ locale: value as SupportedLocale });
  };

  /**
   * Dispatch a registration state to redux
   */
  private handleRegisterClick = () => {
    this.props.dispatch(openRegistration(true));
  };

  /**
   * Handle login button click. Set state to logging (and localStorage) and sign in with Google
   */

  private handleLoginClick = () => {
    localStorage.setItem('isLogging', 'true');
    localStorage.setItem('signInMethodGoogle', 'true');
    this.setState({
      snackCanOpen: true,
    });

    const provider = new firebase.auth.GoogleAuthProvider();

    firebaseApp.auth().languageCode =
      this.state.locale === SupportedLocale.FI ? 'fi' : 'en';

    firebaseApp
      .auth()
      .signInWithRedirect(provider)
      .then(() => {
        this.setState({
          snackCanOpen: false,
        });
        localStorage.clearItem('isLogging');
      })
      .catch((error: firebase.auth.Error) => {
        this.props.dispatch(updateFirestoreAuthError(error));
      });
  };
}
/**
 * Get the correct logo by the current build environment
 */
export const getLogo = () => {
  let logo;
  switch (CONFIG.build.environment) {
    case 'development':
      logo = devLogo;
      break;
    case 'staging':
      logo = stagingLogo;
      break;
    case 'production':
      logo = prodLogo;
      break;
    default:
      logo = prodLogo;
      break;
  }

  return logo;
};
const mapStateToProps = (
  state: ApplicationState,
  ownProps: LoginPageProps,
): any => {
  return {
    ...ownProps,
    authenticating: state.auth.authenticating,
    firebaseError: state.auth.firebaseError,
    appUser: state.auth.appUser,
    registrationEnabled: state.auth.registrationEnabled,
    activeRole: state.auth.activeRole,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(LoginPage),
);
