import { Schema, TaskStatus } from '@shared/schema';
import { TaskWithKey } from '@shared/schema/index';
import { sumTotalTimerTime } from '@shared/utils/timeUtils';
import firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as _ from 'lodash';
import moment from 'moment';
import { docToJsonWithId } from './firestoreUtils';

/**
 * Returns reference to tasks table from firebase
 *
 * @param {string | undefined} companyId
 */
export function getTasksTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.TASKS);
}

export function getShiftsTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.SHIFTS);
}

/**
 * Returns reference to worksite table from firebase
 *
 * @param {string | undefined} companyId
 */
export function getWorksiteTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.WORKSITE);
}

/**
 * Returns reference to task instance with task id
 *
 * @param {string | undefined} companyId
 * @param {string || indefined} id?
 */
export function getTaskDocRef(
  companyId: string | undefined,
  id?: string | undefined,
) {
  return id
    ? getTasksTableRef(companyId).doc(id)
    : getTasksTableRef(companyId).doc();
}

/**
 * Tries to return the task with passed id
 *
 * @param {string | undefined}companyId
 * @param {string} taskId
 * @returns {Promise<TaskWithKey>}
 */
export async function getTaskWithId(
  companyId: string | undefined,
  taskId: string,
) {
  return new Promise<TaskWithKey>(async (resolve, reject) => {
    try {
      const doc = await getTaskDocRef(companyId, taskId).get();

      if (doc.exists) {
        const task = docToJsonWithId<TaskWithKey>(doc);

        resolve(task);
      } else {
        reject('Task does not exist for firebase document snapshot');
      }
    } catch (error) {
      console.error('Error retrieving the task');
      reject(error);
    }
  });
}

/**
 * Tries to set task to pause if it is active by changing task status and stopping active timer. Retrieve task with id, if task is not passed in parameters. If batch is passed, applies changes to a task in the batch also
 *
 * Implementation may seem the same as pauseTaskWithBatch, but this function put task status to pause only if it is active, while pauseTaskWithBatch put it to puase no matter what.
 * Acts as secured solution for handling errors, when member has activeTask record, but in the tasks table this task in done or undone status, in this case it is pointless to set it to paused and this function just check that activeTimer is stopped.
 *
 * @param {string | undefined} companyId
 * @param {string} taskId
 * @param {firebase.firestore.WriteBatch} batch Optional batch to write update task in the batch too
 * @param {TaskWithKey} task Optional task that can be provided. If not, retrieve fresh copy from firebae in this function with passed id
 * @returns {Promise<TaskWithKey>}
 */
export async function deactivateTaskWithBatch(
  companyId: string | undefined,
  taskId: string,
  batch?: firebase.firestore.WriteBatch,
  task?: TaskWithKey,
): Promise<TaskWithKey> {
  return new Promise<TaskWithKey>(async (resolve, reject) => {
    try {
      if (task === undefined || _.isEmpty(task)) {
        task = await getTaskWithId(companyId, taskId);
      }
      if (task !== undefined && !_.isEmpty(task)) {
        if (task.status === TaskStatus.ACTIVE) {
          task.status = TaskStatus.PAUSED;
        }

        task = stopActiveTimerForTaskWithBatch(task, companyId, batch);

        resolve(task);
      } else {
        reject('Task doesn not exist for deactivating');
      }
    } catch (error) {
      reject(`Error retrieving the task from db for deactivating: ${error}`);
    }
  });
}

/**
 * Tries to set task to pause by changing task status and stopping active timer. Retrieve task with id, if task is not passed in parameters. If batch is passed, applies changes to a task in the batch also
 *
 * @param {string | undefined} companyId
 * @param {string} taskId
 * @param {firebase.firestore.WriteBatch} batch Optional batch to write update task in the batch too
 * @param {TaskWithKey} task Optional task that can be provided. If not, retrieve fresh copy from firebae in this function with passed id
 * @returns {Promise<TaskWithKey>}
 */
export async function pauseTaskWithBatch(
  companyId: string | undefined,
  taskId: string,
  batch?: firebase.firestore.WriteBatch,
  task?: TaskWithKey,
): Promise<TaskWithKey> {
  return new Promise<TaskWithKey>(async (resolve, reject) => {
    try {
      if (task === undefined || _.isEmpty(task)) {
        task = await getTaskWithId(companyId, taskId);
      }
      if (task !== undefined && !_.isEmpty(task)) {
        task.status = TaskStatus.PAUSED;

        task = stopActiveTimerForTaskWithBatch(task, companyId, batch);

        resolve(task);
      } else {
        reject('Task doesn not exist for pausing');
      }
    } catch (error) {
      reject(`Error retrieving the task from db for pausing: ${error}`);
    }
  });
}

/**
 * Stop active timer and add a new record to timerLog for passed task. Optionally applies changes to passed batch
 *
 * @param {TaskWithKey} task
 * @param {string} companyId Optional company id for saving changes to batch
 * @param {firebase.firestore.WriteBatch} batch Optional batch to save changes
 * @returns {TaskWithKey}
 */
export function stopActiveTimerForTaskWithBatch(
  task: TaskWithKey,
  companyId?: string | undefined,
  batch?: firebase.firestore.WriteBatch,
) {
  if (task.activeTimer !== undefined) {
    task.activeTimer.ended = moment().valueOf();

    if (task.timerLog === undefined) {
      task.timerLog = [];
    }

    task.timerLog.push(task.activeTimer);

    task.timerTotal = sumTotalTimerTime(task.timerLog);

    task.activeTimer = firebase.firestore.FieldValue.delete() as any;

    if (batch !== undefined && companyId !== undefined) {
      batch.update(getTaskDocRef(companyId, task.key || task.id), task);
    }
  }

  return task;
}

/**
 * Gets users own logs from task data
 * @param {TaskWithKey} task
 * @returns User's total logged time
 */
export function getUserTimerLogsDuration(
  task: TaskWithKey,
  onlyDuration?: boolean,
  selectedMemberId?: string,
) {
  let total = 0;
  if (task.timerLog) {
    task.timerLog.forEach(log => {
      if (
        (selectedMemberId && log.ended && log.memberId === selectedMemberId) ||
        (selectedMemberId === undefined && log.ended)
      ) {
        const duration = log.ended - log.started;
        total += duration;
      }
    });
  }
  if (
    task.timerTotalFixed &&
    selectedMemberId &&
    Array.isArray(task.timerTotalFixed) &&
    onlyDuration !== true
  ) {
    const found = task.timerTotalFixed.find(
      fix => fix.memberId === selectedMemberId,
    );
    if (found) {
      total += found.value;
    }
  }
  return total;
}
/* export function getUserTimerLogsDuration(
  task: TaskWithKey,
  onlyDuration?: boolean,
  selectedMember?: MemberWithKey,
) {
  let total = 0;
  if (task.timerLog) {
    task.timerLog.forEach(log => {
      if (
        (selectedMember && log.ended && log.memberId === selectedMember.key) ||
        (selectedMember === undefined && log.ended)
      ) {
        const duration = log.ended - log.started;
        total += duration;
      }
    });
  }
  if (
    task.timerTotalFixed &&
    selectedMember &&
    Array.isArray(task.timerTotalFixed) &&
    onlyDuration !== true
  ) {
    const found = task.timerTotalFixed.find(
      fix => fix.memberId === selectedMember.key,
    );
    if (found) {
      total += found.value;
    }
  }
  return total;
} */

export function getTaskWorkingTime(task: TaskWithKey) {
  let workingTime = 0;

  if (task.timerLog) {
    task.timerLog.forEach(log => {
      if (log.ended) {
        const duration = log.ended - log.started;
        workingTime += duration;
      }
    });
  }

  if (task.timerTotalFixed && Array.isArray(task.timerLog)) {
    try {
      task.timerTotalFixed.forEach(timer => {
        workingTime += timer.value;
      });
    } catch (err) {
      console.error(
        `Error at reading timerTotalFixed for taskId: ${task.key} `,
        err,
      );
    }
  } else if (task.timerTotalFixed && !Array.isArray(task.timerLog)) {
    const timer = task.timerTotalFixed as any;
    workingTime += timer;
  }

  return workingTime;
}

export function getAllUsersIdsFromTimerLogs(task: TaskWithKey) {
  let listOfTaskUsersIds: string[] = [];

  if (task.timerLog) {
    listOfTaskUsersIds = _.uniq(
      task.timerLog.map(log => log.memberId),
    ) as string[];
  }

  return listOfTaskUsersIds;
}

export function getTaskStartedTimestampForUserId(
  task: TaskWithKey,
  userId: string,
) {
  let startDate;
  if (task.timerLog) {
    const userLogs = task.timerLog.filter(log => log.memberId === userId);

    if (userLogs) {
      startDate = userLogs.sort((log1, log2) => log1.started - log2.started)[0]
        .started;
    }
  }

  return startDate;
}

export function getTaskEndedTimestampForUserId(
  task: TaskWithKey,
  userId: string,
) {
  let startDate;
  if (task.timerLog) {
    const userLogs = task.timerLog.filter(
      log => log.memberId === userId && log.ended,
    );

    if (userLogs) {
      startDate = userLogs.sort((log1, log2) => log1.ended! - log2.ended!)[
        userLogs.length - 1
      ].ended;
    }
  }

  return startDate;
}
