import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<string> => ({
  groupContainer: {
    margin: '5px',
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
    flexWrap: 'nowrap',
  },
  taskGridContainer: {
    maxHeight: '100%',
  },
  chatView: {
    maxHeight: '100%',
  },
});

export default styles;
