import Badge from '@material-ui/core/Badge';
import PeopleIcon from '@material-ui/icons/People';
import { Schema } from '@shared/schema';
import firebaseApp from 'firebaseApp';
import * as React from 'react';

export interface ResourceBadgeProps {
  classes: any;
  companyId: string;
}

interface State {
  count: number;
}

class ResourceBadge extends React.PureComponent<ResourceBadgeProps, State> {
  public state = { count: 0 };
  private unsubscribeResources: () => void;
  public componentDidMount = () => {
    const { companyId } = this.props;

    this.unsubscribeResources = firebaseApp
      .firestore()
      .collection(Schema.COMPANIES)
      .doc(companyId)
      .collection(Schema.MEMBERS)
      .where('role', '==', 'p')
      .onSnapshot(snapshot => {
        this.setState({
          count: snapshot.size,
        });
      });
  };

  public componentWillUnmount = () => {
    // Unsubscribe firestore when component is unmounted
    this.unsubscribeResources && this.unsubscribeResources();
  };

  public render() {
    const { classes } = this.props;
    const { count } = this.state;

    // If there is pending users show pending user badge
    if (count > 0) {
      return (
        <Badge className={classes.badge} badgeContent={count} color="primary">
          <PeopleIcon />
        </Badge>
      );
    }

    // If no pending users show only people icon
    return <PeopleIcon />;
  }
}

export default ResourceBadge;
