import { DurationFormatSettings } from '../moment';

export class DurationFormatPreset {
  /**
   * Disable trimming
   */
  public static readonly NoTrim: DurationFormatSettings = {
    trim: false,
  };

  /**
   * Disable grouping (thousand separator).
   */
  public static readonly NoGrouping: DurationFormatSettings = {
    useToLocaleString: false,
    useGrouping: false,
  };

  /**
   * General settings for duration hh:mm:ss based templates.
   *
   * Basically NoTrim + NoGrouping
   */
  public static readonly General: DurationFormatSettings = {
    ...DurationFormatPreset.NoTrim,
    ...DurationFormatPreset.NoGrouping,
  };

  /**
   * Formats duration in h:mm:ss and also disables trimming and grouping
   */
  public static readonly HMMSS: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'h:mm:ss',
  };

  /**
   * Formats duration in hh:mm:ss and also disables trimming and grouping
   */
  public static readonly HHMMSS: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'hh:mm:ss',
  };

  /**
   * Formats duration in h:mm and also disables trimming and grouping
   */
  public static readonly HMM: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'h:mm',
  };

  /**
   * Formats duration in hh:mm and also disables trimming and grouping
   */
  public static readonly HHMM: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'hh:mm',
  };

  /**
   * Formats in duration m:ss and also disables trimming and grouping
   */
  public static readonly MSS: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'm:ss',
  };

  /**
   * Formats in duration mm:ss and also disables trimming and grouping
   */
  public static readonly MMSS: DurationFormatSettings = {
    ...DurationFormatPreset.General,
    template: 'mm:ss',
  };

  private constructor() {}
}
