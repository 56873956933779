import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 1rem',
    alignItems: 'center',
    minHeight: '70px',
    marginTop: '10px',
  },
  headerText: {
    fontWeight: 'bold',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '100%',
    minWidth: '10%',
  },
  memberList: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
});

export default styles;
