import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { i18n } from '@shared/locale';
import { DialogStatus, HourTypeWithKey, Schema } from '@shared/schema';
import LoadingSpinner from 'components/LoadingSpinner';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import {
  Form,
  FormValidationRules,
  initForm,
  MessageType,
  validateForm,
} from 'ts-form-validation';
import * as validator from 'validator';

interface HourTypeDialogProps {
  dialogTitle: string;
  dialogType: DialogStatus | undefined;
  companyId: string;
  modifiedHourType: HourTypeWithKey | undefined;
  hourTypes: HourTypeWithKey[];
  handleCloseDialog: () => void;
  updateHourTypes: (hourTypes: HourTypeWithKey[], companyId: string) => void;
}

interface State {
  loader: boolean;
  form: Form<HourTypeWithKey>;
}

enum HourTypeDialogInputNames {
  NAME = 'name',
  MULTIPLIER = 'multiplier',
}

/**
 * Checks that given value is between 0.01-99.99 and parses it to float.
 * Returns value with two decimals
 * @param value
 */
const checkMultiplierValue = (value: string): string => {
  let checkedMultiplier = parseFloat(value);

  if (checkedMultiplier >= 100) {
    checkedMultiplier = 99.99;
  }
  if (checkedMultiplier <= 0) {
    checkedMultiplier = 0.01;
  }

  return checkedMultiplier.toFixed(2).toString();
};

const rules: FormValidationRules<HourTypeWithKey> = {
  fields: {
    name: {
      required: true,
      validate: (value: string) =>
        !validator.isLength(value, { min: 3, max: 30 }) && {
          type: MessageType.ERROR,
          message: i18n().ui.hour_type_length_must_be,
        },
    },
    multiplier: {
      required: false,
      preprocess: (value: string) => checkMultiplierValue(value),
      validate: (value: string | number) => {
        switch (typeof value) {
          case 'number':
            return false;

          case 'string': {
            if (!validator.isFloat(value)) {
              return {
                type: MessageType.ERROR,
                message: i18n().ui.please_enter_valid_hour_type,
              };
            }
            break;
          }
          default:
            console.error(
              `Unsupported datat type: ${typeof value} at multiplier validator.`,
            );
        }
        return false;
      },
    },
    default: {
      required: false,
    },
  },
  validateForm: form => {
    const messages = {};

    return { ...form, messages };
  },
};

export default class HourTypeDialog extends React.Component<
  HourTypeDialogProps,
  State
> {
  constructor(props: HourTypeDialogProps) {
    super(props);
    this.state = {
      loader: false,
      form: initForm<HourTypeWithKey>(
        {
          name: '',
          multiplier: 1,
          default: false,
        },
        rules,
      ),
    };
  }

  public componentDidMount() {
    const { dialogType, modifiedHourType } = this.props;

    if (
      dialogType ===
      DialogStatus.MODIFY /* ||
      this.props.modifiedHourType */
    ) {
      const values = modifiedHourType;
      if (values) {
        const form = validateForm({
          ...this.state.form,
          values: {
            key: values.key,
            name: values.name,
            multiplier: values.multiplier,
            default: values.default,
          },
        });

        this.setState({
          form,
        });
      }
    }
  }

  public render() {
    const { dialogTitle, handleCloseDialog } = this.props;

    const { values, isFormValid } = this.state.form;

    const { loader } = this.state;

    // console.log(this.state.form);

    return (
      <Dialog open={true}>
        {loader ? (
          <LoadingSpinner />
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={i18n().ui.hour_type_name}
                type="text"
                fullWidth
                onChange={this.handleDialogChange(
                  HourTypeDialogInputNames.NAME,
                )}
                onBlur={this.handleDialogBlur(HourTypeDialogInputNames.NAME)}
                value={values.name}
                error={this.checkErrorStatus(HourTypeDialogInputNames.NAME)}
                helperText={this.getErrorMessage(HourTypeDialogInputNames.NAME)}
              />
              <TextField
                style={{ display: 'none' }}
                margin="dense"
                id="multiplier"
                label={i18n().ui.hour_multiplier}
                type="number"
                InputProps={{ inputProps: { step: '0.01' } }}
                fullWidth
                onChange={this.handleDialogChange(
                  HourTypeDialogInputNames.MULTIPLIER,
                )}
                onBlur={this.handleDialogBlur(
                  HourTypeDialogInputNames.MULTIPLIER,
                )}
                value={values.multiplier}
                error={this.checkErrorStatus(
                  HourTypeDialogInputNames.MULTIPLIER,
                )}
                helperText={this.getErrorMessage(
                  HourTypeDialogInputNames.MULTIPLIER,
                )}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.form ? this.state.form.values.default : false
                    }
                    onChange={this.handleSwitchChange}
                    value="switch"
                  />
                }
                label={i18n().ui.default}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>{i18n().ui.cancel}</Button>
              <Button
                onClick={this.handleSaveClick(this.props.dialogType)}
                color="secondary"
                disabled={!isFormValid}
              >
                {i18n().ui.save}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }

  /**
   * Returns message to specific input field by its name
   */
  private getErrorMessage = (target: HourTypeDialogInputNames) => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    return message && message.message;
  };

  /**
   * Checks if validation messages contains error messages and returns boolean
   * to textFields error property
   */
  private checkErrorStatus = (target: HourTypeDialogInputNames): boolean => {
    const {
      messages: { [target]: message },
    } = this.state.form;

    if (message && message.type === MessageType.ERROR) {
      return true;
    }
    return false;
  };

  /**
   * Checks when user stops writing, so validation can be started
   */
  private handleDialogBlur = (key: keyof HourTypeWithKey) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let form = { ...this.state.form };
    const filled = {
      ...form.filled,
      [key]: true,
    };

    form = validateForm({
      ...this.state.form,
      filled,
    });

    this.setState({
      form,
    });
  };

  /**
   * Checks when textfields input value changes and sets/updates it to states
   */
  private handleDialogChange = (item: keyof HourTypeWithKey) => (
    e: React.BaseSyntheticEvent,
  ) => {
    const values = {
      ...this.state.form.values,
      [item]: e.target.value,
    };

    const form = validateForm(
      {
        ...this.state.form,
        values,
      },
      {
        usePreprocessor: false,
      },
    );

    this.setState({
      form,
    });
  };

  /**
   * Checks when switch is changes and sets/updates it to states
   */
  private handleSwitchChange = (e: React.BaseSyntheticEvent) => {
    const { form } = this.state;

    if (form) {
      const values = {
        ...form.values,
        default: e.target.checked,
      };

      this.setState({
        form: {
          ...form,
          values,
        },
      });
    }
  };

  /**
   * Gets all data from states and props and saves them to firebase database
   * Updates default value if its has been changed to true
   */
  private saveNewHourType = () => {
    const { form } = this.state;
    const { hourTypes, companyId } = this.props;

    if (form) {
      if (form.values.default === true) {
        this.props.updateHourTypes(hourTypes, companyId);
      }
      const ref = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.HOURTYPES)
        .doc();

      const key = ref.id;

      const newHourTypeToBeSaved: HourTypeWithKey = {
        key,
        name: form.values.name,
        multiplier: form.values.multiplier,
        default: form.values.default,
      };

      this.setState({ loader: true });

      ref
        .set(newHourTypeToBeSaved)
        .then(() => {
          this.props.handleCloseDialog();
        })
        .catch(error => {
          console.log('Adding new hourType failed', error);
          this.setState({ loader: false });
        });
    }
  };
  /**
   * Gets all data from states and props and saves them to firebase database
   * Updates default value if its has been changed to true
   */
  private saveModifyHourType = () => {
    const { form } = this.state;
    const { hourTypes, companyId } = this.props;

    if (form) {
      if (form.values.default === true) {
        this.props.updateHourTypes(hourTypes, companyId);
      }
      const modifiedHourTypeToBeSaved: HourTypeWithKey = {
        key: form.values.key,
        name: form.values.name,
        multiplier: form.values.multiplier,
        default: form.values.default,
      };

      this.setState({ loader: true });

      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.HOURTYPES)
        .doc(form.values.key)
        .update(modifiedHourTypeToBeSaved)
        .then(() => {
          this.props.handleCloseDialog();
        })
        .catch(error => {
          console.log('Modifying existing hourtype failed', error);
          this.setState({ loader: false });
        });
    }
  };

  /**
   * Start specific saving method by what type dialogStatus is
   */
  private handleSaveClick = (dialogStatus: DialogStatus | undefined) => () => {
    if (dialogStatus === DialogStatus.NEW) {
      this.saveNewHourType();
    }
    if (dialogStatus === DialogStatus.MODIFY) {
      this.saveModifyHourType();
    }
  };
}
