import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const styles = (theme: Theme): StyleRules<string> => ({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 1rem',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    minHeight: '70px',
  },
  timeSelectionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  timePeriodSelection: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  dateLine: {
    marginLeft: '7px',
    marginRight: '7px',
    height: '7px',
    alignSelf: 'center',
  },
  dateText: {
    textAlign: 'end',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    marginRight: '15px',
    width: '99px',
    fontWeight: 300,
    fontSize: '14px',
  },
  formControl: {
    width: '15%',
    '& div': {
      '& p': {
        fontSize: '16px',
        fontWeight: 'lighter',
      },
    },
    paddingLeft: '1rem',
  },
  RadioButtonRoot: {
    marginLeft: '5%',
  },
  dateTime: {
    '& div': {
      '& fieldset': {
        borderRadius: '0px',
      },
    },
  },
  calendar: {
    maxHeight: '35px',
    backgroundColor: 'white',
    maxWidth: '185px',
    borderRadius: 0,
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    display: 'none',
  },
  button: {
    display: 'flex',

    border: `1px solid ${theme.colors.black}`,
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    textTransform: 'none',
  },
  downloadLink: {
    display: 'flex',
    marginRight: '30px',
    width: '135px',
    minHeight: '45px',
    height: 'auto',
    marginLeft: 'auto',
    textDecoration: 'none',
    color: 'black',
  },
});

export default styles;
