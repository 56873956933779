import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import globalTheme from 'theme';

export const styles = (theme: Theme): StyleRules<string> => ({
  /* BASIC HTML TAG STYLES */
  h3: {
    margin: '0 0 0 0',
    fontWeight: 'normal',
    alignSelf: 'center',
  },
  h4: {
    margin: '0px',
    textDecoration: 'none',
    fontWeight: 'normal',
    wordBreak: 'break-word',
  },
  p: {
    margin: '0px',
    fontSize: '14px',
    wordBreak: 'break-word',
  },

  /* MAIN COMPONENTS STYLES */
  main: {
    height: 'calc(100% - 56px)',
    overflow: 'auto',
    margin: '10px',
  },
  overflowContainer: {
    display: 'flex',
  },
  listheader: {
    position: 'sticky',
    padding: '3%',
    top: '-1px',
    backgroundColor: '#d8d8d8',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    marginBottom: '10px',
    display: 'flex',
    width: '100%',
    height: '56px',
    zIndex: 100,
  },
  list: {
    backgroundColor: globalTheme.colors.grey,
    borderRadius: '5px',
    flex: 1,
    margin: '0 10px 0 10px',
  },

  rightSide: {
    display: 'inline-block',
    height: '100%',
    width: '20%',
    float: 'right',
    textAlign: 'center',
    margin: 0,
  },
  leftSide: {
    display: 'inline-block',
    height: '100%',
    width: '80%',
    margin: '0px',
    h3: {
      margin: '0px',
      textDecoration: 'none',
      fontWeight: 'normal',
    },
  },

  /* SINGLE ITEM STYLES */
  item: {
    userSelect: 'none',
    padding: 8 * 2,
    margin: '0 6px 8px 6px',
    borderRadius: '5px',
    '&:hover': {
      background: '#F5F5F5',
    },
    overflowWrap: 'anywhere',
    display: 'flex',
    minHeight: '122px',
    position: 'relative',
  },
  itemIsdragging: {
    background: '#8e8e8e',
  },
  itemNodragging: {
    background: 'white',
  },

  /* ICON STYLES */
  avatarIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
  moreIcon: {
    position: 'absolute',
    top: '1%',
    right: 0,
  },
  headerIcon: {
    marginLeft: '2%',
    alignSelf: 'center',
  },

  /* STATUS COLORS */
  active: {
    borderBottom: `5px solid ${globalTheme.timeline.status.active.background}`,
  },
  undone: {
    borderBottom: `5px solid #323232`,
  },
  late: {
    borderBottom: `5px solid ${globalTheme.timeline.status.late.background}`,
  },
  done: {
    borderBottom: `5px solid ${globalTheme.timeline.status.done.background}`,
  },
  paused: {
    borderBottom: `5px solid ${globalTheme.timeline.status.paused.background}`,
  },

  /* PRIORITY COLORS AND STYLES*/
  high: {
    color: globalTheme.priorities.high.color,
  },
  low: {
    color: globalTheme.priorities.low.color,
  },
  priority: {
    marginBottom: '30%',
  },
  priorityIconArea: {
    width: '100%',
    height: '41px',
  },
});
