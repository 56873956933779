import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { i18n } from '@shared/locale';
import * as React from 'react';

interface RepeatingTaskWarningProp {
  onAgree?: () => void;
  onCancel?: () => void;
  ratioOfActiveTasks: string;
  warningText: string;
}

class RepeatingTaskWarning extends React.Component<RepeatingTaskWarningProp> {
  constructor(props: RepeatingTaskWarningProp) {
    super(props);
  }

  public render() {
    const { onAgree, onCancel, ratioOfActiveTasks, warningText } = this.props;

    return (
      <Dialog
        open={true}
        onClose={() => {
          onCancel && onCancel();
        }}
      >
        <DialogTitle>{i18n().ui.warning}</DialogTitle>
        <DialogContent style={{ fontSize: '16px' }}>
          {`${ratioOfActiveTasks} ${warningText}`}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            {i18n().ui.cancel}
          </Button>
          <Button
            onClick={() => {
              onAgree && onAgree();
            }}
          >
            {i18n().ui.yes}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default RepeatingTaskWarning;
