import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SupportedLocale } from '@shared/locale/index';
import Containers from 'containers';
import Header from 'Header';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import * as React from 'react';

class App extends React.Component {
  public render() {
    const locale = localStorage.getItem('locale') as SupportedLocale;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
        <Header />
        <CssBaseline />
        <Containers />
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
