import { CompanyId, Worksite } from '@shared/schema';
import { ActionCreator, AnyAction } from 'redux';

export const enum VenueActionType {
  VENUES_NEW = 'VENUES_NEW',
  VENUES_CANCEL = 'VENUES_CANCEL',
  VENUES_CREATE = 'VENUES_CREATE',
  VENUES_UPDATE = 'VENUES_UPDATE',
}

export interface NewVenueAction extends AnyAction {
  type: VenueActionType.VENUES_NEW;
  payload: {
    companyId: CompanyId;
    venue: Worksite;
  };
}

export interface CancelVenueAction extends AnyAction {
  type: VenueActionType.VENUES_CANCEL;
}

// export interface CreateVenueAction extends AnyAction {
//   type: VenueActionType.VENUES_CREATE;
//   payload:{
//     companyId: CompanyId;
//     venue: Venue;
//   }
// }

export interface UpdateVenueAction extends AnyAction {
  type: VenueActionType.VENUES_UPDATE;
  payload: {
    companyId: CompanyId;
    venue: Worksite;
  };
}

export const newVenue: ActionCreator<NewVenueAction> = (
  companyId: CompanyId,
  venue: Worksite,
) => {
  return {
    type: VenueActionType.VENUES_NEW,
    payload: {
      companyId,
      venue,
    },
  };
};

export const cancelEdit: ActionCreator<CancelVenueAction> = () => {
  return {
    type: VenueActionType.VENUES_CANCEL,
  };
};

// export const createVenue: ActionCreator<CreateVenueAction> = (
//   companyId: CompanyId,
//   venue: Venue
//   ) => {
//   return {
//     type: VenueActionType.VENUES_NEW,
//     payload: {
//       companyId,
//       venue
//     }
//   };
// };

export const updateVenue: ActionCreator<UpdateVenueAction> = (
  companyId: CompanyId,
  venue: Worksite,
) => {
  return {
    type: VenueActionType.VENUES_UPDATE,
    payload: {
      companyId,
      venue,
    },
  };
};

export type VenueAction =
  | AnyAction
  | NewVenueAction
  | CancelVenueAction
  // | CreateVenueAction
  | UpdateVenueAction;
