import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import { StyledComponentProps, StyleRules } from '@material-ui/core/styles';
import { i18n } from '@shared/locale';
import { RepeatingTasks, TaskStatus, TaskWithKey } from '@shared/schema';
import { MemberWithKey } from '@shared/schema/index';
import MultiPurposeDialog from 'components/MultiPurposeDialog';
import * as firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import {
  collectionToJsonWithIds,
  deleteUndefinedValues,
} from 'utils/firestoreUtils';
import {
  getRepeatingTasksDocRef,
  getThisAndFollowingRepeatingTasks,
} from 'utils/repeatingTasksUtils';
import { getTasksTableRef } from 'utils/tasksUtil';
import RepeatingTaskWarning from './RepeatingTaskWarning';

enum RepeatingTaskUpdateOption {
  updateThis = 'updateThis',
  updateFollowing = 'updateFollowing',
  updateAll = 'updateAll',
}

interface RepeatingTaskUpdateDialogProps
  extends StyledComponentProps,
    DispatchProp {
  onCancel: () => void;
  onSave?: () => void;
  saveRequest?: (tasks: TaskWithKey[]) => void;
  companyId?: string;
  initialTask: TaskWithKey;
  updatedTask: TaskWithKey;
  taskMember: MemberWithKey | undefined;
}

interface State {
  selectedOption: RepeatingTaskUpdateOption;
  tasksToUpdate: TaskWithKey[];
  repeatingTasks: RepeatingTasks;
  isMemberChangeWarningOpen: boolean;
  ratioOfActiveTasks: string;
  isOnlyOneActiveTaskForUserDialogOpen: boolean;
  isOnlyEditedTaskCanBeActiveDialogOpen: boolean;
}

class RepeatingTaskUpdateDialog extends React.Component<
  RepeatingTaskUpdateDialogProps,
  State
> {
  constructor(props: RepeatingTaskUpdateDialogProps) {
    super(props);

    this.state = {
      selectedOption: RepeatingTaskUpdateOption.updateThis,
      tasksToUpdate: [],
      repeatingTasks: { tasks: [] },
      isMemberChangeWarningOpen: false,
      ratioOfActiveTasks: '',
      isOnlyOneActiveTaskForUserDialogOpen: false,
      isOnlyEditedTaskCanBeActiveDialogOpen: false,
    };
  }

  public render() {
    const { classes = {}, onCancel } = this.props;
    const {
      isOnlyOneActiveTaskForUserDialogOpen,
      isOnlyEditedTaskCanBeActiveDialogOpen,
    } = this.state;

    return (
      <>
        <Dialog open={true} onClose={onCancel}>
          <DialogTitle>{i18n().ui.update_recurring_task}</DialogTitle>
          <DialogContent>
            <RadioGroup
              value={this.state.selectedOption}
              onChange={(e: React.ChangeEvent<{}>, value: string) =>
                this.setState({
                  selectedOption: value as RepeatingTaskUpdateOption,
                })
              }
            >
              <FormControlLabel
                className={classes.formControllLabel}
                value={RepeatingTaskUpdateOption.updateThis}
                control={<Radio />}
                label={i18n().ui.this_task}
              />

              <FormControlLabel
                className={classes.formControllLabel}
                value={RepeatingTaskUpdateOption.updateFollowing}
                control={<Radio />}
                label={i18n().ui.this_task_and_following}
              />

              <FormControlLabel
                className={classes.formControllLabel}
                value={RepeatingTaskUpdateOption.updateAll}
                control={<Radio />}
                label={i18n().filters.all_tasks}
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>{i18n().ui.cancel}</Button>
            <Button onClick={this.handleSave} color={'primary'} autoFocus>
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.isMemberChangeWarningOpen && (
          <RepeatingTaskWarning
            onAgree={this.hideMemberChangeWarning}
            onCancel={onCancel}
            ratioOfActiveTasks={this.state.ratioOfActiveTasks}
            warningText={i18n().ui.repeating_task_member_change_warning}
          />
        )}
        <MultiPurposeDialog
          open={isOnlyOneActiveTaskForUserDialogOpen}
          dialogTitle={i18n().ui.another_task_active}
          dialogContent={i18n().ui.start_another_task_question}
          handleAccept={this.handleSave}
          handleCloseDialog={onCancel}
        />
        <MultiPurposeDialog
          open={isOnlyEditedTaskCanBeActiveDialogOpen}
          dialogTitle={
            i18n().ui.only_one_task_active_change_applied_for_edited_task
          }
          handleAccept={this.handleSave}
          handleCloseDialog={onCancel}
        />
      </>
    );
  }

  /**
   * Performs additional checks before saving
   */
  private handleSave = async () => {
    const { updatedTask, initialTask, taskMember } = this.props;
    const {
      isOnlyOneActiveTaskForUserDialogOpen,
      isOnlyEditedTaskCanBeActiveDialogOpen,
      selectedOption,
    } = this.state;

    if (
      updatedTask.status !== initialTask.status &&
      updatedTask.status === TaskStatus.ACTIVE &&
      selectedOption !== RepeatingTaskUpdateOption.updateThis
    ) {
      if (isOnlyEditedTaskCanBeActiveDialogOpen === false) {
        this.setState({ isOnlyEditedTaskCanBeActiveDialogOpen: true });
      } else if (
        taskMember !== undefined &&
        taskMember.activeTask &&
        (taskMember.activeTask.key || taskMember.activeTask.id) !==
          updatedTask.key
      ) {
        if (isOnlyOneActiveTaskForUserDialogOpen === false) {
          this.setState({ isOnlyOneActiveTaskForUserDialogOpen: true });
        } else {
          this.save();
        }
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  };

  /**
   * Checks selected save option and perform save procedure correspondingly
   */
  private save = async () => {
    const { updatedTask, onCancel } = this.props;

    let tasksToUpdate: TaskWithKey[] = this.state.tasksToUpdate;
    const batch = firebaseApp.firestore().batch();

    switch (this.state.selectedOption) {
      case RepeatingTaskUpdateOption.updateThis: {
        const validUpdatedTask = deleteUndefinedValues(updatedTask);
        tasksToUpdate.push(validUpdatedTask);
        break;
      }

      case RepeatingTaskUpdateOption.updateFollowing: {
        await this.updateThisAndFollowingTasksByRepeatingTaskId(batch).then(
          tasks => {
            tasksToUpdate = tasks;
          },
        );

        break;
      }

      case RepeatingTaskUpdateOption.updateAll:
        {
          await this.updateAllTasksByRepeatingTaskId().then(tasks => {
            tasksToUpdate = tasks;
          });
        }
        break;
      default:
        console.error('Invalid selection for repeating task update');
        onCancel();
        return;
    }

    if (tasksToUpdate.length === 0) {
      return;
    }

    this.props.saveRequest && this.props.saveRequest(tasksToUpdate);
    this.props.onSave && this.props.onSave();
    /* TODO: Here saveRequest is taking the task list and do firebase logic for updating task in parent component, 
    but it can be so that parent component has no logic for updating tasks. Good idea to implement saving logic in these component also for such cases.*/
  };

  private updateThisAndFollowingTasksByRepeatingTaskId = async (
    batch: firebase.firestore.WriteBatch,
  ) => {
    let tasksToUpdate: TaskWithKey[] = this.state.tasksToUpdate;
    const { initialTask, updatedTask, companyId } = this.props;

    let repeatingTasks: RepeatingTasks = this.state.repeatingTasks;

    await getRepeatingTasksDocRef(companyId, updatedTask.repeatingTaskId)
      .get()
      .then(doc => {
        repeatingTasks = doc.data() as RepeatingTasks;
      })
      .catch(err => {
        console.error(
          'Error retrieving repeating task array for updating task',
          err,
        );
      });

    tasksToUpdate.length ||
      (await getThisAndFollowingRepeatingTasks(companyId, updatedTask).then(
        docs => {
          tasksToUpdate = collectionToJsonWithIds<TaskWithKey>(docs);
        },
      ));

    if (
      this.isMemberChangedForActiveTask(updatedTask, initialTask, tasksToUpdate)
    ) {
      return [];
    }

    if (tasksToUpdate.length === repeatingTasks.tasks.length) {
      tasksToUpdate = this.applyChangesForRepeatingTasks(
        tasksToUpdate,
        initialTask,
        updatedTask,
      );
    } else {
      const document = getRepeatingTasksDocRef(companyId);

      tasksToUpdate = this.applyChangesForRepeatingTasks(
        tasksToUpdate,
        initialTask,
        updatedTask,
        document.id,
      );

      const newRepeatingTasksIds = tasksToUpdate.map(task => task.id);

      batch.set(document, {
        tasks: newRepeatingTasksIds,
      });

      const filteredRepeatingTasks: RepeatingTasks = {
        tasks: repeatingTasks.tasks.filter(
          id => !newRepeatingTasksIds.includes(id),
        ),
      };

      const previousRepeatingChainId = updatedTask.repeatingTaskId;

      batch.update(
        getRepeatingTasksDocRef(companyId, previousRepeatingChainId),
        filteredRepeatingTasks,
      );

      batch
        .commit()
        .catch(err =>
          console.error(
            'Error commiting batch changes for a repeatingTasks table during editing a recurring task: ',
            err,
          ),
        );
    }

    return tasksToUpdate;
  };

  private updateAllTasksByRepeatingTaskId = async () => {
    let tasksToUpdate: TaskWithKey[] = this.state.tasksToUpdate;
    const { initialTask, updatedTask, companyId } = this.props;

    tasksToUpdate.length ||
      (await getTasksTableRef(companyId)
        .where('repeatingTaskId', '==', updatedTask.repeatingTaskId)
        .get()
        .then(docs => {
          tasksToUpdate = collectionToJsonWithIds<TaskWithKey>(docs);
        })
        .catch(err => {
          console.error(
            'Error retrieving tasks with same repeatingTaskId: ',
            err,
          );
        }));

    if (
      this.isMemberChangedForActiveTask(updatedTask, initialTask, tasksToUpdate)
    ) {
      return [];
    }

    tasksToUpdate = this.applyChangesForRepeatingTasks(
      tasksToUpdate,
      initialTask,
      updatedTask,
    );

    return tasksToUpdate;
  };

  private applyChangesForRepeatingTasks = (
    tasksToUpdate: TaskWithKey[],
    initialTask: TaskWithKey,
    updatedTask: TaskWithKey,
    repeatingTaskid?: string,
  ) => {
    const changes = this.difference(updatedTask, initialTask);

    let addDays = 0;
    let addTime = 0;

    // if start been changed
    if (changes.start) {
      // If date without a time is the same
      if (moment(changes.start).isSame(initialTask.start, 'day')) {
        // Calculte new start time for a day
        addTime = moment(changes.start).diff(
          moment(changes.start).startOf('day'),
        );
      } else {
        // Calculate number of days
        addDays = moment(changes.start)
          .startOf('day')
          .diff(moment(initialTask.start).startOf('day'), 'days');

        // If time also been changed
        if (
          moment(changes.start).format('LT') !==
          moment(initialTask.start).format('LT')
        ) {
          addTime = moment(changes.start).diff(
            moment(changes.start).startOf('day'),
          );
        }
      }
    }

    // Changes status to ACTIVE only for edited task, the rest of tasks remains with their statuses
    if (changes.status !== undefined && changes.status === TaskStatus.ACTIVE) {
      tasksToUpdate.forEach(task => {
        if (task.key === updatedTask.key) {
          task.status = TaskStatus.ACTIVE;
        }
        return task;
      });
      delete changes.status;
    }

    const updatedTasks = tasksToUpdate.map(task => {
      let start = moment(task.start).add(addDays, 'days');
      if (addTime) {
        start = start.startOf('day').add(addTime, 'millisecond');
      }
      const startValueInMilliseconds = start.valueOf();
      const taskId = task.key;
      delete task.key;

      return {
        ...task,
        ...changes,
        id: taskId,
        start: startValueInMilliseconds,
        end: startValueInMilliseconds + (changes.duration || task.duration),
        repeatingTaskId: repeatingTaskid
          ? repeatingTaskid
          : task.repeatingTaskId,
      };
    });

    /**
     * Checks that none of the values in task object is no undefied, if some are indefined, then apply firebase delete method, otherwise firebase will return error when will try to process undefined value
     */
    const validUpdatedTasks = updatedTasks.map(task => {
      return deleteUndefinedValues(task);
    });

    return validUpdatedTasks;
  };

  /**
   * Checks that if member change takes place, then checks that none of the tasks is active.
   * If find active tasks then return "true" and writes saved and filtered data to state.
   *
   * @param {TaskWithKey} updatedTask
   * @param {TaskWithKey} initialTask
   * @param {TaskWithKey[]} tasksToUpdate
   * @param {RepeatingTasks} repeatingTask
   * @returns boolean
   */
  private isMemberChangedForActiveTask = (
    updatedTask: TaskWithKey,
    initialTask: TaskWithKey,
    tasksToUpdate: TaskWithKey[],
  ) => {
    if (updatedTask.userId !== initialTask.userId) {
      const numberOfActiveTasks = tasksToUpdate.filter(
        task => task.status === TaskStatus.ACTIVE,
      ).length;
      if (numberOfActiveTasks) {
        this.setState({
          tasksToUpdate: tasksToUpdate.filter(
            task => task.status !== TaskStatus.ACTIVE,
          ),
          isMemberChangeWarningOpen: true,
          ratioOfActiveTasks: `${numberOfActiveTasks}/${tasksToUpdate.length}`,
        });
        return true;
      }
    }
    return false;
  };

  /**
   * Closes the warning dialog and perform save for selected task
   */
  private hideMemberChangeWarning = () => {
    this.setState({ isMemberChangeWarningOpen: false });
    this.handleSave();
  };

  /**
   * Deep diff between two tasks, using lodash
   * @param  {TaskWithKey} updatedTask Task compared
   * @param  {TaskWithKey} initialTask Task to compare with
   * @return {Partial<TaskWithKey>} Return a new object which represent the diff
   */
  private difference = (
    updatedTask: TaskWithKey,
    initialTask: TaskWithKey,
  ): Partial<TaskWithKey> => {
    function changes(object: {}, base: TaskWithKey) {
      return _.transform(
        object,
        (result: Partial<TaskWithKey>, value: any, key: string) => {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        },
      );
    }
    return changes(updatedTask, initialTask);
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<RepeatingTaskUpdateDialogProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    companyId: state.company.companyId,
  };
};

const styles = (): StyleRules<string> => ({
  formControllLabel: {
    '& span': {
      fontSize: '16px',
    },
  },
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(RepeatingTaskUpdateDialog),
);
