import { Reducer } from 'redux';

import { DEFAULT_COMPANY } from '@shared/defaults';
import { Company, CompanyId } from '@shared/schema';
import { CompanyAction, CompanyActionType } from './companyActions';

export interface CompanyState {
  companyId?: CompanyId;
  activeCompany: Company;
}

export const InitialState: CompanyState = {
  activeCompany: DEFAULT_COMPANY,
};

export const companyReducer: Reducer<CompanyState> = (
  state: CompanyState = InitialState,
  action: CompanyAction,
) => {
  switch (action.type) {
    case CompanyActionType.COMPANY_UPDATE:
      return {
        ...state,
        companyId: action.payload.companyId,
        activeCompany: action.payload.company,
      };

    default:
      return state;
  }
};
