import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import { DeleteForever } from '@material-ui/icons';
import * as firebase from 'firebase';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { i18n } from '@shared/locale';
import {
  ChatIconState,
  CompanyId,
  CustomerWithKey,
  EditorState,
  FileReference,
  GeoLocation,
  Groups,
  JobTypeWithKey,
  MemberWithKey,
  QualificationWithKey,
  Schema,
  Settings,
  Task,
  TaskDialogState,
  TaskStatus,
  TaskWithKey,
  TaskWrite,
  User,
  Vat,
  ViewState,
  WorksiteWithKey,
} from '@shared/schema';
import { resolveTaskTitle } from '@shared/utils';
import ChatStatusIcon from 'components/ChatStatusIcon';
import MultiPurposeDialog from 'components/MultiPurposeDialog';
import firebaseApp from 'firebaseApp';
import { ApplicationState } from 'reducers';
import {
  cancelEdit,
  createResourceEvent,
  paramChange,
  setChatIconState,
  setDialogState,
  updateResourceEvent,
} from 'reducers/events/eventActions';
import { TimelineState } from 'reducers/timeline/timelineReducer';
import { updateRepeatingResourceEvent } from '../../../../../reducers/events/eventActions';
import {
  FileUploadStatus,
  MultiUploadFile,
  MultiUploadFileList,
} from '../MultiUpload';
import RepeatingTaskUpdateDialog from './components/RepeatingTaskUpdateDialog';
import TaskDeleteDialog from './components/TaskDeleteDialog';
import ChatContainer from './containers/ChatContainer/ChatContainer';
import TaskMapContainer from './containers/MapContainer/TaskMapContainer';
import TaskFormContainer from './containers/TaskFormContainer/TaskFormContainer';
import { createRepeatingEvents } from './editorUtils';
import styles from './styles';

export interface EventEditorDialogProps
  extends DispatchProp,
    StyledComponentProps {
  appUser?: User;
  members?: MemberWithKey[];
  jobTypes?: JobTypeWithKey[];
  worksites?: WorksiteWithKey[];
  qualifications?: QualificationWithKey[];
  companyId?: CompanyId;
  userId?: string;
  startDateTime?: moment.Moment;
  editedTaskBeforeEditing?: TaskWithKey;
  editedTask?: TaskWithKey;
  editorState?: EditorState;
  timelineState: TimelineState;
  settings: Settings;
  vats?: Vat[];
  firebaseUser?: firebase.User;
  customers: CustomerWithKey[];
  groups: Groups[];
  newWorksiteId?: string;
  viewState?: ViewState;
  taskDialogState: TaskDialogState;
  taskChatIconState: ChatIconState;
}

interface State {
  editorState?: EditorState;
  suggestions?: string[];
  open: boolean;
  worksiteName: string;
  location?: Location;
  fieldValidity: FieldValidity;
  fileUploads: MultiUploadFileList;
  displayWarning: WarningDialogData;
  isDeleteWarning: boolean;
  repetitionExists: boolean;
  isRepeatingTaskUpdateDialogOpen: boolean;
  repeatingTaskUpdateOption: RepeatingTaskRadioButtonOption;
  isRepeatingTaskUserUpdateWarningOpen: boolean;
  isOnlyOneActiveTaskForUserDialogOpen: boolean;
}

enum RepeatingTaskRadioButtonOption {
  affectThis = 'affectThis',
  affectFollowing = 'affectFollowing',
  affectAll = 'affectAll',
}

interface WarningDialogData {
  display: boolean;
  missingNames: string[];
}

interface FieldValidity {
  date?: boolean;
  time?: boolean;
  duration?: boolean;
}

/**
 * Task editor dialog. (Tasks were earlier named events)
 *
 * TODO Rename to `TaskEditorDialog`
 */
export class EventEditorDialog extends React.Component<
  EventEditorDialogProps,
  State
> {
  constructor(props: EventEditorDialogProps) {
    super(props);

    this.state = {
      editorState: EditorState.CLOSED,
      suggestions: [],
      open: false,
      worksiteName: '',
      location: undefined,
      fieldValidity: {
        date: true,
        time: true,
      },
      fileUploads: {},
      displayWarning: {
        display: false,
        missingNames: [],
      },
      isDeleteWarning: false,
      repetitionExists: false,
      isRepeatingTaskUpdateDialogOpen: false,
      repeatingTaskUpdateOption: RepeatingTaskRadioButtonOption.affectThis,
      isRepeatingTaskUserUpdateWarningOpen: false,
      isOnlyOneActiveTaskForUserDialogOpen: false,
    };
  }

  public getDefaultVat() {
    return this.props.vats
      ? this.props.vats.find(item => item.default === true)
      : undefined;
  }
  public componentDidMount() {
    const { editedTask } = this.props;

    if (editedTask && editedTask.repetition) {
      this.setState({
        repetitionExists: true,
      });
    }
  }

  public shouldComponentUpdate(nextProps: EventEditorDialogProps) {
    if (
      nextProps.editorState === 'closed' &&
      this.props.editorState === 'closed'
    ) {
      return false;
    }
    return true;
  }

  public render() {
    const {
      classes = {},
      editorState,
      editedTask,
      editedTaskBeforeEditing,
      taskDialogState,
      taskChatIconState,
      companyId,
    } = this.props;
    const {
      displayWarning,
      isDeleteWarning,
      isOnlyOneActiveTaskForUserDialogOpen,
    } = this.state;

    if (editorState === EditorState.CLOSED) {
      return null;
    }

    const defaultVat = this.getDefaultVat();

    if (editorState === EditorState.NEW && editedTask) {
      editedTask.vat = defaultVat;
    }

    if (editorState === EditorState.EDIT && editedTask && !editedTask.vat) {
      editedTask.vat = undefined;
    }
    const hasTimer =
      editedTask && (editedTask.timerLog || editedTask.timerTotal);

    const deleteText = hasTimer
      ? i18n().ui.task_has_hours_confirm
      : i18n().ui.delete_dialog_confirm;
    // Adding space after each comma
    const missingNames = displayWarning.missingNames
      .toString()
      .replace(/,/g, ', ');

    const warningText =
      displayWarning.missingNames.length === 1
        ? `${i18n().ui.member_missing_qualification} ${missingNames}. ${
            i18n().ui.qualification_required
          }`
        : `${i18n().ui.multiple_qualifications_missing} ${missingNames}. ${
            i18n().ui.multiple_qualifications_required
          }`;

    return (
      <>
        <Dialog
          open={true}
          className={classes.root}
          classes={{ paper: classes.paper }}
          maxWidth="lg"
        >
          <Tabs
            className={classes.tabs}
            value={taskDialogState}
            onChange={this.handleTabChange}
            variant="standard"
          >
            <Tab
              value={TaskDialogState.TASK}
              className={classes.tab}
              label={
                editorState === EditorState.EDIT
                  ? i18n().ui.modify_task
                  : i18n().ui.new_task
              }
              classes={{ root: classes.tabRoot }}
            />
            <Tab
              value={TaskDialogState.CHAT}
              disabled={editorState !== EditorState.EDIT}
              className={classes.tab}
              label={
                <>
                  {i18n().ui.chat + ' '}
                  <ChatStatusIcon
                    state={taskChatIconState}
                    className={classes.chatIcon}
                    viewBox="0 0 24 24"
                  />
                </>
              }
              classes={{
                root: classes.tabRoot,
                wrapper: classes.iconAligment,
              }}
            />
            <Tab
              value={TaskDialogState.MAP}
              disabled={!this.getGeoLocation()}
              className={classes.tab}
              label={i18n().ui.map}
              classes={{ root: classes.tabRoot }}
            />
          </Tabs>

          <DialogContent
            className={
              taskDialogState !== TaskDialogState.MAP
                ? classes.dialogContent
                : classes.mapContent
            }
          >
            {this.renderTabContent()}
          </DialogContent>

          <DialogActions className={classes.dialogFooter}>
            {/*  Show edit button while editing existing task which is NOT ACTIVE */}
            {taskDialogState === TaskDialogState.TASK &&
              editorState === EditorState.EDIT &&
              editedTask &&
              editedTask.status !== TaskStatus.ACTIVE && (
                <Button
                  onClick={this.handleDelete}
                  color="default"
                  className={classes.deleteButton}
                >
                  <DeleteForever />
                  {i18n().ui.delete_task}
                </Button>
              )}

            {/* Buttons Save and Delete */}
            <Button onClick={this.handleRequestClose} color="default">
              {i18n().ui.cancel}
            </Button>
            <Button
              data-test="saveButton"
              disabled={!this.isFormValid()}
              onClick={this.handleSave}
              color="secondary"
              className={classes.saveButton}
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>
        {editedTask && editedTask.key && (
          <TaskDeleteDialog
            isOpen={displayWarning.display && isDeleteWarning}
            onClose={this.handleWarningClose}
            task={editedTask}
            onSendRequest={this.handleRequestClose}
          />
        )}

        {this.state.isRepeatingTaskUpdateDialogOpen && (
          <RepeatingTaskUpdateDialog
            initialTask={editedTaskBeforeEditing}
            updatedTask={editedTask}
            taskMember={this.getMemberForEditedTask()}
            onCancel={() => {
              this.setState({ isRepeatingTaskUpdateDialogOpen: false });
            }}
            saveRequest={(tasks: TaskWithKey[]) =>
              this.props.dispatch(
                updateRepeatingResourceEvent(companyId, tasks),
              )
            }
          />
        )}
        <Dialog
          open={displayWarning.display && !isDeleteWarning}
          onClose={this.handleWarningClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n().ui.caution}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" color="primary">
              {isDeleteWarning ? deleteText : warningText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleWarningClose}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleWarningConfirm}
              color={isDeleteWarning ? 'primary' : 'secondary'}
              autoFocus
            >
              {isDeleteWarning ? i18n().ui.delete : i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>
        <MultiPurposeDialog
          open={isOnlyOneActiveTaskForUserDialogOpen}
          dialogTitle={i18n().ui.another_task_active}
          dialogContent={i18n().ui.start_another_task_question}
          handleAccept={this.handleOnlyOneActiveTaskForUserDialogAccept}
          handleCloseDialog={this.handleOnlyOneActiveTaskForUserDialogCancel}
        />
      </>
    );
  }

  /**
   * Function will close Dialog and cancels all edit what have been done
   */
  private handleRequestClose = () => {
    const { appUser, editedTask } = this.props;
    const { fileUploads } = this.state;

    this.props.dispatch(cancelEdit());

    // Delete already uploaded files, so we dont keep them for nothing
    if (appUser && appUser.home && editedTask && editedTask.id) {
      for (const upload of Object.values(fileUploads)) {
        // Make sure task didnt already have this file on opening the dialog
        if (!this.userHasThisFile(upload.key)) {
          const storageRef = firebaseApp.storage().ref();
          this.deleteFile(upload, appUser.home, editedTask.id, storageRef);
        }
      }
    }
  };

  /**
   * Opens confirmation popup
   */
  private handleDelete = () => {
    this.setState({
      displayWarning: {
        display: true,
        missingNames: [],
      },
      isDeleteWarning: true,
    });
  };

  /**
   * Handle tab changes
   */
  private handleTabChange = (
    event: React.ChangeEvent,
    newState: TaskDialogState,
  ) => {
    this.props.dispatch(setDialogState(newState));
  };

  /**
   * This function should return correct component according to state Tab value.
   */
  private renderTabContent = () => {
    const {
      companyId,
      editedTask,
      firebaseUser,
      members,
      taskDialogState,
      userId = '',
    } = this.props;

    switch (taskDialogState) {
      case TaskDialogState.TASK:
        return (
          <TaskFormContainer
            timelineState={this.props.timelineState}
            jobTypes={this.props.jobTypes}
            worksites={this.props.worksites}
            vats={this.props.vats}
            customers={this.props.customers}
            members={members}
            settings={this.props.settings}
            editorState={this.props.editorState}
            companyId={companyId}
            userId={userId}
            startDate={this.props.startDateTime}
            groups={this.props.groups}
            qualifications={this.props.qualifications}
            newWorksiteId={this.props.newWorksiteId}
            viewState={this.props.viewState}
          />
        );

      case TaskDialogState.CHAT:
        const appMember =
          members && firebaseUser
            ? members.find(member => member.key === firebaseUser.uid)
            : undefined;

        if (companyId && editedTask && appMember) {
          return (
            <ChatContainer
              companyId={companyId}
              task={editedTask}
              member={appMember}
              onReadChat={this.handleReadChat(appMember)}
              onSentMessage={this.handleSentMessage(appMember)}
            />
          );
        }

      case TaskDialogState.MAP:
        return <TaskMapContainer worksiteGeoLocation={this.getGeoLocation()} />;

      default:
        return null;
    }
  };

  /**
   * Returns a function for member which updates redux store
   * after ChatContainer reports message ID of last read message.
   *
   * Note that this does not update `hasReadChat.timestamp` since that
   * is used to mark the last send message time, not read.
   *
   * @returns Handler function
   */
  private handleReadChat = (member: MemberWithKey) => (
    lastMessageId: string,
  ) => {
    const { dispatch, editedTask } = this.props;

    const taskUpdate: Partial<Task> = {
      hasReadChat: {
        ...editedTask!.hasReadChat,
        [member.key!]: lastMessageId,
      },
    };

    dispatch(paramChange(taskUpdate));
    dispatch(setChatIconState(ChatIconState.READ));
  };

  /**
   * Returns a function for member which updates redux store
   * after CharContainer reports message ID of newly added message by member.
   *
   * @returns Handler function
   */
  private handleSentMessage = (member: MemberWithKey) => (
    messageId: string,
    timestamp: number,
  ) => {
    const { dispatch, editedTask } = this.props;

    const taskUpdate: Partial<Task> = {
      hasReadChat: {
        ...editedTask!.hasReadChat,
        [member.key!]: messageId,
        timestamp: (timestamp as unknown) as string, // TODO better typing in schema (maybe impossible though)
      },
    };

    dispatch(paramChange(taskUpdate));
    dispatch(setChatIconState(ChatIconState.READ));
  };

  /**
   * Function deletes file from storage according parameters if there is
   * error when deleting it will shown as console.error().
   * @param {MultiUploadFile} file
   * @param {string} companyName
   * @param {string} taskId
   * @param {firebase.storage.Reference} storageRef
   *
   */
  private deleteFile = async (
    file: MultiUploadFile,
    companyName: string,
    taskId: string,
    storageRef: firebase.storage.Reference,
  ) => {
    await storageRef
      .child(
        `${Schema.COMPANIES}/${companyName}/${Schema.TASKS}/${taskId}/${file.fileName}`,
      )
      .delete()
      .catch(error => {
        if (error.code === 'storage/object-not-found') {
          console.error('Object not found in storage ', error);
        }
      });
  };

  /**
   * If editedResource in props has this file return true
   * @param key key of the file that is searched
   */
  private userHasThisFile = (key: string) => {
    const { editedTask: editedResource } = this.props;
    if (editedResource && editedResource.files) {
      for (const file of editedResource.files) {
        if (file.key === key) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Function saves whole task and calls another save() function
   */
  private handleSave = () => {
    // Check if selected member has the qualifications for jobtype selected
    /* const { members, jobTypes, editedTask } = this.props;

    if (members && jobTypes) {
      const jobTypeId = editedTask!.jobtypeId;
      let selectedMember: MemberWithKey | undefined;

      const userId = editedTask!.userId;
      for (const key in members) {
        if (members[key].key && members[key].key === userId) {
          selectedMember = members[key];
        }
      }
      let selectedJobtype: JobTypeWithKey | undefined;
      for (const key in jobTypes) {
        if (jobTypes[key].key && jobTypes[key].key === jobTypeId) {
          selectedJobtype = jobTypes[key];
        }
      } */
    const { editedTask, editorState, members } = this.props;

    if (
      editedTask &&
      editedTask.repeatingTaskId &&
      editorState &&
      editorState === EditorState.EDIT
    ) {
      this.setState({ isRepeatingTaskUpdateDialogOpen: true });
    } else if (
      editedTask &&
      editedTask.status === TaskStatus.ACTIVE &&
      members !== undefined
    ) {
      const taskMember = members.find(
        member => member.key === editedTask.userId,
      );

      if (
        taskMember !== undefined &&
        taskMember.activeTask &&
        (taskMember.activeTask.key || taskMember.activeTask.id) !==
          editedTask.key
      ) {
        this.setState({ isOnlyOneActiveTaskForUserDialogOpen: true });
      } else {
        this.save();
      }
    } else {
      this.save();
    }

    /*
      if (
        selectedMember &&
        selectedJobtype &&
        selectedJobtype.requiredQualifications
      ) {
        const reqQualifs = selectedJobtype.requiredQualifications;
        let hasAllRequired = true;
        const missingNames: string[] = [];
        for (const reqQualifKey in reqQualifs) {
          if (
            !selectedMember.qualifications ||
            !selectedMember.qualifications[reqQualifKey]
          ) {
            hasAllRequired = false;
            const qualifName = this.getQualificationNameByKey(reqQualifKey);
            missingNames.push(qualifName ? qualifName : 'undefined');
          }
        }
         if (hasAllRequired) {
          this.save();
        } else {
          // Pop dialog warning user about selected memeber not having all requirements
          this.setState({
            displayWarning: {
              display: true,
              missingNames,
            },
            isDeleteWarning: false,
          });
        } 
      } else {
        this.save();
      }
    } else {
      this.save();
    }*/
  };

  /**
   * Async funtion what will dispatch task to redux
   */
  private save = async () => {
    const { fileUploads, repetitionExists } = this.state;
    const {
      firebaseUser,
      companyId,
      members,
      editedTask,
      editorState,
      viewState,
      settings,
      dispatch,
    } = this.props;

    if (editedTask) {
      const source: TaskWithKey = editedTask;

      // If taskBank task has no user and is moved to active, it will be assigned to current admin
      if (
        viewState === ViewState.TASKBANK &&
        firebaseUser &&
        source &&
        source.userId === '' &&
        source.status === TaskStatus.ACTIVE
      ) {
        // Getting real userName for user instead of firebaseUser.name
        if (members) {
          const memberInfo = members.find(
            element => element.key === firebaseUser.uid,
          );

          if (memberInfo) {
            const user = memberInfo;
            source.userId = firebaseUser.uid;
            source.userName = user.name;
          }
        }
      }

      const { start, end, duration } = source;
      const {
        enableWorksite,
        enableWorksiteNick,
        enableJobType,
      } = settings.general;

      const taskUpdate: TaskWrite = {
        ...source,
        title: resolveTaskTitle(
          source,
          enableWorksite,
          enableWorksiteNick,
          enableJobType,
        ),
        archived: false,
        // Making sure data will be 0 instead of NaN if empty
        start: start || 0,
        duration: duration || 0,
        end: end || 0,
        created: source.created || Date.now(),
        desc: source.desc || '',
      };
      /* TODO Unit tests for activating activeTimer */
      if (source.activeTimer) {
        taskUpdate.activeTimer = source.activeTimer;
      }
      /* TODO Unit tests for closing activeTimer and then creating timerLog for the task */
      if (source.timerLog) {
        taskUpdate.timerLog = source.timerLog;
      }

      // Handle worksite adding to the object
      if (this.props.editorState === EditorState.EDIT) {
        // If editing send delete flag to firestore if needed
        taskUpdate.worksite = source.worksite
          ? { ...source.worksite }
          : firebase.firestore.FieldValue.delete();

        taskUpdate.location = source.worksite
          ? source.location
          : firebase.firestore.FieldValue.delete();

        if (
          source.vat &&
          source.vat.name !== '' &&
          source.vat.name !== i18n().ui.select_vat
        ) {
          taskUpdate.vat = source.vat;
        } else if (
          source.vat &&
          (source.vat.name === '' || source.vat.name === i18n().ui.select_vat)
        ) {
          taskUpdate.vat = firebase.firestore.FieldValue.delete() as any;
        } else {
          delete taskUpdate.vat;
        }
        taskUpdate.alarm = source.alarm ? source.alarm : { minutes: -1 };

        if (taskUpdate.status === TaskStatus.ACTIVE) {
          taskUpdate.updatedByAdmin = true;
        }
      } else {
        // On new, only add it if it is there
        if (source.worksite) {
          taskUpdate.worksite = { ...source.worksite };
          taskUpdate.location = source.location;
        }
        if (
          source.vat &&
          source.vat.name !== '' &&
          source.vat.name !== i18n().ui.select_vat
        ) {
          taskUpdate.vat = source.vat;
        } else {
          delete taskUpdate.vat;
        }
      }

      if (source.alarm) {
        taskUpdate.alarm = source.alarm;
      }

      if (source.desc) {
        taskUpdate.desc = source.desc;
      }

      if (source.shiftTemplateTaskId) {
        taskUpdate.shiftTaskModified = true;
      }

      // Add needed file info to task
      const files: FileReference[] = [];
      for (const upload of Object.values(fileUploads)) {
        if (upload.url) {
          const file: FileReference = {
            key: upload.fileName,
            fileName: upload.fileName,
            mimeType: upload.mimeType,
            url: upload.url,
          };
          files.push(file);
        }
      }
      taskUpdate.files = files;

      if (editorState === 'edit') {
        taskUpdate.id = source.id;
        dispatch(updateResourceEvent(companyId, taskUpdate));
      }
      if (
        source.repetition &&
        source.repetition !== null &&
        !repetitionExists
      ) {
        // Creates new doc ID for repeatingTasks table
        let docId;
        try {
          const document = firebase
            .firestore()
            .collection(Schema.COMPANIES)
            .doc(this.props.companyId)
            .collection(Schema.REPEATINGTASKS)
            .doc();

          docId = document.id;
        } catch (error) {
          console.error('Failed to create repeating tasks document id.', error);
        } finally {
          if (docId !== undefined) {
            createRepeatingEvents(
              this.props.dispatch,
              this.props.companyId,
              duration,
              source.repetition,
              taskUpdate,
              editorState,
              docId,
            );
          } else {
            console.error('Failed to create repeating tasks.');
          }
        }
      } else if (editorState === 'new' && !repetitionExists) {
        this.props.dispatch(
          createResourceEvent(this.props.companyId, taskUpdate),
        );
      }
    }
  };

  /**
   * Gets current worksite's geoLocation if possible
   * @returns geoLocation or undefined
   */
  private getGeoLocation = (): GeoLocation | undefined => {
    const { editedTask, worksites } = this.props;

    if (worksites && editedTask) {
      const selectedWorksite = worksites.find(worksite =>
        editedTask.worksite ? worksite.key === editedTask.worksite.id : false,
      );

      if (selectedWorksite && selectedWorksite.geoLocation) {
        return selectedWorksite.geoLocation;
      }
    }
    return undefined;
  };

  /**
   * Checks if form is valid
   * @returns boolean value
   */
  private isFormValid = () => {
    const { fileUploads } = this.state;
    const { editedTask } = this.props;

    // Check if any upload is in progress
    for (const upload of Object.values(fileUploads)) {
      if (upload.status !== FileUploadStatus.DONE) {
        return false;
      }
    }

    // Check if repetition editor count is within limits
    let isRepetitionCountValid = true;
    if (editedTask && editedTask.repetition) {
      isRepetitionCountValid = editedTask
        ? editedTask.repetition.count < 365 // max number of tasks that can be created
        : false;
    }

    // Check if end date is later than start date
    let isRepetitionDateValid = true;
    if (editedTask && editedTask.repetition) {
      isRepetitionDateValid = editedTask
        ? editedTask.repetition.count > 0
        : false;
    }

    return editedTask
      ? editedTask.jobtypeId !== '' &&
          isRepetitionDateValid &&
          isRepetitionCountValid
      : false;
  };

  /* DONT DELETE THESE ONES, WILL BE NEEDED AFTER FILES ARE ADDED TO TASK */
  /* private onUploadChange = () => (event: any) => {
    const fileToUpload = event.target.files[0];
    const originalName = fileToUpload.name;
    const mimeType = fileToUpload.type;

    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)], {
        type: mimeType,
      });
      const fileUploads = {
        ...this.state.fileUploads,
      };

      fileUploads[originalName] = {
        key: originalName,
        fileName: originalName,
        mimeType,
        file: blob,
        status: FileUploadStatus.WAITING,
      } as MultiUploadFile;

      this.setState({
        fileUploads,
      });
    };
    reader.readAsArrayBuffer(fileToUpload);
  };

  private onMultiUploadChange = (fileUploads: MultiUploadFileList) => {
    this.setState({
      fileUploads,
    });
  }; */

  /**
   * Closes warning message
   */
  private handleWarningClose = () => {
    this.setState({
      displayWarning: {
        display: false,
        missingNames: [],
      },
    });
  };

  /**
   * Fires delete/save according to isDeleteWarning boolean
   */
  private handleWarningConfirm = () => {
    this.setState({
      displayWarning: {
        display: false,
        missingNames: [],
      },
    });
  };

  /**
   * Closes the dialog that only one task can be active for a user, then saves chnages made in editor to db
   */
  private handleOnlyOneActiveTaskForUserDialogAccept = () => {
    this.setState({ isOnlyOneActiveTaskForUserDialogOpen: false });
    this.save();
  };

  /**
   * Closes the dialog that only one task can be active for a user.
   */
  private handleOnlyOneActiveTaskForUserDialogCancel = () => {
    this.setState({
      isOnlyOneActiveTaskForUserDialogOpen: false,
    });
  };

  /**
   * Tries to find a member for edited task
   *
   * @returns {MemberWithKey | undefined}
   */
  private getMemberForEditedTask = (): MemberWithKey | undefined => {
    const { editedTask, members } = this.props;
    let taskMember: MemberWithKey | undefined;

    if (members !== undefined && editedTask !== undefined) {
      taskMember = members.find(member => member.key === editedTask.userId);
    }

    return taskMember;
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<EventEditorDialogProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    firebaseUser: state.auth.firebaseUser,
    appUser: state.auth.appUser,
    editorState: state.eventEditor.editorState,
    userId: state.eventEditor.userId,
    editedTask: state.eventEditor.editedResource,
    editedTaskBeforeEditing: state.eventEditor.editedTaskBeforeEditing,
    startDateTime: state.eventEditor.startDateTime,
    timelineState: state.timeline,
    taskDialogState: state.eventEditor.taskDialogState,
    taskChatIconState: state.eventEditor.taskChatIconState,
    settings: state.company.activeCompany.settings,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(EventEditorDialog),
);
