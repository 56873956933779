import {
  FormControl,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { ImportExport, Maximize } from '@material-ui/icons';
import { i18n } from '@shared/locale';
import {
  HourTypeWithKey,
  MemberWithKey,
  WorkingHoursByHourTypeForMember,
} from '@shared/schema';
import { Schema, WorkingHoursWithKey } from '@shared/schema/index';
import { WorkTimeReports } from '@shared/schema/reports';
import { ReportType } from '@shared/schema/reports/index';
import classNames from 'classnames';
import ACSelect, { ACSelectValue } from 'components/ACSelect';
import firebaseApp from 'firebaseApp';
import moment from 'moment';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers/index';
import {
  deleteUserFromReportData,
  updateSelectedMembers,
  updateTimeRangeFilters,
  updateUserReportData,
} from 'reducers/reports/reportsActions';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import CsvExportDialog from '../CsvExportDialog/CsvExportDialog';
import MemberList from '../MemberList/MemberList';
import WorkTimeReportDatePicker from '../WorkReportDatePicker/WorkTimeReportDatePicker';
import styles from './styles';

export interface FilterSettingsBarProps
  extends DispatchProp,
    StyledComponentProps {
  members: MemberWithKey[];
  selectedMembers?: MemberWithKey[];
  startDate?: Date;
  endDate?: Date;
  selectedMember?: MemberWithKey;
  dataToExport?: WorkingHoursByHourTypeForMember[];
  companyId: string;
  hourTypes: HourTypeWithKey[];
  reports?: WorkTimeReports;
}

interface FilterSettingsBarState {
  selectableMembers: MemberWithKey[];
  dateStart: Date;
  dateEnd: Date;
  selectedTaskStatus: boolean | undefined;
  showRadioGroup: boolean;
  data: any;
  generateCsvDialog: boolean;
}

/**
 * Is used with radio buttons
 * IN TODO later on
 */
/* enum RadioButtonFiltering {
  HALF_YEAR = 'half_year',
  YEAR = 'year',
} */

interface MemberWorkingHoursDataBaseListener {
  key: string;
  unsubscribe: () => void;
}

export class FilterSettingsBar extends React.Component<
  FilterSettingsBarProps,
  FilterSettingsBarState
> {
  private snapshotListenerList: MemberWorkingHoursDataBaseListener[] = [];

  constructor(props: FilterSettingsBarProps) {
    super(props);

    this.state = {
      selectableMembers: props.members,
      selectedTaskStatus: undefined,
      showRadioGroup: false,
      dateStart: props.startDate
        ? props.startDate
        : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      dateEnd: props.endDate
        ? props.endDate
        : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      data: [],
      generateCsvDialog: false,
    };
  }

  /**
   * Everytime when choosing new member from list,
   * updates list by removing already selected member from list
   * and updates new list to state
   * @param newProps
   */
  public componentDidUpdate(prevProps: FilterSettingsBarProps) {
    if (prevProps === this.props) {
      return;
    }

    const { selectedMembers, members } = this.props;

    let newSelectableMembers: MemberWithKey[] = [];

    newSelectableMembers = members.filter(
      m => selectedMembers && !selectedMembers.includes(m),
    );

    this.setState({
      selectableMembers: newSelectableMembers,
    });
  }

  public componentWillUnmount() {
    this.snapshotListenerList.forEach(listener => listener.unsubscribe());
  }

  public render() {
    const {
      classes = {},
      selectedMember,
      selectedMembers,
      reports,
      hourTypes,
    } = this.props;
    const { selectableMembers, generateCsvDialog } = this.state;

    return (
      <>
        {generateCsvDialog &&
          reports &&
          (selectedMembers || selectedMember) && (
            <CsvExportDialog
              reports={reports}
              selectedMembers={selectedMembers}
              selectedMember={selectedMember}
              hourTypes={hourTypes}
              closeGenerateCsv={this.closeGenerateCsv}
            />
          )}

        <div className={classes.bar}>
          <FormControl className={classes.formControl}>
            <ACSelect
              isDisabled={selectedMember !== undefined}
              options={selectableMembers.map(member => ({
                value: member.key,
                label: member.name,
              }))}
              onChange={this.handleMemberChange}
              placeholder={i18n().ui.select_member}
              isClearable={false}
              data-test="acSelector"
            />
          </FormControl>
          <div className={classes.timeSelectionContainer}>
            <div className={classNames(classes.dateText)}>
              {i18n().ui.started}
            </div>
            <WorkTimeReportDatePicker
              data-test="startedDateStartPicker"
              onChange={this.handleStartDateChange}
              dateValue={this.state.dateStart}
            />
            <div className={classNames(classes.dateLine)}>
              <Maximize />
            </div>
            <WorkTimeReportDatePicker
              data-test="endDateStartPicker"
              onChange={this.handleEndDateChange}
              dateValue={this.state.dateEnd}
            />
          </div>

          <div className={classes.downloadLink}>
            <Button
              disabled={
                (!selectedMembers || selectedMembers.length <= 0) &&
                !selectedMember
              }
              onClick={this.openGenerateCsv}
              className={classNames(classes.button)}
              color="primary"
            >
              <ImportExport />
              {i18n().ui.export_csv}
            </Button>
          </div>

          {/* <div className={classes.timePeriodSelection}>
            {this.state.showRadioGroup && (
              // Must implemented later when its know how radio buttons
              // should work
              // Currently it is hidden from user
              <RadioGroup
                onChange={this.handleFilteringRadioButtonChange}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value={RadioButtonFiltering.HALF_YEAR}
                  control={<Radio color="primary" />}
                  label="6kk"
                  labelPlacement="end"
                  classes={{
                    root: classes.RadioButtonRoot,
                  }}
                />

                <FormControlLabel
                  value={RadioButtonFiltering.YEAR}
                  control={<Radio color="primary" />}
                  label={i18n().ui.year}
                  labelPlacement="end"
                  classes={{
                    root: classes.RadioButtonRoot,
                  }}
                />
              </RadioGroup>
            )}
          </div> */}
        </div>
        {!selectedMember && selectedMembers && (
          <MemberList
            selectedMembers={selectedMembers}
            onClickRemoveMember={this.onClickRemoveMember}
            data-test="memberList"
          />
        )}
      </>
    );
  }

  /**
   * Open csv generate dialog
   */
  private openGenerateCsv = () => {
    this.setState({
      generateCsvDialog: true,
    });
  };

  /**
   * Closes csv generate dialog
   */
  private closeGenerateCsv = () => {
    this.setState({
      generateCsvDialog: false,
    });
  };

  /**
   * Handles members changes in ACSelector and gets chosen member
   * working days from database and updates that data to redux state
   * @param {ACSelectValue} selected
   */
  private handleMemberChange = async (selected: ACSelectValue) => {
    const { value } = selected;
    const { selectedMembers, members } = this.props;
    let newSelectedMembers: MemberWithKey[];

    if (selectedMembers) {
      newSelectedMembers = [...selectedMembers];
      const member = members.find(m => m.key === value);

      if (member && member.key) {
        newSelectedMembers.push(member);

        this.getUserWorkingDays(member.key);

        this.props.dispatch(updateSelectedMembers(newSelectedMembers));
      }
    }
  };

  /**
   * Gets all workingdays from database for one user and sets those to state and redux
   */
  private getUserWorkingDays = async (memberId: string): Promise<any> => {
    const { companyId, startDate, endDate } = this.props;

    try {
      /**
       * Creates snapshot listener to workinghours collection for selected member
       */
      const unsubscribe = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(companyId)
        .collection(Schema.MEMBERS)
        .doc(memberId)
        .collection(Schema.WORKINGHOURS)
        .onSnapshot((onSnapshot: firebase.firestore.QuerySnapshot) => {
          /**
           * Gets data
           */
          let usersWorkingDays: WorkingHoursWithKey[] = onSnapshot.empty
            ? []
            : collectionToJsonWithIds<WorkingHoursWithKey>(onSnapshot);

          /**
           * Sets data to state
           */
          this.setState({
            data: { ...this.state.data, [memberId]: usersWorkingDays },
          });

          /**
           * Filters data if necessary
           */
          if (usersWorkingDays) {
            usersWorkingDays = this.filterByDateRange(
              usersWorkingDays,
              this.state.dateStart ? this.state.dateStart : startDate,
              this.state.dateEnd ? this.state.dateEnd : endDate,
            );
          }

          /**
           * Dispatch data to redux
           */
          this.props.dispatch(
            updateUserReportData(memberId, ReportType.ALL, usersWorkingDays),
          );
        });

      /**
       * Makes one listener object
       */
      const MemberWorkingDayListener: MemberWorkingHoursDataBaseListener = {
        key: memberId,
        unsubscribe,
      };

      /**
       * Pushes listener object to snapshot list
       */
      this.snapshotListenerList.push(MemberWorkingDayListener);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Filters workingdays by what range is choosed from
   * datepickers and returns it
   * @param workDatesToFilter
   * @param startDate
   * @param endDate
   */
  private filterByDateRange = (
    workDatesToFilter: WorkingHoursWithKey[],
    startDate: Date | undefined,
    endDate: Date | undefined,
  ): WorkingHoursWithKey[] => {
    const workDatesToReturn: WorkingHoursWithKey[] = [];

    const expectedStartDate = moment.utc(startDate).add('hours', 3).valueOf();

    const expectedEndDate = moment.utc(endDate).add('hours', 3).valueOf();

    for (const workDate of workDatesToFilter) {
      const workDateTimeStamp = moment.utc(workDate.key).valueOf();
      if (
        (startDate && expectedStartDate > workDateTimeStamp) ||
        (endDate && expectedEndDate < workDateTimeStamp)
      ) {
        continue;
      }
      workDatesToReturn.push(workDate);
    }

    return workDatesToReturn;
  };

  /**
   * Dispatch dateStart filtering option to redux state
   * @param {Date} date
   */
  private handleStartDateChange = (date: Date) => {
    const { selectedMembers, endDate } = this.props;
    const { data } = this.state;

    let newStartDate: Date = date;

    /**
     * When selected date is empty, the new Date object must be
     * build from object that handle change will return
     */
    if (this.props.startDate === null) {
      /**
       * Also for some reason picker will always return moment object
       * even tho its is typed to be Date. Because this, date param needs to
       * be converted to moment and back to date to use all date functions build
       * new actual right date
       */
      newStartDate = new Date(
        moment(date).toDate().getFullYear(),
        moment(date).toDate().getMonth(),
        moment(date).toDate().getDate(),
      );
    }

    this.props.dispatch(
      updateTimeRangeFilters(newStartDate, this.state.dateEnd),
    );

    if (selectedMembers) {
      selectedMembers.forEach(item => {
        if (data && item.key && data[item.key]) {
          this.props.dispatch(
            updateUserReportData(
              item.key,
              ReportType.ALL,
              this.filterByDateRange(data[item.key], newStartDate, endDate),
            ),
          );
        }
      });
    }

    this.setState({
      dateStart: newStartDate,
    });
  };

  /**
   * Dispatch dateEnd filtering option to redux state
   * @param {Date} date
   */
  private handleEndDateChange = (date: Date) => {
    const { selectedMembers, startDate } = this.props;
    const { data } = this.state;

    let newEndDate: Date = date;

    /**
     * When selected date is empty, the new Date object must be
     * build from object that handle change will return
     */
    if (this.props.startDate === null) {
      /**
       * Also for some reason picker will always return moment object
       * even tho its is typed to be Date. Because this, date param needs to
       * be converted to moment and back to date to use all date functions build
       * new actual right date
       */
      newEndDate = new Date(
        moment(date).toDate().getFullYear(),
        moment(date).toDate().getMonth(),
        moment(date).toDate().getDate(),
      );
    }

    this.props.dispatch(
      updateTimeRangeFilters(this.state.dateStart, newEndDate),
    );

    if (selectedMembers) {
      selectedMembers.forEach(item => {
        if (data && item.key && data[item.key]) {
          this.props.dispatch(
            updateUserReportData(
              item.key,
              ReportType.ALL,
              this.filterByDateRange(data[item.key], startDate, date),
            ),
          );
        }
      });
    }

    this.setState({
      dateEnd: date,
    });
  };

  /**
   * TODO
   * For possible radio button option to select time range from current date
   * to six or 12 months back
   */
  /* private handleFilteringRadioButtonChange = (event: any) => {
    this.setState({
      selectedTaskStatus: event.target.value,
    });
  }; */

  /**
   * Updates member list in redux state when
   * removing member from selected memberlist
   */
  private onClickRemoveMember = (memberToRemoveKey: string) => {
    const { selectedMembers } = this.props;
    let newSelectedMembers: MemberWithKey[];

    if (selectedMembers != null) {
      newSelectedMembers = selectedMembers.filter(
        sMember => sMember.key !== memberToRemoveKey,
      );

      for (let i = 0; i < this.snapshotListenerList.length; i++) {
        if (this.snapshotListenerList[i].key === memberToRemoveKey) {
          this.snapshotListenerList[i].unsubscribe();

          this.snapshotListenerList.splice(i, 1);
        }
      }

      this.props.dispatch(updateSelectedMembers(newSelectedMembers));

      this.props.dispatch(deleteUserFromReportData(memberToRemoveKey));
    }
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<FilterSettingsBarProps>,
) => {
  return {
    ...ownProps,
    selectedMembers: state.reports.selectedMembers,
    selectedMember: state.reports.selectedMember,
  } as FilterSettingsBarProps;
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(FilterSettingsBar),
);
