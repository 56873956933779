import * as React from 'react';
import QualificationsSettings from '../components/QualificationsSettings';

export interface QualificationsContainerProps {
  companyId: string;
  classes?: any;
}

const QualificationsContainer = (props: QualificationsContainerProps) => (
  <QualificationsSettings companyId={props.companyId} />
);

export default QualificationsContainer;
