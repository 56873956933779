import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  StyledComponentProps,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ViewWeek } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import { i18n } from '@shared/locale';
import {
  CustomerWithKey,
  JobTypeWithKey,
  Location,
  MemberWithKey,
  Repetition,
  RepetitionType,
  Schema,
  Shift,
  ShiftTemplateWithKey,
  TaskStatus,
  TaskWithKey,
  TaskWorksite,
  VatWithKey,
  WorksiteWithKey,
} from '@shared/schema';
import ACSelect from 'components/ACSelect';
import firebase from 'firebase';
import firebaseApp from 'firebaseApp';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { extractName, NAVIGATION_PATH } from 'paths';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApplicationState } from 'reducers';
import {
  cancelShift,
  incrementProgress,
  setCurrentProgress,
  setProgressMessage,
  setTotalProgress,
} from 'reducers/shifts/shiftAction';
import store from 'store';
import { collectionToJsonWithIds } from 'utils/firestoreUtils';
import { getRepeatCount } from 'utils/repeatingTasksUtils';
import { getShiftDocRef, getShiftTemplatesTableRef } from 'utils/shiftsUtils';
import { getTaskDocRef } from 'utils/tasksUtil';
import { ACSelectValue } from '../../../../../components/ACSelect/index';
import { nextWorkDay } from '../EventEditorDialog/editorUtils';
import RepetitionEditor from '../RepetitionEditor';
import styles from './styles';

interface ShiftEditorDialogProps extends StyledComponentProps, DispatchProp {
  companyId?: string;
  members: MemberWithKey[];
  worksites: WorksiteWithKey[];
  jobtypes: JobTypeWithKey[];
  customers: CustomerWithKey[];
  vats: VatWithKey[];
}

interface State {
  selectedMember?: MemberWithKey;
  selectedDate: Date;
  repetition?: Repetition;
  shiftTemplates: ShiftTemplateWithKey[];
  selectedShiftTemplate?: ShiftTemplateWithKey;
  isWarningTooltipOpen: boolean;
  repetitionEnd?: moment.Moment;
}

class ShiftEditorDialog extends React.Component<ShiftEditorDialogProps, State> {
  private unsubscribeShiftTemplates: () => void;

  constructor(props: ShiftEditorDialogProps) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      shiftTemplates: [],
      isWarningTooltipOpen: false,
    };
  }

  public componentDidMount() {
    const { companyId } = this.props;

    try {
      this.unsubscribeShiftTemplates = getShiftTemplatesTableRef(
        companyId,
      ).onSnapshot(snapshot => {
        const shiftTemplates: ShiftTemplateWithKey[] = collectionToJsonWithIds(
          snapshot,
        );
        this.setState({ shiftTemplates });
      });
    } catch (err) {
      console.error('Error retrieving list of shiftTemplates: ', err);
    }
  }

  public componentWillUnmount = () => {
    this.unsubscribeShiftTemplates && this.unsubscribeShiftTemplates();
  };

  public render() {
    const { classes = {}, members } = this.props;
    const { isWarningTooltipOpen, selectedDate, repetition } = this.state;
    const maxMenuHeight = 150;

    const path = extractName(location.pathname);

    return (
      <Dialog
        open={true}
        onClose={this.handleCancel}
        className={classes.shiftEditorDialog}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>{i18n().ui.new_shift}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <List>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <FormControl className={classes.formControl}>
                <ACSelect
                  maxMenuHeight={maxMenuHeight}
                  options={members.map(member => ({
                    value: member.key,
                    label: member.name,
                  }))}
                  value={this.getValueForMemberSelect()}
                  onChange={this.handleMemberSelectChange()}
                />
              </FormControl>
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <ViewWeek />
              </ListItemIcon>
              <FormControl className={classes.formControl}>
                <ACSelect
                  maxMenuHeight={maxMenuHeight}
                  options={this.state.shiftTemplates.map(shiftTemplate => ({
                    value: shiftTemplate.key,
                    label: shiftTemplate.name,
                  }))}
                  value={this.getValueForShiftTemplateSelect()}
                  onChange={this.handleShiftTemplateSelectChange()}
                />
              </FormControl>
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <DatePicker
                className={classes.datePicker}
                id="date"
                label={i18n().ui.date}
                value={selectedDate}
                onChange={this.handleDateChange()}
                animateYearScrolling={false}
                invalidDateMessage={i18n().ui.give_valid_date}
                format={'DD.MM.YYYY'}
                mask={(value: string | RegExp) =>
                  value
                    ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
                    : []
                }
                cancelLabel={i18n().ui.cancel}
                keyboard
                disableOpenOnEnter
                minDateMessage={i18n().ui.min_date_message}
                maxDateMessage={i18n().ui.max_date_message}
              />
            </ListItem>
            <div
              className={classes.repetitionEditor}
              style={{ flexDirection: 'column' }}
            >
              <RepetitionEditor
                startDate={moment(selectedDate)}
                isEnabled={path === NAVIGATION_PATH.TASKBANK}
                repetition={repetition}
                onChange={this.handleRepetitionChange()}
              />
              {path === NAVIGATION_PATH.HOME && (
                <Typography style={{ color: 'green' }}>
                  {
                    'For better performance, schedule repeating shifts from Tasklist'
                  }
                </Typography>
              )}
            </div>

            {repetition && repetition.count > 365 && (
              <div className={classes.repetitionCountErrorMessage}>
                {i18n().ui.too_many_tasks_error}
              </div>
            )}

            {repetition && repetition.count < 1 && (
              <div className={classes.repetitionDurationError}>
                {i18n().ui.startdate_later_enddate}
              </div>
            )}
          </List>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button color="default" onClick={this.handleCancel}>
            {i18n().ui.cancel}
          </Button>
          <Tooltip
            placement={'top'}
            title={i18n().ui.shift_save_warning_tooltip}
            classes={{ tooltip: classes.tooltip }}
            open={isWarningTooltipOpen}
            onOpen={this.handleTooltipOpen()}
            onClose={this.handleTooltipClose()}
          >
            <div>
              <Button
                disabled={this.isButtonDisabled()}
                onClick={this.handleSave}
                className={classes.saveButton}
              >
                {i18n().ui.save}
              </Button>
            </div>
          </Tooltip>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Returns ACSelectValue for member select
   *
   * @returns {ACSelectValue}
   */
  private getValueForMemberSelect = () => {
    const { selectedMember } = this.state;
    return selectedMember
      ? ({
          value: selectedMember.key,
          label: selectedMember.name,
        } as ACSelectValue)
      : ({
          value: '',
          label: i18n().ui.select_member,
        } as ACSelectValue);
  };

  /**
   * Returns ACSelectValue for shift template select
   *
   * @returns {ACSelectValue}
   */
  private getValueForShiftTemplateSelect = () => {
    const { selectedShiftTemplate } = this.state;
    return selectedShiftTemplate
      ? ({
          value: selectedShiftTemplate.key,
          label: selectedShiftTemplate.name,
        } as ACSelectValue)
      : ({
          value: '',
          label: i18n().ui.select_shift,
        } as ACSelectValue);
  };

  /**
   * Saves to state selected member
   *
   * @param {ACSelectValue} selected
   */
  private handleMemberSelectChange = () => (selected: ACSelectValue) => {
    this.setState({
      selectedMember: this.props.members.find(
        member => member.key === selected.value,
      ),
    });
  };

  /**
   * Saves to state selected shift template
   *
   * @param {ACSelectValue} selected
   */
  private handleShiftTemplateSelectChange = () => (selected: ACSelectValue) => {
    this.setState({
      selectedShiftTemplate: this.state.shiftTemplates.find(
        shiftTemplate => shiftTemplate.key === selected.value,
      ),
    });
  };

  /**
   * Saves to state selected date
   *
   * @param {Date} date
   */
  private handleDateChange = () => (date: moment.Moment) => {
    const { repetition, repetitionEnd } = this.state;
    if (repetition && repetitionEnd) {
      const newRepetition: Repetition = repetition;
      const repetitionCount = getRepeatCount(repetition, date, repetitionEnd);
      newRepetition.count = repetitionCount;

      this.setState({ selectedDate: date.toDate(), repetition: newRepetition });
    } else {
      this.setState({ selectedDate: date.toDate() });
    }
  };

  /**
   * Saves to state repetition
   *
   * @param {Repetition | undefined} repetition
   */
  private handleRepetitionChange = () => (
    repetitionState: Repetition | undefined,
    selectedDate: moment.Moment,
  ) => {
    this.setState({ repetition: repetitionState, repetitionEnd: selectedDate });
  };

  /**
   * Creates shift and tasks. Perform save with batch to firebase
   */
  private handleSave = () => {
    const {
      selectedMember,
      selectedShiftTemplate,
      repetition,
      selectedDate,
    } = this.state;

    if (selectedMember && selectedShiftTemplate && selectedShiftTemplate.key) {
      if (repetition) {
        // Generate repeatingShiftId for all shifts created in this session
        let repeatingShiftId;
        try {
          const document = firebase
            .firestore()
            .collection(Schema.COMPANIES)
            .doc(this.props.companyId)
            .collection(Schema.TASKS)
            .doc();

          repeatingShiftId = document.id;
        } catch (error) {
          console.error(
            'Failed to create repeating shifts document id.',
            error,
          );
        }

        let date = moment(selectedDate);
        const totalRepetitions = repetition.count;
        const numberOfChunks = Math.ceil(totalRepetitions / 5);

        // Store total number of chunks and current number of chunks to display creation progress in ProgressLoader
        store.dispatch(setTotalProgress(numberOfChunks));
        store.dispatch(setCurrentProgress(0));
        store.dispatch(
          setProgressMessage('Creating new shifts, please wait...'),
        );

        const batchArray: firebase.firestore.WriteBatch[] = [];

        for (let j = 0; j < numberOfChunks; j++) {
          const batch = firebaseApp.firestore().batch();
          for (let k = 0; k < 5; k++) {
            this.createShiftWithTasks(
              batch,
              selectedShiftTemplate,
              selectedShiftTemplate.key,
              selectedMember,
              date.toDate(),
              repeatingShiftId,
            );

            switch (repetition.type) {
              case RepetitionType.DAILY:
                {
                  date.add(repetition.step, 'day');
                }
                break;
              case RepetitionType.WEEKLY:
                {
                  date.add(repetition.step, 'week');
                }
                break;
              case RepetitionType.WORKDAY:
                {
                  date = nextWorkDay(date);
                }
                break;
              case RepetitionType.MONTHLY:
                {
                  date.add(repetition.step, 'months');
                }
                break;
            }
          }
          batchArray.push(batch);
          store.dispatch(incrementProgress());
        }

        store.dispatch(setCurrentProgress(0));
        store.dispatch(setProgressMessage('Saving new shifts, please wait...'));
        batchArray.forEach(b => {
          b.commit()
            .then(() => {
              store.dispatch(incrementProgress());
            })
            .catch(err => {
              store.dispatch(setProgressMessage('Error creating shifts'));
              console.error('Error creating repeating shifts: ', err);
            });
        });
      } else {
        // No repetition, create a single shift
        const batch = firebaseApp.firestore().batch();

        this.createShiftWithTasks(
          batch,
          selectedShiftTemplate,
          selectedShiftTemplate.key,
          selectedMember,
        );

        batch
          .commit()
          .catch(err =>
            console.error('Error creating shift in shift editor: ', err),
          );
      }
    }

    this.props.dispatch(cancelShift());
  };

  private handleCancel = () => {
    this.props.dispatch(cancelShift());
  };

  /**
   * Creates shift and corresponding tasks, saves them to given batch
   *
   * @param {firebase.firestore.WriteBatch} batch
   * @param {ShiftTemplateWithKey} selectedShiftTemplate
   * @param {string} selectedShiftTemplateId
   * @param {MemberWithKey} selectedMember
   * @param {Date} date?
   */
  private createShiftWithTasks = (
    batch: firebase.firestore.WriteBatch,
    selectedShiftTemplate: ShiftTemplateWithKey,
    selectedShiftTemplateId: string,
    selectedMember: MemberWithKey,
    date: Date = this.state.selectedDate,
    repeatingShiftId?: string,
  ) => {
    const { companyId, jobtypes, customers, vats, worksites } = this.props;

    const shiftDocument = getShiftDocRef(companyId);

    const shift: Shift = {
      userId: selectedMember.key,
      shiftTemplateId: selectedShiftTemplateId,
      taskIds: [],
    };

    if (repeatingShiftId) {
      shift.repeatingShiftId = repeatingShiftId;
    }

    for (const shiftTemplateTask of selectedShiftTemplate.tasks) {
      const taskDocument = getTaskDocRef(companyId);

      const taskJobtype = jobtypes.find(
        jobtype => jobtype.key === shiftTemplateTask.jobtypeId,
      );

      const taskJobtypeName = taskJobtype ? taskJobtype.name : '';

      const task: TaskWithKey = {
        archived: false,
        created: new Date().getTime(),
        userId: selectedMember.key,
        userName: selectedMember.name,
        jobtypeId: shiftTemplateTask.jobtypeId,
        jobtypeName: taskJobtypeName,
        start: moment(date)
          .startOf('day')
          .add(shiftTemplateTask.start)
          .valueOf(),
        end: moment(date).startOf('day').add(shiftTemplateTask.end).valueOf(),
        duration: shiftTemplateTask.end - shiftTemplateTask.start,
        status: TaskStatus.UNDONE,
        alarm: shiftTemplateTask.alarm,
        desc: shiftTemplateTask.desc,
        priority: shiftTemplateTask.priority,
        title: shiftTemplateTask.title,
        shiftId: shiftDocument.id,
        shiftTemplateTaskId: shiftTemplateTask.id,
        shiftTemplateId: selectedShiftTemplate.key,
      };

      if (shiftTemplateTask.customerId) {
        const taskCustomer = customers.find(
          customer => customer.key === shiftTemplateTask.customerId,
        );

        if (taskCustomer) {
          task.customerId = shiftTemplateTask.customerId;
          task.customerName = taskCustomer.name;
        }
      }

      if (shiftTemplateTask.vatId) {
        const taskVat = vats.find(vat => vat.key === shiftTemplateTask.vatId);

        if (taskVat) {
          task.vat = taskVat;
        }
      }

      if (shiftTemplateTask.worksiteId) {
        const foundWorksite = worksites.find(
          worksite => worksite.key === shiftTemplateTask.worksiteId,
        );

        if (foundWorksite && foundWorksite.key) {
          const taskWorksite: TaskWorksite = {
            id: foundWorksite.key,
            name: foundWorksite.name,
            nick: foundWorksite.nick,
            note: foundWorksite.note,
          };
          if (foundWorksite.location) {
            const taskLocation: Location = foundWorksite.location;
            task.location = taskLocation;
          }
          task.worksite = taskWorksite;
        }
      }

      if (repeatingShiftId) {
        task.repeatingShiftId = repeatingShiftId;
      }

      Object.keys(task).forEach(key => {
        if (task[key] === undefined) {
          delete task[key];
        }
      });

      batch.set(taskDocument, task);
      shift.taskIds.push(taskDocument.id);
    }
    batch.set(shiftDocument, shift);
  };

  /**
   * Triggered when hovering the button to show or not the tooltip
   */
  private handleTooltipOpen = () => () => {
    this.isButtonDisabled() && this.setState({ isWarningTooltipOpen: true });
  };

  /**
   * Hides the tooltip
   */
  private handleTooltipClose = () => () => {
    this.setState({ isWarningTooltipOpen: false });
  };

  /**
   * Determine a boolean value for disabling the save button
   * @returns {boolean}
   */
  private isButtonDisabled = () => {
    const { repetition, selectedMember, selectedShiftTemplate } = this.state;
    if (repetition) {
      return !(
        selectedMember &&
        selectedShiftTemplate &&
        repetition.count >= 1
      );
    } else {
      return !(selectedMember && selectedShiftTemplate);
    }
  };
}

const mapStateToProps = (
  state: ApplicationState,
  ownProps: Partial<ShiftEditorDialogProps>,
) => {
  return {
    ...ownProps,
    dispatch: ownProps.dispatch,
    shiftDialogState: state.shiftEditor.shiftDialogState,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(ShiftEditorDialog),
);
