import { CompanyId, Customer } from '@shared/schema';
import { ActionCreator, AnyAction } from 'redux';

export const enum CustomerActionType {
  CUSTOMERS_NEW = 'CUSTOMERS_NEW',
  CUSTOMERS_CANCEL = 'CUSTOMERS_CANCEL',
  CUSTOMERS_UPDATE = 'CUSTOMERS_UPDATE',
}

export interface NewCustomerAction extends AnyAction {
  type: CustomerActionType.CUSTOMERS_NEW;
  payload: {
    companyId: CompanyId;
    customer: Customer;
  };
}

export interface CancelCustomerAction extends AnyAction {
  type: CustomerActionType.CUSTOMERS_CANCEL;
}

export interface UpdateCustomerAction extends AnyAction {
  type: CustomerActionType.CUSTOMERS_UPDATE;
  payload: {
    companyId: CompanyId;
    customer: Customer;
  };
}

export const newCustomer: ActionCreator<NewCustomerAction> = (
  companyId: CompanyId,
  customer: Customer,
) => {
  return {
    type: CustomerActionType.CUSTOMERS_NEW,
    payload: {
      companyId,
      customer,
    },
  };
};

export const cancelEdit: ActionCreator<CancelCustomerAction> = () => {
  return {
    type: CustomerActionType.CUSTOMERS_CANCEL,
  };
};

export const updateCustomer: ActionCreator<UpdateCustomerAction> = (
  companyId: CompanyId,
  customer: Customer,
) => {
  return {
    type: CustomerActionType.CUSTOMERS_UPDATE,
    payload: {
      companyId,
      customer,
    },
  };
};

export type CustomerAction =
  | AnyAction
  | NewCustomerAction
  | CancelCustomerAction
  | UpdateCustomerAction;
