import { WithKey } from '@shared/schema';
import * as firebase from 'firebase';
/**
 * Convert firestore document to json and add id as key property to it
 */
export function docToJsonWithId<T>(
  doc:
    | firebase.firestore.DocumentSnapshot
    | firebase.firestore.QueryDocumentSnapshot,
): T & WithKey {
  const data = doc.data() as T & WithKey;
  data.key = doc.id;
  return data;
}

/**
 * Convert eact document from firestore collection to json objects with ids
 *
 * @param {*} docs Array of firestore documents
 */
export function collectionToJsonWithIds<T>(
  snapshot: firebase.firestore.QuerySnapshot,
): Array<T & WithKey> {
  const result: Array<T & WithKey> = [];
  snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) =>
    result.push(docToJsonWithId<T & WithKey>(doc)),
  );
  return result;
}

export function generateKeyMap(
  snapshot: firebase.firestore.QuerySnapshot,
): any {
  const result = {};
  snapshot.forEach(
    (doc: firebase.firestore.QueryDocumentSnapshot) =>
      (result[doc.id] = docToJsonWithId(doc)),
  );
  return result;
}

// function getIndexForKey(array: any[], key: any): number {
//   const obj = array.find(item => item.key === key);
//   return array.indexOf(obj);
// }

function sortByKey(array: WithKey[], key: string) {
  array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    if (!x || !y) {
      return 0;
    }
    return x < y ? -1 : 1;
  });
}

/**
 * ## FirestoreCollectionListener
 *
 * Helper class to keep up changes on given query agains firestore
 */
export class FirestoreCollectionListener {
  public query: any;
  public unSubscribeStoreListener: any;
  public generateKeys: boolean;
  public resources: any[];
  public onMap: any;
  public sortKey?: string;
  public keyMap: any = {};
  /**
   *
   * @param {*} query  Firestore query
   * @param {*} generateKeys If true, data will be manipulated to contain the key property with firestore doc id
   */
  constructor(query: any, generateKeys: boolean = true, sortKey?: string) {
    this.query = query;
    this.generateKeys = generateKeys;
    if (sortKey) {
      this.sortKey = sortKey;
    }
    this.keyMap = {};

    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  public _prepareData(doc: any): any {
    const data = doc.data();
    if (this.onMap) {
      return this.onMap(data, doc.id);
    }
    return this.generateKeys ? docToJsonWithId(doc) : data;
  }

  public subscribe(firestore: any, onChange: any) {
    const prepareFun = this._prepareData.bind(this);

    const keyMap = this.keyMap;

    this.unSubscribeStoreListener = firestore.onSnapshot(
      this.query,
      (snapshot: any) => {
        const newResources = this.resources ? this.resources.slice(0) : [];

        snapshot.docChanges.forEach((change: any) => {
          switch (change.type) {
            case 'added':
              const data = prepareFun(change.doc);
              keyMap[change.doc.id] = newResources.length;
              newResources.push(data);
              break;

            case 'modified':
              const index = keyMap[change.doc.id];

              if (index >= 0) {
                newResources[index] = prepareFun(change.doc);
              }
              break;

            case 'removed':
              const index2 = keyMap[change.doc.id];

              if (index2 >= 0) {
                newResources.splice(index2, 1);
                delete keyMap[change.doc.id];
              }
          }
        });

        this.resources = newResources;

        let result = newResources;

        if (newResources && this.sortKey) {
          result = newResources.slice(0);
          sortByKey(result, this.sortKey);
        }

        if (onChange) {
          onChange(result);
        }
      },
    );
  }

  public unsubscribe() {
    if (this.unSubscribeStoreListener) {
      this.unSubscribeStoreListener();
      this.unSubscribeStoreListener = null;
    }
  }
}

// export class FirestoreCollectionChangeListener {
//   // public static propTypes = {
//   //   onAdded: PropTypes.func,
//   //   onModified: PropTypes.func,
//   //   onDeleted: PropTypes.func
//   // };

//   public query: any;
//   public unSubscribeStoreListener: any;
//   public generateKeys: boolean;
//   public resources: any[];
//   public onMap: any;
//   /**
//    *
//    * @param {*} query  Firestore query
//    * @param {*} generateKeys If true, data will be manipulated to contain the key property with firestore doc id
//    */
//   constructor(query: any, generateKeys: boolean = true) {
//     this.query = query;
//     this.generateKeys = generateKeys;
//   }

//   public _prepareData(doc: any): any {
//     const data = doc.data();
//     if (this.onMap) {
//       return this.onMap(data, doc.id);
//     }
//     return this.generateKeys ? docToJsonWithId(doc) : data;
//   }

//   public subscribe(firestore: any, onChange: any) {
//     const prepareFun = this._prepareData.bind(this);

//     this.unSubscribeStoreListener = firestore.onSnapshot(
//       this.query,
//       (snapshot: any) => {
//         const newResources = this.resources ? this.resources.slice(0) : [];

//         snapshot.docChanges.forEach((change: any) => {
//           switch (change.type) {
//             case "added":
//               if (this.props.onAdded) {
//                 const data = prepareFun(change.doc);

//                 try {
//                   this.props.onAdded(data);
//                 } catch (error) {
//                   console.error(error);
//                 }
//               }
//               break;

//             case "modified":
//               const index = getIndexForKey(newResources, change.doc.id);

//               if (index >= 0) {
//                 newResources[index] = prepareFun(change.doc);
//               }
//               break;

//             case "removed":
//               const index2 = getIndexForKey(newResources, change.doc.id);

//               if (index2 >= 0) {
//                 newResources.splice(index2, 1);
//               }
//           }
//         });

//         this.resources = newResources;
//         if (onChange) {
//           onChange(this.resources);
//         }
//       }
//     );
//   }

//   public unsubscribe() {
//     if (this.unSubscribeStoreListener) {
//       this.unSubscribeStoreListener();
//       this.unSubscribeStoreListener = null;
//     }
//   }
// }

export function deleteUndefinedValues<T>(obj: T): T {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      obj[key] = firebase.firestore.FieldValue.delete();
    }
  });
  return obj;
}
