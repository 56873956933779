import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { i18n } from '@shared/locale';
import {
  CompanyId,
  DialogStatus,
  Schema,
  Vat,
  VatWithKey,
} from '@shared/schema';
import classNames from 'classnames';
import SettingsListItem from 'containers/Private/Settings/components/SettingsListItem';
import firebaseApp from 'firebaseApp';
import * as React from 'react';
import muiTheme from 'theme';
import styles from './styles';

interface VatSettingProps extends StyledComponentProps {
  companyId: CompanyId | undefined;
}

interface State {
  vats: VatWithKey[];
  deleteVat: VatWithKey | undefined;
  modifyVat: VatWithKey | undefined;
  newVat: VatWithKey | undefined;
  filter: string;
  selectMultiple: boolean;
  selectedItems: SelectedVats;
  deleteMultiple: boolean;
  modifychecked: false;
}

interface SelectedVats {
  [key: string]: boolean;
}

export class VatSettings extends React.Component<VatSettingProps, State> {
  private unsubscribeVats: () => void;

  constructor(props: VatSettingProps) {
    super(props);
    this.state = {
      vats: [],
      newVat: undefined,
      deleteVat: undefined,
      modifyVat: undefined,
      filter: '',
      selectMultiple: false,
      selectedItems: {},
      deleteMultiple: false,
      modifychecked: false,
    };
  }

  public componentDidMount() {
    const { companyId } = this.props;
    if (companyId) {
      this.unsubscribeVats = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
          const vats: Vat[] = [];

          snapshot.forEach((data: firebase.firestore.QueryDocumentSnapshot) => {
            const vat = data.data() as VatWithKey;
            vat.key = data.id;
            vats.push({
              ...vat,
            });
          });

          vats.sort((a, b) => {
            if (!a.name.toLowerCase() || !b.name.toLowerCase()) {
              return 0;
            }
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });

          this.setState({
            vats,
          });
        });
    } else {
      console.error('Company id is not set @VatSettings');
    }
  }

  public componentWillUnmount() {
    this.unsubscribeVats && this.unsubscribeVats();
  }

  public render() {
    const { classes = {} } = this.props;
    const {
      vats,
      newVat,
      modifyVat,
      deleteVat,
      filter,
      selectMultiple,
      selectedItems,
      deleteMultiple,
    } = this.state;

    const defaultString = `(${i18n().ui.default})`;
    return (
      <div
        className={classNames(classes.container)}
        style={{ color: muiTheme.palette.primary.main }}
      >
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.bar}>
            <FormControl className={classes.topFormControl}>
              <TextField
                id="search"
                placeholder={i18n().ui.search_vat}
                className={classes.searchInput}
                margin="normal"
                onChange={this.handleFilterChange()}
              />
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleAddNewVat}
              >
                <AddIcon className={classes.icon} />
                {i18n().ui.add_new}
              </Button>
              <Button
                variant="contained"
                className={classNames(
                  classes.button,
                  selectMultiple ? classes.selected : '',
                )}
                onClick={this.handleSelectMultiple}
              >
                <DoneAllIcon className={classes.icon} />
                {i18n().ui.select_multiple}
              </Button>
              {selectMultiple && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleDeleteMultiple}
                >
                  <DeleteIcon className={classes.icon} />
                  {i18n().ui.delete_selected}
                </Button>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.itemContainer}>
            <Grid container spacing={24}>
              {vats.map((item: VatWithKey) => {
                if (
                  filter.length === 0 ||
                  item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
                ) {
                  return (
                    <SettingsListItem
                      key={item.name}
                      id={item.key!}
                      name={`${item.name} ${item.percent} % ${
                        item.default ? defaultString : ''
                      }`}
                      onModify={this.handleVatModify}
                      onDelete={this.handleVatDelete}
                      selectActive={selectMultiple}
                      onSelect={this.handleItemSelect}
                      selected={selectedItems[item.key!]}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={newVat !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n().ui.add_vat}</DialogTitle>
          <DialogContent>
            <TextField
              data-testid="new-dialog-name-input"
              autoFocus
              margin="dense"
              id="name"
              label={i18n().ui.name}
              type="text"
              value={newVat ? newVat.name : ''}
              onChange={this.handleDialogChange(DialogStatus.NEW)}
              fullWidth
            />
            <TextField
              data-testid="new-dialog-percentage-input"
              autoFocus
              margin="dense"
              id="percent"
              label={i18n().ui.percent}
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={newVat ? newVat.percent : ''}
              onChange={this.onPercentChange(DialogStatus.NEW)}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={newVat ? newVat.default : undefined}
                  onChange={this.handleSwitch(DialogStatus.NEW)}
                  value="switch"
                />
              }
              label={i18n().ui.default}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.NEW)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleNewDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={modifyVat !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.modify_vat}
          </DialogTitle>
          <DialogContent>
            <TextField
              data-testid="modify-dialog-name-input"
              autoFocus
              margin="dense"
              id="name"
              label={i18n().ui.name}
              type="text"
              value={modifyVat ? modifyVat.name : ''}
              onChange={this.handleDialogChange(DialogStatus.MODIFY)}
              fullWidth
            />
            <TextField
              data-testid="modify-dialog-percentage-input"
              autoFocus
              margin="dense"
              id="percent"
              label={i18n().ui.percent}
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={modifyVat ? modifyVat.percent : ''}
              onChange={this.onPercentChange(DialogStatus.MODIFY)}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={modifyVat ? modifyVat.default : undefined}
                  onChange={this.handleSwitch(DialogStatus.MODIFY)}
                  value="switch"
                />
              }
              label={i18n().ui.default}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.MODIFY)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleModifyDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteVat !== undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_VAT_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose(DialogStatus.DELETE)}>
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteMultiple}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n().ui.delete_selected_VATs_confirm}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose(DialogStatus.DELETEMULTIPLE)}
            >
              {i18n().ui.cancel}
            </Button>
            <Button
              onClick={this.handleDeleteMultipleDialogConfirm}
              color="secondary"
              autoFocus
            >
              {i18n().ui.delete}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  /**
   * Checks what type of change is going on with name and based on that
   * set it to specific state newVat or modifyVat.
   * Gets inputted name from event.target.value
   * @param {DialogStatus} dialogStatus type of change
   */
  private handleDialogChange = (dialogStatus: DialogStatus) => (
    e: React.BaseSyntheticEvent,
  ) => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        const newVat: VatWithKey = this.state.newVat
          ? { ...this.state.newVat }
          : { name: '', percent: 0, default: false };
        newVat.name = e.target.value;
        this.setState({
          newVat,
        });
        break;

      case DialogStatus.MODIFY:
        const modifyVat: VatWithKey = this.state.modifyVat
          ? { ...this.state.modifyVat }
          : { name: '', percent: 0, default: false };
        modifyVat.name = e.target.value;
        this.setState({
          modifyVat,
        });
        break;

      default:
        break;
    }
  };

  /**
   * Checks what type of change is going on with switch and based on that
   * set it to specific state newVat or modifyVat.
   * Gets inputted name from event.target.value
   * @param {DialogStatus} dialogStatus type of change
   */
  private handleSwitch = (dialogStatus: DialogStatus) => (
    e: React.BaseSyntheticEvent,
  ) => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        const newVat: VatWithKey = this.state.newVat
          ? { ...this.state.newVat }
          : { name: '', percent: 0, default: false };
        newVat.default = e.target.checked;

        this.setState({
          newVat,
        });
        break;
      case DialogStatus.MODIFY:
        const modifyVat: VatWithKey = this.state.modifyVat
          ? { ...this.state.modifyVat }
          : { name: '', percent: 0, default: false };
        modifyVat.default = e.target.checked;

        this.setState({
          modifyVat,
        });
        break;

      default:
        break;
    }
  };

  private handleSelectMultiple = () => {
    this.setState({
      selectMultiple: !this.state.selectMultiple,
      selectedItems: {},
    });
  };

  private handleItemSelect = (vatKey: string, selected: boolean) => {
    const newSelectedItems = this.state.selectedItems;
    if (selected) {
      newSelectedItems[vatKey] = true;
    } else {
      delete newSelectedItems[vatKey];
    }
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  private handleFilterChange = () => (e: React.BaseSyntheticEvent) => {
    this.setState({
      filter: e.target.value,
    });
  };

  /**
   * Checks what type of change is going on with VAT and based on that
   * set it to specific state newVat or modifydVat.
   * Gets inputted VAT from event.target.value and before setting it to state checks it in
   * checkPercentageValue() if number < 0 or number > 100
   * @param {dialogStatus} dialogStatus type of change
   */
  private onPercentChange = (dialogStatus: DialogStatus) => (
    e: React.BaseSyntheticEvent,
  ) => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        if (this.state.newVat) {
          this.setState({
            newVat: {
              ...this.state.newVat,
              percent: checkPercentageValue(e.target.value),
            },
          });
        }

        break;

      case DialogStatus.MODIFY:
        if (this.state.modifyVat) {
          this.setState({
            modifyVat: {
              ...this.state.modifyVat,
              percent: checkPercentageValue(e.target.value),
            },
          });
        }

        break;

      default:
    }
  };

  /**
   * Creating new object to newVat to make the dialog visible to make new vat
   */
  private handleAddNewVat = () => {
    this.setState({
      newVat: {
        name: '',
        percent: 0,
        default: false,
      },
    });
  };

  /**
   * Assigning selected elemento to state to be modified and the dialog to be visible
   */
  private handleVatModify = (vatKey: string) => {
    this.setState({
      modifyVat: this.getVatByKey(vatKey),
    });
  };

  /**
   * Assigning selected elemento to state to be deleted and the dialog to be visible
   */
  private handleVatDelete = (vatKey: string) => {
    this.setState({
      deleteVat: this.getVatByKey(vatKey),
    });
  };

  private handleDeleteMultiple = () => {
    this.setState({
      deleteMultiple: true,
    });
  };

  private handleNewDialogConfirm = async () => {
    const { newVat, vats } = this.state;
    if (newVat) {
      // removing default status from previous default vat(s)
      if (newVat.default === true) {
        this.updateVats(vats);
      }

      const ref = firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .doc();

      // Getting key which will be set into vat
      const key = ref.id;

      const newVatToBeSaved: VatWithKey = {
        key,
        name: newVat.name,
        percent: newVat.percent,
        default: newVat.default,
      };

      ref
        .set(newVatToBeSaved)
        .then(() => {
          this.setState({
            newVat: undefined,
          });
        })
        .catch(error => {
          console.error(
            'Creating new vat failed for some magical reason',
            error,
          );
        });
    }
  };
  private updateVats(vats: VatWithKey[]) {
    const batch = firebaseApp.firestore().batch();
    try {
      vats.forEach(element => {
        const vatUpdate = {
          default: false,
        };
        const vatPath = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.VATS)
          .doc(element.key);

        batch.update(vatPath, vatUpdate);
      });
      batch.commit();
    } catch (error) {
      console.error('Batch update failed', error.message);
    }
  }
  private handleModifyDialogConfirm = () => {
    const { modifyVat, vats } = this.state;
    if (modifyVat && modifyVat.key) {
      // removing default status from previous default vat(s)
      if (modifyVat.default === true) {
        this.updateVats(vats);
      }

      const modifiedVat: VatWithKey = {
        key: modifyVat.key,
        name: modifyVat.name,
        percent: modifyVat.percent,
        default: modifyVat.default,
      };

      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .doc(modifyVat.key)
        .update(modifiedVat)
        .then(() => {
          this.setState({
            modifyVat: undefined,
          });
        })
        .catch(error => {
          console.error('Modify failed for some magical reason', error);
        });
    }
  };

  private handleDeleteDialogConfirm = () => {
    const { deleteVat } = this.state;
    if (deleteVat && deleteVat.key) {
      firebaseApp
        .firestore()
        .collection(Schema.COMPANIES)
        .doc(this.props.companyId)
        .collection(Schema.VATS)
        .doc(deleteVat.key)
        .delete()
        .then(() => {
          this.setState({
            deleteVat: undefined,
          });
        })
        .catch(error => {
          console.error('Delete failed for some magical reason', error);
        });
    }
  };

  private handleDeleteMultipleDialogConfirm = () => {
    const { selectedItems } = this.state;
    const batch = firebaseApp.firestore().batch();

    for (const key in selectedItems) {
      if (selectedItems[key]) {
        const itemRef = firebaseApp
          .firestore()
          .collection(Schema.COMPANIES)
          .doc(this.props.companyId)
          .collection(Schema.VATS)
          .doc(key);

        batch.delete(itemRef);
      }
    }

    batch
      .commit()
      .then(() => {
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      })
      .catch(error => {
        console.error(
          'Something went wrong while deleting multiple @ VatSettings',
          error,
        );
        this.setState({
          selectMultiple: false,
          deleteMultiple: false,
        });
      });
  };

  /**
   * Handling dialog closes
   */
  private handleDialogClose = (dialogStatus: DialogStatus) => () => {
    switch (dialogStatus) {
      case DialogStatus.NEW:
        this.setState({
          newVat: undefined,
        });
        break;
      case DialogStatus.MODIFY:
        this.setState({
          modifyVat: undefined,
        });
        break;
      case DialogStatus.DELETE:
        this.setState({
          deleteVat: undefined,
        });
        break;
      case DialogStatus.DELETEMULTIPLE:
        this.setState({
          deleteMultiple: false,
        });
        break;
    }
  };
  private getVatByKey = (key: string) => {
    return this.state.vats
      ? this.state.vats.find(item => item.key === key)
      : undefined;
  };
}
/**
 * Checks and restrict value to between 0-100 and
 * parses out unwanted zeros from the front of the value
 * @param {string} value
 * @returns {number} checkedPercent value
 */
export const checkPercentageValue = (value: string): number => {
  const checkedPercent = parseInt(value, 10);

  if (checkedPercent > 100) {
    return 100;
  }
  if (checkedPercent < 0) {
    return 0;
  }
  return checkedPercent;
};

export default withStyles(styles, { withTheme: true })(VatSettings);
