import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import withStyles, {
  StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import * as React from 'react';
import Select from 'react-select';
import { ActionMeta } from 'react-select/lib/types';
import styles from './styles';

interface ACSelectProps extends StyledComponentProps {
  /**
   * When isMulti is set true, this will return array of objects
   */
  onChange: (
    selected: ACSelectValue | ACSelectValue[],
    action?: ActionMeta,
  ) => void;
  options: any[];
  placeholder?: string;
  value?: ACSelectValue | ACSelectValue[];
  isClearable?: boolean;
  isMulti?: boolean;
  whiteTheme?: boolean;
  menuListHeight?: string;
  isDisabled?: boolean;
  maxMenuHeight?: number | undefined;
}

interface State {
  [key: string]: any;
  single: any;
  multi: any;
}

export interface ACSelectValue {
  label: string;
  value: string;
}

// Functional components
const NoOptionsMessage = (props: any) => {
  const { classes = {} } = props;
  return (
    <Typography
      color="textSecondary"
      className={classNames(classes.noOptionsMessage)}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }: any) => {
  return <div ref={inputRef} {...props} />;
};

const Option = (props: any) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.data.color,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = (props: any) => {
  return (
    <Typography color="textPrimary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props: any) => {
  return (
    <div className={props.selectProps.styles.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const MultiValue = (props: any) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.styles.classes.chip, {
        [props.selectProps.styles.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu = (props: any) => {
  return (
    <Paper
      square
      className={props.selectProps.styles.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

class ACSelect extends React.Component<ACSelectProps, State> {
  constructor(props: ACSelectProps) {
    super(props);
    this.state = {
      single: null,
      multi: null,
    };
  }

  public render() {
    const {
      classes = {},
      options,
      onChange,
      value,
      placeholder,
      isClearable,
      isMulti,
      whiteTheme,
      isDisabled,
      maxMenuHeight,
    } = this.props;

    /**
     * Builds TextField component for AC selector
     * Needs to be under render to get whiteTheme work, may need refactoring
     * @param props
     */
    const Control = (props: any) => {
      let underline;
      if (whiteTheme) {
        underline = props.selectProps.styles.classes.underline;
      }
      if (isDisabled) {
        underline = props.selectProps.styles.classes.disabledUnderline;
      }
      return (
        <TextField
          fullWidth
          InputProps={{
            inputComponent,
            inputProps: {
              className: props.selectProps.styles.classes.input,
              inputRef: props.innerRef,
              children: props.children,
              ...props.innerProps,
            },
            classes: {
              underline,
              focused: whiteTheme
                ? props.selectProps.styles.classes.underline
                : undefined,
            },
          }}
          {...props.selectProps.textFieldProps}
        />
      );
    };

    /**
     * Gets and sets value from props
     * Needs to be under render to get whiteTheme work, may need refactoring
     * @param props
     */
    const SingleValue = (props: any) => {
      let rootClass;
      if (whiteTheme) {
        rootClass = props.selectProps.styles.classes.text;
      }
      if (isDisabled) {
        rootClass = props.selectProps.styles.classes.disabledText;
      }
      return (
        <Typography
          className={props.selectProps.styles.classes.singleValue}
          {...props.innerProps}
          classes={{
            root: rootClass,
          }}
        >
          {props.children}
        </Typography>
      );
    };

    const components = {
      Control,
      Menu,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
    };

    const selectStyles = {
      input: (base: any) => ({
        ...base,
        color: whiteTheme ? 'white' : 'gray',
        '& input': {
          font: 'inherit',
          color: base.color,
        },
      }),
      menuList: (base: any) => ({
        ...base,
        height: this.props.menuListHeight,
      }),
      classes,
    };

    /**
     * If ACSelectValue with both empty values are passed here, show placeholder instead
     */
    let displayValue: ACSelectValue | ACSelectValue[] | undefined = value;
    if (value && !Array.isArray(value)) {
      if (value.label.length === 0 && value.value.length === 0) {
        displayValue = undefined;
      }
    }

    return (
      <Select
        maxMenuHeight={maxMenuHeight}
        isDisabled={isDisabled}
        styles={selectStyles}
        options={options}
        components={components}
        value={displayValue}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isMulti={isMulti}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(ACSelect);
