import moment from 'moment';
import 'moment-duration-format';

export function momentDurationFormat(
  inp: moment.DurationInputArg1,
  template: string | TemplateFunction,
): string;

export function momentDurationFormat(
  inp: moment.DurationInputArg1,
  settings: DurationFormatSettings,
): string;

export function momentDurationFormat(
  inp: moment.DurationInputArg1,
  template: string | TemplateFunction,
  precision: number,
  settings?: DurationFormatSettings,
): string;

export function momentDurationFormat(
  inp: moment.DurationInputArg1,
  template: string | TemplateFunction,
  settings: DurationFormatSettings,
): string;

/**
 * Helper function to correctly format moment.Duration.
 *
 * Internally this uses moment-duration-format plugin. It supports
 * durations longer than 12/24 hours and minutes/seconds beyond
 * 60 depending on the format.
 *
 * This helper is necessary, because moment-duration-format needs
 * to be initialized every time it is used by hand, which will lead
 * to run-time errors. This function takes care of initializing
 * format function in moment.Duration.
 *
 * Docs for moment-duration:
 * https://github.com/jsmreese/moment-duration-format
 *
 * @param inp
 *          A moment instance, time in milliseconds or any other
 *          input moment.duration() generally accepts.
 * @param template
 *          A template to format duration as.
 * @param precision
 *          Precision value used by moment-duration-format.
 * @param settings
 *          Settings for moment-duration-format.
 * @returns
 *          Formatted duration string, e.g. '123:45:06', 2:34' or '07:03'
 */
export function momentDurationFormat(
  inp: moment.DurationInputArg1,
  template: string | TemplateFunction | DurationFormatSettings,
  precision?: number | DurationFormatSettings,
  settings?: DurationFormatSettings,
) {
  // We use <any> here since moment-duration-format typings are broken and
  // we do type check here anyway while overloading the function.
  const duration = moment.duration(inp) as any;

  if (typeof template === 'object') {
    // settings object only
    return duration.format(template);
  } else if (typeof precision === 'number') {
    // template, precision and possibly settings
    return duration.format(template, precision, settings);
  } else {
    // template and settings (passed as precision)
    return duration.format(template, precision);
  }
}

/**
 * Object that can override any of the default moment duration format options.
 *
 * @link https://github.com/jsmreese/moment-duration-format#settings
 */
export interface DurationFormatSettings {
  /**
   * Largest-magnitude tokens are automatically trimmed when they have no value.
   * trim can be a string, a delimited list of strings, an array of strings,
   * or a boolean. The default trim behaviour is "large".
   *
   * @link https://github.com/jsmreese/moment-duration-format#trim
   */
  trim?: false | string | Array<string>;

  /**
   * Set largest to a positive integer to output only the n
   * largest-magnitude moment tokens, starting with the largest-magnitude
   * token that has a value.
   *
   * @link https://github.com/jsmreese/moment-duration-format#largest
   */
  largest?: number;

  /**
   * Set trunc to true to truncate final token value. Default behavior
   * rounds the final token value. Using trunc can affect the operation
   * of trim and largest.
   *
   * @link https://github.com/jsmreese/moment-duration-format#trunc
   */
  trunc?: true;

  /**
   * Trimming will stop when a token listed in this option is reached.
   *
   * @link https://github.com/jsmreese/moment-duration-format#stoptrim
   */
  stopTrim?: string;

  /**
   * Use minValue to render generalized output for small duration values,
   * e.g. '< 5 minutes'
   *
   * @link https://github.com/jsmreese/moment-duration-format#minvalue
   */
  minValue?: number;

  /**
   * Use maxValue to render generalized output for large duration values,
   * e.g. '> 60 days'
   *
   * @link https://github.com/jsmreese/moment-duration-format#maxvalue
   */
  maxValue?: number;

  /**
   * Formatted numerical output is rendered using toLocaleString with
   * the option useGrouping enabled. Set useGrouping to false to disable
   * digit grouping.
   *
   * @link https://github.com/jsmreese/moment-duration-format#usegrouping
   */
  useGrouping?: boolean;

  /**
   * precision (number) defines the number of decimal fraction or integer
   * digits to display for the final value.
   *
   * @link https://github.com/jsmreese/moment-duration-format#precision
   */
  precision?: number;

  /**
   * The decimal separator used when using the fallback number format
   * function. Default value is a . character.
   *
   * @link https://github.com/jsmreese/moment-duration-format#decimalseparator
   */
  decimalSeparator?: string;

  /**
   * The integer digit grouping separator used when using the fallback
   * number format function. Default value is a , character.
   *
   * @link https://github.com/jsmreese/moment-duration-format#groupingseparator
   */
  groupingSeparator?: string;

  /**
   * The integer digit grouping used when using the fallback number format function.
   * Must be an array. The default value of [3] gives the standard 3-digit
   * thousand/million/billion digit groupings for the "en" locale. Setting this option
   * to [3, 2] would generate the thousand/lakh/crore digit groupings used in the
   * "en-IN" locale.
   *
   * @link https://github.com/jsmreese/moment-duration-format#grouping
   */
  grouping?: number[];

  /**
   * When useSignificantDigits is set to true, the precision option determines
   * the maximum significant digits to be rendered. Precision must be a positive
   * integer. Significant digits extend across unit types, e.g. "6 hours 37.5 minutes"
   * represents 4 significant digits. Enabling this option causes token length
   * to be ignored.
   *
   * Using the useSignificantDigits option defaults trim to "all".
   *
   * Setting trunc affects the operation of useSignificantDigits.
   *
   * See the documentation for toLocaleString for more information on significant digits.
   *
   * @link https://github.com/jsmreese/moment-duration-format#usesignificantdigits
   */
  useSignificantDigits?: true;

  /**
   * Force the first moment token with a value to render at full length, even when
   * the template is trimmed and the first moment token has a length of 1.
   *
   * Sounds more complicated than it is.
   *
   * @link https://github.com/jsmreese/moment-duration-format#forcelength
   */
  forceLength?: boolean;

  /**
   * The string used to create the formatted output, or a function that
   * returns the string to be used as the format template.
   *
   * @link https://github.com/jsmreese/moment-duration-format#template
   */
  template?: string | TemplateFunction;

  /**
   * Numerical output is rendered using the locale set in moment.js, retrieved
   * via moment.locale(). Set the userLocale option to render numerical output
   * using a different locale.
   *
   * @link https://github.com/jsmreese/moment-duration-format#userlocale
   */
  userLocale?: string;

  /**
   * Unit label pluralization is automatically corrected when unit labels appear
   * in the text associated with each moment token. The default "en" locale
   * extension includes long and short unit labels, and a basic pluralization
   * function. Unit labels, unit label types, and the pluralization function can
   * be customized for a locale.
   *
   * @link https://github.com/jsmreese/moment-duration-format#useplural
   */
  usePlural?: boolean;

  /**
   * The text to the right of each moment token in a template string is treated
   * as that token's units for the purposes of trimming, pluralizing, and
   * localizing. To properly process a template string where the token/unit
   * association is reversed, set useLeftUnits to true.
   *
   * @link https://github.com/jsmreese/moment-duration-format#useleftunits
   */
  useLeftUnits?: boolean;

  /**
   * Set this option to false to ignore the Intl.NumberFormat and
   * toLocaleString feature tests and force the use of the formatNumber
   * fallback function included in this plugin.
   *
   * The fallback number format options will have no effect when Intl.NumberFormat
   * or toLocaleString are used. The grouping separator, decimal separator, and
   * integer digit grouping will be determined by the user locale.
   *
   * @link https://github.com/jsmreese/moment-duration-format#usetolocalestring
   */
  useToLocaleString?: boolean;
}

/**
 * Use a custom template function if you need runtime control over the template
 * string. Template functions are executed with a this binding of the settings
 * object, and have access to the underlying duration object via this.duration.
 * Any of the settings may be accessed or modified by the template function.
 *
 * @link https://github.com/jsmreese/moment-duration-format#custom-template-function
 */
export type TemplateFunction = (this: DurationFormatSettings) => string;
