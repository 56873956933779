import { MemberWithKey, Schema } from '@shared/schema';
import firebaseApp from 'firebaseApp';
import { docToJsonWithId } from './firestoreUtils';

/**
 * Returns reference to members table from firebase
 *
 * @param {string | undefined} companyId
 * @returns {firebase.firestore.CollectionReference}
 */
export function getMembersTableRef(companyId: string | undefined) {
  return firebaseApp
    .firestore()
    .collection(Schema.COMPANIES)
    .doc(companyId)
    .collection(Schema.MEMBERS);
}

/**
 * Returns reference to member instance with member id
 *
 * @param {string | undefined} companyId
 * @param {string | indefined} id?
 * @returns {firebase.firestore.CollectionReference}
 */
export function getMemberDocRef(
  companyId: string | undefined,
  id?: string | undefined,
) {
  return id
    ? getMembersTableRef(companyId).doc(id)
    : getMembersTableRef(companyId).doc();
}

/**
 * Tries to return the member with passed id
 *
 * @param {string | undefined} companyId
 * @param {string} memberId
 * @returns {Promise<MemberWithKey>}
 */
export async function getMemberWithId(
  companyId: string | undefined,
  memberId: string,
) {
  return new Promise<MemberWithKey>(async (resolve, reject) => {
    try {
      const doc = await getMemberDocRef(companyId, memberId).get();

      if (doc.exists) {
        const member = docToJsonWithId<MemberWithKey>(doc);

        resolve(member);
      } else {
        reject('Member is not exist for firebase document snapshot');
      }
    } catch (error) {
      console.error('Error retrieving the member');
      reject(error);
    }
  });
}
